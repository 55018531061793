import React, {Component} from "react"
import sortByCreatedAt from "../Base/sortByCreatedAt"

export default class MyCustomerTreeNode extends Component {
  constructor(props) {
    super(props)
    this.selfRef = React.createRef()
  }

  componentDidMount() {
    if (this.props.wantedCustomerId === this.props.node.id) {
      this.selfRef.current.scrollIntoView({
        block: "center"
      })
    }
  }

  render() {
    return (
      <>
        <tr
          className={`${this.isCustomerTypeVisible() ? "" : "opacity-5"} ${this.props.clickedNodeId === this.props.node.id ? "hover" : ""}`}
          onClick={this.handleRowClick.bind(this)}
          title={this.props.wantedCustomerId === this.props.node.id ? "Hledaný zákazník" : ""}
          ref={this.selfRef}
        >
          <td
            className={"d-none"}
          >{this.props.index}</td>

          {this.props.columns
            .keySeq()
            .toArray()
            .map(key => this.props.columns.get(key).visible && (
              <td
                key={key}
                className={`${key === "customer_no" ? "column-sticky bg-white" : ""} ${this.props.wantedCustomerId === this.props.node.id ? "bg-warning" : ""}`}
                style={{
                  paddingLeft: key === "customer_no" ? this.props.depth * 25 : "",
                }}
              >
                {key === "customer_no" && (
                  <button
                    onClick={this.handleToggleClick.bind(this)}
                    className={`p-0 btn mr-2 btn-sm btn-primary d-print-none ${this.props.node.children.length === 0 ? "invisible" : ""}`}
                    type={"button"}
                  >
                    <i
                      className={`fas fa-xs fa-${this.isOpen() ? "minus" : "plus"}`}
                    />
                  </button>
                )}

                {this.props.columns.get(key).hasOwnProperty("format")
                  ? this.props.columns.get(key).format(this.props.node[this.props.columns.get(key).key], this.props.node, this.props.depth)
                  : this.props.node[key]
                }
              </td>
            ))}
        </tr>

        {this.isOpen() && this.props.node.children
          .sort(sortByCreatedAt)
          .map((child, index) => (
            <MyCustomerTreeNode
              key={child.id}
              node={child}
              columns={this.props.columns}
              depth={this.props.depth + 1}
              index={`${this.props.index}.${index + 1}`}
              toggleChildrenHandler={this.props.toggleChildrenHandler}
              customerTypes={this.props.customerTypes}
              currentLimit={this.props.currentLimit}
              rowClickHandler={this.props.rowClickHandler}
              clickedNodeId={this.props.clickedNodeId}
              openedNodes={this.props.openedNodes}
              wantedCustomerId={this.props.wantedCustomerId}
            />
          ))}
      </>
    )
  }

  isOpen() {
    return this.props.openedNodes.indexOf(this.props.node.id) > -1
  }

  handleToggleClick() {
    this.props.toggleChildrenHandler(this.props.node.id, this.props.depth)
  }

  isCustomerTypeVisible() {
    return this.props.customerTypes.some(customerType => customerType.value === this.props.node.type_id)
  }

  handleRowClick() {
    this.props.rowClickHandler(this.props.node.id)
  }
}
