import React from "react"

let handleOrderTypeChange = (type, checked, currentTypes, callback) => {
  currentTypes = currentTypes
    .replace("=", "")
    .split("|")

  if (currentTypes.indexOf(type) > -1) {
    currentTypes = currentTypes.filter(currentTypes => currentTypes !== type)
  } else {
    currentTypes.push(type)
  }

  currentTypes = currentTypes.join("|")
  callback({order_type_id: `=${currentTypes}`})
}

let handleStornoChange = (checked, callback) => {
  callback({status: checked ? "storno" : ""})
}

export default (props) => {
  return (
    <div
      className={"row"}
    >
      <div
        className={"col-lg-6"}
      >
        <label>
          <input
            type={"checkbox"}
            className={"mr-1"}
            checked={props.orderTypeId.indexOf("default") > -1}
            onChange={e => handleOrderTypeChange("default", e.target.checked, props.orderTypeId, props.orderTypeFilterChangeHandler)}
          />
          Faktury
        </label>

        <label>
          <input
            type={"checkbox"}
            className={"mr-1"}
            checked={props.orderTypeId.indexOf("refund") > -1}
            onChange={e => handleOrderTypeChange("refund", e.target.checked, props.orderTypeId, props.orderTypeFilterChangeHandler)}
          />
          Dobropisy
        </label>

        <label>
          <input
            type={"checkbox"}
            className={"mr-1"}
            checked={props.orderTypeId.indexOf("return") > -1}
            onChange={e => handleOrderTypeChange("return", e.target.checked, props.orderTypeId, props.orderTypeFilterChangeHandler)}
          />
          Vratky
        </label>
      </div>

      <div
        className={"col-lg-6"}
      >
        <label>
          <input
            type={"checkbox"}
            className={"mr-1"}
            checked={props.status === "storno"}
            onChange={e => handleStornoChange(e.target.checked, props.orderTypeFilterChangeHandler)}
          />
          Storno
        </label>
      </div>
    </div>
  )
}
