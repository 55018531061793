import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import API from "../../API"
import {getParams, serializeParams} from "../../Utils"
import ExportButton from "../../Base/ExportButton"
import orderSummaryExportColumn from "../../fixtures/orderSummaryExportColumn"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import PAYMENT_METHOD from "../../Base/Enum/PaymentMethod"

export default class OrderSummaryList extends Component {
  constructor(props) {
    super(props)
    let params = getParams()
    this.state = {
      data: [],
      countries: [],
      paymentMethods: [],
      deliveryMethods: [],
      isLoading: true,
      summaryBy: params.summary_by || "payment_method", // make sure correct data is displayed after fetch, not filter change
      params: Object.assign({
        summary_by: "payment_method",
        date_start: moment().subtract(1, "days").format("YYYY-MM-DD"), // yesterday
        date_end: moment().format("YYYY-MM-DD"), // today
      }, params),
    }
    this.apiUrl = "/reports/order-summary"
    this.appUrl = "/reports/sets/order-summary"
  }

  componentDidMount() {
    this.getCountries()
    this.getPaymentMethods()
    this.getDeliveryMethods()
    this.getData()
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          countries: data.objects,
        })
      })
  }

  getPaymentMethods() {
    API.get("/paymentMethods?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          paymentMethods: data.objects,
        })
      })
  }

  getDeliveryMethods() {
    API.get("/deliveryMethods?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          deliveryMethods: data.objects,
        })
      })
  }

  getData() {
    this.setState({
      isLoading: true,
    })

    let params = serializeParams(this.state.params)
    window.history.replaceState({}, "", `/#${this.appUrl}${params}`)

    API.get(`${this.apiUrl}${params}`)
      .then(({data}) => {
        data.objects.sort((a, b) => a.count - b.count)
        this.setState({
          data: data.objects,
          summaryBy: this.state.params.summary_by,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          <div
            className={"form-group col-12 text-right"}
          >
            <ExportButton
              exportName={"order-summary"}
              fileName={"Přehled zakázek doprava a platba"}
              params={this.state.params}
              columns={orderSummaryExportColumn}
              apiUrl={this.apiUrl}
            />
          </div>

          <div
            className={"form-group col-lg-2"}
          >
            <label>Od:</label>
            <DatePicker
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.date_start).toDate()}
              onChange={this.handleDateStartChange.bind(this)}
              dateFormat={"dd. MM. yyyy"}
            />
          </div>

          <div
            className={"form-group col-lg-2"}
          >
            <label>Do:</label>
            <DatePicker
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.date_end).toDate()}
              onChange={this.handleDateEndChange.bind(this)}
              dateFormat={"dd. MM. yyyy"}
            />
          </div>

          <div
            className={"form-group col-lg-2 mt-lg-4"}
          >
            <div
              className={"form-check form-check-inline"}
            >
              <input
                type={"radio"}
                name={"summaryBy"}
                id={"payment_method"}
                className={"form-check-input"}
                value={"payment_method"}
                onChange={this.handleSummaryByChange.bind(this)}
                checked={this.state.params.summary_by === "payment_method"}
              />
              <label
                className={"form-check-label"}
                htmlFor={"payment_method"}
              >Způsob platby</label>
            </div>

            <div
              className={"form-check form-check-inline"}
            >
              <input
                type={"radio"}
                name={"summaryBy"}
                id={"delivery_method"}
                className={"form-check-input"}
                value={"delivery_method"}
                onChange={this.handleSummaryByChange.bind(this)}
                checked={this.state.params.summary_by === "delivery_method"}
              />
              <label
                className={"form-check-label"}
                htmlFor={"delivery_method"}
              >Způsob doručení</label>
            </div>
          </div>

          <div
            className={"form-group col-lg-2 mt-lg-4"}
          >
            <button
              className={"btn btn-primary"}
              onClick={this.getData.bind(this)}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <div
          className={"row"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <div
            className={"col-12"}
          >
            <ScrollableTable>
              <table
                className={"table table-striped mb-0"}
              >
                <thead>
                <tr>
                  <th>Země</th>
                  {this.getMethodData().map(method => (
                    <th
                      key={method.id}
                    >{method.name}</th>
                  ))}
                </tr>
                </thead>

                <tbody>
                {this.state.data.length === 0
                  ? (
                    <tr
                      className={"text-center"}
                    >
                      <td
                        colSpan={1 + this.getMethodData().length}
                      >Nic nenalezeno
                      </td>
                    </tr>
                  ) : (
                    this.state.countries.map(country => (
                      <tr
                        key={country.id}
                      >
                        <td>{country.name}</td>
                        {this.getMethodData().map(method => (
                          <td
                            key={method.id}
                          >{this.getValue(method.id, country.code)}</td>
                        ))}
                      </tr>
                    ))
                  )
                }
                </tbody>

                {this.state.data.length > 0 && (
                  <tfoot
                    className={"bg-light font-weight-bold"}
                  >
                  <tr>
                    <td>Celkem</td>
                    {this.getMethodData().map(method => (
                      <td
                        key={method.id}
                      >{this.getSumValue(method.id)}</td>
                    ))}
                  </tr>
                  </tfoot>
                )}
              </table>
            </ScrollableTable>
          </div>
        </div>
      </>
    )
  }

  handleSummaryByChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        summary_by: e.target.value
      })
    })
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  getMethodData() {
    return this.state.summaryBy === "payment_method"
      ? this.state.paymentMethods.filter(method => method.id !== PAYMENT_METHOD.BONUS_MONEY)
      : this.state.deliveryMethods
  }

  getValue(valueId, countryCode) {
    let countries = this.state.data.filter(item => item.country_code === countryCode)

    if (countries.length > 0) {
      let orders = countries[0].orders.filter(order => order.id === valueId)

      if (orders.length > 0) {
        return orders[0].count
      }
    }

    return 0
  }

  getSumValue(valueId) {
    return this.state.data.reduce((itemAcc, item) => itemAcc + item.orders
      .filter(order => order.id === valueId)
      .reduce((acc2, order) => acc2 + order.count, 0), 0)
  }
}
