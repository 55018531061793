import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import WareHouseList from "./WareHouseList"
import WareHouseScales from "./WareHouseScales"
import WareHouseShipments from "./WareHouseShipments"
import PickUpList from "./PickUpList"

export default class Enums extends Component {
  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <Switch>
            <Route
              path={"/warehouse/sales"}
              component={WareHouseList}
            />
            <Route
              path={"/warehouse/scales"}
              component={WareHouseScales}
            />
            <Route
              path={"/warehouse/shipments"}
              component={WareHouseShipments}
            />
            <Route
              path={"/warehouse/pickup-lists"}
              component={PickUpList}
            />
          </Switch>
        </div>
      </div>
    )
  }
}
