import React, {Component, Fragment} from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import CreatableSelect from "react-select/creatable"
import BootstrapTable from "react-bootstrap-table-next"
import {NavLink} from "react-router-dom"
import {List} from "immutable"
import moment from "moment"
import jwtDecode from "jwt-decode"
import API from "../API"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import Collapsible from "./Collapsible"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import Note from "../Base/Note"
import styles from "./style.module.css"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import RBAC from "../RBAC/RBAC"
import PRODUCT_CODE from "../Base/Enum/ProductCode"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import SpinnerInline from "../Base/SpinnerInline/SpinnerInline"
import seq from "../Base/seq"
import isPromotionDiscountGroup from "../Base/isPromotionDiscountGroup"
import DISCOUNT_TYPE from "../Base/Enum/DiscountType"
import formatCustomerName from "../Base/formatCustomerName"
import {filterPaymentMethodByCountryCode} from "../Base/filterPaymentMethod"
import getProductNameByCountry from "../Base/getProductNameByCountry"
import ProductCatalogue from "./ProductCatalogue/ProductCatalogue"
import getProductPrice from "../Base/getProductPrice"

export default class OrderForm extends Component {
  constructor(props) {
    super(props)
    this.itselfRef = React.createRef()
    this.searchProductInputRef = React.createRef()
    this.searchCustomerInputRef = React.createRef()
    this.productTableRef = React.createRef()
    this.bonusPointsLimits = [500, 1500, 3500, 8000, 14000, 20000, 25000]
  }

  componentDidMount() {
    // selected order by order id
    if (this.props.orderId && this.props.orderId === this.props.order.id) {
      this.scrollToItselfTop()
    }

    // newly added order
    if (this.props.order._focus) {
      this.scrollToItselfTop()
      this.searchCustomerInputRef.current.focus()
    }

    if (this.props.isReturn) {
      this.updateProductsQuantities()
      this.addFeeProducts()
    }

    // promo products
    if (this.props.isDetail) {
      this.updatePromoProductsData()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // focus search product input after select customer
    if (prevProps.order.customer_id !== this.props.order.customer_id) {
      this.focusSearchProductInput()
    }

    // focus order by clicking on order tabs
    if (this.props.focusOrderId && this.props.focusOrderId === this.props.order.id) {
      this.scrollToItselfTop()
    }
  }

  render() {
    let orderQuantity = 0
    let orderPrice = 0
    let orderPoints = 0

    return (
      <div
        ref={this.itselfRef}
        className={`col-12
          ${this.props.order.parent_order_id ? "bg-yellow" : ""} 
          ${this.props.orderId && this.props.orderId === this.props.order.id ? "bg-light-success" : ""}
          ${this.props.order.status === "storno" ? "opacity-disabled" : ""} 
        `}
      >
        <fieldset
          disabled={this.isOrderFormDisabled()}
          title={this.isOrderFormDisabled()
            ? this.props.order.status === "storno"
              ? "Objednávka byla stornována."
              : "Objednávka již byla uhrazena a nelze ji nadále editovat. Pro další úpravy vytvořte vratku nebo dobropis."
            : ""}
        >
          <div
            className={"row"}
          >
            <div
              className={"col-12"}
            >
              <div
                className={`bg-light border-top-15 ${this.props.isSaleFirstOrder ? "" : "mt-4"} mb-2 p-2`}
              >
                <div
                  className={"row"}
                >
                  <div
                    className={"col-6"}
                  >
                    <strong
                      className={`form-group d-block m-0 ${this.someNoteIsWarning() ? "text-danger" : ""}`}
                      title={this.someNoteIsWarning() ? "Objednávka obsahuje varovnou poznámku." : ""}
                    >
                      {this.someNoteIsWarning() && (
                        <i
                          className={"fas fa-exclamation-triangle fa-xs text-danger mr-1"}
                        />
                      )}

                      {this.props.isReturn
                        ? "Dobropis"
                        : (
                          <>
                            {this.props.order.invoice_no ? `${this.props.order.invoice_no} - ` : ""}
                            {`${seq(this.props.seqNo)} - Objednávka pro `}
                            <NavLink
                              to={`/customers/${this.props.order.customer.id}/detail`}
                            >{this.props.order.customer.name || ""} {this.props.order.customer.surname || ""}</NavLink>
                            <span
                              className={"float-right"}
                            >{this.props.order.paid_date ? `Uhrazeno ${moment(this.props.order.paid_date).format("DD. MM. YYYY")}` : ""}</span>
                          </>
                        )
                      }
                    </strong>
                  </div>
                  <div
                    className={"col-6"}
                  >
                    <input
                      type={"checkbox"}
                      className={"form-check-input ml-0"}
                      id={"is_simple_invoice"}
                      onChange={this.handleIsSimpleInvoiceChange.bind(this)}
                      checked={this.props.order.is_simple_invoice || false}
                    />
                    <label
                      htmlFor={"is_simple_invoice"}
                      className={"ml-4 mt-1"}
                    >
                      <strong>Vytvořit zjednodušený daňový doklad</strong>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={"col-6"}
            >
              <div
                className={"form-row"}
              >
                <div
                  className={"form-group col-4"}
                >
                  <label>Splatnost:</label>

                  <CreatableSelect
                    value={this.props.order.due_days ? {
                      label: this.props.order.due_days,
                      value: this.props.order.due_days,
                    } : ""}
                    options={[{
                      label: 7,
                      value: 7,
                    }, {
                      label: 14,
                      value: 14,
                    }]}
                    isClearable={true}
                    isDisabled={this.props.order.customer.type_id !== CUSTOMER_TYPE.RZ}
                    placeholder={"Splatnost"}
                    onChange={this.handleDueDaysChange.bind(this)}
                    formatCreateLabel={value => value}
                  />
                </div>

                <div
                  className={"form-group col-4"}
                >
                  <label>Způsob platby:</label>
                  <select
                    className={"form-control"}
                    required={true}
                    onChange={this.handlePaymentMethodChange.bind(this)}
                    value={this.props.order.payment_method_id || ""}
                  >
                    {this.props.paymentMethods
                      .filter(paymentMethod => filterPaymentMethodByCountryCode(paymentMethod.id, this.props.order.billing_address_country_code))
                      .map(paymentMethod => (
                        <option
                          key={paymentMethod.id}
                          value={paymentMethod.id}
                        >{paymentMethod.name}</option>
                      ))}
                  </select>
                </div>

                <div
                  className={"form-group col-4"}
                >
                  <label>Ceny:</label>
                  <select
                    className={"form-control"}
                    required={true}
                    disabled={this.props.order.is_simple_invoice}
                    onChange={this.handlePriceTypeChange.bind(this)}
                    value={this.props.order.price_type || ""}
                  >
                    {this.props.priceTypes.map(priceType => (
                      <option
                        key={priceType.id}
                        value={priceType.id}
                      >{priceType.name}</option>
                    ))}
                  </select>
                </div>

                <div
                  className={"form-group col-2"}
                >
                  <label>Typ zákazníka:</label>
                  <input
                    className={"form-control"}
                    type={"text"}
                    disabled={true}
                    value={this.props.getCustomerTypeNameHandler(this.props.order.customer)}
                  />
                </div>

                <div
                  className={`form-group col-10 select-required ${this.props.isSaleFirstOrder && this.props.salePaymentMethodId === 5 ? "select-disabled" : ""}`}
                >
                  <label>Zákazník:</label>
                  <AsyncSelect
                    className={"react-select_border"}
                    value={this.props.order.customer || {}}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                    onChange={this.handleCustomerSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                    styles={ReactSelectCustomStyles}
                    ref={this.searchCustomerInputRef}
                    isDisabled={this.props.isSaleFirstOrder && this.props.salePaymentMethodId === 5}
                  />
                </div>

                <div
                  className={"col-12"}
                >
                  <table
                    className={"table mt-1"}
                  >
                    <thead>
                    <tr>
                      <th
                        className={"border-0 bg-light"}
                      >Věrnostní Kč:
                      </th>
                      <th
                        className={"border-0 bg-light"}
                      >Z minulých let:
                      </th>
                      <th
                        className={"border-0 bg-light"}
                      >Letos:
                      </th>
                    </tr>
                    </thead>
                    <tbody
                      title={this.props.isRecalculatingPoints ? "Právě dochází k přepočítávání bodů." : ""}
                      className={this.props.isRecalculatingPoints ? "text-black-50" : ""}
                    >
                    <tr>
                      <td
                        className={"bg-grey border-0"}
                      >
                        {this.getBonusMoneyState("total")}
                        {this.props.isRecalculatingPoints && <SpinnerInline/>}
                      </td>
                      <td
                        className={"bg-grey border-0"}
                      >
                        {this.getBonusMoneyState("previous_years")}
                        {this.props.isRecalculatingPoints && <SpinnerInline/>}
                      </td>
                      <td
                        className={"bg-grey border-0"}
                      >
                        {this.getBonusMoneyState("current_year")}
                        {this.props.isRecalculatingPoints && <SpinnerInline/>}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              className={"col-6"}
            >
              <div
                className={"form-group"}
              >
                <label>Jméno:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  onChange={this.handleBillingAddressBillingNameChange.bind(this)}
                  value={this.props.order.billing_address_billing_name || ""}
                />
              </div>
              <div
                className={"form-group"}
              >
                <label>Fakturační adresa - Ulice včetně čísla popisného, evidenčního nebo i
                  orientačního:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={!this.props.order.is_simple_invoice}
                  onChange={this.handleBillingAddressStreetChange.bind(this)}
                  value={this.props.order.billing_address_street || ""}
                />
              </div>

              <div
                className={"form-row"}
              >
                <div
                  className={"form-group col-2 select-required"}
                >
                  <label>PSČ:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    required={!this.props.order.is_simple_invoice}
                    onChange={this.handleBillingAddressPostCodeChange.bind(this)}
                    value={this.props.order.billing_address_post_code || ""}
                  />
                </div>

                <div
                  className={"form-group col-8 select-required"}
                >
                  <label>Město:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    required={!this.props.order.is_simple_invoice}
                    onChange={this.handleBillingAddressCityChange.bind(this)}
                    value={this.props.order.billing_address_city || ""}
                  />
                </div>

                <div
                  className={"form-group col-2"}
                >
                  <label>Kód země:</label>
                  <select
                    className={"form-control"}
                    required={!this.props.order.is_simple_invoice}
                    onChange={this.handleBillingAddressCountryCodeChange.bind(this)}
                    value={this.props.order.billing_address_country_code || ""}
                  >
                    {this.props.countries.map(country => {
                      return (
                        <option
                          key={country.id}
                          value={country.code}
                        >{country.code}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div
              className={"col-12"}
            >
              <hr/>
            </div>

            <fieldset
              className={"col-12"}
              disabled={this.isProductDisabled()}
            >
              <div
                className={"form-group"}
              >
                <label>Produkty:</label>
                <table
                  className={"table table-striped table-bordered font-weight-bold"}
                  ref={this.productTableRef}
                >
                  <thead>
                  <tr
                    className={"bg-light"}
                  >
                    <th>Č. výr.</th>
                    <th>Název výrobku</th>
                    <th>Počet ks</th>
                    <th>cena/MJ</th>
                    <th>cena/MJ {this.props.hasPricesWithoutVAT ? "bez DPH" : "s DPH"}</th>
                    <th>Body &times; ks</th>
                    <th>Celková cena {this.props.hasPricesWithoutVAT ? "bez DPH" : "s DPH"}</th>
                    <th>
                      <input
                        className={"mr-2"}
                        type={"checkbox"}
                        onChange={this.handleIsAdvertChange.bind(this)}
                        checked={this.props.order._isAdvert || false}
                      />
                      Propagační účely
                    </th>
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  {this.props.order.order_product.map((orderProduct, i) => {
                    let isReturnOrRefund = !!this.props.order.parent_order_id
                    let price = this.getProductPrice(orderProduct) * (isReturnOrRefund ? -1 : 1)
                    orderPrice += price * orderProduct.quantity
                    orderQuantity += orderProduct.quantity * (isReturnOrRefund ? -1 : 1)
                    if (
                      !orderProduct.is_advert &&
                      price !== 0
                    ) {
                      orderPoints += this.getProductPoints(orderProduct, isReturnOrRefund)
                    }

                    return (
                      <Fragment
                        key={this.isPromoProduct(orderProduct) ? `promo-${orderProduct.product_id}` : orderProduct.product_id}
                      >
                        <tr
                          className={"px-2 py-1"}
                        >
                          {/* number */}
                          <td>
                            {orderProduct.product.code}
                            {this.props.order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY && this.hasDiscount(orderProduct) && (
                              <i
                                className={"fas fa-piggy-bank ml-1 text-danger"}
                                title={"Produkt ve slevě"}
                              />
                            )}
                          </td>
                          {/* name */}
                          <td>
                            {getProductNameByCountry(orderProduct.product, this.props.countryId, null)}
                            {orderProduct.product.stock && orderProduct.product.stock.length > 0 && (
                              <i
                                className={"fas fa-warehouse ml-2"}
                                title={`${this.props.getProductStockQuantity(orderProduct.product, "")} zbývajících kusů.`}
                              />
                            )}
                            {orderProduct.product.notes && orderProduct.product.notes.some(note => note.is_extended) && (
                              <span
                                className={"fab fa-product-hunt ml-1 toggle-tooltip"}
                              >
                              <span
                                className={"tooltip ml-3 mt-1 text-white"}
                              >
                                {orderProduct.product.notes.filter(note => note.is_extended).map(note => (
                                  <div
                                    key={note.id}
                                  >{note.note}</div>
                                ))}
                              </span>
                              </span>
                            )}
                          </td>
                          {/* quantity */}
                          <td>
                            <div
                              className={"d-inline-block w-75"}
                            >
                              {this.props.isReturn ? (
                                <Select
                                  value={{
                                    label: orderProduct.quantity,
                                    value: orderProduct.quantity,
                                  }}
                                  options={this.getProductQuantityOptions(this.getOrderProductMaxQuantity(orderProduct))}
                                  autoFocus={orderProduct._focus}
                                  defaultMenuIsOpen={orderProduct._focus}
                                  isDisabled={this.isOrderFormDisabled()}
                                  onChange={option => this.handleProductQuantityChange(option, i)}
                                  noOptionsMessage={() => "Nelze zadat"}
                                />
                              ) : (
                                <CreatableSelect
                                  value={{
                                    label: orderProduct.quantity,
                                    value: orderProduct.quantity,
                                  }}
                                  options={this.getProductQuantityOptions(10)}
                                  autoFocus={orderProduct._focus}
                                  defaultMenuIsOpen={orderProduct._focus}
                                  onChange={option => this.handleProductQuantityChange(option, i)}
                                  formatCreateLabel={value => value}
                                  isDisabled={this.isOrderFormDisabled() || this.isPromoProduct(orderProduct) || this.isProductDisabled()}
                                />
                              )}
                            </div>
                          </td>
                          {/* price without VAT */}
                          <td>
                            {this.props.hasPricesWithoutVAT
                              ? price.toFixed(this.props.countryCurrencyInfo.round_decimal_places)
                              : (price / (1 + (orderProduct.product.vat_rate / 100))).toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                            &nbsp;
                            {this.props.countryCurrencyInfo.currency}
                          </td>
                          {/* price with VAT */}
                          <td>
                            {price.toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                            &nbsp;
                            {this.props.countryCurrencyInfo.currency}
                          </td>
                          {/* points */}
                          <td>
                            {(
                              orderProduct.is_advert ||
                              this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY ||
                              price === 0
                            ) ? 0
                              : orderProduct.product.points * orderProduct.quantity * (isReturnOrRefund ? -1 : 1)
                            }
                          </td>
                          {/* total price */}
                          <td>
                            {(price * orderProduct.quantity).toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                            &nbsp;
                            {this.props.countryCurrencyInfo.currency}
                          </td>
                          {/* is advert */}
                          <td>
                            <input
                              type={"checkbox"}
                              tabIndex={-1}
                              checked={orderProduct.is_advert || false}
                              onChange={e => this.handleProductIsAdvertChange(e, i)}
                            />
                          </td>
                          {/* remove */}
                          <td>
                            {(!orderProduct.hasOwnProperty("is_removable") ||
                              (orderProduct.hasOwnProperty("is_removable") && orderProduct.is_removable)
                            ) && (
                              <button
                                type={"button"}
                                className={"btn text-danger p-1 btn-text-lg"}
                                onClick={() => this.handleRemoveProduct(i)}
                                title={"Odstranit produkt"}
                                disabled={this.isOrderFormDisabled() || this.isProductDisabled()}
                              >
                                <i
                                  className={"far fa-trash-alt fa-xs"}
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                        {!this.props.isReturn &&
                        !this.isPromoProduct(orderProduct) &&
                        this.props.order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY &&
                        this.props.order._promoProducts.some(promoProduct => promoProduct._promo_product_id === orderProduct.product_id) && (
                          <tr>
                            <td
                              colSpan={9}
                            >
                              <label>
                                <input
                                  type={"radio"}
                                  name={`promo-${this.props.order.id}-${orderProduct.product_id}`}
                                  value={"nonsense"}
                                  className={"mr-1"}
                                  checked={!this.isPromoProductRejected(orderProduct.product.discount_group)}
                                  onChange={() => this.setPromoProductRejection(orderProduct.product.discount_group, false)}
                                />
                                {this.getPromoTitle(orderProduct.product_id)}
                                {this.getPromoNames(orderProduct.product_id, this.props.countryId)}
                              </label>
                              <label>
                                <input
                                  type={"radio"}
                                  name={`promo-${this.props.order.id}-${orderProduct.product_id}`}
                                  value={"nonsense"}
                                  className={"mr-1"}
                                  checked={this.isPromoProductRejected(orderProduct.product.discount_group)}
                                  onChange={() => this.setPromoProductRejection(orderProduct.product.discount_group, true)}
                                />
                                Nechci akci
                              </label>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    )
                  })}
                  {!this.props.isReturn && (
                    <tr>
                      {/* add new product */}
                      <td
                        colSpan={9}
                      >
                        <div
                          className={this.props.order.order_product.size === 0 ? "select-required" : ""}
                        >
                          <AsyncSelect
                            value={{}}
                            className={"react-select_border product-search-input"}
                            loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                            onChange={this.handleProductSearchSelect.bind(this)}
                            noOptionsMessage={() => null}
                            loadingMessage={() => null}
                            required={true}
                            styles={ReactSelectCustomStyles}
                            components={{
                              Option: ReactSelectTableOption
                            }}
                            ref={this.searchProductInputRef}
                            isDisabled={this.isProductDisabled()}
                          />

                          <button
                            type={"button"}
                            className={"btn btn-primary ml-3 align-middle"}
                            onClick={this.handleCatalogueOpenClick.bind(this)}
                          >Katalog
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                  </tbody>
                  <tfoot
                    className={styles.productTableFooter}
                  >
                  <tr>
                    <td/>
                    <td/>
                    <td
                      className={"pl-5"}
                    >{orderQuantity}</td>
                    <td/>
                    <td/>
                    <td>{orderPoints}</td>
                    <td>{orderPrice.toFixed(this.props.countryCurrencyInfo.round_decimal_places)} {this.props.countryCurrencyInfo.currency}</td>
                    <td/>
                    <td/>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </fieldset>
          </div>
        </fieldset>
        <div
          className={"row"}
        >
          <div
            className={"col-6"}
          >
            {this.canCreateRefund() && (
              <div
                className={"btn btn-primary cursor-pointer"}
                onClick={() => this.props.orderReturnHandler(this.props.order.id)}
              >Vytvořit dobropis</div>
            )}
          </div>
          <div
            className={"col-6"}
          >
            <div
              className={"form-group text-right"}
            >
              {!this.props.isReturn && (
                <button
                  className={"btn btn-primary"}
                  onClick={this.props.addOrderHandler}
                >Přidat objednávku</button>
              )}
              {!this.props.isReturn && this.props.order.order_type_id === "refund" && (
                <div
                  className={"btn btn-danger ml-3"}
                  onClick={this.handleDeleteRefund.bind(this)}
                >Smazat dobropis</div>
              )}
              {!this.props.isReturn && !this.props.isSingle && this.props.order.status !== "paid" && this.props.order.order_type_id !== "refund" && (
                <button
                  type={"button"}
                  className={"btn btn-danger ml-3"}
                  onClick={this.handleDeleteOrder.bind(this)}
                >Smazat objednávku</button>
              )}
            </div>
          </div>
        </div>
        <div
          className={"row"}
        >
          <div
            className={"col-12 pb-3"}
          >
            <Collapsible
              open={true}
              name={"Zákaznické body"}
            >
              <table
                className={"table mb-0"}
              >
                <thead>
                <tr
                  className={"bg-light"}
                >
                  <th>Vlastní body</th>
                  <th>Celkové body</th>
                  <th>Procento bonusu</th>
                  <th>Chybějící body do vyššího bonusu</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{this.getPointsStatus("points")}</td>
                  <td>{this.getPointsStatus("total_points")}</td>
                  <td>{this.getPointsStatus("bonus")}</td>
                  <td>{this.getPointsStatus("missing_points_to_next_bonus")}</td>
                </tr>
                </tbody>
              </table>
            </Collapsible>
            <Collapsible
              name={"Poznámky"}
              highlight={this.someNoteIsWarning()}
              open={this.someNoteIsPublic() || this.someNoteIsWarning()}
            >
              <div
                className={"border p-3 my-2"}
              >
                {!this.someNotes() && "Žádné poznámky"}
                <table
                  className={"table mb-0"}
                >
                  <tbody>
                  {/* customer notes */}
                  {this.props.order.customer.note && this.props.order.customer.note.map((note, i) =>
                    <Note
                      key={note.id || i}
                      id={note.id || i}
                      index={i}
                      note={note}
                      deleteHandler={this.handleDeleteCustomerNote.bind(this)}
                    />
                  )}
                  {/* order notes */}
                  {this.props.order.note && this.props.order.note.map((note, i) =>
                    <Note
                      key={note.id || i}
                      id={note.id || i}
                      index={i}
                      note={note}
                      deleteHandler={this.handleDeleteOrderNote.bind(this)}
                    />)}
                  </tbody>
                </table>
              </div>

              <div
                className={"form-group row"}
              >
                <label
                  className={"col-2 col-form-label"}
                >Napiš poznámku:</label>
                <div
                  className={"col-10"}
                >
                  <input
                    type={"text"}
                    className={"form-control"}
                    onChange={this.handleNoteChange.bind(this)}
                    value={this.props.order._note || ""}
                  />
                </div>

                <div
                  className={"col-12 text-right mt-2"}
                >
                  <button
                    className={"btn btn-primary mr-2"}
                    type={"button"}
                    disabled={!this.props.order.customer_id}
                    onClick={this.handleAddOrderNormalNote.bind(this)}
                  >Přidat obyčejnou
                  </button>

                  <button
                    className={"btn btn-danger mr-5"}
                    type={"button"}
                    disabled={!this.props.order.customer_id}
                    onClick={this.handleAddOrderWarnNote.bind(this)}
                  >Přidat varovnou
                  </button>

                  <button
                    className={"btn btn-warning"}
                    type={"button"}
                    disabled={!this.props.order.customer_id}
                    onClick={this.handleAddPublicNote.bind(this)}
                  >Přidat pro zákazníka
                  </button>
                </div>
              </div>
            </Collapsible>
            <Collapsible
              name={"Historie objednávek"}
            >
              <BootstrapTable
                bootstrap4={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.getOrderHistoryColumns()}
                data={this.props.order._orderHistory}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </Collapsible>
          </div>
        </div>

        <ProductCatalogue
          isOpen={this.props.order._isCatalogueOpen}
          productTree={this.props.productTree}
          productCategories={this.props.productCategories}
          orderId={this.props.order.id}
          closeHandler={this.handleCatalogueCloseClick.bind(this)}
          selectProductHandler={this.handleCatalogueProductSelect.bind(this)}
        />
      </div>
    )
  }

  scrollToItselfTop() {
    this.itselfRef.current.scrollIntoView({
      block: "start",
    })
  }

  focusSearchProductInput() {
    if (this.searchProductInputRef.current) {
      this.searchProductInputRef.current.focus()
    }
    this.productTableRef.current.scrollIntoView({
      block: "center"
    })
  }

  handlePaymentMethodChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      payment_method_id: parseInt(e.target.value)
    })
  }

  handlePriceTypeChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      price_type: e.target.value
    })
  }

  handleDueDaysChange(option) {
    if (option === null) {
      this.props.setOrderStateHandler(this.props.order.id, {
        due_days: 0,
      })
    } else {
      let value = parseInt(option.value, 10)

      if (!isNaN(value)) {
        this.props.setOrderStateHandler(this.props.order.id, {
          due_days: value,
          payment_method_id: value === 0
            ? this.props.order.payment_method_id
            : PAYMENT_METHOD.BANK_TRANSFER,
        })
      }
    }
  }

  handleCustomerChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}&type_id=!${CUSTOMER_TYPE.REMOVED}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleCustomerSelect(option) {
    let newOption = Object.assign({}, option)

    this.props.setOrderStateHandler(this.props.order.id, {
      customer: Object.assign(newOption, {
        value: option.id,
        label: formatCustomerName(option),
      }),
      customer_id: option.id,
      billing_address_customer_name: option.billing_address_customer_name,
      billing_address_billing_name: option.billing_address_billing_name || option.billing_address_customer_name,
      billing_address_street: option.billing_address_street,
      billing_address_city: option.billing_address_city,
      billing_address_post_code: option.billing_address_post_code,
      billing_address_country_code: option.billing_address_country_code,
      delivery_method_id: this.props.order.delivery_method_id,
      payment_method_id: option.billing_address_country_code === "CZ"
        ? this.props.order.payment_method_id
        : PAYMENT_METHOD.BANK_TRANSFER,
    })
  }

  handleIsSimpleInvoiceChange(e) {
    let isChecked = e.target.checked
    this.props.setOrderStateHandler(this.props.order.id, {
      is_simple_invoice: isChecked,
      // simple invoice has always customers prices (retail prices)
      price_type: isChecked ? "customer" : "customer_type",
    })
  }

  handleNoteChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      _note: e.target.value,
    })
  }

  handleAddOrderWarnNote() {
    if (this.props.order._note) {
      this.props.setOrderStateHandler(this.props.order.id, {
        note: this.props.order.note.push({
          value: this.props.order._note,
          is_warn: true,
          is_pub: false,
          created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
          user_id: jwtDecode(RBAC.getToken()).id,
        }),
        _note: "",
      })
    }
  }

  handleAddOrderNormalNote() {
    if (this.props.order._note) {
      this.props.setOrderStateHandler(this.props.order.id, {
        note: this.props.order.note.push({
          value: this.props.order._note,
          is_warn: false,
          is_pub: false,
          created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
          user_id: jwtDecode(RBAC.getToken()).id,
        }),
        _note: "",
      })
    }
  }

  handleAddPublicNote() {
    if (this.props.order._note) {
      this.props.setOrderStateHandler(this.props.order.id, {
        note: this.props.order.note.push({
          value: this.props.order._note,
          is_warn: false,
          is_pub: true,
          created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
          user_id: jwtDecode(RBAC.getToken()).id,
        }),
        _note: "",
      })
    }
  }

  handleDeleteOrderNote(i) {
    this.props.setOrderStateHandler(this.props.order.id, {
      note: this.props.order.note.delete(i)
    })
  }

  handleDeleteCustomerNote(i) {
    this.props.deleteCustomerNoteHandler(this.props.order.id, this.props.order.customer_id, i)
  }

  handleProductSearchChange(val, cb) {
    if (val.length < 4) {
      cb([])
    } else {
      API.get(`/products?limit=0&offset=0&search=${val}&is_is_order_visible=1&sort=unit_count`)
        .then(({data}) => {
          data = data.objects.map(item => Object.assign(item, {
            value: item.id,
            label: getProductNameByCountry(item, this.props.countryId),
          }))
          cb(data)
        })
    }
  }

  handleProductSearchSelect(option) {
    if (this.props.order.order_product.toArray().some(orderProduct => orderProduct.product.id === option.id)) {
      return alert("Objednávka již obsahuje vybraný produkt.")
    }

    if (
      option.stock &&
      option.stock.length > 0
    ) {
      let quantity = this.props.getProductStockQuantity(option, this.props.order.id)

      if (quantity <= 0) {
        return alert("Nelze objednat více kusů než je na skladě.")
      }
    }

    let product = Object.assign({}, {
      quantity: 1,
      product_id: option.id,
      product: option,
      is_advert: false,
      _focus: true,
    })

    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: this.props.order.order_product.push(product),
    }, this.updatePromoProductsData.bind(this))

    if (
      this.props.order._membershipData &&
      this.props.order._membershipData.is_membership_active &&
      product.product.code === PRODUCT_CODE.MEMBERSHIP
    ) {
      // add products first and then show alert
      setTimeout(() => {
        alert("Zákazník má člentsví zaplacené.")
      }, 250)
    }
  }

  handleCatalogueOpenClick() {
    this.props.setOrderStateHandler(this.props.order.id, {
      _isCatalogueOpen: true,
    })
  }

  handleCatalogueCloseClick() {
    this.props.setOrderStateHandler(this.props.order.id, {
      _isCatalogueOpen: false,
    })
  }

  handleCatalogueProductSelect(product) {
    let orderProductIndex = this.props.order.order_product.findIndex(orderProduct => orderProduct.product_id === product.id)

    if (orderProductIndex > -1) { // product already exists in order -> update quantity
      let orderProduct = this.props.order.order_product.get(orderProductIndex)
      orderProduct.quantity++

      this.props.setOrderStateHandler(this.props.order.id, {
        order_product: this.props.order.order_product.update(orderProductIndex, () => orderProduct),
        _isCatalogueOpen: false,
      }, this.updatePromoProductsData.bind(this))
    } else { // add new product to order
      // we can't use product passed as argument, because it is product from /productTree
      // endpoint and this product does not have discount groups...
      API.get(`/products/${product.id}`)
        .then(({data}) => {

          product = Object.assign({}, {
            quantity: 1,
            product_id: product.id,
            product: data,
            is_advert: false,
            _focus: true,
          })

          this.props.setOrderStateHandler(this.props.order.id, {
            order_product: this.props.order.order_product.push(product),
            _isCatalogueOpen: false,
          }, this.updatePromoProductsData.bind(this))
        })
    }
  }

  handleProductQuantityChange(option, productIndex) {
    let value = parseInt(option.value)

    if (isNaN(value)) {
      return
    }

    let product = Object.assign({}, this.props.order.order_product.get(productIndex), {
      quantity: value
    })

    if (
      product.product.stock &&
      product.product.stock.length > 0
    ) {
      let quantity = this.props.getProductStockQuantity(product.product, this.props.order.id)

      if (quantity < value) {
        return alert("Nelze objednat více kusů než je na skladě.")
      }
    }

    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: this.props.order.order_product.update(productIndex, () => product),
    }, this.updatePromoProductsData.bind(this))

    this.focusSearchProductInput()
  }

  handleRemoveProduct(productIndex) {
    let orderProduct = this.props.order.order_product.get(productIndex)
    let isMembershipProduct = orderProduct.product.code === PRODUCT_CODE.REGISTRATION ||
      orderProduct.product.code === PRODUCT_CODE.MEMBERSHIP

    if (
      isMembershipProduct &&
      !this.props.isReturn
    ) {
      if (!window.confirm("Opravdu chcete odstranit členský poplatek?")) {
        return
      }
    }

    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: this.props.order.order_product.delete(productIndex),
      _promoProducts: [],
    }, this.updatePromoProductsData.bind(this))
  }

  handleIsAdvertChange(e) {
    let state = e.target.checked
    let products = this.props.order.order_product.toArray().map(product => {
      product.is_advert = state
      return product
    })
    // TODO: workaround - probably deep changes wont update...
    setTimeout(() => {
      this.props.setOrderStateHandler(this.props.order.id, {
        _isAdvert: state,
        order_product: List(products),
      })
    }, 0)
  }

  handleProductIsAdvertChange(e, productIndex) {
    let product = Object.assign({}, this.props.order.order_product.get(productIndex), {
      is_advert: e.target.checked
    })
    // TODO: workaround - probably deep changes wont update...
    setTimeout(() => {
      this.props.setOrderStateHandler(this.props.order.id, {
        order_product: this.props.order.order_product.update(productIndex, () => product),
      })
    }, 0)
  }

  handleBillingAddressBillingNameChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_billing_name: e.target.value
    })
  }

  handleBillingAddressStreetChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_street: e.target.value
    })
  }

  handleBillingAddressCityChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_city: e.target.value
    })
  }

  handleBillingAddressPostCodeChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_post_code: e.target.value
    })
  }

  handleBillingAddressCountryCodeChange(e) {
    let countryCode = e.target.value
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_country_code: countryCode,
      payment_method_id: countryCode === "CZ" ? this.props.order.payment_method_id : 2, // bank transfer
    })
  }

  handleDeleteOrder() {
    this.props.deleteOrderHandler(this.props.order.id)
  }

  handleDeleteRefund() {
    this.props.deleteRefundHandler(this.props.order.id)
  }

  getOrderHistoryColumns() {
    return [{
      text: "Č. zakázky",
      dataField: "sale",
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <NavLink
          to={`/sales/${row.sale_id}/detail`}
        >{cell.sale_no || ""}</NavLink>
      ),
    }, {
      text: "Č. objednávky",
      dataField: "invoice_no",
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <NavLink
          to={`/sales/${row.sale_id}/detail/${cell}`}
        >{cell || ""}</NavLink>
      ),
    }, {
      text: "Cena",
      dataField: "price",
    }, {
      text: "Body",
      dataField: "bonus_points",
    }, {
      text: "Čas vytvoření",
      dataField: "created_at",
      formatter: (cell, row, rowIndex, formatExtraData) => (
        moment(cell).format("DD. MM. YYYY")
      )
    }]
  }

  getPointsStatus(type) {
    if (
      !this.props.order.customer.points ||
      this.props.order.customer.points.length === 0
    ) {
      return 0
    }

    let currentDate = moment().format("YYYY-M")

    if (currentDate !== `${this.props.order.customer.points[0].year}-${this.props.order.customer.points[0].month}`) {
      return 0
    }

    if (type === "missing_points_to_next_bonus") {
      let totalPoints = this.props.order.customer.points[0].total_points
      let bonusPointsLimits = this.bonusPointsLimits.filter(points => points > totalPoints)

      if (bonusPointsLimits.length > 0) {
        return bonusPointsLimits[0] - totalPoints
      }

      return 0
    }

    return this.props.order.customer.points[0][type]
  }

  someNoteIsWarning() {
    return (this.props.order.customer.note && this.props.order.customer.note.some(note => note.is_warn)) ||
      (this.props.order.note && this.props.order.note.some(note => note.is_warn))
  }

  someNoteIsPublic() {
    return this.props.order.note && this.props.order.note.some(note => note.is_pub)
  }

  someNotes() {
    return (this.props.order.customer.note && this.props.order.customer.note.length !== 0) ||
      (this.props.order.note && this.props.order.note.size !== 0)
  }

  getProductPrice(orderProduct, realValue = false) {
    if (orderProduct.is_advert) {
      return 0
    }

    // promo product
    if (this.isPromoProduct(orderProduct)) {
      return 0
    }

    if (
      this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY &&
      !realValue
    ) {
      return 0
    }

    let price = 0

    // base price
    price = getProductPrice(orderProduct, this.props.order.customer, this.props.order.price_type)
    price = parseInt(price, 10)

    if (this.props.hasPricesWithoutVAT) {
      price = price / (1 + (orderProduct.product.vat_rate / 100))
    }

    // discounts (not a promo products)
    orderProduct.product.discount_group.forEach(discountGroup => {
      if (
        !isPromotionDiscountGroup(discountGroup.product_discount) &&
        this.orderContainsAllDiscountGroupProductsForDiscount(discountGroup) &&
        moment().isBetween(discountGroup.valid_from, discountGroup.admin_valid_to)
      ) {
        discountGroup.product_discount
          .filter(productDiscount => productDiscount.product_id === orderProduct.product_id)
          .forEach(productDiscount => {
            // % discount
            if (productDiscount.type === DISCOUNT_TYPE.PERCENT_DISCOUNT) {
              price = Math.ceil(price - (price * productDiscount.value / 100))
            }

            // fixed value discount
            if (productDiscount.type === DISCOUNT_TYPE.FIXED_DISCOUNT) {
              price = price - productDiscount.value
            }

            // fixed price
            if (productDiscount.type === DISCOUNT_TYPE.FIXED_PRICE) {
              price = productDiscount.value
            }
          })
      }
    })

    return (price * this.props.countryCurrencyInfo.exchange_rate).toFixed(2)
  }

  getProductPoints(orderProduct, isReturnOrRefund) {
    if (orderProduct.is_advert) {
      return 0
    }

    if (this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY) {
      return 0
    }

    let points = orderProduct.product.points * orderProduct.quantity

    if (isReturnOrRefund) {
      points *= -1
    }

    return points
  }

  // check if order contains all required discount group products
  // works only for discounts
  orderContainsAllDiscountGroupProductsForDiscount(discountGroup) {
    let orderContainsAllDiscountGroupProducts = true

    discountGroup.product_discount.forEach(productDiscount => {
      if (
        !this.props.order.order_product
          .toArray()
          .some(orderProduct => orderProduct.product_id === productDiscount.product_id)
      ) {
        orderContainsAllDiscountGroupProducts = false
      }
    })

    return orderContainsAllDiscountGroupProducts
  }

  // check if order contains all required discount group products
  // works only for promotions
  orderContainsAllDiscountGroupProductsForPromotion(discountGroup) {
    let orderContainsAllDiscountGroupProducts = true

    discountGroup.product_discount
      .filter(productDiscount => productDiscount.value === 0)
      .forEach(productDiscount => {
        // get number of paid products. eg. for 2 same products and one is free
        // this won't affect promo actions like 1+1, etc...
        let requiredQuantity = discountGroup.product_discount
          .filter(pDiscount => pDiscount.value === 0)
          .filter(pDiscount => pDiscount.product_id === productDiscount.product_id).length

        let orderProduct = this.props.order.order_product
          .toArray()
          .find(orderProduct => orderProduct.product_id === productDiscount.product_id)

        if (
          !orderProduct ||
          orderProduct.quantity < requiredQuantity
        ) {
          orderContainsAllDiscountGroupProducts = false
        }
      })

    return orderContainsAllDiscountGroupProducts
  }

  hasDiscount(orderProduct) {
    if (orderProduct.product.discount_group.length === 0) {
      return false
    }

    return !orderProduct.product.discount_group
      .some(discountGroup => isPromotionDiscountGroup(discountGroup.product_discount))
  }

  updatePromoProductsData() {
    let discountGroupIds = []
    let promoProductsIds = []

    this.props.order.order_product.forEach(orderProduct => {
      if (orderProduct.product.discount_group.length > 0) {
        let promoDiscountGroups = orderProduct.product.discount_group
          .filter(discountGroup => isPromotionDiscountGroup(discountGroup.product_discount))

        if (promoDiscountGroups.length > 0) {
          promoDiscountGroups.forEach(discountGroup => {
            // make sure all products are present in order
            if (this.orderContainsAllDiscountGroupProductsForPromotion(discountGroup)) {
              let productIds = discountGroup.product_discount
                .filter(productDiscount => productDiscount.value === 100)
                .map(productDiscount => productDiscount.product_id)
              let paidProduct = discountGroup.product_discount
                .find(productDiscount => productDiscount.value === 0)

              // get only unique discount groups
              if (discountGroupIds.indexOf(discountGroup.id) === -1) {
                promoProductsIds.push({
                  paidProductId: paidProduct.product_id, // add promotion to first paid product
                  freeProductIds: productIds,
                })
                discountGroupIds.push(discountGroup.id)
              }
            }
          })
        }
      }
    })

    this.getPromoProductsData(promoProductsIds)
  }

  getPromoProductsData(promoProductsIds) {
    let allFreeProductIds = promoProductsIds
      .map(item => item.freeProductIds)
      .join("|")
    let promoProducts = []

    if (allFreeProductIds !== "") {
      API.get(`/products?id==${allFreeProductIds}`)
        .then(({data}) => {
          promoProductsIds.forEach(promoProductsId => {
            let paidProductId = promoProductsId.paidProductId
            let freeProductIds = promoProductsId.freeProductIds.join(",")
            let freeProducts = data.objects.filter(product => freeProductIds.indexOf(product.id) > -1)

            freeProducts.map(freeProduct => {
              let item = Object.assign({}, freeProduct, {
                _promo_product_id: paidProductId
              })

              promoProducts.push(item)
            })
          })

          this.props.setOrderStateHandler(this.props.order.id, {
            _promoProducts: promoProducts,
          })
        })
    }
  }

  getPromoTitle(productId) {
    let title = ""
    let promoProduct = this.props.order._promoProducts.find(promoProduct => promoProduct._promo_product_id === productId)

    //debugger
    promoProduct.discount_group.forEach(discountGroup => {
      let paidProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 0)
      let freeProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 100)
      title += `AKCE ${paidProducts.length}+${freeProducts.length} - `
    })

    return title
  }

  getPromoNames(productId, countryId) {
    return this.props.order._promoProducts
      .filter(promoProduct => promoProduct._promo_product_id === productId)
      .map(promoProduct => `${getProductNameByCountry(promoProduct, countryId)} ${this.getPromoQuantity(productId, promoProduct)}× zdarma`)
      .join(", ")
  }

  getPromoQuantity(productId, promoProduct) {
    let minQuantity = Infinity

    promoProduct.discount_group
      // if there is multiple promo actions, we need only discount groups which contains paid product
      .filter(discountGroup => discountGroup.product_discount.some(productDiscount => productDiscount.product_id === productId))
      .forEach(discountGroup => {
        let paidProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 0)

        paidProducts.forEach(paidProduct => {
          let orderProduct = this.props.order.order_product.find(orderProduct => orderProduct.product_id === paidProduct.product_id)
          // there can be promos like 2+1 (2 same products and 1 free). so get number of paid product quantity
          let paidProductRequiredQuantity = paidProducts.filter(fullPriceProduct => fullPriceProduct.product_id === paidProduct.product_id).length

          if (orderProduct) {
            let quantity = Math.floor(orderProduct.quantity / paidProductRequiredQuantity)

            if (quantity < minQuantity) {
              minQuantity = quantity
            }
          }
        })
      })

    return minQuantity
  }

  isPromoProductRejected(discountGroups) {
    if (discountGroups.length > 0) {
      let orderProductDiscountGroup = this.props.order.order_product_discount_group.find(orderProductDiscountGroup => {
        return orderProductDiscountGroup.product_discount_group_id === discountGroups[0].id
      })

      return orderProductDiscountGroup && orderProductDiscountGroup.is_rejected
    }

    return false
  }

  setPromoProductRejection(discountGroups, isRejected) {
    if (discountGroups.length > 0) {
      let discountGroupId = discountGroups[0].id
      let found = false
      let orderProductDiscountGroups = this.props.order.order_product_discount_group.map(orderProductDiscountGroup => {
        if (orderProductDiscountGroup.product_discount_group_id === discountGroupId) {
          orderProductDiscountGroup.is_rejected = isRejected
          found = true
        }

        return orderProductDiscountGroup
      })

      if (!found) {
        orderProductDiscountGroups.push({
          product_discount_group_id: discountGroupId,
          is_rejected: isRejected,
        })
      }
      this.props.setOrderStateHandler(this.props.order.id, {
        order_product_discount_group: orderProductDiscountGroups,
      })
    }
  }

  isOrderFormDisabled() {
    if (this.props.isSaleLocked) {
      return true
    }

    if (this.props.isReturn) {
      return false
    }

    if (
      this.props.order.status === "storno" ||
      this.props.order.paid_date
    ) {
      return true
    }

    return false
  }

  isProductDisabled() {
    if (
      !this.props.isReturn &&
      this.props.isDetail &&
      this.props.isPromo4Plus1Active &&
      this.props.saleStatus !== "open"
      // this.props.order.order_product.reduce((acc, orderProduct) => {
      //   return acc + orderProduct.quantity
      // }, 0) >= 5
    ) {
      return true
    }

    return false
  }

  // Bonus money is the money you can use to pay the order
  //
  // It consist of 3 numbers. Total money, previous years money and current year money.
  // It is fact, that:
  //
  //   total = previous_years + current_year
  //
  // In case order is pay by bonus money, first subtract order price from previous years
  //   and then from current year.
  getBonusMoneyState(type) {
    if (
      this.props.order.customer.account &&
      this.props.order.customer.account.length > 0
    ) {
      let orderPrice = this.props.isDetail
        ? 0
        : this.props.order.order_product.reduce((sum, orderProduct) => {
          return sum + (parseFloat(this.getProductPrice(orderProduct, true)) * orderProduct.quantity)
        }, 0)

      let {
        total_in_year,
        total_out_year,
        total_prev_years,
      } = this.props.order.customer.account[0]

      if (type === "total") {
        let leftover = total_in_year - total_out_year + total_prev_years

        if (this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY) {
          return (leftover - orderPrice).toFixed(2)
        }

        return leftover.toFixed(2)
      }

      if (type === "previous_years") {
        let leftover = total_prev_years - total_out_year

        if (this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY) {
          if (
            leftover < 0 ||
            orderPrice > leftover
          ) {
            return (0).toFixed(2)
          }

          return (leftover - orderPrice).toFixed(2)
        }

        return (leftover < 0 ? 0 : leftover).toFixed(2)
      }

      if (type === "current_year") {
        let prevYearsLeftover = total_prev_years - total_out_year
        let leftover = total_in_year + (prevYearsLeftover <= 0 ? prevYearsLeftover : 0)

        if (
          this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY &&
          prevYearsLeftover - orderPrice <= 0
        ) {
          return (leftover - orderPrice + (prevYearsLeftover <= 0 ? 0 : prevYearsLeftover)).toFixed(2)
        }

        return leftover.toFixed(2)
      }
    }

    return 0
  }

  canCreateRefund() {
    if (
      this.props.isDetail &&
      !this.props.isReturn &&
      this.props.order.order_type_id !== "return" &&
      this.props.order.order_type_id !== "refund" &&
      this.props.order.paid_date !== null &&
      this.props.order.status !== "storno"
    ) {
      return true
    }

    return false
  }

  getProductQuantityOptions(max) {
    return [...Array(max).keys()].map(key => ({
      label: key + 1,
      value: key + 1,
    }))
  }

  updateProductsQuantities() {
    let orderProducts = this.props.order.order_product
      .toArray()
      // make sure we do not overwrite parent order
      .map(orderProduct => Object.assign({}, orderProduct, {
        quantity: this.getOrderProductMaxQuantity(orderProduct)
      }))

    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: List(orderProducts),
    })
  }

  getOrderProductMaxQuantity(orderProduct) {
    // For refunds:
    //   orderId = parent order id
    //   order.id = return order id
    let findProduct = (product) => product.product_id === orderProduct.product_id
    let parentOrder = this.props.getParentOrder(this.props.orderId)
    let existingReturns = this.props.getParentOrderReturns(this.props.orderId, this.props.order.id)
    let originalProduct = parentOrder.order_product.toArray().find(findProduct)

    if (originalProduct) {
      let alreadyReturnedQuantity = existingReturns.reduce((acc, existingReturn) => {
        let product = existingReturn.order_product.toArray().find(findProduct)

        if (product) {
          return acc + product.quantity
        }

        return acc
      }, 0)

      let quantity = originalProduct.quantity - alreadyReturnedQuantity

      return quantity < 0 ? 0 : quantity
    }

    return 0
  }

  isPromoProduct(orderProduct) {
    return orderProduct.hasOwnProperty("discount") &&
      orderProduct.hasOwnProperty("price") &&
      orderProduct.discount === orderProduct.price
  }

  addFeeProducts() {
    if (this.props.order.has_to_pay_fees) {
      API.get(`/products?code==${PRODUCT_CODE.PACKING_CZECH_POST}|${PRODUCT_CODE.HANDLING_FEE}|${PRODUCT_CODE.DELIVERY}&status=deleted`)
        .then(({data}) => {
          let orderProducts = null
          let feeProducts = data.objects

          if (this.props.saleFees.delivery_price_vat > 0) {
            let feeProduct = feeProducts.find(feeProduct => feeProduct.code === PRODUCT_CODE.DELIVERY)

            if (feeProduct) {
              let product = Object.assign({}, {
                quantity: 1,
                product_id: feeProduct.id,
                product: feeProduct,
                is_advert: false,
              })

              orderProducts = this.props.order.order_product.push(product)
            }
          }

          if (this.props.saleFees.packaging_price_vat > 0) {
            let feeProduct = feeProducts.find(feeProduct => feeProduct.code === PRODUCT_CODE.PACKING_CZECH_POST)

            if (feeProduct) {
              let product = Object.assign({}, {
                quantity: 1,
                product_id: feeProduct.id,
                product: feeProduct,
                is_advert: false,
              })

              orderProducts = (orderProducts || this.props.order.order_product).push(product)
            }
          }

          if (this.props.saleFees.handling_fee_vat > 0) {
            let feeProduct = feeProducts.find(feeProduct => feeProduct.code === PRODUCT_CODE.HANDLING_FEE)

            if (feeProduct) {
              feeProduct.price = this.props.saleFees.handling_fee_vat
              feeProduct.prices = {
                consultant: this.props.saleFees.handling_fee_vat,
                customer_with_discount: this.props.saleFees.handling_fee_vat,
                customer: this.props.saleFees.handling_fee_vat,
              }
              let product = Object.assign({}, {
                quantity: 1,
                product_id: feeProduct.id,
                product: feeProduct,
                price: this.props.saleFees.handling_fee_vat,
                is_advert: false,
              })

              orderProducts = (orderProducts || this.props.order.order_product).push(product)
            }
          }

          if (orderProducts !== null) {
            this.props.setOrderStateHandler(this.props.order.id, {
              order_product: orderProducts,
            })
          }
        })
    }
  }
}
