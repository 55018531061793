import React from "react"
import Spinner from "../Spinner/Spinner"
import styles from "./styles.module.css"

const SpinnerWithOverlay = () => (
  <div
    className={styles.overlay}
  >
    <Spinner
      styles={{
        width: 50,
        height: 50,
        left: "50%",
        top: "50%",
        position: "fixed",
        transform: "translateX(-50%) translateY(-50%)",
      }}
    />
  </div>
)

export default SpinnerWithOverlay
