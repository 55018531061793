import React, {Component} from "react"
import {Editor, EditorState, RichUtils} from "draft-js"
import {convertFromHTML, convertToHTML} from "draft-convert"
import "draft-js/dist/Draft.css"
import API from "../../API"

export default class LocalTextForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      local_key: "",
      editorState: EditorState.createEmpty()
    }
    this.id = parseInt(props.match.params.id, 10)
  }

  componentDidMount() {
    this.getLocalText()
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          <div
            className={"col-12"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group"}
              >
                <div
                  className={"mb-3"}
                >
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorBoldClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-bold"}
                    />
                  </button>
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorItalicClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-italic"}
                    />
                  </button>
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorUnderlineClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-underline"}
                    />
                  </button>

                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorListClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-list-ul"}
                    />
                  </button>
                </div>

                <div
                  className={"editor"}
                >
                  <Editor
                    editorState={this.state.editorState}
                    onChange={this.handleEditorChange.bind(this)}
                    handleKeyCommand={this.handleKeyCommand.bind(this)}
                  />
                </div>
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </>
    )
  }

  getLocalText() {
    API.get(`/local_text/${this.id}`)
      .then(({data}) => {
        this.setState({
          local_key: data.local_key,
          editorState: EditorState.createWithContent(convertFromHTML(data.value)),
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    API.put(`/local_text/${this.id}`, {
      local_key: this.state.local_key,
      value: convertToHTML(this.state.editorState.getCurrentContent())
    }).then(() => {
      this.props.history.push("/enums/local-text")
    })
  }

  handleEditorBoldClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"))
  }

  handleEditorItalicClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC"))
  }

  handleEditorUnderlineClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE"))
  }

  handleEditorListClick() {
    this.handleEditorChange(RichUtils.toggleBlockType(this.state.editorState, "unordered-list-item"))
  }

  handleEditorChange(editorState) {
    this.setState({editorState})
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.handleEditorChange(newState)
      return "handled"
    }

    return "not-handled"
  }

  handleCancel() {
    this.props.history.push("/enums/local-text")
  }
}
