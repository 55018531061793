import COUNTRY from "./Enum/Countries"

export default (product, countryId = COUNTRY.CZECH_REPUBLIC, separator = " - ") => {
  if (product.names.length === 0) {
    return ""
  }

  let productName = product.names.find(productName => productName.country_id === countryId)

  if (productName) {

    if (separator === null) {
      return productName.name
    }

    return `${product.code}${separator}${productName.name}`
  }

  return ""
}
