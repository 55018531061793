import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarWarehouse = () => {
  return (
    <Switch>
      <Route
        path={"/warehouse"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/warehouse/sales"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zakázky k vyřízení</NavLink>
            <NavLink
              to={"/warehouse/shipments"}
              className={"nav-link"}
              activeClassName={"active"}
            >Vyřízené zakázky</NavLink>
            <NavLink
              to={"/warehouse/scales"}
              className={"nav-link"}
              activeClassName={"active"}
            >Váha</NavLink>
            <NavLink
              to={"/warehouse/pickup-lists"}
              className={"nav-link"}
              activeClassName={"active"}
            >Předávací protokoly</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarWarehouse
