import React, {Component, Fragment, StrictMode} from "react";
import {HashRouter, Route, Switch, Redirect} from "react-router-dom"
import {createBrowserHistory} from "history"
import cs from "date-fns/locale/cs"
import {registerLocale} from "react-datepicker"
import moment from "moment"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "react-datepicker/dist/react-datepicker.css"
import API from "./API"
import RBAC from "./RBAC/RBAC"
import NavBar from "./NavBar/NavBar"
import LoginForm from "./LoginForm/LoginForm"
import MyLoginForm from "./LoginForm/MyLoginForm"
import Home from "./Home/Home"
import MyHome from "./Home/MyHome"
import SaleList from "./Sales/SaleList"
import SaleForm from "./Sales/SaleForm"
import OrderReturnForm from "./Sales/OrderReturnForm"
import PayOrderList from "./Sales/PayOrderList"
import BoxList from "./Sales/BoxList"
import PayCPOrder from "./Sales/PayCPOrder"
import PayDPDOrder from "./Sales/PayDPDOrder"
import SaleHistory from "./Sales/SaleHistory"
import SaleTracking from "./Sales/SaleTracking"
import CustomerList from "./Customers/CustomerList"
import CustomerForm from "./Customers/CustomerForm"
import CustomerHistory from "./Customers/CustomerHistory"
import SponsorHistory from "./Customers/SponsorHistory"
import Enums from "./Enums/Enums"
import LogList from "./Logs/LogList"
import LogExternalList from "./Logs/LogExternalList"
import Footer from "./Footer/Footer"
import MyFooter from "./Footer/MyFooter"
import CustomerTree from "./Customers/CustomerTree"
import ProductForm from "./Products/ProductForm"
import ProductList from "./Products/ProductList"
import ProductPriceList from "./Products/ProductPriceList"
import ProductHistory from "./Products/ProductHistory"
import DiscountList from "./Products/Discount/DiscountList"
import DiscountForm from "./Products/Discount/DiscountForm"
import PromotionForm from "./Products/Discount/PromotionForm"
import Promotion4Plus1Form from "./Products/Discount/Promotion4Plus1Form"
import GoldCustomerList from "./Customers/GoldCustomer/GoldCustomerList"
import GoldCustomerForm from "./Customers/GoldCustomer/GoldCustomerForm"
import Reports from "./Reports/Reports"
import WareHouse from "./WareHouse/WareHouse"
import RegistrationForm from "./Common/RegistrationForm"
import WatchDogList from "./Products/WatchDogList"
import WatchDogForm from "./Products/WatchDogForm"
import Terms from "./Common/Terms"
import Help from "./Common/Help/Help"
import SMS from "./Communication/SMS/SMS"
import pageTitles from "./fixtures/pageTitle"
import {is_MY} from "./const"
import MyOrderList from "./MySales/MyOrderList"
import MySaleForm from "./MySales/MySaleForm"
import MyCustomerRegistrationForm from "./MyCustomers/MyCustomerRegistrationForm"
import MyCustomerTree from "./MyCustomers/MyCustomerTree"
import MyCustomerList from "./MyCustomers/MyCustomerList"
import MyCustomerDetail from "./MyCustomers/MyCustomerDetail"
import MyProductTree from "./MyProducts/MyProductTree"
import SpinnerWithOverlay from "./Base/SpinnerWithOverlay/SpinnerWithOverlay"
import "./assets/base.css"
import "./assets/table.css"
import "./assets/navbar.css"
import "./assets/form.css"
import "./assets/print.css"
import "./assets/collapsible.css"
import "./assets/tooltip.css"

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOverlayVisible: false,
      isAuthenticated: RBAC.isAuthenticated(),
      authenticatedCustomer: {},
      minutesOfInactivity: 0,
    }
    registerLocale("cs", cs)
  }

  componentDidMount() {
    this.browserHistory = createBrowserHistory()
    this.unlisten = this.browserHistory.listen(() => {
      window.scrollTo(0, 0)

      if (this.state.isAuthenticated) {
        if (!RBAC.hasAccess()) {
          alert("Nemáte dostatečná oprávnění pro vstup do této sekce. Nyní budete přesměrování na hlavní stránku.")
          this.browserHistory.push("#/")
        }
      }

      this.isAuthenticated()
      this.updatePageTitle()

      this.lastActivity = moment()
    })

    this.isAuthenticated()
    this.updatePageTitle()

    API.beforeRequest = this.handleBeforeRequest.bind(this)
    API.beforeResponse = this.handleBeforeResponse.bind(this)
    API.onUnauthorized = this.handleLogout.bind(this)

    this.getAuthenticatedCustomer()
    if (is_MY && this.state.isAuthenticated) {
      this.registerActivity()
    }
  }

  componentWillUnmount() {
    this.unlisten()
    clearInterval(this.lastActivityInterval)
  }

  render() {
    return (
      <StrictMode>
        <HashRouter>
          <Fragment>
            {!this.state.isAuthenticated && window.location.hash.indexOf("#/common") === -1 && (
              <Redirect
                to={"/common/login"}
              />
            )}

            {this.state.isAuthenticated && !RBAC.hasAccess() && (
              <Redirect
                to={"/"}
              />
            )}

            {this.state.isAuthenticated && (
              <NavBar
                logoutHandler={this.handleLogout.bind(this)}
                hasAccessHandler={this.hasURLAccess.bind(this)}
                authenticatedCustomer={this.state.authenticatedCustomer}
                minutesOfInactivity={this.state.minutesOfInactivity}
              />
            )}

            <div
              className={"container-fluid mt-3"}
            >
              <Switch>
                <Route
                  exact
                  path={"/"}
                  component={is_MY ? MyHome : Home}
                />
                <Route
                  path={"/sales/add/:customer_id?"}
                  component={SaleForm}
                  key={"add"}
                />
                <Route
                  path={"/sales/:id/detail/:order_id?"}
                  key={"detail"}
                  component={SaleForm}
                />
                <Route
                  path={"/sales/:id/return/:order_id"}
                  key={"return"}
                  component={OrderReturnForm}
                />
                <Route
                  path={"/sales/pay/cp"}
                  component={PayCPOrder}
                />
                <Route
                  path={"/sales/pay/dpd"}
                  component={PayDPDOrder}
                />
                <Route
                  path={"/sales/pay"}
                  component={PayOrderList}
                />
                <Route
                  path={"/sales/boxes"}
                  component={BoxList}
                />
                <Route
                  path={"/sales/:id/history"}
                  component={SaleHistory}
                />
                <Route
                  path={"/sales/:id/tracking"}
                  component={SaleTracking}
                />
                <Route
                  path={"/sales"}
                  component={SaleList}
                />
                {/* CUSTOMERS */}
                <Route
                  path={"/customers/gold/add"}
                  component={GoldCustomerForm}
                  key={"add"}
                />
                <Route
                  path={"/customers/gold/:id/detail"}
                  component={GoldCustomerForm}
                  key={"detail"}
                />
                <Route
                  path={"/customers/gold"}
                  component={GoldCustomerList}
                />
                <Route
                  path={"/customers/add"}
                  component={CustomerForm}
                  key={"add"}
                />
                <Route
                  path={"/customers/tree/:customer_id?"}
                  component={CustomerTree}
                />
                <Route
                  path={"/customers/:id/detail"}
                  component={CustomerForm}
                  key={"detail"}
                />
                <Route
                  path={"/customers/:id/history"}
                  component={CustomerHistory}
                />
                <Route
                  path={"/customers/:id/sponsor-history"}
                  component={SponsorHistory}
                />
                <Route
                  path={"/customers"}
                  component={CustomerList}
                />
                {/* PRODUCTS */}
                <Route
                  path={"/products/add"}
                  key={"add"}
                  component={ProductForm}
                />
                <Route
                  path={"/products/:id/detail"}
                  key={"detail"}
                  component={ProductForm}
                />
                <Route
                  path={"/products/:id/history"}
                  component={ProductHistory}
                />
                <Route
                  path={"/products/price-list"}
                  component={ProductPriceList}
                />
                <Route
                  path={"/products/watch-dog/:id/detail"}
                  component={WatchDogForm}
                  key={"detail"}
                />
                <Route
                  path={"/products/watch-dog/add"}
                  component={WatchDogForm}
                  key={"add"}
                />
                <Route
                  path={"/products/watch-dog"}
                  component={WatchDogList}
                />
                <Route
                  path={"/products/discount/add-discount/:id?"}
                  component={DiscountForm}
                />
                <Route
                  path={"/products/discount/add-promotion/:id?"}
                  component={PromotionForm}
                />
                <Route
                  path={"/products/discount/add-promotion-n-plus/:id?"}
                  component={Promotion4Plus1Form}
                />
                <Route
                  path={"/products/discount"}
                  component={DiscountList}
                />
                <Route
                  path={"/products"}
                  component={ProductList}
                />
                {/* REPORTS */}
                <Route
                  path={"/reports"}
                  component={Reports}
                />
                {/* ENUMS */}
                <Route
                  path={"/enums"}
                  component={Enums}
                />
                {/* LOGS */}
                <Route
                  path={"/logs/external"}
                  component={LogExternalList}
                />
                <Route
                  path={"/logs"}
                  component={LogList}
                />
                {/* WAREHOUSE */}
                <Route
                  path={"/warehouse"}
                  component={WareHouse}
                />
                {/* COMMUNICATION */}
                <Route
                  path={"/communication/sms/:phone_number?/:customer_id?"}
                  component={SMS}
                />
                {/* COMMON */}
                <Route
                  path={"/common/registration"}
                  component={() => (
                    <RegistrationForm
                      loginHandler={this.handleLogin.bind(this)}
                    />
                  )}
                />
                <Route
                  path={"/common/login"}
                  component={() => is_MY
                    ? (
                      <MyLoginForm
                        loginHandler={this.handleLogin.bind(this)}
                      />
                    ) : (
                      <LoginForm
                        loginHandler={this.handleLogin.bind(this)}
                      />
                    )
                  }
                />
                <Route
                  path={"/common/terms"}
                  component={Terms}
                />
                <Route
                  path={"/common/help"}
                  component={Help}
                />
                {/* MY ORDERS */}
                <Route
                  path={"/my-sales/:id/detail/:order_id?"}
                  key={"detail"}
                  component={MySaleForm}
                />
                <Route
                  path={"/my-sales/add/:customer_id?"}
                  key={"add"}
                  component={MySaleForm}
                />
                <Route
                  path={"/my-sales"}
                  component={props => (
                    <MyOrderList
                      authenticatedCustomer={this.state.authenticatedCustomer}
                      {...props}
                    />
                  )}
                />
                {/* MY CUSTOMERS */}
                <Route
                  path={"/my-customers/registration"}
                  component={MyCustomerRegistrationForm}
                />
                <Route
                  path={"/my-customers/tree/:customerId?"}
                  component={MyCustomerTree}
                />
                <Route
                  path={"/my-customers/:id/detail"}
                  component={MyCustomerDetail}
                />
                <Route
                  path={"/my-customers/customers"}
                  component={MyCustomerList}
                />
                {/* MY PRODUCTS */}
                <Route
                  path={"/my-products/price-list"}
                  component={MyProductTree}
                />
              </Switch>
            </div>

            {this.state.isAuthenticated && (
              is_MY ? <MyFooter/> : <Footer/>
            )}

            {this.state.isOverlayVisible && <SpinnerWithOverlay/>}
          </Fragment>
        </HashRouter>
      </StrictMode>
    )
  }

  handleLogin(token) {
    RBAC.setUserToken(token)

    this.setState({
      isAuthenticated: true,
    }, () => {
      this.browserHistory.push("#/")

      this.getAuthenticatedCustomer()
      if (is_MY) {
        this.registerActivity()
      }
    })
  }

  handleLogout() {
    RBAC.removeUserToken()

    this.setState({
      isAuthenticated: false,
    }, () => {
      clearInterval(this.lastActivityInterval)
      this.browserHistory.push("#/common/login")
    })
  }

  handleBeforeRequest(req) {
    let hash = window.location.hash
    if ((req.method === "post" || req.method === "put") && (hash.indexOf("/add") > -1 || hash.indexOf("/detail") > -1))
      this.setState({
        isOverlayVisible: true,
      })
  }

  handleBeforeResponse(res) {
    if (res.headers && res.headers.authorization) {
      let token = res.headers.authorization.replace("Bearer ", "")
      RBAC.setUserToken(token)
      API.setToken(token)
    }

    let hash = window.location.hash
    if ((res.config.method === "post" || res.config.method === "put") && (hash.indexOf("/add") > -1 || hash.indexOf("/detail") > -1))
      this.setState({
        isOverlayVisible: false,
      })
  }

  hasURLAccess(hash) {
    return RBAC.hasAccess(hash)
  }

  updatePageTitle() {
    let url = window.location.hash.replace("#/", "")
    let key = Object.keys(pageTitles).find(key => RegExp(key).test(url))

    document.title = `${key === undefined ? "" : pageTitles[key]} | ${is_MY ? "Moje Missiva" : "Missiva IS"}`
  }

  isAuthenticated() {
    if (!this.state.isAuthenticated && window.location.hash.indexOf("#/common") === -1)
      this.browserHistory.push("#/common/login")
  }

  getAuthenticatedCustomer() {
    if (this.state.isAuthenticated && is_MY) {
      API.get(`/my/customer/${RBAC.getUser().customer_id}`)
        .then(({data}) => {
          this.setState({
            authenticatedCustomer: data,
          })
        })
    }
  }

  registerActivity() {
    this.lastActivity = moment()
    this.lastActivityInterval = setInterval(this.checkActivity.bind(this), 5000) // 5s
  }

  checkActivity() {
    let minutes = Math.floor(moment.duration(moment().diff(this.lastActivity)).asMinutes())
    if (minutes !== this.state.minutesOfInactivity) {
      this.setState({
        minutesOfInactivity: Math.floor(minutes)
      }, () => {
        if (this.state.minutesOfInactivity >= 60) {
          this.handleLogout()
        }
      })
    }
  }
}
