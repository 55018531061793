import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import CreatableSelect from "react-select/creatable"
import {List} from "immutable"
import {v4 as uuid} from "uuid"
import jwtDecode from "jwt-decode"
import {Prompt} from "react-router-dom"
import moment from "moment"
import API from "../API"
import {cleanState} from "../Utils"
import SaleMenu from "./SaleMenu"
import OrderForm from "./OrderForm"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import styles from "./style.module.css"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import sortInvoiceNumbers from "../Base/sortInvoiceNumbers"
import RBAC from "../RBAC/RBAC"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import PRODUCT_CODE from "../Base/Enum/ProductCode"
import print from "../Base/print"
import COUNTRY from "../Base/Enum/Countries"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import ORDER_METHOD from "../Base/Enum/OrderMethod"
import CONTACT_TYPE from "../Base/Enum/ContactType"
import seq from "../Base/seq"
import formatCustomerName from "../Base/formatCustomerName"
import {filterPaymentMethodByDeliveryMethod} from "../Base/filterPaymentMethod"
import Promo4Plus1Preview from "../Promo4Plus1Preview/Promo4Plus1Preview"
import getProductNameByCountry from "../Base/getProductNameByCountry"
import getProductPrice from "../Base/getProductPrice"

export default class SaleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sale_no: null,
      sale_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      created_at: "",
      delivery_method_id: DELIVERY_METHOD.CZECH_POST,
      payment_method_id: PAYMENT_METHOD.CASH_ON_DELIVERY,
      order_method_id: ORDER_METHOD.PHONE,
      customer_id: null,
      delivery_address_id: null,
      delivery_address: null,
      phone_number: null,
      email: null,
      status: "open",
      total_price: 0,
      delivery_price: 0,
      no_due_price: 0,
      handling_fee: 0,
      weight: null,
      bonus_points: 0,
      customer: {},
      is_free_delivery: false,
      exchange_rate: null,
      order: List([{
        id: uuid(),
        order_type_id: "default",
        payment_method_id: 1,
        price_type: "customer_type",
        due_days: 0,
        customer_id: null,
        billing_address_billing_name: null,
        billing_address_street: null,
        billing_address_city: null,
        billing_address_post_code: null,
        billing_address_country_code: "CZ",
        order_product: List(),
        customer: {},
        status: "created",
        price: 0,
        discount: 0,
        weight: 0,
        bonus_points: 0,
        note: List(),
        is_simple_invoice: false,
        order_product_discount_group: [],
        _orderHistory: [],
        _note: "",
        _isAdvert: false,
        _promoProducts: [],
        _isCatalogueOpen: false,
      }]),
      _deliveryMethods: [],
      _paymentMethods: [],
      _orderMethods: [],
      _countries: [],
      _priceTypes: [],
      _saleStates: [],
      _customerTypes: [],
      _orderTypes: [],
      _delivery_address_customer_name: "",
      _delivery_address_delivery_name: "",
      _delivery_address_city: "",
      _delivery_address_post_code: "",
      _delivery_address_street: "",
      _delivery_address_country_code: "CZ",
      _isLocked: props.match.params.id && !this.props.isReturn,
      _isLockedBy: {},
      _canUnlock: true,
      _focusOrderId: "",
      _productsPrice: 0,
      _productTree: [],
      _productCategories: [],

      _bottomMenuPos: 0,
      _showAddAddressForm: false,
      _haveToWaitForPayment: false,
      _isRecalculatingPoints: false,

      _isPromo4Plus1Active: false,
      _isPromo4Plus1Open: false,
      _isPromo4Plus1MenuVisible: false,
    }

    this.formChanges = false
    this.handleFormChanges = false
  }

  componentDidMount() {
    let {id, order_id, customer_id} = this.props.match.params

    if (id) {
      this.id = parseInt(id, 10)
      this.getSale()

      if (order_id) {
        this.orderId = parseInt(order_id, 10)
      }
    }

    if (customer_id) {
      this.getCustomer(customer_id)
    }

    this.getDeliveryMethods()
    this.getPaymentMethods()
    this.getOrderMethods()
    this.getCountries()
    this.getPriceTypes()
    this.getSaleStates()
    this.getCustomerTypes()
    this.getOrderTypes()
    this.getRecalculationPointsStatus()
    this.getProductCatalogueData()
    this.getDiscountGroups()

    window.addEventListener("scroll", this.handleScroll.bind(this))

    setTimeout(() => {
      this.handleFormChanges = true
    }, 5000)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {id, invoice_no} = prevProps.match.params

    if (id !== this.props.match.params.id || invoice_no !== this.props.match.params.invoice_no) {
      this.componentDidMount()
    }

    // handle non saved changes
    if (
      this.handleFormChanges
      && !this.formChanges
      && JSON.stringify(cleanState(this.state)) !== JSON.stringify(cleanState(prevState))
    ) {
      this.formChanges = true
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  render() {
    let quickTabsOrderCount = 0
    let orderCount = 0

    return (
      <>
        <Prompt
          message={() =>
            this.formChanges &&
            this.state._canUnlock // if user can't unlock sale, he can't make changes
              ? "Máte neuložené změny. Opravdu chcete odejít?"
              : true
          }
        />

        <form
          className={"row form form-sales"}
          onSubmit={this.handleFormSubmit.bind(this)}
          onKeyPress={this.handleFormKeyPress.bind(this)}
        >
          <SaleMenu
            isDetail={!!this.id}
            isTop={true}
            isLocked={this.state._isLocked}
            isLockedBy={this.state._isLockedBy}
            isReturn={this.props.isReturn}
            canUnlock={this.state._canUnlock}
            haveToWaitForPayment={this.state._haveToWaitForPayment}
            shouldPrint={this.shouldPrint()}
            deliveryAndInvoiceUrl={this.state.docs_url}
            saleId={this.id}
            status={this.state.status}
            saleNo={this.state.sale_no}
            email={this.state.email}
            isPersonalPickup={this.isPersonalPickup()}
            formCancelHandler={this.handleCancel.bind(this)}
            deleteHandler={this.handleDelete.bind(this)}
            printReadyHandler={this.handlePrintReady.bind(this)}
            printHandler={this.handlePrint.bind(this)}
            paidReadyHandler={this.handlePaidReady.bind(this)}
            personalPickupPrintHandler={this.handlePersonalPickupPrint.bind(this)}
            unlockHandler={this.handleUnlock.bind(this)}
            returnSaveClickHandler={this.handleReturnSaveClick.bind(this)}
            isPromo4Plus1MenuVisible={this.state._isPromo4Plus1MenuVisible}
            isPromo4Plus1OpenHandler={this.handleIsPromo4Plus1Open.bind(this)}
          />
          <fieldset
            className={"col-12"}
            disabled={this.state._isLocked}
          >
            <div
              className={"row bg-warning mb-2"}
            >
              <div
                className={"col-3"}
              >
                <select
                  className={"form-control my-2"}
                  value={this.state.status}
                  disabled={true}
                >
                  {this.state._saleStates.map(saleState => (
                    <option
                      key={saleState.id}
                      value={saleState.id}
                    >{saleState.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {!this.props.isReturn && (
              <div
                className={"row"}
              >
                <div
                  className={"col-6"}
                >
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Číslo zakázky:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        placeholder={"Číslo zakázky"}
                        value={this.state.sale_no || ""}
                        disabled={true}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>Datum pořízení:</label>
                      <input
                        type={"datetime-local"}
                        className={"form-control"}
                        placeholder={"D. pořízení"}
                        onChange={this.handleSaleDateChange.bind(this)}
                        value={this.state.sale_date.replace(" ", "T")}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>Datum vytvoření:</label>
                      <input
                        type={"datetime-local"}
                        className={"form-control"}
                        placeholder={"D. vytvoření"}
                        value={this.state.created_at ? this.state.created_at.replace(" ", "T") : ""}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Způsob doručení:</label>
                      <select
                        className={"form-control"}
                        required={true}
                        onChange={this.handleDeliveryMethodChange.bind(this)}
                        value={this.state.delivery_method_id || DELIVERY_METHOD.CZECH_POST}
                        autoFocus={true}
                        disabled={this.getCountryId() !== COUNTRY.CZECH_REPUBLIC}
                      >
                        {this.state._deliveryMethods
                          .filter(deliveryMethod => this.state._isLocked ? true : deliveryMethod.id !== DELIVERY_METHOD.GEIS)
                          .map(deliveryMethod => (
                            <option
                              key={deliveryMethod.id}
                              value={deliveryMethod.id}
                            >{deliveryMethod.name}</option>
                          ))}
                      </select>
                    </div>
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Způsob platby:</label>
                      <select
                        className={"form-control"}
                        required={true}
                        onChange={this.handlePaymentMethodChange.bind(this)}
                        value={this.state.payment_method_id || PAYMENT_METHOD.CASH_ON_DELIVERY}
                        disabled={this.getCountryId() !== COUNTRY.CZECH_REPUBLIC}
                      >
                        {this.state._paymentMethods
                          .filter(paymentMethod => paymentMethod.id !== PAYMENT_METHOD.BONUS_MONEY)
                          .filter(paymentMethod => filterPaymentMethodByDeliveryMethod(paymentMethod.id, this.state.delivery_method_id))
                          .map(paymentMethod => (
                            <option
                              key={paymentMethod.id}
                              value={paymentMethod.id}
                            >{paymentMethod.name}</option>
                          ))}
                      </select>
                    </div>
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Způsob objednání:</label>
                      <select
                        className={"form-control"}
                        required={true}
                        onChange={this.handleOrderMethodChange.bind(this)}
                        value={this.state.order_method_id || ORDER_METHOD.PHONE}
                      >
                        {this.state._orderMethods.map(order => (
                          <option
                            key={order.id}
                            value={order.id}
                          >{order.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-2"}
                    >
                      <label>Typ zákazníka:</label>
                      <input
                        className={"form-control"}
                        type={"text"}
                        disabled={true}
                        value={this.getCustomerTypeName(this.state.customer)}
                      />
                    </div>
                    <div
                      className={"form-group select-required col-10"}
                    >
                      <label>Zákazník:</label>
                      <AsyncSelect
                        className={"react-select_border"}
                        value={this.state.customer || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                        onChange={this.handleCustomerSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        components={{
                          Option: ReactSelectTableOption
                        }}
                        styles={ReactSelectCustomStyles}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={"col-6"}
                >
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-8"}
                    >
                      <label>Doručovací adresa:</label>
                      {this.state.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP
                        ? (
                          <input
                            type={"text"}
                            disabled={true}
                            className={"form-control"}
                            value={"Missiva spol. s.r.o., U kostela č. 6, Rtyně nad Bílinou, 417 62"}
                          />
                        ) : (
                          <select
                            className={"form-control"}
                            required={!this.state.delivery_address}
                            onChange={this.handleDeliveryAddressChange.bind(this)}
                            value={this.state.delivery_address_id || ""}
                            disabled={this.state.customer ? !this.state.customer.address : false}
                          >
                            <option
                              value={""}
                            >Vyberte adresu
                            </option>
                            {this.state.customer && this.state.customer.address && this.state.customer.address.map(address => (
                              <option
                                key={address.id}
                                value={address.id}
                              >{`${address.customer_name} (${address.delivery_name || ""}), ${address.street}, ${address.city}, ${address.post_code}, ${this.getCountryName(address.country_code)}`}</option>
                            ))}
                          </select>
                        )
                      }
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>&nbsp;</label>
                      <button
                        className={"btn btn-primary w-100"}
                        onClick={this.handleShowAddAddressForm.bind(this)}
                        disabled={!this.state.customer.id || this.state.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP}
                      >Přidat doručovací adresu
                      </button>
                    </div>
                  </div>
                  {this.state._showAddAddressForm && (
                    <>
                      <div
                        className={"form-group"}
                      >
                        <label>Jméno a příjmení zákazníka:</label>
                        <input
                          type={"text"}
                          className={"form-control"}
                          onChange={this.handleAddressCustomerNameChange.bind(this)}
                          value={this.state._delivery_address_customer_name}
                          required={!this.state.delivery_address_id}
                        />
                      </div>
                      <div
                        className={"form-group"}
                      >
                        <label>Doručovací jméno a příjmení zákazníka:</label>
                        <input
                          type={"text"}
                          className={"form-control"}
                          onChange={this.handleAddressDeliveryNameChange.bind(this)}
                          value={this.state._delivery_address_delivery_name}
                        />
                      </div>
                      <div
                        className={"form-group"}
                      >
                        <label>Ulice včetně čísla popisného, evidenčního nebo i
                          orientačního:</label>
                        <input
                          type={"text"}
                          className={"form-control"}
                          onChange={this.handleDeliveryAddressStreetChange.bind(this)}
                          value={this.state._delivery_address_street}
                          required={!this.state.delivery_address_id}
                        />
                      </div>

                      <div
                        className={"form-row"}
                      >
                        <div
                          className={`form-group col-2 ${this.state.delivery_address_id ? "" : "select-required"}`}
                        >
                          <label>PSČ:</label>
                          <AsyncSelect
                            value={{
                              label: this.state._delivery_address_post_code,
                              value: this.state._delivery_address_post_code,
                            } || {}}
                            loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleDeliveryAddressPostCodeChange.bind(this))}
                            onChange={this.handleDeliveryAddressPostCodeSelect.bind(this)}
                            noOptionsMessage={() => "Nic nenalezeno"}
                            loadingMessage={() => "Načítám"}
                            styles={ReactSelectCustomStyles}
                          />
                        </div>

                        <div
                          className={`form-group col-8 ${this.state.delivery_address_id ? "" : "select-required"}`}
                        >
                          <label>Město:</label>
                          <AsyncSelect
                            value={{
                              label: this.state._delivery_address_city,
                              value: this.state._delivery_address_city,
                            } || {}}
                            loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleDeliveryAddressCityChange.bind(this))}
                            onChange={this.handleDeliveryAddressCitySelect.bind(this)}
                            noOptionsMessage={() => "Nic nenalezeno"}
                            loadingMessage={() => "Načítám"}
                            styles={ReactSelectCustomStyles}
                          />
                        </div>

                        <div
                          className={"form-group col-2"}
                        >
                          <label>Kód země:</label>
                          <select
                            className={"form-control"}
                            required={!this.state.delivery_address_id}
                            onChange={this.handleDeliveryAddressCountryCodeChange.bind(this)}
                            value={this.state._delivery_address_country_code}
                          >
                            {this.state._countries.map(country => {
                              return (
                                <option
                                  key={country.id}
                                  value={country.code}
                                >{country.code}</option>
                              )
                            })}
                          </select>
                        </div>
                        <div
                          className={"form-group col-4 offset-8"}
                        >
                          <button
                            className={"btn btn-primary w-100"}
                            type={"button"}
                            onClick={this.handleAddCustomerAddress.bind(this)}
                            disabled={
                              !this.state._delivery_address_customer_name
                              || !this.state._delivery_address_city
                              || !this.state._delivery_address_post_code
                              || !this.state._delivery_address_street
                            }
                          >Uložit adresu
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group select-required col-8"}
                    >
                      <label>Telefon:</label>
                      <CreatableSelect
                        isClearable={true}
                        value={{
                          label: this.state.phone_number || "",
                          value: this.state.phone_number || "",
                        }}
                        onChange={this.handlePhoneNumberChange.bind(this)}
                        onBlur={this.handlePhoneNumberBlur.bind(this)}
                        options={this.getPhoneNumberOptions()}
                        formatCreateLabel={value => value}
                        noOptionsMessage={() => "Nic nenalezeno"}
                      />
                    </div>
                    <div
                      className={"form-group col-4"}
                    >
                      <label>&nbsp;</label>
                      <button
                        className={"btn btn-primary w-100"}
                        onClick={this.handleAddCustomerPhone.bind(this)}
                        disabled={!this.state.customer.contact || !this.state.phone_number}
                      >Uložit k zákazníkovi
                      </button>
                    </div>
                  </div>
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group select-required col-8"}
                    >
                      <label>Email:</label>
                      <CreatableSelect
                        isClearable={true}
                        value={{
                          label: this.state.email || "",
                          value: this.state.email || "",
                        }}
                        onChange={this.handleEmailChange.bind(this)}
                        onBlur={this.handleEmailBlur.bind(this)}
                        options={this.getEmailOptions()}
                        formatCreateLabel={value => value}
                        noOptionsMessage={() => "Nic nenalezeno"}
                      />
                    </div>
                    <div
                      className={"form-group col-4"}
                    >
                      <label>&nbsp;</label>
                      <button
                        className={"btn btn-primary w-100"}
                        onClick={this.handleAddCustomerEmail.bind(this)}
                        disabled={!this.state.customer.contact || !this.state.email}
                      >Uložit k zákazníkovi
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={"row"}
            >
              <div
                className={"col-12 mt-3"}
              >
                {this.state.order
                  .sort(this.id ? sortInvoiceNumbers : () => 0)
                  .map(order => {
                    if (!order.parent_order_id) {
                      quickTabsOrderCount++
                    }

                    return (
                      <span
                        key={order.id}
                        className={"d-inline-block py-1 px-3 bg-light border-right btn-link cursor-pointer"}
                        onClick={() => this.handleOrderFocus(order.id)}
                      >
                      {order.parent_order_id
                        ? order.paid_date
                          ? "D" // refunded order
                          : "V" // returned order
                        : "" // order
                      }
                        {seq(quickTabsOrderCount)}
                    </span>
                    )
                  })}
              </div>
            </div>

            <div
              className={"row"}
            >
              {this.state.order
                .filter(order => this.props.isReturn ? order._isReturned : true) // for refund get only returned orders. otherwise, return all orders
                .sort(this.id ? sortInvoiceNumbers : () => 0) // show refunds under original order. make sense only for existing sales.
                .map((order, i) => {
                  if (!order.parent_order_id) {
                    orderCount++
                  }

                  return (
                    <OrderForm
                      key={order.id}
                      seqNo={orderCount}
                      isSaleFirstOrder={i === 0}
                      salePaymentMethodId={this.state.payment_method_id}
                      isSingle={this.state.order.size === 1}
                      isDetail={!!this.id}
                      isReturn={this.props.isReturn}
                      order={order}
                      paymentMethods={this.state._paymentMethods}
                      countries={this.state._countries}
                      priceTypes={this.state._priceTypes}
                      isRecalculatingPoints={this.state._isRecalculatingPoints}
                      focusOrderId={this.state._focusOrderId}
                      isSaleLocked={this.state._isLocked}
                      productTree={this.state._productTree}
                      productCategories={this.state._productCategories}
                      isPromo4Plus1Active={this.state._isPromo4Plus1Active}
                      saleStatus={this.state.status}
                      saleFees={this.getSaleFees()}
                      deleteOrderHandler={this.handleDeleteOrder.bind(this)}
                      deleteRefundHandler={this.handleDeleteRefund.bind(this)}
                      setOrderStateHandler={this.handleSetOrderState.bind(this)}
                      getCustomerTypeNameHandler={this.getCustomerTypeName.bind(this)}
                      addOrderHandler={this.handleAddOrder.bind(this)}
                      orderId={this.orderId}
                      countryId={this.getCountryId()}
                      deleteCustomerNoteHandler={this.deleteCustomerNote.bind(this)}
                      orderReturnHandler={this.handleOrderReturn.bind(this)}
                      countryCurrencyInfo={this.getCountryCurrencyInfo()}
                      hasPricesWithoutVAT={this.hasPricesWithoutVAT(order)}
                      getProductStockQuantity={this.getProductStockQuantity.bind(this)}
                      getParentOrder={this.getParentOrder.bind(this)}
                      getParentOrderReturns={this.getParentOrderReturns.bind(this)}
                    />
                  )
                })}
            </div>
          </fieldset>

          <SaleMenu
            isDetail={!!this.id}
            isTop={false}
            isLocked={this.state._isLocked}
            isLockedBy={this.state._isLockedBy}
            isReturn={this.props.isReturn}
            canUnlock={this.state._canUnlock}
            bottomMenuPos={this.state._bottomMenuPos}
            haveToWaitForPayment={this.state._haveToWaitForPayment}
            shouldPrint={this.shouldPrint()}
            deliveryAndInvoiceUrl={this.state.docs_url}
            saleId={this.id}
            status={this.state.status}
            saleNo={this.state.sale_no}
            email={this.state.email}
            isPersonalPickup={this.isPersonalPickup()}
            formCancelHandler={this.handleCancel.bind(this)}
            deleteHandler={this.handleDelete.bind(this)}
            printReadyHandler={this.handlePrintReady.bind(this)}
            printHandler={this.handlePrint.bind(this)}
            paidReadyHandler={this.handlePaidReady.bind(this)}
            personalPickupPrintHandler={this.handlePersonalPickupPrint.bind(this)}
            unlockHandler={this.handleUnlock.bind(this)}
            returnSaveClickHandler={this.handleReturnSaveClick.bind(this)}
            isPromo4Plus1MenuVisible={this.state._isPromo4Plus1MenuVisible}
            isPromo4Plus1OpenHandler={this.handleIsPromo4Plus1Open.bind(this)}
          />
        </form>

        {!this.props.isReturn && (
          <div
            className={"row sale-summary pt-2 border-top-15 position-fixed w-100 bg-white"}
            style={{
              bottom: this.state._bottomMenuPos + 38
            }}
          >
            <div
              title={"Vrátit se zpět"}
              className={styles.topBtn}
              onClick={this.handleScrollTop.bind(this)}
            >
              <i
                className={"fas fa-arrow-alt-circle-up fa-lg"}
              />
            </div>

            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={styles.saleSummaryTotalPriceLabel}
              >Celková cena bez splatností:</label>
              <input
                type={"text"}
                className={`form-control font-weight-bold bg-light-green text-black ${styles.saleSummaryTotalPriceInput}`}
                placeholder={"Celková cena bez splatností"}
                value={(this.state.no_due_price * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={styles.saleSummaryTotalPriceLabel}
              >Celková cena:</label>
              <input
                type={"text"}
                className={`form-control font-weight-bold bg-light-green text-black ${styles.saleSummaryTotalPriceInput}`}
                placeholder={"Celková cena"}
                value={(this.state.total_price * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Body:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Body"}
                value={this.state.bonus_points}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Cena výrobků:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Cena"}
                value={(this.state._productsPrice * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Balné a poštovné:</label>
              <input
                type={"text"}
                className={`form-control font-weight-bold ${this.state.delivery_price === 0 ? "text-black" : "text-white bg-danger"}`}
                placeholder={"Balné a poštovné"}
                value={(this.state.delivery_price * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >M. poplatek:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Manipulační poplatek"}
                value={(this.state.handling_fee * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"mt-2 font-weight-bold"}
              >&nbsp;</label>
              <input
                className={"form-check-input"}
                type={"checkbox"}
                id={"is_free_delivery"}
                checked={this.state.is_free_delivery}
                onChange={this.handleIsFreeDeliveryChange.bind(this)}
                disabled={this.state._isLocked}
              />
              <label
                className={"form-check-label font-weight-bold"}
                htmlFor={"is_free_delivery"}
              >
                Nepočítat poštovné a balné
              </label>
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Váha:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Váha"}
                value={this.state.weight || 0}
                disabled={true}
              />
            </div>
            <div
              className={"form-group col-lg-1 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Objednávek:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Počet objednávek"}
                value={this.state.order.filter(order => !order.parent_order_id).size || 1}
                disabled={true}
              />
            </div>
          </div>
        )}

        <Promo4Plus1Preview
          isOpen={this.state._isPromo4Plus1Open}
          closeHandler={this.handleIsPromo4Plus1Close.bind(this)}
          saveHandler={this.confirmPromo4Plus1.bind(this)}
          data={this.getPromo4Plus1Data()}
          countryCurrencyInfo={this.getCountryCurrencyInfo()}
          // IS specific
          haveToWaitForPayment={this.state._haveToWaitForPayment}
          formSubmitHandler={this.handlePromo4Plus1FormSubmit.bind(this)}
          paidReadyHandler={this.handlePaidReady.bind(this)}
          isPersonalPickup={this.isPersonalPickup()}
          personalPickupPrintHandler={this.handlePersonalPickupPrint.bind(this)}
          printReadyHandler={this.handlePrintReady.bind(this)}
          shouldPrint={this.shouldPrint()}
          printHandler={this.handlePrint.bind(this)}
        />
      </>
    )
  }

  handleScroll() {
    let pos = 0

    if (document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 50) {
      pos = 50
    }

    if (this.state._bottomMenuPos !== pos) {
      this.setState({
        _bottomMenuPos: pos,
      })
    }
  }

  handleScrollTop() {
    this.setState({
      _bottomMenuPos: 0,
    }, () => {
      window.scrollTo(0, 0)
    })
  }

  handleDeliveryMethodChange(e) {
    let deliveryMethodId = parseInt(e.target.value)
    this.setState({
      delivery_method_id: deliveryMethodId,
      payment_method_id: deliveryMethodId === DELIVERY_METHOD.PERSONAL_PICKUP
        ? PAYMENT_METHOD.BANK_TRANSFER
        : deliveryMethodId === DELIVERY_METHOD.CZECH_POST
          ? PAYMENT_METHOD.CASH_ON_DELIVERY
          : this.state.payment_method_id
    }, () => {
      this.recalculatePrice()
      this.updatePaymentWaitingState()
    })
  }

  handlePaymentMethodChange(e) {
    let paymentMethodId = parseInt(e.target.value)
    this.setState({
      payment_method_id: paymentMethodId,
      // fill the first order with same data
      order: this.state.order.update(0, () => Object.assign({}, this.state.order.get(0), {
        payment_method_id: paymentMethodId,
      }))
    }, () => {
      this.recalculatePrice()
      this.updatePaymentWaitingState()
    })
  }

  handleSaleDateChange(e) {
    this.setState({
      sale_date: e.target.value
    })
  }

  handleOrderMethodChange(e) {
    this.setState({
      order_method_id: parseInt(e.target.value)
    }, this.recalculatePrice.bind(this))
  }

  handleCustomerChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}&type_id=!${CUSTOMER_TYPE.REMOVED}`)
      .then(res => {
        let data = res.data.objects.map(item => {
          return Object.assign(item, {
            value: item.id,
            label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
          })
        })
        cb(data)
      })
  }

  handleCustomerSelect(option) {
    let data = {
      customer: Object.assign({}, option, {
        value: option.id,
        label: formatCustomerName(option),
      }),
      customer_id: option.id,
      billing_address_billing_name: option.billing_address_billing_name || option.billing_address_customer_name,
      billing_address_city: option.billing_address_city,
      billing_address_customer_name: option.billing_address_customer_name,
      billing_address_post_code: option.billing_address_post_code,
      billing_address_street: option.billing_address_street,
      billing_address_country_code: option.billing_address_country_code,
      delivery_method_id: this.state.delivery_method_id,
      payment_method_id: option.billing_address_country_code === "CZ"
        ? this.state.payment_method_id
        : PAYMENT_METHOD.BANK_TRANSFER,
      phone_number: "",  // reset on customer change, it will be filled in setState callback bellow
      email: "",  // reset on customer change, it will be filled in setState callback bellow
    }
    this.setState(data, () => {
      this.selectLastAddress()
      this.getCustomerPhoneNumber(true)
      this.getCustomerEmail(true)
      this.getCustomerOrderHistory(data.customer_id, this.state.order.get(0).id)
      this.getCustomerMembershipStatus(data.customer, 0)
      this.getCustomerBonusMoneyState(0)
    })
    // fill first order with the same data
    this.setState({
      order: this.state.order.update(0, () => Object.assign({}, this.state.order.get(0), data)),
    }, this.updatePaymentWaitingState.bind(this))
  }

  getCustomerPhoneNumber(getCustomerPhoneNumber) {
    if (
      getCustomerPhoneNumber ||
      !this.state.phone_number
    ) {
      let phones = this.state.customer.contact.filter(contact =>
        contact.type_id === CONTACT_TYPE.TARIFF_02 ||
        contact.type_id === CONTACT_TYPE.PHONE_HOME ||
        contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
        contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
        contact.type_id === CONTACT_TYPE.PHONE_WORK
      )

      this.setState({
        phone_number: phones.length === 0 ? "" : phones[0].value
      })
    }
  }

  getCustomerEmail(getCustomerEmail) {
    if (
      getCustomerEmail ||
      !this.state.email
    ) {
      let emails = this.state.customer.contact.filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)

      this.setState({
        email: emails.length === 0 ? "" : emails[0].value
      })
    }
  }

  selectLastAddress() {
    if (this.state.customer && this.state.customer.address.length !== 0) {
      let address = this.state.customer.address[0]

      this.setState({
        delivery_address: null,
        delivery_address_id: address.id,
        _delivery_address_customer_name: address.customer_name,
        _delivery_address_delivery_name: address.delivery_name || "",
        _delivery_address_street: address.street,
        _delivery_address_post_code: address.post_code,
        _delivery_address_city: address.city,
        _delivery_address_country_code: address.country_code,
      }, () => {
        // TODO: sometimes this check failed. add little bit of delay
        setTimeout(this.checkSaleToForeignCountry.bind(this), 100)
      })
    }
  }

  handleIsFreeDeliveryChange(e) {
    this.setState({
      is_free_delivery: e.target.checked
    }, () => {
      this.recalculatePrice()
    })
  }

  handleDeliveryAddressChange(e) {
    let id = parseInt(e.target.value)
    let address = this.state.customer.address.find(address => address.id === id)

    this.setState({
      delivery_address_id: id,
      delivery_address: null,
      _delivery_address_customer_name: address.customer_name,
      _delivery_address_delivery_name: address.delivery_name || "",
      _delivery_address_street: address.street,
      _delivery_address_post_code: address.post_code,
      _delivery_address_city: address.city,
      _delivery_address_country_code: address.country_code
    }, this.checkSaleToForeignCountry.bind(this))
  }

  handleShowAddAddressForm(e) {
    e.preventDefault()
    this.setState({
      _showAddAddressForm: true
    })
  }

  handleAddressCustomerNameChange(e) {
    this.setState({
      _delivery_address_customer_name: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleAddressDeliveryNameChange(e) {
    this.setState({
      _delivery_address_delivery_name: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressStreetChange(e) {
    this.setState({
      _delivery_address_street: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressCityChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&city=${val}`)
      .then(res => {
        res.data.objects.forEach(item => {
          item.value = item.city
          item.label = `${item.city} (${item.post_code})`
        })
        cb(res.data.objects)
      })
  }

  handleDeliveryAddressCitySelect(option) {
    this.setState({
      _delivery_address_city: option.city,
      _delivery_address_post_code: option.post_code,
      _delivery_address_country_code: option.country.code,
    }, () => {
      this.updateDeliveryAddress()
    })
  }


  handleDeliveryAddressPostCodeChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&post_code=${val}`)
      .then(res => {
        res.data.objects.forEach(item => {
          item.value = item.post_code
          item.label = `${item.city} (${item.post_code})`
        })
        cb(res.data.objects)
      })
  }

  handleDeliveryAddressPostCodeSelect(option) {
    this.setState({
      _delivery_address_city: option.city,
      _delivery_address_post_code: option.post_code,
      _delivery_address_country_code: option.country.code,
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressCountryCodeChange(e) {
    this.setState({
      _delivery_address_country_code: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleAddCustomerAddress() {
    let customer = Object.assign({}, this.state.customer)
    customer.address.push({
      customer_name: this.state._delivery_address_customer_name,
      delivery_name: this.state._delivery_address_delivery_name,
      city: this.state._delivery_address_city,
      post_code: this.state._delivery_address_post_code,
      street: this.state._delivery_address_street,
      country_code: this.state._delivery_address_country_code,
    })

    // notes are empty on sale detail. even if some exists in DB. and if we update (PUT) customer
    // with empty notes, all notes in DB will be destroyed.
    delete customer.note

    API.put(`/customers/${this.state.customer.id}`, customer)
      .then(({data}) => {
        this.setState({
          customer: Object.assign({}, data, {
            value: data.id,
            label: formatCustomerName(data),
          }),
          delivery_address_id: data.address[data.address.length - 1].id,
          _showAddAddressForm: false,
        })
      })
  }

  handlePhoneNumberChange(option) {
    this.setState({
      phone_number: option ? option.value : ""
    })
  }

  handlePhoneNumberBlur(e) {
    if (e.target.value) {
      this.setState({
        phone_number: e.target.value
      })
    }
  }

  getPhoneNumberOptions() {
    let phoneNumbers = []

    if (this.state.phone_number) {
      phoneNumbers.push(this.state.phone_number)
    }

    if (this.state.customer.contact && this.state.customer.contact.length > 0) {
      this.state.customer.contact
        .filter(contact => contact.type_id >= 3 && contact.type_id <= 7) // phone
        .forEach(contact => {
          if (phoneNumbers.indexOf(contact.value) === -1) {
            phoneNumbers.push(contact.value)
          }
        })
    }

    return phoneNumbers.map(phoneNumber => {
      return {
        label: phoneNumber,
        value: phoneNumber,
      }
    })
  }

  handleAddCustomerPhone(e) {
    e.preventDefault()
    API.post("/validate/contact", {
      type_id: 5,
      value: this.state.phone_number,
      country_code: this.state.customer.billing_address_country_code,
    }).then(() => {
      let contact = this.state.customer.contact.slice(0) // copy
      contact.push({
        type_id: 5,
        value: this.state.phone_number,
      })
      API.put(`/customers/${this.state.customer.id}`, {contact})
        .then(this.getNewCustomerContacts.bind(this))
        .finally(() => alert("Telefon byl uložen k zákazníkovi."))
    })
  }

  handleEmailChange(option) {
    this.setState({
      email: option ? option.value : ""
    })
  }

  handleEmailBlur(e) {
    if (e.target.value) {
      this.setState({
        email: e.target.value
      })
    }
  }

  getEmailOptions() {
    let emails = []

    if (this.state.email) {
      emails.push(this.state.email)
    }

    if (this.state.customer.contact && this.state.customer.contact.length > 0) {
      this.state.customer.contact
        .filter(contact => contact.type_id === 1) // email
        .forEach(contact => {
          if (emails.indexOf(contact.value) === -1) {
            emails.push(contact.value)
          }
        })
    }

    return emails.map(email => {
      return {
        label: email,
        value: email,
      }
    })
  }

  handleAddCustomerEmail(e) {
    e.preventDefault()
    API.post("/validate/contact", {
      type_id: 1,
      value: this.state.email,
    }).then(() => {
      let contact = this.state.customer.contact.slice(0) // copy
      contact.push({
        type_id: 1,
        value: this.state.email,
      })
      API.put(`/customers/${this.state.customer.id}`, {contact})
        .then(this.getNewCustomerContacts.bind(this))
        .finally(() => alert("E-mail byl uložen k zákazníkovi."))
    })
  }

  handleOrderFocus(orderId) {
    this.setState({
      _focusOrderId: orderId,
    })
  }

  getNewCustomerContacts() {
    API.get(`/customers/${this.state.customer.id}`)
      .then(({data}) => {
        this.setState({
          customer: Object.assign({}, this.state.customer, {
            contact: data.contact
          })
        })
      })
  }

  handleAddOrder(e) {
    e.preventDefault()
    this.setState({
      order: this.state.order.push({
        id: uuid(),
        order_type_id: "default",
        payment_method_id: this.state.payment_method_id,
        price_type: "customer_type",
        due_days: 0,
        customer_id: null,
        billing_address_billing_name: null,
        billing_address_street: null,
        billing_address_city: null,
        billing_address_post_code: null,
        billing_address_country_code: "CZ",
        order_product: List(),
        customer: {},
        status: "created",
        price: 0,
        discount: 0,
        weight: 0,
        bonus_points: 0,
        note: List(),
        is_simple_invoice: false,
        order_product_discount_group: [],
        _orderHistory: [],
        _note: "",
        _isAdvert: false,
        _focus: true, // scroll to newly added order
        _promoProducts: [],
        _isCatalogueOpen: false,
      }),
    }, this.handleScroll.bind(this)) // update sale summary position
  }

  handlePrintReady(e) {
    this.setState({
      status: this.state.order.every(order => order.status === "paid") ? "print_ready_paid" : "print_ready",
    }, () => {
      if (this.id) {
        this.updateSale()
      } else {
        this.addSale()
      }
    })
  }

  handlePrint(e) {
    this.setState({
      status: this.state.order.every(order => order.status === "paid") ? "printed_paid" : "printed",
    }, () => {
      if (this.id) {
        this.updateSale(true)
      } else {
        this.addSale(true)
      }
    })
  }

  handlePaidReady(e) {
    this.setState({
      status: "ready_unpaid",
    }, () => {
      if (this.id) {
        this.updateSale()
      } else {
        this.addSale()
      }
    })
  }

  handlePersonalPickupPrint(e) {
    this.setState({
      status: "ready_unpaid",
    }, () => {
      if (this.id) {
        this.updateSale(true)
      } else {
        this.addSale(true)
      }
    })
  }

  handleDeleteOrder(orderId) {
    let orderIndex = this.state.order.findIndex(order => order.id === orderId)
    if (orderIndex > -1) {
      this.setState({
        order: this.state.order.delete(orderIndex)
      }, this.recalculatePrice.bind(this))
    }
  }

  handleDeleteRefund(orderId) {
    if (window.confirm("Opravdu chcete smazat dobropis?")) {
      API.delete(`/orders/${orderId}`)
        .then(() => this.props.history.push("/sales"))
    }
  }

  handleSetOrderState(orderId, obj, cb) {
    // do not recalculate sale price on data which does not affects sale price
    // frontend specific data starts with underscore
    let needsRecalculation = !Object.keys(obj).every(key => key.indexOf("_") === 0)
    let {customer_id, billing_address_country_code} = obj

    if (customer_id) {
      this.getRecalculationPointsStatus()
    }

    // do not allow order with mixed countries
    if (billing_address_country_code && billing_address_country_code !== this.getCountryCurrencyInfo().code) {
      if (customer_id) {
        return alert("Zákazník má fakturační adresu do jiné země než je doručovací země zakázky.")
      }
      return alert("Země fakturační adresy se musí shodovat se zemí doručovací adresy zakázky.")
    }

    let orderIndex = this.state.order.findIndex(order => order.id === orderId)

    if (orderIndex > -1) {
      let order = Object.assign({}, this.state.order.get(orderIndex), obj)
      this.setState({
        order: this.state.order.update(orderIndex, () => order)
      }, () => {
        if (needsRecalculation) {
          this.recalculatePrice()
        }

        if (customer_id) {
          this.getCustomerOrderHistory(customer_id, orderId)
          this.getCustomerMembershipStatus(order.customer, orderIndex)
          this.getCustomerBonusMoneyState(orderIndex)
        }

        // new product change page height - update summary and footer position
        this.handleScroll()

        this.updatePaymentWaitingState()

        this.updateIsPromo4Plus1MenuVisible()

        if (cb) {
          cb()
        }
      })
    }
  }

  updateDeliveryAddress() {
    this.setState({
      delivery_address: {
        customer_name: this.state._delivery_address_customer_name,
        delivery_name: this.state._delivery_address_delivery_name,
        city: this.state._delivery_address_city,
        post_code: this.state._delivery_address_post_code,
        street: this.state._delivery_address_street,
        country_code: this.state._delivery_address_country_code,
      },
      delivery_address_id: null
    }, this.checkSaleToForeignCountry.bind(this))
  }

  getSale() {
    API.get(`/sales/${this.id}`)
      .then(res => {
        this.updateSaleDetail(res.data)
      })
  }

  getCustomer(id) {
    API.get(`/customers/${id}`)
      .then(res => {
        let customer = res.data
        customer.value = customer.id
        customer.label = formatCustomerName(customer)

        this.setState({
          // sale
          customer_id: id,
          customer: customer,
          // first order
          order: this.state.order.update(0, () => Object.assign({}, this.state.order.get(0), {
            customer_id: id,
            customer: customer,
            billing_address_customer_name: customer.billing_address_customer_name,
            billing_address_billing_name: customer.billing_address_billing_name,
            billing_address_street: customer.billing_address_street,
            billing_address_city: customer.billing_address_city,
            billing_address_post_code: customer.billing_address_post_code,
            billing_address_country_code: customer.billing_address_country_code,
          })),
        }, () => {
          this.selectLastAddress()
          this.getCustomerPhoneNumber(true)
          this.getCustomerEmail(true)
          this.getCustomerOrderHistory(this.state.customer_id, this.state.order.get(0).id)
          this.getCustomerMembershipStatus(this.state.customer, 0)
          this.getCustomerBonusMoneyState(0)
        })
      })
  }

  updateSaleDetail(data) {
    // update customer due to autocomplete
    if (this.state.customer) {
      data.customer.value = data.customer.id
      data.customer.label = formatCustomerName(data.customer)
    }
    // add sale delivery address to customer address when address was marked as non active
    let address = data.customer.address.find(address => address.id === data.delivery_address_id)

    if (!address) {
      data.customer.address.push(data.delivery_address)
    }
    // return/refund order
    if (this.props.isReturn) {
      let orders = data.order.filter(order => order.id === this.orderId)
      if (orders.length > 0) {
        let order = orders[0]
        let newOrder = Object.assign({}, order, {
          id: uuid(),
          invoice_no: null,
          order_type_id: order.paid_date ? "refund" : "return",
          parent_order_id: order.id,
          _isReturned: true,
        })
        newOrder.order_product.forEach(product => {
          delete product.id
          delete product.order_id
        })
        data.order.push(newOrder)
      }
    }
    // make List from orders
    data.order = List(data.order)
    data.order.forEach(order => {
      // make List from order products
      order.order_product = List(order.order_product)
      // make List from order notes
      order.note = List(order.note)
      // promo products
      order._promoProducts = []
    })
    // update orders customers due to autocomplete
    data.order.forEach(order => {
      order.customer.value = order.customer.id
      order.customer.label = formatCustomerName(order.customer)
    })
    // update customer's order history
    data.order.forEach(order => {
      order._orderHistory = []
      this.getCustomerOrderHistory(order.customer_id, order.id)
    })
    // handle locked sales
    if (data.open_at && data.open_by_user_id) {
      let user = jwtDecode(RBAC.getToken())
      if (data.open_by_user_id !== user.id) {
        alert("Se zakázkou se aktuálně pracuje.")
        data._canUnlock = false
        this.getLockedByUserInfo(data.open_by_user_id)
      }
    }
    // lock shipped sales
    if (["packed", "unpaid", "paid", "storno"].indexOf(data.status) > -1) {
      data._canUnlock = false
    }

    this.setState(data, () => {
      this.recalculatePrice()
      this.getCustomerPhoneNumber()
      this.getCustomerEmail()
      this.updateIsPromo4Plus1MenuVisible()
    })
  }

  getLockedByUserInfo(userId) {
    API.get(`/users/${userId}`)
      .then(res => {
        this.setState({
          _isLockedBy: res.data
        })
      })
  }

  addSale(shouldPrint = false) {
    let data = cleanState(this.state)

    // browser return datetime with T in the middle
    data.sale_date = data.sale_date.replace("T", " ")

    // delete unnecessary data
    delete data.customer
    delete data.exchange_rate

    let eachOrderContainsSomeProduct = true
    data.order.forEach((order, i) => {
      // delete unnecessary data
      data.order[i] = cleanState(order)
      // when adding new sale, delete client ids for all orders
      delete data.order[i].id
      // every order have to contain at least one product, but not only automatically added products
      if (
        data.order[i].order_product.length === 0 ||
        data.order[i].order_product.every(orderProduct => (
          orderProduct.product.code === PRODUCT_CODE.REGISTRATION ||
          orderProduct.product.code === PRODUCT_CODE.MEMBERSHIP ||
          orderProduct.product.code === PRODUCT_CODE.NEW_CUSTOMER_CARD
        ))
      ) {
        eachOrderContainsSomeProduct = false
      }
    })

    if (!data.customer_id) {
      return alert("Vyplňte zákazníka.")
    }

    if (
      data.status !== "open" &&
      !data.email
    ) {
      return alert("Vyplňte e-mail.")
    }

    if (
      data.status !== "open" &&
      !data.phone_number
    ) {
      return alert("Vyplňte telefon.")
    }

    if (!eachOrderContainsSomeProduct) {
      return alert("Na každé objednávce musí být alespoň jeden produkt.")
    }

    if (data.order.some(order => order.billing_address_country_code !== this.getCountryCurrencyInfo().code)) {
      return alert("Fakturační země na objednávkách se musí shodovat s doručovací zemí zakázky.")
    }

    API.post("/sales", data)
      .then(res => {
        this.formChanges = false

        // unlock sale
        API.post(`/sales/${res.data.id}/unlock`, {
          status: this.state.status,
        })

        if (shouldPrint && this.isPersonalPickup()) {
          this.payOrdersAndPrint(res.data)
        } else if (shouldPrint && res.data.docs_url) {
          print(res.data.docs_url)
          this.props.history.push("/sales")
        } else {
          this.props.history.push("/sales")
        }
      })
  }

  updateSale(shouldPrint = false) {
    let data = cleanState(this.state)

    // browser return datetime with T in the middle
    data.sale_date = data.sale_date.replace("T", " ")

    // delete unnecessary data
    delete data.customer
    delete data.exchange_rate

    let eachOrderContainsSomeProduct = true
    data.order.forEach((order, i) => {
      // delete unnecessary data
      data.order[i] = cleanState(order)
      // when updating new sale, delete client ids for newly added orders, or newly added refund/returns
      if (typeof data.order[i].id === "string" || order._isReturned) { // uuid
        delete data.order[i].id
      }
      // every order have to contain at least one product, but not only automatically added products
      if (
        data.order[i].order_product.length === 0 ||
        data.order[i].order_product.every(orderProduct => (
          orderProduct.product.code === PRODUCT_CODE.REGISTRATION ||
          orderProduct.product.code === PRODUCT_CODE.MEMBERSHIP ||
          orderProduct.product.code === PRODUCT_CODE.NEW_CUSTOMER_CARD
        ))
      ) {
        eachOrderContainsSomeProduct = false
      }
    })

    if (!data.customer_id) {
      return alert("Vyplňte zákazníka.")
    }

    if (
      data.status !== "open" &&
      !data.email
    ) {
      return alert("Vyplňte e-mail.")
    }

    if (
      data.status !== "open" &&
      !data.phone_number
    ) {
      return alert("Vyplňte telefon.")
    }

    if (!eachOrderContainsSomeProduct) {
      return alert("Na každé objednávce musí být alespoň jeden produkt.")
    }

    if (data.order.some(order => order.billing_address_country_code !== this.getCountryCurrencyInfo().code)) {
      return alert("Fakturační země na objednávkách se musí shodovat s doručovací zemí zakázky.")
    }

    API.put(`/sales/${this.id}`, data)
      .then(res => {
        this.formChanges = false

        // unlock sale
        API.post(`/sales/${this.id}/unlock`, {
          status: this.state.status,
        })

        if (shouldPrint && this.isPersonalPickup()) {
          this.payOrdersAndPrint(res.data)
        } else if (shouldPrint && res.data.docs_url) {
          print(res.data.docs_url)
          this.setState({
            _isLocked: true
          })
          this.props.history.push("/sales")
        } else {
          this.props.history.push("/sales")
        }
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateSale()
    } else {
      this.addSale()
    }
  }

  handlePromo4Plus1FormSubmit() {
    if (this.id) {
      this.updateSale()
    } else {
      this.addSale()
    }
  }

  handleFormKeyPress(e) {
    // disable form submit by enter
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  handleCancel(e) {
    e.preventDefault()
    if (this.id) {
      API.post(`/sales/${this.id}/unlock`)
        .then(() => {
          this.props.history.push("/sales")
        })
    } else {
      this.props.history.push("/sales")
    }
  }

  handleDelete() {
    let text = "stornovat zakázku"

    if (
      this.state.status === "packed" ||
      this.state.status === "unpaid"
    ) {
      text = "vytvořit vratku"
    }

    if (this.state.status === "created_open") {
      text = "smazat zakázku"
    }

    if (window.confirm(`Opravdu chcete ${text}?`)) {
      API.delete(`/sales/${this.id}`)
        .then(() => {
          this.formChanges = false
          this.props.history.push("/sales")
        })
    }
  }

  handleUnlock() {
    if (this.state._isLocked && window.confirm("Opravdu chcete upravit existující zakázku?")) {
      API.post(`/sales/${this.id}/lock`)
        .then(() => {
          this.setState({
            status: "open",
            _isLocked: false,
          })
        })
        .catch(() => { // 423
          this.setState({
            status: "open",
            _isLocked: false,
          })
        })
    }
  }

  updatePaymentWaitingState() {
    // every sale has to wait for payments. except...
    let haveToWaitForPayment = true

    // sale that have all orders with payment method "cash on delivery"
    // sale that have all orders with payment method "bank transfer" with due days
    // sale that have all orders with payment method "bonus money"
    // sale that have all orders with status paid
    // sale that have all orders with paid date
    // or combinations...
    if (this.state.order
      .filter(order => order.status !== "storno")
      .filter(order => order.parent_order_id === null)
      .every(order =>
        order.payment_method_id === PAYMENT_METHOD.CASH_ON_DELIVERY ||
        (order.payment_method_id === PAYMENT_METHOD.BANK_TRANSFER && order.due_days !== 0) ||
        order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY ||
        order.status === "paid" ||
        order.paid_date !== null
      )
    ) {
      haveToWaitForPayment = false
    }

    if (this.isPersonalPickup()) {
      haveToWaitForPayment = false
    }

    this.setState({
      _haveToWaitForPayment: haveToWaitForPayment,
    })
  }

  getDeliveryMethods() {
    API.get("/deliveryMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _deliveryMethods: res.data.objects
        }, this.recalculatePrice.bind(this))
      })
  }

  getPaymentMethods() {
    API.get("/paymentMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _paymentMethods: res.data.objects
        })
      })
  }

  getOrderMethods() {
    API.get("/orderMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _orderMethods: res.data.objects
        }, this.recalculatePrice.bind(this))
      })
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then(res => {
        this.setState({
          _countries: res.data.objects
        })
      })
  }

  getPriceTypes() {
    API.get("/priceType?limit=0&offset=0")
      .then(res => {
        this.setState({
          _priceTypes: res.data.objects
        })
      })
  }

  getSaleStates() {
    API.get("/saleStatus?limit=0&offset=0")
      .then(res => {
        this.setState({
          _saleStates: res.data.objects
        })
      })
  }

  getCustomerTypes() {
    API.get("/customerType?limit=0&offset=0")
      .then(res => {
        this.setState({
          _customerTypes: res.data.objects
        })
      })
  }

  getOrderTypes() {
    API.get("/orderType?limit=0&offset=0")
      .then(res => {
        this.setState({
          _orderTypes: res.data.objects
        })
      })
  }

  getCustomerTypeName(customer) {
    if (customer.type_id && this.state._customerTypes.length !== 0) {
      let types = this.state._customerTypes.filter(type => type.id === customer.type_id)
      if (types.length !== 0) {
        return types[0].name
      }
    }
    return ""
  }

  getHandlingFee(countryCode, salePriceWithoutVAT) {
    let countries = this.state._countries.filter(country => country.code === countryCode)
    if (countries.length > 0) {
      let country = countries[0]
      let handlingFeeLimit = country.handling_fee_limit / country.exchange_rate // get CZK value
      let handlingFeeConst = country.handling_fee_const / country.exchange_rate // get CZK value
      let handlingFee = handlingFeeConst - (salePriceWithoutVAT * handlingFeeConst / handlingFeeLimit)
      return handlingFee > 0 ? handlingFee : 0
    }
    return 0
  }

  async recalculatePrice() {
    let backendPriceInfo = {}
    let productsPrice = 0

    if (
      this.state.customer.id && // sale customer is present
      this.state.order.toArray().every(order => order.customer_id) // for every order customer is present
    ) {
      // do not send unnecessary data
      let data = cleanState(this.state)
      data.order.forEach((order, i) => {
        data.order[i] = cleanState(order)
      })

      await API.post("/sale-calculate", data)
        .then(({data}) => backendPriceInfo = data)
        .catch(() => {})

      if (this.state.status === "paid") {
        productsPrice = this.state.order
          .toArray()
          .reduce((acc, order) => {
            return acc + (order.price_vat * (order.parent_order_id ? -1 : 1))
          }, 0)
      } else {
        backendPriceInfo.order
          .filter(order => order.status !== "storno")
          .forEach(order => {
            productsPrice += order.price_vat * (order.parent_order_id ? -1 : 1)
          })
      }
    }

    let productsWeight = 0
    let bonusPoints = 0

    this.state.order.forEach(order => {
      let isReturnOrRefund = !!order.parent_order_id

      order.order_product.forEach(orderProduct => {
        // weight
        productsWeight += orderProduct.product.weight * orderProduct.quantity

        // points
        if (
          !orderProduct.is_advert &&
          order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY &&
          orderProduct.price !== 0 &&
          !this.isPromoProduct(orderProduct)
        ) {
          bonusPoints += orderProduct.product.points * orderProduct.quantity * (isReturnOrRefund ? -1 : 1)
        }
      })
    })

    if (this.state.status === "paid") {
      this.setState({
        total_price: this.state.total_price_vat || 0,
        delivery_price: (this.state.packaging_price_vat + this.state.delivery_price_vat) || 0,
        no_due_price: this.state.no_due_price || 0,
        handling_fee: this.state.handling_fee_vat || 0,
        _productsPrice: productsPrice,
        weight: productsWeight.toFixed(2),
        bonus_points: bonusPoints,
      })
    } else {
      this.setState({
        total_price: backendPriceInfo.total_price_vat || 0,
        delivery_price: (backendPriceInfo.packaging_price_vat + backendPriceInfo.delivery_price_vat) || 0,
        no_due_price: backendPriceInfo.no_due_price || 0,
        handling_fee: backendPriceInfo.handling_fee_vat || 0,
        _productsPrice: productsPrice,
        weight: productsWeight.toFixed(2),
        bonus_points: bonusPoints,
      })
    }
  }

  getCountryName(code) {
    let countries = this.state._countries.filter(country => country.code === code)
    if (countries.length !== 0) {
      return countries[0].name
    }
    return ""
  }

  getCountryId() {
    let countryCode = "CZ"
    // existing delivery address
    if (this.state.delivery_address_id) {
      let addresses = this.state.customer.address.filter(address => address.id === this.state.delivery_address_id)
      if (addresses.length > 0) {
        countryCode = addresses[0].country_code
      }
    }
    // new delivery address
    if (this.state.delivery_address) {
      countryCode = this.state.delivery_address.country_code
    }

    let countries = this.state._countries.filter(country => country.code === countryCode)
    if (countries.length > 0) {
      return countries[0].id
    }

    return 1
  }

  getCountryCurrencyInfo() {
    let countries = this.state._countries.filter(country => country.id === this.getCountryId())

    if (countries.length > 0) {
      return countries[0]
    }

    return {
      code: "CZ",
      currency: "CZK",
      exchange_rate: 1,
      round_decimal_places: 0,
    }
  }

  getCustomerOrderHistory(customerId, orderId) {
    API.get(`/orders?customer_id==${customerId}`)
      .then(({data}) => {
        this.handleSetOrderState(orderId, {
          _orderHistory: data.objects,
        })

        if (data.objects.length === 0) {
          alert("První objednávka pro tohoto zákazníka.")
        }
      })
  }

  getCustomerBonusMoneyState(orderIndex) {
    let customer = this.state.order.get(orderIndex).customer

    if (
      customer
      && customer.account
      && customer.account.length > 0
      && customer.account[0].total > 200
    ) {
      alert("Zákazník má více jak 200 věrnostních Kč.")
    }
  }

  deleteCustomerNote(orderId, customerId, noteIndex) {
    let orderIndex = this.state.order.findIndex(order => order.id === orderId)
    let order = this.state.order.get(orderIndex)
    let notes = [...order.customer.note]

    notes.splice(noteIndex, 1)

    API.put(`/customers/${customerId}`, {
      note: notes
    }).then(res => {
      this.handleSetOrderState(orderId, {
        customer: res.data,
      })
    })
  }

  handleOrderReturn(orderId) {
    this.props.history.push(`/sales/${this.id}/return/${orderId}`)
  }

  getCustomerMembershipStatus(customer, orderIndex) {
    API.get(`/customer/membership/${customer.id}`)
      .then(({data}) => {
        let order = this.state.order.get(orderIndex)
        order._membershipData = data

        // we can add multiple products
        data.products.forEach(product => {
          // add a product only if was not already added
          if (!order.order_product.some(orderProduct => orderProduct.product_id === product.product_id)) {
            order.order_product = order.order_product.push(product)

            this.setState({
              order: this.state.order.update(orderIndex, () => order)
            }, () => {
              if (data.products_unpaid_orders && data.products_unpaid_orders.length > 0) {
                let alerted = false
                data.products_unpaid_orders.forEach(order => {
                  order.order_product.forEach(orderProduct => {
                    if (orderProduct.product_id === product.product_id && !alerted) {
                      alerted = true
                      // add products first and then show alert
                      setTimeout(() => {
                        alert(`Produkt ${product.product.code} existuje na jiné nezplacené objednávce.`)
                      }, 250)
                    }
                  })
                })
              }
            })
          } else {
            // save membership data even no product added,
            // let this function like this for more readability
            this.setState({
              order: this.state.order.update(orderIndex, () => order)
            })
          }
        })
      })
  }

  checkSaleToForeignCountry() {
    if (this.getCountryId() !== COUNTRY.CZECH_REPUBLIC) {
      let order = this.state.order.get(0)
      order.payment_method_id = PAYMENT_METHOD.BANK_TRANSFER

      this.setState({
        payment_method_id: PAYMENT_METHOD.BANK_TRANSFER,
        delivery_method_id: DELIVERY_METHOD.DPD,
        order: this.state.order.update(0, () => order),
      }, this.updatePaymentWaitingState.bind(this))
    }
  }

  handleReturnSaveClick() {
    let order = this.state.order.find(order => order._isReturned)

    if (order) {
      order = cleanState(order)

      let orderProducts = order.order_product.filter(orderProduct => orderProduct.quantity > 0)

      if (orderProducts.length === 0) {
        return alert("Na každé objednávce musí být alespoň jeden produkt.")
      }

      delete order.id
      order.paid_date = moment().format("YYYY-MM-DD HH:ss:mm")

      API.post("/orders", order)
        .then(() => {
          this.formChanges = false
          this.props.history.push("/sales")
        })
    }
  }

  shouldPrint() {
    if (
      this.state.delivery_method_id === DELIVERY_METHOD.CZECH_POST ||
      this.state.delivery_method_id === DELIVERY_METHOD.DPD
    ) {
      if (this.state.payment_method_id === PAYMENT_METHOD.CASH_ON_DELIVERY) {
        return false
      }
    }

    return true
  }

  isPersonalPickup() {
    return this.state.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP && (
      this.state.payment_method_id === PAYMENT_METHOD.CREDIT_CARD ||
      this.state.payment_method_id === PAYMENT_METHOD.CASH
    )
  }

  payOrdersAndPrint(saleData) {
    if (saleData.status === "paid") {
      print(saleData.docs_url)
      this.props.history.push("/sales")
    } else {
      let orders = saleData.order
        .filter(order => order.due_days === 0)
        .filter(order => order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY)
        .map(order => ({
          id: order.id,
        }))

      if (orders.length === 0) {
        this.payOrdersAndPrintXYZ(saleData)
      } else {
        API.post("/payments/pay", {orders})
          .then(() => {
            this.payOrdersAndPrintXYZ(saleData)
          })
      }
    }
  }

  payOrdersAndPrintXYZ(saleData) {
    API.patch(`/sales/${saleData.id}`, {
      status: "printed_paid",
    }).then(() => {
      print(saleData.docs_url)
      this.props.history.push("/sales")
    })
  }

  hasPricesWithoutVAT(order) {
    let isSaleCustomerVatPayer = this.state.customer.tax_no !== null && this.getCountryId() !== COUNTRY.CZECH_REPUBLIC

    // sale customer _is_ a VAT payer = orders prices are based on order customer
    if (
      isSaleCustomerVatPayer &&
      order.customer
    ) {
      return order.customer.tax_no !== null && order.country_id !== COUNTRY.CZECH_REPUBLIC
    }

    // sale customer _is not_ a VAT payer = all orders are with VAT
    return false
  }

  getRecalculationPointsStatus() {
    API.get("/process/status/points_monthly")
      .then(({data}) => {
        this.setState({
          _isRecalculatingPoints: data.is_running,
        })

        if (data.is_running) {
          if (!this.recalculationPointsStatusInterval) {
            this.recalculationPointsStatusInterval = setInterval(this.getRecalculationPointsStatus.bind(this), 5000) // 10s
          }
        } else {
          clearInterval(this.recalculationPointsStatusInterval)
        }
      })
  }

  getProductStockQuantity(product, orderId) {
    let productQuantityAlreadyUsedInSale = this.state.order.reduce((sum, order) => {
      if (order.id !== orderId) {
        let orderProduct = order.order_product.find(orderProduct => orderProduct.product_id === product.id)

        if (orderProduct) {
          return sum + orderProduct.quantity
        }
      }

      return sum
    }, 0)

    return product.stock[0].available - productQuantityAlreadyUsedInSale
  }

  getParentOrder(orderId) {
    return this.state.order.toArray()
      .find(order => order.id === orderId)
  }

  getParentOrderReturns(orderId, currentReturnId) {
    return this.state.order.toArray()
      .filter(order => order.order_type_id === "refund")
      .filter(order => order.status !== "storno")
      .filter(order => order.id !== currentReturnId)
      .filter(order => order.parent_order_id === orderId)
  }

  getProductCatalogueData() {
    API.get("/productTree?limit=10")
      .then(({data}) => {
        this.setState({
          _productTree: data.objects,
        })
      })
    API.get("/categories?limit=0?offset=0")
      .then(({data}) => {
        this.setState({
          _productCategories: data.objects,
        })
      })
  }

  getDiscountGroups() {
    API.get("/product-discount-groups?type=n_plus_discount")
      .then(({data}) => {
        let now = moment()
        let isActive = data.objects.some(promo => now.isBetween(moment(promo.valid_from), moment(promo.admin_valid_to)))

        this.setState({
          _isPromo4Plus1Active: isActive && this.state.status === "open",
        }, this.updateIsPromo4Plus1MenuVisible.bind(this))
      })
  }

  handleIsPromo4Plus1Open() {
    this.setState({
      _isPromo4Plus1Open: true,
    })
  }

  handleIsPromo4Plus1Close() {
    this.setState({
      _isPromo4Plus1Open: false,
    })
  }

  getPromo4Plus1Data() {
    if (!this.state._isPromo4Plus1Active) {
      return []
    }

    return this.state.order
      .toArray()
      .filter(order => order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY)
      .map(order => {
        let products = []

        order.order_product
          .filter(orderProduct => orderProduct.product.code < 8000) // 8xxx & 9xxx not available in promos
          .filter(orderProduct => orderProduct.product.is_price_list_visible)
          .forEach(orderProduct => {
            for (let i = 0; i < orderProduct.quantity; i++) {
              // delete order.order_product[].id, otherwise backend will keep
              //   this order_product, and change quantity to last value, sent
              //   from client (this is primarily for situation, when updating
              //   existing sale, order_product.quantity > 1 and one of
              //   order_product will be for free.
              delete orderProduct.id

              products.push({
                id: orderProduct.product.id,
                name: getProductNameByCountry(orderProduct.product),
                price: getProductPrice(orderProduct, order.customer, order.price_type),
                points: orderProduct.product.points,
              })
            }
          })

        products
          .sort((product1, product2) => product2.price - product1.price)
          .forEach((product, i) => {
            if ((i + 1) % 5 === 0) {
              product.price = 0
              product.points = 0
            }
          })

        return {
          id: order.id,
          customer: `${order.customer.name || ""} ${order.customer.surname}`,
          products: products,
        }
      })
  }

  confirmPromo4Plus1(saveHandler) {
    let orders = this.state.order.toArray()
    let data = this.getPromo4Plus1Data()

    orders.forEach(order => {
      let item = data.find(item => item.id === order.id)

      if (item) {
        let orderProducts = order.order_product.toArray()

        item.products
          .filter(product => product.price === 0)
          .forEach(product => {
            let orderProduct = orderProducts.find(orderProduct => orderProduct.product_id === product.id)

            if (orderProduct.quantity === 1) {
              orderProduct.price = 0
              orderProduct.product.price = 0
              orderProduct.product.prices.consultant = 0
              orderProduct.product.prices.customer = 0
              orderProduct.product.prices.customer_with_discount = 0
            } else {
              orderProduct.quantity--

              let freeOrderProduct = Object.assign({}, orderProduct)
              freeOrderProduct.product = Object.assign({}, orderProduct.product)
              freeOrderProduct.product.prices = Object.assign({}, orderProduct.product.prices)

              freeOrderProduct.quantity = 1
              freeOrderProduct.price = 0
              freeOrderProduct.product.price = 0
              freeOrderProduct.product.prices.consultant = 0
              freeOrderProduct.product.prices.customer = 0
              freeOrderProduct.product.prices.customer_with_discount = 0
              orderProducts.push(freeOrderProduct)
            }
          })

        order.order_product = List(orderProducts)
      }
    })

    this.setState({
      order: List(orders),
      _isPromo4Plus1Open: false,
    }, () => {
      saveHandler()
    })
  }

  updateIsPromo4Plus1MenuVisible() {
    let isPromo4Plus1MenuVisible = false

    if (this.state._isPromo4Plus1Active) {
      this.state.order
        .filter(order => order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY)
        .forEach(order => {
          let productsQuantity = order.order_product
            .toArray()
            .filter(orderProduct => orderProduct.product.code < 8000) // 8xxx & 9xxx not available in promos
            .filter(orderProduct => orderProduct.product.is_price_list_visible)
            .reduce((acc, orderProduct) => {
              return acc + orderProduct.quantity
            }, 0)

          if (productsQuantity >= 5) {
            isPromo4Plus1MenuVisible = true
          }
        })
    }

    this.setState({
      _isPromo4Plus1MenuVisible: isPromo4Plus1MenuVisible && this.state.status === "open",
    })
  }

  getSaleFees() {
    return {
      delivery_price_vat: this.state.delivery_price_vat,
      handling_fee_vat: this.state.handling_fee_vat,
      packaging_price_vat: this.state.packaging_price_vat,
    }
  }

  isPromoProduct(orderProduct) {
    return orderProduct.hasOwnProperty("discount") &&
      orderProduct.hasOwnProperty("price") &&
      orderProduct.discount === orderProduct.price
  }
}
