import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarProducts = () => {
  return (
    <Switch>
      <Route
        path={"/products"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/products"}
              className={"nav-link"}
              activeClassName={"active"}
            >Produkty</NavLink>
            <NavLink
              to={"/products/price-list"}
              className={"nav-link"}
              activeClassName={"active"}
            >Ceník</NavLink>
            <NavLink
              to={"/products/discount"}
              className={"nav-link"}
              activeClassName={"active"}
            >Slevy a akce</NavLink>
            <NavLink
              to={"/products/watch-dog"}
              className={"nav-link"}
              activeClassName={"active"}
            >Hlídací pes</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarProducts
