import React, {Component} from "react"
import sortByCreatedAt from "../Base/sortByCreatedAt"

export default class CustomerTreeNode extends Component {
  render() {
    return (
      <>
        <tr
          title={this.props.depth === 2
            ? "Hledaný zákazník"
            : this.props.node.children.length > 0
              ? "Sponzor"
              : ""
          }
          className={`${this.isCustomerTypeVisible() ? "" : "opacity-5"} ${this.props.clickedNodeId === this.props.node.id ? "hover" : ""}`}
          onClick={this.handleRowClick.bind(this)}
        >
          <td
            className={"d-none"}
          >{this.props.index}</td>
          {this.props.columns
            .keySeq()
            .toArray()
            .map(key => (
              this.props.columns.get(key).visible && <td
                key={key}
                className={`${key === "customer_no" ? "column-sticky" : ""} ${this.props.depth === 2 ? "bg-warning" : this.props.node.children.length > 0 ? "bg-light-grey" : "bg-white"}`}
                style={{
                  paddingLeft: key === "customer_no" ? this.props.depth * 30 : "",
                }}
              >
                {/* button for a sponsor */}
                {this.props.depth === 1 && key === "customer_no" && (
                  <button
                    onClick={this.props.showSponsorHandler}
                    className={"p-0 mr-3 btn btn-sm btn-success d-print-none"}
                    type={"button"}
                  >
                    <i
                      className={"fas fa-angle-up"}
                    />
                  </button>
                )}

                {/* button for a customer */}
                {this.props.depth !== 1 && key === "customer_no" && this.props.node.children.length !== 0 && (
                  <button
                    onClick={this.handleToggleClick.bind(this)}
                    className={"p-0 mr-2 btn btn-sm btn-primary d-print-none"}
                    type={"button"}
                  >
                    <i
                      className={`fas fa-xs fa-${this.isOpen() ? "minus" : "plus"}`}
                    />
                  </button>
                )}

                {this.props.columns.get(key).hasOwnProperty("format")
                  ? this.props.columns.get(key).format(this.props.node[this.props.columns.get(key).key], this.props.node)
                  : this.props.node[key]
                }
              </td>
            ))}
        </tr>

        {this.isOpen() && this.props.node.children
          .sort(sortByCreatedAt)
          .map((child, index) => (
            <CustomerTreeNode
              key={child.id}
              node={child}
              columns={this.props.columns}
              depth={this.props.depth + 1}
              index={`${this.props.index}.${index + 1}`}
              toggleChildrenHandler={this.props.toggleChildrenHandler}
              customerTypes={this.props.customerTypes}
              currentLimit={this.props.currentLimit}
              openedNodes={this.props.openedNodes}
              rowClickHandler={this.props.rowClickHandler}
              clickedNodeId={this.props.clickedNodeId}
            />
          ))}
      </>
    )
  }

  isOpen() {
    return this.props.openedNodes.indexOf(this.props.node.id) > -1
  }

  handleToggleClick() {
    this.props.toggleChildrenHandler(this.props.node.id, this.props.depth)
  }

  isCustomerTypeVisible() {
    return this.props.customerTypes.some(customerType => customerType.value === this.props.node.type_id)
  }

  handleRowClick() {
    this.props.rowClickHandler(this.props.node.id)
  }
}
