import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarSales = () => {
  return (
    <Switch>
      <Route
        path={"/sales"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/sales"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zakázky</NavLink>
            <NavLink
              exact={true}
              to={"/sales/pay"}
              className={"nav-link"}
              activeClassName={"active"}
            >Přehled a úhrada objednávek</NavLink>
            <NavLink
              exact={true}
              to={"/sales/boxes"}
              className={"nav-link"}
              activeClassName={"active"}
            >Vyhledávání zásilky</NavLink>
            <NavLink
              to={"/sales/pay/cp"}
              className={"nav-link"}
              activeClassName={"active"}
            >Úhrada dobírek ČP</NavLink>
            <NavLink
              to={"/sales/pay/dpd"}
              className={"nav-link"}
              activeClassName={"active"}
            >Úhrada dobírek DPD</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarSales
