import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"

export default class CustomerMenu extends Component {
  render() {
    return (
      <div
        className={`col-12 ${this.props.isTop ? "mb-3" : "mt-3 mb-3"}`}
      >
        <div
          className={"row"}
        >
          <div
            className={"col-lg-6 mb-3"}
          >
            <span
              className={"mr-3 text-secondary"}
            >Detail zákazníka</span>
            <NavLink
              to={"/customers/add"}
              className={"btn btn-primary mr-3"}
            >Nový zákazník</NavLink>

            <NavLink
              to={`/customers/tree/${this.props.isDetail && this.props.customerId ? this.props.customerId : ""}`}
              className={"btn btn-primary"}
            >Skupinové informace</NavLink>
          </div>

          <div
            className={"col-lg-4 mb-3 text-lg-right"}
          >
            {this.props.isLocked && (
              <button
                className={"btn btn-success mr-3"}
                onClick={this.props.unlockHandler}
                type={"button"}
              >Odemknout</button>
            )}
            <button
              type={"button"}
              className={"btn btn-warning mr-3"}
              disabled={this.props.isLocked}
              onClick={this.props.formCancelHandler}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success mr-3"}
              disabled={this.props.isLocked}
            >Uložit
            </button>
          </div>

          <div
            className={"col-lg-2 mb-3 text-lg-right"}
          >
            <button
              type={"button"}
              className={"btn btn-danger"}
              onClick={this.props.deleteHandler}
              disabled={this.props.isLocked || this.props.customerTypeId === CUSTOMER_TYPE.REMOVED || !this.props.isDetail}
            >Vyřadit zákazníka
            </button>
          </div>

          <div
            className={"col-lg-10"}
          >
            {this.props.customerTypeId !== CUSTOMER_TYPE.REMOVED && (
              <NavLink
                to={`/sales/add/${this.props.isDetail && this.props.customerId ? this.props.customerId : ""}`}
                className={"btn btn-light mr-3"}
              >Nová zakázka</NavLink>
            )}
            {this.props.isDetail && this.props.customerNo && (
              <NavLink
                to={`/sales/pay?customer__customer_no=${this.props.customerNo}`}
                className={"btn btn-light mr-3"}
              >Zobrazit objednávky</NavLink>
            )}
            {this.props.isDetail && this.props.customerId && (
              <NavLink
                to={`/customers/${this.props.customerId}/history`}
                className={"btn btn-light mr-3"}
              >Historie změn</NavLink>
            )}
            {this.props.isDetail && this.props.customerId && (
              <NavLink
                to={`/customers/${this.props.customerId}/sponsor-history`}
                className={"btn btn-light mr-3"}
              >Historie sponzorů</NavLink>
            )}
            {this.props.isDetail && this.props.customerId && (
              <NavLink
                to={`/reports/commissions/customer-points?customer_id=${this.props.customerId}`}
                className={"btn btn-light mr-3"}
              >Přehled bodů</NavLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}
