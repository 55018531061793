import React from "react"
import {NavLink} from "react-router-dom"
import Grid from "../../Base/Grid"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {getParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"

export default class OrderMethodList extends Grid {
  constructor(props) {
    super(props)

    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      isLoading: false,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
      _columns: [],
    }

    this.apiUrl = "/orderMethods"
    this.appUrl = "/enums/order-methods"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={`${this.appUrl}/add`}
            className={"btn btn-primary"}
          >Přidat způsob objednání</NavLink>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Název",
      dataField: "name",
      formatter: this.formatName.bind(this),
    }, {
      text: "Cena poštovného",
      dataField: "price",
    }, {
      text: "Limit ceny poštovného",
      dataField: "sale_price_limit",
    },]
  }

  formatName(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/enums/order-methods/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }
}
