import React, {Component} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import {NavLink} from "react-router-dom"
import axios from "axios"
import moment from "moment"
import API from "../../API"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import PAYMENT_METHOD from "../../Base/Enum/PaymentMethod"
import DELIVERY_METHOD from "../../Base/Enum/DeliveryMethod"

export default class OpenSaleList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      saleStates: [],
      deliveryMethods: [],
      paymentMethods: [],
      createdSales: [],
      warehouseSales: [],
      warehouseShipments: [],
    }

    this.today = moment().format("YYYY-MM-DD")
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-4"}
        >
          <NavLink
            to={"/sales?status=created"}
            className={"btn btn-primary mb-3"}
          >
            Přejít na vytvořené online zakázky
            <span
              className={`badge badge-danger ml-2`}
            >
              {this.state.createdSales.filter(sale => sale.status === "created").length}
              /
              {this.state.createdSales.filter(sale => sale.status === "ready_unpaid").length}
            </span>
          </NavLink>

          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              columns={this.getColumns()}
              data={this.state.createdSales}
              noDataIndication={"Nic nenalezeno"}
              rowClasses={this.getCreatedSalesRowClasses.bind(this)}
            />
          </ScrollableTable>
        </div>

        <div
          className={"col-lg-4"}
        >
          <NavLink
            to={"/warehouse/sales"}
            className={"btn btn-primary mb-3"}
          >
            Přejít na zakázky k vyřízení
            <span
              className={`badge badge-danger ml-2`}
            >{this.state.warehouseSales.length}</span>
          </NavLink>

          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              columns={this.getColumns()}
              data={this.state.warehouseSales}
              noDataIndication={"Nic nenalezeno"}
            />
          </ScrollableTable>
        </div>

        <div
          className={"col-lg-4"}
        >
          <NavLink
            to={"/warehouse/shipments"}
            className={"btn btn-primary mb-3"}
          >
            Přejít na vyřízené zakázky
            <span
              className={`badge badge-danger ml-2`}
            >{this.state.warehouseShipments.length}</span>
          </NavLink>

          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              columns={this.getColumns()}
              data={this.state.warehouseShipments}
              noDataIndication={"Nic nenalezeno"}
              rowClasses={this.getWarehouseShipmentsRowClasses.bind(this)}
            />
          </ScrollableTable>
        </div>
      </div>
    )
  }

  getData() {
    axios.all([
      this.getSaleStates(),
      this.getDeliveryMethods(),
      this.getPaymentMethods(),
      this.getCreatedSales(),
      this.getUnpaidSales(),
      this.getWarehouseSales(),
      this.getWarehouseShipments(),
      this.getTodayPersonalPickups(),
    ]).then(axios.spread((
      saleStateRes,
      deliveryMethodsRes,
      paymentMethodsRes,
      createdSalesRes,
      unpaidSalesRes,
      warehouseSalesRes,
      warehouseShipmentsRes,
      todayPersonalPickupRes,
    ) => {
      this.setState({
        isLoading: false,
        saleStates: saleStateRes.data.objects,
        deliveryMethods: deliveryMethodsRes.data.objects,
        paymentMethods: paymentMethodsRes.data.objects,
        createdSales: createdSalesRes.data.objects.concat(unpaidSalesRes.data.objects),
        warehouseSales: warehouseSalesRes.data.objects,
        warehouseShipments: warehouseShipmentsRes.data.objects.concat(todayPersonalPickupRes.data.objects),
      })
    }))
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  getCreatedSales() {
    return API.get("/sales?status==created&offset=0&limit=1000")
  }

  getUnpaidSales() {
    return API.get(`/sales?status==ready_unpaid&payment_method_id=${PAYMENT_METHOD.BANK_TRANSFER}&offset=0&limit=1000`)
  }

  getWarehouseSales() {
    return API.get("/sales?status==print_ready|print_ready_paid|printed|printed_paid&offset=0&limit=1000")
  }

  getWarehouseShipments() {
    return API.get("/sales?status==packed&offset=0&limit=1000")
  }

  getTodayPersonalPickups() {
    return API.get(`/sales?status==paid&sale_date=${this.today}&offset=0&limit=1000`)
  }

  getColumns() {
    return [{
      text: "#",
      dataField: "no",
      isDummyField: true,
      formatter: this.formatNo.bind(this),
    }, {
      text: "Stav",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      formatExtraData: this.state.saleStates,
    }, {
      text: "Způsob doručení",
      dataField: "delivery_method_id",
      formatter: this.formatDeliveryMethod.bind(this),
      formatExtraData: this.state.deliveryMethods,
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state.paymentMethods,
    }, {
      text: "Č. zakázky",
      dataField: "sale_no",
      formatter: this.formatSaleNo.bind(this),
    }, {
      text: "D. pořízení",
      dataField: "sale_date",
      formatter: this.formatSaleDate.bind(this),
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      formatter: this.formatCustomerName.bind(this),
    },]
  }

  formatNo(cell, row, rowIndex, formatExtraData) {
    return rowIndex + 1
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    let status = formatExtraData.find(s => s.id === cell)

    return status.name
  }

  formatDeliveryMethod(cell, row, rowIndex, formatExtraData) {
    let deliveryMethod = formatExtraData.find(dm => dm.id === cell)

    return deliveryMethod.name
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    let paymentMethod = formatExtraData.find(pm => pm.id === cell)

    return paymentMethod.name
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatSaleDate(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    if (row.customer) {
      return (
        <NavLink
          to={`/customers/${row.customer.id}/detail`}
        >{row.customer.name || ""} {row.customer.surname || ""}</NavLink>
      )
    }

    return ""
  }

  getCreatedSalesRowClasses(row, rowIndex) {
    if (
      row.status === "ready_unpaid" &&
      row.payment_method_id === PAYMENT_METHOD.BANK_TRANSFER
    ) {
      return "bg-green"
    }

    return ""
  }

  getWarehouseShipmentsRowClasses(row, rowIndex) {
    if (
      row.status === "paid" &&
      row.paid_date === null &&
      row.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP
    ) {
      return "bg-green"
    }

    return ""
  }
}
