import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import API from "../../API"
import getProductNameByCountry from "../../Base/getProductNameByCountry"

export default class ProductKingTemplateForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      products: [],
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getDetail()
    }
  }

  render() {
    return (
      <form
        className={"row"}
        onSubmit={this.handleFormSubmit.bind(this)}
      >
        <div
          className={"col-md-5"}
        >
          <div
            className={"form-group"}
          >
            <label>Název:</label>
            <input
              type={"text"}
              className={"form-control"}
              placeholder={"Název"}
              value={this.state.name}
              onChange={this.handleNameChange.bind(this)}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Produkty:</label>
            <AsyncSelect
              value={this.state.products}
              isMulti={true}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
              onChange={this.handleProductSearchSelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              placeholder={"Vyhledat produkt"}
              required={true}
              styles={ReactSelectCustomStyles}
            />
          </div>

          <button
            type={"button"}
            className={"btn btn-warning mr-2"}
            onClick={this.handleCancel.bind(this)}
          >Zrušit
          </button>

          <button
            type={"submit"}
            className={"btn btn-success mr-2"}
            disabled={!this.state.name || this.state.products.length === 0}
          >Uložit
          </button>

          {this.props.match.params.id && (
            <button
              type={"button"}
              className={"btn btn-danger float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat</button>
          )}
        </div>
      </form>
    )
  }

  getDetail() {
    API.get(`/product-king-template/${this.props.match.params.id}`)
      .then(({data}) => {
        this.setState({
          name: data.name,
          products: data.products_data
            .map(product => Object.assign({}, product, {
              value: product.id,
              label: getProductNameByCountry(product),
            }))
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()

    let data = {
      name: this.state.name,
      products: this.state.products
        .map(product => product.id)
        .join(",")
    }

    if (this.props.match.params.id) {
      API.put(`/product-king-template/${this.props.match.params.id}`, data)
        .then(() => {
          this.props.history.push("/reports/contests/product-king/templates")
        })
    } else {
      API.post("/product-king-template", data)
        .then(() => {
          this.props.history.push("/reports/contests/product-king/templates")
        })
    }
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(product => Object.assign(product, {
          value: product.id,
          label: getProductNameByCountry(product),
        }))
        cb(data)
      })
  }

  handleProductSearchSelect(options) {
    this.setState({
      products: options || [],
    })
  }

  handleCancel() {
    this.props.history.push("/reports/contests/product-king/templates")
  }

  handleDelete() {
    API.delete(`/product-king-template/${this.props.match.params.id}`)
      .then(this.handleCancel.bind(this))
  }
}
