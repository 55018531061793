const circle = (color, margin) => ({
  display: "flex",
  alignItems: "center",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: "''",
    display: "block",
    height: 14,
    width: 14,
    margin: margin,
    border: "solid 1px #000",
  },
})

const ReactSelectCustomerTree = {
  control: (styles, state) => {
    return {
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 .3rem rgba(255, 200, 0);" : "",
    }
  },
  menu: styles => {
    return {
      ...styles,
      zIndex: 102,
      minWidth: "250px",
    }
  },
  menuList: styles => {
    return {
      ...styles,
      maxHeight: "350px",
      minWidth: "250px",
    }
  },
  multiValue: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#F0F0F0",
      ...circle(state.data.color, "0 0 0 5px"),
    }
  },
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? "#007BFF" : "transparent",
      color: state.isFocused ? "#FFFFFF" : "#000000",
      ...circle(state.data.color, "0 5px 0 0"),
    }
  }
}

export {
  ReactSelectCustomerTree,
}
