const CUSTOMER_TYPE = {
  CK: 1,
  NE: 2,
  MS: 3,
  PbP: 4,
  PP: 5,
  RD: 6,
  RP: 7,
  RZ: 8,
  REMOVED: 9,
}

export default CUSTOMER_TYPE
