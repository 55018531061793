import CUSTOMER_TYPE from "./Enum/CustomerType"

export default (orderProduct, customer, priceType) => {
  if (priceType === "customer_type") {
    if (customer.type_id === CUSTOMER_TYPE.RD) {
      if (customer.discount) {
        return orderProduct.product.prices["customer_with_discount"]
      }

      return orderProduct.product.prices["customer"]
    }

    return orderProduct.product.prices["consultant"]
  }

  return orderProduct.product.prices[priceType]
}
