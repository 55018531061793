import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarReports = () => {
  return (
    <Switch>
      <Route
        path={"/reports"}
      >
        <nav
          className={"navbar has-3rd-level navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/reports/sets"}
              className={"nav-link"}
              activeClassName={"active"}
            >Sestavy</NavLink>
            <NavLink
              to={"/reports/commissions"}
              className={"nav-link"}
              activeClassName={"active"}
            >Provize</NavLink>
            <NavLink
              to={"/reports/contests"}
              className={"nav-link"}
              activeClassName={"active"}
            >Soutěže</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarReports
