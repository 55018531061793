import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import API from "../API"
import COUNTRY from "../Base/Enum/Countries"
import {cleanState} from "../Utils"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"

export default class WatchDogForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _product: {},
      product_id: "",
      available: 0,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getDetail()
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleFormSubmit.bind(this)}
      >
        <div
          className={"row"}
        >
          <div
            className="col-5"
          >
            <div
              className={"form-group select-required"}
            >
              <label
                className={"required"}
              >Produkt:</label>

              <AsyncSelect
                value={this.state._product}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                onChange={this.handleProductSearchSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                placeholder={"Vyhledat produkt"}
                styles={ReactSelectCustomStyles}
              />
            </div>
            <div
              className={"form-group"}
            >
              <label>Počet zbývajících kusů:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                value={this.state.available}
                onChange={this.handleAvailableChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div
          className={"row"}
        >
          <div
            className={"col-2"}
          >
            {this.id && (
              <button
                type="button"
                className="btn btn-danger mr-2"
                onClick={this.handleDelete.bind(this)}
              >Smazat</button>
            )}
          </div>
          <div
            className={"col-3 text-right"}
          >
            <button
              type="button"
              className="btn btn-warning mr-2"
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type="submit"
              className="btn btn-success"
            >Uložit
            </button>
          </div>
        </div>
      </form>
    )
  }

  getDetail() {
    API.get(`/productStock/${this.id}`)
      .then(({data}) => {
        this.setState(data)

        API.get(`/products/${data.product_id}`)
          .then(({data}) => {
            let name = data.names.find(name => name.country_id === COUNTRY.CZECH_REPUBLIC).name

            this.setState({
              _product: Object.assign({}, data, {
                value: name || "",
                label: name || "",
              }),
            })
          })
      })
  }

  handleDelete() {
    API.delete(`/productStock/${this.id}`)
      .then(() => {
        this.props.history.push("/products/watch-dog")
      })
  }

  handleCancel() {
    this.props.history.push("/products/watch-dog")
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => {
          let name = item.names.find(name => name.country_id === COUNTRY.CZECH_REPUBLIC)
          return Object.assign(item, {
            value: name.name || "",
            label: name.name || "",
          })
        })
        cb(data)
      })
  }

  handleProductSearchSelect(option) {
    this.setState({
      _product: option,
      product_id: option.id,
    })
  }

  handleAvailableChange(e) {
    let available = parseInt(e.target.value, 10)
    if (!isNaN(available)) {
      this.setState({available})
    }
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let data = cleanState(this.state)

    if (this.id) {
      API.put(`/productStock/${this.id}`, data)
        .then(() => {
          this.props.history.push("/products/watch-dog")
        })
    } else {
      API.post("/productStock", data)
        .then(() => {
          this.props.history.push("/products/watch-dog")
        })
    }
  }
}
