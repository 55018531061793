import React, {Component} from "react"
import {List} from "immutable"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import Select from "react-select"
import {NavLink, Prompt} from "react-router-dom"
import moment from "moment"
import API from "../API"
import CustomerMenu from "./CustomerMenu"
import {cleanState, serializeParams} from "../Utils"
import {
  ReactSelectCustomStyles,
  ReactSelectCustomStylesHighlight
} from "../Base/ReactSelectCustomStyles"
import Note from "../Base/Note"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import COUNTRY from "../Base/Enum/Countries"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import CONTACT_TYPE from "../Base/Enum/ContactType"

export default class CustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type_id: 1,
      _type_option: null,
      customer_no: null,
      prefix: null,
      name: null,
      surname: null,
      billing_address_customer_name: null,
      billing_address_billing_name: null,
      billing_address_street: null,
      billing_address_city: null,
      billing_address_post_code: null,
      billing_address_country_code: "CZ",
      _contact_type_id: 1,
      _contact_value: null,
      _contact_is_default: false,
      contact: List(),
      country_id: 1,
      registration_no: null,
      tax_no: null,
      birth_no: null,
      license_reg: null,
      _address_customer_name: null,
      _address_delivery_name: null,
      _address_street: null,
      _address_city: null,
      _address_post_code: null,
      _address_country_code: "CZ",
      address: List(),
      bank_account: null,
      bank_id: 100,
      bank_name: null,
      bank_vs: null,
      bank_cs: "0308",
      bank_ss: null,
      is_membership_active: false,
      membership_extended_at: null,
      gdpr: false,
      gdpr_at: null,
      terms: false,
      terms_at: null,
      discount: 0,
      is_guarantor: false,
      _note_value: null,
      note: List(),
      sponsor_id: null,
      _sponsor_for_customer_no: {},
      _sponsor_for_surname: {},
      guarantor_id: null,
      _guarantor: {},
      _guarantor_for_customer_no: {},
      _guarantor_for_surname: {},
      registration_delivery_method: null,
      is_my_access_limited: false,
      // enums
      _banks: [],
      _customerTypes: [],
      _contactTypes: [],
      _countries: [],
      _registrationDeliveryMethods: [],
      //
      _isLocked: !!props.match.params.id,
    }

    if (props.match.params.id) { // user detail form
      this.id = parseInt(props.match.params.id, 10)
      this.allowCustomerNoChange = false
      this.getCustomer()
    } else { // create user form
      this.allowCustomerNoChange = true
      this.getCustomerNo() // generate customer number only for new customers
      this.getCountries()
      this.getCustomerTypes()
    }

    this.formChanges = false
    this.handleFormChanges = false

    this.sponsorChanged = !this.id // sponsor always changed on newly added customer

    this.customerNameRef = React.createRef()
  }

  componentDidMount() {
    this.getBanks()
    this.getContactTypes()
    this.getRegistrationDeliveryMethods()

    // handle changes for newly created customers too
    if (!this.id) {
      setTimeout(() => {
        this.handleFormChanges = true
      }, 5000)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // handle non saved changes
    if (
      this.handleFormChanges
      && !this.formChanges
      && JSON.stringify(cleanState(this.state)) !== JSON.stringify(cleanState(prevState))
    ) {
      this.formChanges = true
    }

    // user changed. show sponsor of previous user
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.id = this.props.match.params.id

      this.setState({
        _isLocked: true
      }, this.getCustomer.bind(this))
    }

    // sponsor changed
    if (
      this.id && // only on detail
      prevState.sponsor_id !== null && // do not handle customer fetch finish as sponsor change
      prevState.sponsor_id !== this.state.sponsor_id
    ) {
      this.sponsorChanged = true
    }
  }

  render() {
    return (
      <form
        className={"row"}
        onSubmit={this.handleFormSubmit.bind(this)}
        onKeyPress={this.handleFormKeyPress.bind(this)}
      >

        <Prompt
          message={() => this.formChanges ? "Máte neuložené změny. Opravdu chcete odejít?" : true}
        />

        <CustomerMenu
          isDetail={!!this.id}
          formCancelHandler={this.handleFormCancel.bind(this)}
          deleteHandler={this.handleDelete.bind(this)}
          customerTypeId={this.state.type_id}
          isTop={true}
          customerNo={this.state.customer_no}
          customerId={this.id}
          isLocked={this.state._isLocked}
          unlockHandler={this.handleUnlock.bind(this)}
        />

        <fieldset
          className={"col-12"}
          disabled={this.state._isLocked}
        >
          <div
            className={"row"}
          >
            <div
              className={"col-lg-10"}
            >
              <div
                className={"row"}
              >
                {/* 1st column */}
                <div
                  className={"col-lg-6"}
                >
                  <div
                    className={"form-group"}
                  >
                    <label>Typ zákazníka:</label>
                    <div
                      className={"select-required"}
                    >
                      <Select
                        options={this.state._customerTypes}
                        value={this.state._type_option || {}}
                        autoFocus={!this.id} // create customer only
                        defaultMenuIsOpen={!this.id} // create customer only
                        styles={ReactSelectCustomStyles}
                        placeholder={"Typ zákazníka"}
                        onChange={this.handleCustomerTypeSelect.bind(this)}
                      />
                    </div>
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={`form-group ${this.id ? "col-12" : "col-7"}`}
                    >
                      <label>Číslo zákazníka:</label>
                      <input
                        type={"number"}
                        className={"form-control"}
                        required={true}
                        onChange={this.handleCustomerNoChange.bind(this)}
                        value={this.state.customer_no || ""}
                        min={1}
                        max={999999999999} // 12 digits
                        disabled={!this.id}
                      />
                    </div>

                    {!this.id && (
                      <div
                        className={"form-group col-5"}
                      >
                        <label>&nbsp;</label>
                        <button
                          className={"btn btn-light w-100"}
                          type={"button"}
                          onClick={() => this.getCustomerNo(true)}
                        >Aktualizovat číslo zákazníka
                        </button>
                      </div>
                    )}
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-2"}
                    >
                      <label>Titul:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handlePrefixChange.bind(this)}
                        value={this.state.prefix || ""}
                        minLength={3}
                        maxLength={30}
                      />
                    </div>

                    <div
                      className={"form-group col-5"}
                    >
                      <label>Jméno:</label>
                      <input
                        type={"text"}
                        className={"form-control font-weight-bold bg-light-green"}
                        onChange={this.handleNameChange.bind(this)}
                        value={this.state.name || ""}
                        minLength={3}
                        maxLength={30}
                        ref={this.customerNameRef}
                      />
                    </div>

                    <div
                      className={"form-group col-5"}
                    >
                      <label>Příjmení:</label>
                      <input
                        type={"text"}
                        className={"form-control font-weight-bold bg-light-green"}
                        required={true}
                        onChange={this.handleSurnameChange.bind(this)}
                        value={this.state.surname || ""}
                        minLength={3}
                        maxLength={30}
                      />
                    </div>
                  </div>

                  <strong
                    className={"form-group d-block"}
                  >Fakturační adresa</strong>

                  <div
                    className={"form-group"}
                  >
                    <label>Jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleBillingAddressCustomerName.bind(this)}
                      value={this.state.billing_address_customer_name || ""}
                      required={true}
                      tabIndex={-1}
                      maxLength={30}
                    />
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Fakturační jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleBillingAddressBillingName.bind(this)}
                      value={this.state.billing_address_billing_name || ""}
                      tabIndex={-1}
                      maxLength={60}
                    />
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleBillingAddressStreet.bind(this)}
                      value={this.state.billing_address_street || ""}
                      required={true}
                    />
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-2 select-required"}
                    >
                      <label>PSČ:</label>
                      <AsyncCreatableSelect
                        value={{
                          label: this.state.billing_address_post_code,
                          value: this.state.billing_address_post_code,
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
                        onChange={this.handleBillingAddressPostCodeSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        required={true}
                        styles={ReactSelectCustomStyles}
                        formatCreateLabel={value => value}
                      />
                    </div>

                    <div
                      className={"form-group col-8 select-required"}
                    >
                      <label>Město:</label>
                      <AsyncCreatableSelect
                        value={{
                          label: this.state.billing_address_city,
                          value: this.state.billing_address_city,
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
                        onChange={this.handleBillingAddressCitySelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        required={true}
                        styles={ReactSelectCustomStyles}
                        formatCreateLabel={value => value}
                      />
                    </div>

                    <div
                      className={"form-group col-2"}
                    >
                      <label>Kód země:</label>
                      <select
                        className={"form-control"}
                        onChange={this.handleBillingAddressCountryCodeChange.bind(this)}
                        value={this.state.billing_address_country_code}
                        required={true}
                      >
                        {this.state._countries.map(country => (
                          <option
                            key={country.id}
                            value={country.code}
                          >{country.code}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <strong
                    className={"form-group d-block"}
                  >Kontakty</strong>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Vyberte typ kontaktu:</label>
                      <select
                        className={"form-control"}
                        onChange={this.handleContactTypeIdChange.bind(this)}
                        value={this.state._contact_type_id || 1}
                      >
                        {this.state._contactTypes.map((type) => (
                          <option
                            key={type.id}
                            value={type.id}
                          >{type.type}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>Vyplňte kontakt:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleContactValueChange.bind(this)}
                        value={this.state._contact_value || ""}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <div
                        className={"form-check mt-4"}
                      >
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          id={"is_default"}
                          checked={this.state._contact_is_default}
                          onChange={this.handleContactIsDefaultChange.bind(this)}
                        />
                        <label
                          htmlFor={"is_default"}
                        >Nastavit jako výchozí kontakt</label>
                      </div>
                    </div>
                  </div>

                  <div
                    className={"form-group text-right"}
                  >
                    <button
                      className={"btn btn-primary"}
                      type={"button"}
                      onClick={this.handleAddContact.bind(this)}
                    >Uložit kontakt
                    </button>
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <div
                      className={"scrollable border"}
                    >
                      {this.state.contact.map((contact, i) => (
                        <div
                          className={"px-2 py-1 nowrap"}
                          key={contact.id || i}
                        >
                          {contact.is_default && (
                            <i
                              className={"fas fa-id-badge text-success mr-2"}
                              title={"Výchozí kontakt"}
                            />
                          )}
                          <strong>{this.getContactType(contact)}</strong>: {this.formatContactValue(contact)}
                          <button
                            type={"button"}
                            className={"btn btn-danger btn-sm float-right"}
                            onClick={() => this.handleContactDelete(i)}
                          >&times;</button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* 2nd column */}
                <div
                  className={"col-lg-6"}
                >
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>Země:</label>
                      <select
                        value={this.state.country_id || 1}
                        className={"form-control"}
                        required={true}
                        onChange={this.handleCountryIdChange.bind(this)}
                      >
                        {this.state._countries.map(country => (
                          <option
                            key={country.id}
                            value={country.id}
                          >{country.name}</option>
                        ))}
                      </select>
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>Datum založení:</label>
                      <input
                        type={"datetime-local"}
                        className={"form-control"}
                        disabled={"disabled"}
                        value={this.state.created_at ? this.state.created_at.replace(" ", "T") : moment().format("YYYY-MM-DDTHH:mm:ss")}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <div
                        className={"form-check mb-0"}
                      >
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          id={"is_membership_active"}
                          disabled={true}
                          checked={this.state.is_membership_active}
                        />
                        <label
                          htmlFor={"is_membership_active"}
                        >Datum úhrady čl. poplatku:</label>
                      </div>
                      <input
                        type={"date"}
                        className={"form-control"}
                        disabled={true}
                        value={this.state.membership_extended_at || ""}
                      />
                    </div>
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>IČO:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleRegistrationNoChange.bind(this)}
                        value={this.state.registration_no || ""}
                        minLength={8}
                        maxLength={8}
                        required={this.isRegistrationNoRequired()}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>DIČ:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleTaxNoChange.bind(this)}
                        value={this.state.tax_no || ""}
                        minLength={1}
                        maxLength={30}
                        required={this.isTaxNoRequired()}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>RČ:</label>
                      <input
                        type={"number"}
                        className={"form-control"}
                        onChange={this.handleBirthNoChange.bind(this)}
                        value={this.state.birth_no || ""}
                        minLength={9}
                        maxLength={10}
                      />
                    </div>
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Registrace ŽL:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleLicenseRegChange.bind(this)}
                      value={this.state.license_reg || ""}
                      minLength={3}
                      maxLength={250}
                      required={this.isLicenseRegRequired()}
                    />
                  </div>

                  <strong
                    className={"form-group d-block"}
                  >Doručovací adresy</strong>

                  <div
                    className={"scrollable border"}
                  >
                    {this.state.address.map((address, i) => (
                      <div
                        className={"px-2 py-1"}
                        key={address.id || i}
                      >
                        {address.is_active && (
                          <button
                            className={"btn btn-danger btn-sm float-right"}
                            type={"button"}
                            onClick={() => this.handleDeleteAddress(i)}
                          >&times;</button>
                        )}

                        <button
                          className={"btn btn-info btn-sm float-right fas fa-pen mr-1"}
                          type={"button"}
                          onClick={() => this.handleEditAddress(i)}
                        />

                        <span
                          className={address.is_active ? "" : "opacity-disabled"}
                          title={address.is_active ? "" : "Smazaná adresa"}
                        >
                          {address.is_default && (
                            <i
                              className={"fas fa-id-badge text-success mr-2"}
                              title={"Výchozí adresa"}
                            />
                          )}
                          {address.customer_name}
                          {address.delivery_name
                            ? <span>&nbsp;({address.delivery_name})</span>
                            : ""
                          },&nbsp;
                          {address.street},&nbsp;
                          {address.city},&nbsp;
                          {address.post_code},&nbsp;
                          {address.country_code}
                        </span>
                      </div>
                    ))}
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleAddressCustomerNameChange.bind(this)}
                      value={this.state._address_customer_name || ""}
                      required={this.isDeliveryAddressRequired()}
                      maxLength={60}
                    />
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Doručovací jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleAddressDeliveryNameChange.bind(this)}
                      value={this.state._address_delivery_name || ""}
                    />
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleAddressStreet.bind(this)}
                      value={this.state._address_street || ""}
                      required={this.isDeliveryAddressRequired()}
                    />
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={`form-group col-2 ${this.isDeliveryAddressRequired() ? "select-required" : ""}`}
                    >
                      <label>PSČ:</label>
                      <AsyncCreatableSelect
                        value={{
                          label: this.state._address_post_code,
                          value: this.state._address_post_code,
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
                        onChange={this.handleDeliveryAddressPostCodeSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        required={this.isDeliveryAddressRequired()}
                        styles={ReactSelectCustomStyles}
                        formatCreateLabel={value => value}
                      />
                    </div>

                    <div
                      className={`form-group col-8 ${this.isDeliveryAddressRequired() ? "select-required" : ""}`}
                    >
                      <label>Město:</label>
                      <AsyncCreatableSelect
                        value={{
                          label: this.state._address_city || "",
                          value: this.state._address_city || "",
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
                        onChange={this.handleDeliveryAddressCitySelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        required={this.isDeliveryAddressRequired()}
                        styles={ReactSelectCustomStyles}
                        formatCreateLabel={value => value}
                      />
                    </div>

                    <div
                      className={"form-group col-2"}
                    >
                      <label>Kód země:</label>
                      <select
                        className={"form-control"}
                        onChange={this.handleAddressCountryCodeChange.bind(this)}
                        value={this.state._address_country_code}
                        required={this.isDeliveryAddressRequired()}
                      >
                        {this.state._countries.map(country => (
                          <option
                            key={country.id}
                            value={country.code}
                          >{country.code}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div
                    className={"form-group text-right"}
                  >
                    <button
                      className={"btn btn-primary"}
                      type={"button"}
                      onClick={this.handleAddAddress.bind(this)}
                      disabled={!(
                        this.state._address_customer_name &&
                        this.state._address_street &&
                        this.state._address_city &&
                        this.state._address_post_code
                      )}
                    >Uložit doručovací adresu
                    </button>
                  </div>

                  <strong
                    className={"form-group d-block"}
                  >Bankovní účet</strong>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-8"}
                    >
                      <label>Číslo účtu:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleBankAccountChange.bind(this)}
                        value={this.state.bank_account || ""}
                        required={this.isBankAccountRequired()}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>Kód banky:</label>
                      <select
                        className={"form-control"}
                        onChange={this.handleBankIdChange.bind(this)}
                        value={this.state.bank_id || 1}
                      >
                        {this.state._banks
                          .filter(this.getFilteredBanks.bind(this))
                          .map(bank => (
                            <option
                              key={bank.id}
                              value={bank.id}
                            >{bank.code} - {bank.name}, {bank.country_code}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <label>Název banky:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleBankNameChange.bind(this)}
                      value={this.state.bank_name || ""}
                    />
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-4"}
                    >
                      <label>VS:</label>
                      <input
                        type={"number"}
                        className={"form-control"}
                        onChange={this.handleBankVSChange.bind(this)}
                        value={this.state.bank_vs || ""}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>KS:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleBankCSChange.bind(this)}
                        value={this.state.bank_cs || ""}
                        minLength={3}
                        maxLength={30}
                      />
                    </div>

                    <div
                      className={"form-group col-4"}
                    >
                      <label>SS:</label>
                      <input
                        type={"text"}
                        className={"form-control"}
                        onChange={this.handleBankSSChange.bind(this)}
                        value={this.state.bank_ss || ""}
                        minLength={3}
                        maxLength={30}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={"col-12"}
                >
                  <strong
                    className={"form-group d-block"}
                  >Poznámky</strong>

                  <div
                    className={"form-group"}
                  >
                    <label>Vyplňte text poznámky:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleNoteTextChange.bind(this)}
                      value={this.state._note_value || ""}
                    />
                  </div>

                  <div
                    className={"form-group text-right"}
                  >
                    <button
                      className={"btn btn-danger mr-2"}
                      type={"button"}
                      onClick={this.handleAddWarnNote.bind(this)}
                    >Uložit varovnou poznámku
                    </button>

                    <button
                      className={"btn btn-primary"}
                      type={"button"}
                      onClick={this.handleAddNormalNote.bind(this)}
                    >Uložit obyčejnou poznámku
                    </button>
                  </div>

                  <div
                    className={"form-group"}
                  >
                    <div
                      className={"scrollable border p-3"}
                    >
                      <table
                        className={"table mb-0"}
                      >
                        <tbody>
                        {this.state.note.map((note, i) => (
                          <Note
                            key={note.id || i}
                            id={note.id || i}
                            index={i}
                            note={note}
                            deleteHandler={() => this.handleDeleteNote(i)}
                          />
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 3rd column */}
            <div
              className={"col-lg-2"}
            >
              <label>Sponzor - zákaznické číslo:</label>

              <div
                className={"form-group select-required"}
              >
                <AsyncSelect
                  value={this.state._sponsor_for_customer_no || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleSponsorCustomerNoChange.bind(this))}
                  onChange={this.handleSponsorCustomerNoSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  required={true}
                  styles={ReactSelectCustomStyles}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Jméno:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={!this.state.sponsor_id}
                  disabled={true}
                  value={this.state._sponsor_for_customer_no.name || ""}
                />
              </div>

              <div
                className={"form-group select-required"}
              >
                <label>Příjmení:</label>
                <AsyncSelect
                  value={this.state._sponsor_for_surname || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleSponsorSurnameChange.bind(this))}
                  onChange={this.handleSponsorSurnameSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  styles={ReactSelectCustomStylesHighlight}
                />
              </div>

              {this.state.sponsor_id && this.state.id !== this.state.sponsor_id && (
                <div
                  className={"form-group"}
                >
                  <NavLink
                    to={`/customers/${this.state.sponsor_id}/detail`}
                    className={"btn btn-primary mb-2 d-block"}
                  >Zobrazit detail sponzora</NavLink>
                </div>
              )}

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <div
                className={"form-group"}
              >
                <label>Způsob doručení registrace:</label>
                <select
                  className={"form-control"}
                  value={this.state.registration_delivery_method || ""}
                  onChange={this.handleRegistrationDeliveryMethodChange.bind(this)}
                >
                  <option
                    value={""}
                  >Způsob doručení registrace
                  </option>
                  {this.state._registrationDeliveryMethods.map(method => (
                    <option
                      key={method.id}
                      value={method.id}
                    >{method.name}</option>
                  ))}
                </select>
              </div>

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <div
                className={"form-row"}
              >
                {this.state.type_id === CUSTOMER_TYPE.RD && (
                  <div
                    className={"form-group col-6"}
                  >
                    <div
                      className={"form-check"}
                    >
                      <input
                        type={"checkbox"}
                        className={"form-check-input"}
                        id={"discount"}
                        onChange={this.handleDiscountChange.bind(this)}
                        checked={this.state.discount !== 0}
                      />
                      <label
                        htmlFor={"discount"}
                        className={"form-check-label"}
                      >Sleva 10 %</label>
                    </div>
                  </div>
                )}

                <div
                  className={"form-group col-6"}
                >
                  <div
                    className={"form-check"}
                  >
                    <input
                      type={"checkbox"}
                      className={"form-check-input"}
                      id={"is_guarantor"}
                      onChange={this.handleIsGuarantorChange.bind(this)}
                      checked={this.state.is_guarantor || false}
                    />
                    <label
                      htmlFor={"is_guarantor"}
                      className={"form-check-label"}
                    >Může být garant</label>
                  </div>
                </div>
              </div>

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <strong
                className={"form-group d-block"}
              >Věrnostní Kč</strong>

              <div
                className={"form-group"}
              >
                <input
                  type={"text"}
                  className={"form-control"}
                  disabled={true}
                  value={this.getBonusMoneyState("total")}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Z minulých let:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  disabled={true}
                  value={this.getBonusMoneyState("previous_years")}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Letos:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  disabled={true}
                  value={this.getBonusMoneyState("current_year")}
                />
              </div>

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <strong
                className={"form-group d-block text-right"}
              >
              <span
                className={"float-left mt-2"}
              >Garant</span>
                <button
                  className={"btn btn-light"}
                  type={"button"}
                  onClick={this.handleCopySponsor.bind(this)}
                  disabled={!this.state._sponsor_for_customer_no.is_guarantor}
                >Kopírovat sponzora
                </button>
              </strong>

              <div
                className={`form-group ${this.isGuarantorRequired() ? "select-required" : ""}`}
              >
                <label>Zákaznické číslo:</label>
                <AsyncSelect
                  value={this.state._guarantor_for_customer_no || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleGuarantorCustomerNoChange.bind(this))}
                  onChange={this.handleGuarantorCustomerNoSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  styles={ReactSelectCustomStyles}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Jméno:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={this.isGuarantorRequired()}
                  disabled={true}
                  value={this.state._guarantor_for_customer_no.name || ""}
                />
              </div>

              <div
                className={`form-group ${this.isGuarantorRequired() ? "select-required" : ""}`}
              >
                <label>Příjmení:</label>
                <AsyncSelect
                  value={this.state._guarantor_for_surname || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleGuarantorSurnameChange.bind(this))}
                  onChange={this.handleGuarantorSurnameSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  styles={ReactSelectCustomStyles}
                />
              </div>

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <div
                className={"form-group form-check mb-0"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  id={"gdpr"}
                  onChange={this.handleGDPRChange.bind(this)}
                  checked={this.state.gdpr || false}
                />
                <label
                  htmlFor={"gdpr"}
                >GDPR:</label>
              </div>
              <div
                className={"form-group"}
              >
                <input
                  type={"date"}
                  className={"form-control"}
                  onChange={this.handleGDPRAtChange.bind(this)}
                  value={this.state.gdpr_at || ""}
                  required={this.state.gdpr}
                />
              </div>

              <div
                className={"form-group form-check mb-0"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  id={"terms"}
                  onChange={this.handleTermsChange.bind(this)}
                  checked={this.state.terms || false}
                />
                <label
                  htmlFor={"terms"}
                >Obchodní podmínky:</label>
              </div>
              <div
                className={"form-group"}
              >
                <input
                  type={"date"}
                  className={"form-control"}
                  onChange={this.handleTermsAtChange.bind(this)}
                  value={this.state.terms_at || ""}
                  required={this.state.terms}
                />
              </div>

              {/* spacer */}
              <strong
                className={"form-group d-block"}
              >&nbsp;</strong>

              <div
                className={"form-group form-check"}
              >
                <label>
                  <input
                    type={"checkbox"}
                    className={"form-check-input"}
                    onChange={this.handleIsMyAccessLimited.bind(this)}
                    checked={this.state.is_my_access_limited}
                  />
                  Omezený přístup do moje.missiva.cz
                </label>
              </div>
            </div>
          </div>
        </fieldset>

        <CustomerMenu
          isDetail={!!this.id}
          formCancelHandler={this.handleFormCancel.bind(this)}
          deleteHandler={this.handleDelete.bind(this)}
          customerTypeId={this.state.type_id}
          isTop={false}
          customerNo={this.state.customer_no}
          isLocked={this.state._isLocked}
          unlockHandler={this.handleUnlock.bind(this)}
        />
      </form>
    )
  }

  handleUnlock() {
    if (this.state._isLocked && window.confirm("Opravdu chcete upravit existujícího zákazníka?")) {
      this.setState({
        _isLocked: false
      })
    }
  }

  getContactType(contact) {
    let contactTypes = this.state._contactTypes.filter(contactType => contactType.id === contact.type_id)

    if (contactTypes.length > 0) {
      return contactTypes[0].type
    }

    return ""
  }

  formatContactValue(contact) {
    if (contact.type_id === CONTACT_TYPE.EMAIL) {
      return (
        <a
          href={`mailto:${contact.value}`}
        >{contact.value}</a>
      )
    }

    if (
      contact.type_id === CONTACT_TYPE.TARIFF_02 ||
      contact.type_id === CONTACT_TYPE.PHONE_HOME ||
      contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
      contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
      contact.type_id === CONTACT_TYPE.PHONE_WORK
    ) {
      return (
        <NavLink
          to={`/communication/sms/${contact.value}/${this.id || ""}`}
        >{contact.value}</NavLink>
      )
    }

    return contact.value
  }

  handleCustomerTypeSelect(option) {
    this.setState({
      type_id: option.id,
      _type_option: option,
      // reset discount if customer type is not RD
      discount: option.id === CUSTOMER_TYPE.RD ? this.state.discount : 0,
    }, () => {
      this.customerNameRef.current.focus()
    })
  }

  handleCustomerNoChange(e) {
    if (!this.allowCustomerNoChange) {
      this.allowCustomerNoChange = window.confirm("Opravdu chcete upravit zákaznícké číslo?")
    }

    if (this.allowCustomerNoChange) {
      this.setState({
        customer_no: parseInt(e.target.value, 10)
      })
    }
  }

  handlePrefixChange(e) {
    this.setState({
      prefix: e.target.value,
    })
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
      billing_address_customer_name: `${e.target.value} ${this.state.surname || ""}`,
      _address_customer_name: `${e.target.value} ${this.state.surname || ""}`,
    })
  }

  handleSurnameChange(e) {
    this.setState({
      surname: e.target.value,
      billing_address_customer_name: `${this.state.name || ""} ${e.target.value}`,
      _address_customer_name: `${this.state.name || ""} ${e.target.value}`,
    })
  }

  handleBillingAddressCustomerName(e) {
    this.setState({
      billing_address_customer_name: e.target.value,
      _address_customer_name: e.target.value,
    })
  }

  handleBillingAddressBillingName(e) {
    this.setState({
      billing_address_billing_name: e.target.value,
    })
  }

  handleBillingAddressStreet(e) {
    this.setState({
      billing_address_street: e.target.value,
      _address_street: e.target.value,
    })
  }

  handleAddressCityChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&city=^${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.city,
          label: `${item.city} (${item.post_code})`,
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressCitySelect(option) {
    this.setState({
      billing_address_city: option.__isNew__ ? option.value : option.city,
      billing_address_post_code: option.__isNew__ ? this.state.billing_address_post_code : option.post_code,
      billing_address_country_code: option.__isNew__ ? this.state.billing_address_country_code : option.country.code,
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state._address_post_code : option.post_code,
      _address_country_code: option.__isNew__ ? this.state._address_country_code : option.country.code,
    })
  }

  handleAddressPostCodeChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&post_code=${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.city,
          label: `${item.post_code} (${item.city})`,
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressPostCodeSelect(option) {
    this.setState({
      billing_address_post_code: option.__isNew__ ? option.value : option.post_code,
      billing_address_city: option.__isNew__ ? this.state.billing_address_city : option.city,
      billing_address_country_code: option.__isNew__ ? this.state.billing_address_country_code : option.country.code,
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
      _address_country_code: option.__isNew__ ? this.state._address_country_code : option.country.code,
    })
  }

  handleBillingAddressCountryCodeChange(e) {
    this.setState({
      billing_address_country_code: e.target.value,
      _address_country_code: e.target.value,
    })
  }

  handleContactTypeIdChange(e) {
    this.setState({
      _contact_type_id: parseInt(e.target.value, 10),
    })
  }

  handleContactValueChange(e) {
    this.setState({
      _contact_value: e.target.value,
    })
  }

  handleContactIsDefaultChange(e) {
    this.setState({
      _contact_is_default: e.target.checked,
    })
  }

  handleAddContact() {
    if (this.state._contact_value !== null) {
      // validate phone number
      if (this.state._contact_type_id >= 3 && this.state._contact_type_id <= 7) {
        API.post("/validate/contact", {
          type_id: this.state._contact_type_id,
          value: this.state._contact_value,
          country_code: this.getCountryCode(this.state.country_id)
        }).then(this.addContact.bind(this))
      }

      // validate email
      if (this.state._contact_type_id === 1) {
        API.post("/validate/contact", {
          type_id: this.state._contact_type_id,
          value: this.state._contact_value,
        }).then(this.addContact.bind(this))
      }
    }
  }

  getCountryCode(countryId) {
    let countries = this.state._countries.filter(country => country.id === countryId)

    if (countries.length > 0) {
      return countries[0].country_code
    }

    return "CZ"
  }

  addContact() {
    let contacts = this.state.contact.toArray()

    if (this.state._contact_is_default) {
      contacts.map(contact => {
        if (contact.type_id === this.state._contact_type_id) {
          contact.is_default = false
        }
        return contact
      })
    }

    contacts.push({
      type_id: this.state._contact_type_id,
      value: this.state._contact_value,
      is_default: this.state._contact_is_default,
    })

    this.setState({
      contact: List(contacts),
      _contact_type_id: 1,
      _contact_value: null,
      _contact_is_default: false,
    })
  }

  handleContactDelete(i) {
    this.setState({
      contact: this.state.contact.delete(i)
    })
  }

  handleCountryIdChange(e) {
    this.setState({
      country_id: parseInt(e.target.value, 10),
    }, () => {
      let banks = this.state._banks.filter(this.getFilteredBanks.bind(this))

      this.setState({
        bank_name: banks.length > 0 ? banks[0].name : "",
      })
    })
  }

  handleLicenseRegChange(e) {
    this.setState({
      license_reg: e.target.value,
    })
  }

  handleRegistrationNoChange(e) {
    let registrationNo = e.target.value
    this.setState({
      registration_no: registrationNo,
      bank_ss: registrationNo,
      customer_no: this.state.type_id === CUSTOMER_TYPE.RZ ? registrationNo : this.state.customer_no
    }, () => {
      if (this.state.registration_no.length === 8) {
        this.isRegistrationNoDuplicate()

        if (!this.id) {
          this.getARESData()
        }
      }
    })
  }

  handleTaxNoChange(e) {
    this.setState({
      tax_no: e.target.value
    }, () => {
      if (`${this.state.tax_no}`.length === 10) {
        this.isTaxNoDuplicate()
      }
    })
  }

  handleBirthNoChange(e) {
    this.setState({
      birth_no: parseInt(e.target.value, 10),
    })
  }

  handleAddressCustomerNameChange(e) {
    this.setState({
      _address_customer_name: e.target.value,
    })
  }

  handleAddressDeliveryNameChange(e) {
    this.setState({
      _address_delivery_name: e.target.value
    })
  }

  handleAddressStreet(e) {
    this.setState({
      _address_street: e.target.value
    })
  }

  handleDeliveryAddressCitySelect(option) {
    this.setState({
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state._address_post_code : option.post_code,
      _address_country_code: option.__isNew__ ? this.state._address_country_code : option.country.code,
    })
  }

  handleDeliveryAddressPostCodeSelect(option) {
    this.setState({
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
      _address_country_code: option.__isNew__ ? this.state._address_country_code : option.country.code,
    })
  }

  handleAddressCountryCodeChange(e) {
    this.setState({
      _address_country_code: e.target.value
    })
  }

  handleAddAddress() {
    this.setState({
      address: this.state.address.push({
        customer_name: this.state._address_customer_name,
        delivery_name: this.state._address_delivery_name,
        street: this.state._address_street,
        city: this.state._address_city,
        post_code: this.state._address_post_code,
        country_code: this.state._address_country_code,
        is_active: true,
      }),
    })
  }

  handleDeleteAddress(i) {
    this.setState({
      address: this.state.address.delete(i)
    })
  }

  handleEditAddress(i) {
    let address = this.state.address.get(i)

    this.setState({
      _address_customer_name: address.customer_name,
      _address_delivery_name: address.delivery_name,
      _address_street: address.street,
      _address_city: address.city,
      _address_post_code: address.post_code,
      _address_country_code: address.country_code,
    })
  }

  handleBankAccountChange(e) {
    this.setState({
      bank_account: e.target.value,
    })
  }

  handleBankIdChange(e) {
    let bankId = parseInt(e.target.value, 10)
    let bank = this.state._banks.filter(bank => bank.id === bankId)

    this.setState({
      bank_id: bankId,
      bank_name: bank.length > 0 ? bank[0].name : "",
    })
  }

  getFilteredBanks(bank) {
    if (this.state.country_id === COUNTRY.CZECH_REPUBLIC) {
      return bank.country_code === "CZ"
    } else if (this.state.country_id === COUNTRY.SLOVAK_REPUBLIC) {
      return bank.country_code === "SK"
    } else {
      return true // all
    }
  }

  handleBankNameChange(e) {
    this.setState({
      bank_name: e.target.value,
    })
  }

  handleBankVSChange(e) {
    this.setState({
      bank_vs: parseInt(e.target.value, 10),
    })
  }

  handleBankCSChange(e) {
    this.setState({
      bank_cs: e.target.value,
    })
  }

  handleBankSSChange(e) {
    this.setState({
      bank_ss: e.target.value,
    })
  }

  handleGDPRChange(e) {
    let checked = e.target.checked
    this.setState({
      gdpr: checked,
      gdpr_at: checked ? moment().format("YYYY-MM-DD") : "",
    })
  }

  handleGDPRAtChange(e) {
    this.setState({
      gdpr_at: e.target.value
    })
  }

  handleTermsChange(e) {
    let checked = e.target.checked
    this.setState({
      terms: checked,
      terms_at: checked ? moment().format("YYYY-MM-DD") : ""
    })
  }

  handleTermsAtChange(e) {
    this.setState({
      terms_at: e.target.value,
    })
  }

  handleIsMyAccessLimited(e) {
    this.setState({
      is_my_access_limited: e.target.checked,
    })
  }

  handleRegistrationDeliveryMethodChange(e) {
    this.setState({
      registration_delivery_method: e.target.value
    })
  }

  handleDiscountChange(e) {
    this.setState({
      discount: e.target.checked ? 10 : 0
    })
  }

  handleIsGuarantorChange(e) {
    this.setState({
      is_guarantor: e.target.checked,
    })
  }

  handleCopySponsor() {
    this.setState({
      _guarantor_for_customer_no: this.state._sponsor_for_customer_no,
      _guarantor_for_surname: this.state._sponsor_for_surname,
      guarantor_id: this.state.sponsor_id
    })
  }

  handleNoteTextChange(e) {
    this.setState({
      _note_value: e.target.value,
    })
  }

  handleAddWarnNote() {
    if (this.state._note_value !== "") {
      this.setState({
        note: this.state.note.push({
          is_warn: true,
          value: this.state._note_value,
          created_at: new Date(),
        }),
        _note_value: ""
      })
    }
  }

  handleAddNormalNote() {
    if (this.state._note_value !== "") {
      this.setState({
        note: this.state.note.push({
          is_warn: false,
          value: this.state._note_value,
          created_at: new Date(),
        }),
        _note_value: ""
      })
    }
  }

  handleDeleteNote(i) {
    this.setState({
      note: this.state.note.delete(i)
    })
  }

  handleSponsorCustomerNoChange(val, cb) {
    let params = {
      limit: 20,
      offset: 0,
      sort: "customer_no",
      customer_no: `^${val}`,
      id: this.id ? `!${this.id}` : "",
      type_id: `=${[
        CUSTOMER_TYPE.CK,
        CUSTOMER_TYPE.NE,
        CUSTOMER_TYPE.MS,
        CUSTOMER_TYPE.PbP,
        CUSTOMER_TYPE.PP,
      ].join("|")}`
    }

    API.get(`/customers${serializeParams(params)}`)
      .then(({data}) => {
        data.objects.map(customer => Object.assign(customer, {
          value: customer.customer_no,
          label: customer.customer_no,
        }))
        cb(data.objects)
      })
  }

  handleSponsorCustomerNoSelect(option) {
    this.setState({
      _sponsor_for_customer_no: option,
      _sponsor_for_surname: Object.assign({}, option, {
        value: option.surname,
        label: option.surname
      }),
      sponsor_id: option.id
    })
  }

  handleSponsorSurnameChange(val, cb) {
    let params = {
      limit: 20,
      offset: 0,
      sort: "surname",
      surname: val,
      id: this.id ? `!${this.id}` : "",
      type_id: `=${[
        CUSTOMER_TYPE.CK,
        CUSTOMER_TYPE.NE,
        CUSTOMER_TYPE.MS,
        CUSTOMER_TYPE.PbP,
        CUSTOMER_TYPE.PP,
      ].join("|")}`
    }

    API.get(`/customers${serializeParams(params)}`)
      .then(({data}) => {
        data.objects.map(customer => Object.assign(customer, {
          value: `${customer.surname || ""} ${customer.name || ""}`,
          label: `${customer.surname || ""} ${customer.name || ""}`,
        }))
        cb(data.objects)
      })
  }

  handleSponsorSurnameSelect(option) {
    this.setState({
      _sponsor_for_surname: Object.assign({}, option, {
        value: option.surname,
        label: option.surname
      }),
      _sponsor_for_customer_no: Object.assign({}, option, {
        value: option.customer_no,
        label: option.customer_no
      }),
      sponsor_id: option.id
    })
  }

  handleGuarantorCustomerNoChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&is_guarantor=1&customer_no=^${val}&sort=customer_no`)
      .then(({data}) => {
        data.objects.forEach(item => Object.assign(item, {
          value: item.customer_no,
          label: item.customer_no
        }))
        cb(data.objects)
      })
  }

  handleGuarantorCustomerNoSelect(option) {
    this.setState({
      _guarantor_for_customer_no: option,
      _guarantor_for_surname: Object.assign({}, option, {
        value: option.surname,
        label: option.surname
      }),
      guarantor_id: option.id
    })
  }

  handleGuarantorSurnameChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&is_guarantor=1&surname=${val}`)
      .then(({data}) => {
        data.objects.forEach(item => Object.assign(item, {
          value: `${item.surname} ${item.name || ""}`,
          label: `${item.surname} ${item.name || ""}`,
        }))
        cb(data.objects)
      })
  }

  handleGuarantorSurnameSelect(option) {
    this.setState({
      _guarantor_for_surname: Object.assign({}, option, {
        value: option.surname,
        label: option.surname,
      }),
      _guarantor_for_customer_no: Object.assign({}, option, {
        value: option.customer_no,
        label: option.customer_no
      }),
      guarantor_id: option.id
    })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let data = cleanState(this.state)

    if (data.address.length === 0) {
      return alert("Přidejte alespoň jednu doručovací adresu.")
    }

    if (!data.sponsor_id) {
      return alert("Vyplňte sponzora.")
    }

    if (
      !data.contact.some(contact =>
        contact.type_id === CONTACT_TYPE.TARIFF_02 ||
        contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
        contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
        contact.type_id === CONTACT_TYPE.PHONE_HOME ||
        contact.type_id === CONTACT_TYPE.PHONE_WORK) ||
      !data.contact.some(contact => contact.type_id === CONTACT_TYPE.EMAIL)
    ) {
      alert("Email nebo telefon není vyplněn.")
    }

    // Backend returns whole sponsor/guarantor detail. Detail have higher priority than
    // sponsor_id/guarantor_id on create (POST) or update (PUT). If we change only
    // sponsor_id/guarantor_id, backend will use the detail and nothing change. So, before
    // request remove sponsor/guarantor detail.
    data.sponsor = null
    data.guarantor = null

    if (data.created_at) {
      data.created_at = data.created_at.replace("T", " ")
    }

    if (this.id) {
      this.updateCustomer(data)
    } else {
      this.addCustomer(data)
    }
  }

  handleFormKeyPress(e) {
    // disable form submit by enter
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  handleDelete() {
    API.delete(`/customers/${this.id}`)
      .then(() => {
        this.formChanges = false
        this.props.history.push("/customers")
      })
  }

  handleFormCancel() {
    this.props.history.push("/customers")
  }

  addCustomer(data) {
    API.post("/customers", data)
      .then(res => {
        if (this.sponsorChanged) {
          this.sponsorChanged = false
          API.get(`/points/calculate/${moment().format("YYYY/MM")}`)
        }

        alert("Zákazník byl úspěšně uložen.")
        this.formChanges = false
        this.props.history.push(`/customers/${res.data.id}/detail`)
      })
  }

  updateCustomer(data) {
    API.put(`/customers/${this.id}`, data)
      .then(() => {
        if (this.sponsorChanged) {
          this.sponsorChanged = false
          API.get(`/points/calculate/${moment().format("YYYY/MM")}`)
        }

        this.setState({
          _isLocked: true,
        }, () => {
          alert("Zákazník byl úspěšně uložen.")
          this.formChanges = false
        })
      })
  }

  getCustomer() {
    API.get(`/customers/${this.id}`)
      .then(({data}) => {
        // handle form changes the same way when user changed as in the first load
        this.handleFormChanges = false
        this.formChanges = false
        setTimeout(() => {
          this.handleFormChanges = true
        }, 5000)

        data.note = List(data.note)
        data.address = List(data.address)
        data.contact = List(data.contact)

        this.setState(data)

        // sponsor is required
        this.getSponsor(data.sponsor_id)
        // guarantor is optional
        if (data.guarantor_id)
          this.getGuarantor(data.guarantor_id)
        // defer countries till we have customer detail
        // old customers do not have billing_address_country_code, so we have to update it
        this.getCountries()
        // defer types till we have customer detail
        // set correct type option for react-select
        this.getCustomerTypes()
      })
  }

  getCustomerTypes() {
    API.get("/customerType?limit=0&offset=0")
      .then(res => {
        let customerTypes = res.data.objects.map(type => Object.assign({}, type, {
          value: type.id,
          label: type.name,
        }))
        let customerType = customerTypes.filter(type => type.id === this.state.type_id)
        this.setState({
          _customerTypes: customerTypes,
          _type_option: customerType.length > 0 ? customerType[0] : {},
        })
      })
  }

  getBanks() {
    API.get("/banks?limit=0&offset=0&sort=code")
      .then(({data}) => {
        let banks = data.objects

        this.setState({
          _banks: banks,
          bank_name: banks.length > 0 ? banks[0].name : "",
        })
      })
  }

  getContactTypes() {
    API.get("/contactTypes")
      .then((res) => {
        this.setState({
          _contactTypes: res.data.objects
        })
      })
  }

  getCountries() {
    API.get("/countries")
      .then(res => {
        this.setState({
          _countries: res.data.objects
        })
        if (!this.state.billing_address_country_code) {
          let country = res.data.objects.filter(country => country.id === this.state.country_id)
          this.setState({
            billing_address_country_code: country.length > 0 ? country[0].code : "CZ"
          })
        }
      })
  }

  getSponsor(id) {
    API.get(`/customers/${id}`)
      .then(({data}) => {
        this.setState({
          _sponsor_for_customer_no: Object.assign({
            value: data.customer_no,
            label: data.customer_no,
          }, data),
          _sponsor_for_surname: Object.assign({
            value: data.surname,
            label: data.surname,
          }, data),
        })
      })
  }

  getGuarantor(id) {
    API.get(`/customers/${id}`)
      .then((res) => {
        this.setState({
          _guarantor_for_customer_no: Object.assign({
            value: res.data.customer_no,
            label: res.data.customer_no,
          }, res.data),
          _guarantor_for_surname: Object.assign({
            value: res.data.surname,
            label: res.data.surname,
          }, res.data),
        })
      })
  }

  getCustomerNo(showAlert = false) {
    API.get("/customers/customer_no")
      .then(({data}) => {
        this.setState({
          customer_no: data.customer_no,
        }, () => {
          if (showAlert) {
            alert("Zákaznické číslo bylo aktualizováno.")
          }
        })
      })
  }

  getARESData() {
    API.get(`/customer/ares?ic=${this.state.registration_no}`)
      .then(({data}) => {
        let createdAt = ""
        let createdBy = ""
        let taxNo = ""

        if (data.VBAS && data.VBAS.DV) {
          createdAt = data.VBAS.DV
        }

        if (data.VBAS && data.VBAS.RRZ && data.VBAS.RRZ.ZU && data.VBAS.RRZ.ZU.NZU) {
          createdBy = data.VBAS.RRZ.ZU.NZU
        }

        if (data.VBAS && data.VBAS.DIC && data.VBAS.DIC.$) {
          taxNo = data.VBAS.DIC.$
        }

        this.setState({
          license_reg: `${createdBy}, ${createdAt}`,
          tax_no: taxNo,
        })
      })
  }

  getRegistrationDeliveryMethods() {
    API.get("/registrationDeliveryMethods")
      .then(({data}) => {
        this.setState({
          _registrationDeliveryMethods: data.objects
        })
      })
  }

  isRegistrationNoDuplicate() {
    API.get(`/customers?registration_no==${this.state.registration_no}&limit=10`)
      .then(({data}) => {
        if (data.objects.length > 0) {
          alert("Zadané IČO již v systému existuje.")
        }
      })
  }

  isTaxNoDuplicate() {
    API.get(`/customers?tax_no==${this.state.tax_no}&limit=10`)
      .then(({data}) => {
        if (data.objects.length > 0) {
          alert("Zadané DIČ již v systému existuje.")
        }
      })
  }

  isRegistrationNoRequired() {
    return this.state.type_id === CUSTOMER_TYPE.MS ||
      this.state.type_id === CUSTOMER_TYPE.PP ||
      this.state.type_id === CUSTOMER_TYPE.RP ||
      this.state.type_id === CUSTOMER_TYPE.RZ
  }

  isLicenseRegRequired() {
    return this.state.type_id === CUSTOMER_TYPE.MS ||
      this.state.type_id === CUSTOMER_TYPE.PP ||
      this.state.type_id === CUSTOMER_TYPE.RP ||
      this.state.type_id === CUSTOMER_TYPE.RZ
  }

  isTaxNoRequired() {
    return this.state.type_id === CUSTOMER_TYPE.MS
  }

  isBankAccountRequired() {
    return this.state.country_id === COUNTRY.CZECH_REPUBLIC && (
      this.state.type_id === CUSTOMER_TYPE.MS ||
      this.state.type_id === CUSTOMER_TYPE.PP
    )
  }

  isGuarantorRequired() {
    return this.state.type_id === CUSTOMER_TYPE.RZ
  }

  getBonusMoneyState(type) {
    if (
      this.state.account &&
      this.state.account.length > 0
    ) {
      let {
        total_in_year,
        total_out_year,
        total_prev_years,
      } = this.state.account[0]

      if (type === "total") {
        return (total_in_year - total_out_year + total_prev_years).toFixed(2)
      }

      if (type === "previous_years") {
        let leftover = total_prev_years - total_out_year

        return (leftover < 0 ? 0 : leftover).toFixed(2)
      }

      if (type === "current_year") {
        let prevYearsLeftover = total_prev_years - total_out_year
        let leftover = total_in_year + (prevYearsLeftover <= 0 ? prevYearsLeftover : 0)

        return leftover.toFixed(2)
      }

      return 0
    }
  }

  isDeliveryAddressRequired() {
    return this.state.address.size === 0 ||
      !this.state.address.toArray().some(address => address.is_active)
  }
}


