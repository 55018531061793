import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import COUNTRY from "../Base/Enum/Countries"

export default class ProductTreeNode extends Component {
  render() {
    return (
      <>
        {/* category */}
        {this.props.node.category && (
          <tr>
            <td
              className={`${this.props.depth < 2 ? "bg-primary" : "bg-light-primary"} text-center`}
            >
              <input
                type={"checkbox"}
                onChange={() => this.props.productSelectHandler(this.props.node)}
              />
            </td>

            <td
              colSpan={9}
              className={`${this.props.depth < 2 ? "bg-primary" : "bg-light-primary"} text-white text-center font-weight-bold`}
            >{this.props.node.category.name}</td>
          </tr>
        )}

        {/* product */}
        {this.props.node.product && (
          <tr>
            <td
              className={"text-center"}
            >
              <input
                type={"checkbox"}
                onChange={() => this.props.productSelectHandler(this.props.node)}
                checked={this.props.selectedProducts.indexOf(this.props.node.product_id) > -1}
              />
            </td>

            <td
              className={"text-center"}
            >
              <NavLink
                to={`/products/${this.props.node.product.id}/detail`}
              >{this.props.node.product.code}</NavLink>
            </td>

            <td>{this.getCZProductName()}</td>

            <td
              className={"text-center"}
            >{this.props.node.product.unit_count} {this.getUnitOfMeasure()}</td>

            {this.props.columnVisibility.isColumnConsultantPriceVisible && (
              <td
                className={"text-right"}
              >{this.props.node.product.prices.consultant} Kč</td>
            )}

            {this.props.columnVisibility.isColumnDiscountPriceVisible && (
              <td
                className={"text-right"}
              >{this.props.node.product.prices[this.props.node.product.use_price_category ? "customer_with_discount" : "consultant"]} Kč</td>
            )}

            {this.props.columnVisibility.isColumnCustomerPriceVisible && (
              <td
                className={"text-right"}
              >{this.props.node.product.prices[this.props.node.product.use_price_category ? "customer" : "consultant"]} Kč</td>
            )}

            {this.props.columnVisibility.isColumnPointsVisible && (
              <td
                className={"text-right"}
              >{this.props.node.product.points}</td>
            )}

            <td
              className={"text-right"}
            >{this.props.node.product.weight}</td>

            <td
              className={"text-right"}
            >{this.props.node.product.vat_rate} %
            </td>
          </tr>
        )}

        {/* products */}
        {this.props.node.children && this.props.node.children
          .filter(node => node.product_id !== null)
          .filter(node => node.product.is_price_list_visible)
          .sort((a, b) => a.product.code - b.product.code)
          .map(child => (
            <ProductTreeNode
              key={child.id}
              node={child}
              depth={this.props.depth + 1}
              unitOfMeasures={this.props.unitOfMeasures}
              productSelectHandler={this.props.productSelectHandler}
              selectedProducts={this.props.selectedProducts}
              columnVisibility={this.props.columnVisibility}
            />
          ))}

        {/* categories */}
        {this.props.node.children && this.props.node.children
          .filter(node => node.category_id !== null)
          .map(child => (
            <ProductTreeNode
              key={child.id}
              node={child}
              depth={this.props.depth + 1}
              unitOfMeasures={this.props.unitOfMeasures}
              productSelectHandler={this.props.productSelectHandler}
              selectedProducts={this.props.selectedProducts}
              columnVisibility={this.props.columnVisibility}
            />
          ))}
      </>
    )
  }

  getCZProductName() {
    let name = this.props.node.product.names.find(name => name.country_id === COUNTRY.CZECH_REPUBLIC)

    if (name) {
      return name.name
    }

    return ""
  }

  getUnitOfMeasure() {
    if (
      this.props.unitOfMeasures &&
      this.props.unitOfMeasures.length > 0
    ) {
      let unit = this.props.unitOfMeasures.find(unitOfMeasure => this.props.node.product.unit_of_measure_id === unitOfMeasure.id)

      if (unit) {
        return unit.name
      }
    }

    return ""
  }
}
