import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {List} from "immutable"
import {NavLink} from "react-router-dom"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import API from "../API"
import ProductTree from "./ProductTree"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import getProductNameByCountry from "../Base/getProductNameByCountry"

export default class ProductPriceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // price list
      data: [],
      unitOfMeasures: [],
      isLoading: false,
      // bulk actions
      foundProducts: [],
      selectedProducts: List(),
      key: "price",
      type: "set",
      value: null,
      _bottomMenuPos: 0,
      // filters
      columnVisibility: {
        isColumnPointsVisible: true,
        isColumnConsultantPriceVisible: true,
        isColumnDiscountPriceVisible: true,
        isColumnCustomerPriceVisible: true,
      }
    }
  }

  componentDidMount() {
    this.getData()
    this.getUnitOfMeasures()
    window.addEventListener("scroll", this.handleScroll.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          <div
            className={"col-lg-5 d-print-none"}
          >
            <div
              className={"form-group"}
            >
              <NavLink
                to={"/products/add"}
                className={"btn btn-primary mr-3"}
              >Nový produkt</NavLink>

              <button
                className={"btn btn-light mr-3"}
                onClick={() => window.print()}
              >Tisk
              </button>

              <ReactHTMLTableToExcel
                className={"btn btn-primary"}
                table={"table-price-list"}
                filename={"cenik"}
                sheet={"Ceník"}
                buttonText={"Stáhnout jako  XLS"}
              />
            </div>
          </div>

          <div
            className={"col-lg-7 d-print-none"}
          >
            <div
              className={"form-group"}
            >
              <div
                className={"form-check"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  checked={this.state.columnVisibility.isColumnPointsVisible}
                  onChange={this.handleColumnPointsVisibility.bind(this)}
                  id={"columnPoints"}
                />
                <label
                  className={"form-check-label"}
                  htmlFor={"columnPoints"}
                >Zobrazit body</label>
              </div>

              <div
                className={"form-check"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  checked={this.state.columnVisibility.isColumnConsultantPriceVisible}
                  onChange={this.handleColumnConsultantPriceVisibility.bind(this)}
                  id={"columnConsultantPrice"}
                />
                <label
                  className={"form-check-label"}
                  htmlFor={"columnConsultantPrice"}
                >Zobrazit poradcovské ceny</label>
              </div>

              <div
                className={"form-check"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  checked={this.state.columnVisibility.isColumnDiscountPriceVisible}
                  onChange={this.handleColumnDiscountPriceVisibility.bind(this)}
                  id={"columnDiscountPrice"}
                />
                <label
                  className={"form-check-label"}
                  htmlFor={"columnDiscountPrice"}
                >Zobrazit ceny RD se slevou</label>
              </div>

              <div
                className={"form-check"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  checked={this.state.columnVisibility.isColumnCustomerPriceVisible}
                  onChange={this.handleColumnCustomerPriceVisibility.bind(this)}
                  id={"columnCustomerPrice"}
                />
                <label
                  className={"form-check-label"}
                  htmlFor={"columnCustomerPrice"}
                >Zobrazit zákaznické ceny</label>
              </div>
            </div>
          </div>
        </div>

        <form
          className={"form-bulk-action"}
          onSubmit={this.handleFormSubmit.bind(this)}
        >
          <div
            className={"row"}
          >
            <div
              className={"col-12 d-print-none"}
            >
              <div
                className={"form-group select-required"}
              >
                <label>Vyhledat produkty:</label>

                <AsyncSelect
                  value={this.state.foundProducts}
                  isMulti={true}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                  onChange={this.handleProductSearchSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  placeholder={"Vyhledat produkt"}
                  styles={ReactSelectCustomStyles}
                />
              </div>
            </div>

            <div
              className={"col-12"}
            >
              <div
                className={"form-group"}
              >
                <label
                  className={"d-print-none"}
                >nebo vybrat ze seznamu:</label>

                {this.state.isLoading && <SpinnerWithOverlay/>}

                <ScrollableTable>
                  <ProductTree
                    data={this.state.data}
                    unitOfMeasures={this.state.unitOfMeasures}
                    productSelectHandler={this.handleProductSelect.bind(this)}
                    selectedProducts={this.state.selectedProducts}
                    columnVisibility={this.state.columnVisibility}
                  />
                </ScrollableTable>
              </div>
            </div>
          </div>

          <div
            className={"row bulk-action-menu py-2 border-top-15 d-print-none bg-white"}
            style={{
              bottom: `${this.state._bottomMenuPos}px`
            }}
          >
            <div
              className={"col-3"}
            >
              <div
                className={"form-group select-required"}
              >
                <label>Upravit:</label>
                <select
                  className={"form-control"}
                  onChange={this.handleKeyChange.bind(this)}
                  value={this.state.key}
                  required={true}
                >
                  <option value={"price"}>Cena</option>
                  <option value={"points"}>Body</option>
                  <option value={"vat_rate"}>DPH</option>
                  <option value={"is_is_order_visible"}>Viditelnost v objednávce is.missiva.cz
                  </option>
                  <option value={"is_my_order_visible"}>Viditelnost v objednávce moje.missiva.cz
                  </option>
                  <option value={"is_price_list_visible"}>Viditelnost v ceníku</option>
                </select>
              </div>
            </div>

            <div
              className={"col-3"}
            >
              <div
                className={"form-group select-required"}
              >
                <label>Akce:</label>
                <select
                  className={"form-control"}
                  onChange={this.handleTypeChange.bind(this)}
                  value={this.state.type}
                  required={true}
                >
                  <option value={"set"}>Nastavit přesnou hodnotu</option>
                  {this.state.key !== "is_is_order_visible" &&
                  this.state.key !== "is_my_order_visible" &&
                  this.state.key !== "is_price_list_visible" && (
                    <>
                      <option value={"value"}>Zvýšit o hodnotu</option>
                      <option value={"value-"}>Snížit o hodnotu</option>
                      <option value={"percent"}>Procentuálně zvýšit o hodnotu</option>
                      <option value={"percent-"}>Procentuálně snížit o hodnotu</option>
                    </>
                  )}
                </select>
              </div>
            </div>

            <div
              className={"col-3"}
            >
              <div
                className={"form-group select-required"}
              >
                <label>Hodnota:</label>
                {(this.state.key === "is_is_order_visible" ||
                  this.state.key === "is_my_order_visible" ||
                  this.state.key === "is_price_list_visible")
                  ? (
                    <select
                      className={"form-control"}
                      onChange={this.handleValueChange.bind(this)}
                      value={this.state.value}
                      required={true}
                    >
                      <option value={0}>NE</option>
                      <option value={1}>ANO</option>
                    </select>
                  ) : (
                    <input
                      type={"number"}
                      className={"form-control"}
                      onChange={this.handleValueChange.bind(this)}
                      value={this.state.value === null ? "" : this.state.value}
                      required={true}
                      step={0.01}
                    />
                  )}
              </div>
            </div>

            <div
              className={"col-12 text-right"}
            >
              <button
                className={"btn btn-success"}
              >Provést
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }

  getData() {
    this.setState({
      isLoading: true
    }, () => {
      API.get("/productTree?limit=10")
        .then(res => {
          this.setState({
            data: res.data.objects,
            foundProducts: [],
            selectedProducts: List(),
            isLoading: false,
          })
        })
    })
  }

  getUnitOfMeasures() {
    API.get("/unitOfMeasures?limit=0&offset=0")
      .then(res => {
        this.setState({
          unitOfMeasures: res.data.objects
        })
      })
  }

  handleColumnPointsVisibility() {
    this.setState({
      columnVisibility: Object.assign({}, this.state.columnVisibility, {
        isColumnPointsVisible: !this.state.columnVisibility.isColumnPointsVisible,
      })
    })
  }

  handleColumnConsultantPriceVisibility() {
    this.setState({
      columnVisibility: Object.assign({}, this.state.columnVisibility, {
        isColumnConsultantPriceVisible: !this.state.columnVisibility.isColumnConsultantPriceVisible,
      })
    })
  }

  handleColumnDiscountPriceVisibility() {
    this.setState({
      columnVisibility: Object.assign({}, this.state.columnVisibility, {
        isColumnDiscountPriceVisible: !this.state.columnVisibility.isColumnDiscountPriceVisible,
      })
    })
  }

  handleColumnCustomerPriceVisibility() {
    this.setState({
      columnVisibility: Object.assign({}, this.state.columnVisibility, {
        isColumnCustomerPriceVisible: !this.state.columnVisibility.isColumnCustomerPriceVisible,
      })
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}&is_price_list_visible=1`)
      .then(res => {
        let {data} = res
        data = data.objects.map(item => {
          return Object.assign(item, {
            value: item.id,
            label: getProductNameByCountry(item),
          })
        })
        cb(data)
      })
  }

  handleProductSearchSelect(options) {
    this.setState({
      foundProducts: options,
      selectedProducts: List(),
    })
  }

  handleKeyChange(e) {
    let value = e.target.value

    this.setState({
      key: value,
      type: value.indexOf("visible") > -1 ? "set" : this.state.type,
      value: value.indexOf("visible") > -1 ? 0 : this.state.value,
    })
  }

  handleTypeChange(e) {
    this.setState({
      type: e.target.value
    })
  }

  handleValueChange(e) {
    this.setState({
      value: parseFloat(e.target.value)
    })
  }

  handleProductSelect(node) {
    let ids = []

    // selected node is product
    // add/remove product id from list
    if (node.product_id) {
      ids.push(node.product_id)
    }

    // selected node is category
    // add/remove all children product ids (deeply)
    if (node.category_id) {
      let getProductsIds = n => {
        n.children.forEach(child => {
          if (child.category_id) { // subcategory
            getProductsIds(child)
          } else { // product
            ids.push(child.product_id)
          }
        })
      }
      getProductsIds(node)
    }

    this.addOrRemoveProductId(ids)
  }

  addOrRemoveProductId(ids) {
    let products = List(this.state.selectedProducts)

    ids.forEach(id => {
      let index = products.indexOf(id)

      if (index > -1) {
        products = products.delete(index)
      } else {
        products = products.push(id)
      }
    })

    this.setState({
      foundProducts: [],
      selectedProducts: products,
    })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let products = this.state.foundProducts.length > 0
      ? this.state.foundProducts.map(product => product.id)
      : this.state.selectedProducts.toArray()
    let type = this.state.type
    let value = this.state.value

    // decrease the value
    if (type.indexOf("-") > -1) {
      type = type.replace("-", "")
      value = value * -1
    }

    if (products.length > 0) {
      let data = {
        entity: "product",
        filter: [{
          key: "id",
          value: products,
        }],
        change: [{
          key: this.state.key,
          value: value,
          change_type: type
        }]
      }

      API.post("/mass", data)
        .then(this.getData.bind(this))
    }
  }

  handleScroll() {
    let pos = 0

    if (document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 50) {
      pos = 50
    }

    if (this.state._bottomMenuPos !== pos) {
      this.setState({
        _bottomMenuPos: pos,
      })
    }
  }
}
