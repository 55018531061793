import React, {Component} from "react"
import API from "../../API"

export default class DeliveryMethodForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      price: "",
    }
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getDeliveryMethod()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Název:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Název"}
                required={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state.name}
                minLength={2}
                maxLength={60}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Cena balného:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Cena"}
                required={true}
                onChange={this.handlePriceChange.bind(this)}
                value={this.state.price}
                min={0}
              />
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  handlePriceChange(e) {
    this.setState({
      price: parseInt(e.target.value, 10)
    })
  }

  addDeliveryMethod() {
    let data = Object.assign({}, this.state)
    API.post("/deliveryMethods", data)
      .then(() => {
        this.props.history.push("/enums/delivery-methods")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/delivery-methods")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateDeliveryMethod()
    } else {
      this.addDeliveryMethod()
    }
  }

  getDeliveryMethod() {
    API.get(`/deliveryMethods/${this.id}`)
      .then((res) => {
        this.setState(res.data)
      })
  }

  updateDeliveryMethod() {
    let data = Object.assign({}, this.state)
    API.put(`/deliveryMethods/${this.id}`, data)
      .then(() => {
        this.props.history.push("/enums/delivery-methods")
      })
  }
}
