import React from "react"
import {NavLink, Route, Switch} from "react-router-dom"

const NavBarReportsSets = () => {
  return (
    <Switch>
      <Route
        path={"/reports/sets"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/reports/sets/open-sales"}
              className={"nav-link"}
              activeClassName={"active"}
            >Přehled zakázek</NavLink>
            <NavLink
              to={"/reports/sets/sponsor-orders"}
              className={"nav-link"}
              activeClassName={"active"}
            >Objednávky dle sponzora</NavLink>
            <NavLink
              to={"/reports/sets/order-summary"}
              className={"nav-link"}
              activeClassName={"active"}
            >Přehled zakázek doprava a platba</NavLink>
            <NavLink
              to={"/reports/sets/profits"}
              className={"nav-link"}
              activeClassName={"active"}
            >Denní tržby</NavLink>
            <NavLink
              to={"/reports/sets/turn-over"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zákazníci podle obratu</NavLink>
            {/*{this.props.hasAccessHandler("#/reports/sales") && <NavLink*/}
            {/*  to={"/reports/sales"}*/}
            {/*  className={"nav-link"}*/}
            {/*  activeClassName={"active"}*/}
            {/*>Zakázky</NavLink>}*/}
            <NavLink
              to={"/reports/sets/unpaid-orders"}
              className={"nav-link"}
              activeClassName={"active"}
            >Neplatiči</NavLink>
            <NavLink
              to={"/reports/sets/invoices"}
              className={"nav-link"}
              activeClassName={"active"}
            >Vydané faktury</NavLink>
            <NavLink
              to={"/reports/sets/paid-invoices"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zaplacené faktury</NavLink>
            <NavLink
              to={"/reports/sets/refunds-and-returns"}
              className={"nav-link"}
              activeClassName={"active"}
            >Dobropisy a vratky</NavLink>
            <NavLink
              to={"/reports/sets/products"}
              className={"nav-link"}
              activeClassName={"active"}
            >Vydané položky</NavLink>
            <NavLink
              to={"/reports/sets/used-packages"}
              className={"nav-link"}
              activeClassName={"active"}
            >Použité obaly</NavLink>
            <NavLink
              to={"/reports/sets/required-products"}
              className={"nav-link"}
              activeClassName={"active"}
            >Potřebné produkty</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarReportsSets
