import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarMySales = () => {
  return (
    <Switch>
      <Route
        path={"/my-sales"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/my-sales"}
              className={"nav-link"}
              activeClassName={"active"}
            >Přehled objednávek</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarMySales
