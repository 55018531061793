const CONTACT_TYPE = {
  EMAIL: 1,
  FAX: 2,
  TARIFF_02: 3,
  PHONE_HOME: 4,
  PHONE_MOBILE: 5,
  PHONE_OTHER: 6,
  PHONE_WORK: 7,
}

export default CONTACT_TYPE
