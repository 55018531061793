import React, {Component} from "react"
import moment from "moment"
import API from "../API"
import MyCustomerMenu from "./MyCustomerMenu"
import CONTACT_TYPE from "../Base/Enum/ContactType"

export default class MyCustomerDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type_id: 1,
      customer_no: null,
      prefix: null,
      name: null,
      surname: null,
      billing_address_customer_name: null,
      billing_address_billing_name: null,
      billing_address_street: null,
      billing_address_city: null,
      billing_address_post_code: null,
      billing_address_country_code: "CZ",
      contact: [],
      country_id: 1,
      registration_no: null,
      tax_no: null,
      birth_no: null,
      license_reg: null,
      address: [],
      bank_account: null,
      bank_id: 100,
      bank_name: null,
      bank_vs: null,
      bank_cs: "0308",
      bank_ss: null,
      is_membership_active: false,
      is_my_access_limited: false,
      membership_extended_at: null,
      gdpr: false,
      gdpr_at: null,
      terms: false,
      terms_at: null,
      discount: 0,
      is_guarantor: false,
      note: [],
      sponsor_id: null,
      guarantor_id: null,
      registration_delivery_method: null,
      // enums
      _banks: [],
      _customerTypes: [],
      _contactTypes: [],
      _countries: [],
      _registrationDeliveryMethods: [],
    }
  }

  componentDidMount() {
    this.id = parseInt(this.props.match.params.id, 10)
    this.getBanks()
    this.getContactTypes()
    this.getCustomerTypes()
    this.getRegistrationDeliveryMethods()
    this.getCountries()
    this.getCustomer()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <MyCustomerMenu
          customerId={this.id}
          customerNo={this.state.customer_no}
          isMyAccessLimited={this.state.is_my_access_limited}
        />

        <fieldset
          className={"col-12"}
          disabled={true}
        >
          <div
            className={"row"}
          >
            {/* 1st column */}
            <div
              className="col-lg-5"
            >
              <div
                className="form-group"
              >
                <label>Typ zákazníka:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  value={this.state._customerTypes.length > 0 ? this.state._customerTypes.find(type => type.id === this.state.type_id).name : ""}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Číslo zákazníka:</label>
                <input
                  type="number"
                  className="form-control"
                  value={this.state.customer_no || ""}
                />
              </div>

              <div
                className={"form-row"}
              >
                <div
                  className="form-group col-2"
                >
                  <label>Titul:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.prefix || ""}
                  />
                </div>

                <div
                  className="form-group col-5"
                >
                  <label>Jméno:</label>
                  <input
                    type="text"
                    className="form-control font-weight-bold bg-light-green"
                    value={this.state.name || ""}
                  />
                </div>

                <div
                  className="form-group col-5"
                >
                  <label>Příjmení:</label>
                  <input
                    type="text"
                    className="form-control font-weight-bold bg-light-green"
                    value={this.state.surname || ""}
                  />
                </div>
              </div>

              <strong
                className={"form-group d-block"}
              >Fakturační adresa</strong>

              <div
                className="form-group"
              >
                <label>Jméno a příjmení zákazníka:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.billing_address_customer_name || ""}
                />
              </div>

              <div
                className="form-group"
              >
                <label>Fakturační jméno a příjmení zákazníka:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.billing_address_billing_name || ""}
                />
              </div>

              <div
                className="form-group"
              >
                <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.billing_address_street || ""}
                />
              </div>

              <div
                className={"form-row"}
              >
                <div
                  className="form-group col-lg-8 col-6"
                >
                  <label>Město:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    value={this.state.billing_address_city}
                  />
                </div>

                <div
                  className="form-group col-lg-2 col-3"
                >
                  <label>PSČ:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    value={this.state.billing_address_post_code}
                  />
                </div>

                <div
                  className="form-group col-lg-2 col-3"
                >
                  <label>Kód země:</label>
                  <select
                    className="form-control"
                    value={this.state.billing_address_country_code || "CZ"}
                  >
                    {this.state._countries.map(country => (
                      <option
                        key={country.id}
                        value={country.code}
                      >{country.code}</option>
                    ))}
                  </select>
                </div>
              </div>

              <strong
                className={"form-group d-block"}
              >Kontakty</strong>

              <div
                className={"form-group"}
              >
                <div
                  className={"scrollable border"}
                >
                  {this.state.contact.map(contact => (
                    <div
                      className={"px-2 py-1"}
                      key={contact.id}
                    >
                      <strong>{this.getContactType(contact)}</strong>: {contact.value}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* 2nd column */}
            <div
              className={"col-lg-5"}
            >
              <div
                className={"form-row"}
              >
                <div
                  className="form-group col-4"
                >
                  <label>Země:</label>
                  <select
                    className="form-control"
                    value={this.state.country_id || 1}
                  >
                    {this.state._countries.map(country => (
                      <option
                        key={country.id}
                        value={country.id}
                      >{country.name}</option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group col-4"
                >
                  <label>Datum založení:</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={this.state.created_at ? this.state.created_at.replace(" ", "T") : moment().format("YYYY-MM-DDTHH:mm:ss")}
                  />
                </div>

                <div
                  className={"form-group col-4"}
                >
                  <div
                    className="form-check mb-0"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={this.state.is_membership_active}
                    />
                    <label>Datum úhrady čl. poplatku:</label>
                  </div>
                  <input
                    type={"date"}
                    className="form-control"
                    value={this.state.membership_extended_at || ""}
                  />
                </div>
              </div>

              <div
                className={"form-row"}
              >
                <div className="form-group col-4">
                  <label>IČO:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.registration_no || ""}
                  />
                </div>

                <div className="form-group col-4">
                  <label>DIČ:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.tax_no || ""}
                  />
                </div>

                <div
                  className="form-group col-4"
                >
                  <label>RČ:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.birth_no || ""}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Registrace ŽL:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.license_reg || ""}
                />
              </div>

              <strong
                className={"form-group d-block"}
              >Doručovací adresy</strong>

              <div
                className={"scrollable border"}
              >
                {this.state.address.map(address => (
                  <div
                    className={"px-2 py-1"}
                    key={address.id}
                  >
                    {address.customer_name}
                    {address.delivery_name
                      ? <span>&nbsp;({address.delivery_name})</span>
                      : ""
                    },&nbsp;
                    {address.street},&nbsp;
                    {address.city},&nbsp;
                    {address.post_code},&nbsp;
                    {address.country_code}
                  </div>
                ))}
              </div>

              <strong
                className={"form-group d-block"}
              >Bankovní účet</strong>

              <div
                className={"form-row"}
              >
                <div
                  className="form-group col-8"
                >
                  <label>Číslo účtu:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.bank_account || ""}
                  />
                </div>

                <div
                  className="form-group col-4"
                >
                  <label>Kód banky:</label>
                  <select
                    className="form-control"
                    value={this.state.bank_id || 1}
                  >
                    {this.state._banks.map(bank => (
                      <option
                        key={bank.id}
                        value={bank.id}
                      >{bank.code} ({bank.name})</option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className="form-group"
              >
                <label>Název banky:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.bank_name || ""}
                />
              </div>

              <div
                className={"form-row"}
              >
                <div
                  className="form-group col-4"
                >
                  <label>VS:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.bank_vs || ""}
                  />
                </div>

                <div
                  className="form-group col-4"
                >
                  <label>KS:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.bank_cs || ""}
                  />
                </div>

                <div
                  className="form-group col-4"
                >
                  <label>SS:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.bank_ss || ""}
                  />
                </div>
              </div>
            </div>

            {/* 3rd column */}
            <div
              className={"col-lg-2"}
            >
              <label>Sponzor - Jméno a příjmení:</label>

              <div
                className="form-group"
              >
                <input
                  type={"text"}
                  className={"form-control"}
                  value={this.state.sponsor ? `${this.state.sponsor.name || ""} ${this.state.sponsor.surname || ""}` : ""}
                />
              </div>

              <div
                className="form-group"
              >
                <label>Telefon:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.getSponsorContact()}
                />
              </div>

              {/* spacer */}
              <strong
                className="form-group d-block"
              >&nbsp;</strong>

              <div
                className="form-group"
              >
                <label>Způsob doručení registrace:</label>
                <select
                  className={"form-control"}
                  value={this.state.registration_delivery_method || ""}
                >
                  <option
                    value={""}
                  >Způsob doručení registrace
                  </option>
                  {this.state._registrationDeliveryMethods.map(method => (
                    <option
                      key={method.id}
                      value={method.id}
                    >{method.name}</option>
                  ))}
                </select>
              </div>

              {/* spacer */}
              <strong
                className="form-group d-block"
              >&nbsp;</strong>

              <div
                className={"form-row"}
              >
                <div
                  className={"form-group col-6"}
                >
                  <div
                    className="form-check"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={this.state.discount !== 0}
                    />
                    <label
                      className={"form-check-label"}
                    >Sleva 10 %</label>
                  </div>
                </div>

                <div
                  className={"form-group col-6"}
                >
                  <div
                    className="form-check"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={this.state.is_guarantor || false}
                    />
                    <label
                      className={"form-check-label"}
                    >Může být garant</label>
                  </div>
                </div>
              </div>

              {/* spacer */}
              <strong
                className="form-group d-block"
              >&nbsp;</strong>

              <strong
                className={"form-group d-block"}
              >Věrnostní Kč</strong>

              <div
                className="form-group"
              >
                <input
                  type="text"
                  className="form-control"
                  value={this.getBonusMoneyState("total")}
                />
              </div>

              <div
                className="form-group"
              >
                <label>Z minulých let:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.getBonusMoneyState("previous_years")}
                />
              </div>

              <div
                className="form-group"
              >
                <label>Letos:</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.getBonusMoneyState("current_year")}
                />
              </div>

              {/* spacer */}
              <strong
                className="form-group d-block"
              >&nbsp;</strong>

              <div
                className="form-group form-check mb-0"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.state.gdpr || false}
                />
                <label>GDPR:</label>
              </div>
              <div
                className="form-group"
              >
                <input
                  type={"date"}
                  className="form-control"
                  value={this.state.gdpr_at || ""}
                />
              </div>

              <div
                className="form-group form-check mb-0"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.state.terms || false}
                />
                <label>Obchodní podmínky:</label>
              </div>
              <div
                className="form-group"
              >
                <input
                  type={"date"}
                  className="form-control"
                  value={this.state.terms_at || ""}
                />
              </div>
            </div>
          </div>
        </fieldset>

        <MyCustomerMenu
          customerId={this.id}
          customerNo={this.state.customer_no}
        />
      </div>
    )
  }

  getContactType(contact) {
    if (!this.state._contactTypes.length) {
      return ""
    }
    let contactTypes = this.state._contactTypes.filter((type) => {
      return type.id === contact.type_id
    })
    if (contactTypes.length) {
      return contactTypes[0].type
    }
    return ""
  }

  getCustomer() {
    API.get(`/my/customer/${this.id}`)
      .then(({data}) => {
        data.contact = data.contact || []

        this.setState(data, this.getSponsor.bind(this))
      })
  }

  getSponsor() {
    API.get(`/my/customer/${this.state.sponsor_id}`)
      .then(({data}) => {
        this.setState({
          sponsor: data,
        })
      })
  }

  getSponsorContact() {
    if (this.state.sponsor && this.state.sponsor.contact) {
      let contacts = this.state.sponsor.contact.filter(contact =>
        contact.type_id === CONTACT_TYPE.PHONE_HOME ||
        contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
        contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
        contact.type_id === CONTACT_TYPE.PHONE_WORK
      )
      if (contacts.length > 0) {
        return contacts[0].value
      }
    }
    return ""
  }

  getCustomerTypes() {
    API.get("/customerType?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          _customerTypes: data.objects,
        })
      })
  }

  getBanks() {
    API.get("/banks")
      .then(({data}) => {
        this.setState({
          _banks: data.objects,
        })
      })
  }

  getContactTypes() {
    API.get("/contactTypes")
      .then(({data}) => {
        this.setState({
          _contactTypes: data.objects
        })
      })
  }

  getCountries() {
    API.get("/countries")
      .then(({data}) => {
        this.setState({
          _countries: data.objects
        })
      })
  }

  getRegistrationDeliveryMethods() {
    API.get("/registrationDeliveryMethods")
      .then(({data}) => {
        this.setState({
          _registrationDeliveryMethods: data.objects
        })
      })
  }

  getBonusMoneyState(type) {
    if (
      this.state.account &&
      this.state.account.length > 0
    ) {
      let {
        total_in_year,
        total_out_year,
        total_prev_years,
      } = this.state.account[0]

      if (type === "total") {
        return (total_in_year - total_out_year + total_prev_years).toFixed(2)
      }

      if (type === "previous_years") {
        let leftover = total_prev_years - total_out_year

        return (leftover < 0 ? 0 : leftover).toFixed(2)
      }

      if (type === "current_year") {
        let prevYearsLeftover = total_prev_years - total_out_year
        let leftover = total_in_year + (prevYearsLeftover <= 0 ? prevYearsLeftover : 0)

        return leftover.toFixed(2)
      }

      return 0
    }
  }
}


