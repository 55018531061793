import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import CreatableSelect from "react-select/creatable"
import AsyncCreatableSelect from "react-select/async-creatable"
import {List} from "immutable"
import {v4 as uuid} from "uuid"
import {Prompt} from "react-router-dom"
import moment from "moment"
import API from "../API"
import {cleanState} from "../Utils"
import MySaleMenu from "./MySaleMenu"
import MyOrderForm from "./MyOrderForm"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import sortInvoiceNumbers from "../Base/sortInvoiceNumbers"
import styles from "../Sales/style.module.css"
import RBAC from "../RBAC/RBAC"
import ORDER_METHOD from "../Base/Enum/OrderMethod"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import PRODUCT_CODE from "../Base/Enum/ProductCode"
import {
  filterMyPaymentMethod,
  filterPaymentMethodByCustomerTypeId,
  filterPaymentMethodByDeliveryMethod,
  filterPaymentMethodByOrdersCustomers
} from "../Base/filterPaymentMethod"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import COUNTRY from "../Base/Enum/Countries"
import formatCustomerName from "../Base/formatCustomerName"
import CONTACT_TYPE from "../Base/Enum/ContactType"
import Promo4Plus1Preview from "../Promo4Plus1Preview/Promo4Plus1Preview"
import getProductNameByCountry from "../Base/getProductNameByCountry"
import paymentMethod from "../Base/Enum/PaymentMethod"

export default class MySaleForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sale_no: null,
      sale_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      created_at: "",
      delivery_method_id: DELIVERY_METHOD.CZECH_POST,
      payment_method_id: PAYMENT_METHOD.CASH_ON_DELIVERY,
      order_method_id: ORDER_METHOD.ONLINE,
      customer_id: null,
      delivery_address_id: null,
      delivery_address: null,
      phone_number: null,
      email: null,
      status: "created_open",
      total_price: 0,
      delivery_price: 0,
      no_due_price: 0,
      handling_fee: 0,
      weight: null,
      bonus_points: 0,
      customer: {},
      is_free_delivery: false,
      exchange_rate: null,
      order: List([{
        id: uuid(),
        order_type_id: "default",
        payment_method_id: PAYMENT_METHOD.CASH_ON_DELIVERY,
        price_type: "customer_type",
        due_days: 0,
        customer_id: null,
        billing_address_billing_name: null,
        billing_address_street: null,
        billing_address_city: null,
        billing_address_post_code: null,
        billing_address_country_code: "CZ",
        order_product: List(),
        customer: {},
        status: "created",
        price: 0,
        discount: 0,
        weight: 0,
        bonus_points: 0,
        note: List(),
        is_simple_invoice: false,
        order_product_discount_group: [],
        _note: "",
        _promoProducts: [],
      }]),
      _deliveryMethods: [],
      _paymentMethods: [],
      _orderMethods: [],
      _countries: [],
      _customerTypes: [],
      _orderTypes: [],
      _delivery_address_customer_name: "",
      _delivery_address_delivery_name: "",
      _delivery_address_city: "",
      _delivery_address_post_code: "",
      _delivery_address_street: "",
      _delivery_address_country_code: "CZ",
      _productsPrice: 0,
      _productTree: [],
      _productCategories: [],

      _bottomMenuPos: 0,
      _showAddAddressForm: false,

      _authenticatedCustomerTypeId: CUSTOMER_TYPE.CK,
      _authenticatedCustomer: {},

      _isPromo4Plus1Active: false,
      _isPromo4Plus1Open: false,
      _isPromo4Plus1MenuVisible: false,
    }

    this.formChanges = false
    this.handleFormChanges = false
  }

  componentDidMount() {
    let {id, order_id, customer_id} = this.props.match.params

    if (id) {
      this.id = parseInt(id, 10)
      this.getSale()

      if (order_id) {
        this.orderId = parseInt(order_id, 10)
      }
    } else {
      this.getNewSale(this.state.order.get(0).id)
    }

    if (customer_id) {
      this.customer_id = customer_id
      this.getCustomer(customer_id)
    }

    this.getDeliveryMethods()
    this.getPaymentMethods()
    this.getOrderMethods()
    this.getCountries()
    this.getCustomerTypes()
    this.getOrderTypes()
    this.getProductCatalogueData()
    this.getDiscountGroups()
    window.addEventListener("scroll", this.handleScroll.bind(this))

    setTimeout(() => {
      this.handleFormChanges = true
    }, 5000)

    this.getAuthenticatedCustomerTypeId()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {id, invoice_no} = prevProps.match.params
    if (id !== this.props.match.params.id || invoice_no !== this.props.match.params.invoice_no)
      this.componentDidMount()

    // handle non saved changes
    if (
      this.handleFormChanges
      && !this.formChanges
      && JSON.stringify(cleanState(this.state)) !== JSON.stringify(cleanState(prevState))
    ) {
      this.formChanges = true
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  render() {
    return (
      <>
        <Prompt
          message={() =>
            this.formChanges
              ? "Máte neuložené změny. Opravdu chcete odejít? Dojde ke ztrátě neuložených dat."
              : true
          }
        />

        <form
          className={"row form form-sales"}
          onSubmit={this.handleFormSubmit.bind(this)}
          onKeyPress={this.handleFormKeyPress.bind(this)}
        >
          <MySaleMenu
            isDetail={!!this.id}
            isTop={true}
            authenticatedCustomerTypeId={this.state._authenticatedCustomerTypeId}
            isSaveable={this.isSaleSaveable()}
            isDeletable={this.isSaleDeletable()}
            isPaymentAndDeliverySelected={this.isPaymentAndDeliverySelected()}
            formCancelHandler={this.handleCancel.bind(this)}
            saveSaleAsOpenHandler={this.handleSaveSaleAsOpen.bind(this)}
            deleteHandler={this.handleDelete.bind(this)}
            isPromo4Plus1MenuVisible={this.state._isPromo4Plus1MenuVisible}
            isPromo4Plus1OpenHandler={this.handleIsPromo4Plus1Open.bind(this)}
          />
          <fieldset
            className={"col-12"}
            disabled={!this.isSaleSaveable()}
          >
            <div
              className={"row bg-warning mb-2 py-4"}
            />
            <div
              className={"row"}
            >
              <div
                className={"col-lg-6"}
              >
                <div
                  className={"form-row"}
                >
                  <div
                    className={"form-group col-lg-6"}
                  >
                    <label>Způsob doručení:</label>
                    <select
                      className={"form-control"}
                      required={true}
                      onChange={this.handleDeliveryMethodChange.bind(this)}
                      value={this.state.delivery_method_id}
                      autoFocus={true}
                      disabled={this.getCountryId() !== COUNTRY.CZECH_REPUBLIC}
                    >
                      <option
                        value={""}
                      >Vyberte způsob doručení
                      </option>
                      {this.state._deliveryMethods
                        .filter(this.filterDeliveryMethods.bind(this))
                        .map(deliveryMethod => (
                          <option
                            key={deliveryMethod.id}
                            value={deliveryMethod.id}
                          >{deliveryMethod.name}</option>
                        ))}
                    </select>
                  </div>
                  <div
                    className={"form-group col-lg-6"}
                  >
                    <label>Způsob platby:</label>
                    <select
                      className={"form-control"}
                      required={true}
                      onChange={this.handlePaymentMethodChange.bind(this)}
                      value={this.state.payment_method_id || ""}
                      disabled={this.getCountryId() !== COUNTRY.CZECH_REPUBLIC}
                    >
                      <option
                        value={""}
                      >Vyberte způsob platby
                      </option>
                      {this.state._paymentMethods
                        .filter(paymentMethod => filterMyPaymentMethod(paymentMethod.id))
                        .filter(paymentMethod => filterPaymentMethodByDeliveryMethod(paymentMethod.id, this.state.delivery_method_id))
                        .filter(paymentMethod => filterPaymentMethodByCustomerTypeId(paymentMethod.id, this.state._authenticatedCustomer.type_id))
                        .filter(paymentMethod => filterPaymentMethodByOrdersCustomers(paymentMethod.id, this.state._authenticatedCustomer.id, this.state.order.toArray().map(order => order.customer_id)))
                        .map(paymentMethod => (
                          <option
                            key={paymentMethod.id}
                            value={paymentMethod.id}
                          >{paymentMethod.name}</option>
                        ))}
                    </select>
                  </div>
                </div>

                {this.isSaleCustomer() && (
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group col-lg-2 col-3"}
                    >
                      <label>Typ zákazníka:</label>
                      <input
                        className={"form-control"}
                        type={"text"}
                        disabled={true}
                        value={this.getCustomerTypeName(this.state.customer)}
                      />
                    </div>
                    <div
                      className={"form-group select-required col-lg-10 col-9"}
                    >
                      <label>Zákazník:</label>
                      <AsyncCreatableSelect
                        className={"react-select_border"}
                        value={this.state.customer || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                        onChange={this.handleCustomerSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        components={{
                          Option: ReactSelectTableOption
                        }}
                        styles={ReactSelectCustomStyles}
                        isDisabled={
                          this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RD ||
                          this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RZ ||
                          this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RP
                        }
                        formatCreateLabel={value => `Vyhledat podle zákaznického čísla: ${value}`}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div
                className={"col-lg-6"}
              >
                <div
                  className={"form-row"}
                >
                  <div
                    className={`form-group ${(
                      this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RD ||
                      this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RZ ||
                      this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RP
                    ) ? "col-12" : "col-lg-8"}`}
                  >
                    <label>Doručovací adresa:</label>
                    {this.state.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP
                      ? (
                        <input
                          type={"text"}
                          disabled={true}
                          className={"form-control"}
                          value={"Missiva spol. s.r.o., U kostela č. 6, Rtyně nad Bílinou, 417 62"}
                        />
                      ) : (
                        <select
                          className={"form-control"}
                          required={!this.state.delivery_address}
                          onChange={this.handleDeliveryAddressChange.bind(this)}
                          value={this.state.delivery_address_id || ""}
                          disabled={this.state.customer ? !this.state.customer.address : false}
                        >
                          {this.state.customer && this.state.customer.address && this.state.customer.address.map(address => (
                            <option
                              key={address.id}
                              value={address.id}
                            >{this.isSaleCustomer()
                              ? `${address.customer_name}, ${address.street}, ${address.city}, ${address.post_code}, ${this.getCountryName(address.country_code)}`
                              : `${address.customer_name}`
                            }</option>
                          ))}
                        </select>
                      )}
                  </div>

                  {this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
                  this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ &&
                  this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP && (
                    <div
                      className={"form-group col-lg-4"}
                    >
                      <button
                        className={"btn btn-primary w-100 mt-4"}
                        onClick={this.handleShowAddAddressForm.bind(this)}
                        disabled={!this.state.customer.id || this.state.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP}
                      >Změnit doručovací adresu
                      </button>
                    </div>
                  )}
                </div>
                {this.state._showAddAddressForm && <>
                  <div
                    className={"form-group"}
                  >
                    <label>Jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleAddressCustomerNameChange.bind(this)}
                      value={this.state._delivery_address_customer_name}
                      required={!this.state.delivery_address_id}
                    />
                  </div>
                  <div
                    className={"form-group"}
                  >
                    <label>Doručovací jméno a příjmení zákazníka:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleAddressDeliveryNameChange.bind(this)}
                      value={this.state._delivery_address_delivery_name}
                    />
                  </div>
                  <div
                    className={"form-group"}
                  >
                    <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                    <input
                      type={"text"}
                      className={"form-control"}
                      onChange={this.handleDeliveryAddressStreetChange.bind(this)}
                      value={this.state._delivery_address_street}
                      required={!this.state.delivery_address_id}
                    />
                  </div>

                  <div
                    className={"form-row"}
                  >
                    <div
                      className={`form-group col-lg-8 col-6 ${this.state.delivery_address_id ? "" : "select-required"}`}
                    >
                      <label>Město:</label>
                      <AsyncSelect
                        value={{
                          label: this.state._delivery_address_city,
                          value: this.state._delivery_address_city,
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleDeliveryAddressCityChange.bind(this))}
                        onChange={this.handleDeliveryAddressCitySelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        styles={ReactSelectCustomStyles}
                      />
                    </div>

                    <div
                      className={`form-group col-lg-2 col-3 ${this.state.delivery_address_id ? "" : "select-required"}`}
                    >
                      <label>PSČ:</label>
                      <AsyncSelect
                        value={{
                          label: this.state._delivery_address_post_code,
                          value: this.state._delivery_address_post_code,
                        } || {}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleDeliveryAddressPostCodeChange.bind(this))}
                        onChange={this.handleDeliveryAddressPostCodeSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        styles={ReactSelectCustomStyles}
                      />
                    </div>

                    <div
                      className={"form-group col-lg-2 col-3"}
                    >
                      <label>Kód země:</label>
                      <select
                        className={"form-control"}
                        required={!this.state.delivery_address_id}
                        onChange={this.handleDeliveryAddressCountryCodeChange.bind(this)}
                        value={this.state._delivery_address_country_code}
                      >
                        {this.state._countries.map(country => (
                          <option
                            key={country.id}
                            value={country.code}
                          >{country.code}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>}

                {this.isSaleCustomer() && (
                  <div
                    className={"form-row"}
                  >
                    <div
                      className={"form-group select-required col-lg-6"}
                    >
                      <label>Telefon:</label>
                      <CreatableSelect
                        isClearable={this.isSaleSaveable()}
                        value={{
                          label: this.state.phone_number || "",
                          value: this.state.phone_number || "",
                        }}
                        onChange={this.handlePhoneNumberChange.bind(this)}
                        onBlur={this.handlePhoneNumberBlur.bind(this)}
                        options={this.getPhoneNumberOptions()}
                        formatCreateLabel={value => value}
                        noOptionsMessage={() => "Nic nenalezeno"}
                      />
                    </div>
                    <div
                      className={"form-group select-required col-lg-6"}
                    >
                      <label>Email:</label>
                      <CreatableSelect
                        isClearable={this.isSaleSaveable()}
                        value={{
                          label: this.state.email || "",
                          value: this.state.email || "",
                        }}
                        onChange={this.handleEmailChange.bind(this)}
                        onBlur={this.handleEmailBlur.bind(this)}
                        options={this.getEmailOptions()}
                        formatCreateLabel={value => value}
                        noOptionsMessage={() => "Nic nenalezeno"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className={"row"}
            >
              {this.state.order
                .filter(this.filterOrders.bind(this))
                .sort(this.id ? sortInvoiceNumbers : () => 0) // show refunds under original order. make sense only for existing sales.
                .map((order, i) => (
                  <MyOrderForm
                    key={order.id}
                    seqNo={i + 1}
                    isSaleFirstOrder={i === 0}
                    salePaymentMethodId={this.state.payment_method_id}
                    saleDeliveryMethodId={this.state.delivery_method_id}
                    isSingle={this.state.order.size === 1}
                    isDetail={!!this.id}
                    isSaveable={this.isSaleSaveable()}
                    isPromo4Plus1Active={this.state._isPromo4Plus1Active}
                    order={order}
                    countries={this.state._countries}
                    productTree={this.state._productTree}
                    productCategories={this.state._productCategories}
                    saleStatus={this.state.status}
                    deleteOrderHandler={this.handleDeleteOrder.bind(this)}
                    setOrderStateHandler={this.handleSetOrderState.bind(this)}
                    getCustomerTypeNameHandler={this.getCustomerTypeName.bind(this)}
                    addOrderHandler={this.handleAddOrder.bind(this)}
                    orderId={this.orderId}
                    countryId={this.getCountryId()}
                    deleteCustomerNoteHandler={this.deleteCustomerNote.bind(this)}
                    countryCurrencyInfo={this.getCountryCurrencyInfo()}
                    authenticatedCustomerTypeId={this.state._authenticatedCustomerTypeId}
                    authenticatedCustomer={this.state._authenticatedCustomer}
                  />
                ))}
            </div>
          </fieldset>

          <MySaleMenu
            isDetail={!!this.id}
            isTop={false}
            bottomMenuPos={this.state._bottomMenuPos}
            authenticatedCustomerTypeId={this.state._authenticatedCustomerTypeId}
            isSaveable={this.isSaleSaveable()}
            isDeletable={this.isSaleDeletable()}
            isPaymentAndDeliverySelected={this.isPaymentAndDeliverySelected()}
            formCancelHandler={this.handleCancel.bind(this)}
            saveSaleAsOpenHandler={this.handleSaveSaleAsOpen.bind(this)}
            deleteHandler={this.handleDelete.bind(this)}
            isPromo4Plus1MenuVisible={this.state._isPromo4Plus1MenuVisible}
            isPromo4Plus1OpenHandler={this.handleIsPromo4Plus1Open.bind(this)}
          />
        </form>

        <div
          className={"row sale-summary pt-2 border-top-15 bg-white"}
          style={{
            bottom: this.state._bottomMenuPos + 38
          }}
        >
          <div
            className={"form-group col-lg-2 col-md-4 col-6"}
          >
            <label
              className={styles.saleSummaryTotalPriceLabel}
            >Celková cena:</label>
            <input
              type={"text"}
              className={`form-control font-weight-bold bg-light-green text-black ${styles.saleSummaryTotalPriceInput}`}
              placeholder={"Celková cena"}
              value={(this.state.total_price * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
              disabled={true}
            />
          </div>
          <div
            className={"form-group col-lg-1 col-md-4 col-6"}
          >
            <label
              className={"font-weight-bold"}
            >Body:</label>
            <input
              type={"text"}
              className={"form-control font-weight-bold text-black"}
              placeholder={"Body"}
              value={this.state.bonus_points}
              disabled={true}
            />
          </div>
          <div
            className={"form-group col-lg-2 col-md-4 col-6"}
          >
            <label
              className={"font-weight-bold"}
            >Cena výrobků:</label>
            <input
              type={"text"}
              className={"form-control font-weight-bold text-black"}
              placeholder={"Cena"}
              value={(this.state._productsPrice * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
              disabled={true}
            />
          </div>
          <div
            className={"form-group col-lg-2 col-md-4 col-6"}
          >
            <label
              className={"font-weight-bold"}
            >Balné a poštovné:</label>
            <input
              type={"text"}
              className={`form-control font-weight-bold text-black ${this.state.delivery_price === 0 ? "text-black" : "text-white bg-danger"}`}
              placeholder={"Balné a poštovné"}
              value={(this.state.delivery_price * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
              disabled={true}
            />
          </div>
          <div
            className={"form-group col-lg-1 col-md-4 col-6"}
          >
            <label
              className={"font-weight-bold"}
            >M. poplatek:</label>
            <input
              type={"text"}
              className={"form-control font-weight-bold text-black"}
              placeholder={"Manipulační poplatek"}
              value={(this.state.handling_fee * this.getCountryCurrencyInfo().exchange_rate).toFixed(this.getCountryCurrencyInfo().round_decimal_places)}
              disabled={true}
            />
          </div>
          <div
            className={"form-group col-lg-2 d-lg-block d-none"}
          />
          <div
            className={"form-group col-lg-1 col-md-4 col-6"}
          >
            <label
              className={"font-weight-bold"}
            >Váha:</label>
            <input
              type={"text"}
              className={"form-control font-weight-bold text-black"}
              placeholder={"Váha"}
              value={this.state.weight || 0}
              disabled={true}
            />
          </div>
          {this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
          this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP &&
          this.state._authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ && (
            <div
              className={"form-group col-lg-1 col-md-4 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Objednávek:</label>
              <input
                type={"text"}
                className={"form-control font-weight-bold text-black"}
                placeholder={"Počet objednávek"}
                value={this.state.order.size || 1}
                disabled={true}
              />
            </div>
          )}
        </div>

        <Promo4Plus1Preview
          isOpen={this.state._isPromo4Plus1Open}
          closeHandler={this.handleIsPromo4Plus1Close.bind(this)}
          saveHandler={this.confirmPromo4Plus1.bind(this)}
          data={this.getPromo4Plus1Data()}
          countryCurrencyInfo={this.getCountryCurrencyInfo()}
          // MY specific
          formSubmitHandler={this.handlePromo4Plus1FormSubmit.bind(this)}
          saveAsOpenHandler={this.handleSaveSaleAsOpenForPromo4Plus1.bind(this)}
        />
      </>
    )
  }

  handleScroll() {
    let pos = 0

    if (document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 50)
      pos = 50

    if (this.state._bottomMenuPos !== pos)
      this.setState({
        _bottomMenuPos: pos,
      })
  }

  handleDeliveryMethodChange(e) {
    let deliveryMethodId = parseInt(e.target.value)

    if (isNaN(deliveryMethodId)) {
      deliveryMethodId = ""
    }

    this.setState({
      delivery_method_id: deliveryMethodId,
    }, this.recalculatePrice.bind(this))
  }

  filterDeliveryMethods(deliveryMethod) {
    // all methods allowed for non open sales detail
    if (this.id && this.state.status !== "created_open")
      return true

    // "GEIS" is not allowed for new sales
    return deliveryMethod.id !== DELIVERY_METHOD.GEIS
  }

  handlePaymentMethodChange(e) {
    let paymentMethodId = parseInt(e.target.value)

    if (isNaN(paymentMethodId)) {
      paymentMethodId = ""
    }

    this.setState({
      payment_method_id: paymentMethodId,
    }, () => {
      this.recalculatePrice()

      // update all orders
      this.setState({
        order: this.state.order.map(order => Object.assign({}, order, {
          payment_method_id: paymentMethodId,
        }))
      })
    })
  }

  handleCustomerChange(val, cb) {
    API.get(`/my/customers?limit=20&offset=0&search=${val}&type_id=!${CUSTOMER_TYPE.REMOVED}`)
      .then(res => {
        let data = res.data.objects.map(item => {
          return Object.assign(item, {
            value: item.id,
            label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
          })
        })
        cb(data)
      })
  }

  handleCustomerSelect(option) {
    if (option.__isNew__) {
      API.get(`/customers?customer_no==${option.value}&limit=10`)
        .then(({data}) => {
          if (data.objects.length === 1) {
            let customer = Object.assign({}, data.objects[0], {
              value: data.objects[0].id,
              label: formatCustomerName(data.objects[0]),
            })
            this._handleCustomerSelect(customer)
          } else {
            alert("Zákazník s tímto zákaznickým číslem neexistuje.")
          }
        })
    } else {
      let customer = Object.assign({}, option, {
        value: option.id,
        label: formatCustomerName(option),
      })
      this._handleCustomerSelect(customer)
    }
  }

  _handleCustomerSelect(option) {
    let data = {
      customer: option,
      customer_id: option.id,
      billing_address_billing_name: option.billing_address_billing_name || option.billing_address_customer_name,
      billing_address_city: option.billing_address_city,
      billing_address_customer_name: option.billing_address_customer_name,
      billing_address_post_code: option.billing_address_post_code,
      billing_address_street: option.billing_address_street,
      billing_address_country_code: option.billing_address_country_code,
      delivery_method_id: this.state.delivery_method_id,
      payment_method_id: option.billing_address_country_code === "CZ"
        ? this.state.payment_method_id
        : PAYMENT_METHOD.BANK_TRANSFER,
      phone_number: "",  // reset on customer change, it will be filled in setState callback bellow
      email: "",  // reset on customer change, it will be filled in setState callback bellow
    }
    this.setState(data, () => {
      this.selectLastAddress()
      this.getCustomerPhoneNumber()
      this.getCustomerEmail()
      this.getCustomerBonusMoneyState(0)
    })
    // fill first order with the same data
    this.setState({
      order: this.state.order.update(0, () => Object.assign({}, this.state.order.get(0), data)),
    })
  }

  getCustomerPhoneNumber() {
    if (
      !this.state.phone_number &&
      this.state.customer.contact
    ) {
      let contact = this.state.customer.contact.find(contact =>
        contact.type_id !== CONTACT_TYPE.EMAIL &&
        contact.type_id !== CONTACT_TYPE.FAX
      )
      this.setState({
        phone_number: contact ? contact.value : ""
      })
    }
  }

  getCustomerEmail() {
    if (
      !this.state.email &&
      this.state.customer.contact
    ) {
      let contact = this.state.customer.contact.find(contact => contact.type_id === CONTACT_TYPE.EMAIL)

      this.setState({
        email: contact ? contact.value : ""
      })
    }
  }

  selectLastAddress() {
    if (
      this.state.customer &&
      this.state.customer.address &&
      this.state.customer.address.length > 0
    ) {
      this.setState({
        delivery_address: null,
        delivery_address_id: this.state.customer.address[0].id
      }, () => {
        // TODO: sometimes this check failed. add little bit of delay
        setTimeout(this.checkSaleToForeignCountry.bind(this), 100)
      })
    }
  }

  handleDeliveryAddressChange(e) {
    this.setState({
      delivery_address_id: parseInt(e.target.value),
      delivery_address: null,
      _delivery_address_customer_name: "",
      _delivery_address_delivery_name: "",
      _delivery_address_city: "",
      _delivery_address_post_code: "",
      _delivery_address_street: "",
    }, this.checkSaleToForeignCountry.bind(this))
  }

  handleShowAddAddressForm(e) {
    e.preventDefault()
    this.setState({
      _showAddAddressForm: !this.state._showAddAddressForm,
    })
  }

  handleAddressCustomerNameChange(e) {
    this.setState({
      _delivery_address_customer_name: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleAddressDeliveryNameChange(e) {
    this.setState({
      _delivery_address_delivery_name: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressStreetChange(e) {
    this.setState({
      _delivery_address_street: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressCityChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&city=${val}`)
      .then(res => {
        res.data.objects.forEach(item => {
          item.value = item.city
          item.label = `${item.city} (${item.post_code})`
        })
        cb(res.data.objects)
      })
  }

  handleDeliveryAddressCitySelect(option) {
    this.setState({
      _delivery_address_city: option.city,
      _delivery_address_post_code: option.post_code,
    }, () => {
      this.updateDeliveryAddress()
    })
  }


  handleDeliveryAddressPostCodeChange(val, cb) {
    API.get(`/posts?limit=0&offset=0&post_code=${val}`)
      .then(res => {
        res.data.objects.forEach(item => {
          item.value = item.post_code
          item.label = `${item.city} (${item.post_code})`
        })
        cb(res.data.objects)
      })
  }

  handleDeliveryAddressPostCodeSelect(option) {
    this.setState({
      _delivery_address_city: option.city,
      _delivery_address_post_code: option.post_code,
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handleDeliveryAddressCountryCodeChange(e) {
    this.setState({
      _delivery_address_country_code: e.target.value
    }, () => {
      this.updateDeliveryAddress()
    })
  }

  handlePhoneNumberChange(option) {
    this.setState({
      phone_number: option ? option.value : ""
    })
  }

  handlePhoneNumberBlur(e) {
    if (e.target.value) {
      this.setState({
        phone_number: e.target.value,
      })
    }
  }

  getPhoneNumberOptions() {
    let phoneNumbers = []

    if (this.state.phone_number) {
      phoneNumbers.push(this.state.phone_number)
    }

    if (
      this.state.customer.contact &&
      this.state.customer.contact.length > 0
    ) {
      this.state.customer.contact
        .filter(contact =>
          contact.type_id !== CONTACT_TYPE.EMAIL &&
          contact.type_id !== CONTACT_TYPE.FAX
        )
        .forEach(contact => {
          if (phoneNumbers.indexOf(contact.value) === -1) {
            phoneNumbers.push(contact.value)
          }
        })
    }

    return phoneNumbers.map(phoneNumber => ({
      label: phoneNumber,
      value: phoneNumber,
    }))
  }


  handleEmailChange(option) {
    this.setState({
      email: option ? option.value : ""
    })
  }

  handleEmailBlur(e) {
    if (e.target.value) {
      this.setState({
        email: e.target.value,
      })
    }
  }

  getEmailOptions() {
    let emails = []

    if (this.state.email) {
      emails.push(this.state.email)
    }

    if (
      this.state.customer.contact &&
      this.state.customer.contact.length > 0
    )
      this.state.customer.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .forEach(contact => {
          if (emails.indexOf(contact.value) === -1) {
            emails.push(contact.value)
          }
        })

    return emails.map(email => ({
      label: email,
      value: email,
    }))
  }

  handleAddOrder(e) {
    e.preventDefault()
    this.setState({
      order: this.state.order.push({
        id: uuid(),
        order_type_id: "default",
        payment_method_id: this.state.payment_method_id,
        price_type: "customer_type",
        due_days: 0,
        customer_id: null,
        billing_address_billing_name: null,
        billing_address_street: null,
        billing_address_city: null,
        billing_address_post_code: null,
        billing_address_country_code: "CZ",
        order_product: List(),
        customer: {},
        status: "created",
        price: 0,
        discount: 0,
        weight: 0,
        bonus_points: 0,
        note: List(),
        is_simple_invoice: false,
        order_product_discount_group: [],
        _note: "",
        _focus: true, // scroll to newly added orders
        _promoProducts: [],
      }),
    }, this.handleScroll.bind(this)) // update fixed elements position
  }

  handleDeleteOrder(orderId) {
    let orderIndex = this.state.order.findIndex(order => order.id === orderId)

    if (orderIndex > -1) {
      this.setState({
        order: this.state.order.delete(orderIndex),
      }, this.recalculatePrice.bind(this))
    }
  }

  handleSetOrderState(orderId, obj, cb) {
    // do not recalculate sale price on data which does not affects sale price
    // frontend specific data starts with underscore
    let needsRecalculation = !Object.keys(obj).every(key => key.indexOf("_") === 0)
    let {customer_id, billing_address_country_code} = obj

    // do not allow order with mixed countries
    if (billing_address_country_code && billing_address_country_code !== this.getCountryCurrencyInfo().code) {
      if (customer_id) {
        return alert("Zákazník má fakturační adresu do jiné země než je doručovací země zakázky.")
      }
      return alert("Země fakturační adresy se musí shodovat se zemí doručovací adresy zakázky.")
    }

    let orderIndex = this.state.order.findIndex(order => order.id === orderId)

    if (orderIndex > -1) {
      let order = Object.assign({}, this.state.order.get(orderIndex), obj)
      this.setState({
        order: this.state.order.update(orderIndex, () => order)
      }, () => {
        if (needsRecalculation) {
          this.recalculatePrice()
        }

        if (customer_id) {
          this.getCustomerBonusMoneyState(orderIndex)
          this.getNewSale(order.id, customer_id)
        }

        // new product change page height - update summary and footer position
        this.handleScroll()

        // reflect payment method from first order to sale
        if (orderIndex === 0) {
          this.setState({
            payment_method_id: order.payment_method_id,
          })
        }

        this.updateIsPromo4Plus1MenuVisible()

        if (cb) {
          cb()
        }
      })
    }
  }

  updateDeliveryAddress() {
    this.setState({
      delivery_address: {
        customer_name: this.state._delivery_address_customer_name,
        delivery_name: this.state._delivery_address_delivery_name,
        city: this.state._delivery_address_city,
        post_code: this.state._delivery_address_post_code,
        street: this.state._delivery_address_street,
        country_code: this.state._delivery_address_country_code,
      },
      delivery_address_id: null
    }, this.checkSaleToForeignCountry.bind(this))
  }

  getSale() {
    API.get(`/my/sale/${this.id}`)
      .then(res => {
        this.updateSaleDetail(res.data)
      })
  }

  getCustomer(id) {
    API.get(`/customers/${id}`)
      .then(res => {
        let customer = res.data
        customer.value = customer.id
        customer.label = formatCustomerName(customer)

        this.setState({
          // sale
          customer_id: id,
          customer: customer,
          // first order
          order: this.state.order.update(0, () => Object.assign({}, this.state.order.get(0), {
            customer_id: id,
            customer: customer,
            billing_address_billing_name: customer.billing_address_billing_name || customer.billing_address_customer_name,
            billing_address_street: customer.billing_address_street,
            billing_address_city: customer.billing_address_city,
            billing_address_post_code: customer.billing_address_post_code,
            billing_address_country_code: customer.billing_address_country_code,
          })),
        }, () => {
          this.getCustomerPhoneNumber()
          this.getCustomerEmail()
          this.selectLastAddress()
          this.getCustomerBonusMoneyState(0)
        })
      })
  }

  updateSaleDetail(data) {
    // update customer due to autocomplete
    if (this.state.customer) {
      data.customer.value = data.customer.id
      data.customer.label = formatCustomerName(data.customer)
    }
    // make List from orders
    data.order = List(data.order)
    data.order.forEach(order => {
      // make List from order products
      order.order_product = List(order.order_product)
      // make List from order notes
      order.note = List(order.note)
      // promo products
      order._promoProducts = []
    })
    // update orders customers due to autocomplete
    data.order.forEach(order => {
      order.customer.value = order.customer.id
      order.customer.label = formatCustomerName(order.customer)
    })
    this.setState(data, () => {
      this.recalculatePrice()
      this.getCustomerPhoneNumber()
      this.getCustomerEmail()
      this.updateIsPromo4Plus1MenuVisible()
    })
  }

  addSale() {
    let data = cleanState(this.state)

    // browser return datetime with T in the middle
    data.sale_date = data.sale_date.replace("T", " ")

    // delete unnecessary data
    delete data.customer
    delete data.exchange_rate

    let eachOrderContainsSomeProduct = true

    data.order.forEach((order, i) => {
      // delete unnecessary data
      data.order[i] = cleanState(order)
      // when adding new sale, delete client ids for all orders
      delete data.order[i].id
      // every order have to contain at least one product, but not only automatically added products
      if (
        data.order[i].order_product.length === 0 ||
        data.order[i].order_product.every(orderProduct => (
          orderProduct.product.code === PRODUCT_CODE.REGISTRATION ||
          orderProduct.product.code === PRODUCT_CODE.MEMBERSHIP ||
          orderProduct.product.code === PRODUCT_CODE.NEW_CUSTOMER_CARD
        ))
      ) {
        eachOrderContainsSomeProduct = false
      }
    })

    if (!data.customer_id) {
      return alert("Vyplňte zákazníka.")
    }

    if (
      data.status !== "created_open" &&
      !data.email
    ) {
      return alert("Vyplňte e-mail.")
    }

    if (
      data.status !== "created_open" &&
      !data.phone_number
    ) {
      return alert("Vyplňte telefon.")
    }

    if (!eachOrderContainsSomeProduct) {
      return alert("Na každé objednávce musí být alespoň jeden produkt.")
    }

    if (data.order.some(order => order.billing_address_country_code !== this.getCountryCurrencyInfo().code)) {
      return alert("Fakturační země na objednávkách se musí shodovat s doručovací zemí zakázky.")
    }

    API.post("/my/sale", data)
      .then(() => {
        this.formChanges = false
        this.props.history.push("/my-sales")
      })
  }

  updateSale() {
    let data = cleanState(this.state)

    // browser return datetime with T in the middle
    data.sale_date = data.sale_date.replace("T", " ")

    // delete unnecessary data
    delete data.customer
    delete data.exchange_rate

    let eachOrderContainsSomeProduct = true

    data.order.forEach((order, i) => {
      // delete unnecessary data
      data.order[i] = cleanState(order)
      // when updating new sale, delete client ids for newly added orders, or newly added refund/returns
      if (typeof data.order[i].id === "string") { // uuid
        delete data.order[i].id
      }
      // every order have to contain at least one product, but not only automatically added products
      if (
        data.order[i].order_product.length === 0 ||
        data.order[i].order_product.every(orderProduct => (
          orderProduct.product.code === PRODUCT_CODE.REGISTRATION ||
          orderProduct.product.code === PRODUCT_CODE.MEMBERSHIP ||
          orderProduct.product.code === PRODUCT_CODE.NEW_CUSTOMER_CARD
        ))
      ) {
        eachOrderContainsSomeProduct = false
      }
    })

    if (!data.customer_id) {
      return alert("Vyplňte zákazníka.")
    }

    if (
      data.status !== "created_open" &&
      !data.email
    ) {
      return alert("Vyplňte e-mail.")
    }

    if (
      data.status !== "created_open" &&
      !data.phone_number
    ) {
      return alert("Vyplňte telefon.")
    }

    if (!eachOrderContainsSomeProduct) {
      return alert("Na každé objednávce musí být alespoň jeden produkt.")
    }

    if (data.order.some(order => order.billing_address_country_code !== this.getCountryCurrencyInfo().code)) {
      return alert("Fakturační země na objednávkách se musí shodovat s doručovací zemí zakázky.")
    }

    API.put(`/my/sale/${this.id}`, data)
      .then(() => {
        this.formChanges = false
        this.props.history.push("/my-sales")
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()

    // handle unsaved notes
    let orders = this.state.order
    this.state.order.forEach((order, i) => {
      if (order.hasOwnProperty("_note") && order._note !== "") {
        orders = orders.update(i, () => Object.assign({}, order, {
          note: order.note.push({
            value: order._note,
            is_warn: false,
            is_pub: true,
            created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
          }),
        }))
      }
    })

    this.setState({
      status: this.saveAsOpen ? "created_open" : "created",
      sale_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      order: orders,
    }, () => {
      this.saveAsOpen = false // be sure :)

      if (this.id) {
        this.updateSale()
      } else {
        this.addSale()
      }
    })
  }

  handlePromo4Plus1FormSubmit() {
    // handle unsaved notes
    let orders = this.state.order
    this.state.order.forEach((order, i) => {
      if (order.hasOwnProperty("_note") && order._note !== "") {
        orders = orders.update(i, () => Object.assign({}, order, {
          note: order.note.push({
            value: order._note,
            is_warn: false,
            is_pub: true,
            created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
          }),
        }))
      }
    })

    this.setState({
      status: this.saveAsOpen ? "created_open" : "created",
      sale_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      order: orders,
    }, () => {
      this.saveAsOpen = false // be sure :)

      if (this.id) {
        this.updateSale()
      } else {
        this.addSale()
      }
    })
  }

  handleSaveSaleAsOpen() {
    this.saveAsOpen = true
  }

  handleSaveSaleAsOpenForPromo4Plus1() {
    this.saveAsOpen = true
    this.handlePromo4Plus1FormSubmit()
  }

  handleFormKeyPress(e) {
    // disable form submit by enter
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  handleCancel(e) {
    e.preventDefault()
    this.props.history.push("/my-sales")
  }

  getDeliveryMethods() {
    API.get("/deliveryMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _deliveryMethods: res.data.objects
        }, this.recalculatePrice.bind(this))
      })
  }

  getPaymentMethods() {
    API.get("/paymentMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _paymentMethods: res.data.objects
        })
      })
  }

  getOrderMethods() {
    API.get("/orderMethods?limit=0&offset=0")
      .then(res => {
        this.setState({
          _orderMethods: res.data.objects
        }, this.recalculatePrice.bind(this))
      })
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then(res => {
        this.setState({
          _countries: res.data.objects
        })
      })
  }

  getCustomerTypes() {
    API.get("/customerType?limit=0&offset=0")
      .then(res => {
        this.setState({
          _customerTypes: res.data.objects
        })
      })
  }

  getOrderTypes() {
    API.get("/orderType?limit=0&offset=0")
      .then(res => {
        this.setState({
          _orderTypes: res.data.objects
        })
      })
  }

  getAuthenticatedCustomerTypeId() {
    API.get(`/customers/${RBAC.getUser().customer_id}`)
      .then(({data}) => {
        this.setState({
          _authenticatedCustomerTypeId: data.type_id,
          _authenticatedCustomer: data,
        }, () => {
          if (!this.id && !this.customer_id)
            this.getCustomer(RBAC.getUser().customer_id)
        })
      })
  }

  getNewSale(orderId, customerId = "") {
    let url = customerId
      ? `/my/sale/new?customer_id=${customerId}`
      : "/my/sale/new"

    API.get(url)
      .then(({data}) => {
        let {order} = data
        if (order && order.length > 0) {
          let {order_product} = order[0]
          this.handleSetOrderState(orderId, {
            order_product: List(order_product)
          })
        }
      })
  }

  getCustomerTypeName(customer) {
    if (customer.type_id && this.state._customerTypes.length !== 0) {
      let types = this.state._customerTypes.filter(type => type.id === customer.type_id)
      if (types.length !== 0) {
        return types[0].name
      }
    }
    return ""
  }

  getHandlingFee(countryCode, salePriceWithoutVAT) {
    let countries = this.state._countries.filter(country => country.code === countryCode)
    if (countries.length > 0) {
      let country = countries[0]
      let handlingFeeLimit = country.handling_fee_limit / country.exchange_rate // get CZK value
      let handlingFeeConst = country.handling_fee_const / country.exchange_rate // get CZK value
      let handlingFee = handlingFeeConst - (salePriceWithoutVAT * handlingFeeConst / handlingFeeLimit)
      return handlingFee > 0 ? handlingFee : 0
    }
    return 0
  }

  async recalculatePrice() {
    let backendPriceInfo = {}
    let productsPrice = 0

    if (
      this.state.delivery_method_id &&
      this.state.payment_method_id &&
      this.state.customer.id && // sale customer is present
      this.state.order.toArray().every(order => order.customer_id) // for every order customer is present
    ) {
      // do not send unnecessary data
      let data = cleanState(this.state)
      data.order.forEach((order, i) => {
        data.order[i] = cleanState(order)
      })

      await API.post("/sale-calculate", data)
        .then(({data}) => backendPriceInfo = data)
        .catch(() => {})

      backendPriceInfo.order.forEach(order => {
        productsPrice += order.price_vat * (order.parent_order_id ? -1 : 1)
      })
    }

    let productsWeight = 0
    let bonusPoints = 0

    this.state.order.forEach(order => {
      let isReturnOrRefund = !!order.parent_order_id

      order.order_product.forEach(orderProduct => {
        // weight
        productsWeight += orderProduct.product.weight * orderProduct.quantity

        // points
        if (
          !orderProduct.is_advert &&
          order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY &&
          orderProduct.price !== 0 &&
          !this.isPromoProduct(orderProduct)
        ) {
          bonusPoints += orderProduct.product.points * orderProduct.quantity * (isReturnOrRefund ? -1 : 1)
        }
      })
    })

    this.setState({
      total_price: backendPriceInfo.total_price_vat || 0,
      delivery_price: (backendPriceInfo.packaging_price_vat + backendPriceInfo.delivery_price_vat) || 0,
      no_due_price: backendPriceInfo.no_due_price || 0,
      handling_fee: backendPriceInfo.handling_fee_vat || 0,
      _productsPrice: productsPrice || 0,
      weight: productsWeight.toFixed(2),
      bonus_points: bonusPoints,
    })
  }

  getCountryName(code) {
    let countries = this.state._countries.filter(country => country.code === code)
    if (countries.length !== 0)
      return countries[0].name
    return ""
  }

  getCountryId() {
    let countryCode = "CZ"
    // existing delivery address
    if (this.state.delivery_address_id) {
      let addresses = this.state.customer.address.filter(address => address.id === this.state.delivery_address_id)
      if (addresses.length > 0)
        countryCode = addresses[0].country_code
    }
    // new delivery address
    if (this.state.delivery_address)
      countryCode = this.state.delivery_address.country_code

    let countries = this.state._countries.filter(country => country.code === countryCode)
    if (countries.length > 0)
      return countries[0].id
    return 1
  }

  getCountryCurrencyInfo() {
    let countries = this.state._countries.filter(country => country.id === this.getCountryId())
    if (countries.length > 0)
      return countries[0]
    return {
      code: "CZ",
      currency: "CZK",
      exchange_rate: 1,
      round_decimal_places: 0,
    }
  }

  getCustomerBonusMoneyState(orderIndex) {
    let customer = this.state.order.get(orderIndex).customer

    if (
      customer
      && customer.account
      && customer.account.length > 0
      && customer.account[0].total > 200
    )
      alert("Zákazník má více jak 200 věrnostních Kč.")
  }

  deleteCustomerNote(orderId, customerId, noteIndex) {
    let orderIndex = this.state.order.findIndex(order => order.id === orderId)
    let order = this.state.order.get(orderIndex)
    let notes = [...order.customer.note]
    notes.splice(noteIndex, 1)
    API.put(`/customers/${customerId}`, {
      note: notes
    })
      .then(res => {
        this.handleSetOrderState(orderId, {
          customer: res.data,
        })
      })
  }

  checkSaleToForeignCountry() {
    if (this.getCountryId() !== COUNTRY.CZECH_REPUBLIC) {
      let order = this.state.order.get(0)
      order.payment_method_id = PAYMENT_METHOD.BANK_TRANSFER

      this.setState({
        payment_method_id: PAYMENT_METHOD.BANK_TRANSFER,
        delivery_method_id: DELIVERY_METHOD.DPD,
        order: this.state.order.update(0, () => order),
      })
    }
  }

  filterOrders(order) {
    // return all orders for new sale
    if (!this.id) {
      return true
    }

    // created by a customer sees all orders
    if (this.state._authenticatedCustomer.id === this.state.created_by_customer_id) {
      return true
    }

    // sale customer sees all orders
    if (this.state._authenticatedCustomer.id === this.state.customer.id) {
      return true
    }

    //
    if (
      this.state._authenticatedCustomer.id === order.customer.sponsor_id && (
        this.state._authenticatedCustomer.type_id === CUSTOMER_TYPE.CK ||
        this.state._authenticatedCustomer.type_id === CUSTOMER_TYPE.PP ||
        this.state._authenticatedCustomer.type_id === CUSTOMER_TYPE.PbP ||
        this.state._authenticatedCustomer.type_id === CUSTOMER_TYPE.MS
      )
    ) {
      return true
    }

    // else customer sees only his orders
    return this.state._authenticatedCustomer.id === order.customer_id
  }

  isSaleCustomer() {
    // do not check customer for new sales
    if (!this.id)
      return true

    // created by a customer is the same situation as sale customer
    if (this.state._authenticatedCustomer.id === this.state.created_by_customer_id)
      return true

    return this.state._authenticatedCustomer.id === this.state.customer.id
  }

  isSaleSaveable() {
    return this.state.status === "created_open" || !this.id
  }

  isSaleDeletable() {
    return this.state.status === "created_open" && this.id
  }

  isPaymentAndDeliverySelected() {
    return this.state.payment_method_id && this.state.delivery_method_id
  }

  handleDelete(e) {
    e.preventDefault()
    if (window.confirm("Opravdu chcete objednávku smazat? Smazanou objednávku nelze obnovit.")) {
      API.delete(`/my/sale/${this.id}`)
        .then(() => {
          this.formChanges = false
          this.props.history.push("/my-sales")
        })
    }
  }

  getProductCatalogueData() {
    API.get("/my/productTree?limit=10")
      .then(({data}) => {
        this.setState({
          _productTree: data.objects,
        })
      })
    API.get("/categories?limit=0?offset=0")
      .then(({data}) => {
        this.setState({
          _productCategories: data.objects,
        })
      })
  }

  getDiscountGroups() {
    API.get("/product-discount-groups?type=n_plus_discount")
      .then(({data}) => {
        let now = moment()
        let isActive = data.objects.some(promo => now.isBetween(moment(promo.valid_from), moment(promo.valid_to)))

        this.setState({
          _isPromo4Plus1Active: isActive,
        }, this.updateIsPromo4Plus1MenuVisible.bind(this))
      })
  }

  handleIsPromo4Plus1Open() {
    this.setState({
      _isPromo4Plus1Open: true,
    })
  }

  handleIsPromo4Plus1Close() {
    this.setState({
      _isPromo4Plus1Open: false,
    })
  }

  getPromo4Plus1Data() {
    return this.state.order
      .toArray()
      .filter(order => order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY)
      .map(order => {
        let products = []

        order.order_product
          .filter(orderProduct => orderProduct.product.code < 8000) // 8xxx & 9xxx not available in promos
          .filter(orderProduct => orderProduct.product.is_price_list_visible)
          .forEach(orderProduct => {
            for (let i = 0; i < orderProduct.quantity; i++) {
              // delete order.order_product[].id, otherwise backend will keep
              //   this order_product, and change quantity to last value, sent
              //   from client (this is primarily for situation, when updating
              //   existing sale, order_product.quantity > 1 and one of
              //   order_product will be for free.
              delete orderProduct.id

              products.push({
                id: orderProduct.product.id,
                name: getProductNameByCountry(orderProduct.product),
                price: this.getProductPrice(orderProduct, order.customer, order.price_type),
                points: orderProduct.product.points,
              })
            }
          })

        products
          .sort((product1, product2) => product2.price - product1.price)
          .forEach((product, i) => {
            if ((i + 1) % 5 === 0) {
              product.price = 0
              product.points = 0
            }
          })

        return {
          id: order.id,
          customer: `${order.customer.name || ""} ${order.customer.surname}`,
          products: products,
        }
      })
  }

  getProductPrice(orderProduct, customer, priceType) {
    if (customer.discount) {
      return orderProduct.product.prices["customer_with_discount"]
    } else if (priceType === "customer_type") {
      if (customer.type_id === CUSTOMER_TYPE.RD) {
        return orderProduct.product.prices["customer"]
      }

      return orderProduct.product.prices["consultant"]
    }

    return orderProduct.product.prices[priceType || "consultant"]
  }

  confirmPromo4Plus1(saveHandler) {
    let orders = this.state.order.toArray()
    let data = this.getPromo4Plus1Data()

    orders.forEach(order => {
      let item = data.find(item => item.id === order.id)

      if (item) {
        let orderProducts = order.order_product.toArray()

        item.products
          .filter(product => product.price === 0)
          .forEach(product => {
            let orderProduct = orderProducts.find(orderProduct => orderProduct.product_id === product.id)

            if (orderProduct.quantity === 1) {
              orderProduct.price = 0
              orderProduct.product.price = 0
              orderProduct.product.prices.consultant = 0
              orderProduct.product.prices.customer = 0
              orderProduct.product.prices.customer_with_discount = 0
            } else {
              orderProduct.quantity--

              let freeOrderProduct = Object.assign({}, orderProduct)
              freeOrderProduct.product = Object.assign({}, orderProduct.product)
              freeOrderProduct.product.prices = Object.assign({}, orderProduct.product.prices)

              freeOrderProduct.quantity = 1
              freeOrderProduct.price = 0
              freeOrderProduct.product.price = 0
              freeOrderProduct.product.prices.consultant = 0
              freeOrderProduct.product.prices.customer = 0
              freeOrderProduct.product.prices.customer_with_discount = 0
              orderProducts.push(freeOrderProduct)
            }
          })

        order.order_product = List(orderProducts)
      }
    })

    this.setState({
      order: List(orders),
      _isPromo4Plus1Open: false,
    }, () => {
      saveHandler()
    })
  }

  updateIsPromo4Plus1MenuVisible() {
    let isPromo4Plus1MenuVisible = false

    if (this.state._isPromo4Plus1Active) {
      this.state.order
        .filter(order => order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY)
        .forEach(order => {
          let productsQuantity = order.order_product
            .toArray()
            .filter(orderProduct => orderProduct.product.code < 8000) // 8xxx & 9xxx not available in promos
            .filter(orderProduct => orderProduct.product.is_price_list_visible)
            .reduce((acc, orderProduct) => {
              return acc + orderProduct.quantity
            }, 0)

          if (productsQuantity >= 5) {
            isPromo4Plus1MenuVisible = true
          }
        })
    }

    this.setState({
      _isPromo4Plus1MenuVisible: isPromo4Plus1MenuVisible && this.state.status === "created_open",
    })
  }

  isPromoProduct(orderProduct) {
    return orderProduct.hasOwnProperty("discount") &&
      orderProduct.hasOwnProperty("price") &&
      orderProduct.discount === orderProduct.price
  }
}
