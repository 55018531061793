import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {OrderedMap} from "immutable"
import Select from "react-select"
import {NavLink} from "react-router-dom"
import moment from "moment"
import DatePicker from "react-datepicker"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import API from "../API"
import CustomerTreeNode from "./CustomerTreeNode"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {ReactSelectCustomerTree} from "../Base/ReactSelectCustomerTree"
import {serializeParams} from "../Utils"
import BONUS_COLOR from "../Base/Enum/BonusColor"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"
import formatCustomerName from "../Base/formatCustomerName"
import styles from "./styles.module.css"

export default class CustomerTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sponsor: {},
      customer: {},
      date: moment().format("YYYY-MM"),
      customerTypes: [],
      customerTypeOptions: [],
      percentOptions: this.getPercentOptions(), // all percents are selected by default
      hideCustomersWithZeros: 0, // 0 - show customers with zeros, 1 - hide customers with zeros
      maxLimit: 1,
      currentLimit: 2,
      isLoading: false,
      openedNodes: [],
      isTreeOpened: false,
      process: {},
      clickedNodeId: null,

      columns: OrderedMap({
        customer_no: {
          visible: true,
          text: "Č. zákazníka",
          key: "customer_no",
          format: this.formatCustomerNo.bind(this),
        },
        type_id: {
          visible: true,
          alwaysVisible: true,
          text: "Typ zákazníka",
          key: "type_id",
          format: this.formatTypeName.bind(this),
        },
        name: {
          visible: true,
          alwaysVisible: true,
          text: "Jméno",
          key: "name",
        },
        surname: {
          visible: true,
          alwaysVisible: true,
          text: "Příjmení",
          key: "surname",
        },
        address: {
          visible: true,
          text: "Adresa",
          key: "address",
          format: this.formatAddress.bind(this),
        },
        email: {
          visible: true,
          text: "E-mail",
          key: "contact",
          format: this.formatEmail.bind(this),
        },
        phone: {
          visible: true,
          text: "Telefon",
          key: "contact",
          format: this.formatPhone.bind(this),
        },
        points_on_way: {
          visible: true,
          text: "Body na cestě",
          key: "points",
          format: this.formatPointsOnWay.bind(this),
        },
        group_points_on_way: {
          visible: true,
          text: "Skupinové body na cestě",
          key: "points",
          format: this.formatGroupPointsOnWay.bind(this),
        },
        total_points_on_way: {
          visible: true,
          text: "Celkové body na cestě",
          key: "points",
          format: this.formatTotalPointsOnWay.bind(this),
        },
        points: {
          visible: true,
          text: "Vlastní body",
          key: "points",
          format: this.formatPoints.bind(this),
        },
        group_points: {
          visible: true,
          text: "Skupinové body",
          key: "points",
          format: this.formatGroupPoints.bind(this),
        },
        total_points: {
          visible: true,
          text: "Celkové body",
          key: "points",
          format: this.formatTotalPoints.bind(this),
        },
        turn_over: {
          visible: true,
          text: "Vlastní obrat",
          key: "points",
          format: this.formatTurnOver.bind(this),
        },
        turn_over_vat: {
          visible: true,
          text: "Vlastní obrat s DPH",
          key: "points",
          format: this.formatTurnOverVAT.bind(this),
        },
        group_turn_over: {
          visible: true,
          text: "Skupinový obrat",
          key: "points",
          format: this.formatGroupTurnOver.bind(this),
        },
        group_turn_over_vat: {
          visible: true,
          text: "Skupinový obrat s DPH",
          key: "points",
          format: this.formatGroupTurnOverVAT.bind(this),
        },
        total_turn_over: {
          visible: true,
          text: "Celkový obrat",
          key: "points",
          format: this.formatTotalTurnOver.bind(this),
        },
        total_turn_over_vat: {
          visible: true,
          text: "Celkový obrat s DPH",
          key: "points",
          format: this.formatTotalTurnOverVAT.bind(this),
        },
        rest_points: {
          visible: true,
          text: "Zbytkové body",
          key: "points",
          format: this.formatRestPoints.bind(this),
        },
        rest_turn_over: {
          visible: true,
          text: "Zbytkový obrat",
          key: "points",
          format: this.formatRestTurnOver.bind(this),
        },
        rest_turn_over_vat: {
          visible: true,
          text: "Zbytkový obrat s DPH",
          key: "points",
          format: this.formatRestTurnOverVAT.bind(this),
        },
        bonus: {
          visible: true,
          text: "Procento",
          key: "points",
          format: this.formatBonus.bind(this),
        },
        created_at: {
          visible: true,
          text: "D. zadání",
          key: "created_at",
          format: this.formatCreatedAt.bind(this),
        },
        membership_extended_at: {
          visible: true,
          text: "D. prolongace",
          key: "membership_extended_at",
          format: this.formatMembershipExtendedAt.bind(this),
        },
      }),
    }

    this.updateInterval = null
  }

  componentDidMount() {
    this.getCustomerTreeMaxLimit()
    this.getTypes()
    this.getColumnVisibility()
    this.getRecalculateProcess()

    if (this.props.match.params.customer_id) {
      this.getCustomerById(this.props.match.params.customer_id)
    }
  }

  componentWillUnmount() {
    this.clearUpdateInterval()
  }

  render() {
    return (
      <>
        <div
          className={"row d-print-none"}
        >
          <div
            className={"col-lg-4"}
          >
            <button
              type={"button"}
              className={"btn btn-primary w-100 mt-lg-4 mb-3"}
              onClick={this.handleRecalculateClick.bind(this)}
              disabled={this.state.process.status === "running"}
            >
              {this.state.process.status === "running"
                ? <>Přepočítávám <SpinnerInButton/></>
                : this.getLastRecalculation()
              }
            </button>
          </div>
          <div
            className={"col-lg-2"}
          >
            <label
              className={"required"}
            >Zákazník:</label>

            <div
              className={"form-group select-required"}
            >
              <AsyncSelect
                value={this.state.customer}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerNameChange.bind(this))}
                onChange={this.handleCustomerNameSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                required={true}
                autoFocus={true}
                styles={ReactSelectCustomStyles}
              />
            </div>
          </div>

          <div
            className={"col-lg-2"}
          >
            <div
              className={"form-group"}
            >
              <label>Datum:</label>
              <DatePicker
                showMonthYearPicker={true}
                locale={"cs"}
                className={"form-control"}
                selected={moment(this.state.date, "YYYY-MM").toDate()}
                onChange={this.handleDateChange.bind(this)}
                dateFormat={"MM/yyyy"}
              />
            </div>
          </div>

          <div
            className={"col-lg-1"}
          >
            <div
              className={"form-group"}
            >
              <label>Úroveň:</label>
              <input
                type={"number"}
                className={"form-control"}
                value={this.state.currentLimit}
                min={1}
                max={this.state.maxLimit}
                onChange={this.handleLimitChange.bind(this)}
              />
            </div>
          </div>

          <div
            className={"col-lg-6"}
          >
            <div
              className={"form-group"}
            >
              <button
                className={"btn btn-primary mr-3"}
                type={"button"}
                onClick={this.handleOpenWholeTreeChange.bind(this)}
              >{this.state.currentLimit === this.state.maxLimit ? "Zabalit celý strom" : "Rozbalit celý strom"}
              </button>

              <button
                className={"btn btn-primary mr-3"}
                type={"button"}
                onClick={this.handleCloseMonthClick.bind(this)}
              >Uzavřít měsíc
              </button>

              {this.state.customer.id && (
                <>
                  <ReactHTMLTableToExcel
                    className={"btn btn-primary mr-3"}
                    table={"table-tree-xls-export"}
                    filename={`${this.state.date}-${this.state.customer.surname || ""}-${this.state.customer.name || ""}`}
                    sheet={`${this.state.date}-${this.state.customer.surname || ""}-${this.state.customer.name || ""}`}
                    buttonText={"Exportovat XLS"}
                  />
                  <button
                    className={"btn btn-primary"}
                    onClick={() => window.print()}
                  >Tisk
                  </button>
                </>
              )}
            </div>
          </div>

          <div
            className={"col-lg-6 text-right"}
          >
            <div
              className={"form-group"}
            >
              <Select
                className={"mr-3 d-inline-block react-bootstrap-table-filter text-left"}
                onChange={this.handleColumnToggleChange.bind(this)}
                value={"default"}
                options={this.getOptions()}
                closeMenuOnSelect={false}
                placeholder={"Zobrazit/schovat sloupce"}
                styles={ReactSelectCustomStyles}
              />
              <button
                className={"btn btn-light"}
                type={"button"}
                onClick={this.handleResetFilters.bind(this)}
              >Vymazat filtry
              </button>
            </div>
          </div>
        </div>

        <div
          className={"row d-print-none"}
        >
          <div
            className={"col-lg-5"}
          >
            <div
              className={"form-group"}
            >
              <label>Zobrazit zákazníky typu:</label>
              <Select
                value={this.state.customerTypeOptions}
                placeholder={"Typ zákazníka"}
                isMulti={true}
                options={this.state.customerTypes}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={ReactSelectCustomStyles}
                onChange={this.handleCustomerTypeChange.bind(this)}
              />
            </div>
          </div>
          <div
            className={"col-lg-5"}
          >
            <div
              className={"form-group"}
            >
              <label>Zobrazit zákazníky s bonusem větším než:</label>
              <Select
                value={this.state.percentOptions}
                placeholder={"Procenta"}
                isMulti={true}
                options={this.getPercentOptions()}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={ReactSelectCustomerTree}
                onChange={this.handlePercentChange.bind(this)}
              />
            </div>
          </div>
          <div
            className={"col-lg-2"}
          >
            <label
              className={"mt-lg-4"}
            >
              <input
                type={"checkbox"}
                onChange={this.handleHideCustomersWithZeros.bind(this)}
                checked={this.state.hideCustomersWithZeros}
              /> Schovat nulové
            </label>
          </div>
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-12 mt-3"}
          >
            {this.state.isLoading && <SpinnerWithOverlay/>}

            {this.state.customer.id && (
              <table
                className={`table table-tree table-hover nowrap m-0 ${this.state.isLoading ? "invisible" : ""}`}
                id={"table-tree-xls-export"}
              >
                <thead
                  className={"thead-light thead-sticky"}
                >
                <tr>
                  <th
                    className={"d-none"}
                  >Index
                  </th>
                  {this.state.columns.keySeq().toArray().map(key => {
                    return this.state.columns.get(key).visible && <th
                      key={key}
                      className={this.state.columns.get(key).className || ""}
                    >{this.state.columns.get(key).text}</th>
                  })}
                </tr>
                </thead>

                <tbody>
                {/* sponsor */}
                {this.state.sponsor.id && (
                  <CustomerTreeNode
                    node={this.state.sponsor}
                    columns={this.state.columns}
                    depth={1}
                    index={"1"}
                    showSponsorHandler={() => this.getCustomerById(this.state.sponsor.id)}
                    customerTypes={this.state.customerTypeOptions}
                    currentLimit={this.state.currentLimit}
                    openedNodes={this.state.openedNodes}
                    rowClickHandler={this.handleRowClick.bind(this)}
                    clickedNodeId={this.state.clickedNodeId}
                  />
                )}
                {/* customer */}
                {this.state.customer.id && (
                  <CustomerTreeNode
                    node={this.state.customer}
                    columns={this.state.columns}
                    depth={2}
                    index={"1.1"}
                    toggleChildrenHandler={this.handleToggleChildren.bind(this)}
                    customerTypes={this.state.customerTypeOptions}
                    currentLimit={this.state.currentLimit}
                    openedNodes={this.state.openedNodes}
                    rowClickHandler={this.handleRowClick.bind(this)}
                    clickedNodeId={this.state.clickedNodeId}
                  />
                )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    )
  }

  getData(sponsorId, customerId) {
    if (sponsorId || this.state.sponsor.id || customerId || this.state.customer.id) {
      this.setState({
        isLoading: true,
      })
    }

    if (sponsorId || this.state.sponsor.id) {
      API.get(`/customerTree/${sponsorId || this.state.sponsor.id}${this.getParams(1)}`)
        .then(({data}) => {
          data.children = [] // do not show sponsor children
          this.setState({
            sponsor: data,
            isLoading: false,
          }, () => {
            if (this.state.isTreeOpened) {
              this.openTree()
            }
          })
        })
    }

    if (customerId || this.state.customer.id) {
      API.get(`/customerTree/${customerId || this.state.customer.id}${this.getParams(this.state.currentLimit)}`)
        .then(({data}) => {
          this.setState({
            customer: data,
            isLoading: false,
          }, () => {
            if (this.state.isTreeOpened) {
              this.openTree()
            }
          })
        })
    }
  }

  getParams(limit) {
    let params = {
      limit: limit,
      from: this.state.date,
      bonus: this.state.percentOptions.length === 0
        ? undefined
        : `>=${this.state.percentOptions
          .sort((a, b) => a.value - b.value)
          .map(percent => percent.value)[0]}`, // select minimum value
      non_zero: this.state.hideCustomersWithZeros,
    }
    return serializeParams(params)
  }

  getCustomerTreeMaxLimit() {
    API.get("/customerTreeMaxLimit")
      .then(res => {
        this.setState({
          maxLimit: res.data.limit_max
        })
      })
  }

  getTypes() {
    API.get("/customerType")
      .then(({data}) => {
        data = data.objects.map(type => {
          return {
            label: type.name,
            value: type.id,
          }
        })

        this.setState({
          customerTypes: data,
          customerTypeOptions: data, // all options are selected by default
        })
      })
  }

  getCustomerById(id) {
    API.get(`/customers/${id}`)
      .then(({data}) => {
        this.setState({
          currentLimit: 2,
        }, () => {
          this.getData(data.sponsor_id, data.id)
        })
      })
  }

  handleCustomerNameChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}`)
      .then(res => {
        let data = []
        res.data.objects.forEach(item => {
          data.push(Object.assign(item, {
            value: item.id,
            label: formatCustomerName(item),
          }))
        })
        cb(data)
      })
  }

  handleCustomerNameSelect(option) {
    this.setState({
      currentLimit: 2
    }, () => {
      this.getData(option.sponsor_id, option.id)
    })
  }

  handleDateChange(date) {
    this.setState({
      date: moment(date).format("YYYY-MM")
    }, this.getData.bind(this))
  }

  handleLimitChange(e) {
    this.setState({
      currentLimit: parseInt(e.target.value),
    }, this.getData.bind(this))
  }

  handleOpenWholeTreeChange() {
    if (this.state.currentLimit === this.state.maxLimit) {
      this.setState({
        currentLimit: 2,
        openedNodes: [],
        isTreeOpened: false,
      })
    } else {
      this.setState({
        currentLimit: this.state.maxLimit,
        isTreeOpened: true,
      }, this.getData.bind(this))
    }
  }

  handleColumnToggleChange(option) {
    let {key} = option
    this.setState({
      columns: this.state.columns.set(key, Object.assign({}, this.state.columns.get(key), {
        visible: !this.state.columns.get(key).visible,
      }))
    }, this.persistColumnVisibility.bind(this))
  }

  handleResetFilters() {
    this.setState({
      customerTypeOptions: this.state.customerTypes,
      percentOptions: this.getPercentOptions(),
    }, this.getData.bind(this))
  }

  handleCloseMonthClick(e) {
    API.post("/close_month")
      .then(() => {
        alert("Měsíc uzavřen.")
      })
  }

  getOptions() {
    let arr = []
    this.state.columns.keySeq().toArray().forEach(key => {
      if (!this.state.columns.get(key).alwaysVisible) {
        arr.push({
          key: key,
          value: this.state.columns.get(key).text,
          label: `${this.state.columns.get(key).visible ? "\u2611" : "\u2610"} ${this.state.columns.get(key).text}`,
        })
      }
    })
    return arr
  }

  getPercentOptions() {
    return [{
      label: "0%",
      value: 0,
      color: BONUS_COLOR[0],
    }, {
      label: "3%",
      value: 3,
      color: BONUS_COLOR[3],
    }, {
      label: "6%",
      value: 6,
      color: BONUS_COLOR[6],
    }, {
      label: "9%",
      value: 9,
      color: BONUS_COLOR[9],
    }, {
      label: "12%",
      value: 12,
      color: BONUS_COLOR[12],
    }, {
      label: "15%",
      value: 15,
      color: BONUS_COLOR[15],
    }, {
      label: "18%",
      value: 18,
      color: BONUS_COLOR[18],
    }, {
      label: "21%",
      value: 21,
      color: BONUS_COLOR[21],
    },]
  }

  handleHideCustomersWithZeros(e) {
    this.setState({
      hideCustomersWithZeros: e.target.checked ? 1 : 0
    }, this.getData.bind(this))
  }

  handlePercentChange(options) {
    this.setState({
      percentOptions: options,
    }, this.getData.bind(this))
  }

  handleCustomerTypeChange(options) {
    this.setState({
      customerTypeOptions: options || [],
    })
  }

  handleToggleChildren(nodeId, depth) {
    let node = this.state.openedNodes.find(openNode => openNode === nodeId)

    this.setState({
      openedNodes: node
        ? this.state.openedNodes.filter(openNode => openNode !== nodeId)
        : [...this.state.openedNodes, nodeId]
    }, () => {
      if (this.state.currentLimit <= depth) {
        this.setState({
          currentLimit: depth + 1,
        }, this.getData.bind(this))
      }
    })
  }

  formatCustomerNo(customerNo, node) {
    let membershipDate = moment(node.membership_extended_at, "YYYY-MM-DD")
      .add(1, "years")
    let notificationDate = moment(node.membership_extended_at, "YYYY-MM-DD")
      .add(1, "years")
      .subtract(6, "weeks")

    return (
      <>
        <NavLink
          to={`/customers/${node.id}/detail`}
        >
          {customerNo}
        </NavLink>

        <span
          className={"d-print-none"}
        >&nbsp;({node.surname})</span>

        {moment().isBetween(notificationDate, membershipDate) && (
          <i
            className={"fas fa-clock ml-1"}
            title={"Blížící se konec členství"}
          />
        )}
      </>
    )
  }

  formatTypeName(id, node) {
    let types = this.state.customerTypes.filter(type => type.value === id)

    if (types.length === 0) {
      return ""
    }

    return (
      <>
        <span
          className={`${styles.circle} mr-2`}
          style={{
            backgroundColor: BONUS_COLOR[
              node.points && node.points.length > 0
                ? (node.points[0].bonus || 0)
                : 0
              ]
          }}
        />
        {types[0].label}
      </>
    )
  }

  formatAddress(_, node) {
    if (node) {
      return `${node.billing_address_street}, ${node.billing_address_city}, ${node.billing_address_post_code}`
    }

    return ""
  }

  formatEmail(contacts) {
    if (contacts && Array.isArray(contacts)) {
      let emails = contacts.filter(contact => contact.type_id === 1) // email

      if (emails.length > 0) {
        return emails[0].value
      }
    }

    return ""
  }

  formatPhone(contacts) {
    if (contacts && Array.isArray(contacts)) {
      let phones = contacts.filter(contact => contact.type_id === 5 || contact.type_id === 6) // phone

      if (phones.length > 0) {
        return phones[0].value
      }
    }

    return ""
  }

  formatPoints(points) {
    if (points && points.length > 0) {
      return points[0].points || 0
    }

    return 0
  }

  formatPointsOnWay(points) {
    if (points && points.length > 0) {
      return points[0].points_on_way || 0
    }

    return 0
  }

  formatGroupPoints(points) {
    if (points && points.length > 0) {
      return points[0].group_points || 0
    }

    return 0
  }

  formatGroupPointsOnWay(points) {
    if (points && points.length > 0) {
      return points[0].group_points_on_way || 0
    }

    return 0
  }

  formatTotalPointsOnWay(points) {
    if (points && points.length > 0) {
      return points[0].total_points_on_way || 0
    }

    return 0
  }

  formatTotalPoints(points) {
    if (points && points.length > 0) {
      return points[0].total_points || 0
    }

    return 0
  }

  formatTurnOver(points) {
    if (points && points.length > 0) {
      return points[0].turn_over || 0
    }

    return 0
  }

  formatTurnOverVAT(points) {
    if (points && points.length > 0) {
      return points[0].turn_over_vat || 0
    }

    return 0
  }

  formatGroupTurnOver(points) {
    if (points && points.length > 0) {
      return points[0].group_turn_over || 0
    }

    return 0
  }

  formatGroupTurnOverVAT(points) {
    if (points && points.length > 0) {
      return points[0].group_turn_over_vat || 0
    }

    return 0
  }

  formatTotalTurnOver(points) {
    if (points && points.length > 0) {
      return points[0].total_turn_over || 0
    }

    return 0
  }

  formatTotalTurnOverVAT(points) {
    if (points && points.length > 0) {
      return points[0].total_turn_over_vat || 0
    }

    return 0
  }

  formatRestPoints(points) {
    if (points && points.length > 0) {
      return points[0].rest_points || 0
    }

    return 0
  }

  formatRestTurnOver(points) {
    if (points && points.length > 0) {
      return points[0].rest_turn_over || 0
    }

    return 0
  }

  formatRestTurnOverVAT(points) {
    if (points && points.length > 0) {
      return points[0].rest_turn_over_vat || 0
    }

    return 0
  }

  formatBonus(points) {
    if (points && points.length > 0) {
      return points[0].bonus || 0
    }

    return 0
  }

  formatCreatedAt(createdAt) {
    return moment(createdAt).format("DD. MM. YYYY")
  }

  formatMembershipExtendedAt(membershipExtendedAt) {
    if (membershipExtendedAt) {
      return moment(membershipExtendedAt).format("DD. MM. YYYY")
    }

    return ""
  }

  getColumnVisibility() {
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    if (settings && settings.hasOwnProperty("/customers/tree")) {
      let columnSettings = settings["/customers/tree"]
      let columns = {}

      columnSettings.forEach(col => {
        columns[col.dataField] = Object.assign({}, this.state.columns.get(col.dataField), {
          visible: col.visible,
        })
      })

      this.setState({
        columns: OrderedMap(columns)
      })
    }
  }

  persistColumnVisibility() {
    let columns = this.state.columns.keySeq().toArray().map(key => {
      return {
        dataField: key,
        visible: this.state.columns.get(key).visible,
      }
    })

    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    settings["/customers/tree"] = columns
    localStorage.setItem("missiva_is.grid_settings", JSON.stringify(settings))
  }

  openTree() {
    let openedNodes = [
      this.state.customer.id
    ]

    let getNodeIds = node => {
      node.children
        .filter(child => child.children.length > 0)
        .forEach(child => {
          openedNodes.push(child.id)
          getNodeIds(child)
        })
    }

    getNodeIds(this.state.customer)

    this.setState({openedNodes})
  }

  clearUpdateInterval() {
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  setUpdateInterval() {
    this.clearUpdateInterval()
    this.updateInterval = setInterval(this.getRecalculateProcess.bind(this), 10000) // 10s
  }

  handleRecalculateClick() {
    API.get(`/points/calculate/${moment(this.state.date).format("YYYY/MM")}`)
      .then(this.getRecalculateProcess.bind(this)) // this call can ends before 10s
    setTimeout(this.getRecalculateProcess.bind(this), 500)
  }

  getRecalculateProcess() {
    API.get("/process/status/points_monthly")
      .then(({data}) => {
        if (this.updateInterval === null && data.status === "running") {
          this.setUpdateInterval()
        }

        if (this.updateInterval !== null && data.status !== "running") {
          this.clearUpdateInterval()
          this.getData()
        }

        this.setState({
          process: data
        })
      })
  }

  getLastRecalculation() {
    let date = ""

    if (this.state.process.finished_at) {
      date = moment(this.state.process.finished_at).format("DD. MM. YYYY HH:mm:ss")
    }

    if (date) {
      return `Přepočítat (poslední přepočet: ${date})`
    }

    return "Přepočítat"
  }

  handleRowClick(clickedNodeId) {
    this.setState({clickedNodeId})
  }
}
