import React, {Fragment} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import {NavLink} from "react-router-dom"
import API from "../API"
import {getParams} from "../Utils"
import Grid from "../Base/Grid"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import GridDateRangeFilter from "../Base/GridDateRangeFilter"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import print from "../Base/print"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class WareHouseList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        status: "=print_ready|print_ready_paid|printed|printed_paid",
        sort: "status(print_ready|print_ready_paid|printed|printed_paid),-created_at",
      }),
      _columns: [],
      _saleStates: [],
      _deliveryMethods: [],
      _salesWithDeliveryMethod2: 0,
      _salesWithDeliveryMethod3: 0,
      _salesWithDeliveryMethod4: 0,
      _salesToForeignCountry: 0,
    }
    this.filters = {}
    this.apiUrl = "/sales"
    this.appUrl = "/warehouse/sales"
    this.defaultParams = ["status"]
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-6 mb-3"}
        >
          <span
            className={"text-secondary mr-2"}
          >Zobrazit:</span>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.CZECH_POST ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.CZECH_POST)}
          >Pošta
            <span
              className={`badge badge-${this.state._salesWithDeliveryMethod2 === 0 ? "light" : "danger"} ml-2`}
            >{this.state._salesWithDeliveryMethod2}</span>
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.DPD ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.DPD)}
          >DPD
            <span
              className={`badge badge-${this.state._salesWithDeliveryMethod4 === 0 ? "light" : "danger"} ml-2`}
            >{this.state._salesWithDeliveryMethod4}</span>
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.PERSONAL_PICKUP)}
          >Osobní odběr
            <span
              className={`badge badge-${this.state._salesWithDeliveryMethod3 === 0 ? "light" : "danger"} ml-2`}
            >{this.state._salesWithDeliveryMethod3}</span>
          </button>
          <button
            className={`btn btn-sm ${this.state.params.delivery_address__country_code === "!CZ" ? "bg-green" : "btn-info"}`}
            onClick={this.handleForeignCountryFilterChange.bind(this)}
          >Zahraničí
            <span
              className={`badge badge-${this.state._salesToForeignCountry === 0 ? "light" : "danger"} ml-2`}
            >{this.state._salesToForeignCountry}</span>
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  getData() {
    super.getData()
    this.getCounts()
  }

  _getData() {
    axios.all([
      this.getSaleStates(),
      this.getDeliveryMethods(),
      this.getPaymentMethods(),
    ]).then(axios.spread((
      saleStatesRes,
      deliveryMethodsRes,
      paymentMethodsRes
    ) => {
      let _saleStates = {}
      let _deliveryMethods = {}
      let _paymentMethods = {}

      saleStatesRes.data.objects.forEach(state => _saleStates[state.id] = state.name)
      deliveryMethodsRes.data.objects.forEach(method => _deliveryMethods[method.id] = method.name)
      paymentMethodsRes.data.objects.forEach(method => _paymentMethods[method.id] = method.name)

      this.setState({
        _saleStates,
        _deliveryMethods,
        _paymentMethods,
      }, () => {
        this.getData()
        this.getCounts()
      })
    }))
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  _getCounts(param, key) {
    API.get(`/sales?offset=0&limit=1&status==print_ready|print_ready_paid&${param}`)
      .then(({data}) => {
        this.setState({
          [key]: data.meta.total_count,
        })
      })
  }

  getCounts() {
    this._getCounts(`delivery_method_id=${DELIVERY_METHOD.CZECH_POST}`, "_salesWithDeliveryMethod2")
    this._getCounts(`delivery_method_id=${DELIVERY_METHOD.PERSONAL_PICKUP}`, "_salesWithDeliveryMethod3")
    this._getCounts(`delivery_method_id=${DELIVERY_METHOD.DPD}`, "_salesWithDeliveryMethod4")
    this._getCounts("delivery_address__country_code=!CZ", "_salesToForeignCountry")
  }

  getDefaultColumns() {
    return [{
      text: "Způsob doručení",
      dataField: "delivery_method_id",
      filter: selectFilter({
        options: this.state._deliveryMethods,
        placeholder: "Způsob doručení",
        defaultValue: this.state.params.delivery_method_id || "",
        getFilter: filter => this.filters.delivery_method_id = filter,
      }),
      headerClasses: () => this.state.params.delivery_method_id ? "bg-green" : "",
      formatter: this.formatDeliveryMethod.bind(this),
      formatExtraData: this.state._deliveryMethods,
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      filter: selectFilter({
        options: this.state._paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state._paymentMethods,
    }, {
      text: "Stav",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      formatExtraData: this.state._saleStates,
    }, {
      text: "Čas pořízení zakázky",
      dataField: "sale_date",
      headerClasses: () => this.state.params.sale_date ? "bg-green" : "",
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.sale_date = () => onFilter("")
        this.filters["sale_date-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.sale_date}
            defaultValueTo={this.state.params["sale_date-2"]}
          />
        )
      },
    }, {
      text: "Č. zakázky",
      dataField: "sale_no",
      formatter: this.formatSaleNo.bind(this),
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale_no ? `${this.state.params.sale_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale_no ? "bg-green" : ""}`,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      formatter: this.formatCustomerSurname.bind(this),
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_2 ${this.state.params.customer__surname ? "bg-green" : ""}`,
    }, {
      text: "Země",
      dataField: "delivery_address__country_code",
      filter: textFilter({
        placeholder: "Země",
        defaultValue: this.state.params.delivery_address__country_code || "",
        getFilter: filter => this.filters.delivery_address__country_code = filter,
      }),
      headerClasses: () => this.state.params.delivery_address__country_code ? "bg-green" : "",
      formatter: this.formatDeliveryAddressCountry.bind(this),
    }, {
      text: "Počet objednávek",
      dataField: "order",
      formatter: this.formatOrder.bind(this),
    }, {
      text: "Váha",
      dataField: "weight",
      formatter: this.formatWeight.bind(this),
    }, {
      text: "Tisknout fakturu a dodací list",
      dataField: "docs_url",
      formatter: this.formatPrintInvoiceAndDeliveryList.bind(this),
    }, {
      text: "Vytiskni štítek balíku č.",
      dataField: "shipment_label",
      formatter: this.formatPrintShipmentLabel.bind(this),
    }]
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    return (
      <Fragment>
        <span
          className={`fas fa-${cell === "print_ready" ? "check" : "print"} mr-1`}
        />
        {formatExtraData[cell] || ""}
      </Fragment>
    )
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.surname : ""
  }

  formatDeliveryAddressCountry(cell, row, roIndex, formatExtraData) {
    if (row.delivery_address)
      return row.delivery_address.country_code
    return ""
  }

  formatDeliveryMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return cell.length
  }

  formatWeight(cell, row, rowIndex, formatExtraData) {
    return `${cell} kg`
  }

  formatPrintInvoiceAndDeliveryList(cell, row, rowIndex, formatExtraData) {
    return (
      <button
        className={`btn btn-sm mr-2 text-nowrap w-100 btn-${row.is_changed_after_packing ? "danger" : row.status.indexOf("printed") > -1 ? "info" : "primary"} ${cell ? "" : "disabled"}`}
        onClick={() => this.handlePrintInvoiceAndDeliveryListClick(cell, row)}
      >
        <i
          className={"mr-2 fas fa-file-invoice"}
        />
        {row.status.indexOf("printed") > -1
          ? "Tisknout znovu"
          : "Tisknout fakturu a dodací list"
        }
      </button>
    )
  }

  formatPrintShipmentLabel(cell, row, rowIndex, formatExtraData) {
    if (row.status.indexOf("printed") > -1) {
      if (row.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP) {
        return (
          <button
            className={"btn btn-sm btn-primary text-nowrap w-100"}
            onClick={() => this.handleShipmentPickedClick(row)}
          >Zboží vydáno</button>
        )
      } else {
        return row.shipment.length > 0
          ? row.shipment[0].boxes.map((box, i) => (
            <button
              key={box.id}
              className={"btn btn-sm btn-primary text-nowrap mr-1"}
              onClick={() => this.handlePrintShipmentLabelClick(row.id, box.id)}
            >{i + 1}</button>
          ))
          : ""
      }
    }

    return ""
  }

  handlePrintInvoiceAndDeliveryListClick(url, sale) {
    print(url)

    API.patch(`/sales/${sale.id}`, {
      status: sale.status.indexOf("paid") > -1 ? "printed_paid" : "printed"
    }).then(() => {
      // make sure columns docs_url and shipment_label will get updated. PATCH above modify
      // only status, and grid does not update same column values...
      let index = this.state.data.findIndex(s => s.id === sale.id)
      let data = [...this.state.data]
      data[index] = Object.assign({}, data[index], {
        docs_url: "",
        shipment_label: "",
      })
      this.setState({data})

      this.getData()
      this.getCounts()
    })
  }

  handleShipmentPickedClick(sale) {
    API.patch(`/sales/${sale.id}`, {
      status: "paid",
    }).then(() => {
      this.getData()
      this.getCounts()
    })
  }

  handlePrintShipmentLabelClick(saleId, boxId) {
    API.get(`/shipment/label/${saleId}`)
      .then(({data}) => {
        data.shipment.forEach(shipment => {
          let box = shipment.boxes.find(box => box.id === boxId)
          print(box.label_url)
        })
      })
  }

  handleDeliveryAndPaymentFilterChange(deliveryMethodId) {
    if (this.state.params.delivery_method_id === deliveryMethodId) {
      this.filters.delivery_method_id("") // reset filter
    } else {
      this.filters.delivery_address__country_code("") // reset country filter if delivery method is set
      this.filters.delivery_method_id(deliveryMethodId)
    }
  }

  handleForeignCountryFilterChange() {
    if (this.state.params.delivery_address__country_code === "!CZ") {
      this.filters.delivery_address__country_code("") // reset filter
    } else {
      this.filters.delivery_method_id("") // reset delivery method filter if country filter is set
      this.filters.delivery_address__country_code("!CZ")
    }
  }
}
