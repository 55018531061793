import React, {Component} from "react"
import {NavLink} from "react-router-dom"

export default class ProductMenu extends Component {
  render() {
    return (
      <div
        className={"row d-print-none"}
      >
        <div
          className={"col-12"}
        >
          <NavLink
            to={"/products/add"}
            className={"btn btn-primary mr-3"}
          >Nový produkt</NavLink>
          {this.props.isDetail && this.props.productId && <NavLink
            to={`/products/${this.props.productId}/history`}
            className={"btn btn-light mr-3"}
          >Historie</NavLink>}
          <button
            className={"btn btn-danger float-right"}
            onClick={this.props.deleteHandler}
            disabled={!this.props.isDetail}
          >Smazat produkt
          </button>
        </div>
      </div>
    )
  }
}
