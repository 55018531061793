import React, {Component} from "react"
import axios from "axios"
import {debounce} from "throttle-debounce"
import API from "../API"
import styles from "./styles.module.css"
import print from "../Base/print"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class WareHouseScales extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saleNumber: "",
      sale: null,
      saleBoxCount: 1,
      showBoxCountConfirmed: false,
      saleBoxIndex: 0,
      scaleWeight: 0,
      packageTypes: [],
      packageType: {},
      scaleRoleUsers: [],
      isLoading: false,
    }
    this.weightTolerance = 0.5 // ±0.5 kg
    this.saleNumberInputRef = React.createRef()
    this.saleBoxCountInputRef = React.createRef()
    this.getSaleDetailDebounced = debounce(750, this.getSaleDetail.bind(this))
  }

  componentDidMount() {
    this.readScaleWeightInterval = setInterval(this.readScaleWeight.bind(this), 3000)
    this.getPackageTypes()
    this.getScaleRoleUsers()
  }

  componentWillUnmount() {
    clearInterval(this.readScaleWeightInterval)
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <div
            className={"col-12"}
          >
            <label>Číslo zakázky:</label>
            <div
              className={"input-group mb-2"}
            >
              <input
                autoFocus={true}
                ref={this.saleNumberInputRef}
                className={`${styles.saleInput} text-center border-0 text-white bg-${this.state.sale ? "success" : "danger"}`}
                value={this.state.saleNumber}
                onChange={this.handleSaleNumberChange.bind(this)}
                onBlur={this.handleSaleNumberBlur.bind(this)}
              />
              <div
                className={"input-group-append"}
              >
                <button
                  className={`${styles.resetButton} btn btn-light border px-4`}
                  onClick={this.handleReset.bind(this)}
                >&times;</button>
              </div>
            </div>
          </div>

          <div
            className={"col-6"}
          >
            <label>Počet balíků:</label>
            <div
              className={"input-group mb-2"}
            >
              <input
                type={"number"}
                className={`${styles.boxCountInput} form-control py-4`}
                value={this.state.saleBoxCount}
                ref={this.saleBoxCountInputRef}
                onChange={this.handleSaleBoxCountChange.bind(this)}
                disabled={!this.state.sale || this.state.saleBoxCountConfirmed}
                min={1}
              />
              <div
                className={"input-group-append"}
              >
                <button
                  className={`${styles.boxButton} btn btn-primary py-2`}
                  onClick={this.handleSaleBoxCountConfirmClick.bind(this)}
                  disabled={!this.state.sale || this.state.saleBoxCount < 1 || this.state.saleBoxCountConfirmed}
                >Potvrdit počet balíků
                </button>
              </div>
            </div>

            <label>Vybrat balík:</label>
          </div>

          <div
            className={"col-6"}
          >
            <label>Zrušit zásilku:</label>
            <button
              className={`${styles.boxButton} btn btn-warning py-2 w-100`}
              disabled={!this.state.sale || !this.state.saleBoxCountConfirmed}
              onClick={this.handleCancelShipmentClick.bind(this)}
            >Zrušit zásilku
            </button>
          </div>

          {this.state.saleBoxCountConfirmed ?
            (
              this.state.sale.shipment[0].boxes.map((box, i) => (
                <div
                  className={"col-3"}
                  key={box.id}
                >
                  <button
                    className={`btn py-2 w-100 mb-2 border btn-light ${styles.boxButton} ${this.state.saleBoxIndex === i ? "border-success" : ""}`}
                    onClick={() => this.handleBoxIndexChange(i)}
                  >
                    Balík č. {i + 1}
                    <small>{box.printed_user_id ? ` (${box.weight} kg, vytištěno)` : ""}</small>
                    {this.state.saleBoxIndex === i && (
                      <i
                        className={"fas fa-check-circle float-right mt-2 text-success"}
                      />
                    )}
                  </button>
                </div>
              ))
            ) : (
              <div
                className={"col-3"}
              >
                {/* dummy button to prepare layout */}
                <button
                  disabled={true}
                  className={`btn py-2 w-100 mb-2 border border-light ${styles.boxButton}`}
                >Balík č. 1
                </button>
              </div>
            )}
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-6"}
          >
            <label>Váha balíku/Očekávaná váha balíku:</label>
            <h2
              className={`${styles.saleWeight} p-2 text-center text-white bg-${this.state.saleBoxCountConfirmed && this.boxHasExpectedWeight() ? "success" : "danger"}`}
            >{this.state.scaleWeight}/{this.formatFloat(this.getSaleBoxWeight() + this.getPackageTypeWeight())} kg</h2>
          </div>

          <div
            className={"col-6"}
          >
            <label>Váha zakázky/Očekávaná váha zakázky:</label>
            <h2
              className={`${styles.saleWeight} p-2 text-center text-white bg-${this.state.saleBoxCountConfirmed && this.saleHasExpectedWeight() ? "success" : "danger"}`}
            >{this.getPrintedBoxesWeight() + this.state.scaleWeight}/{this.formatFloat(this.state.saleBoxCountConfirmed ? this.state.sale.weight + this.getPackageTypeWeight() : 0)} kg</h2>
          </div>

          <div
            className={"col-12"}
          >
            <label>Typ balíku:</label>
            <div
              className={"row text-center"}
            >
              {this.state.packageTypes.map(packageType => (
                <div
                  className={"col-2"}
                  key={packageType.id}
                >
                  <button
                    className={`btn py-2 d-block w-100 border mb-3 btn-light ${styles.packageTypeButton} ${this.state.packageType.id === packageType.id ? "border-success" : ""}`}
                    onClick={() => this.handlePackageTypeClick(packageType)}
                    disabled={!this.state.sale}
                  >
                    {packageType.type}
                    {this.state.packageType.id === packageType.id && (
                      <i
                        className={"fas fa-check-circle float-right mt-2 text-success"}
                      />
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div
            className={"col-12"}
          >
            <label>Vytisknout štítek pro:</label>
            <div
              className={"row"}
            >
              {this.state.scaleRoleUsers.map(user => (
                <div
                  className={"col-2"}
                  key={user.id}
                >
                  <button
                    className={`btn btn-primary d-block w-100 mb-3 py-2 ${styles.userButton}`}
                    disabled={!this.state.sale || !this.state.packageType.id || this.state.saleBoxIndex >= this.state.saleBoxCount}
                    onClick={() => this.handlePrintShipmentLabelClick(user.id)}
                  >{user.name}</button>
                </div>
              ))}
            </div>
          </div>

          <div
            className={"col-12"}
          >
            <label>Dokončit zakázku:</label>
            <button
              type={"button"}
              className={`btn btn-success p-2 w-100 ${styles.userButton}`}
              disabled={!this.isAllBoxesPrinted()}
              onClick={this.handleFinishSaleClick.bind(this)}
            >Zakázka zabalena a připravena k expedici
            </button>
          </div>
        </div>
      </>
    )
  }

  handleSaleNumberChange(e) {
    this.setState({
      saleNumber: e.target.value
    }, this.getSaleDetailDebounced)
  }

  handleSaleNumberBlur() {
    if (!this.state.saleNumber) {
      this.focusSaleNumberInput()
    }
  }

  handleReset() {
    this.setState({
      saleNumber: "",
      sale: "",
      scaleWeight: 0,
      saleBoxIndex: 0,
      packageType: {},
      saleBoxCount: 1,
      saleBoxCountConfirmed: false,
    }, this.focusSaleNumberInput.bind(this))
  }

  handleSaleBoxCountChange(e) {
    this.setState({
      saleBoxCount: parseInt(e.target.value, 10)
    })
  }

  handleSaleBoxCountConfirmClick() {
    this.setState({
      isLoading: true,
    })

    API.get(`/shipment/label/${this.state.sale.id}?num_box=${this.state.saleBoxCount}`)
      .then(({data}) => {
        this.handleConfirmedSaleDetail(data)
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  handleCancelShipmentClick() {
    this.setState({
      isLoading: true,
    })

    API.delete(`/shipment/${this.state.sale.shipment[0].id}`)
      .then(() => {
        this.setState({
          saleBoxIndex: 0,
          packageType: {},
          saleBoxCount: 1,
          saleBoxCountConfirmed: false,
        }, () => {
          this.saleBoxCountInputRef.current.focus()
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  handleBoxIndexChange(saleBoxIndex) {
    this.setState({saleBoxIndex})
  }

  focusSaleNumberInput() {
    this.saleNumberInputRef.current.focus()
  }

  getSaleDetail = async () => {
    if (this.state.saleNumber !== "") {
      API.get(`/sales?sale_no==${this.state.saleNumber}&status=printed&limit=1`)
        .then(({data}) => {
          if (data.objects.length > 0) {
            this.handleSaleDetail(data.objects[0])
          } else {
            alert("Zakázka nenalezena.")
          }
        })
    }
  }

  handleSaleDetail(sale) {
    // sale was loaded for 1st time
    if (sale.shipment.length === 0) {
      API.get(`/shipment/label/${sale.id}?type=preview`)
        .then(({data}) => {
          this.setState({
            sale: data,
            saleBoxCount: data.shipment[0].boxes.length,
            saleBoxCountConfirmed: false,
          }, () => {
            this.saleBoxCountInputRef.current.focus()
          })
        })
    } else { // sale was already loaded before
      this.handleConfirmedSaleDetail(sale)
    }
  }

  handleConfirmedSaleDetail(sale) {
    let firstNotPrintedBoxIndex = sale.shipment[0].boxes.findIndex(box => !box.printed_user_id)

    this.setState({
      sale: sale,
      saleBoxIndex: firstNotPrintedBoxIndex >= 0 ? firstNotPrintedBoxIndex : 0,
      saleBoxCount: sale.shipment[0].boxes.length,
      saleBoxCountConfirmed: true,
    })
  }

  readScaleWeight() {
    axios({
      method: "GET",
      url: "http://127.0.0.1:3210",
      contentType: "application/json",
      responseType: "json",
    }).then(({data}) => {
      this.setState({
        scaleWeight: parseFloat(data.weight)
      })
    }).catch(console.warn)
  }

  saleHasExpectedWeight() {
    if (this.state.sale) {
      return (
        this.state.sale.weight + this.weightTolerance >= this.getPrintedBoxesWeight() &&
        this.state.sale.weight - this.weightTolerance <= this.getPrintedBoxesWeight()
      )
    }

    return false
  }

  getPrintedBoxesWeight() {
    if (this.state.sale) {
      return this.state.sale.shipment[0].boxes
        .filter(box => box.printed_user_id)
        .reduce((acc, box) => acc + box.weight, 0)
    }

    return 0
  }

  boxHasExpectedWeight() {
    return (
      this.state.scaleWeight + this.weightTolerance >= this.getSaleBoxWeight() &&
      this.state.scaleWeight - this.weightTolerance <= this.getSaleBoxWeight()
    )
  }

  getSaleBoxWeight() {
    if (this.state.saleBoxCountConfirmed) {
      let boxes = this.state.sale.shipment[0].boxes.length

      if (boxes > this.state.saleBoxIndex) {
        return this.state.sale.shipment[0].boxes[this.state.saleBoxIndex].weight
      }
    }

    return 0
  }

  getPackageTypeWeight() {
    if (this.state.packageType.weight) {
      return this.state.packageType.weight / 1000
    }

    return 0
  }

  // prevent .999 numbers (eg. 0.295 + 0.5 = 0.7949999999999999)
  formatFloat(f) {
    if (typeof f === "number") {
      return parseFloat(f.toFixed(2))
    }

    return 0
  }

  handlePrintShipmentLabelClick = async (userId) => {
    this.setState({
      isLoading: true,
    })

    let box = Object.assign({}, this.state.sale.shipment[0].boxes[this.state.saleBoxIndex], {
      package_type_id: this.state.packageType.id,
      printed_user_id: userId,
      weight: this.state.scaleWeight,
    })

    await API.patch(`/sales/${this.state.sale.id}`, {
      shipment: [{
        id: this.state.sale.shipment[0].id,
        boxes: [
          box
        ]
      }]
    })

    API.get(`/shipment/label/${this.state.sale.id}`)
      .then(({data}) => {
        print(data.shipment[0].boxes[this.state.saleBoxIndex].label_url)

        this.setState({
          sale: data,
          scaleWeight: 0,
          saleBoxIndex: this.state.saleBoxIndex + 1,
          packageType: {},
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  getPackageTypes() {
    API.get("/packageTypes?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          packageTypes: data.objects,
        })
      })
  }

  handlePackageTypeClick(packageType) {
    if (
      this.state.scaleWeight < 0.15 ||
      this.state.scaleWeight > 30
    ) {
      alert("Váha balíku musí být v rozmezí 0,15 až 30 kg.")
      this.handleCancelShipmentClick()
    } else {
      this.setState({packageType})
    }
  }

  getScaleRoleUsers() {
    API.get("/users?limit=0&offset=0&role_id=WAREHOUSE")
      .then(({data}) => {
        this.setState({
          scaleRoleUsers: data.objects.filter(user => user.name !== "Sklad"),
        })
      })
  }

  isAllBoxesPrinted() {
    if (this.state.sale) {
      return this.state.sale.shipment[0].boxes.every(box => box.printed_user_id)
    }

    return false
  }

  handleFinishSaleClick = async () => {
    await API.patch(`/sales/${this.state.sale.id}`, {
      status: "packed",
    })

    this.handleReset()
  }
}
