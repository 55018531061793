const ReactSelectCustomStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 .3rem rgba(255, 200, 0);" : ""
    }
  },
  menu: styles => {
    return {
      ...styles,
      zIndex: 102,
      minWidth: "250px",
    }
  },
  menuList: styles => {
    return {
      ...styles,
      maxHeight: "350px",
      minWidth: "250px",
    }
  },
  singleValue: (styles, state) => {
    return {
      ...styles,
      color: state.selectProps.menuIsOpen ? "transparent" : styles.color,
    }
  },
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: "#F0F0F0",
    }
  },
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isFocused ? "#007BFF" : "transparent",
      color: state.isFocused ? "#FFFFFF" : "#000000",
    }
  }
}

const ReactSelectCustomStylesHighlight = Object.assign({}, ReactSelectCustomStyles, {
  control: (styles, state) => {
    return {
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 .3rem rgba(255, 200, 0);" : "",
      background: "#CCFF90 !important",
      fontWeight: "bold",
    }
  }
})

export {
  ReactSelectCustomStyles,
  ReactSelectCustomStylesHighlight,
}
