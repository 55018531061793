import React, {Component} from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {List} from "immutable"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import ReactSelectTableOption from "../../Base/ReactSelectTableOption"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import COUNTRY from "../../Base/Enum/Countries"

export default class DiscountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _types: [],
      _categories: [],
      _selectedCategory: null,
      _selectedCategoryDiscountType: 1,
      _selectedCategoryDiscountValue: 0,
      valid_from: moment().format("YYYY-MM-DD HH:mm:ss"),
      valid_to: moment().format("YYYY-MM-DD HH:mm:ss"),
      admin_valid_to: moment().format("YYYY-MM-DD HH:mm:ss"),
      product_discount: List(),
    }
  }

  componentDidMount() {
    this.getData()

    if (this.props.match.params.id) {
      this.getDiscountDetail()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-row"}
            >
              <div
                className={"mb-2 col-12"}
              >Nastavte dobu trvání slevy:
              </div>

              <div
                className={"form-group col-3"}
              >
                <label>Začátek slevy:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_from, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidFromChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-3"}
              >
                <label>Konec slevy v moje.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidToChange.bind(this)}
                />
              </div>

              <div
                className={"form-group col-3"}
              >
                <label>Konec slevy v is.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.admin_valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleAdminValidToChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={"form-group mt-4"}
            >Vyberte produkty:
            </div>
            <table
              className={"table table-striped"}
            >
              <thead>
              <tr>
                <th>Název produktu</th>
                <th>Typ slevy</th>
                <th>Hodnota slevy</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {/* products */}
              {this.state.product_discount.map((product, index) => (
                <tr
                  key={index}
                >
                  <td>{product._name}</td>
                  <td>
                    <select
                      className={"form-control"}
                      value={product.type}
                      onChange={e => this.handleProductTypeChange(parseInt(e.target.value, 10), index)}
                    >
                      {this.state._types.map(type => (
                        <option
                          key={type.id}
                          value={type.id}
                        >{type.name}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type={"number"}
                      className={"form-control"}
                      value={product.value}
                      onChange={e => this.handleProductValueChange(parseInt(e.target.value, 10), index)}
                    />
                  </td>
                  <td
                    className={"text-right"}
                  >
                    <button
                      type={"button"}
                      className={"btn btn-sm btn-danger"}
                      onClick={() => this.handleProductDelete(product.product_id)}
                    >Smazat
                    </button>
                  </td>
                </tr>
              ))}

              {/* category */}
              {this.state._selectedCategory && (
                <tr>
                  <td>Kategorie
                    - {this.state._selectedCategory.label} ({this.state._selectedCategory.products.length} produktů)
                  </td>
                  <td>
                    <select
                      className={"form-control"}
                      value={this.state._selectedCategoryDiscountType}
                      onChange={this.handleCategoryTypeChange.bind(this)}
                    >
                      {this.state._types.map(type => (
                        <option
                          key={type.id}
                          value={type.id}
                        >{type.name}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type={"number"}
                      className={"form-control"}
                      value={this.state._selectedCategoryDiscountValue}
                      onChange={this.handleCategoryValueChange.bind(this)}
                    />
                  </td>
                  <td
                    className={"text-right"}
                  >
                    <button
                      type={"button"}
                      className={"btn btn-sm btn-danger"}
                      onClick={this.handleCategoryDelete.bind(this)}
                    >Smazat
                    </button>
                  </td>
                </tr>
              )}
              <tr>
                <td
                  colSpan={4}
                >
                  <AsyncSelect
                    value={null}
                    className={"react-select_border product-search-input"}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                    onChange={this.handleProductSearchSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    placeholder={"Vyberte produkt"}
                    required={true}
                    styles={ReactSelectCustomStyles}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                    isDisabled={this.state._selectedCategory}
                  />
                  <div
                    className={"my-2"}
                  >nebo
                  </div>
                  <Select
                    className={"product-search-input"}
                    placeholder={"Vyberte kategorii"}
                    options={this.state._categories}
                    value={null}
                    onChange={this.handleCategorySelect.bind(this)}
                    isDisabled={this.state.product_discount.size > 0}
                  />
                </td>
              </tr>
              </tbody>
            </table>
            {this.props.match.params.id && !this.isActive() && (
              <NavLink
                className={"btn btn-primary mr-2"}
                to={`/products/discount/add-discount/${this.props.match.params.id}`}
              >Kopírovat slevu</NavLink>
            )}

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>

            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>

            {this.props.match.params.id && (
              <button
                type={"button"}
                className={"btn btn-danger float-right"}
                onClick={this.handleDelete.bind(this)}
              >Smazat</button>
            )}
          </form>
        </div>
      </div>
    )
  }

  getData() {
    API.get("/product-discount-types")
      .then(({data}) => {
        this.setState({
          _types: data.objects,
        })
      })

    API.get("/productTree?limit=10")
      .then(this.getProductCategories.bind(this))
  }

  getProductCategories(res) {
    let tree = res.data.objects
    let categories = []

    let getCategoriesWithProducts = node => {
      // categories with some products
      if (node.category_id && node.children && node.children.some(child => child.product_id)) {
        categories.push({
          value: node.category_id,
          label: node.category.name,
          products: node.children.filter(child => child.product_id),
        })
      }

      // recursively call only for categories
      if (node.children) {
        node.children
          .filter(child => child.category_id)
          .forEach(getCategoriesWithProducts)
      }
    }

    getCategoriesWithProducts(tree[0])

    this.setState({
      _categories: categories,
    })
  }

  getDiscountDetail() {
    API.get(`/product-discount-groups/${this.props.match.params.id}`)
      .then(({data}) => {
        let products = List()

        data.product_discount.forEach(discount => {
          products = products.push({
            product_id: discount.product_id,
            value: discount.value,
            type: discount.type,
            _name: getProductNameByCountry(discount.product),
          })
        })

        this.setState({
          valid_from: data.valid_from,
          valid_to: data.valid_to,
          admin_valid_to: data.admin_valid_to || this.state.admin_valid_to,
          product_discount: products,
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()

    let data = {
      valid_from: this.state.valid_from,
      valid_to: this.state.valid_to,
      admin_valid_to: this.state.admin_valid_to,
    }

    if (this.state._selectedCategory) {
      data.product_discount = this.state._selectedCategory.products.map(product => {
        return {
          product_id: product.product_id,
          value: this.state._selectedCategoryDiscountValue,
          type: this.state._selectedCategoryDiscountType,
        }
      })
    } else {
      data.product_discount = this.state.product_discount.toArray()
    }

    if (this.props.match.params.id) {
      API.put(`/product-discount-groups/${this.props.match.params.id}`, data)
        .then(this.handleCancel.bind(this))
    } else {
      API.post("/product-discount-groups", data)
        .then(this.handleCancel.bind(this))
    }
  }

  handleCancel() {
    this.props.history.push("/products/discount")
  }

  handleValidFromChange(date) {
    this.setState({
      valid_from: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleValidToChange(date) {
    this.setState({
      valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleAdminValidToChange(date) {
    this.setState({
      admin_valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}&is_price_list_visible=1&sort=code`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: getProductNameByCountry(item, COUNTRY.CZECH_REPUBLIC, "|"),
        }))
        cb(data.objects)
      })
  }

  handleProductSearchSelect(option) {
    this.setState({
      product_discount: this.state.product_discount.push({
        product_id: option.id,
        value: 0,
        type: 1,
        _name: getProductNameByCountry(option),
      }),
      _selectedCategory: null,
    })
  }

  handleCategorySelect(option) {
    this.setState({
      _selectedCategory: option,
      product_discount: List(),
    })
  }

  handleProductTypeChange(value, productIndex) {
    let product = this.state.product_discount.get(productIndex)
    product.type = value

    this.setState({
      product_discount: this.state.product_discount.update(productIndex, () => product)
    })
  }

  handleProductValueChange(value, productIndex) {
    let product = this.state.product_discount.get(productIndex)
    product.value = value

    this.setState({
      product_discount: this.state.product_discount.update(productIndex, () => product)
    })
  }

  handleProductDelete(productId) {
    let productIndex = this.state.product_discount.findIndex(discount => discount.product_id === productId)

    if (productIndex > -1) {
      this.setState({
        product_discount: this.state.product_discount.delete(productIndex)
      })
    }
  }

  handleDelete() {
    if (window.confirm("Opravdu chceš smazat?")) {
      API.delete(`/product-discount-groups/${this.props.match.params.id}`)
        .then(this.handleCancel.bind(this))
    }
  }

  handleCategoryTypeChange(e) {
    this.setState({
      _selectedCategoryDiscountType: parseInt(e.target.value, 10),
    })
  }

  handleCategoryValueChange(e) {
    this.setState({
      _selectedCategoryDiscountValue: parseInt(e.target.value, 10),
    })
  }

  handleCategoryDelete() {
    this.setState({
      _selectedCategory: null,
      _selectedCategoryDiscountType: 1,
      _selectedCategoryDiscountValue: 0,
    })
  }

  isActive() {
    return moment(new Date()).isBetween(this.state.valid_from, this.state.valid_to)
  }
}
