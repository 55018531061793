import React from "react"
import styles from "./styles.module.css"

const Spinner = props => (
  <div
    className={styles.spinner}
    style={props.styles}
  />
)

export default Spinner
