import React from "react"
import {components} from "react-select"
import {v4 as uuid} from "uuid"

const ReactSelectTableOption = innerProps => {
  let children = innerProps.children.split("|")

  return (
    <components.Option
      {...innerProps}
    >
      <div
        ref={innerProps.innerRef}
      >
        {children.map(child => {
          return (
            <span
              key={`child-${uuid()}`}
              className={"d-inline-block"}
              style={{
                width: `${100 / children.length}%`
              }}
            >{child}</span>
          )
        })}

      </div>
    </components.Option>
  )
}

export default ReactSelectTableOption
