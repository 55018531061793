import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"

export default class CountryForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      code: "",
      handling_fee_limit: "",
      handling_fee_const: "",
      round_decimal_places: "",
      currency: "",
      phone_code: "",
      exchange_rate: "",
    }

    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getCountry()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <NavLink
            to={"/enums/countries/add"}
            className={"btn btn-primary mr-3"}
          >Přidat zemi</NavLink>
        </div>

        <div
          className={"col-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Název:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Název"}
                required={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state.name}
                minLength={5}
                maxLength={30}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Kód:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Kód"}
                required={true}
                onChange={this.handleCodeChange.bind(this)}
                value={this.state.code}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Limit manipulačního poplatku:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Limit manipulačního poplatku"}
                required={true}
                onChange={this.handleHandlingFeeLimitChange.bind(this)}
                value={this.state.handling_fee_limit}
                min={0}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Konstanta manipulačního poplatku:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Konstanta manipulačního poplatku"}
                required={true}
                onChange={this.handleHandlingFeeConstChange.bind(this)}
                value={this.state.handling_fee_const}
                min={0}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Počet desetinných míst:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Počet desetinných míst"}
                required={true}
                onChange={this.handleRoundDecimalPlacesChange.bind(this)}
                value={this.state.round_decimal_places}
                min={0}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Měna:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Měna"}
                required={true}
                onChange={this.handleCurrencyChange.bind(this)}
                value={this.state.currency}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Předvolba:</label>
              <div
                className={"input-group mb-2"}
              >
                <div
                  className={"input-group-prepend"}
                >
                  <div
                    className={"input-group-text"}
                  >+
                  </div>
                </div>
                <input
                  type={"number"}
                  className={"form-control"}
                  placeholder={"Předvolba"}
                  required={true}
                  onChange={this.handlePhoneCodeChange.bind(this)}
                  value={this.state.phone_code}
                />
              </div>
            </div>

            <div
              className={"form-group"}
            >
              <label>Koeficient přepočtu do CZK:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Koeficient přepočtu do CZK"}
                required={true}
                onChange={this.handleExchangeRateChange.bind(this)}
                value={this.state.exchange_rate}
              />
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    })
  }

  handleCodeChange(e) {
    this.setState({
      code: e.target.value,
    })
  }

  handleHandlingFeeLimitChange(e) {
    let value = parseInt(e.target.value, 10)

    if (!isNaN(value)) {
      this.setState({
        handling_fee_limit: value,
      })
    }
  }

  handleHandlingFeeConstChange(e) {
    let value = parseInt(e.target.value, 10)

    if (!isNaN(value)) {
      this.setState({
        handling_fee_const: value,
      })
    }
  }

  handleRoundDecimalPlacesChange(e) {
    let value = parseInt(e.target.value, 10)

    if (!isNaN(value)) {
      this.setState({
        round_decimal_places: value,
      })
    }
  }

  handleCurrencyChange(e) {
    this.setState({
      currency: e.target.value,
    })
  }

  handlePhoneCodeChange(e) {
    let value = parseInt(e.target.value, 10)

    if (!isNaN(value)) {
      this.setState({
        phone_code: value,
      })
    }
  }

  handleExchangeRateChange(e) {
    let value = parseFloat(e.target.value)

    if (!isNaN(value)) {
      this.setState({
        exchange_rate: value,
      })
    }
  }

  addCountry() {
    API.post("/countries", this.state)
      .then(() => {
        this.props.history.push("/enums/countries")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/countries")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateCountry()
    } else {
      this.addCountry()
    }
  }

  getCountry() {
    API.get(`/countries/${this.id}`)
      .then((res) => {
        this.setState(res.data)
      })
  }

  updateCountry() {
    API.put(`/countries/${this.id}`, this.state)
      .then(() => {
        this.props.history.push("/enums/countries")
      })
  }
}
