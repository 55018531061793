import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import moment from "moment"
import {NavLink} from "react-router-dom"
import Select from "react-select"
import axios from "axios"
import API from "../../API"
import {getParams, serializeParams} from "../../Utils"
import RewardListFilter from "../RewardListFilter"
import ExportButton from "../../Base/ExportButton"
import rentExportColumn from "../../fixtures/rentExportColumn"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import Grid from "../../Base/Grid"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import RentInvoiceList from "./RentInvoiceList"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"

export default class RentList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()

    this.state = {
      data: [],
      meta: {},
      total: 0,
      params: Object.assign({
        offset: 0,
        limit: 0,
        date: moment().subtract(1, "months").format("YYYY/MM"),
        sort: "-customer_points__renta",
        has: "customer_points__renta|customer_points__renta_perla|customer_points__renta_rubin",
      }, params),
      customerTypes: [],
      selectedRows: [],
      countries: [],
      isInvoiceListVisible: false,
    }

    this.defaultSorted = {
      dataField: "customer_points__renta",
      order: "desc",
    }

    this.defaultParams = ["has"]
  }

  render() {
    // this component should not be visible from any url, so I think this is the right way
    if (this.state.isInvoiceListVisible) {
      return (
        <RentInvoiceList
          date={this.state.params.date}
          customerIds={this.state.selectedRows}
          isInvoiceVisibleHandler={this.handleIsInvoiceListVisible.bind(this)}
        />
      )
    }

    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-6 mb-3"}
        >
          <label>Země:</label>
          <Select
            value={this.state.countries.filter(country => this.state.params.country_id.indexOf(country.id) > -1)}
            placeholder={"Země"}
            isMulti={true}
            options={this.state.countries}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCountryChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-6 mb-3 mt-lg-4 text-right"}
        >
          <button
            className={"btn btn-primary mr-3"}
            onClick={() => this.handleIsInvoiceListVisible(true)}
            disabled={this.state.selectedRows.length === 0}
          >Vygenerovat faktury
          </button>

          <ExportButton
            exportName={"rent"}
            fileName={"Renty"}
            params={this.state.params}
            columns={rentExportColumn}
            apiUrl={`/commision/${this.state.params.date}`}
          />
        </div>

        <div
          className={"col-lg-6 mb-3"}
        >
          <RewardListFilter
            date={this.state.params.date}
            dateChangeHandler={this.handleDateChange.bind(this)}
            getDataHandler={this.getData.bind(this)}
          />
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              ref={ref => this.gridTableRef = ref}
              columns={this.getColumns()}
              data={this.state.data}
              onTableChange={this.handleTableChange.bind(this)}
              noDataIndication={"Nic nenalezeno"}
              defaultSorted={[this.defaultSorted]}
              sort={this.sort || {}}
              selectRow={{
                mode: "checkbox",
                selected: this.state.selectedRows,
                onSelect: this.handleRowSelect.bind(this),
                onSelectAll: this.handleRowSelectAll.bind(this),
              }}
            />
          </ScrollableTable>
        </div>
      </div>
    )
  }

  // overridden
  _getData() {
    axios.all([
      this.getCustomerTypes(),
      this.getCountries(),
    ]).then(axios.spread((customerTypesRes, countriesRes) => {
      let countries = []

      countries = countriesRes.data.objects.map(country => Object.assign({}, country, {
        value: country.id,
        label: country.code,
      }))

      this.setState({
        customerTypes: customerTypesRes.data.objects,
        countries: countries,
        params: Object.assign({}, this.state.params, {
          country_id: `=${countries.map(country => country.id).join("|")}`,
        })
      }, this.getData)
    }))
  }

  // overridden
  getData() {
    this.setState({
      isLoading: true,
    })

    let params = Object.assign({}, this.state.params)
    let serializedParams = serializeParams(params)

    window.history.replaceState({}, "", `/#/reports/commissions/rents${serializedParams}`)

    delete params.date
    serializedParams = serializeParams(params)

    API.get(`/commision/${this.state.params.date}${serializedParams}`)
      .then(({data}) => {
        this.setState({
          data: data.objects,
          meta: data.meta,
          total: data.meta.total_count,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  // overridden
  saveSettings() {
    // nothing to do
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  handleDateChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {date})
    })
  }

  getColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state.customerTypes,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Příjmení",
      dataField: "surname",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno",
      dataField: "name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Fakt. adresa - jméno a příjmení",
      dataField: "billing_address_customer_name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Fakt. adresa - fakt. jméno a příjmení",
      dataField: "billing_address_billing_name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Země",
      dataField: "country_id",
      formatter: this.formatCountry.bind(this),
      formatExtraData: this.state.countries,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Renta",
      dataField: "customer_points__renta",
      sort: true,
      formatter: this.formatRent.bind(this),
      footer: "renta",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta,
    }, {
      text: "Renta s DPH",
      dataField: "customer_points__renta_vat",
      sort: true,
      formatter: this.formatRentVAT.bind(this),
      footer: "renta_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta_vat,
    }, {
      text: "Perla",
      dataField: "customer_points__renta_perla",
      sort: true,
      formatter: this.formatRentPearl.bind(this),
      footer: "renta_perla",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta_perla,
    }, {
      text: "Perla s DPH",
      dataField: "customer_points__renta_perla_vat",
      sort: true,
      formatter: this.formatRentPearlVAT.bind(this),
      footer: "renta_perla_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta_perla_vat,
    }, {
      text: "Rubín",
      dataField: "customer_points__renta_rubin",
      sort: true,
      formatter: this.formatRentRuby.bind(this),
      footer: "renta_rubin",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta_rubin,
    }, {
      text: "Rubín s DPH",
      dataField: "customer_points__renta_rubin_vat",
      sort: true,
      formatter: this.formatRentRubyVAT.bind(this),
      footer: "renta_rubin_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].renta_rubin_vat,
    }]
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    let type = formatExtraData.find(customerType => customerType.id === cell)

    if (type) {
      return type.name
    }

    return ""
  }

  formatCountry(cell, row, rowIndex, formatExtraData) {
    let country = this.state.countries.find(country => country.id === cell)

    if (country) {
      return country.name
    }

    return ""
  }

  formatRent(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta
    }

    return ""
  }

  formatRentVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta_vat
    }

    return ""
  }

  formatRentPearl(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta_perla
    }

    return ""
  }

  formatRentPearlVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta_perla_vat
    }

    return ""
  }

  formatRentRuby(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta_rubin
    }

    return ""
  }

  formatRentRubyVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].renta_rubin_vat
    }

    return ""
  }

  handleCountryChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        country_id: `=${(options || [])
          .map(country => country.id)
          .join("|")}`
      })
    })
  }

  handleRowSelect = (row, isSelected) => {
    this.setState({
      selectedRows: isSelected
        ? [...this.state.selectedRows, row.id]
        : this.state.selectedRows.filter(id => id !== row.id)
    })
  }

  handleRowSelectAll = (isSelected, rows) => {
    this.setState({
      selectedRows: isSelected
        ? rows
          .map(row => row.id)
        : []
    })
  }

  handleIsInvoiceListVisible(isVisible) {
    this.setState({
      isInvoiceListVisible: isVisible,
    })
  }
}
