import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import API from "../API"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"

export default class RewardListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      process: {},
    }
    this.updateInterval = null
  }

  componentDidMount() {
    this.getRecalculateProcess()
  }

  componentWillUnmount() {
    this.clearUpdateInterval()
  }

  render() {
    return (
      <>
        <label>Datum:</label>
        <div
          className={"d-inline-block menu-separator"}
        >
          <div
            className={"input-group"}
          >
            <div
              className={"input-group-prepend"}
            >
              <DatePicker
                showMonthYearPicker={true}
                locale={"cs"}
                className={"form-control"}
                selected={moment(this.props.date, "YYYY/MM").toDate()}
                onChange={this.handleDateChange.bind(this)}
                dateFormat={"MM/yyyy"}
              />
            </div>
            <button
              className={"btn btn-primary"}
              onClick={this.props.getDataHandler}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <button
          className={"btn btn-primary"}
          onClick={this.handleRecalculateClick.bind(this)}
          disabled={this.state.process.status === "running"}
        >
          {this.state.process.status === "running"
            ? <>Přepočítávám <SpinnerInButton/></>
            : "Přepočítat uzavřený měsíc"
          }
        </button>
      </>
    )
  }

  clearUpdateInterval() {
    clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  setUpdateInterval() {
    this.clearUpdateInterval()
    this.updateInterval = setInterval(this.getRecalculateProcess.bind(this), 10000) // 10s
  }

  handleDateChange(date) {
    this.props.dateChangeHandler(moment(date).format("YYYY/MM"))
  }

  handleRecalculateClick() {
    API.get(`/commision/calculate/${this.props.date}`)
      .then(this.getRecalculateProcess.bind(this)) // this call should ends before 10s
    setTimeout(this.getRecalculateProcess.bind(this), 500)
  }

  getRecalculateProcess() {
    API.get("/process/status/commision_monthly")
      .then(({data}) => {
        if (this.updateInterval === null && data.status === "running") {
          this.setUpdateInterval()
        }

        if (this.updateInterval !== null && data.status !== "running") {
          this.clearUpdateInterval()
          this.props.dateChangeHandler(this.props.date)
          this.props.getDataHandler()
        }

        this.setState({
          process: data
        })
      })
  }
}
