import React from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import moment from "moment"
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async"
import Grid from "../../Base/Grid"
import API from "../../API"
import {getParams} from "../../Utils"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import GridDateRangeFilter from "../../Base/GridDateRangeFilter"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ORDER_STATUS from "../../Base/Enum/OrderStatus"
import GridDateFilter from "../../Base/GridDateFilter"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import formatCustomerName from "../../Base/formatCustomerName"
import ExportButton from "../../Base/ExportButton"
import sponsorOrdersExportColumn from "../../fixtures/sponsorOrdersExportColumn.json"

export default class SponsorOrdersList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-created_at",
        sale__status: "!created_open",
        customer_tree_date: moment().format("YYYY/MM"),
        customer_tree_parent: "",
      }, params),
      _paymentMethods: {},
      _columns: [],
      _countries: [],
      _saleStates: [],
      _customer: {},
    }

    this.appUrl = "/reports/sets/sponsor-orders"
    this.apiUrl = "/orders"
    this.filters = {}
    this.defaultParams = ["sale__status", "customer_tree_date", "customer_tree_parent"]
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }

    this.statusFilterOptions = {}
    Object.keys(ORDER_STATUS).forEach(key => {
      this.statusFilterOptions[`=${key}`] = ORDER_STATUS[key]
    })
  }

  componentDidMount() {
    super.componentDidMount()

    if (this.state.params.customer_tree_parent) {
      this.getCustomer()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-3"}
        >
          <label>Stav zákaznického stromu v období:</label>
          <DatePicker
            showMonthYearPicker={true}
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.customer_tree_date).toDate()}
            onChange={this.handleCustomerTreeDateChange.bind(this)}
            dateFormat={"MM/yyyy"}
          />
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Zákazník:</label>
          <div
            className={"form-group select-required"}
          >
            <AsyncSelect
              value={this.state._customer}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerNameChange.bind(this))}
              onChange={this.handleCustomerNameSelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              placeholder={"Zákazník"}
              required={true}
              autoFocus={true}
              styles={ReactSelectCustomStyles}
            />
          </div>
        </div>

        <div
          className={"col-lg-2 mt-lg-4"}
        >
          <button
            onClick={this.getData.bind(this)}
            className={"btn btn-primary"}
            disabled={!this.state.params.customer_tree_parent}
          >Zobrazit přehled
          </button>
        </div>

        <div
          className={"col-5 mb-3 mt-lg-4 text-right"}
        >
          <ExportButton
            exportName={"customers"}
            fileName={"Zákazníci"}
            params={this.state.params}
            columns={sponsorOrdersExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                rowClasses={this.handleRowClass.bind(this)}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getPaymentMethods(),
      this.getCountries(),
      this.getSaleStates(),
    ]).then(axios.spread((paymentMethodsRes, countriesRes, saleStatesRes) => {
      let paymentMethods = {}

      paymentMethodsRes.data.objects.forEach(paymentMethod => {
        paymentMethods[paymentMethod.id] = paymentMethod.name
      })

      this.setState({
        _paymentMethods: paymentMethods,
        _countries: countriesRes.data.objects,
        _saleStates: saleStatesRes.data.objects,
      })
    }))
  }

  // overridden
  // disable 30s auto update
  updateIntervalCb() {}

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getCustomer() {
    API.get(`/customers/${this.state.params.customer_tree_parent}`)
      .then(({data}) => {
        data.value = data.id
        data.label = formatCustomerName(data)

        this.setState({
          _customer: data,
        })
      })
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    if (ORDER_STATUS.hasOwnProperty(cell)) {
      return ORDER_STATUS[cell]
    }

    return ""
  }

  formatSaleStatus(cell, row, rowIndex, formatExtraData) {
    if (row.sale) {
      let state = this.state._saleStates.find(saleStatus => saleStatus.id === row.sale.status)

      if (state) {
        return (
          <span
            className={state.id === "open" ? "text-danger" : ""}
          >{state.name}</span>
        )
      }
    }

    return ""
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        className={"nowrap"}
      >
        <NavLink
          to={`/sales/${row.sale_id}/detail/`}
        >{row.sale.sale_no}</NavLink>

        {!this.state.params.sale__sale_no && (
          <button
            onClick={() => this.filters.sale__sale_no(row.sale.sale_no)}
            title={"Vyfiltrovat zakázku"}
            className={"btn btn-link"}
          >
            <i
              className={"fas fa-tasks fa-lg ml-4"}
            />
          </button>
        )}
      </div>
    )
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <NavLink
          to={`/sales/${row.sale_id}/detail/${row.id}`}
        >{cell}</NavLink>

        {(row.customer.note.some(note => note.is_warn) || row.note.some(note => note.is_warn)) && (
          <span
            className={"fas fa-exclamation-triangle ml-1 text-danger toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.customer.note.filter(note => note.is_warn).map(note => (
                <div
                  key={note.id}
                >{note.value}</div>
              ))}

              {row.note.filter(note => note.is_warn).map(note => (
                <div
                  key={note.id}
                >{note.value}</div>
              ))}
            </span>
          </span>
        )}

        {row.note.some(note => note.is_pub) && (
          <span
            className={"fas fa-user ml-1 text-warning toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.note
                .filter(note => note.is_pub)
                .map(note => (
                  <div
                    key={note.id}
                  >{note.value}</div>
                ))}
            </span>
          </span>
        )}

        {row.order_product.some(products => products.is_advert) && (
          <span
            className={"fas fa-gift ml-1"}
            title={"Některé produkty jsou na propagační účely"}
          />
        )}
      </>
    )
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.customer_no}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.surname : ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.name : ""
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY HH:mm:ss")
    }

    return ""
  }

  formatPaidDate(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatTotalPriceVat(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  getDefaultColumns() {
    return [{
      text: "Stav objednávky",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      sort: true,
      visible: true,
      filter: selectFilter({
        options: this.statusFilterOptions,
        placeholder: "Stav objednávky",
        defaultValue: this.state.params.status || "",
        getFilter: filter => this.filters.status = filter,
      }),
      headerClasses: () => this.state.params.status ? "bg-green" : "",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Stav zakázky",
      dataField: "sale__status",
      formatter: this.formatSaleStatus.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state._paymentMethods,
      visible: true,
      filter: selectFilter({
        options: this.state._paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. zakázky",
      dataField: "sale__sale_no",
      classes: "column-sticky",
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale__sale_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale__sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale__sale_no ? "bg-green" : ""}`,
      formatter: this.formatSaleNo.bind(this),
      sort: true,
      visible: true,
      alwaysVisible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. faktury",
      dataField: "invoice_no",
      classes: "column-sticky column-sticky_1",
      filter: textFilter({
        placeholder: "Č. faktur",
        defaultValue: this.state.params.invoice_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.invoice_no = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.invoice_no ? "bg-green" : ""}`,
      formatter: this.formatOrder.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // z-index of customer surname column is 1. There is plenty of space to
          // prevent customer surname overlaps order notes
          zIndex: 100 - rowIndex
        }
      },
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. zák.",
      dataField: "customer__customer_no",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer__customer_no ? `${this.state.params.customer__customer_no}`.replace("^", "") : "",
        getFilter: filter => this.filters.customer__customer_no = filter,
      }),
      headerClasses: () => this.state.params.customer__customer_no ? "bg-green" : "",
      formatter: this.formatCustomerNumber.bind(this),
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      classes: "column-sticky column-sticky_2",
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_2 ${this.state.params.customer__surname ? "bg-green" : ""}`,
      formatter: this.formatCustomerSurname.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          zIndex: 1 // must be smaller than invoice number column to prevent column overlaps order notes
        }
      },
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      filter: textFilter({
        placeholder: "Jméno zák.",
        defaultValue: this.state.params.customer__name || "",
        getFilter: filter => this.filters.customer__name = filter,
      }),
      headerClasses: () => this.state.params.customer__name ? "bg-green" : "",
      formatter: this.formatCustomerName.bind(this),
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "D. vytvoření",
      dataField: "created_at",
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.created_at = () => onFilter("")
        this.filters["created_at-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.created_at}
            defaultValueTo={this.state.params["created_at-2"]}
          />
        )
      },
      headerClasses: () => this.state.params.created_at ? "bg-green" : "",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "D. uhrazení",
      dataField: "paid_date",
      filter: customFilter({
        type: "DATE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.paid_date = () => onFilter("")
        return (
          <GridDateFilter
            onFilter={onFilter}
            column={column}
            placeholder={"D. uhrazení"}
            defaultValue={this.state.params.paid_date || ""}
          />
        )
      },
      headerClasses: () => this.state.params.paid_date ? "bg-green" : "",
      formatter: this.formatPaidDate.bind(this),
      visible: true,
      sort: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      filter: textFilter({
        placeholder: "Celková cena",
        defaultValue: this.state.params.total_price_vat || "",
        getFilter: filter => this.filters.total_price_vat = filter,
      }),
      headerClasses: () => this.state.params.total_price_vat ? "bg-green" : "",
      formatter: this.formatTotalPriceVat.bind(this),
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total_price_vat || 0,
    }, {
      text: "Body",
      dataField: "bonus_points",
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.bonus_points || 0,
    }, {
      text: "Splatnost",
      dataField: "due_days",
      filter: textFilter({
        placeholder: "Splatnost",
        defaultValue: this.state.params.due_days || "",
        getFilter: filter => this.filters.due_days = filter,
      }),
      headerClasses: () => this.state.params.due_days ? "bg-green" : "",
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    },]
  }

  handleRowClass(row, rowIndex) {
    let className = ""

    if (this.lastSaleId && this.lastSaleId !== row.sale_id) {
      this.isWhite = !this.isWhite
      className += " border-top-orange-2"
    }

    className += this.isWhite ? " bg-white" : " bg-grey"

    this.lastSaleId = row.sale_id

    return className
  }

  handleCustomerTreeDateChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        customer_tree_date: moment(date).format("YYYY-MM"),
      })
    })
  }

  handleCustomerNameChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: formatCustomerName(item),
        }))
        cb(data.objects)
      })
  }

  handleCustomerNameSelect(option) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        customer_tree_parent: option.id,
      }),
      _customer: option,
    })
  }
}
