import React from "react"
import {NavLink} from "react-router-dom"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"

export default (props) => (
  <div
    className={`col-12 ${props.isTop ? "mb-3" : "py-2 menu-bottom bg-white"}`}
    style={{
      bottom: props.bottomMenuPos
    }}
  >
    <div
      className={"row"}
    >
      <div
        className={"col-lg-5"}
      >
        <span
          className={"mr-2 text-secondary"}
        >
          {props.authenticatedCustomerTypeId === CUSTOMER_TYPE.RD ||
          props.authenticatedCustomerTypeId === CUSTOMER_TYPE.RZ
            ? "Detail objednávky"
            : "Detail zakázky"
          }
        </span>

        {props.isDetail && (
          <NavLink
            to={"/my-sales/add"}
            className={"btn btn-sm btn-primary mr-2"}
          >Nová objednávka</NavLink>
        )}
      </div>

      <div
        className={"col-lg-6 text-right"}
      >
        <button
          className={`btn btn-sm btn-warning ${props.isSaveable ? "mr-2" : ""}`}
          onClick={props.formCancelHandler}
          type={"button"}
        >Zrušit
        </button>

        {props.isPromo4Plus1MenuVisible && (
          <button
            className={"btn btn-sm btn-primary mr-2"}
            type={"button"}
            onClick={props.isPromo4Plus1OpenHandler}
            disabled={!props.isPaymentAndDeliverySelected}
          >Zobrazit náhled akce 4+1</button>
        )}

        {!props.isPromo4Plus1MenuVisible && (
          <>
            {props.isSaveable && (
              <button
                className={"btn btn-sm btn-primary mr-2"}
                type={"submit"}
                onClick={props.saveSaleAsOpenHandler}
              >Uložit jako rozpracovanou</button>
            )}

            {props.isSaveable && (
              <button
                className={`btn btn-sm btn-success ${props.isDeletable ? "mr-2" : ""}`}
                type={"submit"}
              >Odeslat</button>
            )}
          </>
        )}

        {props.isDeletable && (
          <button
            className={"btn btn-sm btn-danger"}
            onClick={props.deleteHandler}
            type={"button"}
          >Smazat</button>
        )}
      </div>
    </div>
  </div>
)
