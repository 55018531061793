import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import moment from "moment"
import DatePicker from "react-datepicker"
import axios from "axios"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {NavLink} from "react-router-dom"
import API from "../../API"
import Grid from "../../Base/Grid"
import {getParams, serializeParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import COUNTRY from "../../Base/Enum/Countries"
import SpinnerInButton from "../../Base/SpinnerInButton/SpinnerInButton"

export default class ProductsList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()

    this.state = {
      data: [],
      isLoading: false,
      isExportLoading: false,
      _columns: [],
      countryOptions: [],
      products: [],
      params: Object.assign({
        date_start: moment().subtract(1, "days").format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        country_id: "",
        product_id: "",
      }, params),
    }

    this.defaultParams = ["date_start", "date_end", "country_id", "products"]
    this.filters = {}
    this.apiUrl = "/reports/order-products"
    this.appUrl = "/reports/sets/products"
  }


  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_start, "YYYY-MM-DD").toDate()}
            onChange={this.handleDateStartChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_end, "YYYY-MM-DD").toDate()}
            onChange={this.handleDateEndChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-5 mb-3"}
        >
          <label>Země:</label>
          <Select
            value={this.state.countryOptions.filter(option => this.state.params.country_id.indexOf(option.value) > -1)}
            placeholder={"Země"}
            isMulti={true}
            options={this.state.countryOptions}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCountryChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-3 mt-lg-4 text-right"}
        >
          <button
            type={"button"}
            className={"btn btn-secondary border-0 py-2"}
            disabled={this.state.isExportLoading}
            onClick={this.handleExportClick.bind(this)}
          >Stáhnout .xls {this.state.isExportLoading && <SpinnerInButton/>}</button>
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Počítat z:</label>
          <div
            className={"form-check pl-0"}
          >
            <label>
              <input
                type={"radio"}
                name={"status"}
                value={""}
                checked={!this.state.params.status}
                onChange={this.handleStatusChange.bind(this)}
              />
              Uhrazených zakázek
            </label>
          </div>

          <div
            className={"form-check pl-0"}
          >
            <label>
              <input
                type={"radio"}
                name={"status"}
                value={"unpaid"}
                checked={this.state.params.status === "unpaid"}
                onChange={this.handleStatusChange.bind(this)}
              />
              Pořízených zakázek
            </label>
          </div>
        </div>

        <div
          className={"form-group col-lg-8"}
        >
          <label>Produkty:</label>
          <AsyncSelect
            value={this.state.products}
            isMulti={true}
            loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
            onChange={this.handleProductSearchSelect.bind(this)}
            noOptionsMessage={() => "Nic nenalezeno"}
            loadingMessage={() => "Načítám"}
            placeholder={"Vyhledat produkt"}
            required={true}
            styles={ReactSelectCustomStyles}
          />
        </div>

        <div
          className={"col-lg-2 mb-3 mt-lg-4"}
        >
          <button
            className={"btn btn-primary"}
            onClick={this.getData.bind(this)}
          >Zobrazit přehled
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  // overridden
  _getData() {
    axios.all([
      this.getCountries(),
      this.getProducts()
    ]).then(axios.spread((countriesRes, productsRes) => {
      let countries = []

      countriesRes.data.objects.forEach(country => {
        countries.push({
          value: country.id,
          label: country.code,
        })
      })

      let countryId = this.state.params.country_id
      if (!countryId) {
        countryId = `=${countries
          .map(country => country.value)
          .join("|")}`
      }

      let products = productsRes.data.objects.map(product => Object.assign(product, {
        value: product.id,
        label: getProductNameByCountry(product),
      }))

      this.setState({
        countryOptions: countries,
        products: products,
        params: Object.assign({}, this.state.params, {
          country_id: countryId,
        })
      }, this.getData)
    }))
  }

  // overridden
  // disable 30s auto update
  updateIntervalCb() {}

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getProducts() {
    if (this.state.params.product_id !== "") {
      return API.get(`/products?id==${this.state.params.product_id}`)
    }

    return Promise.resolve({data: {objects: []}})
  }

  handleExportClick() {
    this.setState({
      isExportLoading: true,
    }, () => {
      let params = Object.assign({}, this.state.params, {
        csv: 1,
      })

      params = serializeParams(params)

      API.get(`${this.apiUrl}${params}`)
        .then(({data}) => {
          window.open(data.url)
        })
        .finally(() => {
          this.setState({
            isExportLoading: false,
          })
        })
    })
  }

  handleStatusChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        status: e.target.value,
      })
    })
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleCountryChange(options) {
    let countries = (options || [])
      .map(option => option.value)
      .join("|")

    this.setState({
      params: Object.assign({}, this.state.params, {
        country_id: `=${countries}`
      })
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(product => Object.assign(product, {
          value: product.id,
          label: getProductNameByCountry(product),
        }))
        cb(data)
      })
  }

  handleProductSearchSelect(options) {
    this.setState({
      products: options || [],
      params: Object.assign({}, this.state.params, {
        product_id: (options || [])
          .map(product => product.id)
          .join("|")
      })
    })
  }

  getDefaultColumns() {
    return [{
      text: "Č. výrobku",
      dataField: "product__code",
      formatter: this.formatProductCode.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Název výrobku",
      dataField: "product_name__name",
      formatter: this.formatProductName.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "DPH",
      dataField: "vat_rate",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Počet kusů celkem",
      dataField: "report__total__quantity",
      formatter: this.formatReportTotalQuantity.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total.quantity || 0,
    }, {
      text: "Cena celkem",
      dataField: "report__total__price",
      formatter: this.formatReportTotalPrice.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total.price || 0,
    }, {
      text: "Cena celkem s DPH",
      dataField: "report__total__price_vat",
      formatter: this.formatReportTotalPriceVAT.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total.price_vat || 0,
    }, {
      text: "Počet kusů s nulovou cenou",
      dataField: "report__free__quantity",
      formatter: this.formatReportFreeQuantity.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.free.quantity || 0,
    }, {
      text: "Cena celkem",
      dataField: "report__free__price",
      formatter: this.formatReportFreePrice.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.free.price || 0,
    }, {
      text: "Cena celkem s DPH",
      dataField: "report__free__price_vat",
      formatter: this.formatReportFreePriceVAT.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.free.price_vat || 0,
    }, {
      text: "Počet kusů uhrazených",
      dataField: "report__paid__quantity",
      formatter: this.formatReportPaidQuantity.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.paid.quantity || 0,
    }, {
      text: "Cena celkem",
      dataField: "report__paid__price",
      formatter: this.formatReportPaidPrice.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.paid.price || 0,
    }, {
      text: "Cena celkem s DPH",
      dataField: "report__paid__price_vat",
      formatter: this.formatReportPaidPriceVAT.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.paid.price_vat || 0,
    },]
  }

  formatPrice(price) {
    return price.toFixed(2)
  }

  formatProductCode(cell, row, rowIndex, formatExtraData) {
    if (row.product) {
      return (
        <NavLink
          to={`/products/${row.product_id}/detail`}
        >{row.product.code}</NavLink>
      )
    }

    return ""
  }

  formatProductName(cell, row, rowIndex, formatExtraData) {
    if (row.product) {
      return getProductNameByCountry(row.product, COUNTRY.CZECH_REPUBLIC, null)
    }

    return ""
  }

  formatReportTotalQuantity(cell, row, rowIndex, formatExtraData) {
    return row.report.total.quantity
  }

  formatReportTotalPrice(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.total.price)
  }

  formatReportTotalPriceVAT(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.total.price_vat)
  }

  formatReportFreeQuantity(cell, row, rowIndex, formatExtraData) {
    return row.report.free.quantity
  }

  formatReportFreePrice(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.free.price)
  }

  formatReportFreePriceVAT(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.free.price_vat)
  }

  formatReportPaidQuantity(cell, row, rowIndex, formatExtraData) {
    return row.report.paid.quantity
  }

  formatReportPaidPrice(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.paid.price)
  }

  formatReportPaidPriceVAT(cell, row, rowIndex, formatExtraData) {
    return this.formatPrice(row.report.paid.price_vat)
  }
}
