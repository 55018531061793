import React, {Component} from "react"

export default class Collapsible extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.open,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        isOpen: true,
      })
    }
  }

  render() {
    return (
      <>
        <button
          className={`btn btn-sm w-100 text-left border-top toggle-collapsible
            ${this.state.isOpen ? " open" : ""}
            ${this.props.highlight ? " bg-danger text-white" : ""}
          `}
          onClick={this.handleOpenClick.bind(this)}
          type={"button"}
        >
          <i
            className={`fas fa-angle-${this.state.isOpen ? "down" : "right"} pr-2`}
          />

          <span
            className={this.props.boldTitle ? "font-weight-bold" : ""}
          >{this.props.name}</span>
        </button>

        <div
          className={`collapsible ${this.state.isOpen ? "open" : ""}`}
        >{this.props.children}</div>
      </>
    )
  }

  handleOpenClick() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
}
