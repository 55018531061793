import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import DatePicker from "react-datepicker"
import Grid from "../../Base/Grid"
import {getParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"

export default class UsedPackagesList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      meta: {},
      total: 0,
      isLoading: false,
      customerTypes: [],
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        date_start: moment().format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
      }, params),
      _columns: [],
    }

    this.apiUrl = "/reports/used-package-types"
    this.appUrl = "/reports/sets/used-packages"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"form-group col-lg-2"}
        >
          <label>Datum od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_start).toDate()}
            onChange={this.handleDateStartChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"form-group col-lg-2"}
        >
          <label>Datum do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_end).toDate()}
            onChange={this.handleDateEndChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-group mt-lg-4"}
          >
            <button
              className={"btn btn-primary"}
              onClick={this.getData.bind(this)}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  getDefaultColumns() {
    return [{
      text: "Název balíku",
      dataField: "name",
    }, {
      text: "ČR",
      dataField: "domestic",
    }, {
      text: "Zahraničí",
      dataField: "abroad",
    }, {
      text: "Celkem",
      dataField: "count",
    }]
  }
}
