import React from "react"
import ProductCatalogueRow from "./MyProductCatalogueRow"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import COUNTRY from "../../Base/Enum/Countries"
import styles from "./style.module.css"

let getCzechDescription = (product) => {
  let name = product.names.find(name => name.country_id === COUNTRY.CZECH_REPUBLIC)

  if (name) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: name.description || ""
        }}
      />
    )
  }

  return ""
}

export default (props) => (
  <>
    {props.node.category && props.node.category_id !== 1 && ( // :facepalm:
      <tr
        id={`order-${props.orderId}-category-${props.node.category_id}`}
      >
        <td
          colSpan={3}
          className={"border-0 text-primary py-3"}
          style={{
            fontSize: 20 - (props.node.level * 2) // :facepalm:
          }}
        >{props.node.category.name}</td>
      </tr>
    )}

    {props.node.product && (
      <tr>
        <td
          className={"border-0 py-3"}
          style={{
            height: 200
          }}
        >
          <img
            src={props.node.product.image_url_thumb}
            alt={getProductNameByCountry(props.node.product)}
          />
        </td>
        <td
          className={"border-0 py-3"}
        >
          <h6
            className={"font-weight-bold"}
          >
            {getProductNameByCountry(props.node.product)}
            <span
              className={`ml-3 badge badge-success ${styles.badge}`}
            >{props.node.product.prices.customer.toLocaleString()} Kč</span>
          </h6>
          <div
            className={"m-0"}
          >{getCzechDescription(props.node.product)}</div>
        </td>
        <td
          className={"border-0 py-3"}
        >
          <button
            type={"button"}
            className={`btn btn-success ${styles.addProductBtn}`}
            onClick={() => props.selectProductHandler(props.node.product)}
          >Přidat do objednávky
          </button>
        </td>
      </tr>
    )}

    {/* products */}
    {props.node.children && props.node.children
      .filter(node => node.product_id !== null)
      .filter(node => node.product.is_my_order_visible)
      .sort((a, b) => a.product.code - b.product.code)
      .map(node => (
        <ProductCatalogueRow
          key={node.id}
          node={node}
          orderId={props.orderId}
          selectProductHandler={props.selectProductHandler}
        />
      ))}

    {/* categories */}
    {props.node.children && props.node.children
      .filter(node => node.category_id !== null)
      .map(node => (
        <ProductCatalogueRow
          key={node.id}
          node={node}
          orderId={props.orderId}
          selectProductHandler={props.selectProductHandler}
        />
      ))}
  </>
)
