import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import axios from "axios"
import Select from "react-select"
import Grid from "../../Base/Grid"
import API from "../../API"
import {getParams} from "../../Utils"
import turnOverExportColumn from "../../fixtures/turnOverExportColumn"
import ExportButton from "../../Base/ExportButton"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"

export default class TurnOverList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    this.settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      isLoading: false,
      total: 0,
      params: Object.assign({
        offset: 0,
        limit: this.settings.limit || 25,
        dateStart: moment().format("YYYY-MM"),
        dateEnd: moment().format("YYYY-MM"),
        range: "customer_points__turn_over",
        sort: "-customer_points__turn_over",
        rangeMin: "",
        rangeMax: "",
        pointsMin: "",
        pointsMax: "",
        totalPointsMin: "",
        totalPointsMax: "",
        type_id: ""
      }, params),
      _columns: [],
      customerTypes: [],
      goldCustomers: [],
    }

    this.apiUrl = "/reports/customersByTurnOver"
    this.appUrl = "/reports/sets/turn-over"

    this.defaultSorted = {
      dataField: "customer_points__turn_over",
      order: "desc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3 text-right"}
        >
          <ExportButton
            exportName={"turn-over-list"}
            fileName={"Zákazníci podle obratu"}
            params={this.state.params}
            columns={turnOverExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Datum od:</label>
          <DatePicker
            showMonthYearPicker={true}
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.dateStart, "YYYY-MM").toDate()}
            onChange={this.handleDateStartChange.bind(this)}
            dateFormat={"MM/yyyy"}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Datum do:</label>
          <DatePicker
            showMonthYearPicker={true}
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.dateEnd, "YYYY-MM").toDate()}
            onChange={this.handleDateEndChange.bind(this)}
            dateFormat={"MM/yyyy"}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Vlastní body od:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.pointsMin}
            onChange={this.handlePointsMinChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Vlastní body do:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.pointsMax}
            onChange={this.handlePointsMaxChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Celkové body od:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.totalPointsMin}
            onChange={this.handleTotalPointsMinChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Celkové body do:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.totalPointsMax}
            onChange={this.handleTotalPointsMaxChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Vlastní obrat od:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.rangeMin}
            onChange={this.handleRangeMinChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-2 col-6 mb-3"}
        >
          <label>Vlastní obrat do:</label>
          <input
            type={"number"}
            className={"form-control"}
            value={this.state.params.rangeMax}
            onChange={this.handleRangeMaxChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-6 mb-3"}
        >
          <label>Typ zákazníka:</label>
          <Select
            value={this.state.customerTypes.filter(type => this.state.params.type_id.indexOf(type.id) > -1)}
            placeholder={"Typ zákazníka"}
            isMulti={true}
            options={this.state.customerTypes}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCustomerTypeIdChange.bind(this)}
          />
        </div>

        <div
          className={"col-md-6 mb-3"}
        >
          <button
            className={"btn btn-primary mr-3"}
            onClick={this.getData.bind(this)}
          >Zobrazit přehled
          </button>

          <button
            className={"btn btn-primary"}
            onClick={this.handleDraw.bind(this)}
          >{this.state.params.sort === "RAND" ? "Zrušit losování" : "Losuj"}</button>
        </div>

        <div
          className={"col-md-6 mb-3 text-right"}
        >
          <button
            className={"btn btn-light"}
            onClick={this.resetFilters.bind(this)}
          >Vymazat filtry
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4
                remote
                striped
                hover
                keyField={"id"}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getCustomerTypes(),
      this.getGoldCustomers()
    ]).then(axios.spread((customerTypesRes, goldCustomersRes) => {
      let customerTypes = customerTypesRes.data.objects.map(type => Object.assign({}, type, {
        label: type.name,
        value: type.id,
      }))

      this.setState({
        customerTypes: customerTypes,
        goldCustomers: goldCustomersRes.data.objects,
        params: Object.assign({}, this.state.params, {
          type_id: `=${customerTypes.map(type => type.id).join("|")}`
        })
      }, this.getData.bind(this))
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getGoldCustomers() {
    return API.get("/customers?is_gold=1&limit=999&offset=0")
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        dateStart: moment(date).format("YYYY-MM")
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        dateEnd: moment(date).format("YYYY-MM")
      })
    })
  }

  handleRangeMinChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        rangeMin: e.target.value,
      })
    })
  }

  handleRangeMaxChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        rangeMax: e.target.value,
      })
    })
  }

  handlePointsMinChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        pointsMin: e.target.value,
      })
    })
  }

  handlePointsMaxChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        pointsMax: e.target.value,
      })
    })
  }

  handleTotalPointsMinChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        totalPointsMin: e.target.value,
      })
    })
  }

  handleTotalPointsMaxChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        totalPointsMax: e.target.value,
      })
    })
  }

  handleCustomerTypeIdChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        type_id: `=${(options || [])
          .map(type => type.id)
          .join("|")}`
      })
    })
  }

  handleDraw() {
    let isDrawn = this.state.params.sort === "RAND"

    this.setState({
      params: Object.assign({}, this.state.params, {
        sort: isDrawn ? "-customer_points__turn_over" : "RAND",
        limit: isDrawn ? (this.settings.limit || 25) : 3,
      })
    }, this.getData.bind(this))
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    let types = formatExtraData.filter(customerType => customerType.id === cell)

    if (types.length > 0) {
      return types[0].name
    }

    return ""
  }


  formatPoints(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].points
    }

    return 0
  }

  formatTotalPoints(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].total_points
    }

    return 0
  }

  formatTurnOver(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].turn_over.toFixed(2)
    }

    return 0
  }

  formatTurnOverVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return row.points[0].turn_over_vat.toFixed(2)
    }

    return 0
  }

  formatSponsorCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (row.sponsor) {
      return (
        <NavLink
          to={`/customers/${row.id}/detail`}
        >{row.sponsor.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    if (row.sponsor) {
      return row.sponsor.name || ""
    }

    return ""
  }

  formatSponsorSurname(cell, row, rowIndex, formatExtraData) {
    if (row.sponsor) {
      return row.sponsor.surname || ""
    }

    return ""
  }

  formatGoldCustomerNo(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return (
        <NavLink
          to={`/customers/${row.id}/detail`}
        >{goldCustomer.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatGoldName(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return goldCustomer.name
    }

    return ""
  }

  formatGoldSurname(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return goldCustomer.surname
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state.customerTypes,
    }, {
      text: "Příjmení",
      dataField: "surname",
    }, {
      text: "Jméno",
      dataField: "name",
    }, {
      text: "Město",
      dataField: "billing_address_city",
    }, {
      text: "Vlastní body",
      dataField: "customer_points__points",
      formatter: this.formatPoints.bind(this),
      sort: true,
    }, {
      text: "Celkové body",
      dataField: "customer_points__total_points",
      formatter: this.formatTotalPoints.bind(this),
      sort: true,
    }, {
      text: "Vlastní obrat",
      dataField: "customer_points__turn_over",
      formatter: this.formatTurnOver.bind(this),
      sort: true,
    }, {
      text: "Vlastní obrat s DPH",
      dataField: "customer_points__turn_over_vat",
      formatter: this.formatTurnOverVAT.bind(this),
      sort: true,
    }, {
      text: "Č. sponzora",
      dataField: "sponsor__customer_no",
      formatter: this.formatSponsorCustomerNo.bind(this),
    }, {
      text: "Jméno sponzora",
      dataField: "sponsor__name",
      formatter: this.formatSponsorName.bind(this),
    }, {
      text: "Příjmení sponzora",
      dataField: "sponsor__surname",
      formatter: this.formatSponsorSurname.bind(this),
    }, {
      text: "Č. zlatého sponzora",
      dataField: "gold__customer_no",
      formatter: this.formatGoldCustomerNo.bind(this),
    }, {
      text: "Jméno zlatého sponzora",
      dataField: "gold__name",
      formatter: this.formatGoldName.bind(this),
    }, {
      text: "Příjmení zlatého sponzora",
      dataField: "gold__surname",
      formatter: this.formatGoldSurname.bind(this),
    },]
  }

  // overridden
  resetFilters() {
    this.setState({
      params: Object.assign({}, this.state.params, {
        dateStart: moment().format("YYYY-MM"),
        dateEnd: moment().format("YYYY-MM"),
        rangeMin: "",
        rangeMax: "",
        pointsMin: "",
        pointsMax: "",
        totalPointsMin: "",
        totalPointsMax: "",
        limit: this.settings.limit || 25,
      })
    }, this.getData.bind(this))
  }
}

