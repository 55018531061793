import React, {Component} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import {NavLink} from "react-router-dom"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import COUNTRY from "../../Base/Enum/Countries"
import API from "../../API"

export default class RequiredProductsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }

    this.apiUrl = "/reports/products-requested"
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <BootstrapTable
            bootstrap4={true}
            remote={true}
            striped={true}
            hover={true}
            keyField={"id"}
            ref={ref => this.gridTableRef = ref}
            columns={this.getDefaultColumns()}
            data={this.state.data}
            noDataIndication={"Nic nenalezeno"}
          />
        </div>
      </div>
    )
  }

  getData() {
    this.setState({
      isLoading: true,
    })

    API.get(this.apiUrl)
      .then(({data}) => {
        this.setState({
          data: data.objects,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  getDefaultColumns() {
    return [{
      text: "Č. výrobku",
      dataField: "product__code",
      formatter: this.formatProductCode.bind(this),
    }, {
      text: "Název výrobku",
      dataField: "product__name",
      formatter: this.formatProductName.bind(this),
    }, {
      text: "Počet kusů",
      dataField: "quantity_requested",
    },]
  }

  formatProductCode(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/products/${row.product.id}/detail`}
      >{row.product.code}</NavLink>
    )
  }

  formatProductName(cell, row, rowIndex, formatExtraData) {
    return getProductNameByCountry(row.product, COUNTRY.CZECH_REPUBLIC, null)
  }
}
