import React, {Component} from "react"
import {NavLink, Route, Switch} from "react-router-dom"
import logo from "../assets/logo.png"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import {is_MY} from "../const"
import NavBarSales from "./NavBarSales"
import NavBarCustomers from "./NavBarCustomers"
import NavBarProducts from "./NavBarProducts"
import NavBarEnums from "./NavBarEnums"
import NavBarReports from "./NavBarReports"
import NavBarReportsSets from "./NavBarReportsSets"
import NavBarWarehouse from "./NavBarWarehouse"
import NavBarMySales from "./NavBarMySales"
import NavBarMyProducts from "./NavBarMyProducts"
import NavBarMyCustomers from "./NavBarMyCustomers"
import NavBarReportsCommissions from "./NavBarReportsCommissions"
import NavBarReportsContests from "./NavBarReportsContests"
import NavBarLogs from "./NavBarLogs"

export default class NavBar extends Component {
  render() {
    return (
      <>
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-brand"}
          >
            <img
              src={logo}
              alt={"Missiva"}
              height={25}
            />
          </div>

          <div
            className={"navbar-nav mr-lg-auto"}
          >
            <NavLink
              exact
              to={"/"}
              className={"nav-link"}
              activeClassName={"active"}
            >Úvod</NavLink>
            {this.props.hasAccessHandler("#/sales") && (
              <NavLink
                to={"/sales"}
                className={"nav-link"}
                activeClassName={"active"}
                disabled={"disabled"}
              >Zakázky a objednávky</NavLink>
            )}
            {this.props.hasAccessHandler("#/my-sales") && (
              <NavLink
                to={"/my-sales"}
                className={"nav-link"}
                activeClassName={"active"}
                disabled={"disabled"}
              >Přehled objednávek</NavLink>
            )}
            {this.props.hasAccessHandler("#/customers") && (
              <NavLink
                to={"/customers"}
                className={"nav-link"}
                activeClassName={"active"}
              >Zákazníci</NavLink>
            )}
            {this.props.hasAccessHandler("#/my-customers") &&
            this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RD &&
            !this.props.authenticatedCustomer.is_my_access_limited && (
              <NavLink
                to={"/my-customers/customers"}
                className={"nav-link"}
                activeClassName={"active"}
                isActive={this.isMyCustomersNavLinkActive.bind(this)}
              >Přehled zákazníků</NavLink>
            )}
            {this.props.hasAccessHandler("#/my-customers") &&
            this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RD &&
            !this.props.authenticatedCustomer.is_my_access_limited && (
              <NavLink
                to={"/my-customers/tree"}
                className={"nav-link"}
                activeClassName={"active"}
              >Moje skupinové body</NavLink>
            )}
            {this.props.hasAccessHandler("#/products") && (
              <NavLink
                to={"/products"}
                className={"nav-link"}
                activeClassName={"active"}
              >Produkty</NavLink>
            )}
            {this.props.hasAccessHandler("#/my-products") &&
            !this.props.authenticatedCustomer.is_my_access_limited && (
              <NavLink
                to={"/my-products/price-list"}
                className={"nav-link"}
                activeClassName={"active"}
              >Ceník</NavLink>
            )}
            {this.props.hasAccessHandler("#/reports") && (
              <NavLink
                to={"/reports"}
                className={"nav-link"}
                activeClassName={"active"}
                disabled={"disabled"}
              >Reporty</NavLink>
            )}
            {this.props.hasAccessHandler("#/enums") && (
              <NavLink
                to={"/enums/users"}
                className={"nav-link"}
                activeClassName={"active"}
              >Nastavení</NavLink>
            )}
            {this.props.hasAccessHandler("#/logs") && (
              <NavLink
                to={"/logs"}
                className={"nav-link"}
                activeClassName={"active"}
              >Logy</NavLink>
            )}
            {this.props.hasAccessHandler("#/warehouse/sales") && (
              <NavLink
                to={"/warehouse/sales"}
                className={"nav-link"}
                activeClassName={"active"}
              >Sklad</NavLink>
            )}
            {this.props.hasAccessHandler("#/communication") && (
              <NavLink
                to={"/communication"}
                className={"nav-link"}
                activeClassName={"active"}
              >Komunikace</NavLink>
            )}
            {this.props.hasAccessHandler("#/common/help") && (
              <NavLink
                to={"/common/help"}
                className={"nav-link"}
                activeClassName={"active"}
              >Nápověda</NavLink>
            )}
          </div>

          <div>
            {is_MY && (
              <small
                className={"text-black-50 mr-1"}
              >{60 - this.props.minutesOfInactivity} minut do automatického odhlášení</small>
            )}
            <button
              className={"btn btn-sm btn-secondary"}
              onClick={this.handleLogoutClick.bind(this)}
            >Odhlásit
            </button>
          </div>
        </nav>

        <NavBarSales/>
        <NavBarCustomers/>
        <NavBarProducts/>
        <NavBarEnums/>
        <NavBarReports/>
        <NavBarReportsSets/>
        <NavBarReportsCommissions/>
        <NavBarReportsContests/>
        <NavBarWarehouse/>
        <NavBarMySales/>
        <NavBarMyProducts/>
        <NavBarMyCustomers
          authenticatedCustomer={this.props.authenticatedCustomer}
        />
        <NavBarLogs/>
        {/* COMMUNICATION */}
        <Switch>
          <Route
            path={"/communication"}
          >
            <nav
              className={"navbar navbar-expand-sm py-0 d-print-none"}
            >
              <div
                className={"navbar-nav mr-auto"}
              >
                <NavLink
                  to={"/communication/sms"}
                  className={"nav-link"}
                  activeClassName={"active"}
                >SMS</NavLink>
              </div>
            </nav>
          </Route>
        </Switch>
      </>
    )
  }

  handleLogoutClick() {
    this.props.logoutHandler()
  }

  isMyCustomersNavLinkActive(match, location) {
    return location.pathname === "/my-customers/customers" ||
      location.pathname === "/my-customers/registration"
  }
}
