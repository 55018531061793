import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import API from "../../API"
import {cleanState} from "../../Utils"

export default class Promotion4Plus1Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "n_plus_discount",
      valid_from: moment().format("YYYY-MM-DD hh:mm:ss"),
      valid_to: moment().format("YYYY-MM-DD hh:mm:ss"),
      admin_valid_to: moment().format("YYYY-MM-DD hh:mm:ss"),
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getPromotionDetail()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-row"}
            >
              <div
                className={"mb-2 col-12"}
              >Nastavte dobu trvání akce:
              </div>

              <div
                className={"form-group col-3"}
              >
                <label>Začátek akce:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_from, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidFromChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-3"}
              >
                <label>Ukončení akce v moje.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidToChange.bind(this)}
                />
              </div>
              <div
                className={"form-group col-3"}
              >
                <label>Ukončení akce v is.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.admin_valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleAdminValidToChange.bind(this)}
                />
              </div>
            </div>
            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
            {this.props.match.params.id && (
              <button
                type={"button"}
                className={"btn btn-danger float-right"}
                onClick={this.handleDelete.bind(this)}
              >Smazat</button>
            )}
          </form>
        </div>
      </div>
    )
  }

  getPromotionDetail() {
    API.get(`/product-discount-groups/${this.props.match.params.id}`)
      .then(({data}) => {
        this.setState({
          valid_from: data.valid_from,
          valid_to: data.valid_to,
          admin_valid_to: data.admin_valid_to || this.state.admin_valid_to,
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let data = cleanState(this.state)

    if (this.props.match.params.id) {
      API.put(`/product-discount-groups/${this.props.match.params.id}`, data)
        .then(this.handleCancel.bind(this))
    } else {
      API.post("/product-discount-groups", data)
        .then(this.handleCancel.bind(this))
    }
  }

  handleCancel() {
    this.props.history.push("/products/discount")
  }

  handleValidFromChange(date) {
    this.setState({
      valid_from: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleValidToChange(date) {
    this.setState({
      valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleAdminValidToChange(date) {
    this.setState({
      admin_valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleDelete() {
    if (window.confirm("Opravdu chceš smazat?")) {
      API.delete(`/product-discount-groups/${this.props.match.params.id}`)
        .then(this.handleCancel.bind(this))
    }
  }

  isActive() {
    return moment(new Date()).isBetween(this.state.valid_from, this.state.valid_to)
  }
}
