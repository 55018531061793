import jwtDecode from "jwt-decode"
import API from "../API"
import ROLE_ACCESS from "./roleAccess"

const userData = JSON.parse(localStorage.getItem("missiva_is"))

const RBAC = {
  token: userData ? userData.token : null,
  user: userData ? jwtDecode(userData.token) : null,

  setUserToken(token) {
    API.setToken(token)

    this.token = token
    this.user = jwtDecode(token)

    localStorage.setItem("missiva_is", JSON.stringify({token}))
  },

  removeUserToken() {
    API.setToken("")

    this.token = null
    this.user = null

    localStorage.removeItem("missiva_is")
  },

  isAuthenticated() {
    return !!this.token
  },

  getUser() {
    return this.user
  },

  getToken() {
    return this.token || ""
  },

  hasAccess(customHash = null) {
    let hash = customHash || window.location.hash
    let role = "ROLE_COMMON" // role with access to common pages

    if (this.user) {
      role = this.user.role_id

      if (hash === "" || hash === "#/") // everyone authenticated has access to HP
        return true
    }

    return ROLE_ACCESS[role].some(roleAccessHash =>
      hash.substr(0, roleAccessHash.length) === roleAccessHash)
  },
}

export default RBAC
