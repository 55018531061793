import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {cleanState} from "../../Utils"
import SpinnerInButton from "../../Base/SpinnerInButton/SpinnerInButton"

export default class SMS extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone_number: props.match.params.phone_number || "",
      message: "Missiva: ",
      _charactersCount: 9,
      _isLoading: false,
    }

    this.customerId = props.match.params.customer_id || null
    this.phoneNumber = props.match.params.phone_number || null
  }

  render() {
    return (
      <form
        onSubmit={this.handleFormSubmit.bind(this)}
      >
        <div
          className={"row"}
        >
          <div
            className={"form-group col-lg-5"}
          >
            <label>Telefonní číslo:</label>
            <input
              type={"text"}
              required={true}
              className={"form-control"}
              value={this.state.phone_number}
              placeholder={"Telefonní číslo"}
              onChange={this.handlePhoneNumberChange.bind(this)}
            />
          </div>
        </div>

        <div
          className={"row"}
        >
          <div
            className={"form-group col-lg-5"}
          >
            <label>Text zprávy bez diakritiky:</label>
            <textarea
              required={true}
              className={"form-control"}
              value={this.state.message}
              placeholder={"Text zprávy"}
              maxLength={160}
              rows={5}
              onChange={this.handleMessageChange.bind(this)}
            />

            <div
              className={"mt-1 text-right"}
            >
              <small>Počet znaků: {this.state._charactersCount}/160</small>
            </div>
          </div>
        </div>

        <div
          className={"row"}
        >
          <div
            className={"form-group col-lg-5"}
          >
            {this.customerId && (
              <NavLink
                to={`/customers/${this.customerId}/detail`}
                className={"btn btn-primary mr-3"}
              >Zpět na detail zákazníka</NavLink>
            )}

            <button
              className={"btn btn-success mr-2"}
              disabled={!this.state.phone_number || !this.state.message || this.state._isLoading}
            >Odeslat SMS zprávu {this.state._isLoading ? <SpinnerInButton/> : ""}</button>
          </div>
        </div>
      </form>
    )
  }

  validatePhoneNumber() {
    return API.post("/validate/contact", {
      type_id: 5,
      value: this.state.phone_number,
    }).then(() => true)
      .catch(() => false)
  }

  async handleFormSubmit(e) {
    e.preventDefault()
    let isPhoneNumberValid = await this.validatePhoneNumber()

    if (isPhoneNumberValid) {
      this.setState({
        _isLoading: true,
      })

      let sms = cleanState(this.state)
      sms.phone_number = this.state.phone_number

      API.post("/sms/send", sms)
        .then(({data}) => {
          alert(data.message)

          if (data.success) {
            if (
              this.customerId ||
              this.phoneNumber
            ) {
              this.props.history.goBack()
            } else {
              this.setState({
                message: "Missiva: ",
                _charactersCount: 9,
              })
            }
          }
        })
        .catch(({data}) => alert(data.message))
        .finally(() => {
          this.setState({
            _isLoading: false,
          })
        })
    }
  }

  handlePhoneNumberChange(e) {
    this.setState({
      phone_number: e.target.value,
    })
  }

  handleMessageChange(e) {
    this.setState({
      message: e.target.value,
      _charactersCount: e.target.value.length,
    })
  }
}
