import {Component} from "react"
import API from "../API"
import {isNumber, serializeParams} from "../Utils"

export default class Grid extends Component {
  componentDidMount() {
    this.automaticUpdate = false
    this.updateInterval = setInterval(this.updateIntervalCb.bind(this), 30000) // 30s
    this._getData()
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
  }

  // default implementation. in most cases will be overridden by component
  // it is used to fetch required data before grid data
  _getData() {
    this.getData()
  }

  updateIntervalCb() {
    this.automaticUpdate = true
    this.getData()
  }

  getData() {
    // show overlay only when fetching is more than 15s long
    let loadingTimeout = setTimeout(() => {
      this.setState({
        isLoading: true,
      })
    }, this.automaticUpdate ? 15000 : 0)

    let params = serializeParams(this.state.params)
    window.history.replaceState({}, "", `/#${this.appUrl}${params}`)

    API.get(`${this.apiUrl}${params}`)
      .then(({data}) => {
        let {objects, meta} = data

        let state = {
          data: objects,
          meta: meta,
          total: meta.total_count,
        }

        // set columns on first load}
        if (this.state._columns.length === 0) {
          let columns = this.getDefaultColumns()
          let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings"))

          if (settings && settings.hasOwnProperty(this.appUrl)) {
            let savedColumns = settings[this.appUrl] // get columns for corresponding grid

            columns.forEach(column => {
              let saveColumns = savedColumns.filter(savedColumn => savedColumn.dataField === column.dataField)
              if (saveColumns.length !== 0) {
                column.visible = saveColumns[0].visible
              }
            })
          }

          state._columns = columns
        }

        this.setState(state)
      })
      .finally(() => {
        clearTimeout(loadingTimeout)
        this.setState({
          isLoading: false,
        })
      })
  }

  handleTableChange(type, newState) {
    // grid pagination
    if (type === "pagination") {
      let {page, sizePerPage} = newState

      this.setState({
        params: Object.assign({}, this.state.params, {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
        })
      }, () => {
        this.getData()
        this.saveSettings()
      })
    }

    // column filtering
    if (type === "filter") {
      let {filters} = newState

      // handle add column
      if (filters.hasOwnProperty("add_column")) {
        return this.toggleColumn(filters.add_column.filterVal)
      }

      // handle filters
      let filter = {}
      let keys = Object.keys(filters)

      keys.forEach(key => {
        if (filters[key].filterType === "TEXT" || filters[key].filterType === "DATE") {
          if (["sale_no", "customer_no", "customer__customer_no"].indexOf(key) > -1) {
            filter[key] = `^${filters[key].filterVal}` // finding from start
          } else {
            filter[key] = filters[key].filterVal
          }
        }

        if (filters[key].filterType === "NUMBER") {
          filter[key] = filters[key].filterVal.number
        }

        if (filters[key].filterType === "SELECT") {
          filter[key] = isNumber(filters[key].filterVal)
            ? parseInt(filters[key].filterVal)
            : filters[key].filterVal
        }

        if (filters[key].filterType === "DATE_RANGE") {
          filter[key] = filters[key].filterVal.dateFrom
            ? `>=${filters[key].filterVal.dateFrom}`
            : ""

          filter[`${key}-2`] = filters[key].filterVal.dateTo
            ? `<=${filters[key].filterVal.dateTo}`
            : ""
        }
      })

      // handle empty filters (reset filters are not returned in newState)
      Object.keys(this.state.params).forEach(key => {
        // non removable params
        if (["limit", "offset", "sort"].indexOf(key) > -1) {
          return
        }

        // component custom non removable params
        if (
          this.defaultParams &&
          this.defaultParams.indexOf(key) > -1
        ) {
          return
        }

        if (
          !filters.hasOwnProperty(key) &&
          !filters.hasOwnProperty(key.replace("-2", "")) // remove only if filters does no contain key without -2 postfix
        ) {
          filter[key] = undefined
        }
      })

      // reset pagination to first page when filtering
      filter.offset = 0

      this.setState({
        params: Object.assign({}, this.state.params, filter)
      }, () => {
        this.getData()
      })
    }

    // column sorting
    if (type === "sort") {
      this.sort = {} // TODO: use it
      let {sortField, sortOrder} = newState

      this.setState({
        params: Object.assign({}, this.state.params, {
          sort: sortField
            ? `${sortOrder === "asc" ? "" : "-"}${sortField}`
            : ""
        })
      })
    }
  }

  toggleColumn(option) {
    let newColumns = [...this.state._columns]
    let columns = newColumns.filter(col => col.dataField === option.value)

    if (columns.length > 0) {
      columns[0].visible = !columns[0].visible
    }

    this.setState({
      _columns: newColumns
    }, this.saveSettings)
  }

  saveSettings() {
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    settings.limit = this.state.params.limit

    let columnsVisibility = []
    this.state._columns.forEach(column => {
      columnsVisibility.push({
        dataField: column.dataField,
        visible: column.visible,
      })
    })

    settings[this.appUrl] = columnsVisibility
    localStorage.setItem("missiva_is.grid_settings", JSON.stringify(settings))
  }

  resetFilters() {
    for (let key in this.state.params) {
      if (
        this.state.params.hasOwnProperty(key) &&
        this.state.params[key] &&
        this.filters.hasOwnProperty(key)
      ) {
        this.filters[key]("")
      }
    }

    setTimeout(() => {
      if (
        this.state.params.hasOwnProperty("sort") &&
        this.gridTableRef
      ) {
        if (this.defaultSorted) {
          if (this.state.params.sort !== `${this.defaultSorted.order === "asc" ? "" : "-"}${this.defaultSorted.dataField}`) {
            this.gridTableRef.sortContext.handleSort(this.defaultSorted)
          }
        } else {
          this.gridTableRef.sortContext.handleSort({})
        }
      }
    }, 1000)
  }
}
