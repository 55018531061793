import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import logo from "../assets/logo.png"
import API from "../API"
import styles from "./styles.module.css"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"

export default class MyLoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customerNo: "",
      smsDelivered: false,
      loginCode: "",
      isSMSSendLoading: false,
      isLoginLoading: false,
    }
  }

  componentDidMount() {
    this.loginCodeRef = React.createRef()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={`mx-auto mt-5 col-xl-4 col-lg-6 col-md-8 col-12 ${styles.colXL}`}
        >
          <div
            className="form-group text-center"
          >
            <img
              src={logo}
              alt="Missiva logo"
              height={100}
            />
            <p
              className="my-3"
            >Vítejte na <strong>moje.missiva.cz</strong>, v online světě produktů každodenní
              spotřeby,
              přátelských k lidem a přírodě sloužících k udržování čistoty a zdraví lidského těla a
              obydlí.</p>
          </div>

          <div
            className={"form-group text-center"}
          >
            <h4>Přihlášení registrovaných zákazníků</h4>
            <p>Nevíte si rady s přihlášením? Volejte Po - Pá, 9 - 15h, tel: 417 590 911.</p>
          </div>

          <div
            className={"form-group col-xl-8 offset-xl-2"}
          >
            <p
              className={"mb-2"}
            >Zadejte vaše zákaznické číslo:</p>

            <input
              type={"text"}
              className={"form-control"}
              placeholder={"Zákaznické číslo"}
              autoFocus={true}
              onChange={this.handleCustomerNoChange.bind(this)}
              onKeyDown={this.handleCustomerNoKeyDown.bind(this)}
              value={this.state.customerNo}
            />
          </div>

          <div
            className={"form-group col-xl-8 offset-xl-2"}
          >
            <button
              type={"button"}
              className={"btn btn-primary w-100"}
              disabled={!this.state.customerNo || this.state.isSMSSendLoading}
              onClick={this.handleSendCustomerNo.bind(this)}
            >
              Požádejte o přihlašovací kód SMSkou
              {this.state.isSMSSendLoading && <SpinnerInButton/>}
            </button>
          </div>

          <div
            className={"form-group col-xl-8 offset-xl-2"}
          >
            <p
              className={"mb-2"}
            >Zadejte přihlašovací kód z SMS:</p>
            <input
              type={"text"}
              className={"form-control"}
              placeholder={"Přihlašovací kód"}
              onChange={this.handleLoginCodeChange.bind(this)}
              onKeyDown={this.handleLoginCodeKeyDown.bind(this)}
              value={this.state.loginCode}
              ref={this.loginCodeRef}
            />
          </div>

          <div
            className={"form-group col-xl-8 offset-xl-2"}
          >
            <button
              type="button"
              className={"btn btn-primary w-100"}
              disabled={!this.state.smsDelivered || !this.state.loginCode || this.state.isLoginLoading}
              onClick={this.handleLogin.bind(this)}
            >
              Přihlásit se do moje.missiva.cz
              {this.state.isLoading && <SpinnerInButton/>}
            </button>
          </div>

          <div
            className={"form-group mt-5 text-center"}
          >
            <h4>Vítáme i nové zákazníky</h4>
          </div>

          <div
            className="form-group"
          >
            <p><strong>Chcete z domova pohodlně nakoupit a vyzkoušet kvalitní ekologicky šetrné
              výrobky od českého výrobce za výhodné ceny?</strong></p>
            <p>Šetřte nejen vaše finance, ale také zdraví a přírodu. Myslete na sebe a své děti.</p>
            <p>Staňte se zdarma naším novým zákazníkem a pravidelnými nákupy můžete získat kvalitní
              produkty, zajímavé informace a další výhody.</p>
          </div>

          <div
            className="form-group col-xl-8 offset-xl-2"
          >
            <NavLink
              to={"/common/registration"}
              className={"btn btn-primary w-100"}
            >Registrovat se do moje.missiva.cz</NavLink>
          </div>
        </div>
      </div>
    )
  }

  handleCustomerNoChange(e) {
    this.setState({
      customerNo: e.target.value,
    })
  }

  handleCustomerNoKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault()
      if (this.state.customerNo)
        this.handleSendCustomerNo()
    }
  }

  handleLoginCodeChange(e) {
    this.setState({
      loginCode: e.target.value,
    })
  }

  handleLoginCodeKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault()
      if (this.state.loginCode)
        this.handleLogin()
    }
  }

  handleSendCustomerNo() {
    this.setState({
      isSMSSendLoading: true,
    })

    API.post("/auth/customer", {
      customer_no: this.state.customerNo,
    }).then(({data}) => {
      if (data.success)
        this.setState({
          smsDelivered: true,
        }, () => {
          this.loginCodeRef.current.focus()
        })
      else
        alert("SMS se nepodařilo doručit. Zkuste to později nebo volejte zákaznické centrum Missiva.")
    }).finally(() => {
      this.setState({
        isSMSSendLoading: false,
      })
    })
  }

  handleLogin() {
    this.setState({
      isLoginLoading: true,
    })

    API.post("/auth/sms", {
      customer_no: this.state.customerNo,
      code: this.state.loginCode,
    }).then(({data}) => {
      this.props.loginHandler(data.token)
    }).catch(({response}) => {
      if (response && response.data.error_code.length > 0 && response.data.error_code[0] === "auth_code_expired")
        this.setState({
          smsDelivered: true,
        })
    }).finally(() => {
      this.setState({
        isLoginLoading: false,
      })
    })
  }
}
