const PRODUCT_CODE = {
  REGISTRATION: 9601,
  MEMBERSHIP: 9112,
  DELIVERY: 9130,
  PACKING_CZECH_POST: 9133,
  PACKING_GEIS: 9136,
  HANDLING_FEE: 9876,
  NEW_CUSTOMER_CARD: 9225,
}

export default PRODUCT_CODE
