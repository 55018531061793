import React, {Component, Fragment} from "react"
import {NavLink} from "react-router-dom"
import AsyncSelect from "react-select/async"
import API from "../../API"
import ReactSelectTableOption from "../../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import formatCustomerName from "../../Base/formatCustomerName"

export default class GoldCustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: null
    }
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getCustomer()
    }
  }

  render() {
    return (
      <Fragment>
        <div
          className={"mb-3"}
        >
          <NavLink
            to={"/customers/gold/add"}
            className={"btn btn-primary mr-3"}
          >Přidat zlatého poradce</NavLink>
        </div>

        <form
          onSubmit={this.handleFormSubmit.bind(this)}
          className={"row"}
        >
          <div
            className={"form-group select-required col-12"}
          >
            <label>Zákazník:</label>
            {this.id
              ?
              <input
                type={"text"}
                className={"form-control"}
                disabled={true}
                value={this.state.customer ? this.state.customer.label : ""}
              />
              :
              <AsyncSelect
                className={"react-select_border"}
                value={this.state.customer || {}}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                onChange={this.handleCustomerSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                components={{
                  Option: ReactSelectTableOption
                }}
                styles={ReactSelectCustomStyles}
              />
            }
          </div>

          <div
            className={"col-12"}
          >
            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >{this.id ? "Odebrat status zlatého poradce" : "Přidat zákazníka mezi zlaté poradce"}
            </button>
          </div>
        </form>
      </Fragment>
    )
  }

  getCustomer() {
    API.get(`/customers/${this.id}`)
      .then(({data}) => {
        this.setState({
          customer: Object.assign({}, data, {
            value: data.id,
            label: formatCustomerName(data),
          })
        })
      })
  }

  handleCustomerChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleCustomerSelect(option) {
    this.setState({
      customer: Object.assign({}, option, {
        value: option.id,
        label: formatCustomerName(option),
      }),
    })
  }

  handleCancel() {
    this.props.history.push("/customers/gold")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.state.customer)
      this.updateIsGoldStatus(this.id ? 0 : 1)

  }

  updateIsGoldStatus(status) {
    API.patch(`/customers/${this.state.customer.id}`, {
      is_gold: status
    }).then(() => {
      this.props.history.push("/customers/gold")
    })
  }
}
