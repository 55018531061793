import React, {Component, Fragment} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {cleanState} from "../../Utils"

export default class UserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      name: "",
      surname: "",
      role_id: "",
      _roles: []
    }
    this.getRoles()
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getUser()
    }
  }

  render() {
    return (
      <Fragment>
        <div
          className={"mb-3"}
        >
          <NavLink
            to={"/enums/users/add"}
            className={"btn btn-primary mr-3"}
          >Přidat uživatele</NavLink>
          {this.id && <button
            className={"btn btn-danger mr-3 float-right"}
            onClick={this.handleDelete.bind(this)}
          >Smazat uživatele</button>}
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-5"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group"}
              >
                <label>Email:</label>
                <input
                  type={"email"}
                  className={"form-control"}
                  placeholder={"Email"}
                  required={true}
                  onChange={this.handleEmailChange.bind(this)}
                  value={this.state.email}
                  minLength={5}
                  maxLength={30}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Heslo pro přihlášení:</label>
                <input
                  type={"password"}
                  className={"form-control"}
                  placeholder={"Heslo pro přihlášení"}
                  required={!this.id}
                  onChange={this.handlePasswordChange.bind(this)}
                  value={this.state.password}
                  minLength={5}
                  maxLength={80}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Jméno:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder={"Jméno"}
                  required={true}
                  onChange={this.handleNameChange.bind(this)}
                  value={this.state.name}
                  minLength={3}
                  maxLength={30}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Příjmení:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  id={"surname"}
                  placeholder={"Příjmení"}
                  required={true}
                  onChange={this.handleSurnameChange.bind(this)}
                  value={this.state.surname}
                  minLength={3}
                  maxLength={30}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Role:</label>
                <select
                  value={this.state.role_id}
                  className={"form-control"}
                  id={"role"}
                  required={true}
                  onChange={this.handleRoleChange.bind(this)}
                >
                  <option
                    value={""}
                  >Role
                  </option>
                  {this.state._roles.map(role => {
                    return (
                      <option
                        key={role.id}
                        value={role.id}
                      >
                        {role.name}
                      </option>
                    )
                  })}
                </select>
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    })
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    })
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    })
  }

  handleSurnameChange(e) {
    this.setState({
      surname: e.target.value,
    })
  }

  handleRoleChange(e) {
    this.setState({
      role_id: e.target.value,
    })
  }

  addUser() {
    let data = cleanState(this.state)
    API.post("/users", data)
      .then(this.handleCancel.bind(this))
  }

  handleCancel() {
    this.props.history.push("/enums/users")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id)
      this.updateUser()
    else
      this.addUser()
  }

  getUser() {
    API.get(`/users/${this.id}`)
      .then(res => {
        let {name, role, surname, email} = res.data
        this.setState({
          name: name,
          role_id: role.id,
          surname: surname,
          email: email,
        })
      })
  }

  updateUser() {
    let data = Object.assign({}, this.state)
    delete data._roles
    if (data.password === "")
      delete data.password

    API.put(`/users/${this.id}`, data)
      .then(this.handleCancel.bind(this))
  }

  getRoles() {
    API.get("/roles?limit=20&offset=0")
      .then(res => {
        this.setState({
          _roles: res.data.objects,
        })
      })
  }

  handleDelete() {
    API.delete(`/users/${this.id}`)
      .then(this.handleCancel.bind(this))
  }
}
