import React, {Component} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import {NavLink} from "react-router-dom"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import API from "../../API"
import CONTACT_TYPE from "../../Base/Enum/ContactType"

export default class CommissionInvoiceList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
      isLoading: true,
      _columns: this.getDefaultColumns(),
    }
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                columns={this.state._columns}
                data={this.state.data.invoices || []}
                noDataIndication={"Nic nenalezeno"}
              />
            </ScrollableTable>
          )}
        </div>

        <div
          className={"col-12 text-right"}
        >
          <button
            className={"btn btn-warning mr-3"}
            onClick={() => this.props.isInvoiceVisibleHandler(false)}
          >Zpět
          </button>
          <button
            className={"btn btn-primary"}
            onClick={this.handleWhatIfIGetWrong.bind(this)}
          >Odeslat faktury
          </button>
        </div>
      </div>
    )
  }

  async getData() {
    this.setState({
      isLoading: true
    })

    let postData = {
      type: ["basic", "direct"],
      customers: this.props.customerIds.map(customerId => {
        return {
          id: customerId,
        }
      })
    }

    await API.post(`/commision-invoice/prepare/${this.props.date}`, postData)
      .catch(() => this.props.isInvoiceVisibleHandler(false))

    API.get(`/commision-invoice/print/${this.props.date}`)
      .then(({data}) => {
        this.setState({
          data: data,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  formatPDF(cell, row, rowIndex, formatExtraData) {
    return (
      <a
        href={cell}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        <i
          className={"fas fa-file-pdf mr-1"}
        />
        Faktura
      </a>
    )
  }

  formatCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (row.customer) {
      return (
        <NavLink
          to={`/customers/${row.customer.id}/detail`}
        >{row.customer.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    if (row.customer) {
      return `${row.customer.surname || ""} ${row.customer.name || ""}`
    }

    return ""
  }

  formatEmail(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.contact
    ) {
      return row.customer.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .map(contact => (
          <a
            href={`mailto:${contact.value}`}
            key={contact.id}
            className={"d-block"}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatSummaryPDF(cell, row, rowIndex, formatExtraData) {
    if (
      this.state.data.invoices &&
      this.state.data.invoices.length > 0
    ) {
      return (
        <a
          href={this.state.data.summary_url}
          className={"text-white"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          <i
            className={"fas fa-file-pdf mr-1"}
          />
          Přehled fakturovaných provizí
        </a>
      )
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Č. faktury",
      dataField: "invoice_no",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNo.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno zákazníka",
      dataField: "customer_name",
      formatter: this.formatCustomerName.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "E-mail",
      dataField: "customer__contact",
      formatter: this.formatEmail.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Celková cena",
      dataField: "total_price_formatted",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Faktura",
      dataField: "url",
      formatter: this.formatPDF.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: this.formatSummaryPDF.bind(this),
    },]
  }

  handleWhatIfIGetWrong() {
    if (window.confirm("Opravdu odeslat faktury?")) {
      this.setState({
        isLoading: true,
      })

      API.post(`/commision-invoice/send/${this.props.date}`)
        .then(() => {
          this.props.isInvoiceVisibleHandler(false)
        })
    }
  }
}
