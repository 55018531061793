import React, {Component} from "react"
import {NavLink} from "react-router-dom"

export default class ListTable extends Component {
  render() {
    return (
      <table
        className={"table table-hover table-striped border-bottom"}
      >
        <thead
          className={"thead-light"}
        >
        <tr>
          {this.props.columns.map(column => {
            return <th
              key={column.text}
            >{column.text}</th>
          })}
        </tr>
        </thead>
        <tbody>
        {this.props.rows.length === 0
          ? <tr>
            <td
              colSpan={this.props.columns.length}
              className={"text-center"}
            >Nic nenalezeno
            </td>
          </tr>
          : this.props.rows.map((row, i) => {
            return (
              <tr
                key={row.id || i}
              >
                {this.props.columns.map((column, i) => this.getCell(row, column, i))}
              </tr>
            )
          })
        }
        </tbody>
      </table>
    )
  }

  getCell(row, column, i) {
    let value = ""

    // value
    if (column.key.length === 2)
      value = row[column.key[0]][column.key[1]]
    else
      value = row[column.key[0]]

    // prefix
    if (column.prefix)
      value = `${column.prefix}${value}`

    // suffix
    if (column.suffix)
      value = `${value}${column.suffix}`

    // format callback
    if (column.format)
      value = column.format(value, row)

    // first column = link to detail
    if (i === 0 && this.props.appUrl)
      value = <NavLink
        to={`${this.props.appUrl}/${row.id}/detail`}
      >{value}</NavLink>

    // image
    if (column.key[0] === "image")
      value = <img
        src={`data:image/png;base64,${value}`}
        alt={""}
        style={{maxHeight: "25px"}}
      />

    return (
      <td
        key={column.elKey || column.key[0]}
        className={column.className || ""}
      >{value}</td>
    )
  }
}
