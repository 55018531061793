import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import axios from "axios"
import Select from "react-select"
import Grid from "../../Base/Grid"
import {getParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import API from "../../API"
import ExportButton from "../../Base/ExportButton"
import reportProfitExportColumn from "../../fixtures/reportProfitExportColumn.json"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"

export default class ProfitsList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      meta: {},
      paymentMethods: [],
      deliveryMethods: [],
      countries: [],
      total: 0,
      isLoading: false,
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        paid_date: `>=${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
        "paid_date-2": `<=${moment().format("YYYY-MM-DD")}`,
        delivery_address__country_code: "",
      }, params),
      _columns: [],
    }

    this.defaultParams = [
      "paid_date",
      "paid_date-2",
      "delivery_address__country_code"
    ]
    this.filters = {}
    this.apiUrl = "/sales"
    this.appUrl = "/reports/sets/profits"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3 text-right"}
        >
          <ExportButton
            exportName={"sales"}
            fileName={"Denní tržby"}
            params={this.state.params}
            columns={reportProfitExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum úhrady od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.paid_date, "YYYY-MM-DD").toDate()}
            onChange={this.handlePaidDateFromChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum úhrady do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params["paid_date-2"], "YYYY-MM-DD").toDate()}
            onChange={this.handlePaidDateToChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-5 mb-3"}
        >
          <label>Země:</label>
          <Select
            value={this.state.countries.filter(country => this.state.params.delivery_address__country_code.indexOf(country.code) > -1)}
            placeholder={"Země"}
            isMulti={true}
            options={this.state.countries}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCountryCodeChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-2 mb-3 mt-lg-4"}
        >
          <button
            className={"btn btn-primary"}
            onClick={this.getData.bind(this)}
          >Zobrazit přehled
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getPaymentMethods(),
      this.getDeliveryMethods(),
      this.getCountries(),
    ]).then(axios.spread((paymentMethodsRes, deliveryMethodsRes, countriesRes) => {
      let paymentMethods = {}
      let deliveryMethods = {}
      let countries = []

      paymentMethodsRes.data.objects.forEach(paymentMethod => {
        paymentMethods[paymentMethod.id] = paymentMethod.name
      })

      deliveryMethodsRes.data.objects.forEach(deliveryMethod => {
        deliveryMethods[deliveryMethod.id] = deliveryMethod.name
      })

      countries = countriesRes.data.objects.map(country => Object.assign(country, {
        value: country.code,
        label: country.code,
      }))

      this.setState({
        paymentMethods: paymentMethods,
        deliveryMethods: deliveryMethods,
        countries: countries,
        params: Object.assign({}, this.state.params, {
          delivery_address__country_code: `=${countries.map(country => country.code).join("|")}`
        })
      }, this.getData.bind(this))
    }))
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  handlePaidDateFromChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        paid_date: `>=${moment(date).format("YYYY-MM-DD")}`
      })
    })
  }

  handlePaidDateToChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        "paid_date-2": `<=${moment(date).format("YYYY-MM-DD")}`
      })
    })
  }

  handleCountryCodeChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        delivery_address__country_code: `=${(options || [])
          .map(country => country.code)
          .join("|")}`
      })
    })
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatPaymentMethodId(cell, row, rowIndex, formatExtraData) {
    return this.state.paymentMethods[cell]
  }

  formatDeliveryMethodId(cell, row, rowIndex, formatExtraData) {
    return this.state.deliveryMethods[cell]
  }

  formatTotalPriceVAT(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  getDefaultColumns() {
    return [{
      text: "Č. zakázky",
      dataField: "sale_no",
      formatter: this.formatSaleNo.bind(this),
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale_no ? `${this.state.params.sale_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale_no ? "bg-green" : ""}`,
      sort: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      formatter: this.formatPaymentMethodId.bind(this),
      formatExtraData: this.state.paymentMethods,
      filter: selectFilter({
        options: this.state.paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
      sort: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Způsob dopravy",
      dataField: "delivery_method_id",
      formatter: this.formatDeliveryMethodId.bind(this),
      formatExtraData: this.state.deliveryMethods,
      filter: selectFilter({
        options: this.state.deliveryMethods,
        placeholder: "Způsob dopravy",
        defaultValue: this.state.params.delivery_method_id || "",
        getFilter: filter => this.filters.delivery_method_id = filter,
      }),
      headerClasses: () => this.state.params.delivery_method_id ? "bg-green" : "",
      sort: true,
      footer: "",
      footerClasses: "bg-info",
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      formatter: this.formatTotalPriceVAT.bind(this),
      sort: true,
      footer: "total_price_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total_price_vat,
    },]
  }
}
