import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory from "react-bootstrap-table2-filter"
import axios from "axios"
import moment from "moment"
import {NavLink} from "react-router-dom"
import DatePicker from "react-datepicker"
import Select from "react-select"
import API from "../../API"
import {getParams} from "../../Utils"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import Grid from "../../Base/Grid"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ExportButton from "../../Base/ExportButton"
import paidInvoicesExportColumn from "../../fixtures/paidInvoicesExportColumn.json"
import CONTACT_TYPE from "../../Base/Enum/ContactType"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import OrderTypeFilter from "../../Base/OrderTypeFilter"

export default class PaidInvoicesList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      meta: {},
      total: 0,
      customerTypes: {},
      countries: [],
      deliveryMethods: [],
      _columns: [],
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        date_start: moment().subtract(1, "days").format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        country__code: "",
        sale__delivery_method_id: "",
        order_type_id: "=default|refund|return",
      }, params),
    }

    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }
    this.defaultParams = [
      "date_start",
      "date_end",
      "country__code",
    ]
    this.filters = {}
    this.appUrl = "/reports/sets/paid-invoices"
    this.apiUrl = "/invoices/paid"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-group"}
          >
            <label>Datum od:</label>
            <DatePicker
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.date_start).toDate()}
              onChange={this.handleDateStartChange.bind(this)}
              dateFormat={"dd. MM. yyyy"}
            />
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-group"}
          >
            <label>Datum do:</label>
            <DatePicker
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.date_end).toDate()}
              onChange={this.handleDateEndChange.bind(this)}
              dateFormat={"dd. MM. yyyy"}
            />
          </div>
        </div>

        <div
          className={"col-lg-3"}
        >
          <label>Typ:</label>

          <OrderTypeFilter
            orderTypeId={this.state.params.order_type_id || ""}
            status={this.state.params.status || ""}
            orderTypeFilterChangeHandler={this.handleOrderTypeFilterChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-5 text-right mb-3 mt-lg-4"}
        >
          <ExportButton
            exportName={"paidInvoices"}
            fileName={"zaplacene-faktury"}
            params={this.state.params}
            columns={paidInvoicesExportColumn}
            apiUrl={"/invoices/paid"}
          />
        </div>

        <div
          className={"col-lg-5 mb-3"}
        >
          <label>Země:</label>
          <Select
            value={this.state.countries.filter(country => this.state.params.country__code.indexOf(country.code) > -1)}
            placeholder={"Země"}
            isMulti={true}
            options={this.state.countries}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCountryCodeChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-4"}
        >
          <label>Způsob dopravy:</label>
          <Select
            value={this.state.deliveryMethods.filter(deliveryMethod => this.state.params.sale__delivery_method_id.indexOf(deliveryMethod.id) > -1)}
            placeholder={"Způsob dopravy"}
            isMulti={true}
            options={this.state.deliveryMethods}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleDeliveryMethodChange.bind(this)}
          >
          </Select>
        </div>

        <div
          className={"col-lg-3 mb-3 mt-lg-4"}
        >
          <button
            className={"btn btn-primary"}
            onClick={this.getData.bind(this)}
          >Zobrazit přehled
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  // overidden
  _getData() {
    axios.all([
      this.getCustomerTypes(),
      this.getCountries(),
      this.getDeliveryMethods(),
    ]).then(axios.spread((customerTypesRes, countriesRes, deliveryMethodsRes) => {
      let customerTypes = {}

      let countries = countriesRes.data.objects.map(country => Object.assign({}, country, {
        label: country.code,
        value: country.code,
      }))

      customerTypesRes.data.objects.forEach(customerType => {
        customerTypes[customerType.id] = customerType.name
      })

      let deliveryMethods = deliveryMethodsRes.data.objects.map(deliveryMethod => Object.assign({}, deliveryMethod, {
        label: deliveryMethod.name,
        value: deliveryMethod.id,
      }))

      this.setState({
        countries: countries,
        customerTypes: customerTypes,
        deliveryMethods: deliveryMethods,
        params: Object.assign({}, this.state.params, {
          country__code: `=${countries.map(country => country.code).join("|")}`,
          sale__delivery_method_id: `=${deliveryMethods.map(deliveryMethod => deliveryMethod.id).join("|")}`,
        })
      }, this.getData)
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD"),
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD"),
      })
    })
  }

  handleCountryCodeChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        country__code: `=${(options || [])
          .map(country => country.code)
          .join("|")}`
      })
    })
  }

  handleDeliveryMethodChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        sale__delivery_method_id: `=${(options || [])
          .map(deliveryMethod => deliveryMethod.id)
          .join("|")}`
      })
    })
  }

  handleOrderTypeFilterChange(obj) {
    this.setState({
      params: Object.assign({}, this.state.params, obj)
    })
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.customer_no}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.surname : ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.name : ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.sale_id}/detail/`}
      >{row.sale.sale_no}</NavLink>
    )
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.sale_id}/detail/${row.id}`}
        className={"nowrap"}
      >{cell}</NavLink>
    )
  }

  formatTotalPriceVat(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  formatPaidDate(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatDueAt(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatHasRefundOrReturn(cell, row, rowIndex, formatExtraData) {
    return cell ? "Ano" : "Ne"
  }

  formatSponsorNo(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return (
        <NavLink
          to={`/customers/${row.customer.sponsor.id}/detail`}
        >{row.customer.sponsor.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatSponsorSurname(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return row.customer.sponsor.surname || ""
    }

    return ""
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return row.customer.sponsor.name || ""
    }

    return ""
  }

  formatSponsorType(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return this.state.customerTypes[row.customer.sponsor.type_id]
    }

    return ""
  }

  formatSponsorPhone(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor &&
      row.customer.sponsor.contact
    ) {
      return row.customer.sponsor.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.TARIFF_02 ||
          contact.type_id === CONTACT_TYPE.PHONE_HOME ||
          contact.type_id === CONTACT_TYPE.PHONE_WORK ||
          contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
          contact.type_id === CONTACT_TYPE.PHONE_MOBILE
        )
        .map(contact => (
          <a
            href={`tel:${contact.value}`}
            className={"d-block"}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatDeliveryMethodId(cell, row, rowIndex, formatExtraData) {
    if (row.sale) {
      let deliveryMethod = this.state.deliveryMethods.find(deliveryMethod => deliveryMethod.id === row.sale.delivery_method_id)

      if (deliveryMethod) {
        return deliveryMethod.name
      }
    }

    return ""
  }

  formatCountry(cell, row, rowIndex, formatExtraData) {
    return row.country.name
  }

  getDefaultColumns() {
    return [{
      text: "Č. zák.",
      dataField: "customer__customer_no",
      formatter: this.formatCustomerNumber.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      formatter: this.formatCustomerSurname.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      formatter: this.formatCustomerName.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. zakázky",
      dataField: "sale__sale_no",
      formatter: this.formatSaleNo.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. faktury",
      dataField: "invoice_no",
      formatter: this.formatOrder.bind(this),
      visible: true,
      alwaysVisible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      formatter: this.formatTotalPriceVat.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.total_price_vat || 0,
    }, {
      text: "Základ daně 21 %",
      dataField: "vat21_base",
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.vat21_base || 0,
    }, {
      text: "DPH 21 %",
      dataField: "vat21_value",
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.vat21_value || 0,
    }, {
      text: "Základ daně 15 %",
      dataField: "vat15_base",
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.vat15_base || 0,
    }, {
      text: "DPH 15 %",
      dataField: "vat15_value",
      footer: "",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums.vat15_value || 0,
    }, {
      text: "Datum zadání",
      dataField: "created_at",
      sort: true,
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Datum uhrazení",
      dataField: "paid_date",
      formatter: this.formatPaidDate.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Splatnost",
      dataField: "due_days",
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Datum splatnosti",
      dataField: "due_at",
      formatter: this.formatDueAt.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Obsahuje V/D",
      dataField: "has_refund_or_return",
      formatter: this.formatHasRefundOrReturn.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. sponzora",
      dataField: "customer__sponsor__customer_no",
      formatter: this.formatSponsorNo.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Příjmení sponzora",
      dataField: "customer__sponsor__surname",
      formatter: this.formatSponsorSurname.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno sponzora",
      dataField: "customer__sponsor__name",
      formatter: this.formatSponsorName.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Typ sponzora",
      dataField: "customer__sponsor__type_id",
      formatter: this.formatSponsorType.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Telefon sponzora",
      dataField: "customer__sponsor__phone_contact__value",
      formatter: this.formatSponsorPhone.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Země",
      dataField: "country__id",
      formatter: this.formatCountry.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Doprava",
      dataField: "sale__delivery_method_id",
      formatter: this.formatDeliveryMethodId.bind(this),
      visible: true,
      footer: "",
      footerClasses: "bg-info text-white",
    },]
  }
}
