import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarReportsContests = () => {
  return (
    <Switch>
      <Route
        path={"/reports/contests"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/reports/contests/bonuses"}
              className={"nav-link"}
              activeClassName={"active"}
            >Nové bonusy</NavLink>
            <NavLink
              to={"/reports/contests/cup-of-freedom"}
              className={"nav-link"}
              activeClassName={"active"}
            >Pohár svobody</NavLink>
            <NavLink
              to={"/reports/contests/product-king"}
              className={"nav-link"}
              activeClassName={"active"}
            >Produktový král</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarReportsContests
