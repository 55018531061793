import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {NavLink} from "react-router-dom"
import axios from "axios"
import moment from "moment"
import API from "../API"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import formatCustomerName from "../Base/formatCustomerName"
import ORDER_STATUS from "../Base/Enum/OrderStatus"
import SpinnerInline from "../Base/SpinnerInline/SpinnerInline"

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stats: {},
      saleStates: [],
      deliveryMethods: [],
      paymentMethods: [],
      customerTypes: [],
    }
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        {/* left column */}
        <div
          className={"col-lg-4"}
        >
          <div
            className={"jumbotron py-4"}
          >
            <h1>Právě jste se přihlásili do Missiva&nbsp;IS.</h1>
            <p
              className={"lead"}
            >Na této obrazovce najdete základní informační údaje. Pro další práci
              vyberte položku z hlavního menu výše.</p>

            <h4>Objednávky</h4>

            <div
              className={"form-group"}
            >
              <label>Vyhledat objednávku podle čísla faktury:</label>
              <AsyncSelect
                value={{}}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleOrderInvoiceNoChange.bind(this))}
                onChange={this.handleOrderInvoiceNoSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                components={{
                  Option: ReactSelectTableOption
                }}
                styles={ReactSelectCustomStyles}
              />
            </div>

            <NavLink
              to={"/sales/add"}
              className={"btn btn-primary mr-3 mb-3"}
            >Vytvořit novou objednávku</NavLink>

            <NavLink
              to={"/sales/pay"}
              className={"btn btn-primary mr-3 mb-3"}
            >Přehled a úhrada objednávek</NavLink>

            <hr/>

            <h4>Zákazníci</h4>

            <div
              className={"form-group"}
            >
              <label>Vyhledat zákazníka:</label>
              <AsyncSelect
                value={{}}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                onChange={this.handleCustomerSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                components={{
                  Option: ReactSelectTableOption
                }}
                styles={ReactSelectCustomStyles}
              />
            </div>

            <NavLink
              to={"/customers/add"}
              className={"btn btn-primary mr-3 mb-3"}
            >Vytvořit nového zákazníka</NavLink>

            <NavLink
              to={"/customers/tree"}
              className={"btn btn-primary mr-3 mb-3"}
            >Skupinové informace</NavLink>
          </div>
        </div>

        {/* right column */}
        <div
          className={"col-lg-8"}
        >
          <div
            className={"jumbotron py-4"}
          >
            <h4
              className={"bg-info text-white p-2 my-3 font-weight-bold"}
            >Statistika zakázek</h4>

            {!this.state.stats.sale && (
              <div>
                <SpinnerInline/>
              </div>
            )}

            {this.state.stats.sale && this.state.stats.sale
              .filter(sale => sale.data.length > 0)
              .map(sale => (
                <div
                  key={sale.status}
                >
                  <h5
                    className={"font-weight-bold mt-3 mb-0"}
                  >{this.getEnumName("saleStates", sale.status)}</h5>

                  <div
                    className={"row"}
                  >
                    {sale.data.map(saleData => (
                      <div
                        key={saleData.date}
                        className={"col-12"}
                      >
                        {this.getDate(saleData.date)}

                        <table
                          className={"table-layout-fixed w-100"}
                        >
                          {saleData.data.map(saleDataData => (
                            <tr
                              key={saleDataData.delivery_method_id}
                            >
                              <td
                                className={"border border-secondary px-1"}
                              >{this.getEnumName("deliveryMethods", saleDataData.delivery_method_id)}</td>
                              <td
                                className={"border border-secondary px-1"}
                              >{saleDataData.number}&nbsp;ks
                              </td>
                              <td
                                className={"border border-secondary px-1"}
                              >{saleDataData.total_price_vat.replace(".00", "")}&nbsp;Kč
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            }

            <NavLink
              to={"/reports/sets/open-sales"}
              className={"btn btn-primary mt-3"}
            >Zobrazit přehled zakázek</NavLink>

            <h4
              className={"bg-info text-white p-2 my-3 font-weight-bold"}
            >Vyřízeno balíků</h4>

            {!this.state.stats.shipment && (
              <div>
                <SpinnerInline/>
              </div>
            )}

            {this.state.stats.shipment && this.state.stats.shipment
              .filter(shipment => shipment.data.length > 0)
              .map(shipment => (
                <div
                  key={shipment.status}
                  className={"row"}
                >
                  {shipment.data.map(shipmentData => (
                    <div
                      key={shipmentData.date}
                      className={"col-12"}
                    >
                      {this.getDate(shipmentData.date)}

                      <table
                        className={"table-layout-fixed w-100"}
                      >
                        {shipmentData.data.map(shipmentDataData => (
                          <tr
                            key={shipmentDataData.delivery_method_id}
                          >
                            <td
                              className={"border border-secondary px-1"}
                            >{this.getEnumName("deliveryMethods", shipmentDataData.delivery_method_id)}</td>
                            <td
                              className={"border border-secondary px-1"}
                            >{shipmentDataData.number}&nbsp;ks
                            </td>
                            <td
                              className={"border border-secondary px-1"}
                            >{shipmentDataData.total_weight}&nbsp;Kg
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  ))}
                </div>
              ))
            }

            <NavLink
              to={"/reports/sets/order-summary"}
              className={"btn btn-primary mt-3"}
            >Zobrazit přehled zakázek doprava a platba</NavLink>

            <h4
              className={"bg-info text-white p-2 my-3 font-weight-bold"}
            >Statistika objednávek</h4>

            {!this.state.stats.order && (
              <div>
                <SpinnerInline/>
              </div>
            )}

            {this.state.stats.order && this.state.stats.order
              .filter(order => order.data.length > 0)
              .map(order => (
                <div
                  key={order.status}
                >
                  <h5
                    className={"font-weight-bold mt-3 mb-0"}
                  >{ORDER_STATUS[order.status]}</h5>

                  <div
                    className={"row"}
                  >
                    {order.data.map(orderData => (
                      <div
                        key={orderData.date}
                        className={"col-12"}
                      >
                        {this.getDate(orderData.date)}

                        <table
                          className={"table-layout-fixed w-100"}
                        >
                          {orderData.data.map(orderDataData => (
                            <tr
                              key={orderDataData.payment_method_id}
                            >
                              <td
                                className={"border border-secondary px-1"}
                              >{this.getEnumName("paymentMethods", orderDataData.payment_method_id)}</td>
                              <td
                                className={"border border-secondary px-1"}
                              >{orderDataData.number}&nbsp;ks
                              </td>
                              <td
                                className={"border border-secondary px-1"}
                              >{orderDataData.total_price_vat.replace(".00", "")}&nbsp;Kč
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            }

            <NavLink
              to={"/reports/sets/profits"}
              className={"btn btn-primary mt-3"}
            >Zobrazit přehled denních tržeb</NavLink>

            <h4
              className={"bg-info text-white p-2 my-3 font-weight-bold"}
            >Počet nových zákazníků</h4>

            {!this.state.stats.customer && (
              <div>
                <SpinnerInline/>
              </div>
            )}

            {this.state.stats.customer && (
              <div
                className={"row"}
              >
                {this.state.stats.customer.created.map(item => (
                  <div
                    className={"col-3"}
                    key={item.date}
                  >
                    {this.getDate(item.date)}: {item.total}
                  </div>
                ))}
              </div>
            )}

            <h4
              className={"bg-info text-white p-2 my-3 font-weight-bold"}
            >Počet zákazníků celkem</h4>

            {!this.state.stats.customer && (
              <div>
                <SpinnerInline/>
              </div>
            )}

            {this.state.stats.customer && (
              <div
                className={"row"}
              >
                {this.state.stats.customer.total.map(item => (
                  <div
                    className={"col-3"}
                    key={item.type_id}
                  >
                    <span
                      className={"text-secondary text-bold"}
                    >{this.getEnumName("customerTypes", item.type_id)}:</span> {item.total}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  getData() {
    axios.all([
      this.getSaleStates(),
      this.getDeliveryMethods(),
      this.getPaymentMethods(),
      this.getCustomerTypes(),
    ]).then(axios.spread((
      saleStatusRes,
      deliveryMethodsRes,
      paymentMethodsRes,
      customerTypesRes,
    ) => {
      this.setState({
        saleStates: saleStatusRes.data.objects,
        deliveryMethods: deliveryMethodsRes.data.objects,
        paymentMethods: paymentMethodsRes.data.objects,
        customerTypes: customerTypesRes.data.objects,
      }, () => {
        API.get("/dashboard?days=5")
          .then(({data}) => {
            this.setState({
              stats: data.stats,
            })
          })
      })
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  handleCustomerChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleCustomerSelect(option) {
    this.props.history.push(`/customers/${option.id}/detail`)
  }

  handleOrderInvoiceNoChange(val, cb) {
    API.get(`/orders?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${item.invoice_no}|${item.sale.sale_no}|${item.customer.surname || ""} ${item.customer.name || ""}|${item.billing_address_street}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleOrderInvoiceNoSelect(option) {
    this.props.history.push(`/sales/${option.sale_id}/detail/${option.invoice_no}`)
  }

  getEnumName(name, id) {
    let item = this.state[name].find(obj => obj.id === id)

    if (item) {
      return item.name
    }

    return ""
  }

  formatDate(date) {
    return ""
  }

  getDate(date) {
    let formattedDate = moment(date).format("DD. MM. YYYY")
    let today = moment().format("YYYY-MM-DD")

    return (
      <span
        className={
          (date === today
              ? "text-white bg-warning px-2"
              : "text-secondary"
          ) + " text-bold mt-2 d-inline-block"
        }
      >{formattedDate}</span>
    )
  }
}
