import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import moment from "moment"
import API from "../API"
import ListTable from "../Base/ListTable"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class SaleTracking extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sale: {},
      isLoading: true,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getData()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            className={"btn btn-primary"}
            to={`/sales/${this.state.sale.id}/detail`}
          >Zpět na detail zakázky</NavLink>
        </div>
        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state.sale.shipment && this.state.sale.shipment.length > 0
            ? this.state.sale.shipment.map(shipment => {
              return shipment.boxes.map(box => {
                return (
                  <div
                    key={box.shipment_no}
                  >
                    <table
                      className={"table mb-0"}
                    >
                      <thead>
                      <tr>
                        <th
                          colSpan={4}
                        >Zásilka {box.shipment_no}</th>
                      </tr>
                      </thead>
                    </table>

                    <ListTable
                      columns={this.getTableColumns()}
                      rows={box.history.reverse()}
                    />
                  </div>
                )
              })
            })
            : <ListTable
              columns={this.getTableColumns()}
              rows={[]}
            />
          }
        </div>
      </div>
    )
  }

  getData() {
    API.get(`/shipment/track/${this.id}`)
      .then(({data}) => {
        this.setState({
          sale: data
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  getTableColumns() {
    return [{
      text: "Datum a čas",
      key: ["status_at"],
      format: this.formatStatusAt.bind(this),
    }, {
      text: "Místo",
      key: ["description"],
    }, {
      text: "PSČ",
      key: ["location"],
    }, {
      text: "Poznámka",
      key: ["message"],
    },]
  }

  formatStatusAt(statusAt) {
    return moment(statusAt, "YYYY-MM-DD HH-mm-ss").format("DD. MM. YYYY HH:mm:ss")
  }
}
