import React from "react"
import Spinner from "../Spinner/Spinner"

const SpinnerInButton = () => (
  <Spinner
    styles={{
      width: 20,
      height: 20,
      float: "right",
      marginLeft: 15,
    }}
  />
)

export default SpinnerInButton
