import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import CommissionList from "./Commission/CommissionList"
import RentList from "./Rents/RentList"
import OrderSummaryList from "./OrderSummary/OrderSummaryList"
import TurnOverList from "./TurnOver/TurnOverList"
import BonusesList from "./Bonuses/BonusesList"
import CupOfFreedomList from "./CupOfFreedom/CupOfFreedomList"
import ProductKing from "./ProductKing/ProductKing"
import ProductKingTemplateList from "./ProductKing/ProductKingTemplateList"
import ProductKingTemplateForm from "./ProductKing/ProductKingTemplateForm"
// import SaleList from "./Sales/SaleList"
import ProfitsList from "./Profits/ProfitsList"
import CustomerPointsList from "./CustomerPoints/CustomerPointsList"
import OpenSaleList from "./OpenSales/OpenSaleList"
import UnpaidOrderList from "./UnpaidOrders/UnpaidOrderList"
import InvoicesList from "./Invoices/InvoicesList"
import PaidInvoicesList from "./PaidInvoices/PaidInvoicesList"
import RefundsAndReturnsList from "./RefundAndReturns/RefundsAndReturnsList"
import ProductsList from "./Products/ProductsList"
import SponsorOrdersList from "./SponsorOrders/SponsorOrdersList"
import UsedPackagesList from "./UsedPackages/UsedPackagesList"
import RequiredProductsList from "./RequiredProducts/RequiredProductsList"

export default class Reports extends Component {
  componentDidMount(prevProps, prevState, snapshot) {
    this.handleRedirects()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.handleRedirects()
  }

  render() {
    return (
      <Switch>
        {/* SETS */}
        <Route
          path={"/reports/sets/order-summary/:page?"}
          component={OrderSummaryList}
        />
        <Route
          path={"/reports/sets/profits"}
          component={ProfitsList}
        />
        <Route
          path={"/reports/sets/turn-over/:page?"}
          component={TurnOverList}
        />
        {/*<Route*/}
        {/*  path={"/reports/sets/sales"}*/}
        {/*  component={SaleList}*/}
        {/*/>*/}
        <Route
          path={"/reports/sets/open-sales"}
          component={OpenSaleList}
        />
        <Route
          path={"/reports/sets/unpaid-orders"}
          component={UnpaidOrderList}
        />
        <Route
          path={"/reports/sets/invoices"}
          component={InvoicesList}
        />
        <Route
          path={"/reports/sets/paid-invoices"}
          component={PaidInvoicesList}
        />
        <Route
          path={"/reports/sets/refunds-and-returns"}
          component={RefundsAndReturnsList}
        />
        <Route
          path={"/reports/sets/products"}
          component={ProductsList}
        />
        <Route
          path={"/reports/sets/sponsor-orders"}
          component={SponsorOrdersList}
        />
        <Route
          path={"/reports/sets/used-packages"}
          component={UsedPackagesList}
        />
        <Route
          path={"/reports/sets/required-products"}
          component={RequiredProductsList}
        />
        {/* COMMISSIONS */}
        <Route
          path={"/reports/commissions/commissions"}
          component={CommissionList}
        />
        <Route
          path={"/reports/commissions/rents/:page?"}
          component={RentList}
        />
        <Route
          path={"/reports/commissions/customer-points"}
          component={CustomerPointsList}
        />
        {/* CONTESTS */}
        <Route
          path={"/reports/contests/bonuses"}
          component={BonusesList}
        />
        <Route
          path={"/reports/contests/cup-of-freedom"}
          component={CupOfFreedomList}
        />
        <Route
          path={"/reports/contests/product-king/templates/add"}
          component={ProductKingTemplateForm}
        />
        <Route
          path={"/reports/contests/product-king/templates/:id/detail"}
          component={ProductKingTemplateForm}
        />
        <Route
          path={"/reports/contests/product-king/templates"}
          component={ProductKingTemplateList}
        />
        <Route
          path={"/reports/contests/product-king"}
          component={ProductKing}
        />
      </Switch>
    )
  }

  handleRedirects() {
    if (this.props.location.pathname === "/reports") {
      this.props.history.push("/reports/sets")
    }
  }
}
