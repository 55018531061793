import React, {Component} from "react"
import {
  COUNTRY_ID,
  CUSTOMER_TYPE_ID,
  DELIVERY_METHOD_ID,
  ORDER_METHOD_ID,
  ORDER_STATE_ID,
  PAYMENT_METHOD_ID,
  SALE_STATE_ID,
  SORT_KEY,
  VALUE_KEY
} from "./fixtures.js"
import styles from "./styles.module.css"

export default class GridParams extends Component {
  constructor(props) {
    super(props)
    this.hiddenParams = ["limit", "offset", "customer_tree_parent", "customer_tree_date"]
  }

  render() {
    return (
      <div
        className={"col-12"}
      >
        {Object.keys(this.props.params)
          .filter(key => this.hiddenParams.indexOf(key) === -1)
          .filter(key => this.props.params[key])
          .filter(key => this.props.params[key] !== "!created_open")
          .map(key => (
            <div
              key={key}
              className={`badge ${key === "sort" ? styles.sortBadge : styles.filterBadge} border p-2 mr-3 mb-3`}
            >
              {this.normalizeKey(key)}
              {this.normalizeEquality(key, this.props.params[key])}
              {this.normalizeValue(key, this.props.params[key])}
            </div>
          ))}
      </div>
    )
  }

  normalizeKey(key) {
    key = key
      .replace("_1", "")
      .replace("-2", "")

    if (VALUE_KEY.hasOwnProperty(key)) {
      return VALUE_KEY[key]
    }

    return key
  }

  normalizeEquality(key, value) {
    if (key === "sort") {
      if (value.indexOf("-") === 0) {
        return <span> &#8595; </span>
      }

      return <span> &#8593; </span>
    } else if (typeof value === "string") {
      if (value.indexOf("!") === 0) {
        return <span> &ne; </span>
      }

      if (value.indexOf("<=") === 0) {
        return <span> &#8804; </span>
      }

      if (value.indexOf(">=") === 0) {
        return <span> &#8805; </span>
      }

      if (value.indexOf("-") === 0) {
        return <span> &#8805; </span>
      }
    }

    return <span>: </span>
  }

  normalizeValue(key, value) {
    if (typeof value === "string") {
      value = value
        .replace(">=", "")
        .replace("<=", "")
        .replace(/^[=!\-^]/, "")
    }

    if (key === "status" || key === "status_1") {
      if (window.location.hash.indexOf("#/sales/pay") > -1) {
        return ORDER_STATE_ID[value]
      }

      return SALE_STATE_ID[value]
    }

    if (key === "delivery_method_id") {
      return DELIVERY_METHOD_ID[value]
    }

    if (key === "payment_method_id") {
      return PAYMENT_METHOD_ID[value]
    }

    if (key === "order_method_id") {
      return ORDER_METHOD_ID[value]
    }

    if (key === "sort") {
      return SORT_KEY[value]
    }

    if (key === "type_id") {
      return CUSTOMER_TYPE_ID[value]
    }

    if (key === "country_id") {
      return COUNTRY_ID[value]
    }

    if (value === "0") {
      return "Ne"
    }

    if (value === "1") {
      return "Ano"
    }

    return value
  }
}
