import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {textFilter, selectFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import {NavLink} from "react-router-dom"
import Grid from "../Base/Grid"
import {getParams} from "../Utils"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import GridMenu from "../Base/GridMenu"
import API from "../API"

export default class BoxList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        status: "=packed|unpaid|paid",
      }, params),
      _columns: [],
      _saleStates: [],
      _deliveryMethods: [],
    }

    this.appUrl = "/sales/boxes"
    this.apiUrl = "/sales"
    this.filters = {}
    this.defaultParams = ["status"]
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          <div
            className={"col-12 mb-3"}
          >
            <GridMenu
              toggleColumnHandler={this.toggleColumn.bind(this)}
              resetFiltersHandler={this.resetFilters.bind(this)}
              columns={this.state._columns}
            />
          </div>

          <div
            className={"col-12"}
          >
            {this.state.isLoading && <SpinnerWithOverlay/>}

            {this.state._columns.length > 0 && (
              <ScrollableTable>
                <BootstrapTable
                  bootstrap4={true}
                  remote={true}
                  striped={true}
                  hover={true}
                  keyField={"id"}
                  columns={this.state._columns.filter(col => col.visible)}
                  data={this.state.data}
                  onTableChange={this.handleTableChange.bind(this)}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    page: (this.state.params.offset / this.state.params.limit) + 1,
                    sizePerPage: this.state.params.limit,
                    totalSize: this.state.total
                  })}
                  noDataIndication={"Nic nenalezeno"}
                />
              </ScrollableTable>
            )}
          </div>
        </div>
      </>
    )
  }

  _getData() {
    axios.all([
      this.getSaleStates(),
      this.getDeliveryMethods(),
    ]).then(axios.spread((saleStateRes, deliveryMethodsRes) => {
      let saleStates = {}
      let deliveryMethods = {}

      saleStateRes.data.objects.forEach(state => {
        saleStates[state.id] = state.name
      })

      deliveryMethodsRes.data.objects.forEach(deliveryMethod => {
        deliveryMethods[deliveryMethod.id] = deliveryMethod.name
      })

      this.setState({
        _saleStates: saleStates,
        _deliveryMethods: deliveryMethods,
      }, this.getData.bind(this))
    }))
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }


  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell]
  }

  formatDeliveryMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell]
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatBoxNo(cell, row, rowIndex, formatExtraData) {
    return row.shipment.map(shipment => shipment.boxes.map(box => (
      <div
        key={box.shipment_no}
      >{box.shipment_no}</div>
    )))
  }

  formatCustomerNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.customer_no}</NavLink>
    )
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer.name || ""
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.surname || ""}</NavLink>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Status",
      dataField: "status",
      visible: true,
      formatter: this.formatStatus.bind(this),
      formatExtraData: this.state._saleStates,
    }, {
      text: "Č. balíku",
      dataField: "shipment_box__shipment_no",
      formatter: this.formatBoxNo.bind(this),
      filter: textFilter({
        placeholder: "Č. balíku",
        defaultValue: this.state.params.shipment_box__shipment_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.shipment_box__shipment_no = filter,
      }),
      headerClasses: () => this.state.params.shipment_box__shipment_no ? "bg-green" : "",
      visible: true,
    }, {
      text: "Č. zakázky",
      dataField: "sale_no",
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale_no || "",
        getFilter: filter => this.filters.sale_no = filter,
      }),
      headerClasses: () => this.state.params.sale_no ? "bg-green" : "",
      formatter: this.formatSaleNo.bind(this),
      visible: true,
    }, {
      text: "Způsob doručení",
      dataField: "delivery_method_id",
      visible: true,
      filter: selectFilter({
        options: this.state._deliveryMethods,
        placeholder: "Způsob doručení",
        defaultValue: this.state.params.delivery_method_id || "",
        getFilter: filter => this.filters.delivery_method_id = filter,
      }),
      headerClasses: () => this.state.params.delivery_method_id ? "bg-green" : "",
      formatter: this.formatDeliveryMethod.bind(this),
      formatExtraData: this.state._deliveryMethods,
    }, {
      text: "Č. zák.",
      dataField: "customer__customer_no",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer__customer_no ? `${this.state.params.customer__customer_no}`.replace("=", "") : "",
        getFilter: filter => this.filters.customer__customer_no = filter,
      }),
      headerClasses: () => this.state.params.customer__customer_no ? "bg-green" : "",
      formatter: this.formatCustomerNo.bind(this),
      visible: true,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => this.state.params.customer__surname ? "bg-green" : "",
      formatter: this.formatCustomerSurname.bind(this),
      visible: true,
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      filter: textFilter({
        placeholder: "Jméno zák.",
        defaultValue: this.state.params.customer__name || "",
        getFilter: filter => this.filters.customer__name = filter,
      }),
      headerClasses: () => this.state.params.customer__name ? "bg-green" : "",
      formatter: this.formatCustomerName.bind(this),
      sort: true,
      visible: true,
    },]
  }
}
