import React, {Component, Fragment} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"

export default class UnitForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: ""
    }
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getUnit()
    }
  }

  render() {
    return (
      <Fragment>
        <div
          className={"mb-3"}
        >
          <NavLink
            to={"/enums/units/add"}
            className={"btn btn-primary mr-3"}
          >Přidat měrnou jednotku</NavLink>
          {this.id && <button
            className={"btn btn-danger mr-3 float-right"}
            onClick={this.handleDelete.bind(this)}
          >Smazat měrnou jednotku</button>}
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-5"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group"}
              >
                <label>Název jednotky:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder={"Název jednotky"}
                  required={true}
                  onChange={this.handleNameChange.bind(this)}
                  value={this.state.name}
                  minLength={1}
                  maxLength={30}
                />
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  addUnit() {
    API.post("/unitOfMeasures", this.state)
      .then(() => {
        this.props.history.push("/enums/units")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/units")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateUnit()
    } else {
      this.addUnit()
    }
  }

  getUnit() {
    API.get(`/unitOfMeasures/${this.id}`)
      .then((res) => {
        let {name} = res.data
        this.setState({
          name: name
        })
      })
  }

  updateUnit() {
    API.put(`/unitOfMeasures/${this.id}`, this.state)
      .then(() => {
        this.props.history.push("/enums/units")
      })
  }

  handleDelete() {
    API.delete(`/unitOfMeasures/${this.id}`)
      .then(() => {
        this.props.history.push("/enums/units")
      })
  }
}
