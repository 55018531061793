const COUNTRY = {
  CZECH_REPUBLIC: 1,
  SLOVAK_REPUBLIC: 2,
  POLAND: 3,
  GERMANY: 4,
  AUSTRIA: 5,
  NETHERLANDS: 6,
  SPAIN: 7,
  SLOVENIA: 8
}

export default COUNTRY
