import React from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {textFilter, selectFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import Grid from "../Base/Grid"
import GridMenu from "../Base/GridMenu"
import API from "../API"
import {getParams} from "../Utils"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable.js"
import ExportButton from "../Base/ExportButton"
import productExportColumn from "../fixtures/productExportColumn"
import COUNTRY from "../Base/Enum/Countries"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class ProductList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "code",
      }),
      _unitOfMeasures: {},
      _categories: {},
      _columns: [],
    }
    this.appUrl = "/products"
    this.apiUrl = "/products"
    this.defaultParams = ["sort"]
    this.boolOptions = {
      0: "Ne",
      1: "Ano",
    }
    this.statusOptions = {
      active: "Aktivní",
      deleted: "Smazaný",
    }
    this.filters = {}
    this.defaultSorted = {
      dataField: "code",
      order: "asc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-6 mb-3"}
        >
          <NavLink
            to={"/products/add"}
            className={"btn btn-primary mr-3"}
          >Nový produkt</NavLink>
        </div>

        <div
          className={"col-6 mb-3 text-right"}
        >
          <ExportButton
            exportName={"products"}
            fileName={"Produkty"}
            params={this.state.params}
            columns={productExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-12 mb-3"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          />
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  formatProductCode(cell, row, rowIndex, formatExtraData) {
    return (
      <div>
        <NavLink
          to={`/products/${row.id}/detail`}
        >{cell}</NavLink>
        {row.discount_group.length > 0 && (
          <i
            className={"fas fa-piggy-bank ml-1 text-danger "}
            title={"Produkt ve slevě nebo v akci"}
          />
        )}
      </div>
    )
  }

  formatProductName(cell, row, rowIndex, formatExtraData) {
    let name = ""
    let names = row.names.filter(name => name.country_id === COUNTRY.CZECH_REPUBLIC)
    if (names.length > 0)
      name = names[0].name

    return (
      <>
        {name}
        {row.notes && row.notes.some(note => note.is_extended) && (
          <span
            className={"fab fa-product-hunt ml-1 toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.notes
                .filter(note => note.is_extended)
                .map(note => (
                  <div
                    key={note.id}
                  >{note.note}</div>
                ))}
            </span>
          </span>
        )}
        {row.notes && row.notes.some(note => !note.is_extended) && (
          <span
            className={"fas fa-info-circle ml-1 toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.notes
                .filter(note => !note.is_extended)
                .map(note => (
                  <div
                    key={note.id}
                  >{note.note}</div>
                ))}
            </span>
          </span>
        )}
      </>
    )
  }

  formatUnitOfMeasures(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatBoolColumn(cell, row, rowIndex, formatExtraData) {
    return cell ? "ano" : "ne";
  }

  formatStatusColumn(cell, row, rowIndex, formatExtraData) {
    return this.statusOptions[cell] || ""
  }

  formatCategories(cell, row, rowindex, formatExtraData) {
    return row.categories.map(category => { // product can be in multiple categories
      return category.map(subcategory => ( // show all subcategories
        <div
          key={subcategory.category.id}
        >{subcategory.category.name}</div>
      ))
    })
  }

  getDefaultColumns() {
    return [{
      text: "Kód",
      dataField: "code",
      filter: textFilter({
        placeholder: "Kód",
        defaultValue: this.state.params.code || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.code = filter,
      }),
      headerClasses: () => this.state.params.code ? "bg-green" : "",
      sort: true,
      formatter: this.formatProductCode.bind(this),
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Název",
      dataField: "product_name__name",
      filter: textFilter({
        placeholder: "Název",
        defaultValue: this.state.params.product_name__name || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.product_name__name = filter,
      }),
      headerClasses: () => this.state.params.product_name__name ? "bg-green" : "",
      sort: true,
      formatter: this.formatProductName.bind(this),
      visible: true,
    }, {
      text: "Měrná jednotka",
      dataField: "unit_of_measure_id",
      filter: selectFilter({
        options: this.state._unitOfMeasures,
        placeholder: "Měrná jednotka",
        defaultValue: this.state.params.unit_of_measure_id || "",
        getFilter: filter => this.filters.unit_of_measure_id = filter,
      }),
      headerClasses: () => this.state.params.unit_of_measure_id ? "bg-green" : "",
      formatter: this.formatUnitOfMeasures.bind(this),
      formatExtraData: this.state._unitOfMeasures,
      visible: true,
    }, {
      text: "Počet jednotek",
      dataField: "unit_count",
      filter: textFilter({
        placeholder: "Počet jednotek",
        defaultValue: this.state.params.unit_count || "",
        getFilter: filter => this.filters.unit_count = filter,
      }),
      headerClasses: () => this.state.params.unit_count ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Váha",
      dataField: "weight",
      filter: textFilter({
        placeholder: "Váha",
        defaultValue: this.state.params.weight || "",
        getFilter: filter => this.filters.weight = filter,
      }),
      headerClasses: () => this.state.params.weight ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Váha bez obalu",
      dataField: "weight_net",
      filter: textFilter({
        placeholder: "Váha bez obalu",
        defaultValue: this.state.params.weight_net || "",
        getFilter: filter => this.filters.weight_net = filter,
      }),
      headerClasses: () => this.state.params.weight_net ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Cena",
      dataField: "price",
      filter: textFilter({
        placeholder: "Cena",
        defaultValue: this.state.params.price || "",
        getFilter: filter => this.filters.price = filter,
      }),
      headerClasses: () => this.state.params.price ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "DPH",
      dataField: "vat_rate",
      filter: textFilter({
        placeholder: "DPH",
        defaultValue: this.state.params.vat_rate || "",
        getFilter: filter => this.filters.vat_rate = filter,
      }),
      headerClasses: () => this.state.params.vat_rate ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Body",
      dataField: "points",
      filter: textFilter({
        placeholder: "Body",
        defaultValue: this.state.params.points || "",
        getFilter: filter => this.filters.points = filter,
      }),
      headerClasses: () => this.state.params.points ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Balíček",
      dataField: "box",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Balíček",
        defaultValue: this.state.params.box || "",
        getFilter: filter => this.filters.box = filter,
      }),
      headerClasses: () => this.state.params.box ? "bg-green" : "",
      visible: true,
    }, {
      text: "Ekologicky šetrný výrobek",
      dataField: "ecological",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Ekologicky šetrný výrobek",
        defaultValue: this.state.params.ecological || "",
        getFilter: filter => this.filters.ecological = filter,
      }),
      headerClasses: () => this.state.params.ecological ? "bg-green" : "",
      visible: true,
    }, {
      text: "Použít cenové kategorie",
      dataField: "use_price_category",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Použít cenové kategorie",
        defaultValue: this.state.params.use_price_category || "",
        getFilter: filter => this.filters.use_price_category = filter,
      }),
      headerClasses: () => this.state.params.use_price_category ? "bg-green" : "",
      visible: true,
    }, {
      text: "Viditelnost v objednávce is.missiva.cz",
      dataField: "is_is_order_visible",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Viditelnost v objednávce is.missiva.cz",
        defaultValue: this.state.params.is_is_order_visible || "",
        getFilter: filter => this.filters.is_is_order_visible = filter,
      }),
      headerClasses: () => this.state.params.is_is_order_visible ? "bg-green" : "",
      visible: true,
    }, {
      text: "Viditelnost v objednávce moje.missiva.cz",
      dataField: "is_my_order_visible",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Viditelnost v objednávce moje.missiva.cz",
        defaultValue: this.state.params.is_my_order_visible || "",
        getFilter: filter => this.filters.is_my_order_visible = filter,
      }),
      headerClasses: () => this.state.params.is_my_order_visible ? "bg-green" : "",
      visible: true,
    }, {
      text: "Viditelnost v ceníku",
      dataField: "is_price_list_visible",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Viditelnost v ceníku",
        defaultValue: this.state.params.is_price_list_visible || "",
        getFilter: filter => this.filters.is_price_list_visible = filter,
      }),
      headerClasses: () => this.state.params.is_price_list_visible ? "bg-green" : "",
      visible: true,
    }, {
      text: "Nepočítat do balného zdarma",
      dataField: "excluded_from_free_shipping",
      formatter: this.formatBoolColumn.bind(this),
      filter: selectFilter({
        options: this.boolOptions,
        placeholder: "Nepočítat do balného zdarma",
        defaultValue: this.state.params.excluded_from_free_shipping || "",
        getFilter: filter => this.filters.excluded_from_free_shipping = filter,
      }),
      headerClasses: () => this.state.params.excluded_from_free_shipping ? "bg-green" : "",
      visible: true,
    }, {
      text: "Stav",
      dataField: "status",
      formatter: this.formatStatusColumn.bind(this),
      filter: selectFilter({
        options: this.statusOptions,
        placeholder: "Stav",
        defaultValue: this.state.params.status || "",
        getFilter: filter => this.filters.status = filter,
      }),
      headerClasses: () => this.state.params.status ? "bg-green" : "",
      visible: true,
    }, {
      text: "Kategorie",
      dataField: "branch",
      formatter: this.formatCategories.bind(this),
      filter: selectFilter({
        options: this.state._categories,
        placeholder: "Kategorie",
        defaultValue: this.state.params.branch || "",
      }),
      headerClasses: () => this.state.params.branch ? "bg-light-primary" : "",
      visible: true,
    }]
  }

  _getData() {
    axios.all([
      this.getUnitOfMeasures(),
      this.getCategories(),
    ]).then(axios.spread((unitOfMeasuresRes, categoriesRes) => {
      let unitOfMeasures = {}
      let categories = {}

      unitOfMeasuresRes.data.objects.forEach(unit => {
        unitOfMeasures[unit.id] = unit.name
      })

      let formatCategories = node => {
        if (node.category) {
          categories[node.id] = node.category.name
        }
        if (node.children && node.children.length > 0) {
          node.children.forEach(child => {
            formatCategories(child)
          })
        }
      }

      if (categoriesRes.data.objects.length > 0)
        formatCategories(categoriesRes.data.objects[0])

      this.setState({
        _unitOfMeasures: unitOfMeasures,
        _categories: categories,
      }, this.getData)
    }))
  }

  getUnitOfMeasures() {
    return API.get("/unitOfMeasures?limit=0&offset=0")
  }

  getCategories() {
    return API.get("/productTree?limit=10")
  }
}
