import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import MyProductTreeNode from "./MyProductTreeNode"
import API from "../API"
import RBAC from "../RBAC/RBAC"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import getProductNameByCountry from "../Base/getProductNameByCountry"

export default class MyProductTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      unitOfMeasures: [],
      productId: null,
      clickedNodeId: null,
      _authenticatedCustomer: {},
    }
  }

  componentDidMount() {
    this.getData()
    this.getUnitOfMeasures()
    this.getAuthenticatedCustomer()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 d-print-none"}
        >
          <div
            className={"form-group"}
          >
            <label>Vyhledat produkt:</label>
            <AsyncSelect
              value={{}}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
              onChange={this.handleProductSearchSelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              placeholder={"Vyhledat produkt"}
              styles={ReactSelectCustomStyles}
            />
          </div>
        </div>
        <div
          className={"col-12"}
        >
          <ScrollableTable>
            <table
              className={"table table-bordered table-striped w-100 table-price-list mb-0"}
            >
              <thead>
              <tr>
                <th
                  className={"text-center"}
                >Obj. č.
                </th>
                <th>Název</th>
                <th
                  className={"text-center"}
                >Balení
                </th>
                <th
                  className={"text-center"}
                >Cena
                </th>
                {this.isPointsVisible() && (
                  <th
                    className={"text-center"}
                  >Bod</th>
                )}
                <th
                  className={"text-center"}
                >kg
                </th>
                <th
                  className={"text-center"}
                >DPH
                </th>
              </tr>
              </thead>
              <tbody>

            {/* products */}
            {this.state.data
              .filter(node => node.product_id !== null)
              .filter(node => node.product.is_price_list_visible)
              .sort((a, b) => a.product.code - b.product.code)
              .map(node => (
                <MyProductTreeNode
                  key={node.id}
                  node={node}
                  depth={0}
                  unitOfMeasures={this.state.unitOfMeasures}
                  priceType={this.priceType()}
                  productId={this.state.productId}
                  rowClickHandler={this.handleRowClick.bind(this)}
                  clickedNodeId={this.state.clickedNodeId}
                  isPointsVisible={this.isPointsVisible()}
                />
              ))}

              {/* categories */}
              {this.state.data
                .filter(node => node.category_id !== null)
                .map(node => (
                  <MyProductTreeNode
                    key={node.id}
                    node={node}
                    depth={0}
                    unitOfMeasures={this.state.unitOfMeasures}
                    priceType={this.priceType()}
                    productId={this.state.productId}
                    rowClickHandler={this.handleRowClick.bind(this)}
                    clickedNodeId={this.state.clickedNodeId}
                    isPointsVisible={this.isPointsVisible()}
                  />
                ))}
              </tbody>
            </table>
          </ScrollableTable>
        </div>
      </div>
    )
  }

  getData() {
    API.get("/my/productTree?limit=10")
      .then(({data}) => {
        this.setState({
          data: data.objects,
        })
      })
  }

  getUnitOfMeasures() {
    API.get("/unitOfMeasures?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          unitOfMeasures: data.objects,
        })
      })
  }

  getAuthenticatedCustomer() {
    API.get(`/customers/${RBAC.getUser().customer_id}`)
      .then(({data}) => {
        this.setState({
          _authenticatedCustomer: data,
        })
      })
  }

  priceType() {
    if (this.state._authenticatedCustomer.discount !== 0) {
      return "customer_with_discount"
    }

    if (this.state._authenticatedCustomer.type_id === CUSTOMER_TYPE.RD) {
      return "customer"
    }

    return "consultant"
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}&is_price_list_visible=1`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: getProductNameByCountry(item),
        }))
        cb(data)
      })
  }

  handleProductSearchSelect(option) {
    this.setState({
      productId: option.value,
    })
  }

  handleRowClick(clickedNodeId) {
    this.setState({clickedNodeId})
  }

  isPointsVisible() {
    return this.state._authenticatedCustomer.type_id !== CUSTOMER_TYPE.RD
  }
}
