import React, {Component, Fragment} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"

export default class PostForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      country_id: 1,
      city: "",
      post_code: null,
      _countries: []
    }
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getPost()
    }
    this.getCountries()
  }

  render() {
    return (
      <Fragment>
        <div
          className={"mb-3"}
        >
          <NavLink
            to={"/enums/posts/add"}
            className={"btn btn-primary mr-3"}
          >Přidat poštu</NavLink>
          {this.id && <button
            className={"btn btn-danger mr-3 float-right"}
            onClick={this.handleDelete.bind(this)}
          >Smazat poštu</button>}
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-5"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group"}
              >
                <label>Země:</label>
                <select
                  className={"form-control"}
                  required={true}
                  onChange={this.handleCountryIdChange.bind(this)}
                  value={this.state.country_id}
                >
                  {this.state._countries.map(country => {
                    return (
                      <option
                        key={country.id}
                        value={country.id}
                      >{country.name}</option>
                    )
                  })}
                </select>
              </div>

              <div
                className={"form-group"}
              >
                <label>Město:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder={"Město"}
                  required={true}
                  onChange={this.handleCityChange.bind(this)}
                  value={this.state.city}
                  minLength={2}
                  maxLength={60}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>PSČ:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  id={"post_code"}
                  placeholder={"PSČ"}
                  required={true}
                  onChange={this.handlePostCodeChange.bind(this)}
                  value={this.state.post_code || ""}
                />
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }

  handleCountryIdChange(e) {
    this.setState({country_id: parseInt(e.target.value)})
  }

  handleCityChange(e) {
    this.setState({city: e.target.value})
  }

  handlePostCodeChange(e) {
    this.setState({post_code: e.target.value})
  }

  addPost() {
    let data = Object.assign({}, this.state)
    delete data._countries
    API.post("/posts", data)
      .then(() => {
        this.props.history.push("/enums/posts")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/posts")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updatePost()
    } else {
      this.addPost()
    }
  }

  getPost() {
    API.get(`/posts/${this.id}`)
      .then((res) => {
        this.setState(res.data)
      })
  }

  updatePost() {
    let data = Object.assign({}, this.state)
    delete data._countries
    API.put(`/posts/${this.id}`, data)
      .then(() => {
        this.props.history.push("/enums/posts")
      })
  }

  handleDelete() {
    API.delete(`/posts/${this.id}`)
      .then(() => {
        this.props.history.push("/enums/posts")
      })
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then((res) => {
        this.setState({
          _countries: res.data.objects
        })
      })
  }
}
