import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import Grid from "../Base/Grid"
import {getParams} from "../Utils"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"

export default class LogList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      isLoading: false,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
      _columns: [],
    }

    this.appUrl = "/logs"
    this.apiUrl = "/logs"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  formatEditedValues(cell, row, rowIndex, formatExtraData) {
    return (
      <small
        className={"text-monospace word-break-break-all"}
      >{cell}</small>
    )
  }

  formatExecutionDate(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        className={"nowrap"}
      >{moment(cell).format("DD. MM. YYYY")}</div>
    )
  }

  formatUser(cell, row, rowIndex, formatExtraData) {
    if (cell && cell.email) {
      return cell.email
    }
    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Akce",
      dataField: "action",
    }, {
      text: "Změny",
      dataField: "edited_values",
      formatter: this.formatEditedValues.bind(this)
    }, {
      text: "Změněno",
      dataField: "entity",
    }, {
      text: "Čas",
      dataField: "execution_date",
      formatter: this.formatExecutionDate.bind(this)
    }, {
      text: "Provedl",
      dataField: "user",
      formatter: this.formatUser.bind(this)
    }]
  }
}
