import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import moment from "moment"
import {NavLink} from "react-router-dom"
import Grid from "../Base/Grid"
import API from "../API"
import {getParams} from "../Utils"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import GridDateFilter from "../Base/GridDateFilter"
import GridMenu from "../Base/GridMenu"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import ORDER_STATUS from "../Base/Enum/OrderStatus"

export default class MyOrderList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-created_at",
      }),
      _paymentMethods: {},
      _columns: [],
    }

    this.appUrl = "/my-sales"
    this.apiUrl = "/my/orders"
    this.filters = {}
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={"/my-sales/add"}
            className={"btn btn-primary mr-3"}
          >Nová objednávka</NavLink>
        </div>
        <div
          className={"col-12 mb-3"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          >
            {this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RD &&
            this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RZ &&
            this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RP && (
              <>
                <span
                  className={"text-secondary mr-2"}
                >Zobrazit:</span>
                <button
                  className={`mr-2 btn btn-sm ${this.state.params.customer__customer_no === this.props.authenticatedCustomer.customer_no ? "bg-green" : "btn-info"}`}
                  onClick={() => this.handleMyOrdersFilter()}
                >Moje objednávky
                </button>
                <button
                  className={`mr-2 btn btn-sm ${this.state.params.customer__sponsor_id && this.state.params.customer__sponsor_id === this.props.authenticatedCustomer.id ? "bg-green" : "btn-info"}`}
                  onClick={() => this.handleFirstLineOrdersFilter()}
                >Objednávky první linie
                </button>
              </>
            )}
          </GridMenu>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getPaymentMethods(),
    ]).then(axios.spread((paymentMethodsRes) => {
      let paymentMethods = {}

      paymentMethodsRes.data.objects.forEach(method => {
        paymentMethods[method.id] = method.name
      })

      this.setState({
        _paymentMethods: paymentMethods,
      }, this.getData)
    }))
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    if (row.sale.status === "created_open") {
      return "Rozpracovaná"
    }

    if (ORDER_STATUS.hasOwnProperty(cell)) {
      return ORDER_STATUS[cell]
    }

    return ""
  }

  formatPaidDate(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    if (row.sale) {
      return row.sale.sale_no
    }

    return ""
  }

  formatInvoiceNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/my-sales/${row.sale_id}/detail/${row.id}`}
      >{cell}</NavLink>
    )
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return row.customer.customer_no
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer.surname || ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer.name || ""
  }

  formatTotalPriceVAT(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  formatBillingAddress(cell, row, rowIndex, formatExtraData) {
    return `${row.billing_address_street}, ${row.billing_address_city}, ${row.billing_address_country_code}`
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Stav objednávky",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "D. uhrazení",
      dataField: "paid_date",
      filter: customFilter({
        type: "DATE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.paid_date = () => onFilter("")
        return <GridDateFilter
          onFilter={onFilter}
          column={column}
          placeholder={"D. uhrazení"}
          defaultValue={this.state.params.paid_date || ""}
        />
      },
      headerClasses: () => this.state.params.paid_date ? "bg-green" : "",
      formatter: this.formatPaidDate.bind(this),
      visible: true,
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      filter: selectFilter({
        options: this.state._paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state._paymentMethods,
      visible: true,
    }, {
      text: "Č. zakázky",
      dataField: "sale__sale_no",
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale__sale_no || "",
        getFilter: filter => this.filters.sale__sale_no = filter,
      }),
      headerClasses: () => this.state.params.sale__sale_no ? "bg-green" : "",
      formatter: this.formatSaleNo.bind(this),
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Č. faktury",
      dataField: "invoice_no",
      classes: "column-sticky column-sticky",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // z-index of customer surname column is 1. There is plenty of space to
          // prevent customer surname overlaps order notes
          zIndex: 100 - rowIndex
        }
      },
      filter: textFilter({
        placeholder: "Č. faktury",
        defaultValue: this.state.params.invoice_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.invoice_no = filter,
      }),
      headerClasses: () => `column-sticky column-sticky ${this.state.params.invoice_no ? "bg-green" : ""}`,
      formatter: this.formatInvoiceNo.bind(this),
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Č. zák.",
      dataField: "customer__customer_no",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer__customer_no ? `${this.state.params.customer__customer_no}`.replace("^", "") : "",
        getFilter: filter => this.filters.customer__customer_no = filter,
      }),
      headerClasses: () => this.state.params.customer__customer_no ? "bg-green" : "",
      formatter: this.formatCustomerNumber.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      classes: "column-sticky column-sticky_1",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          zIndex: 1 // must be smaller than invoice number column to prevent column overlaps order notes
        }
      },
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.customer__surname ? "bg-green" : ""}`,
      formatter: this.formatCustomerSurname.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      filter: textFilter({
        placeholder: "Jméno zák.",
        defaultValue: this.state.params.customer__name || "",
        getFilter: filter => this.filters.customer__name = filter,
      }),
      headerClasses: () => this.state.params.customer__name ? "bg-green" : "",
      formatter: this.formatCustomerName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      filter: textFilter({
        placeholder: "Celková cena",
        defaultValue: this.state.params.total_price_vat || "",
        getFilter: filter => this.filters.total_price_vat = filter,
      }),
      headerClasses: () => this.state.params.total_price_vat ? "bg-green" : "",
      formatter: this.formatTotalPriceVAT.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Fakturační adresa",
      dataField: "billing_address_street",
      filter: textFilter({
        placeholder: "Fakturační adresa",
        defaultValue: this.state.params.billing_address_street || "",
        getFilter: filter => this.filters.billing_address_street = filter,
      }),
      headerClasses: () => this.state.params.billing_address_street ? "bg-green" : "",
      formatter: this.formatBillingAddress.bind(this),
      visible: true,
    }, {
      text: "D. vytvoření",
      dataField: "created_at",
      filter: customFilter({
        type: "DATE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.created_at = () => onFilter("")
        return <GridDateFilter
          onFilter={onFilter}
          column={column}
          placeholder={"D. vytvoření"}
          defaultValue={this.state.params.created_at || ""}
        />
      },
      headerClasses: () => this.state.params.created_at ? "bg-green" : "",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
      visible: true,
    },]
  }

  handleMyOrdersFilter() {
    let customerNo = this.state.params.customer__customer_no === this.props.authenticatedCustomer.customer_no
      ? ""
      : this.props.authenticatedCustomer.customer_no

    this.setState({
      params: Object.assign({}, this.state.params, {
        customer__sponsor_id: "",
        customer__customer_no: customerNo,
      })
    }, () => {
      this.filters.customer__customer_no(customerNo)
    })
  }

  handleFirstLineOrdersFilter() {
    this.filters.customer__customer_no("")

    this.setState({
      params: Object.assign({}, this.state.params, {
        customer__customer_no: "",
        customer__sponsor_id: this.state.params.customer__sponsor_id ? "" : this.props.authenticatedCustomer.id,
      })
    }, this.getData)
  }
}
