import React, {Component} from "react"
import SaleForm from "./SaleForm"

export default class OrderReturnForm extends Component {
  render() {
    return (
      <SaleForm
        isReturn={true}
        {...this.props}
      />
    )
  }
}
