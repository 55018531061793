import React, {Component} from "react"
import AsyncSelect from "react-select/async"
import {List} from "immutable"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import ReactSelectTableOption from "../../Base/ReactSelectTableOption"
import {cleanState} from "../../Utils"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import COUNTRY from "../../Base/Enum/Countries"

export default class PromotionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _types: [],
      valid_from: moment().format("YYYY-MM-DD hh:mm:ss"),
      valid_to: moment().format("YYYY-MM-DD hh:mm:ss"),
      admin_valid_to: moment().format("YYYY-MM-DD hh:mm:ss"),
      product_discount: List(),
    }
  }

  componentDidMount() {
    this.getData()

    if (this.props.match.params.id) {
      this.getPromotionDetail()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-row"}
            >
              <div
                className={"mb-2 col-12"}
              >Nastavte dobu trvání akce:
              </div>

              <div
                className={"form-group col-3"}
              >
                <label>Začátek akce:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_from, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidFromChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-3"}
              >
                <label>Ukočení akce v moje.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleValidToChange.bind(this)}
                />
              </div>

              <div
                className={"form-group col-3"}
              >
                <label>Ukočení akce v is.missiva.cz:</label>
                <DatePicker
                  showTimeSelect={true}
                  locale={"cs"}
                  className={"form-control"}
                  selected={moment(this.state.admin_valid_to, "YYYY-MM-DD HH:mm:ss").toDate()}
                  dateFormat={"dd. MM. yyyy HH:mm"}
                  timeFormat={"HH:mm"}
                  timeIntervals={15}
                  timeCaption={"Čas"}
                  onChange={this.handleAdminValidToChange.bind(this)}
                />
              </div>
            </div>

            {/* PAID */}
            <div
              className={"form-group mt-4"}
            >Vyberte placené produkty:
            </div>
            <table
              className={"table table-striped"}
            >
              <thead>
              <tr>
                <th>Název produktu</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {this.state.product_discount
                .filter(product => product.value === 0)
                .map(product => (
                  <tr
                    key={product.product_id}
                  >
                    <td>{product._name}</td>
                    <td
                      className={"text-right"}
                    >
                      <button
                        type={"button"}
                        className={"btn btn-sm btn-danger"}
                        onClick={() => this.handleProductDelete(product.product_id)}
                      >Smazat
                      </button>
                    </td>
                  </tr>
                ))}
              <tr>
                <td
                  colSpan={2}
                >
                  <AsyncSelect
                    value={{}}
                    className={"react-select_border product-search-input"}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                    onChange={this.handlePaidProductSearchSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    styles={ReactSelectCustomStyles}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                  />
                </td>
              </tr>
              </tbody>
            </table>

            {/* FREE */}
            <div
              className={"form-group mt-4"}
            >Vyberte produkty zdarma:
            </div>
            <table
              className={"table table-striped"}
            >
              <thead>
              <tr>
                <th>Název produktu</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {this.state.product_discount
                .filter(product => product.value === 100)
                .map(product => (
                  <tr
                    key={product.product_id}
                  >
                    <td>{product._name}</td>
                    <td
                      className={"text-right"}
                    >
                      <button
                        type={"button"}
                        className={"btn btn-sm btn-danger"}
                        onClick={() => this.handleProductDelete(product.product_id)}
                      >Smazat
                      </button>
                    </td>
                  </tr>
                ))}
              <tr>
                <td>
                  <AsyncSelect
                    value={{}}
                    className={"react-select_border product-search-input"}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                    onChange={this.handleFreeProductSearchSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    styles={ReactSelectCustomStyles}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                  />
                </td>
                <td/>
              </tr>
              </tbody>
            </table>

            {this.props.match.params.id && !this.isActive() && (
              <NavLink
                className={"btn btn-primary mr-2"}
                to={`/products/discount/add-promotion/${this.props.match.params.id}`}
              >Kopírovat akci</NavLink>
            )}

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>

            <button
              type={"submit"}
              className={"btn btn-success"}
              disabled={!this.isFormValid()}
            >Uložit
            </button>

            {this.props.match.params.id && (
              <button
                type={"button"}
                className={"btn btn-danger float-right"}
                onClick={this.handleDelete.bind(this)}
              >Smazat</button>
            )}
          </form>
        </div>
      </div>
    )
  }

  getData() {
    API.get("/product-discount-types")
      .then(({data}) => {
        this.setState({
          _types: data.objects,
        })
      })
  }

  getPromotionDetail() {
    API.get(`/product-discount-groups/${this.props.match.params.id}`)
      .then(({data}) => {
        let products = List()

        data.product_discount.forEach(discount => {
          products = products.push({
            product_id: discount.product_id,
            value: discount.value,
            type: 1,
            _name: getProductNameByCountry(discount.product),
          })
        })

        this.setState({
          valid_from: data.valid_from,
          valid_to: data.valid_to,
          admin_valid_to: data.admin_valid_to || this.state.admin_valid_to,
          product_discount: products,
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let data = cleanState(this.state)

    if (this.props.match.params.id) {
      API.put(`/product-discount-groups/${this.props.match.params.id}`, data)
        .then(this.handleCancel.bind(this))
    } else {
      API.post("/product-discount-groups", data)
        .then(this.handleCancel.bind(this))
    }
  }

  handleCancel() {
    this.props.history.push("/products/discount")
  }

  handleValidFromChange(date) {
    this.setState({
      valid_from: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleValidToChange(date) {
    this.setState({
      valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleAdminValidToChange(date) {
    this.setState({
      admin_valid_to: moment(date).format("YYYY-MM-DD HH:mm:ss")
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}&is_price_list_visible=1&sort=code`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: getProductNameByCountry(item, COUNTRY.CZECH_REPUBLIC, "|"),
        }))
        cb(data.objects)
      })
  }

  handlePaidProductSearchSelect(option) {
    this.setState({
      product_discount: this.state.product_discount.push({
        product_id: option.id,
        value: 0, // full price
        type: 1,
        _name: getProductNameByCountry(option),
      })
    })
  }

  handleFreeProductSearchSelect(option) {
    this.setState({
      product_discount: this.state.product_discount.push({
        product_id: option.id,
        value: 100, // 100 %
        type: 1,
        _name: getProductNameByCountry(option),
      })
    })
  }

  handleProductDelete(productId) {
    let productIndex = this.state.product_discount.findIndex(discount => discount.product_id === productId)

    if (productIndex > -1) {
      this.setState({
        product_discount: this.state.product_discount.delete(productIndex)
      })
    }
  }

  handleDelete() {
    if (window.confirm("Opravdu chceš smazat?")) {
      API.delete(`/product-discount-groups/${this.props.match.params.id}`)
        .then(this.handleCancel.bind(this))
    }
  }

  isActive() {
    return moment(new Date()).isBetween(this.state.valid_from, this.state.valid_to)
  }

  isFormValid() {
    let freeProducts = this.state.product_discount.toArray().filter(product => product.value === 100)
    let paidProducts = this.state.product_discount.toArray().filter(product => product.value === 0)

    return freeProducts.length > 0 && paidProducts.length > 0
  }
}
