const ORDER_METHOD = {
  ONLINE: 1,
  EMAIL: 2,
  WRITTEN_OR_FAX: 3,
  RECORDED: 4,
  PERSONAL: 5,
  PHONE: 6,
}

export default ORDER_METHOD
