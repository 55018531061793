import React from "react"
import styles from "./style.module.css"
import {is_MY} from "../const"
import seq from "../Base/seq"

export default (props) => (
  <div
    className={`modal ${props.isOpen ? "d-block" : ""} ${styles.modal}`}
  >
    <div
      className={"modal-dialog modal-dialog-scrollable"}
    >
      <div
        className={"modal-content"}
      >
        <div
          className={"modal-header font-weight-bold"}
        >
          <div
            className={"d-block"}
          >
            Náhled akce 4+1
            <p
              className={"text-danger m-0"}
            >V tomto souhrnu není vidět položky, které se do akce nezahrnují, např. poštovné, balné,
              prolongační poplatek atp.</p>
          </div>

          <button
            type={"button"}
            className={"close"}
            onClick={props.closeHandler}
          >&times;</button>
        </div>

        <div
          className={"modal-body"}
        >
          {props.data.map((item, i) => (
            <div
              key={item.id}
            >
              <h5
                className={"border m-0 p-2"}
              >{seq(i + 1)} - {item.customer}</h5>

              <table
                className={"table table-bordered"}
              >
                <thead
                  className={"bg-light"}
                >
                <tr>
                  <th
                    className={styles.dark}
                  >Název
                  </th>
                  <th
                    className={`${styles.fixed} ${styles.dark}`}
                  >Cena
                  </th>
                  <th
                    className={`${styles.fixed} ${styles.dark}`}
                  >Body
                  </th>
                </tr>
                </thead>
                <tbody>
                {item.products.map(product => (
                  <tr
                    className={product.price === 0 ? "bg-warning" : ""}
                  >
                    <td>{product.name}</td>
                    <td
                      className={styles.fixed}
                    >{(product.price * props.countryCurrencyInfo.exchange_rate).toFixed(props.countryCurrencyInfo.round_decimal_places)} {props.countryCurrencyInfo.currency}</td>
                    <td
                      className={styles.fixed}
                    >{product.points}</td>
                  </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                  <th
                    className={styles.dark}
                  />
                  <th
                    className={`${styles.fixed} ${styles.dark}`}
                  >
                    {(item.products.reduce((acc, product) => {
                      return acc + product.price
                    }, 0) * props.countryCurrencyInfo.exchange_rate).toFixed(props.countryCurrencyInfo.round_decimal_places)} {props.countryCurrencyInfo.currency}
                  </th>
                  <th
                    className={`${styles.fixed} ${styles.dark}`}
                  >{item.products.reduce((acc, product) => {
                    return acc + product.points
                  }, 0)}
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          ))}
        </div>

        <div
          className={"modal-footer"}
        >
          <button
            type={"button"}
            className={"btn btn-sm btn-warning mr-2"}
            onClick={props.closeHandler}
          >Zrušit
          </button>

          {is_MY ? (
            <>
              <button
                className={"btn btn-sm btn-primary mr-2"}
                type={"submit"}
                onClick={props.saveAsOpenHandler}
              >Uložit jako rozpracovanou
              </button>

              <button
                type={"button"}
                className={"btn btn-sm btn-success"}
                onClick={() => props.saveHandler(props.formSubmitHandler)}
              >Odeslat
              </button>
            </>
          ) : (
            <>
              <button
                className={"btn btn-sm btn-primary mr-2"}
                type={"button"}
                onClick={props.formSubmitHandler}
              >Uložit jako rozpracovanou
              </button>

              {props.haveToWaitForPayment
                ? (
                  <button
                    className={"btn btn-sm btn-success mr-2"}
                    type={"button"}
                    onClick={() => props.saveHandler(props.paidReadyHandler)}
                  >Připravená k zaplacení</button>
                ) : (
                  props.isPersonalPickup
                    ? (
                      <button
                        className={"btn btn-sm btn-success mr-2"}
                        onClick={() => props.saveHandler(props.personalPickupPrintHandler)}
                        type={"button"}
                      >Zaplatit a vytisknout</button>
                    ) : (
                      <>
                        <button
                          className={"btn btn-sm btn-success mr-2"}
                          onClick={() => props.saveHandler(props.printReadyHandler)}
                          type={"button"}
                        >Připravit k tisku
                        </button>

                        {props.shouldPrint && (
                          <button
                            className={"btn btn-sm btn-success mr-2"}
                            onClick={() => props.saveHandler(props.printHandler)}
                            type={"button"}
                          >Vytisknout</button>
                        )}
                      </>
                    )
                )}
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)
