import React from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import moment from "moment"
import Grid from "../Base/Grid"
import API from "../API"
import {getParams} from "../Utils"
import GridMenu from "../Base/GridMenu"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable.js"
import GridDateRangeFilter from "../Base/GridDateRangeFilter"
import ExportButton from "../Base/ExportButton"
import customerExportColumn from "../fixtures/customerExportColumn"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import CONTACT_TYPE from "../Base/Enum/ContactType"
import GridParams from "../Base/GridParams/GridParams"

export default class CustomerList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-created_at"
      }),
      _types: {},
      _countries: {},
      _columns: [],
    }
    this.appUrl = "/customers"
    this.apiUrl = "/customers"
    this.filters = {}
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-6 mb-3"}
        >
          <NavLink
            to={`${this.appUrl}/add`}
            className={"btn btn-primary mr-3"}
          >Nový zákazník</NavLink>
        </div>

        <div
          className={"col-6 mb-3 text-right"}
        >
          <ExportButton
            exportName={"customers"}
            fileName={"Zákazníci"}
            params={this.state.params}
            columns={customerExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-12 mb-3"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          />
        </div>

        <GridParams
          params={this.state.params}
        />

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getTypes(),
      this.getCountries()
    ]).then(axios.spread((typesRes, countriesRes) => {
      let types = {}
      let countries = {}

      typesRes.data.objects.forEach(type => {
        types[type.id] = type.name
      })

      countriesRes.data.objects.forEach(country => {
        countries[country.id] = country.name
      })

      this.setState({
        _types: types,
        _countries: countries,
      }, this.getData)
    }))
  }

  getTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    return <strong>{formatExtraData[cell] || ""}</strong>
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`${this.appUrl}/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatSurname(cell, row, rowIndex, formatExtraData) {
    let membershipDate = moment(row.membership_extended_at, "YYYY-MM-DD")
      .add(1, "years")
    let notificationDate = moment(row.membership_extended_at, "YYYY-MM-DD")
      .add(1, "years")
      .subtract(6, "weeks")

    return (
      <>
        <NavLink
          to={`${this.appUrl}/${row.id}/detail`}
        >{cell || ""}</NavLink>

        {row.note && row.note.map(note => (
          <div
            key={note.id}
            className={`ml-1 toggle-tooltip fas fa-${note.is_warn
              ? "exclamation-triangle text-danger"
              : note.is_pub
                ? "user text-warning"
                : "exclamation"}`}
          >
            <div
              className={"tooltip ml-3 mt-1 text-white"}
            >{note.value}</div>
          </div>
        ))}

        {moment().isBetween(notificationDate, membershipDate) && (
          <i
            className={"fas fa-clock ml-1"}
            title={"Blížící se konec členství"}
          />
        )}
      </>
    )
  }

  formatName(cell, row, rowIndex, formatExtraData) {
    return cell || ""
  }

  formatSponsorCustomerNumber(cell, row, rowIndex, formatExtraData) {
    if (!row.sponsor) { // root customers do not have sponsors
      return ""
    }

    return (
      <NavLink
        to={`${this.appUrl}/${row.sponsor.id}/detail`}
      >{row.sponsor.customer_no}</NavLink>
    )
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    if (!row.sponsor) { // root customers do not have sponsors
      return ""
    }

    return `${row.sponsor.name || ""} ${row.sponsor.surname || ""}`
  }

  formatDiscount(cell, row, rowIndex, formatExtraData) {
    return cell === 10 ? "Ano" : "Ne"
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("D. M. YYYY")
    }

    return ""
  }

  formatContact(cell, row, rowIndex, formatExtraData) {
    return row.contact.map(contact => {
      if (contact.type_id === CONTACT_TYPE.EMAIL) {
        return (
          <a
            key={contact.id}
            href={`mailto:${contact.value}`}
            className={"d-block"}
          >{contact.value}</a>
        )
      }

      if (
        contact.type_id === CONTACT_TYPE.PHONE_HOME ||
        contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
        contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
        contact.type_id === CONTACT_TYPE.PHONE_WORK ||
        contact.type_id === CONTACT_TYPE.TARIFF_02
      ) {
        return (
          <NavLink
            key={contact.id}
            to={`/communication/sms/${contact.value}`}
            className={"d-block"}
          >{contact.value}</NavLink>
        )
      }

      return (
        <div
          key={contact.id}
        >{contact.value}</div>
      )
    })
  }

  formatCountry(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatPayment(cell, row, rowIndex, formatExtraData) {
    let date = ""

    // payment property
    if (row.payment && row.payment.length > 0) {
      date = row.payment[0].created_at
    }

    // info property (existing users from v3.1)
    if (row.info && row.info.order_last_paid_at) {
      date = row.info.order_last_paid_at
    }

    if (date) {
      return moment(date).format("D. M. YYYY HH:mm:ss")
    }

    return ""
  }

  formatIsMyAccessLimited(cell, row, rowIndex, formatExtraData) {
    return cell ? "Ano" : "Ne"
  }

  formatGoldCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (row.gold) {
      return (
        <NavLink
          to={`/customers/${row.gold.id}/detail`}
        >{row.gold.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatGoldSurname(cell, row, rowIndex, formatExtraData) {
    if (row.gold) {
      return row.gold.surname || ""
    }

    return ""
  }

  formatGoldName(cell, row, rowIndex, formatExtraData) {
    if (row.gold) {
      return row.gold.name || ""
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Typ zákazníka",
      dataField: "type_id",
      filter: selectFilter({
        options: this.state._types,
        placeholder: "Typ zákazníka",
        defaultValue: this.state.params.type_id || "",
        getFilter: filter => this.filters.type_id = filter,
      }),
      headerClasses: () => this.state.params.type_id ? "bg-green" : "",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state._types,
      visible: true,
    }, {
      text: "Č. zákazníka",
      dataField: "customer_no",
      classes: "column-sticky",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer_no ? `${this.state.params.customer_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.customer_no ? "bg-green" : ""}`,
      sort: true,
      formatter: this.formatCustomerNumber.bind(this),
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Příjmení",
      dataField: "surname",
      classes: "column-sticky column-sticky_1",
      filter: textFilter({
        placeholder: "Příjmení",
        defaultValue: this.state.params.surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.surname ? "bg-green" : ""}`,
      sort: true,
      visible: true,
      formatter: this.formatSurname.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // There is plenty of space to prevent customer surname overlaps other notes
          zIndex: 100 - rowIndex
        }
      },
    }, {
      text: "Jméno",
      dataField: "name",
      filter: textFilter({
        placeholder: "Jméno",
        defaultValue: this.state.params.name || "",
        getFilter: filter => this.filters.name = filter,
      }),
      headerClasses: () => this.state.params.name ? "bg-green" : "",
      formatter: this.formatName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "IČO",
      dataField: "registration_no",
      filter: textFilter({
        placeholder: "IČO",
        defaultValue: this.state.params.registration_no || "",
        getFilter: filter => this.filters.registration_no = filter,
      }),
      headerClasses: () => this.state.params.registration_no ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "DIČ",
      dataField: "tax_no",
      filter: textFilter({
        placeholder: "DIČ",
        defaultValue: this.state.params.tax_no || "",
        getFilter: filter => this.filters.tax_no = filter,
      }),
      headerClasses: () => this.state.params.tax_no ? "bg-green" : "",
      sort: true,
      visible: false,
    }, {
      text: "Kontakt",
      dataField: "contact__value",
      filter: textFilter({
        placeholder: "Kontakt",
        defaultValue: this.state.params.contact__value || "",
        getFilter: filter => this.filters.contact__value = filter,
      }),
      headerClasses: () => this.state.params.contact__value ? "bg-green" : "",
      formatter: this.formatContact.bind(this),
      visible: true,
    }, {
      text: "Město",
      dataField: "billing_address_city",
      filter: textFilter({
        placeholder: "Město",
        defaultValue: this.state.params.billing_address_city || "",
        getFilter: filter => this.filters.billing_address_city = filter,
      }),
      headerClasses: () => this.state.params.billing_address_city ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Ulice",
      dataField: "billing_address_street",
      filter: textFilter({
        placeholder: "Ulice",
        defaultValue: this.state.params.billing_address_street || "",
        getFilter: filter => this.filters.billing_address_street = filter,
      }),
      headerClasses: () => this.state.params.billing_address_street ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Č. sponzora",
      dataField: "sponsor__customer_no",
      filter: textFilter({
        placeholder: "Č. sponzora",
        defaultValue: this.state.params.sponsor__customer_no || "",
        getFilter: filter => this.filters.sponsor__customer_no = filter,
      }),
      headerClasses: () => this.state.params.sponsor__customer_no ? "bg-green" : "",
      formatter: this.formatSponsorCustomerNumber.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Jméno sponzora",
      dataField: "sponsor__name",
      formatter: this.formatSponsorName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "D. založení",
      dataField: "created_at",
      headerClasses: () => this.state.params.created_at ? "bg-green" : "",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
      visible: true,
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.created_at = () => onFilter("")
        this.filters["created_at-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.created_at}
            defaultValueTo={this.state.params["created_at-2"]}
          />
        )
      },
    }, {
      text: "Sleva 10%",
      dataField: "discount",
      filter: selectFilter({
        options: {
          "=0": "Ne",
          "=10": "Ano",
        },
        placeholder: "Sleva 10%",
        defaultValue: this.state.params.discount || "",
        getFilter: filter => this.filters.discount = filter,
      }),
      headerClasses: () => this.state.params.discount ? "bg-green" : "",
      formatter: this.formatDiscount.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Země",
      dataField: "country_id",
      filter: selectFilter({
        options: this.state._countries,
        placeholder: "Země",
        defaultValue: this.state.params.country_id || "",
        getFilter: filter => this.filters.country_id = filter,
      }),
      headerClasses: () => this.state.params.country_id ? "bg-green" : "",
      formatter: this.formatCountry.bind(this),
      formatExtraData: this.state._countries,
      visible: true,
    }, {
      text: "Titul",
      dataField: "prefix",
      visible: false,
      filter: textFilter({
        placeholder: "Titul",
        defaultValue: this.state.params.prefix || "",
        getFilter: filter => this.filters.prefix = filter,
      }),
      headerClasses: () => this.state.params.prefix ? "bg-green" : "",
    }, {
      text: "Číslo účtu",
      dataField: "bank_account",
      visible: false,
    }, {
      text: "Konstatní symbol",
      visible: false,
      dataField: "bank_cs",
    }, {
      text: "Specifický symbol",
      visible: false,
      dataField: "bank_ss",
    }, {
      text: "Variabilní symbol",
      visible: false,
      dataField: "bank_vs",
    }, {
      text: "Rodné číslo",
      dataField: "birth_no",
      visible: false,
      filter: textFilter({
        placeholder: "Rodné číslo",
        defaultValue: this.state.params.birth_no || "",
        getFilter: filter => this.filters.birth_no = filter,
      }),
      headerClasses: () => this.state.params.birth_no ? "bg-green" : "",
    }, {
      text: "Dosažený bonus",
      dataField: "bonus",
      visible: false,
      sort: true,
    }, {
      text: "D. poslední zaplacené zakázky",
      dataField: "payment",
      formatter: this.formatPayment.bind(this),
      visible: false,
    }, {
      text: "Omezený přístup do moje.missiva.cz",
      dataField: "is_my_access_limited",
      formatter: this.formatIsMyAccessLimited.bind(this),
      visible: true,
    }, {
      text: "Č. zl. poradce",
      dataField: "gold__customer_no",
      formatter: this.formatGoldCustomerNo.bind(this),
      visible: true,
    }, {
      text: "Příjmení zl. poradce",
      dataField: "gold__surname",
      formatter: this.formatGoldSurname.bind(this),
      visible: true,
    }, {
      text: "Jméno zl. poradce",
      dataField: "gold__name",
      formatter: this.formatGoldName.bind(this),
      visible: true,
    },]
  }
}
