import React, {Component} from "react"
import API from "../../API"

export default class ContactTypeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "",
      protocol: "",
      image: null
    }
    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getContactType()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        {this.id && (
          <div
            className={"col-12"}
          >
            <button
              className={"btn btn-danger mr-3 float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat typ kontaktu
            </button>
          </div>
        )}
        <div
          className={"col-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Typ:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Typ"}
                required={true}
                onChange={this.handleTypeChange.bind(this)}
                value={this.state.type}
                minLength={3}
                maxLength={30}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Protokol:</label>
              <div
                className={"input-group"}
              >
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder={"Protokol"}
                  required={true}
                  onChange={this.handleProtocolChange.bind(this)}
                  value={this.state.protocol}
                  minLength={3}
                  maxLength={30}
                />
                <div
                  className={"input-group-append"}
                >
                <span
                  className={"input-group-text text-monospace"}
                >:</span>
                </div>
              </div>
            </div>

            <div
              className={"form-group"}
            >
              <label>Obrázek:</label>
              <input
                type={"file"}
                className={"form-control-file"}
                required={!this.id}
                onChange={this.handleImageChange.bind(this)}
              />
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  handleTypeChange(e) {
    this.setState({type: e.target.value})
  }

  handleProtocolChange(e) {
    this.setState({protocol: e.target.value})
  }

  handleImageChange(e) {
    let reader = new FileReader()
    reader.onload = () => this.setState({
      image: reader.result.split(",")[1] // remove "data:image/png;base64," prefix
    })
    reader.readAsDataURL(e.target.files[0])
  }

  addContactType() {
    API.post("/contactTypes", this.state)
      .then(() => {
        this.props.history.push("/enums/contact-types")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/contact-types")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateContactType()
    } else {
      this.addContactType()
    }
  }

  getContactType() {
    API.get(`/contactTypes/${this.id}`)
      .then((res) => {
        let {type, protocol} = res.data
        this.setState({type, protocol})
      })
  }

  updateContactType() {
    let data = Object.assign({}, this.state)
    if (data.image === null) {
      delete data.image
    }
    API.put(`/contactTypes/${this.id}`, data)
      .then(() => {
        this.props.history.push("/enums/contact-types")
      })
  }

  handleDelete() {
    API.delete(`/contactTypes/${this.id}`)
      .then(() => {
        this.props.history.push("/enums/contact-types")
      })
  }
}
