const COUNTRY_ID = {
  1: "Česká republika",
  2: "Slovensko",
  3: "Polsko",
  4: "Německo",
  5: "Rakousko",
  6: "Nizozemí",
  7: "Španělsko",
  8: "Slovinsko"
}

const CUSTOMER_TYPE_ID = {
  1: "CK",
  2: "NE",
  3: "MS",
  4: "PbP",
  5: "PP",
  6: "RD",
  7: "RP",
  8: "RZ",
  9: "Zrušená žádost"
}

const DELIVERY_METHOD_ID = {
  1: "Geis",
  2: "ČP",
  3: "Osobní odběr",
  4: "DPD"
}

const PAYMENT_METHOD_ID = {
  1: "Dobírka",
  2: "Bankovní převod",
  3: "Hotově",
  4: "Platba kartou při osobním odběru",
  5: "Věrnostní Kč"
}

const ORDER_METHOD_ID = {
  1: "online",
  2: "email",
  3: "písemný/fax",
  4: "záznamník",
  5: "osobní",
  6: "telefon",
}

const SALE_STATE_ID = {
  created: "Vytvořená online zakázka",
  created_open: "Online rozpracovaná zakázka",
  created_paid: "Vytvořená online zakázka - zaplacená",
  open: "Rozpracovaná zakázka",
  ready_unpaid: "Připravené k úhradě bez dobírek",
  print_ready: "Dobírky k tisku",
  print_ready_paid: "Připravené k tisku",
  printed: "Dobírka k tisku štítku",
  printed_paid: "Vytištěno - zaplaceno",
  packed: "Zabaleno se štítkem (Vyexpedováno)",
  unpaid: "Neuhrazeno",
  paid: "Vyřízená",
  storno: "Zrušena"
}

const ORDER_STATE_ID = {
  created: "Vytvořená",
  unpaid: "Neuhrazená",
  paid: "Uhrazená",
  storno: "Stornovaná"
}

const SORT_KEY = {
  status: "Stav",
  sale_date: "Čas pořízení",
  sale_no: "Č. zakázky",
  sale__sale_no: "Č. zakázky",
  customer_no: "Č. zákazníka",
  customer__customer_no: "Č. zákazníka",
  surname: "Příjmení zák.",
  customer__surname: "Příjmení zák.",
  name: "Jméno zák.",
  customer__name: "Jméno zák.",
  total_price: "Celková cena",
  no_due_price: "Cena objednávek bez splatností",
  delivery_price: "Poštovné",
  packaging_price: "Balné",
  bonus_points: "Celkové body",
  registration_no: "IČO",
  billing_address_city: "Město",
  billing_address_street: "Ulice",
  sponsor__customer_no: "Č. sponzora",
  sponsor__name: "Jméno sponzora",
  created_at: "D. založení",
  discount: "Sleva 10 %",
  paid_date: "D. uhrazení",
  due_days: "Splatnost"
}

const VALUE_KEY = {
  sort: "Řazeno",
  status: "Stav",
  delivery_method_id: "Způsob doručení",
  payment_method_id: "Způsob platby",
  sale_no: "Č. zakázky",
  sale__sale_no: "Č. zakázky",
  invoice_no: "Č. faktur",
  order__invoice_no: "Č. faktur",
  order__paid_date: "D. uhrazení",
  sale_date: "Čas pořízení",
  customer__customer_no: "Č. zákazníka",
  customer_no: "Č. zákazníka",
  customer__surname: "Příjmení zák.",
  surname: "Příjmení zák.",
  customer__name: "Jméno zák.",
  name: "Jméno zák.",
  order_method_id: "Způsob objednání",
  total_price: "Celková cena",
  no_due_price: "Cena objednávek bez splatností",
  delivery_price: "Poštovné",
  packaging_price: "Balné",
  bonus_points: "Celkové body",
  delivery_address__street: "Doručovací adresa",
  delivery_address__country_code: "Země",
  type_id: "Typ",
  registration_no: "IČO",
  contact__value: "Kontakt",
  billing_address_city: "Město",
  billing_address_street: "Ulice",
  sponsor__customer_no: "Č. sponzora",
  created_at: "D. založení",
  discount: "Sleva",
  country_id: "Země",
  paid_date: "D. uhrazení",
  due_days: "Splatnost",
  order__order_product__is_advert: "Propagační účely",
  customer_tree_date: "Datum",
}

export {
  COUNTRY_ID,
  CUSTOMER_TYPE_ID,
  DELIVERY_METHOD_ID,
  PAYMENT_METHOD_ID,
  ORDER_METHOD_ID,
  SALE_STATE_ID,
  ORDER_STATE_ID,
  SORT_KEY,
  VALUE_KEY,
}
