import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"

export default class GridDateFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: this.props.defaultValue || "",
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        date: this.props.defaultValue,
      })
    }
  }

  render() {
    return (
      <div
        onClick={this.handleDateClick.bind(this)}
      >
        <DatePicker
          isClearable={this.state.date}
          locale={"cs"}
          className={`form-control ${this.props.className || ""}`}
          selected={this.state.date ? moment(this.state.date).toDate() : ""}
          onChange={this.handleDateChange.bind(this)}
          dateFormat={"dd. MM. yyyy"}
          placeholderText={this.props.placeholder}
        />
      </div>
    )
  }

  handleDateClick(e) {
    // if column has allowed sorting, click will change the sort order -> this stop that
    e.preventDefault()
    e.stopPropagation()
  }

  handleDateChange(date) {
    this.setState({
      date: date ? moment(date).format("YYYY-MM-DD") : ""
    }, () => {
      this.props.onFilter(this.state.date)
    })
  }
}
