import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import {NavLink} from "react-router-dom"
import moment from "moment"
import Grid from "../Base/Grid"
import GridMenu from "../Base/GridMenu"
import {getParams} from "../Utils"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable.js"
import API from "../API"
import COUNTRY from "../Base/Enum/Countries"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class WatchDogList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
      _columns: [],
    }
    this.appUrl = "/products/watch-dog"
    this.apiUrl = "/productStock"
    this.filters = {}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.data.length !== prevState.data.length || this.state.params.offset !== prevState.params.offset) {
      this.getProducts()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          >
            <NavLink
              to={"/products/watch-dog/add"}
              className={"btn btn-primary"}
            >Nový hlídací pes</NavLink>
          </GridMenu>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Produkt",
      dataField: "_product",
      visible: true,
      alwaysVisible: true,
      formatter: this.formatProduct.bind(this),
    }, {
      text: "Počet zbývajících kusů",
      dataField: "available",
      visible: true,
    }, {
      text: "Vytvořeno",
      dataField: "created_at",
      visible: true,
      formatter: this.formatCreatedAt.bind(this)
    },]
  }

  getProducts() {
    let ids = this.state.data.map(watchDog => watchDog.product_id).join("|")
    API.get(`/products?id==${ids}`)
      .then(({data}) => {
        data = this.state.data.map(watchDog => {
          let product = data.objects.find(product => product.id === watchDog.product_id)
          return Object.assign({}, watchDog, {
            _product: product
          })
        })

        this.setState({data})
      })
  }

  formatProduct(cell, row, rowIndex, formatExtraData) {
    let name = row.id

    if (row._product) {
      name = `${row._product.code} - ${row._product.names.find(name => name.country_id === COUNTRY.CZECH_REPUBLIC).name}`
    }

    return (
      <NavLink
        to={`/products/watch-dog/${row.id}/detail`}
      >{name}</NavLink>
    )
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY HH:mm:ss")
  }
}
