let frame
const print = file => {
  if (!frame)
    frame = document.createElement("iframe")

  // be sure frame is not visible and has zero size
  Object.assign(frame.style, {
    visibility: "hidden",
    width: 0,
    height: 0,
    border: 0,
    position: "absolute",
  })

  // append to document and print file
  frame.type = "application/pdf"
  frame.src = file
  document.body.appendChild(frame)
  frame.contentWindow.focus()
  frame.contentWindow.print()
}

export default print
