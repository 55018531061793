import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import {NavLink} from "react-router-dom"
import DatePicker from "react-datepicker"
import axios from "axios"
import Grid from "../../Base/Grid"
import {getParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import API from "../../API"
import CONTACT_TYPE from "../../Base/Enum/ContactType"
import ExportButton from "../../Base/ExportButton"
import unpaidOrdersExportColumn from "../../fixtures/unpaidOrdersExportColumn.json"

export default class UnpaidOrderList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      meta: {},
      total: 0,
      isLoading: false,
      customerTypes: [],
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        created_at: `<=${moment().subtract(1, "days").format("YYYY-MM-DD")}`,
        "created_at-2": `>=${moment().subtract(3, "years").format("YYYY-MM-DD")}`,
        paid_date: "null",
        parent_order_id: "null",
        export: "customer.sponsor",
        total_price: ">0",
        status: "!storno",
        sale__status: "!created_open",
      }, params),
      _columns: [],
    }

    this.apiUrl = "/orders"
    this.appUrl = "/reports/sets/unpaid-orders"
    this.defaultParams = [
      "paid_date",
      "parent_order_id",
      "export",
      "total_price",
      "created_at-2",
      "status",
    ]
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"form-group col-lg-2"}
        >
          <label>Datum od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params["created_at-2"].replace(">=", "")).toDate()}
            onChange={this.handleCreatedAt2Change.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"form-group col-lg-2"}
        >
          <label>Datum do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.created_at.replace("<=", "")).toDate()}
            onChange={this.handleCreatedAtChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-check form-check-inline"}
          >
            <input
              type={"checkbox"}
              id={"with_due_days"}
              className={"mr-2"}
              checked={!this.state.params.due_days}
              onChange={this.handleDueDaysChange.bind(this)}
            />
            <label
              className={"form-check-label"}
              htmlFor={"with_due_days"}
            >včetně FA ve splatnosti</label>
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-group mt-lg-4"}
          >
            <button
              className={"btn btn-primary"}
              onClick={this.getData.bind(this)}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <div
          className={"col-lg-4 text-right"}
        >
          <div
            className={"form-group mt-lg-4"}
          >
            <ExportButton
              exportName={"unpaid-orders"}
              fileName={"neplatici"}
              params={this.state.params}
              columns={unpaidOrdersExportColumn}
              apiUrl={this.apiUrl}
            />
          </div>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  // overridden
  _getData() {
    axios.all([
      this.getCustomerTypes()
    ]).then(axios.spread((customerTypesRes) => {
      this.setState({
        customerTypes: customerTypesRes.data.objects,
      }, this.getData)
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  handleCreatedAtChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        created_at: `<=${moment(date).format("YYYY-MM-DD")}`,
      })
    })
  }

  handleCreatedAt2Change(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        "created_at-2": `>=${moment(date).format("YYYY-MM-DD")}`,
      })
    })
  }

  handleDueDaysChange(e) {
    if (e.target.checked) {
      const {due_days, ...params} = this.state.params
      this.setState({params})
    } else {
      this.setState({
        params: Object.assign({}, this.state.params, {
          due_days: "=0",
        }),
      })
    }

  }

  getDefaultColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_id",
      formatter: this.formatCustomerNo.bind(this),
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      formatter: this.formatCustomerName.bind(this),
    }, {
      text: "Č. zakázky",
      dataField: "sale__sale_no",
      formatter: this.formatSaleNo.bind(this),
    }, {
      text: "Č. faktury",
      dataField: "invoice_no",
    }, {
      text: "Cena s DPH",
      dataField: "total_price_vat",
      formatter: this.formatTotalPriceVAT.bind(this),
    }, {
      text: "D. vytvoření",
      dataField: "created_at",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
    }, {
      text: "D. splatnosti",
      dataField: "due_at",
      formatter: this.formatDueAt.bind(this),
    }, {
      text: "Dnů prodleva",
      dataField: "past_due_days",
    }, {
      text: "Č. sponzora",
      dataField: "customer__sponsor__customer_no",
      formatter: this.formatSponsorCustomerNo.bind(this),
    }, {
      text: "Jméno sponzora",
      dataField: "customer__sponsor__name",
      formatter: this.formatSponsorName.bind(this),
    }, {
      text: "Typ sponzora",
      dataField: "customer__sponsor__type_id",
      formatter: this.formatSponsorType.bind(this),
    }, {
      text: "Telefon sponzora",
      dataField: "customer__sponsor__contact__phone",
      formatter: this.formatSponsorPhone.bind(this),
    }, {
      text: "E-mail sponzora",
      dataField: "customer__sponsor__contact__email",
      formatter: this.formatSponsorEmail.bind(this),
    },]
  }

  formatCustomerNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer ? row.customer.id : cell}/detail`}
      >{row.customer ? row.customer.customer_no : cell}</NavLink>
    )
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    if (row.customer) {
      return `${row.customer.surname || ""} ${row.customer.name || ""}`
    }

    return ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.sale_id}/detail`}
      >{row.sale.sale_no}</NavLink>
    )
  }

  formatTotalPriceVAT(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY")
  }

  formatDueAt(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY hh:mm:ss")
  }

  formatSponsorCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return (
        <NavLink
          to={`/customers/${row.customer.sponsor.id}/detail`}
        >{row.customer.sponsor.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      return `${row.customer.sponsor.surname || ""} ${row.customer.sponsor.name || ""}`
    }

    return ""
  }

  formatSponsorType(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor
    ) {
      let customerType = this.state.customerTypes.find(customerType => customerType.id === row.customer.sponsor.type_id)

      if (customerType) {
        return customerType.name
      }
    }

    return ""
  }

  formatSponsorPhone(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor &&
      row.customer.sponsor.contact
    ) {
      return row.customer.sponsor.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.PHONE_WORK ||
          contact.type_id === CONTACT_TYPE.PHONE_HOME ||
          contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
          contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
          contact.type_id === CONTACT_TYPE.TARIFF_02
        )
        .map(contact => (
          <a
            href={`tel:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatSponsorEmail(cell, row, rowIndex, formatExtraData) {
    if (
      row.customer &&
      row.customer.sponsor &&
      row.customer.sponsor.contact
    ) {
      return row.customer.sponsor.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .map(contact => (
          <a
            href={`mailto:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }
}
