import React, {Component} from "react"
import logo from "../assets/logo.png"
import API from "../API"

export default class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: ""
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleFormSubmit.bind(this)}
        className={"mx-auto mt-5 col-3"}
      >
        <div
          className="form-group text-center"
        >
          <img
            src={logo}
            alt="Missiva logo"
            height={100}
          />
          <h1
            className="mt-3"
          >MISSIVA&nbsp;IS</h1>
          <h5
            className="mb-3"
          >přihlášení do systému</h5>
        </div>
        <div
          className="form-group"
        >
          <label>Email:</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            onChange={this.handleEmailChange.bind(this)}
            value={this.state.email}
          />
        </div>
        <div className="form-group">
          <label>Heslo:</label>
          <input
            type="password"
            className="form-control"
            placeholder="Heslo"
            onChange={this.handlePasswordChange.bind(this)}
            value={this.state.password}
            minLength={5}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
        >Přihlásit
        </button>
      </form>
    )
  }

  handleEmailChange(e) {
    this.setState({
      email: e.target.value
    })
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value
    })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    API.post("/auth", this.state)
      .then(({data}) => {
        this.props.loginHandler(data.token)
      })
  }
}
