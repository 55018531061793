import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import axios from "axios"
import API from "../../API"
import Grid from "../../Base/Grid"
import {getParams} from "../../Utils"
import ExportButton from "../../Base/ExportButton"
import cupOfFreedomExportColumn from "../../fixtures/cupOfFreedomExportColumn"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"

export default class CupOfFreedomList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        date_start: moment().format("YYYY-MM-DD"),
        date_end: moment().add(1, "months").format("YYYY-MM-DD"),
        by_gold: 0,
      }, params),
      customerTypes: [],
      _columns: [],
    }

    this.apiUrl = "/reports/customersByCup"
    this.appUrl = "/reports/contests/cup-of-freedom"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3 text-right"}
        >
          <ExportButton
            exportName={"cup-of-freedom-list"}
            fileName={"Pohár svobody"}
            params={this.state.params}
            columns={cupOfFreedomExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_start, "YYYY-MM-DD").toDate()}
            onChange={this.handleDateStartChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_end, "YYYY-MM-DD").toDate()}
            onChange={this.handleDateEndChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label
            className={"d-none d-lg-block"}
          >&nbsp;</label>
          <div>
            <div
              className={"form-check form-check-inline"}
            >
              <input
                type={"radio"}
                name={"group_by_golden_customers"}
                id={"singles"}
                value={0}
                checked={this.state.params.by_gold === 0}
                onChange={this.handleByGoldChange.bind(this)}
              />
              <label
                htmlFor={"singles"}
                className={"form-check-label"}
              >Jednotlivci</label>
            </div>
          </div>

          <div>
            <div
              className={"form-check form-check-inline"}
            >
              <input
                className={"form-check-label"}
                type={"radio"}
                name={"group_by_golden_customers"}
                id={"teams"}
                value={1}
                checked={this.state.params.by_gold === 1}
                onChange={this.handleByGoldChange.bind(this)}
              />
              <label
                htmlFor={"teams"}
                className={"form-check-label"}
              >Týmy</label>
            </div>
          </div>
        </div>

        <div
          className={"col-lg-2 mt-lg-4 mb-3"}
        >
          <button
            className={"btn btn-primary"}
            onClick={this.getData.bind(this)}
          >Zobrazit přehled
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4
                remote
                striped
                hover
                keyField={"id"}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getCustomerTypes()
    ]).then(axios.spread((customerTypesRes) => {
      this.setState({
        customerTypes: customerTypesRes.data.objects,
      }, this.getData)
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD"),
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD"),
      })
    })
  }

  handleByGoldChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        by_gold: parseInt(e.target.value, 10),
      })
    })
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatTypeId(cell, row, rowIndex, formatExtraData) {
    let types = formatExtraData.filter(customerType => customerType.id === cell)

    if (types.length > 0) {
      return types[0].name
    }

    return ""
  }

  formatPoints(cell, row, rowIndex, formatExtraData) {
    if (cell && cell.length > 0) {
      return cell[0].points
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatTypeId.bind(this),
      formatExtraData: this.state.customerTypes,
    }, {
      text: "Příjmení",
      dataField: "surname",
    }, {
      text: "Jméno",
      dataField: "name",
    }, {
      text: "Body",
      dataField: "points",
      formatter: this.formatPoints.bind(this),
    }]
  }
}
