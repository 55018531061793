import React, {Component} from "react"
import {Editor, EditorState, RichUtils} from "draft-js"
import {convertFromHTML, convertToHTML} from "draft-convert"
import "draft-js/dist/Draft.css"
import API from "../../API"

export default class TemplateForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      service: props.match.params.service,
      type: props.match.params.type,
      subject: "",
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    this.getTemplate()
  }

  render() {
    return (
      <>
        <div
          className={"row"}
        >
          <div
            className={"col-12"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group mb-3"}
              >
                <label>Předmět:</label>
                <input
                  type={"text"}
                  value={this.state.subject}
                  onChange={this.handleSubjectChange.bind(this)}
                  className={"form-control"}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Obsah:</label>
                <div
                  className={"mb-3"}
                >
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorBoldClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-bold"}
                    />
                  </button>
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorItalicClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-italic"}
                    />
                  </button>
                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorUnderlineClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-underline"}
                    />
                  </button>

                  <button
                    className={"btn btn-light mr-3"}
                    onClick={this.handleEditorListClick.bind(this)}
                    type={"button"}
                  >
                    <span
                      className={"fas fa-list-ul"}
                    />
                  </button>
                </div>

                <div
                  className={"editor"}
                >
                  <Editor
                    editorState={this.state.editorState}
                    onChange={this.handleEditorChange.bind(this)}
                    handleKeyCommand={this.handleKeyCommand.bind(this)}
                  />
                </div>
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </>
    )
  }

  getTemplate() {
    API.get("/notification-template")
      .then(({data}) => {
        let template = data.objects.find(template => {
          return template.type === this.state.type &&
            template.service === this.state.service
        })

        this.setState({
          id: template.id,
          subject: template.subject,
          editorState: EditorState.createWithContent(convertFromHTML(template.content)),
        })
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    let data = {
      service: this.state.service,
      type: this.state.type,
      subject: this.state.subject,
      content: convertToHTML(this.state.editorState.getCurrentContent()),
    }

    if (this.state.id) {
      data.id = this.state.id
    }

    API.post("/notification-template", data).then(() => {
      this.props.history.push("/enums/templates")
    })
  }

  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value,
    })
  }

  handleEditorBoldClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"))
  }

  handleEditorItalicClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "ITALIC"))
  }

  handleEditorUnderlineClick() {
    this.handleEditorChange(RichUtils.toggleInlineStyle(this.state.editorState, "UNDERLINE"))
  }

  handleEditorListClick() {
    this.handleEditorChange(RichUtils.toggleBlockType(this.state.editorState, "unordered-list-item"))
  }

  handleEditorChange(editorState) {
    this.setState({editorState})
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.handleEditorChange(newState)
      return "handled"
    }
    return "not-handled"
  }

  handleCancel(e) {
    this.props.history.push("/enums/templates")
  }
}
