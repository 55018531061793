import React from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import DatePicker from "react-datepicker"
import moment from "moment"
import Grid from "../Base/Grid"
import API from "../API"
import {getParams} from "../Utils"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import GridDateRangeFilter from "../Base/GridDateRangeFilter"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import GridMenu from "../Base/GridMenu"
import ORDER_STATUS from "../Base/Enum/OrderStatus"
import GridDateFilter from "../Base/GridDateFilter"
import GridParams from "../Base/GridParams/GridParams"

export default class PayOrderList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-created_at",
        sale__status: "!created_open"
      }, params),
      _paymentMethods: {},
      _columns: [],
      _selectedRows: [],
      _paidDate: moment().format("YYYY-MM-DD"),
      _bottomMenuPos: 0,
      _countries: [],
      _saleStates: [],
    }

    this.appUrl = "/sales/pay"
    this.apiUrl = "/orders"
    this.filters = {}
    this.defaultParams = ["sale__status"]
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }

    this.statusFilterOptions = {}
    Object.keys(ORDER_STATUS).forEach(key => {
      this.statusFilterOptions[`=${key}`] = ORDER_STATUS[key]
    })
  }

  componentDidMount() {
    super.componentDidMount()

    window.addEventListener("scroll", this.handleScroll.bind(this))
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  render() {
    return (
      <>
        <div
          className={`row ${this.state.params.sale__sale_no ? "pay-order-list" : ""}`}
        >
          <div
            className={"col-lg-2 mb-3 mt-lg-4"}
          >
            <NavLink
              to={`/sales/add`}
              className={"btn btn-primary"}
            >Nová zakázka</NavLink>
          </div>

          <div
            className={"col-lg-2 mb-3"}
          >
            <label>Datum úhrady:</label>
            <DatePicker
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state._paidDate).toDate()}
              onChange={this.handlePaidDateChange.bind(this)}
              dateFormat={"dd. MM. yyyy"}
            />
          </div>

          <div
            className={"col-lg-8 mb-3 mt-lg-4"}
          >
            <button
              type={"button"}
              className={"btn btn-primary"}
              disabled={this.state._selectedRows.length === 0}
              onClick={this.handlePayOrders.bind(this)}
            >Uhradit označené objednávky
            </button>
          </div>

          <div
            className={"col-12 mb-3"}
          >
            <GridMenu
              toggleColumnHandler={this.toggleColumn.bind(this)}
              resetFiltersHandler={this.resetFilters.bind(this)}
              columns={this.state._columns}
            >
            <span
              className={"text-secondary mr-2"}
            >Zobrazit:</span>
              <button
                className={`mr-2 btn btn-sm ${this.state.params.payment_method_id === PAYMENT_METHOD.CASH_ON_DELIVERY ? "bg-green" : "btn-info"}`}
                onClick={() => this.handlePaymentMethodFilterChange(PAYMENT_METHOD.CASH_ON_DELIVERY)}
              >Dobírka
              </button>
              <button
                className={`mr-2 btn btn-sm ${this.state.params.payment_method_id === PAYMENT_METHOD.BANK_TRANSFER ? "bg-green" : "btn-info"}`}
                onClick={() => this.handlePaymentMethodFilterChange(PAYMENT_METHOD.BANK_TRANSFER)}
              >Bankovním převodem
              </button>
              <button
                className={`mr-2 btn btn-sm ${this.state.params.payment_method_id === PAYMENT_METHOD.CASH ? "bg-green" : "btn-info"}`}
                onClick={() => this.handlePaymentMethodFilterChange(PAYMENT_METHOD.CASH)}
              >Hotově
              </button>
              <button
                className={`mr-4 btn btn-sm ${this.state.params.payment_method_id === PAYMENT_METHOD.CREDIT_CARD ? "bg-green" : "btn-info"}`}
                onClick={() => this.handlePaymentMethodFilterChange(PAYMENT_METHOD.CREDIT_CARD)}
              >Kartou při osobním odběru
              </button>
              <button
                className={`mr-2 btn btn-sm ${this.state.params.due_days && this.state.params.due_days === "0" ? "bg-green" : "btn-info"}`}
                onClick={() => this.handleDueDaysFilterChange("0")}
              >Bez splatnosti
              </button>
              <button
                className={`mr-4 btn btn-sm ${this.state.params.due_days && this.state.params.due_days === "!0" ? "bg-green" : "btn-info"}`}
                onClick={() => this.handleDueDaysFilterChange("!0")}
              >Se splatností
              </button>
              <button
                className={`mr-2 btn btn-sm ${this.state.params.invoice_no === "D" ? "bg-green" : "btn-info"}`}
                onClick={this.handleCreditNotesFilterChange.bind(this)}
              >Dobropisy
              </button>
              <button
                className={`mr-4 btn btn-sm ${this.state.params.invoice_no === "V" ? "bg-green" : "btn-info"}`}
                onClick={this.handleRefundsFilterChange.bind(this)}
              >Vratky
              </button>
            </GridMenu>
          </div>

          <GridParams
            params={this.state.params}
          />

          <div
            className={"col-12"}
          >
            {this.state.isLoading && <SpinnerWithOverlay/>}

            {this.state._columns.length > 0 && (
              <ScrollableTable>
                <BootstrapTable
                  bootstrap4={true}
                  remote={true}
                  striped={true}
                  hover={true}
                  keyField={"id"}
                  ref={ref => this.gridTableRef = ref}
                  columns={this.state._columns.filter(col => col.visible)}
                  data={this.state.data}
                  onTableChange={this.handleTableChange.bind(this)}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    page: (this.state.params.offset / this.state.params.limit) + 1,
                    sizePerPage: this.state.params.limit,
                    totalSize: this.state.total
                  })}
                  noDataIndication={"Nic nenalezeno"}
                  selectRow={{
                    mode: "checkbox",
                    selected: this.state._selectedRows,
                    onSelect: this.handleRowSelect.bind(this),
                    onSelectAll: this.handleRowSelectAll.bind(this),
                  }}
                  defaultSorted={[this.defaultSorted]}
                  rowClasses={this.handleRowClass.bind(this)}
                  sort={this.sort || {}}
                />
              </ScrollableTable>
            )}
          </div>
        </div>

        {this.state.params.sale__sale_no && (
          <div
            className={"row pay-order-summary pt-2 border-top-15 position-fixed w-100 bg-white"}
            style={{
              bottom: this.state._bottomMenuPos
            }}
          >
            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Cena objednávek:</label>

              <input
                type={"text"}
                className={"form-control font-weight-bold"}
                disabled={true}
                value={this.getOrdersTotalPrice()}
              />
            </div>

            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Počet objednávek se splatností:</label>

              <input
                type={"text"}
                className={"form-control font-weight-bold"}
                disabled={true}
                value={this.getOrdersWithDueDaysCount()}
              />
            </div>

            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Cena objednávek se splatností:</label>

              <input
                type={"text"}
                className={"form-control font-weight-bold"}
                disabled={true}
                value={this.getOrdersWithDueDaysTotalPrice()}
              />
            </div>

            <div
              className={"form-group col-lg-2 col-6"}
            >
              <label
                className={"font-weight-bold"}
              >Cena objednávek bez splatností:</label>

              <input
                type={"text"}
                className={"form-control font-weight-bold"}
                disabled={true}
                value={this.getOrdersWithoutDueDaysTotalPrice()}
              />
            </div>

            <div
              className={"form-group col-lg-4 mt-lg-4 text-right"}
            >
              <button
                className={"btn btn-primary"}
                disabled={!this.state.params.sale__sale_no}
                onClick={this.handlePayFilteredOrders.bind(this)}
              >Uhradit vyfiltrované objednávky
              </button>
            </div>
          </div>
        )}
      </>
    )
  }

  _getData() {
    axios.all([
      this.getPaymentMethods(),
      this.getCountries(),
      this.getSaleStates(),
    ]).then(axios.spread((paymentMethodsRes, countriesRes, saleStatesRes) => {
      let paymentMethods = {}

      paymentMethodsRes.data.objects.forEach(paymentMethod => {
        paymentMethods[paymentMethod.id] = paymentMethod.name
      })

      this.setState({
        _paymentMethods: paymentMethods,
        _countries: countriesRes.data.objects,
        _saleStates: saleStatesRes.data.objects,
      }, () => {
        this.getData()
      })
    }))
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    if (ORDER_STATUS.hasOwnProperty(cell)) {
      return ORDER_STATUS[cell]
    }

    return ""
  }

  formatSaleStatus(cell, row, rowIndex, formatExtraData) {
    if (row.sale) {
      let state = this.state._saleStates.find(saleStatus => saleStatus.id === row.sale.status)

      if (state) {
        return (
          <span
            className={state.id === "open" ? "text-danger" : ""}
          >{state.name}</span>
        )
      }
    }

    return ""
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        className={"nowrap"}
      >
        <NavLink
          to={`/sales/${row.sale_id}/detail/`}
        >{row.sale.sale_no}</NavLink>

        {!this.state.params.sale__sale_no && (
          <button
            onClick={() => this.filters.sale__sale_no(row.sale.sale_no)}
            title={"Vyfiltrovat zakázku"}
            className={"btn btn-link"}
          >
            <i
              className={"fas fa-tasks fa-lg ml-4"}
            />
          </button>
        )}
      </div>
    )
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <NavLink
          to={`/sales/${row.sale_id}/detail/${row.id}`}
        >{cell}</NavLink>

        {(row.customer.note.some(note => note.is_warn) || row.note.some(note => note.is_warn)) && (
          <span
            className={"fas fa-exclamation-triangle ml-1 text-danger toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.customer.note.filter(note => note.is_warn).map(note => (
                <div
                  key={note.id}
                >{note.value}</div>
              ))}

              {row.note.filter(note => note.is_warn).map(note => (
                <div
                  key={note.id}
                >{note.value}</div>
              ))}
            </span>
          </span>
        )}

        {row.note.some(note => note.is_pub) && (
          <span
            className={"fas fa-user ml-1 text-warning toggle-tooltip"}
          >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {row.note
                .filter(note => note.is_pub)
                .map(note => (
                  <div
                    key={note.id}
                  >{note.value}</div>
                ))}
            </span>
          </span>
        )}

        {row.order_product.some(products => products.is_advert) && (
          <span
            className={"fas fa-gift ml-1"}
            title={"Některé produkty jsou na propagační účely"}
          />
        )}
      </>
    )
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.customer_no}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.surname : ""
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.name : ""
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY HH:mm:ss")
    }

    return ""
  }

  formatPaidDate(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell).format("DD. MM. YYYY")
    }

    return ""
  }

  formatTotalPriceVat(cell, row, rowIndex, formatExtraData) {
    return row.total_price_vat_formatted
  }

  getDefaultColumns() {
    return [{
      text: "Stav objednávky",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      sort: true,
      visible: true,
      filter: selectFilter({
        options: this.statusFilterOptions,
        placeholder: "Stav objednávky",
        defaultValue: this.state.params.status || "",
        getFilter: filter => this.filters.status = filter,
      }),
      headerClasses: () => this.state.params.status ? "bg-green" : "",
    }, {
      text: "Stav zakázky",
      dataField: "sale__status",
      formatter: this.formatSaleStatus.bind(this),
      visible: true,
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state._paymentMethods,
      visible: true,
      filter: selectFilter({
        options: this.state._paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
    }, {
      text: "Č. zakázky",
      dataField: "sale__sale_no",
      classes: "column-sticky",
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale__sale_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale__sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale__sale_no ? "bg-green" : ""}`,
      formatter: this.formatSaleNo.bind(this),
      sort: true,
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Č. faktury",
      dataField: "invoice_no",
      classes: "column-sticky column-sticky_1",
      filter: textFilter({
        placeholder: "Č. faktur",
        defaultValue: this.state.params.invoice_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.invoice_no = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.invoice_no ? "bg-green" : ""}`,
      formatter: this.formatOrder.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // z-index of customer surname column is 1. There is plenty of space to
          // prevent customer surname overlaps order notes
          zIndex: 100 - rowIndex
        }
      },
      visible: true,
    }, {
      text: "Č. zák.",
      dataField: "customer__customer_no",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer__customer_no ? `${this.state.params.customer__customer_no}`.replace("^", "") : "",
        getFilter: filter => this.filters.customer__customer_no = filter,
      }),
      headerClasses: () => this.state.params.customer__customer_no ? "bg-green" : "",
      formatter: this.formatCustomerNumber.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      classes: "column-sticky column-sticky_2",
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_2 ${this.state.params.customer__surname ? "bg-green" : ""}`,
      formatter: this.formatCustomerSurname.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          zIndex: 1 // must be smaller than invoice number column to prevent column overlaps order notes
        }
      },
      sort: true,
      visible: true,
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      filter: textFilter({
        placeholder: "Jméno zák.",
        defaultValue: this.state.params.customer__name || "",
        getFilter: filter => this.filters.customer__name = filter,
      }),
      headerClasses: () => this.state.params.customer__name ? "bg-green" : "",
      formatter: this.formatCustomerName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "D. vytvoření",
      dataField: "created_at",
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.created_at = () => onFilter("")
        this.filters["created_at-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.created_at}
            defaultValueTo={this.state.params["created_at-2"]}
          />
        )
      },
      headerClasses: () => this.state.params.created_at ? "bg-green" : "",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "D. uhrazení",
      dataField: "paid_date",
      filter: customFilter({
        type: "DATE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.paid_date = () => onFilter("")
        return (
          <GridDateFilter
            onFilter={onFilter}
            column={column}
            placeholder={"D. uhrazení"}
            defaultValue={this.state.params.paid_date || ""}
          />
        )
      },
      headerClasses: () => this.state.params.paid_date ? "bg-green" : "",
      formatter: this.formatPaidDate.bind(this),
      visible: true,
      sort: true,
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      filter: textFilter({
        placeholder: "Celková cena",
        defaultValue: this.state.params.total_price_vat || "",
        getFilter: filter => this.filters.total_price_vat = filter,
      }),
      headerClasses: () => this.state.params.total_price_vat ? "bg-green" : "",
      formatter: this.formatTotalPriceVat.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Splatnost",
      dataField: "due_days",
      filter: textFilter({
        placeholder: "Splatnost",
        defaultValue: this.state.params.due_days || "",
        getFilter: filter => this.filters.due_days = filter,
      }),
      headerClasses: () => this.state.params.due_days ? "bg-green" : "",
      sort: true,
      visible: true,
    },]
  }

  handleRowSelect = (row, isSelected) => {
    if (
      row.status === "unpaid" ||
      row.status === "paid"
    ) {
      this.setState({
        _selectedRows: isSelected
          ? [...this.state._selectedRows, row.id]
          : this.state._selectedRows.filter(id => id !== row.id)
      })
    }

    return false
  }

  handleRowSelectAll = (isSelected, rows) => {
    this.setState({
      _selectedRows: isSelected
        ? rows
          .filter(row => row.status === "unpaid" || row.status === "paid")
          .map(row => row.id)
        : []
    })
  }

  handlePaidDateChange(date) {
    this.setState({
      _paidDate: moment(date).format("YYYY-MM-DD")
    })
  }

  handlePayOrders() {
    let alreadyPaidOrders = this.state.data
      .filter(order => order.status === "paid")
      .filter(order => this.state._selectedRows.indexOf(order.id) > -1)

    if (
      alreadyPaidOrders.length > 0 &&
      !window.confirm("Opravdu chceš změnit datum zaplacení?")
    ) {
      return
    }

    let orders = this.state._selectedRows.map(id => {
      return {
        id,
        paid_date: this.state._paidDate,
      }
    })

    API.post("/payments/pay", {orders})
      .then(() => {
        this._getData()
        this.setState({
          _selectedRows: []
        })
      })
  }

  handlePayFilteredOrders() {
    let orders = this.state.data.map(order => {
      return {
        id: order.id,
        paid_date: this.state._paidDate,
      }
    })

    API.post("/payments/pay", {orders})
      .then(() => {
        this._getData()
        this.setState({
          _selectedRows: []
        })
      })
  }

  handlePaymentMethodFilterChange(paymentMethodId) {
    if (
      this.state.params.payment_method_id &&
      this.state.params.payment_method_id === paymentMethodId
    ) {
      this.filters.payment_method_id("")
    } else {
      this.filters.payment_method_id(paymentMethodId)
    }
  }

  handleDueDaysFilterChange(val) {
    if (this.state.params.due_days && this.state.params.due_days === val) {
      this.filters.due_days("")
    } else {
      this.filters.due_days(val)
    }
  }

  handleCreditNotesFilterChange() {
    if (this.state.params.invoice_no === "D") {
      this.filters.invoice_no("")
    } else {
      this.filters.invoice_no("D")
    }
  }

  handleRefundsFilterChange() {
    if (this.state.params.invoice_no === "V") {
      this.filters.invoice_no("")
    } else {
      this.filters.invoice_no("V")
    }
  }

  handleScroll() {
    let pos = 0

    if (document.body.offsetHeight - (window.innerHeight + window.scrollY) <= 50) {
      pos = 50
    }

    if (this.state._bottomMenuPos !== pos) {
      this.setState({
        _bottomMenuPos: pos,
      })
    }
  }

  getOrdersTotalPrice() {
    if (
      !this.state.params.sale__sale_no ||
      this.state.data.length === 0
    ) {
      return 0
    }

    let price = this.state.data
      .filter(order => order.status !== "storno")
      .reduce((acc, order) => {
        return acc + (order.total_price_vat * (order.parent_order_id ? -1 : 1))
      }, 0)

    return this.getPriceInCountryCurrency(price)
  }

  getOrdersWithDueDaysCount() {
    if (
      !this.state.params.sale__sale_no ||
      this.state.data.length === 0
    ) {
      return 0
    }

    return this.state.data
      .filter(order => order.due_days !== 0)
      .length
  }

  getOrdersWithDueDaysTotalPrice() {
    if (
      !this.state.params.sale__sale_no ||
      this.state.data.length === 0
    ) {
      return 0
    }

    let price = this.state.data
      .filter(order => order.status !== "storno")
      .filter(order => order.due_days !== 0)
      .reduce((acc, order) => {
        return acc + (order.total_price_vat * (order.parent_order_id ? -1 : 1))
      }, 0)

    return this.getPriceInCountryCurrency(price)
  }

  getOrdersWithoutDueDaysTotalPrice() {
    if (
      !this.state.params.sale__sale_no ||
      this.state.data.length === 0
    ) {
      return 0
    }

    let price = this.state.data
      .filter(order => order.due_days === 0)
      .filter(order => order.status !== "storno")
      .reduce((acc, order) => {
        return acc + (order.total_price_vat * (order.parent_order_id ? -1 : 1))
      }, 0)

    return this.getPriceInCountryCurrency(price)
  }

  handleRowClass(row, rowIndex) {
    let className = ""

    if (this.lastSaleId && this.lastSaleId !== row.sale_id) {
      this.isWhite = !this.isWhite
      className += " border-top-orange-2"
    }

    className += this.isWhite ? " bg-white" : " bg-grey"

    this.lastSaleId = row.sale_id

    return className
  }

  getPriceInCountryCurrency(price) {
    let countryCode = this.state.data[0].billing_address_country_code

    if (this.state.data.every(order => order.billing_address_country_code === countryCode)) {
      let country = this.state._countries.find(country => country.code === countryCode)
      price = (price * country.exchange_rate).toFixed(country.round_decimal_places)
    }

    return price
  }
}
