import React, {Component} from "react"
import Dropzone from "react-dropzone"
import md5 from "md5"
import {NavLink} from "react-router-dom"
import DatePicker from "react-datepicker"
import moment from "moment"
import API from "../API"
import RBAC from "../RBAC/RBAC"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import styles from "./style.module.css"

export default class PayDPDOrder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: "",
      checksum: "",
      isLoading: false,
      paymentStatus: [],
      paid_date: moment().format("YYYY-MM-DD"),
    }

    this.errorStatus = {
      invalid_shipment_no: "Číslo zásilky neexistuje.",
      invalid_amount: "Nesouhlasí částka za dobírku.",
      already_processed: "Zásilka již byla spárována.",
    }
  }

  render() {
    return this.state.paymentStatus.length > 0
      ? (
        <>
          <table
            className={"table table-striped mb-3"}
          >
            <thead>
            <tr>
              <th>Číslo zásilky</th>
              <th>Číslo zakázky</th>
              <th>Datum</th>
              <th>Částka</th>
              <th>Stav</th>
            </tr>
            </thead>
            <tbody>
            {this.state.paymentStatus.map((paymentStatus, i) => (
              <tr
                key={paymentStatus.id || i}
              >
                <td>{paymentStatus.incoming.shipment_no}</td>
                <td>{paymentStatus.sale
                  ? (
                    <NavLink
                      to={`/sales/${paymentStatus.sale.id}/detail`}
                    >{paymentStatus.sale.sale_no}</NavLink>
                  )
                  : ""
                }</td>
                <td>{paymentStatus.incoming.date}</td>
                <td>{paymentStatus.incoming.amount}</td>
                <td>
                  {paymentStatus.success
                    ? (
                      <span
                        className={"text-success font-weight-bold"}
                      >Platba byla spárována</span>
                    ) : (
                      <span
                        className={`${paymentStatus.status === "already_processed" ? "text-warning" : "text-danger"} font-weight-bold`}
                      >{this.errorStatus[paymentStatus.status] || ""}</span>
                    )
                  }
                </td>
              </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
              <td
                colSpan={3}
              >&nbsp;</td>
              <td
                colSpan={2}
              >
                <span
                  className={"text-info"}
                >{this.getSumAmount()}</span> (
                <span
                  className={"text-success"}
                >{this.getSuccessAmount()}</span>/
                <span
                  className={"text-danger"}
                >{this.getErrorAmount()}</span>)
              </td>
            </tr>
            </tfoot>
          </table>

          <button
            className={"btn btn-primary mr-3 d-print-none"}
            type={"button"}
            onClick={this.handleResetClick.bind(this)}
          >Zaplatit další dobírky
          </button>
        </>
      ) : (
        <>
          <div
            className={"row"}
          >
            <div
              className={"col-lg-2 mb-3"}
            >
              <label>Datum úhrady:</label>
              <DatePicker
                locale={"cs"}
                className={"form-control"}
                selected={moment(this.state.paid_date).toDate()}
                onChange={this.handlePaidDateChange.bind(this)}
                dateFormat={"dd. MM. yyyy"}
              />
            </div>
          </div>

          {this.state.isLoading && <SpinnerWithOverlay/>}

          <Dropzone
            onDrop={this.handleFileChange.bind(this)}
          >
            {({getRootProps, getInputProps}) => (
              <section
                className={styles.dropZone}
              >
                <div
                  {...getRootProps()}
                >
                  <input
                    {...getInputProps()}
                    disabled={this.state.isLoading}
                  />
                  <i
                    className={"fas fa-file-upload fa-3x mb-3"}
                  />
                  <p>Přetažením nebo kliknutím nahrajete soubor s dobírkami DPD&hellip;</p>
                </div>
              </section>
            )}
          </Dropzone>
        </>
      )
  }

  handlePaidDateChange(date) {
    this.setState({
      paid_date: moment(date).format("YYYY-MM-DD"),
    })
  }

  handleFileChange(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      let fileReader = new FileReader()

      fileReader.onload = () => {
        let file = fileReader.result.replace("data:application/octet-stream;base64,", "")

        this.setState({
          file: file,
          checksum: md5(`${file}${RBAC.getToken()}`)
        }, this.sendFile.bind(this))
      }

      fileReader.readAsDataURL(acceptedFiles[0])
    }
  }

  sendFile() {
    this.setState({
      isLoading: true,
    }, () => {
      API.post("/payments/dpd", {
        dpd: {
          paid_date: this.state.paid_date,
          data: this.state.file,
          chk: this.state.checksum,
        }
      }).then(({data}) => {
        this.setState({
          paymentStatus: data.payments,
        })
      }).finally(() => {
        this.setState({
          isLoading: false,
        })
      })
    })
  }

  handleResetClick() {
    this.setState({
      paymentStatus: [],
    })
  }

  getSumAmount() {
    return this.state.paymentStatus.reduce((acc, item) => acc + item.incoming.amount, 0)
  }

  getSuccessAmount() {
    return this.state.paymentStatus.reduce((acc, item) => acc + (item.success ? item.incoming.amount : 0), 0)
  }

  getErrorAmount() {
    return this.state.paymentStatus.reduce((acc, item) => acc + (item.success ? 0 : item.incoming.amount), 0)
  }
}
