import React, {Component} from "react"
import AsyncCreatableSelect from "react-select/async-creatable"
import moment from "moment"
import API from "../API"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {cleanState} from "../Utils"
import COUNTRY from "../Base/Enum/Countries"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import RBAC from "../RBAC/RBAC"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"

export default class MyCustomerRegistrationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type_id: "",
      discount: 0,
      _customerTypes: [],
      registration_delivery_method: "online",
      customer_no: "",
      _name: "",
      prefix: "",
      name: "",
      surname: "",
      _email: "",
      _phone: "",
      _countries: [],
      billing_address_customer_name: "",
      billing_address_billing_name: "",
      billing_address_street: "",
      billing_address_city: "",
      billing_address_post_code: "",
      billing_address_country_code: "CZ",
      _deliveryAddressIsSameAsBillingAddress: true,
      _address_customer_name: "",
      _address_street: "",
      _address_city: "",
      _address_post_code: "",
      _address_country_code: "CZ",
      country_id: 1,
      registration_no: "",
      tax_no: "",
      gdpr: false,
      gdpr_at: "",
      terms: false,
      terms_at: "",
      _customer_note: "",
      _discover_note: "",
      _isRegistrationLoading: false,
      _sponsorNo: "",
      _sponsor: "",
      sponsor_id: RBAC.getUser().customer_id, // by default, sponsor is who register another customer
      _guarantorNo: "",
      _guarantor: "",
      guarantor_id: RBAC.getUser().customer_id,
      _banks: [],
      bank_account: "",
      bank_id: 100,
      license_reg: "",
      _authenticatedCustomerTypeId: CUSTOMER_TYPE.CK,
    }
  }

  componentDidMount() {
    this.getCustomerNo()
    this.getCountries()
    this.getCustomerTypes()
    this.getBanks()
    this.getAuthenticatedCustomerTypeId()
    this.TYPE_DESC = {
      CK: "člen Clubu 21",
      PbP: "poradce bez provize",
      RD: "registrovaná domácnost",
      RZ: "registrovaný zákazník",
      RP: "registrovaný prodejce",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <form
          onSubmit={this.handleRegistrationFormSubmit.bind(this)}
          className={"col-xl-4 col-lg-6 col-md-8"}
        >
          <p
            className={"text-danger"}
          >Modře označená pole jsou povinná.</p>

          <div
            className={"form-group"}
          >
            <label>Zvolte typ registrovaného zákazníka:</label>
            <select
              className={"form-control"}
              value={this.state.type_id}
              onChange={this.handleTypeIdChange.bind(this)}
              required={true}
            >
              <option
                value={""}
              >Zvolte typ registrovaného zákazníka
              </option>
              {this.getFilteredCustomerTypes().map(type => (
                <option
                  key={type.id}
                  value={type.id}
                >{type.name} - {this.TYPE_DESC[type.name] || ""}</option>
              ))}
            </select>
          </div>

          {this.state.type_id === CUSTOMER_TYPE.RD && (
            <div
              className={"form-group"}
            >
              <div
                className={"form-check"}
              >
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  id={"discount"}
                  onChange={this.handleDiscountChange.bind(this)}
                  checked={this.state.discount !== 0}
                />
                <label
                  htmlFor={"discount"}
                  className={"form-check-label"}
                >Poskytnou slevu 10 %</label>
              </div>
            </div>
          )}

          <div
            className={"form-group"}
          >
            <label>Číslo zákazníka:</label>
            <input
              type={"number"}
              className={"form-control"}
              disabled={true}
              value={this.state.customer_no}
              min={1}
              max={999999999999} // 12 digits
            />
          </div>

          {(this.state.type_id === CUSTOMER_TYPE.CK ||
            this.state.type_id === CUSTOMER_TYPE.PP ||
            this.state.type_id === CUSTOMER_TYPE.PbP ||
            this.state.type_id === CUSTOMER_TYPE.RZ ||
            this.state.type_id === CUSTOMER_TYPE.RD ||
            this.state.type_id === CUSTOMER_TYPE.RP) &&
          (
            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-6"}
              >
                <label>Zákaznické číslo sponzora:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={true}
                  onChange={this.handleSponsorNoChange.bind(this)}
                  value={this.state._sponsorNo}
                />
              </div>
              <div
                className={"form-group col-6"}
              >
                <label>&nbsp;</label>
                <button
                  type={"button"}
                  className={"btn btn-primary w-100"}
                  onClick={this.handleSponsorNoValidationClick.bind(this)}
                >Ověřit číslo sponzora
                </button>
              </div>
              <div
                className={"form-group col-12"}
              >
                <label>Jméno sponzora:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  value={this.state._sponsor}
                  disabled={true}
                />
              </div>
            </div>
          )}

          {this.state.type_id === CUSTOMER_TYPE.RZ && (
            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-6"}
              >
                <label>Zákaznické číslo garanta:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={true}
                  onChange={this.handleGuarantorNoChange.bind(this)}
                  value={this.state._guarantorNo}
                />
              </div>
              <div
                className={"form-group col-6"}
              >
                <label>&nbsp;</label>
                <button
                  type={"button"}
                  className={"btn btn-primary w-100"}
                  onClick={this.handleGuarantorNoValidationClick.bind(this)}
                >Ověřit číslo garanta
                </button>
              </div>
              <div
                className={"form-group col-12"}
              >
                <label>Jméno garanta:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  value={this.state._guarantor}
                  disabled={true}
                />
              </div>
            </div>
          )}

          <div
            className={"form-group"}
          >
            <label>Titul:</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handlePrefixChange.bind(this)}
              value={this.state.prefix}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Jméno a příjmení:</label>
            <input
              type={"text"}
              className={"form-control"}
              required={true}
              autoFocus={true}
              onChange={this.handleNameChange.bind(this)}
              value={this.state._name}
              minLength={3}
              maxLength={30}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Email:</label>
            <input
              type={"email"}
              className={"form-control"}
              onChange={this.handleEmailChange.bind(this)}
              value={this.state._email}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Telefon:</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handlePhoneChange.bind(this)}
              value={this.state._phone}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Stát:</label>
            <select
              className={"form-control"}
              onChange={this.handleCountryIdChange.bind(this)}
              value={this.state.country_id}
              required={true}
            >
              {this.state._countries.map(country => (
                <option
                  key={country.id}
                  value={country.id}
                >{country.name}</option>
              ))}
            </select>
          </div>

          <div
            className={"form-group"}
          >
            <label>IČO:</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handleRegistrationNoChange.bind(this)}
              value={this.state.registration_no}
              minLength={8}
              maxLength={8}
              required={
                this.state.type_id === CUSTOMER_TYPE.PP ||
                this.state.type_id === CUSTOMER_TYPE.RZ ||
                this.state.type_id === CUSTOMER_TYPE.RP
              }
            />
          </div>

          <div
            className={"form-group mb-3"}
          >
            <label>POUZE PRO PLÁTCE DPH - DIČ:</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handleTaxNoChange.bind(this)}
              value={this.state.tax_no}
              minLength={1}
              maxLength={30}
            />
          </div>

          {(this.state.type_id === CUSTOMER_TYPE.PP ||
            this.state.type_id === CUSTOMER_TYPE.RZ) && (
            <>
              <div
                className={"form-row"}
              >
                <div
                  className={"form-group col-8"}
                >
                  <label>Číslo účtu:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    onChange={this.handleBankAccountChange.bind(this)}
                    value={this.state.bank_account}
                    required={this.state.type_id === CUSTOMER_TYPE.PP}
                  />
                </div>

                <div
                  className={"form-group col-4"}
                >
                  <label>Kód banky:</label>
                  <select
                    className={"form-control"}
                    onChange={this.handleBankIdChange.bind(this)}
                    value={this.state.bank_id || 1}
                  >
                    {this.state._banks.map(bank => (
                      <option
                        key={bank.id}
                        value={bank.id}
                      >{bank.code} ({bank.name})</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}

          <strong
            className={"form-group d-block"}
          >Fakturační adresa</strong>

          <div
            className={"form-group"}
          >
            <label>Jméno a příjmení zákazníka: (maximálně 30 znaků, v případě potřeby pokračujte prosím na druhém poli)</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handleBillingAddressCustomerName.bind(this)}
              value={this.state.billing_address_customer_name}
              maxLength={30}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Fakturační jméno a příjmení zákazníka: (maximálně 60 znaků)</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handleBillingAddressBillingName.bind(this)}
              value={this.state.billing_address_billing_name || ""}
              maxLength={60}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
            <input
              type={"text"}
              className={"form-control"}
              onChange={this.handleBillingAddressStreet.bind(this)}
              value={this.state.billing_address_street}
              required={true}
            />
          </div>

          <div
            className={"form-group select-required"}
          >
            <label>Město:</label>
            <AsyncCreatableSelect
              value={{
                label: this.state.billing_address_city,
                value: this.state.billing_address_city,
              } || {}}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
              onChange={this.handleBillingAddressCitySelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              required={true}
              styles={ReactSelectCustomStyles}
              formatCreateLabel={value => value}
            />
          </div>

          <div
            className={"form-group select-required"}
          >
            <label>PSČ:</label>
            <AsyncCreatableSelect
              value={{
                label: this.state.billing_address_post_code,
                value: this.state.billing_address_post_code,
              } || {}}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
              onChange={this.handleBillingAddressPostCodeSelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              required={true}
              styles={ReactSelectCustomStyles}
              formatCreateLabel={value => value}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Stát:</label>
            <select
              className={"form-control"}
              onChange={this.handleBillingAddressCountryCodeChange.bind(this)}
              value={this.state.billing_address_country_code}
              required={true}
            >
              {this.state._countries.map(country => (
                <option
                  key={country.id}
                  value={country.code}
                >{country.name}</option>
              ))}
            </select>
          </div>

          <strong
            className={"form-group d-block"}
          >Doručovací adresa</strong>

          <div
            className={"form-group form-check"}
          >
            <input
              type={"checkbox"}
              className={"form-check-input mt-0"}
              id={"delivery_address_is_same"}
              onChange={this.handleDeliveryAddressIsSameAsBillingAddressChange.bind(this)}
              checked={this.state._deliveryAddressIsSameAsBillingAddress}
            />
            <label
              htmlFor={"delivery_address_is_same"}
            >Doručovací adresa je stejná jako fakturační</label>
          </div>

          {!this.state._deliveryAddressIsSameAsBillingAddress && (
            <>
              <div
                className={"form-group"}
              >
                <label>Doručovací jméno a příjmení zákazníka:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  onChange={this.handleAddressCustomerNameChange.bind(this)}
                  value={this.state._address_customer_name}
                  required={true}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  onChange={this.handleAddressStreet.bind(this)}
                  value={this.state._address_street}
                  required={true}
                />
              </div>

              <div
                className={"form-group select-required"}
              >
                <label>Město:</label>
                <AsyncCreatableSelect
                  value={{
                    label: this.state._address_city || "",
                    value: this.state._address_city || "",
                  } || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
                  onChange={this.handleDeliveryAddressCitySelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  required={true}
                  styles={ReactSelectCustomStyles}
                  formatCreateLabel={value => value}
                />
              </div>

              <div
                className={"form-group select-required"}
              >
                <label>PSČ:</label>
                <AsyncCreatableSelect
                  value={{
                    label: this.state._address_post_code,
                    value: this.state._address_post_code,
                  } || {}}
                  loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
                  onChange={this.handleDeliveryAddressPostCodeSelect.bind(this)}
                  noOptionsMessage={() => "Nic nenalezeno"}
                  loadingMessage={() => "Načítám"}
                  required={true}
                  styles={ReactSelectCustomStyles}
                  formatCreateLabel={value => value}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Stát:</label>
                <select
                  className={"form-control"}
                  onChange={this.handleAddressCountryCodeChange.bind(this)}
                  value={this.state._address_country_code}
                  required={true}
                >
                  {this.state._countries.map(country => (
                    <option
                      key={country.id}
                      value={country.code}
                    >{country.name}</option>
                  ))}
                </select>
              </div>
            </>
          )}

          <div
            className={"form-group mt-3"}
          >
            <label>Poznámka:</label>
            <textarea
              className={"form-control"}
              value={this.state._customer_note}
              onChange={this.handleCustomerNoteChange.bind(this)}
              rows={3}
            />
          </div>

          <div
            className={"form-group"}
          >
            <label>Uvítáme když nám napíšete odkud popřípadě od koho jste se o nás
              dozvěděli:</label>
            <input
              type={"text"}
              className={"form-control"}
              value={this.state._discover_note}
              onChange={this.handleDiscoverNoteChange.bind(this)}
            />
          </div>

          <div
            className={"form-group form-check my-4"}
          >
            <input
              type={"checkbox"}
              className={"form-check-input mt-0"}
              id={"terms"}
              onChange={this.handleTermsChange.bind(this)}
              checked={this.state.terms}
              required={true}
            />
            <label
              htmlFor={"terms"}
              className={"fs-lg text-body"}
            >
              Souhlasím s <u>
              <a
                href={"#/common/terms"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={"text-body"}
              >obchodními podmínkami a s nakládáním s osobními údaji</a></u>
            </label>
          </div>

          <button
            className={"btn btn-primary"}
            type={"submit"}
            disabled={this.state._isRegistrationLoading}
          >
            Odeslat registrační údaje
            {this.state._isRegistrationLoading && <SpinnerInButton/>}
          </button>
        </form>
      </div>
    )
  }

  getCustomerNo() {
    API.get("/my/registration/customer_no")
      .then(({data}) => {
        this.setState({
          customer_no: data.customer_no,
        })
      })
  }

  getCountries() {
    API.get("/my/registration/countries")
      .then(({data}) => {
        this.setState({
          _countries: data.objects.filter(country => country.id === COUNTRY.CZECH_REPUBLIC || country.id === COUNTRY.SLOVAK_REPUBLIC),
        })
      })
  }

  getCustomerTypes() {
    API.get("/customerType")
      .then(({data}) => {
        this.setState({
          _customerTypes: data.objects,
        })
      })
  }

  getBanks() {
    API.get("/banks")
      .then(({data}) => {
        this.setState({
          _banks: data.objects,
        })
      })
  }

  getARESData() {
    API.get(`/customer/ares?ic=${this.state.registration_no}`)
      .then(({data}) => {
        let createdAt = ""
        let createdBy = ""
        if (data.VBAS && data.VBAS.DV)
          createdAt = data.VBAS.DV
        if (data.VBAS && data.VBAS.RRZ && data.VBAS.RRZ.ZU && data.VBAS.RRZ.ZU.NZU)
          createdBy = data.VBAS.RRZ.ZU.NZU
        if (createdBy && createdAt)
          this.setState({
            license_reg: `${createdBy}, ${createdAt}`
          })
      })
  }

  getAuthenticatedCustomerTypeId() {
    API.get(`/customers/${RBAC.getUser().customer_id}`)
      .then(({data}) => {
        this.setState({
          _authenticatedCustomerTypeId: data.type_id,
        })
      })
  }

  handleTypeIdChange(e) {
    this.setState({
      type_id: parseInt(e.target.value, 10),
    }, () => {
      if (
        this.state.type_id === CUSTOMER_TYPE.MS ||
        this.state.type_id === CUSTOMER_TYPE.PP ||
        this.state.type_id === CUSTOMER_TYPE.RP ||
        this.state.type_id === CUSTOMER_TYPE.RZ
      ) {
        this.setState({
          license_reg: "DATA Z ARES NEZÍSKÁNA - OPRAVTE", // it may be replaced by ARES data
        })
      }
    })
  }

  handleDiscountChange(e) {
    this.setState({
      discount: e.target.checked ? 10 : 0,
    })
  }

  getFilteredCustomerTypes() {
    return this.state._customerTypes.filter(type => {
      if (this.state._authenticatedCustomerTypeId === CUSTOMER_TYPE.RD) {
        return type.id === CUSTOMER_TYPE.RD // RD can register only RDs
      } else {
        return type.id === CUSTOMER_TYPE.RD ||
          type.id === CUSTOMER_TYPE.CK ||
          type.id === CUSTOMER_TYPE.PbP ||
          type.id === CUSTOMER_TYPE.RZ ||
          type.id === CUSTOMER_TYPE.RP
      }
    })
  }

  handleSponsorNoChange(e) {
    this.setState({
      _sponsorNo: e.target.value,
    })
  }

  handleSponsorNoValidationClick() {
    let typeIds = `=${[
      CUSTOMER_TYPE.CK,
      CUSTOMER_TYPE.NE,
      CUSTOMER_TYPE.MS,
      CUSTOMER_TYPE.PbP,
      CUSTOMER_TYPE.PP,
    ].join("|")}`

    API.get(`/customers?customer_no==${this.state._sponsorNo}&limit=1&type_id=${typeIds}`)
      .then(({data}) => {
        if (data.objects.length > 0) {
          this.setState({
            sponsor_id: data.objects[0].id,
            _sponsor: `${data.objects[0].name} ${data.objects[0].surname}`,
          })
        } else {
          this.setState({
            sponsor_id: "",
            _sponsor: "Sponzor nenalezen",
          })
        }
      })
  }

  handleGuarantorNoChange(e) {
    this.setState({
      _guarantorNo: e.target.value,
    })
  }

  handleGuarantorNoValidationClick() {
    API.get(`/customers?customer_no==${this.state._guarantorNo}&is_guarantor=1&limit=1`)
      .then(({data}) => {
        if (data.objects.length > 0) {
          this.setState({
            guarantor_id: data.objects[0].id,
            _guarantor: `${data.objects[0].name} ${data.objects[0].surname}`,
          })
        } else {
          this.setState({
            guarantor_id: "",
            _guarantor: "Garant nenalezen",
          })
        }
      })
  }

  handlePrefixChange(e) {
    this.setState({
      prefix: e.target.value,
    })
  }

  handleNameChange(e) {
    let name = e.target.value
    let firstSpacePosition = name.indexOf(" ")

    this.setState({
      _name: name,
      name: firstSpacePosition > -1 ? name.substr(0, firstSpacePosition) : "",
      surname: firstSpacePosition > -1 ? name.substr(firstSpacePosition + 1) : name,
      billing_address_customer_name: name,
      _address_customer_name: name,
    })
  }

  handleEmailChange(e) {
    this.setState({
      _email: e.target.value,
    })
  }

  handlePhoneChange(e) {
    this.setState({
      _phone: e.target.value,
    })
  }

  handleCountryIdChange(e) {
    this.setState({
      country_id: parseInt(e.target.value, 10),
    })
  }

  handleRegistrationNoChange(e) {
    this.setState({
      registration_no: e.target.value,
      customer_no: this.state.type_id === CUSTOMER_TYPE.RZ ? e.target.value : this.state.customer_no,
    }, () => {
      if (this.state.registration_no.length === 8) {
        this.getARESData()
      }
    })
  }

  handleTaxNoChange(e) {
    this.setState({
      tax_no: e.target.value,
    })
  }

  handleBankAccountChange(e) {
    this.setState({
      bank_account: e.target.value,
    })
  }

  handleBankIdChange(e) {
    this.setState({
      bank_id: parseInt(e.target.value, 10),
    })
  }

  handleBillingAddressCustomerName(e) {
    this.setState({
      billing_address_customer_name: e.target.value,
      _address_customer_name: e.target.value,
    })
  }

  handleBillingAddressBillingName(e) {
    this.setState({
      billing_address_billing_name: e.target.value,
    })
  }

  handleBillingAddressStreet(e) {
    this.setState({
      billing_address_street: e.target.value,
      _address_street: e.target.value,
    })
  }

  handleAddressCityChange(val, cb) {
    API.get(`/my/registration/posts?limit=0&offset=0&city=^${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.city,
          label: `${item.city} (${item.post_code})`
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressCitySelect(option) {
    this.setState({
      billing_address_city: option.__isNew__ ? option.value : option.city,
      billing_address_post_code: option.__isNew__ ? this.state.billing_address_post_code : option.post_code,
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state.billing_address_post_code : option.post_code,
    })
  }

  handleAddressPostCodeChange(val, cb) {
    API.get(`/my/registration/posts?limit=0&offset=0&post_code=${val}`)
      .then(({data}) => {
        data.objects.forEach(item => Object.assign(item, {
          value: item.city,
          label: `${item.post_code} (${item.city})`
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressPostCodeSelect(option) {
    this.setState({
      billing_address_post_code: option.__isNew__ ? option.value : option.post_code,
      billing_address_city: option.__isNew__ ? this.state.billing_address_city : option.city,
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
    })
  }

  handleDeliveryAddressIsSameAsBillingAddressChange(e) {
    this.setState({
      _deliveryAddressIsSameAsBillingAddress: e.target.checked,
    })
  }

  handleBillingAddressCountryCodeChange(e) {
    this.setState({
      billing_address_country_code: e.target.value,
      _address_country_code: e.target.value,
    })
  }

  handleAddressCustomerNameChange(e) {
    this.setState({
      _address_customer_name: e.target.value,
    })
  }

  handleAddressStreet(e) {
    this.setState({
      _address_street: e.target.value,
    })
  }

  handleDeliveryAddressCitySelect(option) {
    this.setState({
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state._address_post_code : option.post_code,
    })
  }

  handleDeliveryAddressPostCodeSelect(option) {
    this.setState({
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
    })
  }

  handleAddressCountryCodeChange(e) {
    this.setState({
      _address_country_code: e.target.value,
    })
  }

  handleCustomerNoteChange(e) {
    this.setState({
      _customer_note: e.target.value,
    })
  }

  handleDiscoverNoteChange(e) {
    this.setState({
      _discover_note: e.target.value,
    })
  }

  handleTermsChange(e) {
    let checked = e.target.checked
    this.setState({
      gdpr: checked,
      gdpr_at: checked ? moment().format("YYYY-MM-DD") : "",
      terms: checked,
      terms_at: checked ? moment().format("YYYY-MM-DD") : ""
    })
  }

  handleRegistrationFormSubmit(e) {
    e.preventDefault()

    if (
      !this.state._email ||
      !this.state._phone
    ) {
      alert("Email nebo telefon není vyplněn.")
    }

    this.setState({
      _isRegistrationLoading: true,
    })

    let customer = cleanState(this.state)
    customer.address = []
    customer.contact = []
    customer.note = []

    customer.address.push({
      customer_name: this.state._address_customer_name,
      street: this.state._address_street,
      city: this.state._address_city,
      post_code: this.state._address_post_code,
      country_code: this.state._address_country_code,
    })

    if (this.state._email) {
      customer.contact.push({
        value: this.state._email,
        type_id: 1,
      })
    }

    if (this.state._phone) {
      customer.contact.push({
        value: this.state._phone,
        type_id: 5,
      })
    }

    if (this.state._customer_note)
      customer.note.push({
        value: `Poznámka zákazníka při registraci: ${this.state._customer_note}`,
        is_warn: true,
        is_pub: false,
      })

    if (this.state._discover_note)
      customer.note.push({
        value: `Od koho jste se o nás dozvěděli: ${this.state._discover_note}`,
        is_warn: false,
        is_pub: true,
      })

    API.post("/my/registration/customer", customer)
      .then(({data}) => {
        alert("Registrace zákazníka proběhla úspěšně.")
        this.props.history.push("/my-customers/tree")
      }).finally(() => {
      this.setState({
        _isRegistrationLoading: false,
      })
    })
  }
}
