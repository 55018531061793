import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarLogs = () => {
  return (
    <Switch>
      <Route
        path={"/logs"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/logs"}
              className={"nav-link"}
              activeClassName={"active"}
            >Logy IS</NavLink>
            <NavLink
              exact={true}
              to={"/logs/external"}
              className={"nav-link"}
              activeClassName={"active"}
            >Externí logy</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarLogs
