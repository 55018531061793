import React, {Component, Fragment} from "react"
import AsyncCreatableSelect from "react-select/async-creatable"
import moment from "moment"
import logo from "../assets/logo.png"
import API from "../API"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {cleanState} from "../Utils"
import COUNTRY from "../Base/Enum/Countries"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"

export default class RegistrationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type_id: CUSTOMER_TYPE.RD,
      registration_delivery_method: "online",
      customer_no: "",
      _name: "",
      prefix: "",
      name: "",
      surname: "",
      _email: "",
      _phone: "",
      _countries: [],
      billing_address_customer_name: "",
      billing_address_billing_name: "",
      billing_address_street: "",
      billing_address_city: "",
      billing_address_post_code: "",
      billing_address_country_code: "CZ",
      _deliveryAddressIsSameAsBillingAddress: true,
      _address_customer_name: "",
      _address_street: "",
      _address_city: "",
      _address_post_code: "",
      _address_country_code: "CZ",
      country_id: 1,
      registration_no: "",
      tax_no: "",
      gdpr: false,
      gdpr_at: "",
      terms: false,
      terms_at: "",
      _customer_note: "",
      _discover_note: "",
      _isRegistrationLoading: false,
      //
      _showLogin: false,
      _loginCode: "",
      _isLoginLoading: false,
    }
  }

  componentDidMount() {
    this.getCustomerNo()
    this.getCountries()
    this.loginCodeRef = React.createRef()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        {this.state._showLogin ? (
          <form
            onSubmit={this.handleLoginFormSubmit.bind(this)}
            className={"mx-auto mt-5 col-xl-4 col-lg-6 col-md-8 col-12"}
          >
            <div
              className={"form-group text-center"}
            >
              <img
                src={logo}
                alt={"Missiva logo"}
                height={100}
              />
              <h1
                className={"mt-3"}
              >MOJE&nbsp;MISSIVA</h1>
              <p>Na telefon uvedený při registraci byla odeslána SMS s přihlašovacím kódem, který
                opište do formuláře níže.</p>
            </div>

            <div
              className={"form-group col-xl-8 offset-xl-2"}
            >
              <p
                className={"mb-2"}
              >Zadejte přihlašovací kód z SMS:</p>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Přihlašovací kód"}
                onChange={this.handleLoginCodeChange.bind(this)}
                value={this.state._loginCode}
                ref={this.loginCodeRef}
              />
            </div>

            <div
              className={"form-group col-xl-8 offset-xl-2"}
            >
              <button
                type={"submit"}
                className={"btn btn-primary"}
                disabled={!this.state._loginCode || this.state._isLoginLoading}
              >
                Přihlásit se do moje.missiva.cz
                {this.state._isLoginLoading && <SpinnerInButton/>}
              </button>
            </div>
          </form>
        ) : (
          <form
            onSubmit={this.handleRegistrationFormSubmit.bind(this)}
            className={"mx-auto mt-5 col-xl-4 col-lg-6 col-md-8 col-12"}
          >
            <div
              className={"form-group text-center"}
            >
              <img
                src={logo}
                alt={"Missiva logo"}
                height={100}
              />
              <h1
                className={"mt-3"}
              >MOJE&nbsp;MISSIVA</h1>
              <h5
                className={"mb-3"}
              >Registrace nového zákazníka</h5>

              <p>Nevíte si rady s registrací? Volejte Po - Pá, 9 - 15h, tel: 417 590 911.</p>

              <p
                className={"text-danger"}
              >Modře označená pole jsou povinná.</p>
            </div>

            <div
              className={"form-group"}
            >
              <label>Číslo zákazníka:</label>
              <input
                type={"number"}
                className={"form-control"}
                disabled={true}
                value={this.state.customer_no}
                min={1}
                max={999999999999} // 12 digits
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Titul:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handlePrefixChange.bind(this)}
                value={this.state.prefix}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Jméno a příjmení:</label>
              <input
                type={"text"}
                className={"form-control"}
                required={true}
                autoFocus={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state._name}
                minLength={3}
                maxLength={30}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Email:</label>
              <input
                type={"email"}
                className={"form-control"}
                required={true}
                onChange={this.handleEmailChange.bind(this)}
                value={this.state._email}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Telefon:</label>
              <input
                type={"text"}
                className={"form-control"}
                required={true}
                onChange={this.handlePhoneChange.bind(this)}
                value={this.state._phone}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Stát:</label>
              <select
                className={"form-control"}
                onChange={this.handleCountryIdChange.bind(this)}
                value={this.state.country_id}
                required={true}
              >
                {this.state._countries.map(country => (
                  <option
                    key={country.id}
                    value={country.id}
                  >{country.name}</option>
                ))}
              </select>
            </div>

            <div
              className={"form-group"}
            >
              <label>IČO:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleRegistrationNoChange.bind(this)}
                value={this.state.registration_no}
                minLength={8}
                maxLength={8}
              />
            </div>

            <div
              className={"form-group mb-3"}
            >
              <label>POUZE PRO PLÁTCE DPH - DIČ:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleTaxNoChange.bind(this)}
                value={this.state.tax_no}
                minLength={1}
                maxLength={30}
              />
            </div>

            <strong
              className={"form-group d-block"}
            >Fakturační adresa</strong>

            <div
              className={"form-group"}
            >
              <label>Jméno a příjmení zákazníka: (maximálně 30 znaků, v případě potřeby pokračujte prosím na druhém poli)</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleBillingAddressCustomerName.bind(this)}
                value={this.state.billing_address_customer_name}
                maxLength={30}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Fakturační jméno a příjmení zákazníka: (maximálně 60 znaků)</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleBillingAddressBillingName.bind(this)}
                value={this.state.billing_address_billing_name || ""}
                maxLength={60}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleBillingAddressStreet.bind(this)}
                value={this.state.billing_address_street}
                required={true}
              />
            </div>

            <div
              className={"form-group select-required"}
            >
              <label>Město:</label>
              <AsyncCreatableSelect
                value={{
                  label: this.state.billing_address_city,
                  value: this.state.billing_address_city,
                } || {}}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
                onChange={this.handleBillingAddressCitySelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                required={true}
                styles={ReactSelectCustomStyles}
                formatCreateLabel={value => value}
              />
            </div>

            <div
              className={"form-group select-required"}
            >
              <label>PSČ:</label>
              <AsyncCreatableSelect
                value={{
                  label: this.state.billing_address_post_code,
                  value: this.state.billing_address_post_code,
                } || {}}
                loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
                onChange={this.handleBillingAddressPostCodeSelect.bind(this)}
                noOptionsMessage={() => "Nic nenalezeno"}
                loadingMessage={() => "Načítám"}
                required={true}
                styles={ReactSelectCustomStyles}
                formatCreateLabel={value => value}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Stát:</label>
              <select
                className={"form-control"}
                onChange={this.handleBillingAddressCountryCodeChange.bind(this)}
                value={this.state.billing_address_country_code}
                required={true}
              >
                {this.state._countries.map(country => (
                  <option
                    key={country.id}
                    value={country.code}
                  >{country.name}</option>
                ))}
              </select>
            </div>

            <strong
              className={"form-group d-block"}
            >Doručovací adresa</strong>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                className={"form-check-input mt-0"}
                id={"delivery_address_is_same"}
                onChange={this.handleDeliveryAddressIsSameAsBillingAddressChange.bind(this)}
                checked={this.state._deliveryAddressIsSameAsBillingAddress}
              />
              <label
                htmlFor={"delivery_address_is_same"}
              >Doručovací adresa je stejná jako fakturační</label>
            </div>

            {!this.state._deliveryAddressIsSameAsBillingAddress && (
              <Fragment>
                <div
                  className={"form-group"}
                >
                  <label>Doručovací jméno a příjmení zákazníka:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    onChange={this.handleAddressCustomerNameChange.bind(this)}
                    value={this.state._address_customer_name}
                    required={true}
                  />
                </div>

                <div
                  className={"form-group"}
                >
                  <label>Ulice včetně čísla popisného, evidenčního nebo i orientačního:</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    onChange={this.handleAddressStreet.bind(this)}
                    value={this.state._address_street}
                    required={true}
                  />
                </div>

                <div
                  className={"form-group select-required"}
                >
                  <label>Město:</label>
                  <AsyncCreatableSelect
                    value={{
                      label: this.state._address_city || "",
                      value: this.state._address_city || "",
                    } || {}}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressCityChange.bind(this))}
                    onChange={this.handleDeliveryAddressCitySelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    styles={ReactSelectCustomStyles}
                    formatCreateLabel={value => value}
                  />
                </div>

                <div
                  className={"form-group select-required"}
                >
                  <label>PSČ:</label>
                  <AsyncCreatableSelect
                    value={{
                      label: this.state._address_post_code,
                      value: this.state._address_post_code,
                    } || {}}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleAddressPostCodeChange.bind(this))}
                    onChange={this.handleDeliveryAddressPostCodeSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    styles={ReactSelectCustomStyles}
                    formatCreateLabel={value => value}
                  />
                </div>

                <div
                  className={"form-group"}
                >
                  <label>Stát:</label>
                  <select
                    className={"form-control"}
                    onChange={this.handleAddressCountryCodeChange.bind(this)}
                    value={this.state._address_country_code}
                    required={true}
                  >
                    {this.state._countries.map(country => (
                      <option
                        key={country.id}
                        value={country.code}
                      >{country.name}</option>
                    ))}
                  </select>
                </div>
              </Fragment>
            )}

            <div
              className={"form-group mt-3"}
            >
              <label>Poznámka:</label>
              <textarea
                className={"form-control"}
                value={this.state._customer_note}
                onChange={this.handleCustomerNoteChange.bind(this)}
                rows={3}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Uvítáme když nám napíšete odkud popřípadě od koho jste se o nás
                dozvěděli:</label>
              <input
                type={"text"}
                className={"form-control"}
                value={this.state._discover_note}
                onChange={this.handleDiscoverNoteChange.bind(this)}
              />
            </div>

            <div
              className={"form-group form-check my-4"}
            >
              <input
                type={"checkbox"}
                className={"form-check-input mt-0"}
                id={"terms"}
                onChange={this.handleTermsChange.bind(this)}
                checked={this.state.terms}
                required={true}
              />
              <label
                htmlFor={"terms"}
                className={"fs-lg text-body"}
              >
                Souhlasím s <u>
                <a
                  href={"#/common/terms"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className={"text-body"}
                >obchodními podmínkami a s nakládáním s osobními údaji</a></u>
              </label>
            </div>

            <button
              className={"btn btn-primary"}
              type={"submit"}
              disabled={this.isRegistrationBtnDisabled() || this.state._isRegistrationLoading}
            >
              Odeslat registrační údaje
              {this.state._isRegistrationLoading && <SpinnerInButton/>}
            </button>
          </form>
        )}
      </div>
    )
  }

  getCustomerNo() {
    API.get("/my/registration/customer_no")
      .then(({data}) => {
        this.setState({
          customer_no: data.customer_no,
        })
      })
  }

  getCountries() {
    API.get("/my/registration/countries")
      .then(({data}) => {
        this.setState({
          _countries: data.objects.filter(country => country.id === COUNTRY.CZECH_REPUBLIC || country.id === COUNTRY.SLOVAK_REPUBLIC),
        })
      })
  }

  handlePrefixChange(e) {
    this.setState({
      prefix: e.target.value,
    })
  }

  handleNameChange(e) {
    let name = e.target.value
    let firstSpacePosition = name.indexOf(" ")

    this.setState({
      _name: name,
      name: firstSpacePosition > -1 ? name.substr(0, firstSpacePosition) : "",
      surname: firstSpacePosition > -1 ? name.substr(firstSpacePosition + 1) : name,
      billing_address_customer_name: name,
      _address_customer_name: name,
    })
  }

  handleEmailChange(e) {
    this.setState({
      _email: e.target.value,
    })
  }

  handlePhoneChange(e) {
    this.setState({
      _phone: e.target.value,
    })
  }

  handleCountryIdChange(e) {
    this.setState({
      country_id: parseInt(e.target.value, 10),
    })
  }

  handleRegistrationNoChange(e) {
    this.setState({
      registration_no: e.target.value,
    })
  }

  handleTaxNoChange(e) {
    this.setState({
      tax_no: e.target.value,
    })
  }

  handleBillingAddressCustomerName(e) {
    this.setState({
      billing_address_customer_name: e.target.value,
      _address_customer_name: e.target.value,
    })
  }

  handleBillingAddressBillingName(e) {
    this.setState({
      billing_address_billing_name: e.target.value,
    })
  }

  handleBillingAddressStreet(e) {
    this.setState({
      billing_address_street: e.target.value,
      _address_street: e.target.value,
    })
  }

  handleAddressCityChange(val, cb) {
    API.get(`/my/registration/posts?limit=0&offset=0&city=^${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.city,
          label: `${item.city} (${item.post_code})`
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressCitySelect(option) {
    this.setState({
      billing_address_city: option.__isNew__ ? option.value : option.city,
      billing_address_post_code: option.__isNew__ ? this.state.billing_address_post_code : option.post_code,
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state.billing_address_post_code : option.post_code,
    })
  }

  handleAddressPostCodeChange(val, cb) {
    API.get(`/my/registration/posts?limit=0&offset=0&post_code=${val}`)
      .then(({data}) => {
        data.objects.forEach(item => Object.assign(item, {
          value: item.city,
          label: `${item.post_code} (${item.city})`
        }))
        cb(data.objects)
      })
  }

  handleBillingAddressPostCodeSelect(option) {
    this.setState({
      billing_address_post_code: option.__isNew__ ? option.value : option.post_code,
      billing_address_city: option.__isNew__ ? this.state.billing_address_city : option.city,
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
    })
  }

  handleDeliveryAddressIsSameAsBillingAddressChange(e) {
    this.setState({
      _deliveryAddressIsSameAsBillingAddress: e.target.checked,
    })
  }

  handleBillingAddressCountryCodeChange(e) {
    this.setState({
      billing_address_country_code: e.target.value,
      _address_country_code: e.target.value,
    })
  }

  handleAddressCustomerNameChange(e) {
    this.setState({
      _address_customer_name: e.target.value,
    })
  }

  handleAddressStreet(e) {
    this.setState({
      _address_street: e.target.value,
    })
  }

  handleDeliveryAddressCitySelect(option) {
    this.setState({
      _address_city: option.__isNew__ ? option.value : option.city,
      _address_post_code: option.__isNew__ ? this.state._address_post_code : option.post_code,
    })
  }

  handleDeliveryAddressPostCodeSelect(option) {
    this.setState({
      _address_post_code: option.__isNew__ ? option.value : option.post_code,
      _address_city: option.__isNew__ ? this.state._address_city : option.city,
    })
  }

  handleAddressCountryCodeChange(e) {
    this.setState({
      _address_country_code: e.target.value,
    })
  }

  handleCustomerNoteChange(e) {
    this.setState({
      _customer_note: e.target.value,
    })
  }

  handleDiscoverNoteChange(e) {
    this.setState({
      _discover_note: e.target.value,
    })
  }

  handleTermsChange(e) {
    let checked = e.target.checked
    this.setState({
      gdpr: checked,
      gdpr_at: checked ? moment().format("YYYY-MM-DD") : "",
      terms: checked,
      terms_at: checked ? moment().format("YYYY-MM-DD") : ""
    })
  }

  isRegistrationBtnDisabled() {
    return !this.state.name ||
      !this.state.surname ||
      !this.state._email ||
      !this.state._phone ||
      !this.state.billing_address_customer_name ||
      !this.state.billing_address_street ||
      !this.state.billing_address_city ||
      !this.state.billing_address_post_code ||
      !this.state.terms
  }

  handleRegistrationFormSubmit(e) {
    e.preventDefault()
    this.setState({
      _isRegistrationLoading: true,
    })

    let customer = cleanState(this.state)
    customer.address = []
    customer.contact = []
    customer.note = []

    customer.address.push({
      customer_name: this.state._address_customer_name,
      street: this.state._address_street,
      city: this.state._address_city,
      post_code: this.state._address_post_code,
      country_code: this.state._address_country_code,
    })

    customer.contact.push({
      value: this.state._email,
      type_id: 1,
    })

    customer.contact.push({
      value: this.state._phone,
      type_id: 5,
    })

    if (this.state._customer_note)
      customer.note.push({
        value: `Poznámka zákazníka při registraci: ${this.state._customer_note}`,
        is_warn: true,
        is_pub: false,
      })

    if (this.state._discover_note)
      customer.note.push({
        value: `Od koho jste se o nás dozvěděli: ${this.state._discover_note}`,
        is_warn: false,
        is_pub: true,
      })

    API.post("/my/registration/customer", customer)
      .then(({data}) => {
        this.setState({
          _showLogin: true,
        }, () => {
          API.post("/auth/customer", {
            customer_no: this.state.customer_no,
          })
          this.loginCodeRef.current.focus()
        })
      }).finally(() => {
      this.setState({
        _isRegistrationLoading: false,
      })
    })
  }

  handleLoginFormSubmit(e) {
    e.preventDefault()
    this.setState({
      _isLoginLoading: true,
    })

    API.post("/auth/sms", {
      customer_no: this.state.customer_no,
      code: this.state._loginCode,
    }).then(({data}) => {
      this.props.loginHandler(data.token)
    }).finally(() => {
      this.setState({
        _isLoginLoading: false,
      })
    })
  }

  handleLoginCodeChange(e) {
    this.setState({
      _loginCode: e.target.value,
    })
  }
}
