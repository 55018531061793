import React from "react"
import iconTab from "./icon-tab.png"

const Help = () => (
  <div
    className={"row"}
  >
    <div
      className={"col-xl-5 col-lg-7 mx-auto"}
    >
      <h1>Informace o aplikaci moje.missiva.cz a nápověda</h1>

      <h3>Informace o aplikaci</h3>

      <ul>
        <li>moje.missiva.cz je webová aplikace, podobně jako elektronické bankovnictví, stránky
          pojišťoven pro online sjednání pojistek nebo e-shopy.
        </li>
        <li>Vzhled aplikace vychází z prostředí webových aplikací - většina informací je rozložena a
          organizována svisle a zleva doprava. Důležité informace jsou v levém sloupci, méně
          důležité ve středním a nejméně důležité (informativní) pak v pravém.
          <ul>
            <li>Postupujeme od obecného ke konkrétnímu = od přehledových tabulek, ve kterých lze
              vyhledávat a filtrovat po detail zákazníka nebo objednávky, kde lze vidět podrobné
              info a pracovat s ním.
            </li>
          </ul>
        </li>
        <li>
          <strong>Doporučený internetový prohlížeč je Google Chrome nebo Mozilla Firefox.</strong>
          <ul>
            <li>V Internet Exploreru nebo MS Edge funguje aplikace také s výjimkou posuvníku ve
              skupinových informacích. Přesto lze přehled skupinových informací ovládat viz nápověda
              níže.
            </li>
          </ul>
        </li>
        <li>
          <strong>POZOR</strong> pokud není stránka se zadanými daty uložena, budou data ztracena
          při jejím zavření nebo při přechodu na jinou stránku. Na některých stránkách jsou
          výstražná hlášení, vyžadující potvrzení před opuštěním stránky.
        </li>
      </ul>

      <h5>Funkce aplikace moje.missiva.cz</h5>
      <ul>
        <li>aplikace má pokročilé funkce, které umožňují <strong>vyhledávat podle</strong> zadaných
          hodnot (<strong>příjmení zákazníka, číslo zákazníka, číslo objednávky,
            zakázky</strong> apod.). Aplikace při vyhledávání sama nabízí průběžně výsledky
          vyhledávání, které upřesňuje s tím jak přibývá zadaných informací.
        </li>
        <li>
          <strong>Funkce aplikace nebo množství informací v aplikaci může být omezeno typem
            zákazníka nebo jeho pozicí ve skupině.</strong>
        </li>
      </ul>

      <h3>Nápověda</h3>

      <h5>Odkazy, menu a tlačítka v aplikaci</h5>

      <ul>
        <li>Po přihlášení do aplikace moje.missiva.cz jsou na úvodní stránce <strong>vlevo
          vyhledávací pole pro vyhledání zakázky, objednávky nebo zákazníka</strong>, ale
          také <strong>ovládací tlačítka, která slouží pro vytvoření nové zakázky nebo nového
            zákazníka</strong>. Dále jsou zde tlačítka pro rychlý přístup k funkcím aplikace nebo k
          dalším užitečným informacím.
        </li>
        <li>V horní části je vodorovné hlavní menu, které umožňuje přechod mezi hlavními částmi
          aplikace. Na podstránkách může být pod hlavním menu ještě doplňkové, vodorovné ovládací
          menu.
        </li>
        <li>Důležitá pole (zpravidla ve kterých se vyhledává podle unikátních dat) jsou označena
          modrým orámováním.
        </li>
        <li>Vybrané sloupce (podle kterých je vyfiltrováno) jsou označeny zeleně.</li>
        <li>Seřazené informace indikují červené šipky v záhlaví tabulek.</li>
        <li>Některé typy zákazníků mají nad přehledovými tabulkami objednávek zeleno-modrá tlačítka
          rychlých filtrů, která fungují jako přepínače (klik zapnuto / klik vypnuto).
        </li>
      </ul>

      <h3>Jak nakupovat v aplikaci moje.missiva.cz</h3>

      <ol>
        <li>po přihlášení kliknete na tlačítko Vytvořit novou objednávku</li>
        <li>vyberete způsob doručení (napravo vidíte doručovací adresu)</li>
        <li>vyberete způsob platby</li>
        <li>dále v tabulce produkty můžete zadat číslo produktu nebo jeho název; Produkty se budou
          průběžně vyhledávat.
        </li>
        <li>Entrem nebo kliknutím na název vyhledaného produktu, přidáte produkt do objednávky</li>
        <li>Entrem přidáte počet kusů; Počet kusů lze také ihned zadat napsáním čísla</li>
        <li>Buď můžete pokračovat zadáním dalšího produktu nebo
          <ul>
            <li>uložíte koncept objednávky tlačítkem Uložit jako rozpracovanou - to vám umožní
              uložit objednávku a dokončit ji později,
            </li>
            <li>nebo kliknete na tlačítko Odeslat a objednávku odešlete ke zpracování do Missiva
              spol. s r. o.
            </li>
          </ul>
        </li>
      </ol>

      <h5>Popis objednávkového formuláře</h5>

      <ul>
        <li>pod přehledem produktů v objednávce je možné přidat poznámku pro Zákaznické centrum
          Missiva spol. s r. o. napsáním textu do pole Napiš poznámku a kliknutím na tlačítko Přidat
          poznámku k objednávce.
        </li>
        <li>v případě, že jste členem Clubu 21 a máte věrnostní Kč za vaše nákupy, můžete za ně
          nakoupit, pokud je zvolíte jako způsob platby;
        </li>
        <li>v případě, že jste členem Clubu 21, vidíte pod přehledem produktů v objednávce vaše
          zákaznické bod, procento bonusu a body chybějící do vyššího bonusu;
        </li>
      </ul>

      <h5>Ovládání aplikace moje.missiva.cz</h5>

      <p>V aplikaci existuje více způsobů jak vyřešit úkol a můžete ho začít řešit z více míst
        (např. vytvoření nového zákazníka lze provést z úvodní stránky, ale také z přehledové
        tabulky Zákazníci);</p>

      <h6>V aplikaci moje.missiva.cz lze také využívat klávesnici:</h6>

      <ul>
        <li>pro pohyb na další formulářový prvek - pole <strong>TABulátor</strong>
          <img
            src={iconTab}
            alt={"tabulátor"}
            width={25}
            className={"ml-1"}
          />
        </li>
        <li>pro návrat na předchozí formulářový prvek - pole <strong>SHIFT+TABulátor</strong></li>
        <li>pro výběr možnosti z nabídky nebo zvýšení či snížení počtu <strong>svislé šipky</strong>
        </li>
        <li>pro potvrzení a provedení akce <strong>enter</strong> (při výběru možnosti z nabídky)
        </li>
        <li>pro “stisknutí tlačítka” nebo zaškrtnutí políčka <strong>mezerník</strong></li>
        <li>pohyb po stránce nahoru a dolu <strong>PageUp</strong> a <strong>PageDown</strong></li>
        <li>vodorovný posuv stránky <strong>SHIFT+kolečko myši</strong> (pro posuv v tabulce do ní
          nejprve klikněte)
        </li>
        <li>nová záložka prohlížeče s Missiva IS <strong>CTRL+levé tlačítko myši</strong></li>
      </ul>

      <h6>Otevírání více oken / záložek s Missiva IS</h6>

      <p>Stejně jako internetové stránky lze otevírat (CTRL+levé tlačítko myši) další záložky nebo
        okna s aplikací moje.missiva.cz.</p>
      <p><strong>POZOR</strong> - pokud v novém okně / záložce zadáte informace, je nutné je uložit
        a obnovit původní záložku / okno, aby se změny projevily i ve všech ostatních otevřených
        oknech / záložkách.</p>

      <h3>Vysvětlení některých částí aplikace</h3>

      <p><strong>Objednávka pro neregistrovaného za zákaznické ceny</strong> - funkce je dostupná
        pouze pro některé typy zákazníků - členů Clubu 21.<br/>
        Zaškrtnutím tohoto políčka vytvoříte objednávku za zákaznické ceny pro neregistrovaného
        zákazníka a získáte zjednodušený daňový doklad bez jména zákazníka (odběratele).</p>

      <p>V <strong>Ceníku</strong> lze vyhledat produkty ze sortimentu Missiva spol. s r. o.,
        zjistit jejich číslo, cenu, body, velikost i váhu balení. <strong>V ceníku nelze
          nakupovat</strong>.</p>

      <p><strong>Moje skupinové body</strong> - je část aplikace, kde mají členové Clubu 21 přehled
        o struktuře, bodech a obratu jejich skupiny. </p>

      <ul>
        <li>Informace v přehledu skupiny lze filtrovat podle typu zákazníka a dosaženého bonusu
          zákazníka.
          <ul>
            <li>filtry lze nastavit do výchozího stavu kliknutím na tlačítko “Vymazat filtry” vpravo
              nahoře;
            </li>
          </ul>
        </li>
        <li>Lze také vybrat období, za které chcete informace vidět.</li>
        <li>informace lze exportovat a tisknout (podle toho, které sloupce si zobrazíte, tak bude
          vypadat váš export nebo tisk);
        </li>
        <li>volba Schovat nulové skryje členy stromu, kteří nemají žádný obrat ani body ve vybraném
          období.
        </li>
      </ul>
    </div>
  </div>
)

export default Help
