import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import {getParams} from "../../Utils"
import ExportButton from "../../Base/ExportButton"
import productKingExportColumn from "../../fixtures/productKingExportColumn"
import Grid from "../../Base/Grid"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import getProductNameByCountry from "../../Base/getProductNameByCountry"
import CONTACT_TYPE from "../../Base/Enum/ContactType"

export default class ProductKing extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      products: [],
      customerTypes: [],
      templates: [],
      total: 0,
      template_id: "",
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        date_start: moment().subtract(1, "months").format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        customer_types: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        by_gold: 0,
        paid_orders_only: false,
      }, params),
    }

    this.apiUrl = "/reports/productKing"
    this.appUrl = "/reports/contests/product-king"
  }

  componentDidMount() {
    this.getCustomerTypes()
    this.getTemplates()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-md-6 mb-3"}
        >
          <NavLink
            className={"btn btn-primary mr-3"}
            to={"/reports/contests/product-king/templates"}
          >Přehled a tvorba šablon</NavLink>
        </div>

        <div
          className={"col-md-6 text-right"}
        >
          <ExportButton
            exportName={"product-king"}
            fileName={"produktovy-kral"}
            params={this.getExportParams()}
            columns={productKingExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"form-group col-md-2 col-6"}
        >
          <label>Od:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_start).toDate()}
            onChange={this.handleDateStartChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"form-group col-md-2 col-6"}
        >
          <label>Do:</label>
          <DatePicker
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.date_end).toDate()}
            onChange={this.handleDateEndChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-12"}
        >
          <hr/>
        </div>

        <div
          className={"form-group col-12"}
        >
          <label>Použít šablonu:</label>
          <select
            className={"form-control"}
            onChange={this.handleTemplateChange.bind(this)}
            value={this.state.template_id || ""}
          >
            <option
              value={""}
            >Vybrat šablonu
            </option>
            {this.state.templates.map(template => (
              <option
                key={template.id}
                value={template.id}
              >{template.name}</option>
            ))}
          </select>
        </div>

        <div
          className={"col-12"}
        >
          <hr/>
        </div>

        <div
          className={"form-group col-md-6"}
        >
          <label>Typ zákazníka:</label>
          <Select
            value={this.state.customerTypes.filter(type => this.state.params.customer_types.indexOf(type.id) > -1)}
            placeholder={"Typ zákazníka"}
            isMulti={true}
            options={this.state.customerTypes}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCustomerTypeChange.bind(this)}
          />
        </div>

        <div
          className={"form-group col-md-3"}
        >
          <label
            className={"mt-lg-4"}
          >
            <input
              type={"checkbox"}
              className={"mr-2"}
              checked={!!this.state.params.by_gold}
              onChange={this.handleByGoldChange.bind(this)}
            />
            Sjednotit dle zlatého poradce
          </label>
        </div>

        <div
          className={"form-group col-md-3"}
        >
          <label>Počítat z:</label>
          <div>
            <div
              className={"form-check form-check-inline"}
            >
              <label
                className={"form-check-label"}
              >
                <input
                  type={"radio"}
                  name={"paid_orders_only"}
                  value={1}
                  checked={this.state.params.paid_orders_only === true}
                  onChange={this.handlePaidOrdersOnlyChange.bind(this)}
                />
                Uhrazených zakázek
              </label>
            </div>
          </div>

          <div>
            <div
              className={"form-check form-check-inline"}
            >
              <label
                className={"form-check-label"}
              >
                <input
                  className={"form-check-label"}
                  type={"radio"}
                  name={"paid_orders_only"}
                  value={0}
                  checked={this.state.params.paid_orders_only === false}
                  onChange={this.handlePaidOrdersOnlyChange.bind(this)}
                />
                Pořízených zakázek
              </label>
            </div>
          </div>
        </div>

        <div
          className={"form-group col-12"}
        >
          <label>Produkty:</label>
          <AsyncSelect
            value={this.state.products}
            isMulti={true}
            loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
            onChange={this.handleProductSearchSelect.bind(this)}
            noOptionsMessage={() => "Nic nenalezeno"}
            loadingMessage={() => "Načítám"}
            placeholder={"Vyhledat produkt"}
            required={true}
            styles={ReactSelectCustomStyles}
          />
        </div>

        <div
          className={"form-group col-12 text-right"}
        >
          <button
            className={"btn btn-primary mr-3"}
            onClick={() => {
              if (this.state.products.length === 0) {
                alert("Vyberte alespoň jeden produkt.")
              } else {
                this.getData()
              }
            }}
            disabled={this.state.products.length === 0}
          >Zobrazit přehled
          </button>

          <button
            className={"btn btn-light"}
            onClick={this.handleResetFiltersClick.bind(this)}
          >Vymazat filtry
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              ref={ref => this.gridTableRef = ref}
              columns={this.getColumns()}
              data={this.state.data}
              onTableChange={this.handleTableChange.bind(this)}
              pagination={paginationFactory({
                page: (this.state.params.offset / this.state.params.limit) + 1,
                sizePerPage: this.state.params.limit,
                totalSize: this.state.total,
              })}
              noDataIndication={"Nic nenalezeno"}
              sort={this.sort || {}}
            />
          </ScrollableTable>

        </div>
      </div>
    )
  }

  // overridden
  getData() {
    let {offset, limit, ...body} = this.state.params

    body.products = this.state.products.map(product => {
      return {
        product_id: product.id,
      }
    })

    this.setState({
      isLoading: true,
    })

    API.post(`${this.apiUrl}?offset=${offset}&limit=${limit}`, body)
      .then(({data}) => {
        this.setState({
          data: data.objects,
          total: data.meta.total_count,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  // overridden
  saveSettings() {
  }

  getColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
    }, {
      text: "Příjmení zák.",
      dataField: "surname",
    }, {
      text: "Jméno zák.",
      dataField: "name",
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state.customerTypes,
    }, {
      text: "Počet bodů",
      dataField: "report",
      formatter: this.formatReport.bind(this),
    }, {
      text: "Město",
      dataField: "billing_address_city",
    }, {
      text: "E-mail",
      dataField: "contact",
      formatter: this.formatEmail.bind(this),
    }, {
      text: "Č. sponzora",
      dataField: "sponsor__customer_no",
      formatter: this.formatSponsorCustomerNo.bind(this),
    }, {
      text: "Jméno sponzora",
      dataField: "sponsor__name",
      formatter: this.formatSponsorName.bind(this),
    }, {
      text: "Příjmení sponzora",
      dataField: "sponsor__surname",
      formatter: this.formatSponsorSurname.bind(this),
    }, {
      text: "Č. zlatého poradce",
      dataField: "gold__customer_no",
      formatter: this.formatGoldCustomerNo.bind(this),
    }, {
      text: "Jméno zlatého poradce",
      dataField: "gold__name",
      formatter: this.formatGoldName.bind(this),
    }, {
      text: "Příjmení zlatého poradce",
      dataField: "gold__surname",
      formatter: this.formatGoldSurname.bind(this),
    },]
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    let types = formatExtraData.filter(customerType => customerType.id === cell)

    if (types.length > 0) {
      return types[0].name
    }

    return ""
  }

  formatReport(cell, row, rowIndex, formatExtraData) {
    if (cell && cell.length > 0) {
      return cell[0].total
    }

    return ""
  }

  formatEmail(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return cell
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .map(contact => (
          <div key={contact.id}>
            <a
              href={`mailto:${contact.value}`}
            >{contact.value}</a>
          </div>
        ))
    }

    return ""
  }

  formatSponsorCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (row.sponsor) {
      return (
        <NavLink
          to={`/customers/${row.sponsor.id}/detail`}
        >{row.sponsor.customer_no}</NavLink>
      )
    }
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    return row.sponsor ? (row.sponsor.name || "") : ""
  }

  formatSponsorSurname(cell, row, rowIndex, formatExtraData) {
    return row.sponsor ? (row.sponsor.surname || "") : ""
  }

  formatGoldCustomerNo(cell, row, rowIndex, formatExtraData) {
    if (row.gold) {
      return (
        <NavLink
          to={`/customers/${row.gold.id}/detail`}
        >{row.gold.customer_no}</NavLink>
      )
    }
  }

  formatGoldName(cell, row, rowIndex, formatExtraData) {
    return row.gold ? (row.gold.name || "") : ""
  }

  formatGoldSurname(cell, row, rowIndex, formatExtraData) {
    return row.gold ? (row.gold.surname || "") : ""
  }

  getCustomerTypes() {
    API.get("/customerType?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          customerTypes: data.objects.map(type => Object.assign({}, type, {
            label: type.name,
            value: type.id,
          }))
        })
      })
  }


  getTemplates() {
    API.get("/product-king-template")
      .then(({data}) => {
        this.setState({
          templates: data.objects,
        })
      })
  }

  handleDateStartChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_start: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleDateEndChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date_end: moment(date).format("YYYY-MM-DD")
      })
    })
  }

  handleCustomerTypeChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        customer_types: options.map(option => option.id)
      })
    })
  }

  handleByGoldChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        by_gold: e.target.checked ? 1 : 0
      })
    })
  }

  handlePaidOrdersOnlyChange(e) {
    let value = parseInt(e.target.value, 10)

    this.setState({
      params: Object.assign({}, this.state.params, {
        paid_orders_only: !!value,
      })
    })
  }

  handleTemplateChange({target}) {
    const template = this.state.templates.find(template => template.id === +target.value)

    this.setState({
      template_id: template ? template.id : null,
      products: template ? template.products_data.map(product => ({
        ...product,
        value: product.id,
        label: getProductNameByCountry(product)
      })) : []
    })

  }

  handleResetFiltersClick() {
    this.setState({
      params: {
        offset: 0,
        limit: 25,
        date_start: moment().subtract(1, "months").format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        customer_types: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        by_gold: 0,
      },
      products: [],
      template_id: null
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(product => Object.assign(product, {
          value: product.id,
          label: getProductNameByCountry(product),
        }))
        cb(data)
      })
  }

  handleProductSearchSelect(options) {
    this.setState({
      products: options || [],
    })
  }

  getExportParams() {
    const {date_start, date_end, customer_types, by_gold} = this.state.params
    const {products} = this.state

    return {
      date_start,
      date_end,
      by_gold,
      customer_types: customer_types.join(","),
      products: products.map(product => product.id).join(",")
    }
  }
}
