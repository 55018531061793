import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import AsyncSelect from "react-select/async"
import moment from "moment"
import RBAC from "../RBAC/RBAC"
import API from "../API"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import formatCustomerName from "../Base/formatCustomerName"

export default class MyHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: {},
      notification: "",
    }
  }

  componentDidMount() {
    this.getData()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <div
            className={"jumbotron"}
          >
            <h1>Vítejte v aplikaci MOJE Missiva</h1>
            <p>Na této obrazovce najdete základní informace. Pro další práci můžete použít rychlé
              funkce a vyhledávání níže nebo vyberte položku z hlavního menu nahoře.</p>

            {this.state.notification && (
              <h2
                className={"bg-warning text-white mb-3 d-inline-block p-1"}
                dangerouslySetInnerHTML={{
                  __html: this.state.notification
                }}
              />
            )}

            <h4>Objednávky</h4>
            <NavLink
              to={"/my-sales/add"}
              className={"btn btn-primary mr-3"}
            >Vytvořit novou objednávku</NavLink>
            <NavLink
              to={"/my-sales"}
              className={"btn btn-primary"}
            >Přehled objednávek</NavLink>

            {!this.state.customer.is_my_access_limited && (
              <>
                <div
                  className={"form-group mt-4"}
                >
                  <label>Vyhledat objednávku podle čísla faktury nebo přijmení zákazníka:</label>
                  <AsyncSelect
                    value={{}}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleOrderSearchChange.bind(this))}
                    onChange={this.handleOrderSearchSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                    styles={ReactSelectCustomStyles}
                  />
                </div>

                {(this.state.customer.type_id === CUSTOMER_TYPE.CK ||
                  this.state.customer.type_id === CUSTOMER_TYPE.PP ||
                  this.state.customer.type_id === CUSTOMER_TYPE.PbP ||
                  this.state.customer.type_id === CUSTOMER_TYPE.MS) && (
                  <>
                    <hr
                      className={"my-4"}
                    />

                    <h4>Zákazníci</h4>
                    <NavLink
                      to={"/my-customers/registration"}
                      className={"btn btn-primary mr-3"}
                    >Registrovat nového zákazníka</NavLink>
                    <NavLink
                      to={"/my-customers/customers"}
                      className={"btn btn-primary"}
                    >Přehled zákazníků - zákaznický servis</NavLink>

                    <div
                      className={"form-group mt-4"}
                    >
                      <label>Vyhledat zákazníka podle příjmení nebo zákaznického čísla:</label>
                      <AsyncSelect
                        value={{}}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerSearchChange.bind(this))}
                        onChange={this.handleCustomerSearchSelect.bind(this)}
                        noOptionsMessage={() => "Nic nenalezeno"}
                        loadingMessage={() => "Načítám"}
                        components={{
                          Option: ReactSelectTableOption
                        }}
                        styles={ReactSelectCustomStyles}
                      />
                    </div>
                  </>
                )}

                {this.state.customer.type_id !== CUSTOMER_TYPE.RD && (
                  <>
                    <hr
                      className={"my-4"}
                    />

                    <h4>Informace o mém účtu</h4>
                    <p>
                      <NavLink
                        to={`/my-customers/${this.state.customer.id}/detail`}
                        className={"btn btn-primary mr-3"}
                      >Detail mého účtu</NavLink>

                      <NavLink
                        to={"/my-customers/tree"}
                        className={"btn btn-primary mr-3"}
                      >Zobrazit moje skupinové body</NavLink>
                      Věrnostní kredit: <strong>Věrnostní Vám sdělíme e-mailem nebo
                      telefonicky.</strong>
                    </p>
                  </>
                )}

                <hr
                  className={"my-4"}
                />

                <h4>Užitečné odkazy</h4>
                <a
                  href={"https://www.missiva.cz/aktualni-informace/"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  className={"btn btn-primary mr-3"}
                >Aktuální informace z missiva.cz</a>

                <NavLink
                  to={"/my-products/price-list"}
                  className={"btn btn-primary mr-3"}
                >Ceník</NavLink>

                <NavLink
                  to={"/common/help"}
                  className={"btn btn-primary mr-3"}
                >Nápověda</NavLink>
              </>)}
          </div>
        </div>
      </div>
    )
  }

  getData() {
    let customer = RBAC.getUser()

    if (customer && customer.customer_id) {
      API.get(`/my/customer/${customer.customer_id}`)
        .then(({data}) => {
          this.setState({
            customer: data,
          })
        })
    }

    API.get("/local_text")
      .then(({data}) => {
        // notification for 4+1 promo is now only notification... :facepalm:
        let text = data.objects.find(item => item.local_key === "sale_4_plus_1_homepage")

        this.setState({
          notification: text.value || "",
        })
      })
  }

  handleOrderSearchChange(val, cb) {
    API.get(`/my/orders?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: `${item.invoice_no}|${item.sale.sale_no}|${item.customer.surname || ""} ${item.customer.name || ""}|${item.billing_address_street}|${item.billing_address_city || ""}`,
          label: `${item.invoice_no}|${item.sale.sale_no}|${item.customer.surname || ""} ${item.customer.name || ""}|${item.billing_address_street}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleOrderSearchSelect(option) {
    this.props.history.push(`/my-sales/${option.sale_id}/detail/${option.invoice_no}`)
  }

  handleCustomerSearchChange(val, cb) {
    API.get(`/my/customers?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data = data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
        }))
        cb(data)
      })
  }

  handleCustomerSearchSelect(option) {
    this.props.history.push(`/my-customers/${option.id}/detail`)
  }
}
