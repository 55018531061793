import React, {Component} from "react"
import {OrderedMap} from "immutable"
import Select from "react-select"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import API from "../API"
import MyCustomerTreeNode from "./MyCustomerTreeNode"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import {ReactSelectCustomerTree} from "../Base/ReactSelectCustomerTree"
import {serializeParams} from "../Utils"
import RBAC from "../RBAC/RBAC"
import BONUS_COLOR from "../Base/Enum/BonusColor"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import styles from "./styles.module.css"

export default class MyCustomerTree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: {},
      date: moment().format("YYYY-MM"),
      customerTypes: [],
      customerTypeOptions: [],
      percentOptions: this.getPercentOptions(), // all percents selected by default
      hideCustomersWithZeros: 0, // 0 - show customers with zeros, 1 - hide customers with zeros
      maxLimit: 1,
      currentLimit: 2,
      isLoading: false,
      clickedNodeId: null,
      openedNodes: [],
      isTreeOpened: false,

      columns: OrderedMap({
        customer_no: {
          visible: true,
          alwaysVisible: true,
          text: "Č. zákazníka",
          key: "customer_no",
          format: this.formatCustomerNo.bind(this),
        },
        name: {
          visible: true,
          alwaysVisible: true,
          text: "Jméno",
          key: "name",
        },
        type_id: {
          visible: true,
          alwaysVisible: true,
          text: "Typ zákazníka",
          key: "type_id",
          format: this.formatTypeId.bind(this),
        },
        total_points_on_way: {
          visible: true,
          text: "Celkové body na cestě",
          key: "points",
          format: this.formatTotalPointsOnWay.bind(this),
        },
        points: {
          visible: true,
          text: "Vlastní body",
          key: "points",
          format: this.formatPoints.bind(this),
        },
        total_points: {
          visible: true,
          text: "Celkové body",
          key: "points",
          format: this.formatTotalPoints.bind(this),
        },
        total_turn_over: {
          visible: true,
          text: "Celkový obrat",
          key: "points",
          format: this.formatTotalTurnOver.bind(this),
        },
        rest_points: {
          visible: true,
          text: "Zbytkové body",
          key: "points",
          format: this.formatRestPoints.bind(this),
        },
        bonus: {
          visible: true,
          text: "Procento",
          key: "points",
          format: this.formatBonus.bind(this),
        },
        created_at: {
          visible: true,
          text: "Datum registrace",
          key: "created_at",
          format: this.formatCreatedAt.bind(this),
        },
        membership_extended_at: {
          visible: true,
          text: "Datum prodloužení",
          key: "membership_extended_at",
          format: this.formatMembershipExtendedAt.bind(this),
        }
      }),
    }
  }

  componentDidMount() {
    this.getCustomerTreeMaxLimit()
    this.getTypes()
    this.getColumnVisibility()
    this.getCustomerById(RBAC.getUser().customer_id)

    if (this.props.match.params.customerId) {
      this.wantedCustomerId = parseInt(this.props.match.params.customerId, 10)
    }
  }

  render() {
    return (
      <>
        <div
          className={"row d-print-none"}
        >
          <div
            className={"col-lg-2"}
          >
            <div
              className={"form-group"}
            >
              <label>Datum:</label>
              <DatePicker
                showMonthYearPicker={true}
                locale={"cs"}
                className={"form-control"}
                selected={moment(this.state.date, "YYYY-MM").toDate()}
                onChange={this.handleDateChange.bind(this)}
                dateFormat={"MM/yyyy"}
              />
            </div>
          </div>

          <div
            className={"col-lg-9"}
          >
            <div
              className={"form-group"}
            >
              <button
                className={"btn btn-primary mt-lg-4 mr-3"}
                type={"button"}
                onClick={this.handleOpenWholeTreeChange.bind(this)}
              >{this.state.currentLimit === this.state.maxLimit ? "Zabalit celý strom" : "Rozbalit celý strom"}
              </button>

              {this.state.customer.id && (
                <>
                  <ReactHTMLTableToExcel
                    className={"btn btn-primary mr-3 mt-xl-4"}
                    table={"table-tree-xls-export"}
                    filename={`${this.state.date}-${this.state.customer.surname || ""}-${this.state.customer.name || ""}`}
                    sheet={`${this.state.date}-${this.state.customer.surname || ""}-${this.state.customer.name || ""}`}
                    buttonText={"Exportovat XLS"}
                  />
                  <button
                    className={"btn btn-primary mt-xl-4"}
                    onClick={() => window.print()}
                  >Tisk
                  </button>
                </>
              )}
            </div>
          </div>

          <div
            className={"col-12 text-right"}
          >
            <div
              className={"form-group"}
            >
              <Select
                className={"mr-3 d-inline-block react-bootstrap-table-filter text-left"}
                onChange={this.handleColumnToggleChange.bind(this)}
                value={"default"}
                options={this.getOptions()}
                closeMenuOnSelect={false}
                placeholder={"Zobrazit/schovat sloupce"}
                styles={ReactSelectCustomStyles}
              />

              <button
                className={"btn btn-light"}
                type={"button"}
                onClick={this.handleResetFilters.bind(this)}
              >Vymazat filtry
              </button>
            </div>
          </div>
        </div>

        <div
          className={"row d-print-none"}
        >
          <div
            className={"col-xl-5 col-lg-6"}
          >
            <div
              className={"form-group"}
            >
              <label>Zobrazit zákazníky typu:</label>
              <Select
                value={this.state.customerTypeOptions}
                placeholder={"Typ zákazníka"}
                isMulti={true}
                options={this.state.customerTypes}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={ReactSelectCustomStyles}
                onChange={this.handleCustomerTypeChange.bind(this)}
              />
            </div>
          </div>
          <div
            className={"col-xl-5 col-lg-6"}
          >
            <div
              className={"form-group"}
            >
              <label>Zobrazit zákazníky s bonusem větším než:</label>
              <Select
                value={this.state.percentOptions}
                placeholder={"Procenta"}
                isMulti={true}
                options={this.getPercentOptions()}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={ReactSelectCustomerTree}
                onChange={this.handlePercentChange.bind(this)}
              />
            </div>
          </div>
          <div
            className={"col-xl-2"}
          >
            <label
              className={"mt-xl-4"}
            >
              <input
                type={"checkbox"}
                onChange={this.handleHideCustomersWithZeros.bind(this)}
                checked={this.state.hideCustomersWithZeros}
              /> Schovat nulové
            </label>
          </div>
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-12 mt-3"}
          >
            {this.state.isLoading && <SpinnerWithOverlay/>}

            {this.state.customer.id && (
              <table
                className={`table table-hover table-tree nowrap m-0 ${this.state.isLoading ? "invisible" : ""}`}
                id={"table-tree-xls-export"}
              >
                <thead
                  className={"thead-light thead-sticky"}
                >
                <tr>
                  <th
                    className={"d-none"}
                  >Index
                  </th>
                  {this.state.columns.keySeq().toArray().map(key => {
                    return this.state.columns.get(key).visible && (
                      <th
                        key={key}
                        className={this.state.columns.get(key).className || ""}
                      >{this.state.columns.get(key).text}</th>
                    )
                  })}
                </tr>
                </thead>

                <tbody>
                {this.state.customer.id && (
                  <MyCustomerTreeNode
                    node={this.state.customer}
                    columns={this.state.columns}
                    depth={1}
                    index={"1.1"}
                    toggleChildrenHandler={this.handleToggleChildren.bind(this)}
                    customerTypes={this.state.customerTypeOptions}
                    currentLimit={this.state.currentLimit}
                    rowClickHandler={this.handleRowClick.bind(this)}
                    clickedNodeId={this.state.clickedNodeId}
                    openedNodes={this.state.openedNodes}
                    wantedCustomerId={this.wantedCustomerId}
                  />
                )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    )
  }

  getData(customerId) {
    if (customerId || this.state.customer.id) {
      this.setState({
        isLoading: true,
      })
    }

    if (customerId || this.state.customer.id) {
      API.get(`/my/customerTree/${customerId || this.state.customer.id}${this.getParams(this.state.currentLimit)}`)
        .then(({data}) => {
          this.setState({
            customer: data,
            isLoading: false,
          }, () => {
            if (this.state.isTreeOpened) {
              this.openTree()
            }
          })
        })
    }
  }

  getParams(limit) {
    let params = {
      limit: limit,
      from: this.state.date,
      bonus: this.state.percentOptions.length === 0
        ? undefined
        : `>=${this.state.percentOptions
          .sort((a, b) => a.value - b.value)
          .map(percent => percent.value)[0]}`, // select minimum value
      non_zero: this.state.hideCustomersWithZeros,
    }
    return serializeParams(params)
  }

  getCustomerTreeMaxLimit() {
    API.get("/customerTreeMaxLimit")
      .then(res => {
        this.setState({
          maxLimit: res.data.limit_max
        })
      })
  }

  getTypes() {
    API.get("/customerType")
      .then(({data}) => {
        data = data.objects.map(type => {
          return {
            label: type.name,
            value: type.id,
          }
        })

        this.setState({
          customerTypes: data,
          customerTypeOptions: data, // all options selected by default
        })
      })
  }

  getCustomerById(id) {
    API.get(`/customers/${id}`)
      .then(({data}) => {
        this.setState({
          currentLimit: 2,
          openedNodes: [id],
        }, () => {
          this.getData(data.id)
        })
      })
  }

  handleDateChange(date) {
    this.setState({
      date: moment(date).format("YYYY-MM")
    }, this.getData.bind(this))
  }

  handleOpenWholeTreeChange() {
    if (this.state.currentLimit === this.state.maxLimit) {
      this.setState({
        currentLimit: 2,
        openedNodes: [],
        isTreeOpened: false,
      })
    } else {
      this.setState({
        currentLimit: this.state.maxLimit,
        isTreeOpened: true,
      }, this.getData.bind(this))
    }
  }

  handleColumnToggleChange(option) {
    let {key} = option
    this.setState({
      columns: this.state.columns.set(key, Object.assign({}, this.state.columns.get(key), {
        visible: !this.state.columns.get(key).visible,
      }))
    }, this.persistColumnVisibility.bind(this))
  }

  handleResetFilters() {
    this.setState({
      customerTypeOptions: this.state.customerTypes,
      percentOptions: this.getPercentOptions(),
    }, this.getData.bind(this))
  }

  getOptions() {
    let arr = []
    this.state.columns.keySeq().toArray().forEach(key => {
      if (!this.state.columns.get(key).alwaysVisible)
        arr.push({
          key: key,
          value: this.state.columns.get(key).text,
          label: `${this.state.columns.get(key).visible ? "\u2611" : "\u2610"} ${this.state.columns.get(key).text}`,
        })
    })
    return arr
  }

  getPercentOptions() {
    return [{
      label: "0%",
      value: 0,
      color: BONUS_COLOR[0],
    }, {
      label: "3%",
      value: 3,
      color: BONUS_COLOR[3],
    }, {
      label: "6%",
      value: 6,
      color: BONUS_COLOR[6],
    }, {
      label: "9%",
      value: 9,
      color: BONUS_COLOR[9],
    }, {
      label: "12%",
      value: 12,
      color: BONUS_COLOR[12],
    }, {
      label: "15%",
      value: 15,
      color: BONUS_COLOR[15],
    }, {
      label: "18%",
      value: 18,
      color: BONUS_COLOR[18],
    }, {
      label: "21%",
      value: 21,
      color: BONUS_COLOR[21],
    },]
  }

  handleHideCustomersWithZeros(e) {
    this.setState({
      hideCustomersWithZeros: e.target.checked ? 1 : 0
    }, this.getData.bind(this))
  }

  handlePercentChange(options) {
    this.setState({
      percentOptions: options,
    }, this.getData.bind(this))
  }

  handleCustomerTypeChange(options) {
    this.setState({
      customerTypeOptions: options || [],
    })
  }

  handleToggleChildren(nodeId, depth) {
    let node = this.state.openedNodes.find(openNode => openNode === nodeId)

    this.setState({
      openedNodes: node
        ? this.state.openedNodes.filter(openNode => openNode !== nodeId)
        : [...this.state.openedNodes, nodeId]
    }, () => {
      if (this.state.currentLimit <= depth) {
        this.setState({
          currentLimit: depth + 1,
        }, this.getData.bind(this))
      }
    })
  }

  handleRowClick(clickedNodeId) {
    this.setState({clickedNodeId})
  }

  formatCustomerNo(customerNo, customer, depth) {
    return (
      <>
        <span
          className={`${styles.circle} mr-2`}
          style={{
            backgroundColor: BONUS_COLOR[
              customer.points && customer.points.length > 0
                ? (customer.points[0].bonus || 0)
                : 0
              ]
          }}
        />
        {depth <= 2 && (
          <NavLink
            to={`/my-customers/${customer.id}/detail`}
            className={"mr-2"}
          >{customerNo}</NavLink>
        )}
        {customer.surname}
      </>
    )
  }

  formatTypeId(typeId) {
    let customerType = this.state.customerTypes.find(customerType => customerType.value === typeId)

    if (customerType) {
      return customerType.label
    }

    return ""
  }

  formatPoints(points) {
    if (points && points.length > 0) {
      return points[0].points || 0
    }

    return 0
  }

  formatTotalPointsOnWay(points) {
    if (points && points.length > 0) {
      return points[0].total_points_on_way || 0
    }

    return 0
  }

  formatTotalPoints(points) {
    if (points && points.length > 0) {
      return points[0].total_points || 0
    }

    return 0
  }

  formatTotalTurnOver(points) {
    if (points && points.length > 0) {
      return points[0].total_turn_over || 0
    }

    return 0
  }

  formatRestPoints(points) {
    if (points && points.length > 0) {
      return points[0].rest_points || 0
    }

    return 0
  }

  formatBonus(points) {
    if (points && points.length > 0) {
      return points[0].bonus || 0
    }

    return 0
  }

  formatCreatedAt(createdAt) {
    if (createdAt) {
      return moment(createdAt).format("DD. MM. YYYY")
    }

    return ""
  }

  formatMembershipExtendedAt(membershipExtendedAt) {
    if (membershipExtendedAt) {
      return moment(membershipExtendedAt).format("DD. MM. YYYY")
    }

    return ""
  }

  getColumnVisibility() {
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    if (settings && settings.hasOwnProperty("/customers/tree")) {
      let columnSettings = settings["/customers/tree"]
      let columns = {}

      columnSettings.forEach(col => {
        let column = this.state.columns.get(col.dataField)

        if (column) {
          columns[col.dataField] = Object.assign({}, column, {
            visible: col.visible,
          })
        }
      })

      this.setState({
        columns: OrderedMap(columns)
      })
    }
  }

  persistColumnVisibility() {
    let columns = this.state.columns.keySeq().toArray().map(key => {
      return {
        dataField: key,
        visible: this.state.columns.get(key).visible,
      }
    })

    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    settings["/customers/tree"] = columns
    localStorage.setItem("missiva_is.grid_settings", JSON.stringify(settings))
  }

  openTree() {
    let openedNodes = [
      this.state.customer.id
    ]

    let getNodeIds = node => {
      node.children
        .filter(child => child.children.length > 0)
        .forEach(child => {
          openedNodes.push(child.id)
          getNodeIds(child)
        })
    }

    getNodeIds(this.state.customer)

    this.setState({openedNodes})
  }
}
