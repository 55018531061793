import React, {Component} from "react"
import Select from "react-select"
import {serializeParams} from "../Utils"
import API from "../API"
import {ReactSelectCustomStyles} from "./ReactSelectCustomStyles"
import SpinnerInButton from "./SpinnerInButton/SpinnerInButton"

export default class ExportButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      columns: this.props.columns,
    }
  }

  componentDidMount() {
    this.loadExportColumns()
  }

  render() {
    return (
      <>
        <Select
          className={"react-bootstrap-table-filter d-inline-block text-left"}
          onChange={this.handleColumnToggleChange.bind(this)}
          value={"default"}
          options={this.state.columns}
          closeMenuOnSelect={false}
          placeholder={"Exportovat sloupce"}
          styles={ReactSelectCustomStyles}
          formatOptionLabel={this.formatOptionLabel.bind(this)}
        />

        <button
          type={"button"}
          className={"btn btn-secondary border-0 py-2"}
          onClick={() => this.handleExport(false)}
          disabled={this.state.isLoading}
        >
          <span
            className={"fas fa-download mr-2"}
          />
          Stáhnout .xls {this.state.isLoading && <SpinnerInButton/>}
        </button>
      </>
    )
  }

  formatOptionLabel(option) {
    return `${option.isActive ? "\u2611" : "\u2610"} ${option.label}`
  }

  handleColumnToggleChange(option) {
    this.setState({
      columns: this.state.columns.map(column => Object.assign({}, column, {
        isActive: option.value === column.value ? !column.isActive : column.isActive,
      }))
    }, this.persistExportColumns.bind(this))
  }

  handleExport(forceExport = false) {
    let params = Object.assign({}, this.props.params)

    delete params.limit
    delete params.offset
    delete params.export

    if (forceExport) {
      params.force = 1
    }

    params = serializeParams(params)

    let columns = this.state.columns
      .filter(column => column.isActive)
      .map(column => column.value)
      .join(",")

    this.setState({
      isLoading: true
    })

    API.get(`${this.props.apiUrl}${params}&csv=${columns}&csv_file=${this.props.fileName}&limit=0&offset=0`)
      .then(({data}) => window.open(data.url))
      .catch(err => {
        if (err.response.status === 400 && window.confirm(`${err.response.data.error_message}\nOpravdu chcete exportovat data?`)) {
          this.handleExport(true)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  loadExportColumns() {
    let exportSettings = JSON.parse(localStorage.getItem("missiva_is.export_settings")) || {}
    if (exportSettings.hasOwnProperty(this.props.exportName)) {
      this.setState({
        columns: this.state.columns.map(column => {
          let exportColumns = exportSettings[this.props.exportName].filter(exportColumn => exportColumn.value === column.value)

          return Object.assign({}, column, {
            isActive: exportColumns.length > 0 ? exportColumns[0].isActive : true
          })
        })
      })
    }
  }

  persistExportColumns() {
    let exportColumns = JSON.parse(localStorage.getItem("missiva_is.export_settings")) || {}
    exportColumns[this.props.exportName] = this.state.columns
    localStorage.setItem("missiva_is.export_settings", JSON.stringify(exportColumns))
  }
}
