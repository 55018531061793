import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"
import {cleanState} from "../../Utils"

export default class BankForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      code: "",
      country_code: "CZ",
      _countries: [],
    }
  }

  componentDidMount() {
    this.getCountries()

    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getBank()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={"/enums/banks/add"}
            className={"btn btn-primary mr-3"}
          >Přidat banku</NavLink>

          {this.id && (
            <button
              className={"btn btn-danger mr-3 float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat banku</button>
          )}
        </div>

        <div
          className={"col-lg-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Název banky:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Název banky"}
                required={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state.name}
                minLength={3}
                maxLength={100}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Kód banky:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Kód banky"}
                required={true}
                onChange={this.handleCodeChange.bind(this)}
                value={this.state.code}
                max={9999}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Země:</label>
              <select
                value={this.state.country_code}
                onChange={this.handleCountryCodeChange.bind(this)}
                className={"form-control"}
              >
                {this.state._countries.map(country => (
                  <option
                    key={country.code}
                    value={country.code}
                  >{country.name}</option>
                ))}
              </select>
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then(({data}) => {
        this.setState({
          _countries: data.objects,
        })
      })
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    })
  }

  handleCodeChange(e) {
    this.setState({
      code: parseInt(e.target.value, 10),
    })
  }

  handleCountryCodeChange(e) {
    this.setState({
      country_code: e.target.value,
    })
  }

  handleCancel() {
    this.props.history.push("/enums/banks")
  }

  handleFormSubmit(e) {
    e.preventDefault()

    if (this.id) {
      this.updateBank()
    } else {
      this.addBank()
    }
  }

  getBank() {
    API.get(`/banks/${this.id}`)
      .then(({data}) => {
        let {name, code, country_code} = data

        this.setState({
          name,
          code,
          country_code
        })
      })
  }

  addBank() {
    let data = cleanState(this.state)

    API.post("/banks", data)
      .then(() => this.props.history.push("/enums/banks"))
  }

  updateBank() {
    let data = cleanState(this.state)

    API.put(`/banks/${this.id}`, data)
      .then(() => this.props.history.push("/enums/banks"))
  }

  handleDelete() {
    API.delete(`/banks/${this.id}`)
      .then(() => this.props.history.push("/enums/banks"))
  }
}
