import React, {Fragment} from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import moment from "moment"
import Grid from "../Base/Grid"
import API from "../API"
import {getParams} from "../Utils"
import GridMenu from "../Base/GridMenu"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import GridDateRangeFilter from "../Base/GridDateRangeFilter"
import ExportButton from "../Base/ExportButton"
import saleExportColumn from "../fixtures/saleExportColumn"
import sortInvoiceNumbers from "../Base/sortInvoiceNumbers"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import GridParams from "../Base/GridParams/GridParams"

export default class SaleList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-sale_date",
        status_1: "!created_open",
      }),
      isLoading: false,
      createdSalesCount: 0,
      _orderMethods: {},
      _saleStates: {},
      _saleStatesOptions: {},
      _deliveryMethods: {},
      _paymentMethods: {},
      _columns: [],
      _users: [],
    }
    this.appUrl = "/sales"
    this.apiUrl = "/sales"
    this.filters = {}
    this.defaultParams = ["status_1"]
    this.defaultSorted = {
      dataField: "sale_date",
      order: "desc",
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.getCreatedSalesCount()
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-2 mb-3"}
        >
          <NavLink
            to={"/sales/add"}
            className={"btn btn-primary mr-3"}
          >Nová zakázka</NavLink>
        </div>

        <div
          className={"col-lg-10 mb-3 text-right"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          >
            <ExportButton
              exportName={"sales"}
              fileName={"Zakázky"}
              params={this.state.params}
              columns={saleExportColumn}
              apiUrl={this.apiUrl}
            />
          </GridMenu>
        </div>

        <div
          className={"col-12 mb-3"}
        >
          <span
            className={"text-secondary mr-2"}
          >Zobrazit:</span>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.status && this.state.params.status === "=created" ? "bg-green" : "btn-info"}`}
            onClick={this.handleStatusCreatedFilterChange.bind(this)}
          >
            Vytvořená online zakázka
            <span
              className={`badge badge-danger ml-2`}
            >{this.state.createdSalesCount}</span>
          </button>
          <button
            className={`mr-2 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.CZECH_POST && this.state.params.payment_method_id === PAYMENT_METHOD.BANK_TRANSFER ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.CZECH_POST, PAYMENT_METHOD.BANK_TRANSFER)}
          >ČP/banka
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.CZECH_POST && this.state.params.payment_method_id === PAYMENT_METHOD.CASH_ON_DELIVERY ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.CZECH_POST, PAYMENT_METHOD.CASH_ON_DELIVERY)}
          >ČP/dobírka
          </button>
          <button
            className={`mr-2 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.DPD && this.state.params.payment_method_id === PAYMENT_METHOD.BANK_TRANSFER ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.DPD, PAYMENT_METHOD.BANK_TRANSFER)}
          >DPD/banka
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.DPD && this.state.params.payment_method_id === PAYMENT_METHOD.CASH_ON_DELIVERY ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.DPD, PAYMENT_METHOD.CASH_ON_DELIVERY)}
          >DPD/dobírka
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_method_id === DELIVERY_METHOD.PERSONAL_PICKUP ? "bg-green" : "btn-info"}`}
            onClick={() => this.handleDeliveryAndPaymentFilterChange(DELIVERY_METHOD.PERSONAL_PICKUP)}
          >Osobní odběr
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.delivery_address__country_code === "!CZ" ? "bg-green" : "btn-info"}`}
            onClick={this.handleForeignCountryFilterChange.bind(this)}
          >Zahraničí
          </button>
          <button
            className={`mr-2 btn btn-sm ${this.state.params.order__invoice_no === "D" ? "bg-green" : "btn-info"}`}
            onClick={this.handleCreditNotesFilterChange.bind(this)}
          >Dobropisy
          </button>
          <button
            className={`mr-4 btn btn-sm ${this.state.params.order__invoice_no === "V" ? "bg-green" : "btn-info"}`}
            onClick={this.handleRefundsFilterChange.bind(this)}
          >Vratky
          </button>
        </div>

        <GridParams
          params={this.state.params}
        />

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                rowClasses={this.getRowClasses.bind(this)}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getOrderMethods(),
      this.getSaleStates(),
      this.getDeliveryMethods(),
      this.getPaymentMethods(),
      this.getUsers(),
    ]).then(axios.spread((orderMethodsRes, saleStatesRes, deliveryMethodsRes, paymentMethodsRes, usersRes) => {
      let orderMethods = {}
      let saleStates = {}
      let saleStatesOptions = {}
      let deliveryMethods = {}
      let paymentMethods = {}

      orderMethodsRes.data.objects.forEach(method => {
        orderMethods[method.id] = method.name
      })

      saleStatesRes.data.objects.forEach(state => {
        saleStates[state.id] = state.name
        if (state.visible) {
          saleStatesOptions[`=${state.id}`] = state.name
        }
      })

      deliveryMethodsRes.data.objects.forEach(method => {
        deliveryMethods[method.id] = method.name
      })

      paymentMethodsRes.data.objects.forEach(method => {
        paymentMethods[method.id] = method.name
      })

      this.setState({
        _orderMethods: orderMethods,
        _saleStates: saleStates,
        _saleStatesOptions: saleStatesOptions,
        _deliveryMethods: deliveryMethods,
        _paymentMethods: paymentMethods,
        _users: usersRes.data.objects,
      }, this.getData)
    }))
  }

  getData() {
    super.getData()
    this.getCreatedSalesCount()
  }

  getCreatedSalesCount() {
    API.get("/sales?status==created&limit=1&offset=0")
      .then(({data}) => {
        this.setState({
          createdSalesCount: data.meta.total_count,
        })
      })
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getOrderMethods() {
    return API.get("/orderMethods?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getPaymentMethods() {
    return API.get("/paymentMethods?limit=0&offset=0")
  }

  getUsers() {
    return API.get("/users?limit=0&offset=0")
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    let {open_at, open_by_user_id} = row
    let user = null

    if (open_at && open_by_user_id)
      user = formatExtraData.users.filter(user => user.id === open_by_user_id)

    return <Fragment>
      {user && user.length > 0 && <span
        title={`Uzamčeno uživatelem ${user[0].name} ${user[0].surname} (${user[0].email})`}
        className={"fas fa-lock mr-1"}
      />}
      {formatExtraData.saleStates[cell] || ""}
    </Fragment>
  }

  formatDeliveryMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatPaymentMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        className={"nowrap"}
      >
        <NavLink
          to={`/sales/${row.id}/detail`}
        >{cell}</NavLink>

        <NavLink
          to={`/sales/pay?sale__sale_no=${row.sale_no}`}
          title={"Přejít do přehledu objednávek"}
        >
          <i
            className={"fas fa-tasks fa-lg ml-4"}
          />
        </NavLink>
      </div>
    )
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return row.order
      .sort(sortInvoiceNumbers)
      .map(order => (
        <div
          key={order.id}
        >
          <NavLink
            to={`/sales/${row.id}/detail/${order.id}`}
          >{order.invoice_no}</NavLink>
          {(order.customer.note.some(note => note.is_warn) || order.note.some(note => note.is_warn)) && (
            <span
              className={"fas fa-exclamation-triangle ml-1 text-danger toggle-tooltip"}
            >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {order.customer.note.filter(note => note.is_warn).map(note =>
                <div
                  key={`cn-${note.id}`}
                >{note.value}</div>
              )}
              {order.note.filter(note => note.is_warn).map(note =>
                <div
                  key={`on-${note.id}`}
                >{note.value}</div>
              )}
            </span>
          </span>
          )}
          {order.note.some(note => note.is_pub) && (
            <span
              className={"fas fa-user ml-1 text-warning toggle-tooltip"}
            >
            <span
              className={"tooltip ml-3 mt-1 text-white"}
            >
              {order.note
                .filter(note => note.is_pub)
                .map(note =>
                  <div
                    key={note.id}
                  >{note.value}</div>
                )}
            </span>
          </span>
          )}
          {order.order_product.some(products => products.is_advert) && (
            <span
              className={"fas fa-gift ml-1"}
              title={"Některé produkty jsou na propagační účely"}
            />
          )}
        </div>
      ))
  }

  formatOrderPaidDate(cell, row, rowIndex, formatExtraData) {
    return row.order.map(order => {
      return <div
        key={order.id}
        className={"text-nowrap"}
      >{order.paid_date ? moment(order.paid_date).format("DD. MM. YYYY") : ""}</div>
    })
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer.customer_no}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.customer.id}/detail`}
      >{row.customer ? row.customer.surname : ""}</NavLink>
    )
  }

  formatCustomerName(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.name : ""
  }

  formatDeliveryAddress(cell, row, rowIndex, formatExtraData) {
    if (row.delivery_address)
      return `${row.delivery_address.street}, ${row.delivery_address.city}`
    return ""
  }

  formatSaleDate(cell, row, rowIndex, formatExtraData) {
    if (cell)
      return moment(cell).format("D. M. YYYY HH:mm:ss")
    return ""
  }

  formatDeliveryAddressCountry(cell, row, roIndex, formatExtraData) {
    if (row.delivery_address)
      return row.delivery_address.country_code
    return ""
  }

  formatOrderMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatTotalPriceVAT(cell, row, rowIndex, formatExtraData) {
    return (
      <span
        className={"nowrap"}
      >{row.total_price_vat_formatted}</span>
    )
  }

  formatNoDuePrice(cell, row, rowIndex, formatExtraData) {
    return (
      <span
        className={"nowrap"}
      >{row.no_due_price_formatted}</span>
    )
  }

  formatDeliveryPriceVAT(cell, row, rowIndex, formatExtraData) {
    return (
      <span
        className={"nowrap"}
      >{row.delivery_price_vat_formatted}</span>
    )
  }

  formatPackagingPriceVAT(cell, row, rowIndex, formatExtraData) {
    return (
      <span
        className={"nowrap"}
      >{row.packaging_price_vat_formatted}</span>
    )
  }

  formatOrderProductAdvert(cell, row, rowIndex, formatExtraData) {
    if (row.order.some(order => order.order_product.some(orderProduct => orderProduct.is_advert))) {
      return "Ano"
    }

    return "Ne"
  }

  getDefaultColumns() {
    return [{
      text: "Stav",
      dataField: "status",
      filter: selectFilter({
        options: this.state._saleStatesOptions,
        placeholder: "Stav",
        defaultValue: this.state.params.status || "",
        getFilter: filter => this.filters.status = filter,
      }),
      headerClasses: () => this.state.params.status ? "bg-green" : "",
      formatter: this.formatStatus.bind(this),
      formatExtraData: {
        saleStates: this.state._saleStates,
        users: this.state._users,
      },
      sort: true,
      visible: true,
    }, {
      text: "Způsob doručení",
      dataField: "delivery_method_id",
      filter: selectFilter({
        options: this.state._deliveryMethods,
        placeholder: "Způsob doručení",
        defaultValue: this.state.params.delivery_method_id || "",
        getFilter: filter => this.filters.delivery_method_id = filter,
      }),
      headerClasses: () => this.state.params.delivery_method_id ? "bg-green" : "",
      formatter: this.formatDeliveryMethod.bind(this),
      formatExtraData: this.state._deliveryMethods,
      visible: true,
    }, {
      text: "Způsob platby",
      dataField: "payment_method_id",
      filter: selectFilter({
        options: this.state._paymentMethods,
        placeholder: "Způsob platby",
        defaultValue: this.state.params.payment_method_id || "",
        getFilter: filter => this.filters.payment_method_id = filter,
      }),
      headerClasses: () => this.state.params.payment_method_id ? "bg-green" : "",
      formatter: this.formatPaymentMethod.bind(this),
      formatExtraData: this.state._paymentMethods,
      visible: true,
    }, {
      text: "Č. zakázky",
      dataField: "sale_no",
      classes: "column-sticky",
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale_no ? `${this.state.params.sale_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale_no ? "bg-green" : ""}`,
      formatter: this.formatSaleNo.bind(this),
      sort: true,
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Č. faktur",
      dataField: "order__invoice_no",
      classes: "column-sticky column-sticky_1",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // z-index of customer surname column is 1. There is plenty of space to
          // prevent customer surname overlaps order notes
          zIndex: 100 - rowIndex
        }
      },
      filter: textFilter({
        placeholder: "Č. faktur",
        defaultValue: this.state.params.order__invoice_no || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.order__invoice_no = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.order__invoice_no ? "bg-green" : ""}`,
      formatter: this.formatOrder.bind(this),
      visible: true,
    }, {
      text: "D. uhrazení",
      dataField: "order__paid_date",
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.order__paid_date = () => onFilter("")
        this.filters["order__paid_date-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.order__paid_date}
            defaultValueTo={this.state.params["order__paid_date-2"]}
          />
        )
      },
      headerClasses: () => this.state.params.order__paid_date ? "bg-green" : "",
      formatter: this.formatOrderPaidDate.bind(this),
      visible: true,
    }, {
      text: "Čas pořízení zakázky",
      dataField: "sale_date",
      headerClasses: () => this.state.params.sale_date ? "bg-green" : "",
      formatter: this.formatSaleDate.bind(this),
      sort: true,
      visible: true,
      filter: customFilter({
        type: "DATE_RANGE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.sale_date = () => onFilter("")
        this.filters["sale_date-2"] = () => onFilter("")

        return (
          <GridDateRangeFilter
            onFilter={onFilter}
            column={column}
            defaultValueFrom={this.state.params.sale_date}
            defaultValueTo={this.state.params["sale_date-2"]}
          />
        )
      },
    }, {
      text: "Č. zák.",
      dataField: "customer__customer_no",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer__customer_no ? `${this.state.params.customer__customer_no}`.replace("^", "") : "",
        getFilter: filter => this.filters.customer__customer_no = filter,
      }),
      headerClasses: () => this.state.params.customer__customer_no ? "bg-green" : "",
      formatter: this.formatCustomerNumber.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      classes: "column-sticky column-sticky_2",
      style: (cell, row, rowIndex, colIndex) => {
        return {
          zIndex: 1 // must be smaller than invoice number column to prevent column overlaps order notes
        }
      },
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_2 ${this.state.params.customer__surname ? "bg-green" : ""}`,
      formatter: this.formatCustomerSurname.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Jméno zák.",
      dataField: "customer__name",
      filter: textFilter({
        placeholder: "Jméno zák.",
        defaultValue: this.state.params.customer__name || "",
        getFilter: filter => this.filters.customer__name = filter,
      }),
      headerClasses: () => this.state.params.customer__name ? "bg-green" : "",
      formatter: this.formatCustomerName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Způsob objednání",
      dataField: "order_method_id",
      filter: selectFilter({
        options: this.state._orderMethods,
        placeholder: "Způsob objednání",
        defaultValue: this.state.params.order_method_id || "",
        getFilter: filter => this.filters.order_method_id = filter,
      }),
      headerClasses: () => this.state.params.order_method_id ? "bg-green" : "",
      formatter: this.formatOrderMethod.bind(this),
      formatExtraData: this.state._orderMethods,
      visible: true,
    }, {
      text: "Celková cena",
      dataField: "total_price_vat",
      filter: textFilter({
        placeholder: "Celková cena",
        defaultValue: this.state.params.total_price_vat || "",
        getFilter: filter => this.filters.total_price_vat = filter,
      }),
      headerClasses: () => this.state.params.total_price_vat ? "bg-green" : "",
      formatter: this.formatTotalPriceVAT.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Cena objednávek bez splatností",
      dataField: "no_due_price",
      filter: textFilter({
        placeholder: "Cena objednávek bez splatností",
        defaultValue: this.state.params.no_due_price || "",
        getFilter: filter => this.filters.no_due_price = filter,
      }),
      headerClasses: () => this.state.params.no_due_price ? "bg-green" : "",
      formatter: this.formatNoDuePrice.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Poštovné",
      dataField: "delivery_price_vat",
      filter: textFilter({
        placeholder: "Poštovné",
        defaultValue: this.state.params.delivery_price_vat || "",
        getFilter: filter => this.filters.delivery_price_vat = filter,
      }),
      headerClasses: () => this.state.params.delivery_price_vat ? "bg-green" : "",
      formatter: this.formatDeliveryPriceVAT.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Balné",
      dataField: "packaging_price_vat",
      filter: textFilter({
        placeholder: "Balné",
        defaultValue: this.state.params.packaging_price_vat || "",
        getFilter: filter => this.filters.packaging_price_vat = filter,
      }),
      headerClasses: () => this.state.params.packaging_price_vat ? "bg-green" : "",
      formatter: this.formatPackagingPriceVAT.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Celkové body",
      dataField: "bonus_points",
      filter: textFilter({
        placeholder: "Celkové body",
        defaultValue: this.state.params.bonus_points || "",
        getFilter: filter => this.filters.bonus_points = filter,
      }),
      headerClasses: () => this.state.params.bonus_points ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Doručovací adresa",
      dataField: "delivery_address__street",
      filter: textFilter({
        placeholder: "Doručovací adresa",
        defaultValue: this.state.params.delivery_address__street || "",
        getFilter: filter => this.filters.delivery_address__street = filter,
      }),
      headerClasses: () => this.state.params.delivery_address__street ? "bg-green" : "",
      formatter: this.formatDeliveryAddress.bind(this),
      visible: true,
    }, {
      text: "Země",
      dataField: "delivery_address__country_code",
      filter: textFilter({
        placeholder: "Země",
        defaultValue: this.state.params.delivery_address__country_code || "",
        getFilter: filter => this.filters.delivery_address__country_code = filter,
      }),
      headerClasses: () => this.state.params.delivery_address__country_code ? "bg-green" : "",
      formatter: this.formatDeliveryAddressCountry.bind(this),
      visible: true,
      // scenario: grid has 2 "dummy" fields side by side (dummy means they aren't real data
      // fields). If we try hide the last but one column, the last one shows wrong data. This
      // workaround fix this.
      isDummyField: true,
    }, {
      text: "Propagační účely",
      dataField: "order__order_product__is_advert",
      filter: selectFilter({
        options: {
          "=0": "Ne",
          "=1": "Ano",
        },
        placeholder: "Propagační účely",
        defaultValue: this.state.params.order__order_product__is_advert || "",
        getFilter: filter => this.filters.order__order_product__is_advert = filter,
      }),
      headerClasses: () => this.state.params.order__order_product__is_advert ? "bg-green" : "",
      formatter: this.formatOrderProductAdvert.bind(this),
      visible: true,
    }]
  }

  handleStatusCreatedFilterChange() {
    if (this.state.params.status && this.state.params.status === "=created") {
      this.filters.status("")
    } else {
      this.filters.status("=created")
    }
  }

  handleDeliveryAndPaymentFilterChange(deliveryMethodId, paymentMethodId) {
    if (this.state.params.delivery_method_id === deliveryMethodId && this.state.params.payment_method_id === paymentMethodId) {
      deliveryMethodId = ""
      paymentMethodId = ""
    }
    this.filters.delivery_method_id(deliveryMethodId || "")
    this.filters.payment_method_id(paymentMethodId || "")
  }

  handleForeignCountryFilterChange() {
    if (this.state.params.delivery_address__country_code === "!CZ")
      this.filters.delivery_address__country_code("")
    else
      this.filters.delivery_address__country_code("!CZ")
  }

  getRowClasses(row, rowIndex) {
    if (row.status === "created") {
      return "bg-green"
    }

    return ""
  }

  handleCreditNotesFilterChange() {
    if (this.state.params.order__invoice_no === "D") {
      this.filters.order__invoice_no("")
    } else {
      this.filters.order__invoice_no("D")
    }
  }

  handleRefundsFilterChange() {
    if (this.state.params.order__invoice_no === "V") {
      this.filters.order__invoice_no("")
    } else {
      this.filters.order__invoice_no("V")
    }
  }
}
