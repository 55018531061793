import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import {NavLink} from "react-router-dom"
import moment from "moment"
import API from "../API"
import {getParams} from "../Utils"
import Grid from "../Base/Grid"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import SpinnerInButton from "../Base/SpinnerInButton/SpinnerInButton"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import print from "../Base/print"

export default class WareHouseShipments extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        status: "=packed",
        sort: "-sale_date",
      }),
      _columns: [],
      _saleStates: [],
      _deliveryMethods: [],
      _isPrintingCzechPostPickupList: false,
      _isPrintingDPDPickupList: false,
    }

    this.filters = {}
    this.apiUrl = "/sales"
    this.appUrl = "/warehouse/shipments"
    this.defaultParams = ["status", "sale_date", "sort"]
    this.defaultSorted = {
      dataField: "sale_date",
      order: "desc",
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-4 mb-3"}
        >
          <span
            className="text-secondary mr-2"
          >Počet vyexpedovaných zásilek/balíků:</span>
          <div
            className={"d-inline-block border px-3 py-2"}
          >{this.state.total}/{this.getTotalBoxes()}</div>
        </div>

        <div
          className={"col-lg-8 text-right"}
        >
          <label
            className={"d-inline"}
          >Vytisknout předávací protokol pro dnešní den:</label>

          <button
            className={"btn btn-secondary ml-3 border-right"}
            type={"button"}
            onClick={this._handleCzechPostPickUpListPrintClick.bind(this)}
            disabled={this.state._isPrintingCzechPostPickupList}
          >Česká pošta {this.state._isPrintingCzechPostPickupList && <SpinnerInButton/>}</button>

          <button
            className={"btn btn-secondary"}
            type={"button"}
            onClick={this._handleDPDPickUpListPrintClick.bind(this)}
            disabled={this.state._isPrintingDPDPickupList}
          >DPD {this.state._isPrintingDPDPickupList && <SpinnerInButton/>}</button>

          <button
            className={"btn btn-light ml-5"}
            onClick={this.resetFilters.bind(this)}
          >Vymazat filtry
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getSaleStates(),
      this.getDeliveryMethods(),
    ]).then(axios.spread((saleStatesRes, deliveryMethodsRes) => {
      let saleStates = {}
      let deliveryMethods = {}

      saleStatesRes.data.objects.forEach(state => {
        saleStates[state.id] = state.name
      })

      deliveryMethodsRes.data.objects.forEach(method => {
        deliveryMethods[method.id] = method.name
      })

      this.setState({
        _saleStates: saleStates,
        _deliveryMethods: deliveryMethods,
      }, () => {
        this.getData()
      })
    }))
  }

  getSaleStates() {
    return API.get("/saleStatus?limit=0&offset=0")
  }

  getDeliveryMethods() {
    return API.get("/deliveryMethods?limit=0&offset=0")
  }

  getDefaultColumns() {
    return [{
      text: "Způsob doručení",
      dataField: "delivery_method_id",
      filter: selectFilter({
        options: this.state._deliveryMethods,
        placeholder: "Způsob doručení",
        defaultValue: this.state.params.delivery_method_id || "",
        getFilter: filter => this.filters.delivery_method_id = filter,
      }),
      headerClasses: () => this.state.params.delivery_method_id ? "bg-green" : "",
      formatter: this.formatDeliveryMethod.bind(this),
      formatExtraData: this.state._deliveryMethods,
    }, {
      text: "Stav",
      dataField: "status",
      formatter: this.formatStatus.bind(this),
      formatExtraData: this.state._saleStates,
    }, {
      text: "Čas pořízení zakázky",
      dataField: "sale_date",
      formatter: this.formatSaleDate.bind(this),
    }, {
      text: "Č. zakázky",
      dataField: "sale_no",
      formatter: this.formatSaleNo.bind(this),
      filter: textFilter({
        placeholder: "Č. zakázky",
        defaultValue: this.state.params.sale_no ? `${this.state.params.sale_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.sale_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.sale_no ? "bg-green" : ""}`,
    }, {
      text: "Příjmení zák.",
      dataField: "customer__surname",
      formatter: this.formatCustomerSurname.bind(this),
      filter: textFilter({
        placeholder: "Příjmení zák.",
        defaultValue: this.state.params.customer__surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer__surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_2 ${this.state.params.customer__surname ? "bg-green" : ""}`,
    }, {
      text: "Země",
      dataField: "delivery_address__country_code",
      filter: textFilter({
        placeholder: "Země",
        defaultValue: this.state.params.delivery_address__country_code || "",
        getFilter: filter => this.filters.delivery_address__country_code = filter,
      }),
      headerClasses: () => this.state.params.delivery_address__country_code ? "bg-green" : "",
      formatter: this.formatDeliveryAddressCountry.bind(this),
    }, {
      text: "Počet objednávek",
      dataField: "order",
      formatter: this.formatOrder.bind(this),
    }, {
      text: "Váha",
      dataField: "weight",
      formatter: this.formatWeight.bind(this),
    }, {
      text: "Počet balíků",
      dataField: "shipment",
      formatter: this.formatShipment.bind(this),
    }, {
      text: "Zrušit odeslání",
      dataField: "id",
      isDummyField: true,
      formatter: this.formatResetShipment.bind(this),
    }]
  }

  formatStatus(cell, row, rowIndex, formatExtraData) {
    return (
      <>
        <span
          className={`fas fa-${cell === "print_ready" ? "check" : "print"} mr-1`}
        />
        {formatExtraData[cell] || ""}
      </>
    )
  }

  formatSaleDate(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY HH:MM:SS")
  }

  formatSaleNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerSurname(cell, row, rowIndex, formatExtraData) {
    return row.customer ? row.customer.surname : ""
  }

  formatDeliveryAddressCountry(cell, row, roIndex, formatExtraData) {
    if (row.delivery_address) {
      return row.delivery_address.country_code
    }
    return ""
  }

  formatDeliveryMethod(cell, row, rowIndex, formatExtraData) {
    return formatExtraData[cell] || ""
  }

  formatOrder(cell, row, rowIndex, formatExtraData) {
    return cell.length
  }

  formatWeight(cell, row, rowIndex, formatExtraData) {
    return `${cell} kg`
  }

  formatShipment(cell, row, rowIndex, formatExtraData) {
    if (cell.length > 0) {
      return cell[0].boxes.length > 0 ? cell[0].boxes.length : 1
    }
    return 1
  }

  formatResetShipment(cell, row, rowIndex, formatExtraData) {
    return (
      <button
        type={"button"}
        className={"btn btn-primary nowrap"}
        onClick={() => this.handleResetShipmentClick(row.shipment[0].id)}
      >Zrušit odeslání</button>
    )
  }

  _handleCzechPostPickUpListPrintClick() {
    if (window.confirm("Opravdu ukončit balení pro tento den a vytisknout protokol?")) {
      this.handleCzechPostPickUpListPrintClick()
    }
  }

  handleCzechPostPickUpListPrintClick = async () => {
    let today = moment().format("YYYY-MM-DD")
    let isShipmentRequestSuccessful = false

    this.setState({
      _isPrintingCzechPostPickupList: true,
    })

    await API.post(`/shipment/request?date=${today}&delivery_method=${DELIVERY_METHOD.CZECH_POST}`)
      .then(() => {
        isShipmentRequestSuccessful = true
      })
      .finally(() => {
        this.setState({
          _isPrintingCzechPostPickupList: false,
        })
      })

    if (isShipmentRequestSuccessful) {
      API.get(`/shipment/pickUpList?date=${today}&delivery_method=${DELIVERY_METHOD.CZECH_POST}`)
        .then(({data}) => {
          if (data.ceska_posta_url) {
            print(data.ceska_posta_url)
          }
        })
        .catch(err => API.formatErrors(err.response.data.error_message))
        .finally(() => {
          this.setState({
            _isPrintingCzechPostPickupList: false,
          })
        })
    }
  }

  _handleDPDPickUpListPrintClick() {
    if (window.confirm("Opravdu ukončit balení pro tento den a vytisknout protokol?")) {
      this.handleDPDPickUpListPrintClick()
    }
  }

  handleDPDPickUpListPrintClick = async () => {
    let today = moment().format("YYYY-MM-DD")

    this.setState({
      _isPrintingDPDPickupList: true,
    })

    API.get(`/shipment/pickUpList?date=${today}&delivery_method=${DELIVERY_METHOD.DPD}`)
      .then(({data}) => {
        if (data.dpd_url) {
          print(data.dpd_url)
        }
      })
      .catch(err => API.formatErrors(err.response.data.error_message))
      .finally(() => {
        this.setState({
          _isPrintingDPDPickupList: false,
        })
      })
  }

  handleResetShipmentClick(shipmentId) {
    API.delete(`/shipment/${shipmentId}`)
      .then(this.getData.bind(this))
  }

  getTotalBoxes() {
    return this.state.data.reduce((saleAcc, sale) => {
      return saleAcc + sale.shipment.reduce((shipmentAcc, shipment) => {
        return shipmentAcc + shipment.boxes.length
      }, 0)
    }, 0)
  }
}
