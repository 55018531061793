import PAYMENT_METHOD from "./Enum/PaymentMethod"
import DELIVERY_METHOD from "./Enum/DeliveryMethod"
import CUSTOMER_TYPE from "./Enum/CustomerType"

const filterMyPaymentMethod = (paymentMethod) => {
  return paymentMethod !== PAYMENT_METHOD.BONUS_MONEY
}

const filterPaymentMethodByDeliveryMethod = (paymentMethod, deliveryMethod) => {
  if (deliveryMethod === DELIVERY_METHOD.PERSONAL_PICKUP)
    return paymentMethod !== PAYMENT_METHOD.CASH_ON_DELIVERY

  if (deliveryMethod === DELIVERY_METHOD.DPD || deliveryMethod === DELIVERY_METHOD.CZECH_POST)
    return paymentMethod !== PAYMENT_METHOD.CREDIT_CARD

  return true
}

const filterPaymentMethodByCustomerTypeId = (paymentMethod, customerType) => {
  if (
    customerType !== CUSTOMER_TYPE.CK &&
    customerType !== CUSTOMER_TYPE.PP
  ) {
    return paymentMethod !== PAYMENT_METHOD.BONUS_MONEY
  }

  return true
}

const filterPaymentMethodByOrdersCustomers = (paymentMethod, authenticatedCustomerId, orderCustomerIds) => {
  if (orderCustomerIds.some(orderCustomerId => orderCustomerId !== authenticatedCustomerId)) {
    return paymentMethod !== PAYMENT_METHOD.BONUS_MONEY
  }

  return true
}

const filterPaymentMethodByCountryCode = (paymentMethod, countryCode) => {
  if (countryCode !== "CZ") {
    return paymentMethod === PAYMENT_METHOD.BANK_TRANSFER ||
      paymentMethod === PAYMENT_METHOD.BONUS_MONEY
  }

  return true
}

export {
  filterMyPaymentMethod,
  filterPaymentMethodByDeliveryMethod,
  filterPaymentMethodByCustomerTypeId,
  filterPaymentMethodByOrdersCustomers,
  filterPaymentMethodByCountryCode,
}
