import DISCOUNT_TYPE from "./Enum/DiscountType"

// TODO: is DISCOUNT_GROUP
const isPromotionDiscountGroup = discountProducts => {
  let paidProducts = discountProducts.filter(discount => discount.type === DISCOUNT_TYPE.PERCENT_DISCOUNT && discount.value === 0)
  let promoProducts = discountProducts.filter(discount => discount.type === DISCOUNT_TYPE.PERCENT_DISCOUNT && discount.value === 100)

  return paidProducts.length + promoProducts.length === discountProducts.length
}

export default isPromotionDiscountGroup
