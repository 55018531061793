import React from "react"

const Terms = () => (
  <div
    className={"row"}
  >
    <div
      className={"col-xl-5 col-lg-7 mx-auto"}
    >
      <h1>Obchodní podmínky pro náhodné zákazníky</h1>
      <h6>Tyto Všeobecné obchodní podmínky (dále jen VOP) upravují
        vztahy mezi kupujícím a prodávajícím <br/>
        v oblasti prodeje zboží mezi společností MISSIVA, spol. s&nbsp;r.o. (dále jen
        „prodávající“) a jejími obchodními partnery (dále jen „kupující“).</h6>

      <ol
        style={{
          listStyleType: "upper-roman"
        }}
      >
        <li>
          <strong>Základní ustanovení</strong>
          <p>Tyto Všeobecné obchodní podmínky (dále jen VOP) upravují vztahy mezi kupujícím
            a prodávajícím v oblasti prodeje zboží mezi společností MISSIVA, spol.
            s&nbsp;r.o. a jejími obchodními partnery.</p>

          <p>Kupující podáním objednávky
            potvrzuje, že se seznámil s těmito obchodními podmínkami, jejichž nedílnou
            součást tvoří reklamační řád, a že s nimi souhlasí. Na tyto obchodní podmínky
            je kupující dostatečným způsobem před vlastním uskutečněním objednávky
            upozorněn a má možnost se s nimi seznámit.</p>

          <p>
            <strong>Prodávající</strong> - MISSIVA, spol. s&nbsp;r.o. V&nbsp;oblouku
            588/21, 400 07 Ústí nad Labem, zapsaná v&nbsp;obchodním rejstříku vedeném
            Krajským soudem v&nbsp;Ústí nad Labem oddíl C, vložka 7734 zabývající
            se&nbsp;prodejem především čistících a pracích prostředků, kosmetiky a doplňků
            stravy.</p>


          <p>
            <strong>Kupující</strong> - spotřebitel - osoba, která při uzavírání a plnění smlouvy
            nejedná v rámci své
            obchodní nebo jiné podnikatelské činnosti. Je to fyzická či právnická osoba,
            která nakupuje výrobky nebo užívá služby za jiným účelem než pro podnikání s
            těmito výrobky nebo službami.</p>


          <p>Spotřebitel
            při zahájení obchodních vztahů předává prodávajícímu pouze své kontaktní údaje,
            nutné pro bezproblémové vyřízení objednávky, popřípadě údaje, které chce mít
            uvedeny na nákupních dokladech.</p>

          <p>Právní
            vztahy prodávajícího se spotřebitelem výslovně neupravené těmito VOP se řídí
            příslušnými ustanoveními a zákony v&nbsp;platném znění.</p>

          <p>Spotřebitelská
            smlouva - smlouva kupní, případně jiné smlouvy dle občanského zákoníku, pokud
            smluvními stranami jsou na jedné straně spotřebitel a na druhé straně
            dodavatel, resp. prodávající.</p>

          <strong>Kupní smlouva</strong>

          <p>Kupující
            - spotřebitel, návrhem k&nbsp;uzavření kupní smlouvy je umístění nabízeného
            zboží dodavatelem na stránky, kupní smlouva vzniká odesláním objednávky kupujícím
            spotřebitelem a&nbsp;přijetím objednávky dodavatelem. Toto přijetí dodavatel
            neprodleně potvrdí kupujícímu informativním emailem na zadaný email, na vznik
            smlouvy však toto potvrzení nemá vliv. Vzniklou smlouvu (včetně dohodnuté ceny)
            lze měnit nebo rušit pouze na základě dohody stran nebo na základě zákonných
            důvodů.</p>

          <p>Vztahy
            a případné spory, které vzniknou na základě smlouvy, budou řešeny výhradně
            podle platného práva České republiky a budou řešeny soudy České
            republiky.</p>

          <p>Smlouva
            je uzavírána v českém jazyce. Pokud vznikne pro potřebu kupujícího překlad
            textu smlouvy, platí, že v&nbsp;případě sporu o výklad pojmů platí výklad
            smlouvy v&nbsp;českém jazyce.</p>

          <p>Uzavřená
            smlouva je prodávajícím archivována za účelem jejího úspěšného splnění a není
            přístupná třetím nezúčastněným stranám. Informace o jednotlivých technických
            krocích vedoucích k uzavření smlouvy jsou patrné z těchto obchodních podmínek,
            kde je tento proces srozumitelně popsán. Kupující má možnost před vlastním
            odesláním objednávky ji zkontrolovat a případně opravit. Tyto obchodní podmínky
            jsou zobrazeny na webových stránkách internetového obchodu&nbsp;<a
              href={"http://www.missiva.cz/"}>www.missiva.cz</a>
            &nbsp;a je tak umožněna jejich archivace <br/>
            a reprodukce kupujícím.&nbsp;</p>

          <p>Náklady
            na použití komunikačních prostředků na dálku (telefon, internet atd.) pro
            uskutečnění objednávky jsou v běžné výši, závislé na tarifu telekomunikačních
            služeb, které kupující používá.</p>
        </li>

        <li>
          <strong>Bezpečnost a ochrana informací</strong>

          <ol>
            <li>
              <p>Prodávající
                prohlašuje, že veškeré osobní údaje jsou důvěrné, budou použity pouze <br/>
                k uskutečnění plnění smlouvy s kupujícím a nebudou jinak zveřejněny, poskytnuty
                třetí osobě apod. s výjimkou situace související s distribucí či platebním
                stykem týkajícího se objednaného zboží (sdělení jména a adresy dodání). Osobní
                údaje, které jsou poskytnuty kupujícím prodávajícímu za účelem splnění
                objednávky, jsou shromažďovány, zpracovávány a uchovávány v souladu s platnými
                zákony České republiky. Kupující dává prodávajícímu svůj souhlas ke
                shromažďování a zpracování těchto osobních údajů pro účely splnění předmětu
                uzavírané kupní smlouvy, a to až do doby jeho písemného vyjádření nesouhlasu s
                tímto zpracováním. Kupující má právo přístupu ke svým osobním údajům a právo na
                jejich opravu včetně dalších zákonných práv k těmto údajům.</p>

              <strong>Informace o zpracovávání osobních údajů při nákupu online:</strong>

              <p>Společnost MISSIVA, spol. s.r.o. se sídlem V Oblouku 588/21,
                Krásné Březno, 400 07 Ústí nad Labem, IČ 615 38 108, zapsaná u Krajského soudu
                v Ústí nad Labem, oddíl C, vložka 7734 a poradci Clubu 21 jako správci osobních
                údajů zpracovávají v&nbsp;rámci kupní smlouvy ve smyslu zákona č. 101/2000 Sb.,
                o ochraně osobních údajů, následující osobní údaje kupujících:</p>

              <ul>
                <li>Titul</li>
                <li>Jméno, příjmení</li>
                <li>Emailovou adresu</li>
                <li>Telefonní číslo</li>
                <li>Doručovací adresu</li>
                <li>Fakturační adresu</li>
                <li>IČ</li>
                <li>DIČ</li>
                <li>Číslo běžného účtu</li>
              </ul>

              <p>v souladu s platnými zákony České republiky, zejména
                se zákonem č. 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších
                předpisů, a to za účelem poskytnutí plnění ze smlouvy a dále za účelem evidence
                smlouvy a případného budoucího uplatnění a obranu práv a povinností smluvních
                stran.</p>

              <p>Uchování a zpracování osobních údajů je za výše
                uvedeným účelem po dobu 10 let od realizace poslední části plnění dle smlouvy,
                nepožaduje-li jiný právní předpis uchování smluvní dokumentace po dobu delší.</p>

              <p>Výše uvedené zpracování je umožněno na základě ust. §
                5 odst. 2 písm. b) zákona o ochraně osobních údajů – zpracování nezbytné pro
                plnění smlouvy a na základě ust. § 5 odst. 2 písm. e) zákona o ochraně osobních
                údajů – zpracování nezbytné pro ochranu práv a právem chráněných zájmů správce,
                příjemce nebo jiné dotčené osoby.</p>
            </li>
            <li>
              <p>Společnost MISSIVA, spol. s.r.o. a
                poradci Clubu 21 prohlašují, že veškeré osobní údaje jsou důvěrné a nebudou
                poskytnuty žádné třetí osobě. Výjimku představují dopravci, kterým jsou osobní
                údaje kupujících předávány v minimálním rozsahu, který&nbsp;je&nbsp;nutný pro
                doručení zboží, poskytovatele platebních bran, kterým jsou osobní údaje
                kupujících předávány v minimálním rozsahu, který je nutný pro umožnění úhrady
                objednávky a dále zpracovatelé, kterými jsou</p>

              <ol
                style={{
                  listStyleType: "lower-alpha"
                }}
              >
                <li>Jiří Rudovský, IČO:12773557;</li>

                <li>RKA SW Systems s.r.o., IČO:
                  26124688;
                </li>

                <li>ACCESS IT, s.r.o., IČO: 25040588;</li>

                <li>Případně další poskytovatelé
                  zpracovatelských softwarů, služeb a aplikací, které však v&nbsp;současné době
                  společnost nevyužívá.
                </li>
              </ol>
            </li>
            <li>
              <p>Nevyužije-li zákazník možnosti
                objednat si přes registraci v zákaznickém systému, bere na vědomí, že uskuteční
                objednávku prostřednictvím poradce. Seznam poradců je uveden na internetové
                stránce <a
                  href={"https://www.missiva.cz/kontakty-na-poradce/"}>https://www.missiva.cz/kontakty-na-poradce/</a>.
              </p>
            </li>
            <li>
              <p>Na emailovou adresu vám budou
                společností MISSIVA, spol. s.r.o. zasílána obchodní sdělení, neboť tento postup
                umožňuje § 7 odst. 3 zákona č.480/2004 Sb., o službách informační společnosti,
                pokud jej neodmítnete. Tato sdělení se mohou týkat pouze obdobného zboží či
                služeb a lze je kdykoliv jednoduchým způsobem – zasláním dopisu, emailu nebo
                proklikem na odkaz v&nbsp;obchodním sdělení – odhlásit. Emailová adresa bude za
                tímto účelem společností MISSIVA, spol. s.r.o. uchovávána po dobu 3 let od
                uzavření poslední kupní smlouvy mezi smluvními stranami</p>
            </li>
            <li>
              <p>Podle zákona o ochraně osobních
                údajů máte, coby kupující, právo:</p>

              <ul>
                <li>požadovat po nás
                  informaci, jaké vaše osobní údaje
                  zpracováváme,
                </li>

                <li>požadovat po nás
                  vysvětlení ohledně zpracování
                  osobních údajů,
                </li>

                <li>vyžádat si u nás přístup k
                  těmto údajům a tyto nechat
                  aktualizovat nebo opravit,
                </li>

                <li>požadovat po nás výmaz
                  těchto osobních údajů – pokud se
                  nejedná o osobní údaje, které jsme povinni nebo oprávněni dále zpracovávat dle
                  příslušných právních předpisů,
                </li>

                <li>v případě pochybností o
                  dodržování povinností
                  souvisejících se zpracováním osobních údajů obrátit se na nás nebo na Úřad pro
                  ochranu osobních údajů.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <strong>Otevírací doba</strong>

          <p>Obchod: na adrese Rtyně nad
            Bílinou 6, Pondělí – Pátek 6<sup>30</sup> – 15 hodin</p>


          <p>Objednávky přes <a
            href={"http://www.missiva.cz/"}>www.missiva.cz</a>
            7 dnů v&nbsp;týdnu 24 hodin denně </p>


          <p>Pro
            doručování objednávek jsou vybírány přepravní společnosti, které zaručují
            doručení zásilek po ČR do 24:00 hod. Objednávky přijaté v&nbsp;pracovní dny do
            12:00 jsou obvykle doručovány následující pracovní den.</p>
        </li>
        <li>
          <strong>Ceny, balné</strong>

          <p>Všechny
            ceny jsou smluvní a řídí se platným ceníkem, který je součástí objednávkového
            programu. Kupující, který poskytl základní údaje nutné pro uskutečnění
            objednávky a není řádným členem Clubu21 nakupuje za základní zákaznické ceny.</p>


          <p>Na
            objednávku bez osobního převzetí, která je nižší
            než 1&nbsp;000,-Kč&nbsp;je účtováno poštovné v&nbsp;částce 100,-Kč. Objednávka
            nad 1&nbsp;000,-Kč je zasílána bez poštovného.&nbsp;Ke každé zakázce odesílané
            přepravní společností je účtováno balné – 20,- Kč</p>
        </li>

        <li>
          <strong>Objednávání</strong>

          <p>Kupující
            dostane zboží za cenu platnou v době objednání. Kupující spotřebitel má možnost
            se před provedením objednávky seznámit s celkovou cenou včetně DPH a všemi
            dalšími poplatky (PHE apod.). Tato cena bude uvedena v objednávce a ve zprávě
            potvrzující přijetí objednávky zboží. Kupující spotřebitel má možnost se před
            provedením objednávky seznámit se skutečností, po jakou dobu zůstává nabídka
            nebo cena v platnosti. V případě, že se jedná <br/>
            o speciální zboží na objednávku, nebo zboží, které není na skladě, prodávající
            kupujícímu předem potvrdí telefonicky / e-mailem cenu a termín dodání. Tuto
            cenu je prodávající oprávněn měnit vzhledem k aktuální situaci na trhu a v
            závislosti na vývoji kurzu Kč vůči zahraničním měnám. Pokud kupující s
            takovouto změnou nesouhlasí, objednávku nepotvrdí <br/>
            a tato není realizována. Je-li kupujícím spotřebitel, musí být u takovéhoto
            zboží předem seznámen se skutečností, že jde o zboží na objednávku, u něhož k
            uzavření kupní smlouvy mezi oběma smluvními stranami dojde teprve v okamžiku
            upřesnění nabídky ze strany prodávajícího.</p>

          <p>Objednávat je možno
            následujícími způsoby:</p>

          <p>Elektronicky na adrese:<a href={"http://www.missiva.cz/"}>www.missiva.cz</a></p>

          <p>Písemnou formou e-mail: <a
            href={"mailto:objednávky@missiva.cz"}>objednávky@missiva.cz</a> fax :
            420 417 872&nbsp;187
          </p>

          <p>Telefonicky: 420 417 590&nbsp;911 , 420
            417 872&nbsp;302</p>


          <p>Prodávající
            doporučuje kupujícímu provádět objednávky přes elektronický obchod nebo
            písemnou formou (e-mail, fax) a uvést následující údaje:</p>

          <ul>
            <li>
              jméno, příjmení a bydliště nebo obchodní jméno a sídlo kupujícího – IČO a DIČ,
              je-li registrován jako plátce DPH
            </li>

            <li>
              kód a název zboží dle ceníku, jednotkovou cenu a počty kusů, způsob odebrání a
              dopravy, dodací adresu, podpis osoby oprávněné objednávat zboží a všechny
              údaje, které kupující považuje za důležité.
            </li>
          </ul>
        </li>

        <li>
          <strong>Odstoupení od smlouvy</strong>


          <p>Kupující
            spotřebitel má právo dle platné legislativy odstoupit od smlouvy ve lhůtě 14
            dní od převzetí zboží, pokud byla smlouva uzavřena pomocí prostředků komunikace
            na dálku. Rozhodne-li se spotřebitel využít tohoto práva, musí odstoupení od
            smlouvy doručit prodávajícímu nejpozději do 14. dne od převzetí věci.</p>


          <p>Zboží
            je kupující spotřebitel povinen vrátit úplné, s kompletní dokumentací,
            nepoškozené, čisté, v originálním obalu, ve stavu a hodnotě, v jakém zboží
            převzal. V případě, že bude vrácené zboží nekompletní či poškozené, může
            prodávající vrácenou kupní cenu snížit o tomu odpovídající částku. Peníze za
            vrácené zboží budou kupujícímu spotřebiteli vráceny do 30 dnů od účinnosti
            odstoupení od smlouvy kupujícího.</p>

          <p>Poškozené
            zboží nebo jinak znečištěné a neodpovídající hygienickým standardům není
            prodávající povinen přijmout zpět.</p>

          <p>Rozhodne-li
            se kupující pro odstoupení od smlouvy v uvedené
            lhůtě, doporučujeme pro urychlení vyřízení odstoupení od smlouvy zboží doručit
            na adresu prodávajícího spolu <br/>
            s přiloženým průvodním dopisem s případným důvodem odstoupení od kupní smlouvy
            (není podmínkou), s číslem nákupního dokladu a uvedeným číslem bankovního účtu
            nebo <br/>
            s uvedením, zdali částka bude odebrána v&nbsp;hotovosti.</p>


          <p>Kupující
            bere na vědomí skutečnost, že jsou-li se zbožím poskytovány dárky, je darovací
            smlouva mezi prodávajícím a kupujícím uzavřena s podmínkou, že dojde-li k
            využití práva spotřebitele odstoupit od smlouvy, darovací smlouva pozbývá
            účinnosti a kupující je povinen spolu s vráceným zbožím vrátit i s ním
            související poskytnuté dárky.</p>


          <p>Při
            splnění všech výše uvedených podmínek pro vrácení zboží vznikne kupujícímu
            kredit, který na jeho žádost bude odeslán na jím uvedený účet, a to nejpozději
            do 30 dnů od nabytí účinnosti odstoupení od smlouvy.&nbsp; Při neuvedení čísla
            účtu je částka v téže lhůtě automaticky připravena k vyzvednutí v hotovosti na
            základě předložení originálního dobropisu, který je bez zbytečného odkladu po
            vyřešení odstoupení od kupní smlouvy zákazníkovi zaslán.</p>
        </li>
        <li>
          <strong>Platební podmínky</strong>

          <ul>
            <li>platba v&nbsp;hotovosti/kartou
              při nákupu v&nbsp;provozovně na adrese Rtyně nad Bílinou 6
            </li>

            <li>platba předem bankovním
              převodem
            </li>

            <li>
              na dobírku při doručení zboží (hotovost přebírá od zákazníka přepravce). Zboží
              zůstává do úplného zaplacení majetkem prodávajícího.
            </li>
          </ul>
        </li>
        <li>
          <strong>Dodací podmínky</strong>


          <p>Osobní odběr: Zboží může
            převzít pouze kupující. </p>


          <p>Zasílání
            přepravní službou - ČR: Zboží je možno kupujícímu zaslat Geisem nebo balíkem do
            ruky od České pošty. Objednávky přijaté v&nbsp;pracovní dny do 12:00 jsou
            obvykle doručovány následující pracovní den. Přepravce běžně dodává zásilky
            kamkoliv do ČR do 24&nbsp; hodin. Cena přepravy se řídí výší objednávky. U
            objednávky do 1&nbsp;000,- Kč činí cena za přepravu 120,- Kč <br/>
            u objednávek nad 1&nbsp;000,- Kč je přeprava za 20,- Kč</p>


          <p>Zasílání
            přepravní službou - SR: Zboží je možno kupujícímu zaslat přepravní službou Geis.
            Podmínkou je předem uhrazená objednávka včetně ceny za přepravu.&nbsp;Cena za
            dopravu bude stanovena při potvrzení objednávky v&nbsp;závislosti na její
            celkové ceně. Cena za dopravu je rozdělena na balné v&nbsp;ČR do ceny
            objednávky 40,-EUR včetně DPH je 3,80 EUR nad tuto částku je objednávka bez
            balného a manipulační poplatek za dopravu v&nbsp;SR, který plynule klesá z
            částky 10,80 EUR až při překročení ceny objednávky 104,-EUR bez DPH se
            manipulační poplatek neúčtuje.</p>

          <p>Neúplnou
            nebo poškozenou zásilku je nutno neprodleně oznámit e-mailem na adresu <a
              href={"mailto:objednávky@missiva.cz"}>objednávky@missiva.cz</a>
            V&nbsp;případě poškození – nepřebírat zásilku od dopravce.</p>
        </li>

        <li>
          <strong>Záruční podmínky</strong>


          <p>Záruční
            podmínky na zboží se řídí Reklamačním řádem prodávajícího a platnými právními
            předpisy ČR.&nbsp;Jako záruční list slouží nákupní doklad.</p>
        </li>
        <li>
          <strong>Závěrečná
            ustanovení</strong>

          <p>Tyto Všeobecné obchodní
            podmínky jsou platné od&nbsp;1. října 2010&nbsp;. Prodávající si vyhrazuje
            právo změnit tyto Všeobecné obchodní podmínky bez předchozího upozornění.</p>


          <p>Za společnost MISSIVA, spol.
            s&nbsp;r.o. schválil Miloslav Špelda</p>


          <p>Vypracováno v&nbsp;souladu
            s&nbsp;občanským zák. č. 40/1964 Sb. § 619-627
            a zák č. 634/1992, oba ve znění novel.</p>
        </li>
      </ol>

      <h1>Reklamační řád Zákaznického centra</h1>

      <p>Reklamaci podává zákazník: písemnou
        formou - e-mail:<a href={"mailto:reklamace@missiva.cz"}>reklamace@missiva.cz</a>
        fax: 420 417 872&nbsp;187, Telefonicky: 420 417 590&nbsp;911, Adresa:
        MISSIVA, spol. s&nbsp;r.o., Rtyně nad Bílinou 6, 417 62 Rtyně nad Bílinou či
        osobně na zákaznické centrum.</p>

      <p>Formuláře pro stažení naleznete na stránkách: <a
        href={"http://www.missiva.cz/"}>www.missiva.cz</a> v&nbsp;sekci „ke stažení pro
        poradce“</p>

      <ol>

        <li>Reklamace služby</li>

        <ol>
          <li>Kompetentní
            pracovník zjistí, zda je reklamace oprávněná či ne.

            <p>OPRÁVNĚNÁ –
              dohodne se se zákazníkem o nápravě a navrhne řešení, které je pro zákazníka
              přijatelné.</p>

            <p>NEOPRÁVNĚNÁ –
              Kompetentní pracovník odpoví zákazníkovi korektním zamítnutím (dopisem,
              telefonicky, osobně), kde ho informuje o daných skutečnostech.</p>
          </li>
          <li>Při
            přejímce zboží ve skladu je kupující povinen zkontrolovat si své zboží (počet
            kusů, obaly, druh zboží atd.). Zjištěné neshody při přejímce oznámí ihned
            pracovníkovi skladu.

            <p><b>Na pozdější
              reklamace nebude brán zřetel.</b></p>
          </li>
          <li>Reklamaci
            obchodního balíku, musí zákazník uplatnit bez zbytečného odkladu a to nejlépe
            do druhého dne od převzetí zásilky.
          </li>

          <li>Nevydané
            či zaměněné zboží v&nbsp;zásilce, má adresát právo oznámit bez zbytečného
            odkladu nejlépe následující pracovní den po převzetí zaslaného zboží a to na
            zákaznickém centru firmy MISSIVA, spol. s&nbsp;r.o. tel. č. 417&nbsp;590
            911 a dohodnout způsob nápravy.
          </li>

          <li>Každý
            zákazník, který objednává zboží prostřednictvím dopravní společnosti, je
            povinen zásilku od Přepravce převzít. Pokud kupující, který si objednal zboží
            prostřednictvím zásilkové služby, zboží od Přepravce nepřevezme, prodávající mu
            v příští objednávce účtuje 150,- Kč případně i manipulační poplatek.
          </li>
        </ol>


        <li>Reklamace produktu</li>
        <ol>
          <li>K&nbsp;reklamaci
            pracovník skladu přijme zboží s&nbsp;nejméně cca 80% obsahem.
          </li>

          <li>Záruka
            se nevztahuje na zbožíprokazatelně mechanicky
            násilím poškozené a zboží prošlé záruční lhůtou.
          </li>

          <li>Oznámení
            zákazníka o vadách zboží, dokládá jím řádně vyplněným Reklamačním protokolem/produkt
            <br/>
            a kopií potvrzené objednávky či faktury.
          </li>

          <li>Pracovník
            skladu zjistí, zda se jedná o reklamaci obalu či obsahu

            <p>OBAL –
              reklamaci obalu vyřizuje sám pracovník ZS a to ihned po převzetí reklamace. </p>

            <p>Způsob
              vyřízení zapíše do Reklamačního protokolu. </p>

            <p>OBSAH –
              pracovník skladu převezme reklamaci od zákazníka a předá mu ústřižek
              z&nbsp;Reklamačního protokolu, který slouží zákazníkovi jako potvrzení o
              předání reklamace a pracovníkovi skladu k&nbsp;identifikaci </p>

            <p>při výdeji
              vyřízené reklamace. Tento druh reklamace postoupí pracovník skladu
              technologovi, který rozhodne, zda reklamace bude postoupena kontrole či ne.
              Technolog či středisko kontroly se k&nbsp;dané reklamaci písemně vyjádří
              v&nbsp;Reklamačním protokolu. PZS zajistí předání reklamace reklamujícímu
              zákazníkovi.</p>
          </li>
          <li>Osobní
            předání reklamace zákazníkovi je vždy stvrzeno podpisem zákazníka o převzetí
            reklamace a pokud <br/>
            je vyřízená reklamace zaslána společně s&nbsp;objednaným zbožím je povinností
            PZS uvést tuto informaci v&nbsp;Reklamačním protokolu. Do kolonky
            Výdej vyřízené reklamace zapíše č. obchodního balíku nebo VS dané zakázky.
          </li>

          <li>Je-li reklamace oprávněná, prodávající zjištěné vady zboží
            odstraní v&nbsp;přiměřené lhůtě – maximálně do 30 dnů od zahájení
            reklamačního řízení. Reklamační řízení je zahájeno vždy v&nbsp;den přijetí
            reklamace prodejním skladem.
          </li>
        </ol>
      </ol>
      <p>Nebude-li některá z&nbsp;výše
        uvedených podmínek kupujícím, dodržena, nemusí být reklamace prodávajícím uznána za
        oprávněnou. </p>

      <p>&nbsp;</p>


      <p>&nbsp;</p>

      <p>Ve Rtyni nad Bílinou dne:
        Eva Francová</p>

      <p>
        Vedoucí zákaznického centra</p>

      <p>MISSIVA, spol. s&nbsp;r.o.</p>

      <div
        className={"text-center my-5"}
      >
        <button
          className={"btn btn-primary"}
          onClick={() => window.close()}
        >Zavřít stránku a vrátit se na registraci
        </button>
      </div>
    </div>
  </div>
)

export default Terms
