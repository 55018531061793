import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {NavLink} from "react-router-dom"
import axios from "axios"
import API from "../../API"
import {getParams} from "../../Utils"
import Grid from "../../Base/Grid"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"

export default class LocalTextList extends Grid {
  constructor(props) {
    super(props)

    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      keys: [],
      page: parseInt(props.match.params.page, 10) || 1,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
    }

    this.apiUrl = "/local_text"
    this.appUrl = "/enums/local-text"
  }

  render() {
    return (
      <ScrollableTable>
        <BootstrapTable
          bootstrap4={true}
          remote={true}
          striped={true}
          hover={true}
          keyField={"id"}
          columns={this.getDefaultColumns()}
          data={this.state.data}
          onTableChange={this.handleTableChange.bind(this)}
          pagination={paginationFactory({
            page: (this.state.params.offset / this.state.params.limit) + 1,
            sizePerPage: this.state.params.limit,
            totalSize: this.state.total
          })}
          noDataIndication={"Nic nenalezeno"}
        />
      </ScrollableTable>
    )
  }

  _getData() {
    axios.all([
      this.getLocalTextKeys()
    ]).then(axios.spread((localTextKeysRes) => {
      this.setState({
        keys: localTextKeysRes.data.objects,
      }, this.getData)
    }))
  }

  getData() {
    API.get("/local_text")
      .then(({data}) => {
        this.setState({
          data: data.objects
        })
      })
  }

  getLocalTextKeys() {
    return API.get("/local_text/keys")
  }

  getDefaultColumns() {
    return [{
      text: "Název",
      dataField: "local_key",
      formatter: this.formatName.bind(this),
      formatExtraData: this.state.keys,
    },]
  }

  formatName(cell, row, rowIndex, formatExtraData) {
    let key = formatExtraData.find(data => data.key === cell)

    return (
      <NavLink
        to={`/enums/local-text/${row.id}/detail`}
      >{key.name}</NavLink>
    )
  }
}

