import React from "react"
import styles from "./style.module.css"
import ProductCatalogueRow from "./MyProductCatalogueRow"

let handleCategoryClick = (orderId, categoryId) => {
  document.getElementById(`order-${orderId}-category-${categoryId}`).scrollIntoView({
    behavior: "smooth"
  })
}

export default props => (
  <div
    className={`modal ${props.isOpen ? "d-block" : ""} ${styles.catalogueModal}`}
  >
    <div
      className={`modal-dialog modal-dialog-scrollable ${styles.catalogueModalDialog}`}
    >
      <div
        className={"modal-content"}
      >
        <div
          className={"modal-header font-weight-bold"}
        >
          Katalog produktů Missiva

          <button
            type={"button"}
            className={"close"}
            onClick={props.closeHandler}
          >&times;</button>
        </div>

        <div
          className={`modal-header d-block ${styles.modalHeader}`}
        >
          {props.productCategories
            .filter(category => category.id !== 1) // :facepalm:
            .map(category => (
              <button
                key={category.id}
                type={"button"}
                className={"btn btn-sm btn-primary mr-1 mb-1 w-auto"}
                onClick={() => handleCategoryClick(props.orderId, category.id)}
              >{category.name}</button>
            ))}
        </div>

        <div
          className={"modal-body"}
        >
          <table
            className={"table"}
          >
            <tbody>
            {/* products */}
            {props.productTree
              .filter(node => node.product_id !== null)
              .filter(node => node.product.is_my_order_visible)
              .sort((a, b) => a.product.code - b.product.code)
              .map(node => (
                <ProductCatalogueRow
                  key={node.id}
                  node={node}
                  orderId={props.orderId}
                  selectProductHandler={props.selectProductHandler}
                />
              ))}

            {/* categories */}
            {props.productTree
              .filter(node => node.category_id !== null)
              .map(node => (
                <ProductCatalogueRow
                  key={node.id}
                  node={node}
                  orderId={props.orderId}
                  selectProductHandler={props.selectProductHandler}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)
