import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {NavLink} from "react-router-dom"
import axios from "axios"
import moment from "moment"
import Grid from "../Base/Grid"
import {getParams} from "../Utils"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import API from "../API"

export default class SponsorHistory extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      customer: {},
      customerTypes: [],
      total: 0,
      isLoading: false,
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        customer_id: this.props.match.params.id,
        group: "sponsor_id",
        sort: "-date_start"
      }, params),
      _columns: [],
    }

    this.apiUrl = "/customer/history"
    this.appUrl = `/customers/${this.props.match.params.id}/sponsor-history`

    this.defaultParams = ["customer_id", "group"]
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={`/customers/${this.props.match.params.id}/detail`}
            className={"btn btn-primary"}
          >Zpět na detail
            zákazníka {this.state.customer.surname || ""} {this.state.customer.name || ""}</NavLink>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getCustomerDetail(),
      this.getCustomerTypes(),
    ]).then(axios.spread((customerDetailRes, customerTypesRes) => {
      this.setState({
        customer: customerDetailRes.data,
        customerTypes: customerTypesRes.data.objects,
      }, this.getData)
    }))
  }

  getCustomerDetail() {
    return API.get(`/customers/${this.props.match.params.id}`)
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  formatDateStart(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY HH:mm:ss")
  }

  formatSponsorType(cell, row, rowIndex, formatExtraData) {
    let type = formatExtraData.find(customerType => customerType.id === row.sponsor.type_id)

    if (type) {
      return type.name
    }

    return ""
  }

  formatSponsorCustomerNo(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.sponsor.id}/detail`}
      >{row.sponsor.customer_no}</NavLink>
    )
  }

  formatSponsorSurname(cell, row, rowIndex, formatExtraData) {
    return row.sponsor.surname || ""
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    return row.sponsor.name || ""
  }

  getDefaultColumns() {
    return [{
      text: "D. přeregistrování",
      dataField: "date_start",
      formatter: this.formatDateStart.bind(this),
    }, {
      text: "Typ sponzora",
      dataField: "type_id",
      formatter: this.formatSponsorType.bind(this),
      formatExtraData: this.state.customerTypes,
    }, {
      text: "Č. sponzora",
      dataField: "sponsor__customer_no",
      formatter: this.formatSponsorCustomerNo.bind(this),
    }, {
      text: "Příjmení sponzora",
      dataField: "sponsor__surname",
      formatter: this.formatSponsorSurname.bind(this),
    }, {
      text: "Jméno sponzora",
      dataField: "sponsor__name",
      formatter: this.formatSponsorName.bind(this),
    }]
  }
}
