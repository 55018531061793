// app version
const APP_VERSION = "0.2"

// known hosts
const HOST_NAME = {
  LOCALHOST: "localhost",
  DEV_IS: "dev.missiva.cz",
  DEV_MY: "dev.moje.missiva.cz",
  TEST_IS: "test.missiva.cz",
  TEST_MY: "test.moje.missiva.cz",
  PROD_IS: "is.missiva.cz",
  PROD_MY: "moje.missiva.cz",
}

// host name API url
const API_URL = {
  [HOST_NAME.LOCALHOST]: "https://dev.missiva.cz:643/public/api/",
  [HOST_NAME.DEV_IS]: "https://dev.missiva.cz:643/public/api/",
  [HOST_NAME.DEV_MY]: "https://dev.moje.missiva.cz:643/public/api/",
  [HOST_NAME.TEST_IS]: "https://test.missiva.cz:643/public/api/",
  [HOST_NAME.TEST_MY]: "https://test.moje.missiva.cz:643/public/api/",
  [HOST_NAME.PROD_IS]: "https://is.missiva.cz/public/api/",
  [HOST_NAME.PROD_MY]: "https://moje.missiva.cz/public/api/",
}

// determines whether to show MY Missiva or Missiva IS
const is_MY = window.location.hostname === HOST_NAME.DEV_MY ||
  window.location.hostname === HOST_NAME.TEST_MY ||
  window.location.hostname === HOST_NAME.PROD_MY

export {
  HOST_NAME,
  API_URL,
  is_MY,
  APP_VERSION,
}
