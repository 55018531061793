import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {NavLink} from "react-router-dom"
import {getParams} from "../../Utils"
import Grid from "../../Base/Grid"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"

export default class TemplateList extends Grid {
  constructor(props) {
    super(props)

    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      keys: [],
      page: parseInt(props.match.params.page, 10) || 1,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
      _columns: [],
    }

    this.apiUrl = "/notification-template"
    this.appUrl = "/enums/templates"
  }

  render() {
    return (
      <>
        {this.state.isLoading && <SpinnerWithOverlay/>}

        {this.state._columns.length > 0 && (
          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              columns={this.state._columns}
              data={this.state.data}
              onTableChange={this.handleTableChange.bind(this)}
              pagination={paginationFactory({
                page: (this.state.params.offset / this.state.params.limit) + 1,
                sizePerPage: this.state.params.limit,
                totalSize: this.state.total
              })}
              noDataIndication={"Nic nenalezeno"}
            />
          </ScrollableTable>
        )}
      </>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Služba",
      dataField: "service",
      formatter: this.formatService.bind(this),
    }, {
      text: "Předmět",
      dataField: "subject",
    }, {
      text: "Obsah",
      dataField: "content",
      formatter: this.formatContent.bind(this),
    },]
  }

  formatService(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/enums/templates/${row.service}/${row.type}`}
      >{cell === "mail" ? "E-mail" : "SMS"}</NavLink>
    )
  }

  formatContent(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: cell
        }}
      />
    )
  }
}

