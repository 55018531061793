import React from "react"
import {components} from "react-select"

const ReactSelectTreeOption = innerProps => {

  let category = innerProps.categories.filter(category => category.name === innerProps.children)

  return (
    <components.Option
      {...innerProps}
    >
      <div
        ref={innerProps.innerRef}
      >
        <div
          style={{
            paddingLeft: `${(category.length > 0 ? category[0].depth : 0) * 20}px`,
          }}
        >{innerProps.children}</div>
      </div>
    </components.Option>
  )
}

export default ReactSelectTreeOption
