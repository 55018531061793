import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarEnums = () => {
  return (
    <Switch>
      <Route
        path={"/enums"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/enums/users"}
              className={"nav-link"}
              activeClassName={"active"}
            >Uživatelé</NavLink>
            <NavLink
              to={"/enums/banks"}
              className={"nav-link"}
              activeClassName={"active"}
            >Banky</NavLink>
            <NavLink
              to={"/enums/countries"}
              className={"nav-link"}
              activeClassName={"active"}
            >Země</NavLink>
            <NavLink
              to={"/enums/posts"}
              className={"nav-link"}
              activeClassName={"active"}
            >Pošty</NavLink>
            <NavLink
              to={"/enums/units"}
              className={"nav-link"}
              activeClassName={"active"}
            >Měrné jednotky</NavLink>
            <NavLink
              to={"/enums/contact-types"}
              className={"nav-link"}
              activeClassName={"active"}
            >Typy kontaktů</NavLink>
            <NavLink
              to={"/enums/package-types"}
              className={"nav-link"}
              activeClassName={"active"}
            >Typy balíků</NavLink>
            <NavLink
              to={"/enums/delivery-methods"}
              className={"nav-link"}
              activeClassName={"active"}
            >Způsoby doručení</NavLink>
            <NavLink
              to={"/enums/order-methods"}
              className={"nav-link"}
              activeClassName={"active"}
            >Způsoby objednání</NavLink>
            <NavLink
              to={"/enums/categories"}
              className={"nav-link"}
              activeClassName={"active"}
            >Kategorie produktů</NavLink>
            <NavLink
              to={"/enums/local-text"}
              className={"nav-link"}
              activeClassName={"active"}
            >Texty dokumentů a upozornění</NavLink>
            <NavLink
              to={"/enums/templates"}
              className={"nav-link"}
              activeClassName={"active"}
            >Šablony e-mailů a SMS</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarEnums
