import React, {Component} from "react"
import Select from "react-select"
import {ReactSelectCustomStyles} from "./ReactSelectCustomStyles"

export default class GridMenu extends Component {
  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-8 mb-3 mb-lg-0 mb-xl-0 grid-menu"}
        >
          {this.props.children}
        </div>

        <div
          className={"col-lg-4 text-right"}
        >
          <Select
            className={"mr-3 d-inline-block react-bootstrap-table-filter text-left"}
            onChange={this.props.toggleColumnHandler}
            value={"default"}
            options={this.getOptions()}
            closeMenuOnSelect={false}
            placeholder={"Zobrazit/schovat sloupce"}
            styles={ReactSelectCustomStyles}
          />
          <button
            className={"btn btn-light"}
            onClick={this.props.resetFiltersHandler}
          >Vymazat filtry
          </button>
        </div>
      </div>
    )
  }

  getOptions() {
    let options = []

    this.props.columns.forEach(column => {
      if (!column.alwaysVisible) {
        options.push({
          value: column.dataField,
          label: `${column.visible ? "\u2611" : "\u2610"} ${column.text}`
        })
      }
    })

    return options
  }

}
