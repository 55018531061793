import React, {Component} from "react"
import API from "../API"
import HistoryTable from "../Base/HistoryTable/HistoryTable"
import productPropName from "../fixtures/productPropName"
import productValueName from "../fixtures/productValueName"
import productEntity from "../fixtures/productEntity"

export default class ProductHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getData()
    }
  }

  render() {
    return (
      <HistoryTable
        name={"detail produktu"}
        data={this.state.data}
        entityPropName={productPropName}
        entityValueName={productValueName}
        entity={productEntity}
        isLoading={this.state.isLoading}
      />
    )
  }

  getData() {
    API.get(`/history/product/${this.id}`)
      .then(({data}) => {
        this.setState({
          data: data.objects,
          isLoading: false,
        })
      })
  }
}
