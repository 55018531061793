import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import moment from "moment"
import {NavLink} from "react-router-dom"
import axios from "axios"
import Select from "react-select"
import API from "../../API"
import {getParams, serializeParams} from "../../Utils"
import RewardListFilter from "../RewardListFilter"
import ExportButton from "../../Base/ExportButton"
import commissionExportColumn from "../../fixtures/commisionExportColumn"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import Grid from "../../Base/Grid"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import CUSTOMER_TYPE from "../../Base/Enum/CustomerType"
import CommissionInvoiceList from "./CommissionInvoiceList"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import CONTACT_TYPE from "../../Base/Enum/ContactType"
import COUNTRY from "../../Base/Enum/Countries"

export default class CommissionList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()

    this.state = {
      data: [],
      meta: {},
      total: 0,
      params: Object.assign({
        offset: 0,
        limit: 0,
        date: moment().subtract(1, "months").format("YYYY/MM"),
        sort: "-customer_points__commision",
        has: "customer_points__commision|customer_points__commision_direct"
      }, params),
      customerType: [], // all customer types for grid data
      customerTypeId: [], // selected customer type ids from grid filter
      countries: [],
      selectedRows: [],
      isInvoiceListVisible: false,
      countryCurrencies: [],
    }

    this.defaultSorted = {
      dataField: "customer_points__commision",
      order: "desc",
    }

    this.defaultParams = ["has"]
    this.filters = {}
  }

  render() {
    // this component should not be visible from any url, so I think this is the right way
    if (this.state.isInvoiceListVisible) {
      return (
        <CommissionInvoiceList
          date={this.state.params.date}
          customerIds={this.state.selectedRows}
          isInvoiceVisibleHandler={this.handleIsInvoiceListVisible.bind(this)}
        />
      )
    }

    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 text-right"}
        >
          <button
            className={"btn btn-primary mr-3"}
            onClick={() => this.handleIsInvoiceListVisible(true)}
            disabled={this.state.selectedRows.length === 0}
          >Vygenerovat faktury
          </button>

          <ExportButton
            exportName={"commission"}
            fileName={"provize"}
            params={this.state.params}
            columns={commissionExportColumn}
            apiUrl={`/commision/${this.state.params.date}`}
          />
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Zobrazit zákazníky typu:</label>
          <div
            className={"form-group"}
          >
            <input
              type={"checkbox"}
              className={"d-inline-block mr-1"}
              id={"CK"}
              value={CUSTOMER_TYPE.CK}
              onChange={this.handleCustomerTypeChange.bind(this)}
              checked={this.state.customerTypeId.indexOf(CUSTOMER_TYPE.CK) > -1}
            />
            <label
              className={"d-inline-block mr-3"}
              htmlFor={"CK"}
            >CK</label>

            <input
              type={"checkbox"}
              className={"d-inline-block mr-1"}
              id={"MS"}
              value={CUSTOMER_TYPE.MS}
              onChange={this.handleCustomerTypeChange.bind(this)}
              checked={this.state.customerTypeId.indexOf(CUSTOMER_TYPE.MS) > -1}
            />
            <label
              className={"d-inline-block mr-3"}
              htmlFor={"MS"}
            >MS</label>

            <input
              type={"checkbox"}
              className={"d-inline-block mr-1"}
              id={"PP"}
              value={CUSTOMER_TYPE.PP}
              onChange={this.handleCustomerTypeChange.bind(this)}
              checked={this.state.customerTypeId.indexOf(CUSTOMER_TYPE.PP) > -1}
            />
            <label
              className={"d-inline-block mr-3"}
              htmlFor={"PP"}
            >PP</label>

            <input
              type={"checkbox"}
              className={"d-inline-block mr-1"}
              id={"RD"}
              value={CUSTOMER_TYPE.RD}
              onChange={this.handleCustomerTypeChange.bind(this)}
              checked={this.state.customerTypeId.indexOf(CUSTOMER_TYPE.RD) > -1}
            />
            <label
              className={"d-inline-block mr-3"}
              htmlFor={"RD"}
            >RD</label>
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <div
            className={"form-group"}
          >
            <label>DPH:</label>
            <div
              className={"form-check"}
            >
              <input
                className={"form-check-input"}
                type={"checkbox"}
                id={"only_with_tax_no"}
                checked={this.state.params.tax_no === "!null"}
                onChange={this.handleWithTaxNoChange.bind(this)}
              />
              <label
                className={"form-check-label"}
                htmlFor={"only_with_tax_no"}
              >Jen plátci</label>
            </div>
            <div
              className={"form-check"}
            >
              <input
                className={"form-check-input"}
                type={"checkbox"}
                id={"only_without_tax_no"}
                checked={this.state.params.tax_no === "null"}
                onChange={this.handleWithoutTaxNoChange.bind(this)}
              />
              <label
                className={"form-check-label"}
                htmlFor={"only_without_tax_no"}
              >Jen neplátci</label>
            </div>
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Číslo účtu:</label>
          <div
            className={"form-check"}
          >
            <input
              className={"form-check-input"}
              type={"checkbox"}
              id={"only_with_bank_account"}
              checked={this.state.params.bank_account === "!null"}
              onChange={this.handleBankAccountChange.bind(this)}
            />
            <label
              className={"form-check-label"}
              htmlFor={"only_with_bank_account"}
            >Jen s číslem účtu</label>
          </div>
        </div>

        <div
          className={"col-lg-5 mb-3"}
        >
          <label>Země:</label>
          <Select
            value={this.state.countries.filter(country => this.state.params.country_id.indexOf(country.id) > -1)}
            placeholder={"Země"}
            isMulti={true}
            options={this.state.countries}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            styles={ReactSelectCustomStyles}
            onChange={this.handleCountryChange.bind(this)}
          />
        </div>

        <div
          className={"col-lg-6 mb-3"}
        >
          <RewardListFilter
            date={this.state.params.date}
            dateChangeHandler={this.handleDateChange.bind(this)}
            getDataHandler={this.getData.bind(this)}
          />
        </div>

        <div
          className={"col-lg-6 mt-lg-4 mb-3 text-right"}
        >
          <button
            type={"button"}
            className={"btn btn-primary"}
            onClick={this.handleKBCommissionFileClick.bind(this)}
          >Export provizí pro KB
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <BootstrapTable
              bootstrap4={true}
              remote={true}
              striped={true}
              hover={true}
              keyField={"id"}
              ref={ref => this.gridTableRef = ref}
              columns={this.getColumns()}
              data={this.state.data}
              onTableChange={this.handleTableChange.bind(this)}
              filter={filterFactory()}
              noDataIndication={"Nic nenalezeno"}
              defaultSorted={[this.defaultSorted]}
              sort={this.sort || {}}
              selectRow={{
                mode: "checkbox",
                selected: this.state.selectedRows,
                onSelect: this.handleRowSelect.bind(this),
                onSelectAll: this.handleRowSelectAll.bind(this),
              }}
              rowClasses={this.handleRowClass.bind(this)}
            />
          </ScrollableTable>
        </div>
      </div>
    )
  }

  // overridden
  _getData() {
    axios.all([
      this.getCustomerTypes(),
      this.getCountries(),
      this.getCountryCurrencies()
    ]).then(axios.spread((customerTypesRes, countriesRes, countryCurrenciesRes) => {
      let countries = []
      let customerType = [...customerTypesRes.data.objects]
      let customerTypeId

      countries = countriesRes.data.objects.map(country => Object.assign({}, country, {
        value: country.id,
        label: country.code,
      }))

      if (this.state.params.type_id) {
        customerTypeId = this.state.params.type_id
          .replace("=", "")
          .split("|")

        customerTypeId = customerTypeId.map(typeId => parseInt(typeId, 10))
      } else {
        customerTypeId = [
          CUSTOMER_TYPE.CK,
          CUSTOMER_TYPE.MS,
          CUSTOMER_TYPE.PP,
          CUSTOMER_TYPE.RD,
        ]
      }

      this.setState({
        customerType: customerType,
        customerTypeId: customerTypeId,
        countries: countries,
        countryCurrencies: countryCurrenciesRes.data.objects,
        params: Object.assign({}, this.state.params, {
          type_id: `=${customerTypeId.join("|")}`,
          country_id: `=${countries.map(country => country.id).join("|")}`,
        })
      })
    }))
  }

  // overridden
  getData() {
    this.setState({
      isLoading: true,
    })

    let params = Object.assign({}, this.state.params)
    let serializedParams = serializeParams(params)

    window.history.replaceState({}, "", `/#/reports/commissions/commissions${serializedParams}`)

    delete params.date
    serializedParams = serializeParams(params)

    API.get(`/commision/${this.state.params.date}${serializedParams}`)
      .then(({data}) => {
        this.setState({
          data: data.objects,
          meta: data.meta,
          total: data.meta.total_count,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  // overridden
  saveSettings() {}

  // overridden
  // disable automatic update
  updateIntervalCb() {}

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getCountryCurrencies() {
    return API.get(`/countries?exchange_rate_date=${moment().format("YYYY-MM-DD")}`)
  }

  handleCustomerTypeChange(e) {
    let value = parseInt(e.target.value, 10)
    let customerTypeId = [...this.state.customerTypeId]
    let index = customerTypeId.indexOf(value)

    if (index > -1) {
      customerTypeId.splice(index, 1)
    } else {
      customerTypeId.push(value)
    }

    this.setState({
      customerTypeId: customerTypeId,
      params: Object.assign({}, this.state.params, {
        type_id: `=${customerTypeId.join("|")}`
      })
    })
  }

  handleWithTaxNoChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        tax_no: e.target.checked ? "!null" : "",
      })
    })
  }

  handleWithoutTaxNoChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        tax_no: e.target.checked ? "null" : "",
      })
    })
  }

  handleBankAccountChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        bank_account: e.target.checked ? "!null" : "",
      })
    })
  }

  handleDateChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {date})
    })
  }

  handleKBCommissionFileClick() {
    let exportParams = Object.assign({}, this.state.params)
    delete exportParams.date
    exportParams = serializeParams(exportParams)

    API.get(`/customer-points/commissions/${this.state.params.date}${exportParams}`)
      .then(({data}) => window.open(data.filepath))
  }

  getColumns() {
    return [{
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state.customerType,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Příjmení",
      dataField: "surname",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Jméno",
      dataField: "name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Fakt. adresa - jméno a příjmení",
      dataField: "billing_address_customer_name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Fakt. adresa - fakt. jméno a příjmení",
      dataField: "billing_address_billing_name",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Země",
      dataField: "country_id",
      formatter: this.formatCountry.bind(this),
      formatExtraData: this.state.countries,
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Provize",
      dataField: "customer_points__commision",
      sort: true,
      formatter: this.formatCommission.bind(this),
      footer: "commision",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision || 0,
    }, {
      text: "Provize s DPH",
      dataField: "customer_points__commision_vat",
      sort: true,
      formatter: this.formatCommissionVAT.bind(this),
      footer: "commision_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_vat || 0,
    }, {
      text: "Přímá provize",
      dataField: "customer_points__commision_direct",
      sort: true,
      formatter: this.formatCommissionDirect.bind(this),
      footer: "commision_direct",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_direct || 0,
    }, {
      text: "Přímá provize s DPH",
      dataField: "customer_points__commision_direct_vat",
      sort: true,
      formatter: this.formatCommissionDirectVAT.bind(this),
      footer: "commision_direct_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_direct_vat || 0,
    }, {
      text: "Celková provize",
      dataField: "customer_points__commision_total",
      sort: true,
      formatter: this.formatCommissionTotal.bind(this),
      footer: "commision_sum",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_total || 0,
    }, {
      text: "Celková provize s DPH",
      dataField: "customer_points__commision_total_vat",
      sort: true,
      formatter: this.formatCommissionTotalVAT.bind(this),
      footer: "commision_sum_vat",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_total_vat || 0,
    }, {
      text: "Provize EUR",
      dataField: "customer_points__commision_uer",
      sort: true,
      formatter: this.formatCommissionEUR.bind(this),
      footer: "commision_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_eur || 0,
    }, {
      text: "Provize s DPH EUR",
      dataField: "customer_points__commision_vat_eur",
      sort: true,
      formatter: this.formatCommissionVATEUR.bind(this),
      footer: "commision_vat_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_vat_eur || 0,
    }, {
      text: "Přímá provize EUR",
      dataField: "customer_points__commision_direct_eur",
      sort: true,
      formatter: this.formatCommissionDirectEUR.bind(this),
      footer: "commision_direct_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_direct_eur || 0,
    }, {
      text: "Přímá provize s DPH EUR",
      dataField: "customer_points__commision_direct_vat_eur",
      sort: true,
      formatter: this.formatCommissionDirectVATEUR.bind(this),
      footer: "commision_direct_vat_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_direct_vat_eur || 0,
    }, {
      text: "Celková provize EUR",
      dataField: "customer_points__commision_total_eur",
      sort: true,
      formatter: this.formatCommissionTotalEUR.bind(this),
      footer: "commision_sum_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_total_eur || 0,
    }, {
      text: "Celková provize s DPH EUR",
      dataField: "customer_points__commision_total_vat_eur",
      sort: true,
      formatter: this.formatCommissionTotalVATEUR.bind(this),
      footer: "commision_sum_vat_eur",
      footerClasses: "bg-info text-white",
      footerFormatter: () => this.state.meta.sums?.points[0].commision_total_vat_eur || 0,
    }, {
      text: "DIČ",
      dataField: "tax_no",
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Č. účtu",
      dataField: "bank_account",
      formatter: this.formatBankAccount.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "Telefon",
      dataField: "contact__phone",
      formatter: this.formatPhone.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    }, {
      text: "E-mail",
      dataField: "contact__email",
      formatter: this.formatEmail.bind(this),
      footer: "",
      footerClasses: "bg-info text-white",
    },]
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    let types = formatExtraData.filter(customerType => customerType.id === cell)

    if (types.length > 0) {
      return types[0].name
    }

    return ""
  }

  formatCountry(cell, row, rowIndex, formatExtraData) {
    let country = this.state.countries.find(country => country.id === cell)

    if (country) {
      return country.name
    }

    return ""
  }

  formatPrice(price, countryId) {
    let country = this.state.countryCurrencies.find(country => country.id === countryId)

    return (
      <span
        className={"nowrap"}
      >{price.toFixed(2)} {country.currency}</span>
    )
  }

  formatCommission(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_vat, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionDirect(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_direct, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionDirectVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_direct_vat, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionTotal(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_total, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionTotalVAT(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_total_vat, COUNTRY.CZECH_REPUBLIC)
    }

    return ""
  }

  formatCommissionEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatCommissionVATEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_vat_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatCommissionDirectEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_direct_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatCommissionDirectVATEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_direct_vat_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatCommissionTotalEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_total_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatCommissionTotalVATEUR(cell, row, rowIndex, formatExtraData) {
    if (row.points && row.points.length > 0) {
      return this.formatPrice(row.points[0].commision_total_vat_eur, COUNTRY.SLOVAK_REPUBLIC)
    }

    return ""
  }

  formatBankAccount(cell, row, rowIndex, formatExtraData) {
    if (
      row.bank_account &&
      row.bank_id
    ) {
      return `${row.bank_account}/${row.bank_id}`
    }

    return ""
  }

  formatPhone(cell, row, rowIndex, formatExtraData) {
    if (row.contact) {
      return row.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.PHONE_WORK ||
          contact.type_id === CONTACT_TYPE.PHONE_HOME ||
          contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
          contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
          contact.type_id === CONTACT_TYPE.TARIFF_02
        )
        .map(contact => (
          <a
            href={`tel:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatEmail(cell, row, rowIndex, formatExtraData) {
    if (row.contact) {
      return row.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .map(contact => (
          <a
            href={`mailto:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  handleRowSelect = (row, isSelected) => {
    this.setState({
      selectedRows: isSelected
        ? [...this.state.selectedRows, row.id]
        : this.state.selectedRows.filter(id => id !== row.id)
    })
  }

  handleRowSelectAll = (isSelected, rows) => {
    this.setState({
      selectedRows: isSelected
        ? rows
          .map(row => row.id)
        : []
    })
  }

  handleCountryChange(options) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        country_id: `=${(options || [])
          .map(country => country.id)
          .join("|")}`
      })
    })
  }

  handleIsInvoiceListVisible(isVisible) {
    this.setState({
      isInvoiceListVisible: isVisible,
    })
  }

  handleRowClass(row, rowIndex) {
    if (!row.bank_account) {
      return "bg-green"
    }

    return ""
  }
}
