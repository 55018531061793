import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"

export default class GridDateFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dateFrom: this.props.defaultValueFrom || "",
      dateTo: this.props.defaultValueTo || "",
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.defaultValueFrom !== this.props.defaultValueFrom &&
      prevProps.defaultValueTo !== this.props.defaultValueTo &&
      !this.props.defaultValueFrom &&
      !this.props.defaultValueTo
    ) {
      this.setState({
        dateFrom: "",
        dateTo: "",
      })
    }
  }

  render() {
    return (
      <div
        onClick={this.handleDateClick.bind(this)}
        className={"grid-date-range-filter nowrap"}
      >
        <DatePicker
          isClearable={this.state.dateFrom}
          locale={"cs"}
          className={"form-control"}
          selected={this.state.dateFrom ? moment(this.state.dateFrom).toDate() : ""}
          onChange={this.handleDateFromChange.bind(this)}
          dateFormat={"dd. MM. yyyy"}
          placeholderText={"Od"}
        />

        <DatePicker
          isClearable={this.state.dateTo}
          locale={"cs"}
          className={"form-control"}
          selected={this.state.dateTo ? moment(this.state.dateTo).toDate() : ""}
          onChange={this.handleDateToChange.bind(this)}
          dateFormat={"dd. MM. yyyy"}
          placeholderText={"Do"}
        />
      </div>
    )
  }

  // if column has allowed sorting, click will change the sort order -> this stop that
  handleDateClick(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDateFromChange(date) {
    this.setState({
      dateFrom: date ? moment(date).format("YYYY-MM-DD") : "",
    }, this.handleOnFilter.bind(this))
  }

  handleDateToChange(date) {
    this.setState({
      dateTo: date ? moment(date).format("YYYY-MM-DD") : "",
    }, this.handleOnFilter.bind(this))
  }

  handleOnFilter() {
    this.props.onFilter({
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
    })
  }
}
