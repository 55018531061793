import {List} from "immutable"

// clean api call data
const cleanState = stateObj => {
  let data = {}

  Object
    .keys(stateObj)
    .filter(key => key.indexOf("_") !== 0) // remove custom (FE specific) data fields
    .filter(key => key !== "log") // remove log data returned from backend
    .forEach(key => {
      if (List.isList(stateObj[key])) {
        data[key] = stateObj[key].toArray()
      } else {
        data[key] = stateObj[key]
      }
    })

  return data
}

const isNumber = str => /^[0-9]+$/.test(str)

// parse url params
const getParams = () => {
  let params = {}
  let queryStr = window.location.hash.split("?")[1]

  if (queryStr) {
    queryStr.split("&").forEach(q => {
      let keyVal = q.split("=")
      if (isNumber(keyVal[1])) {
        params[keyVal[0]] = parseInt(keyVal[1])
      } else {
        params[keyVal[0]] = decodeURIComponent(keyVal[1])
      }
    })
  }

  return params
}

// serialize url params object
const serializeParams = (obj) => {
  let str = "?"

  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== undefined
      && obj[key] !== ""
    ) {
      if (str !== "?") {
        str += "&"
      }

      str += `${key}=${encodeURIComponent(obj[key])}`
    }
  }

  return str
}

export {
  cleanState,
  getParams,
  serializeParams,
  isNumber,
}
