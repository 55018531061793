import React from "react"
import {NavLink} from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {customFilter, selectFilter, textFilter} from "react-bootstrap-table2-filter"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import moment from "moment"
import Grid from "../Base/Grid"
import API from "../API"
import {getParams} from "../Utils"
import GridMenu from "../Base/GridMenu"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable.js"
import GridDateFilter from "../Base/GridDateFilter"
import RBAC from "../RBAC/RBAC"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
// import ExportButton from "../Base/ExportButton"
// import myCustomerExportColumns from "../fixtures/myCustomerExportColumn"

export default class MyCustomerList extends Grid {
  constructor(props) {
    super(props)
    this.appUrl = "/my-customers/customers"
    this.apiUrl = "/my/customers"
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}
    this.state = {
      data: [],
      total: 0,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        sort: "-created_at",
        sponsor_id: params.sponsor_id,
      }),
      _types: {},
      _countries: {},
      _columns: [],
      _authenticatedCustomer: {},
    }
    this.filters = {}
    this.defaultSorted = {
      dataField: "created_at",
      order: "desc",
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.getAuthenticatedCustomer()
  }

  render() {
    return (
      <div
        className={"row"}
      >

        {/*<div*/}
        {/*  className={"col-12 mb-3 text-right"}*/}
        {/*>*/}
        {/*  <ExportButton*/}
        {/*    exportName={"customers"}*/}
        {/*    fileName={"Zákazníci"}*/}
        {/*    params={this.state.params}*/}
        {/*    columns={myCustomerExportColumns}*/}
        {/*    apiUrl={this.apiUrl}*/}
        {/*  />*/}
        {/*</div>*/}

        <div
          className={"col-12 mb-3"}
        >
          <GridMenu
            toggleColumnHandler={this.toggleColumn.bind(this)}
            resetFiltersHandler={this.resetFilters.bind(this)}
            columns={this.state._columns}
          >
            <span
              className={"text-secondary mr-2"}
            >Zobrazit:</span>
            <button
              className={`mr-2 btn btn-sm ${this.state.params.sponsor_id ? "bg-green" : "btn-info"}`}
              onClick={this.handleSponsorChange.bind(this)}
            >První linie
            </button>
          </GridMenu>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns.filter(col => col.visible)}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                filter={filterFactory()}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                defaultSorted={[this.defaultSorted]}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getTypes(),
      this.getCountries()
    ]).then(axios.spread((typesRes, countriesRes) => {
      let types = {}
      let countries = {}

      typesRes.data.objects.forEach(type => {
        types[type.id] = type.name
      })

      countriesRes.data.objects.forEach(country => {
        countries[country.id] = country.name
      })

      this.setState({
        _types: types,
        _countries: countries,
      }, this.getData)
    }))
  }

  getTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    return <strong>{formatExtraData[cell] || ""}</strong>
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    if (!this.isDirectChild(row))
      return ""

    return (
      <NavLink
        to={`/my-customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatSurname(cell, row, rowIndex, formatExtraData) {
    return this.isDirectChild(row)
      ? (
        <NavLink
          to={`/my-customers/${row.id}/detail`}
        >{cell || ""}</NavLink>
      ) : (
        cell || ""
      )
  }

  formatName(cell, row, rowIndex, formatExtraData) {
    return cell || ""
  }

  formatBillingAddressStreet(cell, row, rowIndex, formatExtraData) {
    if (!this.isDirectChild(row))
      return ""

    return cell
  }

  formatSponsorName(cell, row, rowIndex, formatExtraData) {
    if (!row.sponsor) // root customers do not have sponsors
      return ""

    return `${row.sponsor.name || ""} ${row.sponsor.surname || ""}`
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    if (!this.isDirectChild(row))
      return ""

    if (cell)
      return moment(cell).format("D. M. YYYY HH:mm:ss")
    return ""
  }

  formatContact(cell, row, rowIndex, formatExtraData) {
    if (!this.isDirectChild(row))
      return ""

    if (row.contact)
      return row.contact.map(contact => (
        <div
          key={contact.id}
        >{contact.value}</div>
      ))

    return ""
  }

  formatBonusTop(cell, row, rowIndex, formatExtraData) {
    if (!this.isDirectChild(row)) {
      return ""
    }

    return cell
  }

  formatPayment(cell, row, rowIndex, formatExtraData) {
    let date
    // get data from "payment" property
    if (cell && cell.length > 0)
      date = cell[0].created_at
    // get data from "info" property (existing user from v3.1)
    if (row.info && typeof row.info === "object")
      date = row.info.sale_last_paid_at || ""

    if (date)
      return moment(date).format("D. M. YYYY HH:mm:ss")

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Typ zákazníka",
      dataField: "type_id",
      filter: selectFilter({
        options: this.state._types,
        placeholder: "Typ zákazníka",
        defaultValue: this.state.params.type_id || "",
        getFilter: filter => this.filters.type_id = filter,
      }),
      headerClasses: () => this.state.params.type_id ? "bg-green" : "",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state._types,
      visible: true,
    }, {
      text: "Č. zákazníka",
      dataField: "customer_no",
      classes: "column-sticky",
      filter: textFilter({
        placeholder: "Č. zákazníka",
        defaultValue: this.state.params.customer_no ? `${this.state.params.customer_no}`.replace("^", "") : "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.customer_no = filter,
      }),
      headerClasses: () => `column-sticky ${this.state.params.customer_no ? "bg-green" : ""}`,
      sort: true,
      formatter: this.formatCustomerNumber.bind(this),
      visible: true,
      alwaysVisible: true,
    }, {
      text: "Příjmení",
      dataField: "surname",
      classes: "column-sticky column-sticky_1",
      filter: textFilter({
        placeholder: "Příjmení",
        defaultValue: this.state.params.surname || "",
        className: "border-primary border-2",
        getFilter: filter => this.filters.surname = filter,
      }),
      headerClasses: () => `column-sticky column-sticky_1 ${this.state.params.surname ? "bg-green" : ""}`,
      sort: true,
      visible: true,
      formatter: this.formatSurname.bind(this),
      style: (cell, row, rowIndex, colIndex) => {
        return {
          // Maximum number of rows is 50. So maximum z-index is 100 and minimum is 50
          // There is plenty of space to prevent customer surname overlaps other notes
          zIndex: 100 - rowIndex
        }
      },
    }, {
      text: "Jméno",
      dataField: "name",
      filter: textFilter({
        placeholder: "Jméno",
        defaultValue: this.state.params.name || "",
        getFilter: filter => this.filters.name = filter,
      }),
      headerClasses: () => this.state.params.name ? "bg-green" : "",
      formatter: this.formatName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Kontakt",
      dataField: "contact__value",
      filter: textFilter({
        placeholder: "Kontakt",
        defaultValue: this.state.params.contact__value || "",
        getFilter: filter => this.filters.contact__value = filter,
      }),
      headerClasses: () => this.state.params.contact__value ? "bg-green" : "",
      formatter: this.formatContact.bind(this),
      visible: true,
    }, {
      text: "Město",
      dataField: "billing_address_city",
      filter: textFilter({
        placeholder: "Město",
        defaultValue: this.state.params.billing_address_city || "",
        getFilter: filter => this.filters.billing_address_city = filter,
      }),
      headerClasses: () => this.state.params.billing_address_city ? "bg-green" : "",
      sort: true,
      visible: true,
    }, {
      text: "Ulice",
      dataField: "billing_address_street",
      filter: textFilter({
        placeholder: "Ulice",
        defaultValue: this.state.params.billing_address_street || "",
        getFilter: filter => this.filters.billing_address_street = filter,
      }),
      headerClasses: () => this.state.params.billing_address_street ? "bg-green" : "",
      formatter: this.formatBillingAddressStreet.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "Jméno sponzora",
      dataField: "sponsor__name",
      formatter: this.formatSponsorName.bind(this),
      sort: true,
      visible: true,
    }, {
      text: "D. založení",
      dataField: "created_at",
      headerClasses: () => this.state.params.created_at ? "bg-green" : "",
      formatter: this.formatCreatedAt.bind(this),
      sort: true,
      visible: true,
      filter: customFilter({
        type: "DATE",
      }),
      filterRenderer: (onFilter, column) => {
        this.filters.created_at = () => onFilter("")
        return <GridDateFilter
          onFilter={onFilter}
          column={column}
          placeholder={"D. založení"}
          defaultValue={this.state.params.created_at || ""}
        />
      },
    }, {
      text: "Nejvyšší dosažený bonus",
      dataField: "bonus_top",
      formatter: this.formatBonusTop.bind(this),
      visible: true,
      sort: true,
    }, {
      text: "D. poslední objednávky",
      dataField: "payment",
      formatter: this.formatPayment.bind(this),
      visible: true,
    },]
  }

  getAuthenticatedCustomer() {
    API.get(`/my/customer/${RBAC.getUser().customer_id}`)
      .then(({data}) => {
        this.setState({
          _authenticatedCustomer: data,
        })
      })
  }

  isDirectChild(customer) {
    return this.state._authenticatedCustomer.id === customer.sponsor_id
  }

  handleSponsorChange() {
    this.setState({
      params: Object.assign({}, this.state.params, {
        sponsor_id: this.state.params.sponsor_id ? "" : this.state._authenticatedCustomer.id
      })
    }, this.getData)
  }
}
