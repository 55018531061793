import React, {Component} from "react"
import moment from "moment"
import API from "../API"

export default class Note extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: ""
    }
  }

  componentDidMount() {
    if (this.props.note.user_id) {
      this.getUser()
    }
  }

  render() {
    return (
      <tr
        className={this.props.note.is_warn ? "text-danger" : ""}
      >
        <td>
          {this.props.note.is_warn && (
            <i
              className={"fas fa-exclamation-triangle"}
            />
          )}

          {this.props.note.is_pub && (
            <i
              className={"fas fa-user text-warning"}
            />
          )}

          {this.props.note.hasOwnProperty("is_extended") && this.props.note.is_extended && (
            <i
              className={"fab fa-product-hunt"}
            />
          )}

          {this.props.note.hasOwnProperty("is_extended") && !this.props.note.is_extended && (
            <i
              className={"fas fa-info-circle"}
            />
          )}

          {!this.props.note.is_warn && !this.props.note.is_pub && !this.props.note.hasOwnProperty("is_extended") && (
            <i
              className={"fas fa-exclamation"}
            />
          )}
        </td>
        <td>{this.props.note.created_at ? moment(this.props.note.created_at).format("DD. MM. YYYY") : ""}</td>
        <td>{this.props.note.value || this.props.note.note}</td>
        <td>{this.state.user}</td>
        {this.props.deleteHandler && (
          <td
            className={"text-right"}
          >
            <button
              className={"btn btn-danger btn-sm"}
              type={"button"}
              onClick={() => this.props.deleteHandler(this.props.index)}
            >&times;</button>
          </td>
        )}
      </tr>
    )
  }

  getUser() {
    API.get(`/users/${this.props.note.user_id}`)
      .then(res => {
        this.setState({
          user: `${res.data.name} ${res.data.surname}`
        })
      })
  }
}
