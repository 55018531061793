import React, {Component, Fragment} from "react"
import AsyncSelect from "react-select/async"
import CreatableSelect from "react-select/creatable"
import AsyncCreatableSelect from "react-select/async-creatable"
import moment from "moment"
import API from "../API"
import ReactSelectTableOption from "../Base/ReactSelectTableOption"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import Note from "../Base/Note"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"
import PAYMENT_METHOD from "../Base/Enum/PaymentMethod"
import Collapsible from "../Sales/Collapsible"
import styles from "../Sales/style.module.css"
import {loadOptionsDebounced} from "../Base/loadOptionsDebounced"
import seq from "../Base/seq"
import isPromotionDiscountGroup from "../Base/isPromotionDiscountGroup"
import formatCustomerName from "../Base/formatCustomerName"
import getProductNameByCountry from "../Base/getProductNameByCountry"
import MyProductCatalogue from "./MyProductCatalogue/MyProductCatalogue"

export default class MyOrderForm extends Component {
  constructor(props) {
    super(props)
    this.itselfRef = React.createRef()
    this.searchProductInputRef = React.createRef()
    this.searchCustomerInputRef = React.createRef()
    this.productTableRef = React.createRef()
    this.bonusPointsLimits = [500, 1500, 3500, 8000, 14000, 20000, 25000]
    this.productQuantityOptions = [...Array(10).keys()].map(key => ({
      label: key + 1,
      value: key + 1,
    }))
  }

  componentDidMount() {
    // selected order by order id
    if (this.props.orderId && this.props.orderId === this.props.order.id) {
      this.scrollToItselfTop()
    }

    // newly added order
    if (this.props.order._focus) {
      this.scrollToItselfTop()
      this.searchCustomerInputRef.current.focus()
    }

    // promo products
    if (this.props.isDetail) {
      this.updatePromoProductsData()
    }
  }

  render() {
    let orderQuantity = 0
    let orderPrice = 0
    let orderPoints = 0

    return (
      <div
        ref={this.itselfRef}
        className={`col-12 ${this.props.orderId && this.props.orderId === this.props.order.id ? "bg-light-success" : ""}`}
      >
        <div
          className={"row"}
        >
          {this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
          this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ &&
          this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP && (
            <div
              className={"col-12"}
            >
              <div
                className={"bg-light border-top-15 mt-4 mb-2 p-2"}
              >
                <div
                  className={"row"}
                >
                  <div
                    className={"col-lg-6"}
                  >
                    <strong
                      className={"form-group d-block m-0"}
                    >
                    <span>
                      {this.props.order.invoice_no ? `${this.props.order.invoice_no} - ` : ""}
                      {seq(this.props.seqNo)} - Objednávka pro {this.props.order.customer.name || ""} {this.props.order.customer.surname || ""}
                    </span>

                      <span
                        className={"float-right"}
                      >{this.props.order.paid_date ? `Uhrazeno ${moment(this.props.order.paid_date).format("DD. MM. YYYY")}` : ""}</span>
                    </strong>
                  </div>
                  <div
                    className={"col-lg-6"}
                  >
                    <input
                      type={"checkbox"}
                      className={"form-check-input ml-0"}
                      id={`is_simple_invoice-${this.props.order.id}`}
                      onChange={this.handleIsSimpleInvoiceChange.bind(this)}
                      checked={this.props.order.is_simple_invoice || false}
                    />
                    <label
                      htmlFor={`is_simple_invoice-${this.props.order.id}`}
                      className={"ml-4 mt-1 font-weight-bold"}
                    >Objednávka pro neregistrovaného za zákaznické ceny</label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={"col-lg-6"}
          >
            <div
              className={"form-row"}
            >
              {this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP && (
                <div
                  className={"form-group col-lg-2 col-3"}
                >
                  <label>Typ zákazníka:</label>
                  <input
                    className={"form-control"}
                    type={"text"}
                    disabled={true}
                    value={this.props.getCustomerTypeNameHandler(this.props.order.customer)}
                  />
                </div>
              )}

              {this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP && (
                <div
                  className={`form-group col-lg-10 col-9 select-required ${this.props.isSaleFirstOrder && this.props.salePaymentMethodId === 5 ? "select-disabled" : ""}`}
                >
                  <label>Zákazník:</label>
                  <AsyncCreatableSelect
                    className={"react-select_border"}
                    value={this.props.order.customer || {}}
                    loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
                    onChange={this.handleCustomerSelect.bind(this)}
                    noOptionsMessage={() => "Nic nenalezeno"}
                    loadingMessage={() => "Načítám"}
                    required={true}
                    components={{
                      Option: ReactSelectTableOption
                    }}
                    styles={ReactSelectCustomStyles}
                    ref={this.searchCustomerInputRef}
                    isDisabled={
                      this.props.authenticatedCustomerTypeId === CUSTOMER_TYPE.RD ||
                      this.props.authenticatedCustomerTypeId === CUSTOMER_TYPE.RZ ||
                      this.props.authenticatedCustomerTypeId === CUSTOMER_TYPE.RP
                    }
                    formatCreateLabel={value => `Vyhledat podle zákaznického čísla: ${value}`}
                  />
                </div>
              )}

              {this.props.order.customer.type_id !== CUSTOMER_TYPE.RD &&
              this.props.order.customer.type_id !== CUSTOMER_TYPE.RZ &&
              this.props.order.customer.type_id !== CUSTOMER_TYPE.RP &&
              this.props.order.customer.id === this.props.authenticatedCustomer.id && (
                <div
                  className={"col-12"}
                >
                  <table
                    className={"table mt-1"}
                  >
                    <thead>
                    <tr>
                      <th
                        className={"border-0 bg-light"}
                      >Věrnostní Kč:
                      </th>
                      <th
                        className={"border-0 bg-light"}
                      >Z minulých let:
                      </th>
                      <th
                        className={"border-0 bg-light"}
                      >Letos:
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td
                        className={"bg-grey border-0"}
                        colSpan={3}
                      >Věrnostní Vám sdělíme e-mailem nebo telefonicky.</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          <div
            className={"col-lg-6"}
          >
            <div
              className={"form-group"}
            >
              <label>Fakturační jméno:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleBillingAddressBillingNameChange.bind(this)}
                value={this.props.order.billing_address_billing_name || ""}
                disabled={true}
              />
            </div>
            <div
              className={"form-group"}
            >
              <label>Fakturační adresa - Ulice včetně čísla popisného, evidenčního nebo i
                orientačního:</label>
              <input
                type={"text"}
                className={"form-control"}
                required={!this.props.order.is_simple_invoice}
                onChange={this.handleBillingAddressStreetChange.bind(this)}
                value={this.props.order.billing_address_street || ""}
                disabled={true}
              />
            </div>

            <div
              className={"form-row"}
            >
              <div
                className={"form-group col-lg-8 col-6 select-required"}
              >
                <label>Město:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={!this.props.order.is_simple_invoice}
                  onChange={this.handleBillingAddressCityChange.bind(this)}
                  value={this.props.order.billing_address_city || ""}
                  disabled={true}
                />
              </div>

              <div
                className={"form-group col-lg-2 col-3 select-required"}
              >
                <label>PSČ:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  required={!this.props.order.is_simple_invoice}
                  onChange={this.handleBillingAddressPostCodeChange.bind(this)}
                  value={this.props.order.billing_address_post_code || ""}
                  disabled={true}
                />
              </div>

              <div
                className={"form-group col-lg-2 col-3"}
              >
                <label>Kód země:</label>
                <select
                  className={"form-control"}
                  required={!this.props.order.is_simple_invoice}
                  onChange={this.handleBillingAddressCountryCodeChange.bind(this)}
                  value={this.props.order.billing_address_country_code}
                  disabled={true}
                >
                  {this.props.countries.map(country => (
                    <option
                      key={country.id}
                      value={country.code}
                    >{country.code}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div
            className={"col-12"}
          >
            <hr/>
          </div>

          <fieldset
            className={"col-12"}
            disabled={this.isProductDisabled()}
          >
            <div
              className={"form-group"}
            >
              <label>Produkty:</label>
              <table
                className={"table table-striped table-bordered font-weight-bold"}
                ref={this.productTableRef}
              >
                <thead>
                <tr
                  className={"bg-light"}
                >
                  <th
                    className={"rotate-90"}
                  >
                    <div>Č. výr.</div>
                  </th>
                  <th
                    className={"rotate-90"}
                  >
                    <div>Název výrobku</div>
                  </th>
                  <th
                    className={"rotate-90"}
                  >
                    <div>Počet ks</div>
                  </th>
                  <th
                    className={"rotate-90"}
                  >
                    <div>cena/MJ</div>
                  </th>
                  <th
                    className={"rotate-90"}
                  >
                    <div>cena/MJ s DPH</div>
                  </th>
                  {this.isPointsVisible() && (
                    <th
                      className={"rotate-90"}
                    >
                      <div>Body &times; ks</div>
                    </th>
                  )}
                  <th
                    className={"rotate-90"}
                  >
                    <div>Celková cena s DPH</div>
                  </th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {this.props.order.order_product.map((orderProduct, i) => {
                  let price = this.getProductPrice(orderProduct)
                  orderPrice += price * orderProduct.quantity
                  orderQuantity += orderProduct.quantity
                  if (
                    !orderProduct.is_advert &&
                    orderProduct.price !== 0
                  ) {
                    orderPoints += orderProduct.product.points * orderProduct.quantity
                  }
                  return (
                    <Fragment
                      key={this.isPromoProduct(orderProduct) ? `promo-${orderProduct.product_id}` : orderProduct.product_id}
                    >
                      <tr
                        className={"px-2 py-1"}
                      >
                        {/* number */}
                        <td>
                          {orderProduct.product.code}
                          {this.props.order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY && this.hasDiscount(orderProduct) && (
                            <i
                              className={"fas fa-piggy-bank ml-1 text-danger"}
                              title={"Produkt ve slevě"}
                            />
                          )}
                        </td>
                        {/* name */}
                        <td>
                          {getProductNameByCountry(orderProduct.product, this.props.countryId, null)}
                        </td>
                        {/* quantity */}
                        <td>
                          <div
                            className={"d-inline-block w-75"}
                          >
                            <CreatableSelect
                              value={{
                                label: orderProduct.quantity,
                                value: orderProduct.quantity,
                              }}
                              options={this.productQuantityOptions}
                              autoFocus={orderProduct._focus}
                              defaultMenuIsOpen={orderProduct._focus}
                              onChange={option => this.handleProductQuantityChange(option, i)}
                              formatCreateLabel={value => value}
                              isDisabled={!this.props.isSaveable || this.isPromoProduct(orderProduct) || this.isProductDisabled()}
                            />
                          </div>
                        </td>
                        {/* price without VAT */}
                        <td>
                          {(price / (1 + (orderProduct.product.vat_rate / 100))).toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                          &nbsp;
                          {this.props.countryCurrencyInfo.currency}
                        </td>
                        {/* price with VAT */}
                        <td>
                          {price.toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                          &nbsp;
                          {this.props.countryCurrencyInfo.currency}
                        </td>
                        {/* points */}
                        {this.isPointsVisible() && (
                          <td>
                            {(
                              orderProduct.is_advert ||
                              this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY ||
                              price === 0
                            ) ? 0
                              : orderProduct.product.points * orderProduct.quantity
                            }
                          </td>
                        )}
                        {/* total price */}
                        <td>
                          {(price * orderProduct.quantity).toFixed(this.props.countryCurrencyInfo.round_decimal_places)}
                          &nbsp;
                          {this.props.countryCurrencyInfo.currency}
                        </td>
                        {/* remove */}
                        <td>
                          {(!orderProduct.hasOwnProperty("is_removable") ||
                            (orderProduct.hasOwnProperty("is_removable") && orderProduct.is_removable)
                          ) && (
                            <button
                              className={"btn text-danger p-1 btn-text-lg"}
                              onClick={() => this.handleRemoveProduct(i)}
                              title={"Odstranit produkt"}
                              type={"button"}
                              disabled={this.isProductDisabled()}
                            >
                              <i
                                className={"far fa-trash-alt fa-xs"}
                              />
                            </button>
                          )}
                        </td>
                      </tr>
                      {!this.isPromoProduct(orderProduct) &&
                      this.props.order.payment_method_id !== PAYMENT_METHOD.BONUS_MONEY &&
                      this.props.order._promoProducts.some(promoProduct => promoProduct._promo_product_id === orderProduct.product_id) && (
                        <tr>
                          <td
                            colSpan={9}
                          >
                            <label>
                              <input
                                type={"radio"}
                                name={`promo-${this.props.order.id}-${orderProduct.product_id}`}
                                value={"nonsense"}
                                className={"mr-1"}
                                checked={!this.isPromoProductRejected(orderProduct.product.discount_group)}
                                onChange={() => this.setPromoProductRejection(orderProduct.product.discount_group, false)}
                              />
                              {this.getPromoTitle(orderProduct.product_id)}
                              {this.getPromoNames(orderProduct.product_id, this.props.countryId)}
                            </label>
                            <label>
                              <input
                                type={"radio"}
                                name={`promo-${this.props.order.id}-${orderProduct.product_id}`}
                                value={"nonsense"}
                                className={"mr-1"}
                                checked={this.isPromoProductRejected(orderProduct.product.discount_group)}
                                onChange={() => this.setPromoProductRejection(orderProduct.product.discount_group, true)}
                              />
                              Nechci akci
                            </label>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })}
                <tr>
                  {/* add new product */}
                  <td
                    colSpan={8}
                  >
                    <div
                      className={this.props.order.order_product.size === 0 ? "select-required" : ""}
                    >
                      <AsyncSelect
                        value={{}}
                        className={"react-select_border product-search-input"}
                        loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleProductSearchChange.bind(this))}
                        onChange={this.handleProductSearchSelect.bind(this)}
                        noOptionsMessage={() => "Hledanému výrazu neodpovídá žádný produkt"}
                        loadingMessage={() => "Načítám"}
                        required={true}
                        styles={ReactSelectCustomStyles}
                        components={{
                          Option: ReactSelectTableOption
                        }}
                        ref={this.searchProductInputRef}
                        isDisabled={this.isProductDisabled()}
                      />

                      <button
                        type={"button"}
                        className={"btn btn-primary ml-3 align-middle"}
                        onClick={this.handleCatalogueOpenClick.bind(this)}
                      >Katalog
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot
                  className={styles.productTableFooter}
                >
                <tr>
                  <td/>
                  <td/>
                  <td
                    className={"pl-5"}
                  >{orderQuantity}</td>
                  <td/>
                  <td/>
                  {this.isPointsVisible() && <td>{orderPoints}</td>}
                  <td>{orderPrice.toFixed(this.props.countryCurrencyInfo.round_decimal_places)} {this.props.countryCurrencyInfo.currency}</td>
                  <td/>
                </tr>
                </tfoot>
              </table>
            </div>
          </fieldset>

          <div
            className={"col-12"}
          >
            <div
              className={"form-group text-right"}
            >
              {this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RD &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RZ &&
              this.props.authenticatedCustomerTypeId !== CUSTOMER_TYPE.RP && (
                <button
                  className={`btn btn-primary ${!this.props.isSingle && "mr-3"}`}
                  onClick={this.props.addOrderHandler}
                  disabled={!this.props.order.customer_id || this.props.order.order_product.size === 0}
                >Přidat objednávku pro dalšího zákazníka</button>
              )}
              {!this.props.isSingle && (
                <button
                  className={"btn btn-danger"}
                  type={"button"}
                  onClick={this.handleDeleteOrder.bind(this)}
                >Smazat objednávku</button>
              )}
            </div>
          </div>
        </div>
        <div
          className={"row"}
        >
          <div
            className={"col-12 pb-3"}
          >
            {this.props.order.customer.type_id !== CUSTOMER_TYPE.RD &&
            this.props.order.customer.type_id !== CUSTOMER_TYPE.RZ &&
            this.props.order.customer.type_id !== CUSTOMER_TYPE.RP && (
              this.props.order.customer.id === this.props.authenticatedCustomer.id ||
              this.props.order.customer.sponsor_id === this.props.authenticatedCustomer.id
            ) && (
              <Collapsible
                open={true}
                name={"Zákaznické body"}
              >
                <table
                  className={"table mb-0"}
                >
                  <thead>
                  <tr
                    className={"bg-light"}
                  >
                    <td>Vlastní body</td>
                    <td>Celkové body</td>
                    <td>Procento bonusu</td>
                    <td>Chybějící body do vyššího bonusu</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{this.getPointsStatus("points")}</td>
                    <td>{this.getPointsStatus("total_points")}</td>
                    <td>{this.getPointsStatus("bonus")}</td>
                    <td>{this.getPointsStatus("missing_points_to_next_bonus")}</td>
                  </tr>
                  </tbody>
                </table>
              </Collapsible>
            )}
            <Collapsible
              name={"Poznámky"}
              open={true}
              boldTitle={true}
            >
              <div
                className={"form-group row"}
              >
                <label
                  className={"col-lg-2 col-form-label font-weight-bold"}
                >Napiš poznámku:</label>
                <div
                  className={"col-lg-10"}
                >
                  <input
                    type={"text"}
                    className={"form-control"}
                    onChange={this.handleNoteChange.bind(this)}
                    value={this.props.order._note || ""}
                  />
                </div>

                <div
                  className={"col-12 text-right mt-2"}
                >
                  <button
                    className={"btn btn-warning"}
                    type={"button"}
                    disabled={!this.props.order.customer_id}
                    onClick={this.handleAddPublicNote.bind(this)}
                  >Přidat poznámku k objednávce
                  </button>
                </div>
              </div>
              <div
                className={"border p-3 my-2"}
              >
                {!this.someNotes() && "Žádné poznámky"}
                <table
                  className={"table mb-0"}
                >
                  <tbody>
                  {this.props.order.note && this.props.order.note
                    .filter(note => note.is_pub)
                    .map((note, i) => (
                      <Note
                        key={note.id || i}
                        id={note.id || i}
                        index={i}
                        note={note}
                        deleteHandler={this.handleDeleteOrderNote.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </Collapsible>
          </div>
        </div>

        <MyProductCatalogue
          isOpen={this.props.order._isCatalogueOpen}
          productTree={this.props.productTree}
          productCategories={this.props.productCategories}
          orderId={this.props.order.id}
          closeHandler={this.handleCatalogueCloseClick.bind(this)}
          selectProductHandler={this.handleCatalogueProductSelect.bind(this)}
        />
      </div>
    )
  }

  scrollToItselfTop() {
    window.scrollTo(0, this.itselfRef.current.offsetTop)
  }

  focusSearchProductInput() {
    this.searchProductInputRef.current.focus()
    this.productTableRef.current.scrollIntoView({
      block: "center"
    })
  }

  handleCustomerChange(val, cb) {
    API.get(`/my/customers?limit=20&offset=0&search=${val}&type_id=!${CUSTOMER_TYPE.REMOVED}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: `${formatCustomerName(item, "|")}|${item.billing_address_city || ""}`,
        }))
        cb(data.objects)
      })
  }

  handleCustomerSelect(option) {
    if (option.__isNew__) {
      API.get(`/customers?customer_no==${option.value}&limit=10`)
        .then(({data}) => {
          if (data.objects.length === 1) {
            let customer = Object.assign({}, data.objects[0], {
              value: data.objects[0].id,
              label: formatCustomerName(data.objects[0]),
            })
            this._handleCustomerSelect(customer)
          } else {
            alert("Zákazník s tímto zákaznickým číslem neexistuje.")
          }
        })
    } else {
      let customer = Object.assign({}, option, {
        value: option.id,
        label: formatCustomerName(option),
      })
      this._handleCustomerSelect(customer)
    }
  }

  _handleCustomerSelect(option) {
    this.props.setOrderStateHandler(this.props.order.id, {
      customer: option,
      customer_id: option.id,
      billing_address_customer_name: option.billing_address_customer_name,
      billing_address_billing_name: option.billing_address_billing_name || option.billing_address_customer_name,
      billing_address_street: option.billing_address_street,
      billing_address_city: option.billing_address_city,
      billing_address_post_code: option.billing_address_post_code,
      billing_address_country_code: option.billing_address_country_code,
      delivery_method_id: this.props.order.delivery_method_id,
      payment_method_id: option.billing_address_country_code === "CZ" ? this.props.order.payment_method_id : 2, // bank transfer
    })
  }

  handleIsSimpleInvoiceChange(e) {
    let isChecked = e.target.checked
    this.props.setOrderStateHandler(this.props.order.id, {
      is_simple_invoice: isChecked,
      // simple invoice has always customers prices (retail prices)
      price_type: isChecked ? "customer" : "customer_type",
    })
  }

  handleNoteChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      _note: e.target.value,
    })
  }

  handleAddPublicNote() {
    if (this.props.order._note) {
      this.props.setOrderStateHandler(this.props.order.id, {
        note: this.props.order.note.push({
          value: this.props.order._note,
          is_warn: false,
          is_pub: true,
          created_at: moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
        }),
        _note: "",
      })
    }
  }

  handleDeleteOrderNote(i) {
    this.props.setOrderStateHandler(this.props.order.id, {
      note: this.props.order.note.delete(i)
    })
  }

  handleProductSearchChange(val, cb) {
    API.get(`/products?limit=0&offset=0&search=${val}&is_my_order_visible=1&sort=code`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: getProductNameByCountry(item, this.props.countryId),
        }))
        cb(data.objects)
      })
  }

  handleProductSearchSelect(option) {
    if (this.props.order.order_product.toArray().some(orderProduct => orderProduct.product.id === option.id)) {
      alert("Objednávka již obsahuje vybraný produkt.")
    } else {

      let product = Object.assign({}, {
        quantity: 1,
        product_id: option.id,
        product: option,
        is_advert: false,
        _focus: true,
      })

      this.props.setOrderStateHandler(this.props.order.id, {
        order_product: this.props.order.order_product.push(product),
      }, this.updatePromoProductsData.bind(this))
    }
  }

  handleCatalogueOpenClick() {
    this.props.setOrderStateHandler(this.props.order.id, {
      _isCatalogueOpen: true,
    })
  }

  handleCatalogueCloseClick() {
    this.props.setOrderStateHandler(this.props.order.id, {
      _isCatalogueOpen: false,
    })
  }

  handleCatalogueProductSelect(product) {
    let orderProductIndex = this.props.order.order_product.findIndex(orderProduct => orderProduct.product_id === product.id)

    if (orderProductIndex > -1) { // product already exists in order -> update quantity
      let orderProduct = this.props.order.order_product.get(orderProductIndex)
      orderProduct.quantity++

      this.props.setOrderStateHandler(this.props.order.id, {
        order_product: this.props.order.order_product.update(orderProductIndex, () => orderProduct),
        _isCatalogueOpen: false,
      }, this.updatePromoProductsData.bind(this))
    } else { // add new product to order
      // we can't use product passed as argument, because it is product from /productTree
      // endpoint and this product does not have discount groups...
      API.get(`/products/${product.id}`)
        .then(({data}) => {

          product = Object.assign({}, {
            quantity: 1,
            product_id: product.id,
            product: data,
            is_advert: false,
            _focus: true,
          })

          this.props.setOrderStateHandler(this.props.order.id, {
            order_product: this.props.order.order_product.push(product),
            _isCatalogueOpen: false,
          }, this.updatePromoProductsData.bind(this))
        })
    }
  }

  handleProductQuantityChange(option, productIndex) {
    let value = parseInt(option.value)

    if (
      isNaN(value) ||
      value < 0
    ) {
      return
    }

    let product = Object.assign({}, this.props.order.order_product.get(productIndex), {
      quantity: value
    })
    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: this.props.order.order_product.update(productIndex, () => product),
    }, this.updatePromoProductsData.bind(this))

    this.focusSearchProductInput()
  }

  handleRemoveProduct(productIndex) {
    this.props.setOrderStateHandler(this.props.order.id, {
      order_product: this.props.order.order_product.delete(productIndex),
      _promoProducts: [],
    }, this.updatePromoProductsData.bind(this))
  }

  handleBillingAddressBillingNameChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_billing_name: e.target.value
    })
  }

  handleBillingAddressStreetChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_street: e.target.value
    })
  }

  handleBillingAddressCityChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_city: e.target.value
    })
  }

  handleBillingAddressPostCodeChange(e) {
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_post_code: e.target.value
    })
  }

  handleBillingAddressCountryCodeChange(e) {
    let countryCode = e.target.value
    this.props.setOrderStateHandler(this.props.order.id, {
      billing_address_country_code: countryCode,
      payment_method_id: countryCode === "CZ" ? this.props.order.payment_method_id : 2, // bank transfer
    })
  }

  handleDeleteOrder() {
    if (window.confirm("Opravdu chcete smazat objednávku? Její obsah bude ztracen.")) {
      this.props.deleteOrderHandler(this.props.order.id)
    }
  }

  getPointsStatus(type) {
    if (
      !this.props.order.customer.points ||
      this.props.order.customer.points.length === 0
    ) {
      return 0
    }

    let currentDate = moment().format("YYYY-M")

    if (currentDate !== `${this.props.order.customer.points[0].year}-${this.props.order.customer.points[0].month}`) {
      return 0
    }

    if (type === "missing_points_to_next_bonus") {
      let totalPoints = this.props.order.customer.points[0].total_points
      let bonusPointsLimits = this.bonusPointsLimits.filter(points => points > totalPoints)

      if (bonusPointsLimits.length > 0) {
        return bonusPointsLimits[0] - totalPoints
      }

      return 0
    }

    return this.props.order.customer.points[0][type]
  }

  someNotes() {
    return this.props.order.note && this.props.order.note.size !== 0
  }

  getProductPrice(orderProduct, realValue = false) {
    if (orderProduct.is_advert) {
      return 0
    }

    // promo product
    if (this.isPromoProduct(orderProduct)) {
      return 0
    }

    if (
      this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY &&
      !realValue
    ) {
      return 0
    }

    let price = 0

    // base price
    if (this.props.order.customer.discount) {
      price = orderProduct.product.prices["customer_with_discount"]
    } else if (this.props.order.price_type === "customer_type") {
      if (this.props.order.customer.type_id === CUSTOMER_TYPE.RD) {
        price = orderProduct.product.prices["customer"]
      } else {
        price = orderProduct.product.prices["consultant"]
      }
    } else {
      price = orderProduct.product.prices[this.props.order.price_type || "consultant"]
    }

    price = parseInt(price, 10)

    if (this.props.hasPricesWithoutVAT) {
      price = price / (1 + (orderProduct.product.vat_rate / 100))
    }

    // discounts (not a promo products)
    orderProduct.product.discount_group.forEach(discountGroup => {
      if (
        !isPromotionDiscountGroup(discountGroup.product_discount) &&
        this.orderContainsAllDiscountGroupProductsForDiscount(discountGroup) &&
        moment().isBetween(discountGroup.valid_from, discountGroup.valid_to)
      ) {
        discountGroup.product_discount
          .filter(productDiscount => productDiscount.product_id === orderProduct.product_id)
          .forEach(productDiscount => {
            // % discount
            if (productDiscount.type === 1) {
              price = Math.ceil(price - (price * productDiscount.value / 100))
            }

            // fixed value discount
            if (productDiscount.type === 2) {
              price = price - productDiscount.value
            }

            // fixed price
            if (productDiscount.type === 3) {
              price = productDiscount.value
            }
          })
      }
    })

    return parseFloat((price * this.props.countryCurrencyInfo.exchange_rate).toFixed(2))
  }

  // check if order contains all required discount group products
  // works only for discounts
  orderContainsAllDiscountGroupProductsForDiscount(discountGroup) {
    let orderContainsAllDiscountGroupProducts = true

    discountGroup.product_discount.forEach(productDiscount => {
      if (
        !this.props.order.order_product
          .toArray()
          .some(orderProduct => orderProduct.product_id === productDiscount.product_id)
      ) {
        orderContainsAllDiscountGroupProducts = false
      }
    })

    return orderContainsAllDiscountGroupProducts
  }

  // check if order contains all required discount group products
  // works only for promotions
  orderContainsAllDiscountGroupProductsForPromotion(discountGroup) {
    let orderContainsAllDiscountGroupProducts = true

    discountGroup.product_discount
      .filter(productDiscount => productDiscount.value === 0)
      .forEach(productDiscount => {
        // get number of paid products. eg. for 2 same products and one is free
        // this wont affect action 1+1, etc...
        let requiredQuantity = discountGroup.product_discount
          .filter(pDiscount => pDiscount.value === 0)
          .filter(pDiscount => pDiscount.product_id === productDiscount.product_id).length

        let orderProduct = this.props.order.order_product
          .toArray()
          .find(orderProduct => orderProduct.product_id === productDiscount.product_id)

        if (
          !orderProduct ||
          orderProduct.quantity < requiredQuantity
        ) {
          orderContainsAllDiscountGroupProducts = false
        }
      })

    return orderContainsAllDiscountGroupProducts
  }

  hasDiscount(orderProduct) {
    if (orderProduct.product.discount_group.length === 0) {
      return false
    }

    return !orderProduct.product.discount_group
      .some(discountGroup => isPromotionDiscountGroup(discountGroup.product_discount))
  }

  updatePromoProductsData() {
    let discountGroupIds = []
    let promoProductsIds = []

    this.props.order.order_product.forEach(orderProduct => {
      if (orderProduct.product.discount_group.length > 0) {
        let promoDiscountGroups = orderProduct.product.discount_group
          .filter(discountGroup => isPromotionDiscountGroup(discountGroup.product_discount))

        if (promoDiscountGroups.length > 0) {
          promoDiscountGroups.forEach(discountGroup => {
            if (this.orderContainsAllDiscountGroupProductsForPromotion(discountGroup)) {
              let productIds = discountGroup.product_discount
                .filter(productDiscount => productDiscount.value === 100)
                .map(productDiscount => productDiscount.product_id)

              if (discountGroupIds.indexOf(discountGroup.id) === -1) {
                promoProductsIds.push({
                  paidProductId: orderProduct.product.id,
                  freeProductIds: productIds,
                })
                discountGroupIds.push(discountGroup.id)
              }
            }
          })
        }
      }
    })

    this.getPromoProductsData(promoProductsIds)
  }

  getPromoProductsData(promoProductsIds) {
    let allFreeProductIds = promoProductsIds
      .map(item => item.freeProductIds)
      .join("|")
    let promoProducts = []

    if (allFreeProductIds !== "") {
      API.get(`/products?id==${allFreeProductIds}`)
        .then(({data}) => {
          promoProductsIds.forEach(promoProductsId => {
            let paidProductId = promoProductsId.paidProductId
            let freeProductIds = promoProductsId.freeProductIds.join(",")
            let freeProducts = data.objects.filter(product => freeProductIds.indexOf(product.id) > -1)

            freeProducts.map(freeProduct => {
              let item = Object.assign({}, freeProduct, {
                _promo_product_id: paidProductId
              })

              promoProducts.push(item)
            })
          })

          this.props.setOrderStateHandler(this.props.order.id, {
            _promoProducts: promoProducts,
          })
        })
    }
  }

  getPromoTitle(productId) {
    let title = ""
    let promoProduct = this.props.order._promoProducts.find(promoProduct => promoProduct._promo_product_id === productId)

    promoProduct.discount_group.forEach(discountGroup => {
      let paidProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 0)
      let freeProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 100)
      title += `AKCE ${paidProducts.length}+${freeProducts.length} - `
    })

    return title
  }

  getPromoNames(productId, countryId) {
    return this.props.order._promoProducts
      .filter(promoProduct => promoProduct._promo_product_id === productId)
      .map(promoProduct => `${getProductNameByCountry(promoProduct, countryId)} ${this.getPromoQuantity(productId, promoProduct)}× zdarma`)
      .join(", ")
  }

  getPromoQuantity(productId, promoProduct) {
    let minQuantity = Infinity

    promoProduct.discount_group
      // if there is multiple promo actions for one product (free product), we need only discount groups which contain paid product
      .filter(discountGroup => discountGroup.product_discount.some(productDiscount => productDiscount.product_id === productId))
      .forEach(discountGroup => {
        let paidProducts = discountGroup.product_discount.filter(productDiscount => productDiscount.value === 0)

        paidProducts.forEach(paidProduct => {
          let orderProduct = this.props.order.order_product.find(orderProduct => orderProduct.product_id === paidProduct.product_id)
          // there can be promos like 2+1 (2 same products and 1 free). so get number of paid product quantity
          let paidProductRequiredQuantity = paidProducts.filter(fullPriceProduct => fullPriceProduct.product_id === paidProduct.product_id).length

          if (orderProduct) {
            let quantity = Math.floor(orderProduct.quantity / paidProductRequiredQuantity)

            if (quantity < minQuantity) {
              minQuantity = quantity
            }
          }
        })
      })

    return minQuantity
  }

  isPromoProductRejected(discountGroups) {
    if (discountGroups.length > 0) {
      let orderProductDiscountGroup = this.props.order.order_product_discount_group.find(orderProductDiscountGroup => {
        return orderProductDiscountGroup.product_discount_group_id === discountGroups[0].id
      })

      if (
        orderProductDiscountGroup &&
        orderProductDiscountGroup.is_rejected
      ) {
        return true
      }
    }

    return false
  }

  setPromoProductRejection(discountGroups, isRejected) {
    if (discountGroups.length > 0) {
      let discountGroupId = discountGroups[0].id
      let found = false
      let orderProductDiscountGroups = this.props.order.order_product_discount_group.map(orderProductDiscountGroup => {
        if (orderProductDiscountGroup.product_discount_group_id === discountGroupId) {
          orderProductDiscountGroup.is_rejected = isRejected
          found = true
        }

        return orderProductDiscountGroup
      })

      if (!found) {
        orderProductDiscountGroups.push({
          product_discount_group_id: discountGroupId,
          is_rejected: isRejected,
        })
      }
      this.props.setOrderStateHandler(this.props.order.id, {
        order_product_discount_group: orderProductDiscountGroups,
      })
    }
  }

  isProductDisabled() {
    if (
      this.props.isDetail &&
      this.props.isPromo4Plus1Active &&
      this.props.saleStatus !== "created_open"
      // this.props.order.order_product.reduce((acc, orderProduct) => {
      //   return acc + orderProduct.quantity
      // }, 0) >= 5
    ) {
      return true
    }

    return false
  }

  // Bonus money is the money you can use to pay the order
  //
  // It consist of 3 numbers. Total money, previous years money and current year money.
  // It is fact, that:
  //
  //   total = previous_years + current_year
  //
  // In case order is pay by bonus money, first subtract order price from previous years
  //   and then from current year.
  getBonusMoneyState(type) {
    if (
      this.props.order.customer.account &&
      this.props.order.customer.account.length > 0
    ) {
      let orderPrice = this.props.isDetail
        ? 0
        : this.props.order.order_product.reduce((sum, orderProduct) => {
          return sum + (parseFloat(this.getProductPrice(orderProduct, true)) * orderProduct.quantity)
        }, 0)

      let {
        total_in_year,
        total_out_year,
        total_prev_years,
      } = this.props.order.customer.account[0]

      if (type === "total") {
        let leftover = total_in_year - total_out_year + total_prev_years

        if (this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY) {
          return (leftover - orderPrice).toFixed(2)
        }

        return leftover.toFixed(2)
      }

      if (type === "previous_years") {
        let leftover = total_prev_years - total_out_year

        if (this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY) {
          if (
            leftover < 0 ||
            orderPrice > leftover
          ) {
            return (0).toFixed(2)
          }

          return (leftover - orderPrice).toFixed(2)
        }

        return (leftover < 0 ? 0 : leftover).toFixed(2)
      }

      if (type === "current_year") {
        let prevYearsLeftover = total_prev_years - total_out_year
        let leftover = total_in_year + (prevYearsLeftover <= 0 ? prevYearsLeftover : 0)

        if (
          this.props.order.payment_method_id === PAYMENT_METHOD.BONUS_MONEY &&
          prevYearsLeftover - orderPrice <= 0
        ) {
          return (leftover - orderPrice + (prevYearsLeftover <= 0 ? 0 : prevYearsLeftover)).toFixed(2)
        }

        return leftover.toFixed(2)
      }
    }

    return 0
  }

  isPointsVisible() {
    return this.props.authenticatedCustomer.type_id !== CUSTOMER_TYPE.RD
  }

  isPromoProduct(orderProduct) {
    return orderProduct.hasOwnProperty("discount") &&
      orderProduct.hasOwnProperty("price") &&
      orderProduct.discount === orderProduct.price
  }
}
