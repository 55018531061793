import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import Select from "react-select"
import API from "../../API"
import ReactSelectTreeOption from "../../Base/ReactSelectTreeOption"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import {cleanState} from "../../Utils"

export default class ProductCategoriesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entity: "product",
      name: "",
      parents: [],
      _categories: [],
      _categoryId: null,
      _categoryOption: {},
    }
  }

  componentDidMount() {
    this.getCategories()

    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getProductCategory()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={"/enums/categories/add"}
            className={"btn btn-primary mr-3"}
          >Přidat kategorii</NavLink>

          {this.id && (
            <button
              className={"btn btn-danger mr-3 float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat kategorii</button>
          )}
        </div>
        <div
          className={"col-lg-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Název kategorie</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Název kategorie"}
                required={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state.name}
              />
            </div>

            <div
              className={"form-group select-required"}
            >
              <label>Nadřazená kategorie:</label>
              <Select
                value={this.state._categoryOption.id ? this.state._categoryOption : ""}
                onChange={this.handleCategorySelect.bind(this)}
                options={this.state._categories}
                isLoading={this.state._categories.length === 0}
                isDisabled={this.id && !this.state._categoryId}
                placeholder={"Nadřazená kategorie"}
                components={{
                  Option: innerProps => {
                    return <ReactSelectTreeOption
                      categories={this.state._categories}
                      {...innerProps}
                    />
                  }
                }}
                styles={ReactSelectCustomStyles}
              />
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateCategory()
    } else {
      this.addCategory()
    }
  }

  handleCancel() {
    this.props.history.push("/enums/categories")
  }

  handleDelete() {
    API.delete(`/categories/${this.id}`)
      .then(() => this.props.history.push("/enums/categories"))
  }

  getProductCategory() {
    API.get(`/categories/${this.id}`)
      .then(({data}) => {
        let {name, parents} = data
        let parentCategory = {}

        if (parents && parents.length > 0) {
          parents = parents.filter(parent => parent) // if parent exists
          if (parents && parents.length > 0) {
            parentCategory = parents[parents.length - 1]
          }
        }

        this.setState({
          name: name,
          _categoryId: parentCategory.id,
          _categoryOption: Object.assign(parentCategory, {
            label: parentCategory.name || "",
            value: parentCategory.id
          }),
        })
      })
  }

  getCategories() {
    API.get("/productTree?limit=10")
      .then(res => {
        let categories = []
        let getCategory = (node, depth) => {
          if (node.category) {
            categories.push({
              id: node.id,
              value: node.id, // react select
              name: node.category.name,
              label: node.category.name, // react select
              depth: depth,
            })
          }
          if (node.children) {
            node.children.forEach(child => {
              getCategory(child, depth + 1)
            })
          }
        }
        getCategory(res.data.objects[0], 0)
        this.setState({
          _categories: categories,
        })
      })
  }

  updateParentCategory(id) {
    API.post("/categories/branches", {
      categories: [{
        id: id,
        branches: [this.state._categoryId],
      }]
    }).then(() => this.props.history.push("/enums/categories"))
  }

  addCategory() {
    if (this.state._categoryId) {
      let data = cleanState(this.state)

      API.post("/categories", data)
        .then(res => this.updateParentCategory(res.data.id))
    }
  }

  updateCategory() {
    let data = cleanState(this.state)

    API.put(`/categories/${this.id}`, data)
      .then(() => this.updateParentCategory(this.id))
  }

  handleCategorySelect(option) {
    this.setState({
      _categoryOption: option,
      _categoryId: option.value,
    })
  }
}
