import React from "react"
import {NavLink, Route, Switch} from "react-router-dom"

const NavBarReportsCommissions = () => {
  return (
    <Switch>
      <Route
        path={"/reports/commissions"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              to={"/reports/commissions/commissions"}
              className={"nav-link"}
              activeClassName={"active"}
            >Provize</NavLink>
            <NavLink
              to={"/reports/commissions/rents"}
              className={"nav-link"}
              activeClassName={"active"}
            >Renty</NavLink>
            <NavLink
              to={"/reports/commissions/customer-points"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zákaznické body</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarReportsCommissions
