import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import {List} from "immutable"
import styles from "./styles.module.css"
import API from "../../API"
import SpinnerWithOverlay from "../SpinnerWithOverlay/SpinnerWithOverlay"
import formatCustomerName from "../formatCustomerName"

export default class HistoryTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: List(),
    }
    this.fetchedKeys = []
    this.ACTION = {
      UPDATE: "Aktualizováno",
      INSERT: "Vloženo",
      DELETE: "Smazáno",
      AUTHENTICATION: "Přihlášení",
    }
    this.formatters = [{
      key: "user_id",
      apiUrl: "/users",
      format: this.formatUser.bind(this),
    }, {
      key: "printed_user_id",
      apiUrl: "/users",
      format: this.formatUser.bind(this),
    }, {
      key: "created_user_id",
      apiUrl: "/users",
      format: this.formatUser.bind(this),
    }, {
      key: "open_by_user_id",
      apiUrl: "/users",
      format: this.formatUser.bind(this),
    }, {
      key: "payment_method_id",
      apiUrl: "/paymentMethods",
      format: this.formatEnumEntity.bind(this),
    }, {
      key: "order_method_id",
      apiUrl: "/orderMethods",
      format: this.formatEnumEntity.bind(this),
    }, {
      key: "delivery_method_id",
      apiUrl: "/deliveryMethods",
      format: this.formatEnumEntity.bind(this),
    }, {
      key: "type_id",
      apiUrl: "/customerType",
      format: this.formatEnumEntity.bind(this),
    }, {
      key: "country_id",
      apiUrl: "/countries",
      format: this.formatEnumEntity.bind(this),
    }, {
      key: "product_id",
      apiUrl: "/products",
      format: this.formatProduct.bind(this)
    }, {
      key: "package_type_id",
      apiUrl: "/packageTypes",
      format: this.formatPackageType.bind(this),
    }, {
      key: "customer_id",
      apiUrl: "/customers",
      format: this.formatCustomer.bind(this),
    }, {
      key: "sponsor_id",
      apiUrl: "/customers",
      format: this.formatCustomer.bind(this),
    }, {
      key: "guarantor_id",
      apiUrl: "/customers",
      format: this.formatCustomer.bind(this),
    }, {
      key: "is_advert",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_simple_invoice",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_free_delivery",
      format: this.formatBoolean.bind(this),
    }, {
      key: "gdpr",
      format: this.formatBoolean.bind(this),
    }, {
      key: "terms",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_guarantor",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_warn",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_pub",
      format: this.formatBoolean.bind(this),
    }, {
      key: "use_price_category",
      format: this.formatBoolean.bind(this),
    }, {
      key: "box",
      format: this.formatBoolean.bind(this),
    }, {
      key: "ecological",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_is_order_visible",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_my_order_visible",
      format: this.formatBoolean.bind(this),
    }, {
      key: "is_price_list_visible",
      format: this.formatBoolean.bind(this),
    },]
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <NavLink
            className={"btn btn-primary"}
            to={window.location.hash
              .replace("#", "")
              .replace("history", "detail")}
          >Zpět na {this.props.name}</NavLink>
        </div>
        <div
          className={"col-12"}
        >
          <table
            className={"table table-hover table-striped border-bottom mt-3"}
          >
            <thead
              className={"thead-light"}
            >
            <tr>
              <th>Datum a čas změny</th>
              <th>Uživatel</th>
              <th>Zákazník</th>
              <th>Akce</th>
              <th>Entita</th>
              <th>
                Přidáno <span className={`${styles.annotation} ${styles.changeAdded}`}/>
                Odebráno <span className={`${styles.annotation} ${styles.changeRemoved}`}/>
              </th>
            </tr>
            </thead>
            <tbody>
            {this.props.isLoading
              ? (
                <tr>
                  <td
                    colSpan={6}
                    className={"text-center p-2"}
                  >
                    <SpinnerWithOverlay/>
                  </td>
                </tr>
              ) : (
                this.props.data.length === 0
                  ? (
                    <tr>
                      <td
                        colSpan={6}
                        className={"text-center"}
                      >Žádné změny
                      </td>
                    </tr>
                  ) : (
                    this.props.data.map((row, i) => {
                      return (
                        <tr
                          key={i}
                        >
                          <td>{row.date}</td>
                          <td>{row.user ? row.user.name : ""} {row.user ? row.user.surname : ""}</td>
                          <td>{row.customer ? formatCustomerName(row.customer) : ""}</td>
                          <td>{this.ACTION[row.action]}</td>
                          <td>{this.props.entity[row.entity]}</td>
                          <td>{this.getChanges(row)}</td>
                        </tr>
                      )
                    })
                  )
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  getChanges(row) {
    let oldValues = row.old_values
    let newValues = row.new_values
    let oldValuesKeys = Object.keys(oldValues)
    let newValuesKeys = Object.keys(newValues)

    return (
      <>
        {oldValuesKeys
          .filter(key => newValuesKeys.indexOf(key) === -1) // filter only removed values
          .filter(key => this.props.entityPropName[key]) // show only i18n keys
          .map(key => (
            <div
              key={key}
            >
              {this.renderChangeProp(key)}:&nbsp;
              <span
                className={styles.changeRemoved}
              >{this.renderChangeValue(oldValues, key)}</span>
            </div>
          ))
        }

        {newValuesKeys
          .filter(key => oldValuesKeys.indexOf(key) === -1) // filter only added values
          .filter(key => this.props.entityPropName[key]) // show only i18n keys
          .map(key => (
            <div
              key={key}
            >
              {this.renderChangeProp(key)}:&nbsp;
              <span
                className={styles.changeAdded}
              >{this.renderChangeValue(newValues, key)}</span>
            </div>
          ))
        }

        {newValuesKeys
          .filter(key => oldValuesKeys.indexOf(key) > -1) // filter only changed values
          .filter(key => this.props.entityPropName[key]) // show only i18n keys
          .map(key => (
            <div
              key={key}
            >
              {this.renderChangeProp(key)}:&nbsp;
              <span
                className={styles.changeRemoved}
              >{this.renderChangeValue(oldValues, key)}</span>
              <span
                className={styles.changeAdded}
              >{this.renderChangeValue(newValues, key)}</span>
            </div>
          ))
        }
      </>
    )
  }

  renderChangeProp(value) {
    return this.props.entityPropName[value]
  }

  renderChangeValue(values, key) {
    let value = this.props.entityValueName[values[key]] || values[key]
    let valueFormatter = this.formatters.find(formatter => formatter.key === key)

    // fetch data, then format value
    if (valueFormatter && valueFormatter.apiUrl) {
      let fetchKey = `${key}-${value}`
      let item = this.state.data.find(item => item.fetchKey === fetchKey)

      if (item) {
        return valueFormatter.format(item)
      } else {
        if (value !== " " && this.fetchedKeys.indexOf(fetchKey) === -1) {
          this.fetchedKeys.push(fetchKey) // store key as fetched to prevent multiple API calls

          API.get(`${valueFormatter.apiUrl}/${value}`)
            .then(({data}) => {
              this.setState({
                data: this.state.data.push(Object.assign(data, {fetchKey}))
              })
            })
        }
      }
    }

    // only format value
    if (valueFormatter && !valueFormatter.apiUrl) {
      return valueFormatter.format(value)
    }

    // no value formatter exists for key, or fetching data...
    return value
  }

  formatUser(user) {
    return `${user.name || ""} ${user.surname || ""}`
  }

  formatPackageType(packageType) {
    return packageType.type
  }

  formatProduct(product) {
    return `${product.code} - ${product.names.find(name => name.country_id === 1).name || ""}`
  }

  formatCustomer(customer) {
    return formatCustomerName(customer)
  }

  formatEnumEntity(entity) {
    return entity.name
  }

  formatBoolean(isAdvert) {
    return isAdvert ? "Ano" : "Ne"
  }
}
