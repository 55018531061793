import React from "react"
import RBAC from "../RBAC/RBAC"
import {APP_VERSION} from "../const"
import styles from "./styles.module.css"

const Footer = () => (
  <div
    className={`${styles.footer} bg-light p-3 text-black-50 d-print-none`}
  >
    Missiva IS verze {APP_VERSION} ({RBAC.getUser().email})
    <span
      className={"d-inline-block float-lg-right"}
    >
      Kontakt na technickou podporu:
      &nbsp;
      <a
        href={"mailto:podpora@goodsailors.com"}
      >podpora@goodsailors.com</a> a tel.&nbsp;
      <a
        href={"tel:+420 778 404 741"}
      >+420 778 404 741</a>
    </span>
  </div>
)

export default Footer
