import React from "react"
import Spinner from "../Spinner/Spinner"

const SpinnerInline = () => (
  <Spinner
    styles={{
      width: 20,
      height: 20,
      marginLeft: 15,
      display: "inline-block",
      verticalAlign: "middle",
    }}
  />
)

export default SpinnerInline
