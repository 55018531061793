import React, {Component} from "react"
import ProductTreeNode from "./ProductTreeNode"

export default class ProductTree extends Component {
  render() {
    return (
      <table
        id={"table-price-list"}
        className={"table table-bordered table-striped w-100 table-price-list"}
      >
        <thead>
        <tr>
          <th/>

          <th
            className={"text-center"}
          >Obj. č.
          </th>

          <th>Název</th>

          <th
            className={"text-center"}
          >Balení
          </th>

          {this.props.columnVisibility.isColumnConsultantPriceVisible && (
            <th
              className={"text-center"}
            >Poradce/RZ</th>
          )}

          {this.props.columnVisibility.isColumnDiscountPriceVisible && (
            <th
              className={"text-center"}
            >RD sleva</th>
          )}

          {this.props.columnVisibility.isColumnCustomerPriceVisible && (
            <th
              className={"text-center"}
            >Zák./RD</th>
          )}

          {this.props.columnVisibility.isColumnPointsVisible && (
            <th
              className={"text-center"}
            >Bod</th>
          )}

          <th
            className={"text-center"}
          >kg
          </th>

          <th
            className={"text-center"}
          >DPH
          </th>
        </tr>
        </thead>
        <tbody>

        {/* products */}
        {this.props.data
          .filter(node => node.product_id !== null)
          .filter(node => node.product.is_price_list_visible)
          .sort((a, b) => a.product.code - b.product.code)
          .map(node => (
            <ProductTreeNode
              key={node.id}
              node={node}
              depth={0}
              unitOfMeasures={this.props.unitOfMeasures}
              productSelectHandler={this.props.productSelectHandler}
              selectedProducts={this.props.selectedProducts}
              columnVisibility={this.props.columnVisibility}
            />
          ))}

        {/* categories */}
        {this.props.data
          .filter(node => node.category_id !== null)
          .map(node => (
            <ProductTreeNode
              key={node.id}
              node={node}
              depth={0}
              unitOfMeasures={this.props.unitOfMeasures}
              productSelectHandler={this.props.productSelectHandler}
              selectedProducts={this.props.selectedProducts}
              columnVisibility={this.props.columnVisibility}
            />
          ))}
        </tbody>
      </table>
    )
  }
}
