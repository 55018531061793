import React, {Component} from "react"
import API from "../API"
import HistoryTable from "../Base/HistoryTable/HistoryTable"
import salePropName from "../fixtures/salePropName"
import saleValueName from "../fixtures/saleValueName"
import saleEntity from "../fixtures/saleEntity"

export default class SaleHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getData()
    }
  }

  render() {
    return (
      <HistoryTable
        name={"detail zakázky"}
        data={this.state.data}
        entityPropName={salePropName}
        entityValueName={saleValueName}
        entity={saleEntity}
        isLoading={this.state.isLoading}
      />
    )
  }

  getData() {
    API.get(`/history/sale/${this.id}`)
      .then(({data}) => {
        this.setState({
          data: data.objects,
          isLoading: false,
        })
      })
  }
}
