import React from "react"
import {NavLink} from "react-router-dom"
import RBAC from "../RBAC/RBAC"
import {APP_VERSION} from "../const"
import styles from "./styles.module.css"

const MyFooter = () => (
  <div
    className={`${styles.footer} bg-light p-3 text-black-50 d-print-none`}
  >
    Aplikace moje.missiva.cz verze {APP_VERSION}, Přihlášený
    uživatel: <NavLink
    to={`/my-customers/${RBAC.getUser().customer_id}/detail`}
  >
    {RBAC.getUser().name || ""} {RBAC.getUser().surname || ""}
    <small> (klikni pro detail svého účtu)</small>
  </NavLink>
    <span
      className={"d-inline-block float-lg-right"}
    >
      Zákaznické centrum Missiva spol. s r. o.:
      &nbsp;
      <a
        href={"tel:+420 417 590 911"}
      >+420 417 590 911</a>
      &nbsp;|&nbsp;
      <a
        href={"mailto:objednavky@missiva.cz"}
      >objednavky@missiva.cz</a>
    </span>
  </div>
)

export default MyFooter
