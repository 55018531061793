import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarMyProducts = () => {
  return (
    <Switch>
      <Route
        path={"/my-products"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/my-products/price-list"}
              className={"nav-link"}
              activeClassName={"active"}
            >Ceník</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarMyProducts
