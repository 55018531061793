import axios from "axios"
import {debounce} from "throttle-debounce"
import RBAC from "./RBAC/RBAC"
import {API_URL} from "./const"

const API = axios.create({
  baseURL: API_URL[window.location.hostname]
})

API.setToken = token => {
  if (token)
    API.defaults.headers.common.Authorization = `Bearer ${token}`
  else
    delete API.defaults.headers.common.Authorization
}

API.beforeRequest = () => {} // dummy function called before every request

API.beforeResponse = () => {} // dummy function called before every response

API.onUnauthorized = () => {}

API.interceptors.request.use(
  req => {
    API.beforeRequest(req)
    return req
  }
  // do not handle error request
)

API.interceptors.response.use(
  res => {
    API.beforeResponse(res)
    return res
  },
  err => {
    API.beforeResponse(err)
    if (err.response && err.response.status === 400) { // 400 Bad request
      // do not show error message for too many data to export (XLS)
      if (err.response.config.url.indexOf("csv=") === -1)
        API.formatErrors(err.response.data.error_message)
    } else if (err.response && err.response.status === 401) { // 401 Unauthorized
      API.formatErrors(err.response.data.error_message)
      if (RBAC.isAuthenticated())
        API.onUnauthorized()
    } else if (err.response && err.response.status === 403) { // 403 Forbidden
      if (err.response.config.url.indexOf("auth") > -1) // wrong login
        API.formatErrors(err.response.data.error_message)
    } else if (err.response && err.response.status === 423) { // 423 Locked — sale locking functionality
      // nothing to do ...
    } else if (err.response && err.response.status === 404) { // 404 Not found
      // shipments and pickup list has some internal errors, so do not show them.
      // errors are handled by component itself.
      if (err.response.config.url.indexOf("api/shipment") === -1) {
        alert("Hledaný objekt neexistuje. Zkontrolujte prosím adresu URL. Nebo se vraťte o stránku zpět.")
      }
    } else { // API call data error
      API.formatErrors(err.response ? err.response.data.error_message : ["Neočekávaná chyba."])
    }
    return Promise.reject(err)
  }
)

API.formatErrors = debounce(1000, true, errorMessages => alert(errorMessages.reduce((acc, item) => `${acc}\n${item}`, "")))

// by default set persisted token from local storage
API.setToken(RBAC.getToken())

export default API
