import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {NavLink} from "react-router-dom"
import {getParams} from "../../Utils"
import Grid from "../../Base/Grid"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"

export default class ProductKingTemplateList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
      }, params),
      _columns: [],
    }

    this.apiUrl = "/product-king-template"
    this.appUrl = "/reports/contests/product-king/templates"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-md-6 mb-3"}
        >
          <NavLink
            className={"btn btn-primary mr-3"}
            to={"/reports/contests/product-king/templates/add"}
          >Nová šablona</NavLink>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Název",
      dataField: "name",
      formatter: this.formatName.bind(this),
    }, {
      text: "Produkt",
      dataField: "products_data",
      formatter: this.formatProducts.bind(this),
    }]
  }

  formatName(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/reports/contests/product-king/templates/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatProducts(cell, row, rowIndex, formatExtraData) {
    return cell
      .filter(product => product.names.length > 0)
      .map(product => (
        <div
          key={product.id}
        >{product.names[0].name}</div>
      ))
  }
}
