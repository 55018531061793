import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import API from "../../API"

export default class PackageTypeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "",
      weight: 0,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getPackage()
    }
  }

  render() {
    return (
      <>
        <div
          className={"mb-3"}
        >
          <NavLink
            to={"/enums/package-types/add"}
            className={"btn btn-primary mr-3"}
          >Přidat typ balíků</NavLink>

          {this.id && (
            <button
              className={"btn btn-danger mr-3 float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat typ balíků</button>
          )}
        </div>

        <div
          className={"row"}
        >
          <div
            className={"col-lg-5"}
          >
            <form
              onSubmit={this.handleFormSubmit.bind(this)}
            >
              <div
                className={"form-group"}
              >
                <label>Typ:</label>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder={"Typ"}
                  required={true}
                  onChange={this.handleTypeChange.bind(this)}
                  value={this.state.type}
                  minLength={1}
                  maxLength={30}
                />
              </div>

              <div
                className={"form-group"}
              >
                <label>Váha (g):</label>
                <input
                  type={"number"}
                  className={"form-control"}
                  placeholder={"Váha"}
                  required={true}
                  onChange={this.handleWeightChange.bind(this)}
                  value={this.state.weight}
                  step={0.01}
                  min={0.01}
                />
              </div>

              <button
                type={"button"}
                className={"btn btn-warning mr-2"}
                onClick={this.handleCancel.bind(this)}
              >Zrušit
              </button>
              <button
                type={"submit"}
                className={"btn btn-success"}
              >Uložit
              </button>
            </form>
          </div>
        </div>
      </>
    )
  }

  handleTypeChange(e) {
    this.setState({
      type: e.target.value,
    })
  }

  handleWeightChange(e) {
    this.setState({
      weight: parseFloat(e.target.value),
    })
  }

  handleDelete() {
    API.delete(`/packageTypes/${this.id}`)
      .then(() => {
        this.props.history.push("/enums/package-types")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/package-types")
  }

  updatePackage() {
    API.put(`/packageTypes/${this.id}`, this.state)
      .then(() => {
        this.props.history.push("/enums/package-types")
      })
  }

  addPackage() {
    API.post("/packageTypes", this.state)
      .then(() => {
        this.props.history.push("/enums/package-types")
      })
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updatePackage()
    } else {
      this.addPackage()
    }
  }

  getPackage() {
    API.get(`/packageTypes/${this.id}`)
      .then((res) => {
        let {type, weight} = res.data
        this.setState({type, weight})
      })
  }
}
