import React, {Component} from "react"

export default class MyProductTreeNode extends Component {
  componentDidMount() {
    if (this.props.node.product) {
      this.productRowRef = React.createRef()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.node.product &&
      prevProps.productId !== this.props.productId &&
      this.props.node.product.id === this.props.productId
    ) {
      this.productRowRef.current.scrollIntoView({
        block: "center",
      })
    }
  }

  render() {
    return (
      <>
        {/* category */}
        {this.props.node.category && (
          <tr>
            <td
              colSpan={this.props.isPointsVisible ? 7 : 6}
              className={`${this.props.depth < 2 ? "bg-primary" : "bg-light-primary"} text-white text-center`}
            >
              <strong>{this.props.node.category.name}</strong>
            </td>
          </tr>
        )}

        {/* product */}
        {this.props.node.product && (
          <tr
            ref={this.productRowRef}
            className={`
              ${this.props.node.product.id === this.props.productId ? "bg-warning" : ""} 
              ${this.props.clickedNodeId === this.props.node.id ? "bg-light-green" : ""}
            `}
            onClick={this.handleRowClick.bind(this)}
          >
            <td
              className={"text-center"}
            >{this.props.node.product.code}</td>
            <td>{this.getCZProductName()}</td>
            <td
              className={"text-center"}
            >{this.props.node.product.unit_count} {this.getUnitOfMeasure()}</td>
            <td
              className={"text-right"}
            >{this.props.node.product.prices[this.props.priceType]} Kč
            </td>
            {this.props.isPointsVisible && (
              <td
                className={"text-right"}
              >{this.props.node.product.points}</td>
            )}
            <td
              className={"text-right"}
            >{this.props.node.product.weight}
            </td>
            <td
              className={"text-right"}
            >{this.props.node.product.vat_rate} %
            </td>
          </tr>
        )}

        {/* products */}
        {this.props.node.children && this.props.node.children
          .filter(node => node.product_id !== null)
          .filter(node => node.product.is_price_list_visible)
          .sort((a, b) => a.product.code - b.product.code)
          .map(child => (
            <MyProductTreeNode
              key={child.id}
              node={child}
              depth={this.props.depth + 1}
              unitOfMeasures={this.props.unitOfMeasures}
              priceType={this.props.priceType}
              productId={this.props.productId}
              rowClickHandler={this.props.rowClickHandler}
              clickedNodeId={this.props.clickedNodeId}
              isPointsVisible={this.props.isPointsVisible}
            />
          ))}

        {/* categories */}
        {this.props.node.children && this.props.node.children
          .filter(node => node.category_id !== null)
          .map(child => (
            <MyProductTreeNode
              key={child.id}
              node={child}
              depth={this.props.depth + 1}
              unitOfMeasures={this.props.unitOfMeasures}
              priceType={this.props.priceType}
              productId={this.props.productId}
              rowClickHandler={this.props.rowClickHandler}
              clickedNodeId={this.props.clickedNodeId}
              isPointsVisible={this.props.isPointsVisible}
            />
          ))}
      </>
    )
  }

  getCZProductName() {
    let names = this.props.node.product.names.filter(name => name.country_id === 1) // CZ

    if (names.length > 0) {
      return names[0].name
    }

    return ""
  }

  getUnitOfMeasure() {
    if (this.props.unitOfMeasures && this.props.unitOfMeasures.length > 0) {
      let units = this.props.unitOfMeasures.filter(unitOfMeasure => this.props.node.product.unit_of_measure_id === unitOfMeasure.id)

      if (units.length > 0) {
        return units[0].name
      }
    }

    return ""
  }

  handleRowClick() {
    this.props.rowClickHandler(this.props.node.id)
  }
}
