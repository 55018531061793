import React from "react"
import {NavLink} from "react-router-dom"
import print from "../Base/print"

export default (props) => (
  <div
    className={`col-12 ${props.isTop ? "mb-3" : "py-2 menu-bottom bg-white"}`}
    style={{
      bottom: props.bottomMenuPos
    }}
  >
    <div
      className={"row"}
    >
      <div
        className={"col-6"}
      >
        <span
          className={"mr-2 text-secondary"}
        >{props.isReturn ? "Tvorba dobropisu" : "Detail zakázky"}</span>

        {/********** LEFT SALE DETAIL MENU **********/}

        {props.isDetail && (
          <>
            {!props.isReturn && (
              <NavLink
                to={"/sales/add"}
                className={"btn btn-sm btn-primary mr-2"}
              >Nová zakázka</NavLink>
            )}

            {props.deliveryAndInvoiceUrl && !props.isReturn && (
              <button
                className={"btn btn-sm btn-light mr-2"}
                type={"button"}
                onClick={() => print(props.deliveryAndInvoiceUrl)}
              >Tisk</button>
            )}

            {props.saleId && !props.isReturn && (
              <NavLink
                to={`/sales/${props.saleId}/history`}
                className={"btn btn-sm btn-light mr-2"}
              >Historie</NavLink>
            )}

            {props.saleId && !props.isReturn && (
              <NavLink
                to={`/sales/${props.saleId}/tracking`}
                className={"btn btn-sm btn-light mr-2"}
              >Sledování zásilky</NavLink>
            )}

            {props.email && (
              <a
                href={`mailto:${props.email}?subject=Zakázka č. ${props.saleNo}`}
                className={"btn btn-sm btn-light mr-2"}
              >Odeslat e-mail</a>
            )}
          </>
        )}
      </div>

      {/********** SALE RETURN MENU **********/}

      {props.isReturn && (
        <div
          className={"col-6 text-right"}
        >
          <NavLink
            className={"btn btn-sm btn-warning mr-2"}
            to={`/sales/${props.saleId}/detail`}
          >Zpět</NavLink>

          <button
            className={"btn btn-sm btn-primary"}
            type={"button"}
            onClick={props.returnSaveClickHandler}
          >Uložit
          </button>
        </div>
      )}

      {/********** RIGHT SALE DETAIL MENU **********/}

      {!props.isReturn && (
        <div
          className={"col-6 text-right"}
        >
          {props.isLocked && (
            <button
              className={"btn btn-sm btn-success mr-2"}
              onClick={props.unlockHandler}
              disabled={!props.canUnlock}
              title={props.canUnlock
                ? "" // sale can be unlocked by user
                : props.isLockedBy.name
                  ? `Uzamčeno uživatelem ${props.isLockedBy.name} ${props.isLockedBy.surname} (${props.isLockedBy.email})`
                  : "Zakázku již nelze editovat, protože byla expedována. Pro další úpravy vytvořte vratku nebo dobropis."
              }
              type={"button"}
            >Odemknout</button>
          )}

          <button
            className={"btn btn-sm btn-warning mr-2"}
            onClick={props.formCancelHandler}
            disabled={props.isLocked}
            type={"button"}
          >Zrušit
          </button>

          {props.isPromo4Plus1MenuVisible && (
            <button
              className={"btn btn-sm btn-primary mr-2"}
              type={"button"}
              onClick={props.isPromo4Plus1OpenHandler}
            >Zobrazit náhled akce 4+1</button>
          )}

          {!props.isPromo4Plus1MenuVisible && (
            <>
              <button
                className={"btn btn-sm btn-primary mr-2"}
                disabled={props.isLocked}
                type={"submit"}
              >Uložit jako rozpracovanou
              </button>

              {props.haveToWaitForPayment
                ? (
                  <button
                    className={"btn btn-sm btn-success mr-2"}
                    disabled={props.isLocked}
                    onClick={props.paidReadyHandler}
                    type={"button"}
                  >Připravená k zaplacení</button>
                ) : (
                  props.isPersonalPickup
                    ? (
                      <button
                        className={"btn btn-sm btn-success mr-2"}
                        disabled={props.isLocked}
                        onClick={props.personalPickupPrintHandler}
                        type={"button"}
                      >Zaplatit a vytisknout</button>
                    ) : (
                      <>
                        <button
                          className={"btn btn-sm btn-success mr-2"}
                          disabled={props.isLocked}
                          onClick={props.printReadyHandler}
                          type={"button"}
                        >Připravit k tisku
                        </button>

                        {props.shouldPrint && (
                          <button
                            className={"btn btn-sm btn-success mr-2"}
                            disabled={props.isLocked}
                            onClick={props.printHandler}
                            type={"button"}
                          >Vytisknout</button>
                        )}
                      </>
                    )
                )}
            </>
          )}

          <button
            className={"btn btn-sm btn-danger float-right"}
            onClick={props.deleteHandler}
            disabled={!props.isDetail || props.status === "paid" || props.status === "storno"}
            type={"button"}
          >{props.status === "created_open"
            ? "Smazat"
            : props.status === "packed" || props.status === "unpaid"
              ? "Vytvořit vratku"
              : "Storno"
          }
          </button>
        </div>
      )}
    </div>
  </div>
)
