import React, {Component} from "react"
import {List} from "immutable"
import Select from "react-select"
import {Prompt} from "react-router-dom"
import API from "../API"
import {cleanState} from "../Utils"
import {ReactSelectCustomStyles} from "../Base/ReactSelectCustomStyles"
import ProductMenu from "./ProductMenu"
import Note from "../Base/Note"
import COUNTRY from "../Base/Enum/Countries"

export default class ProductForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      names: List(),
      code: "",
      unit_of_measure_id: 1,
      unit_count: "",
      weight: "",
      weight_net: "",
      price: "",
      vat_rate: "",
      points: "",
      box: false,
      ecological: false,
      use_price_category: false,
      is_is_order_visible: false,
      is_my_order_visible: false,
      is_price_list_visible: false,
      categories: [], // selected categories for API
      product_type_id: null,
      notes: List(),
      excluded_from_free_shipping: false,
      _note_value: "",
      _countries: [],
      _unitOfMeasures: [],
      _categories: [], // all categories
      _categoryOptions: [], // selected categories for react select
    }

    this.formChanges = false
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.id = parseInt(this.props.match.params.id, 10)
      this.getProduct()
    }
    this.getCountries()
    this.getUnitOfMeasures()
    this.getCategories()
    this.getProductTypes()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // handle non saved changes
    if (
      !this.formChanges
      && prevState.names.size !== 0 // getCountries modified names, wait for product detail on detail page
      && JSON.stringify(cleanState(this.state)) !== JSON.stringify(cleanState(prevState))
    ) {
      this.formChanges = true
    }
  }

  render() {
    return (
      <>
        <Prompt
          message={() => this.formChanges ? "Máte neuložené změny. Opravdu chcete odejít?" : true}
        />

        <div
          className={"mb-3"}
        >
          <ProductMenu
            productId={this.id}
            isDetail={!!this.id}
            deleteHandler={this.handleDelete.bind(this)}
          />
        </div>

        <form
          className={"row"}
          onSubmit={this.handleFormSubmit.bind(this)}
        >
          <div
            className={"col-lg-5"}
          >
            <div
              className={"form-group"}
            >
              <label>Stav:</label>
              <input
                type={"text"}
                className={"form-control"}
                disabled={true}
                value={this.state.status ? (this.state.status === "active" ? "Aktivní" : "Smazaný") : ""}
              />
            </div>

            {this.state._countries.map(country => (
              <div
                key={country.id}
                className={"form-row"}
              >
                <div
                  className={`form-group ${country.code === "CZ" ? "col-8" : "col-12"}`}
                >
                  <label>Název produktu pro zemi {country.name}</label>
                  <input
                    type={"text"}
                    className={"form-control"}
                    value={this.getCountryProductName(country.id)}
                    onChange={e => this.handleProductNameChange(e.target.value, country.id)}
                    required={true}
                  />
                </div>
                {country.code === "CZ" && (
                  <div
                    className={"form-group col-4"}
                  >
                    <label>&nbsp;</label>
                    <button
                      onClick={this.handleNameCopy.bind(this)}
                      type={"button"}
                      className={"btn btn-light w-100"}
                    >Zkopírovat název
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div
            className={"col-lg-5"}
          >
            <div
              className={"form-group select-required"}
            >
              <label>Kategorie:</label>
              <Select
                value={this.state._categoryOptions}
                onChange={this.handleCategorySelect.bind(this)}
                options={this.state._categories}
                isLoading={this.state._categories.length === 0}
                placeholder={"Kategorie"}
                isMulti={true}
                styles={ReactSelectCustomStyles}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Kód produktu:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handleCodeChange.bind(this)}
                value={this.state.code}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Měrná jednotka:</label>
              <select
                value={this.state.unit_of_measure_id || 1}
                className={"form-control"}
                required={true}
                onChange={this.handleUnitOfMeasureIdChange.bind(this)}
              >
                {this.state._unitOfMeasures.map(unit => (
                  <option
                    key={unit.id}
                    value={unit.id}
                  >{unit.name}</option>
                ))}
              </select>
            </div>

            <div
              className={"form-group"}
            >
              <label>Počet jednotek:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handleUnitCountChange.bind(this)}
                value={this.state.unit_count}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Váha:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handleWeightChange.bind(this)}
                value={this.state.weight}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Váha bez obalu:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handleWeightNetChange.bind(this)}
                value={this.state.weight_net}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Cena:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handlePriceChange.bind(this)}
                value={this.state.price}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>DPH:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handleVatRateChange.bind(this)}
                value={this.state.vat_rate || ""}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Body:</label>
              <input
                type={"number"}
                className={"form-control"}
                required={true}
                onChange={this.handlePointsChange.bind(this)}
                value={this.state.points === null ? "" : this.state.points}
              />
            </div>
          </div>

          <div
            className={"col-lg-2"}
          >
            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"box"}
                className={"form-check-input"}
                onChange={this.handleBoxChange.bind(this)}
                checked={this.state.box}
              />
              <label
                htmlFor={"box"}
                className={"form-check-label"}
              >Balíček</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"ecological"}
                className={"form-check-input"}
                onChange={this.handleEcologicalChange.bind(this)}
                checked={this.state.ecological}
              />
              <label
                htmlFor={"ecological"}
                className={"form-check-label"}
              >Ekologicky šetrný výrobek</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"use_price_category"}
                className={"form-check-input"}
                onChange={this.handleUsePriceCategoryChange.bind(this)}
                checked={this.state.use_price_category}
              />
              <label
                htmlFor={"use_price_category"}
                className={"form-check-label"}
              >Použít cenové kategorie</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"is_is_order_visible"}
                className={"form-check-input"}
                onChange={this.handleIsISOrderVisibleChange.bind(this)}
                checked={this.state.is_is_order_visible}
              />
              <label
                htmlFor={"is_is_order_visible"}
                className={"form-check-label"}
              >Viditelnost v objednávce is.misisva.cz</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"is_my_order_visible"}
                className={"form-check-input"}
                onChange={this.handleIsMYOrderVisibleChange.bind(this)}
                checked={this.state.is_my_order_visible}
              />
              <label
                htmlFor={"is_my_order_visible"}
                className={"form-check-label"}
              >Viditelnost v objednávce moje.missiva.cz</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"is_price_list_visible"}
                className={"form-check-input"}
                onChange={this.handleIsPriceListVisibleChange.bind(this)}
                checked={this.state.is_price_list_visible}
              />
              <label
                htmlFor={"is_price_list_visible"}
                className={"form-check-label"}
              >Viditelnost v ceníku</label>
            </div>

            <div
              className={"form-group form-check"}
            >
              <input
                type={"checkbox"}
                id={"excluded_from_free_shipping"}
                className={"form-check-input"}
                onChange={this.handleExcludedFromFreeShippingChange.bind(this)}
                checked={this.state.excluded_from_free_shipping}
              />
              <label
                htmlFor={"excluded_from_free_shipping"}
                className={"form-check-label"}
              >Nepočítat do balného zdarma</label>
            </div>
          </div>

          <div
            className={"col-lg-10"}
          >
            <strong
              className={"form-group d-block"}
            >Poznámky</strong>

            <div
              className={"form-group"}
            >
              <label>Vyplňte text poznámky:</label>
              <input
                type={"text"}
                className={"form-control"}
                onChange={this.handleNoteChange.bind(this)}
                value={this.state._note_value}
              />
            </div>

            <div
              className={"form-group text-right"}
            >
              <button
                className={"btn btn-primary mr-3"}
                type={"button"}
                onClick={() => this.handleAddNote(true)}
                disabled={!this.state._note_value}
              >Uložit poznámku
              </button>

              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={() => this.handleAddNote(false)}
                disabled={!this.state._note_value}
              >Uložit poznámku pouze k produktu
              </button>
            </div>

            <div
              className={"form-group"}
            >
              <div
                className={"scrollable border p-3"}
              >
                <table
                  className={"table mb-0"}
                >
                  <tbody>
                  {this.state.notes.map((note, i) => (
                    <Note
                      key={note.id || i}
                      id={note.id || i}
                      index={i}
                      note={note}
                      deleteHandler={() => this.handleDeleteNote(i)}
                    />
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            className={"col-lg-10 text-right"}
          >
            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </div>
        </form>
      </>
    )
  }

  handleDelete() {
    API.delete(`/products/${this.id}`)
      .then(() => {
        this.formChanges = false
        this.props.history.push("/products")
      })
  }

  handleCancel() {
    this.props.history.push("/products")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.state.categories.length === 0) {
      return alert("Vyberte kategorii.")
    }

    if (this.id) {
      this.updateProduct()
    } else {
      this.addProduct()
    }
  }

  handleProductNameChange(countryName, countryId) {
    let nameIndex = this.state.names.findIndex(name => name.country_id === countryId)

    if (nameIndex > -1) {
      let name = Object.assign({}, this.state.names.get(nameIndex), {
        name: countryName,
      })

      this.setState({
        names: this.state.names.update(nameIndex, () => name)
      })
    }
  }

  handleNameCopy() {
    let czNameIndex = this.state.names.findIndex(name => name.country_id === COUNTRY.CZECH_REPUBLIC)
    let names = this.state.names.toArray()

    names.forEach(name => {
      name.name = this.state.names.get(czNameIndex).name
    })

    this.setState({
      names: List(names)
    })
  }

  handleCodeChange(e) {
    this.setState({
      code: parseInt(e.target.value, 10)
    })
  }

  handleUnitOfMeasureIdChange(e) {
    this.setState({
      unit_of_measure_id: parseInt(e.target.value, 10)
    })
  }

  handleUnitCountChange(e) {
    let value = parseFloat(e.target.value)

    if (!isNaN(value)) {
      this.setState({
        unit_count: value,
      })
    }
  }

  handleWeightChange(e) {
    let value = parseFloat(e.target.value)

    if (!isNaN(value)) {
      this.setState({
        weight: value
      })
    }
  }

  handleWeightNetChange(e) {
    let value = parseFloat(e.target.value)

    if (!isNaN(value)) {
      this.setState({
        weight_net: value
      })
    }
  }

  handlePriceChange(e) {
    this.setState({
      price: parseFloat(e.target.value)
    })
  }

  handleVatRateChange(e) {
    this.setState({
      vat_rate: parseFloat(e.target.value)
    })
  }

  handlePointsChange(e) {
    this.setState({
      points: parseInt(e.target.value)
    })
  }

  handleBoxChange(e) {
    this.setState({
      box: e.target.checked
    })
  }

  handleEcologicalChange(e) {
    this.setState({
      ecological: e.target.checked
    })
  }

  handleUsePriceCategoryChange(e) {
    this.setState({
      use_price_category: e.target.checked
    })
  }

  handleIsISOrderVisibleChange(e) {
    this.setState({
      is_is_order_visible: e.target.checked,
    })
  }

  handleIsMYOrderVisibleChange(e) {
    this.setState({
      is_my_order_visible: e.target.checked,
    })
  }

  handleIsPriceListVisibleChange(e) {
    this.setState({
      is_price_list_visible: e.target.checked,
    })
  }

  handleExcludedFromFreeShippingChange(e) {
    this.setState({
      excluded_from_free_shipping: e.target.checked,
    })
  }

  handleCategorySelect(options) {
    let ids = []

    options.forEach(option => {
      ids.push(parseInt(option.id, 10))
    })

    this.setState({
      categories: ids,
      _categoryOptions: options,
    })
  }

  getProduct() {
    API.get(`/products/${this.id}`)
      .then(({data}) => {
        data.names = List(data.names)
        data.notes = List(data.notes)
        let categories = []
        let categoryOptions = []
        data.categories.forEach(category => {
          if (category.length > 0) {
            let lastCategory = category[category.length - 1]
            categories.push(lastCategory.id)
            categoryOptions.push(Object.assign(lastCategory, {
              value: lastCategory.id,
              label: lastCategory.category.name,
            }))
          }
        })
        data.categories = categories
        data._categoryOptions = categoryOptions
        this.setState(data)
      })
  }

  getCountries() {
    API.get("/countries?limit=0&offset=0")
      .then(res => {
        this.setState({
          _countries: res.data.objects
        }, () => {
          if (!this.id) {
            this.processCountries()
          }
        })
      })
  }

  processCountries() {
    let names = []

    this.state._countries.forEach(country => {
      names.push({
        country_id: country.id,
        name: "",
      })
    })

    this.setState({
      names: List(names)
    })
  }

  getUnitOfMeasures() {
    API.get("/unitOfMeasures?limit=0&offset=0")
      .then(res => {
        this.setState({
          _unitOfMeasures: res.data.objects
        })
      })
  }

  getCategories() {
    API.get("/productTree?limit=10")
      .then(res => {
        let categories = []

        let getCategory = (node, depth) => {
          if (node.category) {
            categories.push({
              id: node.id,
              value: node.id,
              name: node.category.name,
              label: node.category.name,
            })
          }

          if (node.children) {
            node.children.forEach(child => {
              getCategory(child, depth + 1)
            })
          }
        }

        getCategory(res.data.objects[0], 0)

        this.setState({
          _categories: categories
        })
      })
  }

  getProductTypes() {
    API.get("/productTypes")
      .then(({data}) => {
        let types = data.objects.filter(type => type.is_editable && type.is_removable)

        this.setState({
          product_type_id: types.length > 0 ? types[0].id : null
        })
      })
  }

  getCountryProductName(countryId) {
    let index = this.state.names.findIndex(name => name.country_id === countryId)

    if (index > -1) {
      return this.state.names.get(index).name
    }

    return ""
  }

  updateCategories(id) {
    API.post("/products/branches", {
      products: [{
        id: id,
        branches: this.state.categories,
      }]
    }).then(() => {
      this.formChanges = false
      this.props.history.push("/products")
    })
  }

  updateProduct() {
    let data = Object.assign({}, cleanState(this.state))
    delete data.categories

    API.put(`/products/${this.id}`, data)
      .then(() => {
        this.updateCategories(this.id)
      })
  }

  addProduct() {
    let data = Object.assign({}, cleanState(this.state))
    delete data.categories

    API.post("/products", data)
      .then(res => {
        this.updateCategories(res.data.id)
      })
  }

  handleNoteChange(e) {
    this.setState({
      _note_value: e.target.value,
    })
  }

  handleAddNote(isExtended) {
    this.setState({
      notes: this.state.notes.push({
        note: this.state._note_value,
        is_extended: isExtended,
      }),
      _note_value: "",
    })
  }

  handleDeleteNote(i) {
    this.setState({
      notes: this.state.notes.delete(i)
    })
  }
}
