import React, {Component} from "react"
import BootstrapTable from "react-bootstrap-table-next"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import API from "../API"

export default class LogExternalList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    this.setState({
      isLoading: true,
    })
    API.get("/public-log/")
      .then(({data}) => {
        this.setState({
          files: data.files,
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <BootstrapTable
            bootstrap4={true}
            remote={true}
            striped={true}
            hover={true}
            keyField={"url"}
            columns={this.getColumns()}
            data={this.state.files}
            noDataIndication={"Nic nenalezeno"}
          />
        </div>
      </div>
    )
  }

  formatUrl(cell, row, rowIndex, formatExtraData) {
    return (
      <a
        href={cell}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >{cell}</a>
    )
  }

  getColumns() {
    return [{
      text: "Adresa",
      dataField: "url",
      formatter: this.formatUrl.bind(this),
    }, {
      text: "Změněno",
      dataField: "date",
    },]
  }
}
