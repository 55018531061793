import React from "react"
import {NavLink} from "react-router-dom"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import Grid from "../../Base/Grid"
import API from "../../API"
import DISCOUNT_GROUP from "../../Base/Enum/DiscountGroup"
import {getParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import DISCOUNT_TYPE from "../../Base/Enum/DiscountType"
import getProductNameByCountry from "../../Base/getProductNameByCountry"

export default class DiscountList extends Grid {
  constructor(props) {
    super(props)
    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      isLoading: false,
      params: Object.assign(params, {
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
      }),
      _columns: [],
      discountTypes: [],
      discountGroups: [],
    }

    this.apiUrl = "/product-discount-groups"
    this.appUrl = "/products/discount"
    this.apiUrlParams = "&sort=-valid_from"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3"}
        >
          <NavLink
            to={"/products/discount/add-discount"}
            className={"btn btn-primary mr-3"}
          >Přidat slevu</NavLink>
          <NavLink
            to={"/products/discount/add-promotion"}
            className={"btn btn-primary mr-3"}
          >Přidat akci</NavLink>
          <NavLink
            to={"/products/discount/add-promotion-n-plus"}
            className={"btn btn-primary"}
          >Přidat akci 4+1</NavLink>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getDiscountTypes(),
      this.getDiscountGroups(),
    ]).then(axios.spread((discountTypesRes, discountGroupsRes) => {
      this.setState({
        discountTypes: discountTypesRes.data.objects,
        discountGroups: discountGroupsRes.data.objects,
      }, this.getData)
    }))
  }

  getDiscountTypes() {
    return API.get("/product-discount-types")
  }

  getDiscountGroups() {
    return API.get("/product-discount-group-types")
  }

  formatType(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={row.type === DISCOUNT_GROUP.SIMPLE_DISCOUNT
          ? `/products/discount/add-discount/${row.id}`
          : row.type === DISCOUNT_GROUP.ACTION_DISCOUNT
            ? `/products/discount/add-promotion/${row.id}`
            : `/products/discount/add-promotion-n-plus/${row.id}`
        }
      >
        {row.type === DISCOUNT_GROUP.SIMPLE_DISCOUNT && "Sleva"}
        {row.type === DISCOUNT_GROUP.ACTION_DISCOUNT && "Akce"}
        {row.type === DISCOUNT_GROUP.N_PLUS_DISCOUNT && "Akce 4+1"}

        {this.isActive(row.valid_from, row.valid_to, row.admin_valid_to) && (
          <span
            className={"badge badge-info ml-2"}
          >Aktivní</span>
        )}
      </NavLink>
    )
  }

  formatProductCode(cell, row, rowIndex, formatExtraData) {
    return row.product_discount.map(discountProduct => (
      <div
        key={discountProduct.id}
      >
        <NavLink
          to={`/products/${discountProduct.product.id}/detail`}
        >{discountProduct.product.code}</NavLink>
      </div>
    ))
  }

  formatProductName(cell, row, rowIndex, formatExtraData) {
    if (row.type === DISCOUNT_GROUP.N_PLUS_DISCOUNT) {
      return "Pro všechny produkty"
    }

    return row.product_discount.map(discountProduct => (
      <div
        key={discountProduct.id}
      >{getProductNameByCountry(discountProduct.product)}</div>
    ))
  }

  formatProductDiscount(cell, row, rowIndex, formatExtraData) {
    return row.product_discount.map(discountProduct => (
      <div
        key={discountProduct.id}
      >{this.getDiscountInfo(discountProduct)}</div>
    ))
  }

  formatValidFrom(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell, "YYYY-MM-DD HH:mm:ss").format("DD. MM. YYYY HH:mm:ss")
    }

    return ""
  }

  formatValidTo(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell, "YYYY-MM-DD HH:mm:ss").format("DD. MM. YYYY HH:mm:ss")
    }

    return ""
  }

  formatAdminValidTo(cell, row, rowIndex, formatExtraData) {
    if (cell) {
      return moment(cell, "YYYY-MM-DD HH:mm:ss").format("DD. MM. YYYY HH:mm:ss")
    }

    return ""
  }

  getDiscountInfo(discountProduct) {
    if (
      discountProduct.type === DISCOUNT_TYPE.PERCENT_DISCOUNT &&
      discountProduct.value === 100
    ) {
      return "Zdarma"
    }

    if (discountProduct.type === DISCOUNT_TYPE.PERCENT_DISCOUNT &&
      discountProduct.value === 0
    ) {
      return "Nákup"
    }

    let types = this.state.discountTypes.filter(type => type.id === discountProduct.type)

    if (types.length > 0) {
      return `${types[0].name}: ${discountProduct.value} ${discountProduct.type === 1 ? "%" : "Kč"}`
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Typ",
      dataField: "product_discount",
      formatter: this.formatType.bind(this),
    }, {
      text: "Název produktu",
      dataField: "product_discount",
      formatter: this.formatProductName.bind(this),
    }, {
      text: "Kód produktu",
      dataField: "product_discount",
      formatter: this.formatProductCode.bind(this),
    }, {
      text: "Sleva",
      dataField: "product_discount",
      formatter: this.formatProductDiscount.bind(this),
    }, {
      text: "Začátek",
      dataField: "valid_from",
      formatter: this.formatValidFrom.bind(this),
    }, {
      text: "Ukončení v moje.missiva.cz",
      dataField: "valid_to",
      formatter: this.formatValidTo.bind(this),
    }, {
      text: "Ukončení v is.missiva.cz",
      dataField: "admin_valid_to",
      formatter: this.formatAdminValidTo.bind(this),
    },]
  }

  isActive(validFrom, validTo, adminValidTo) {
    return moment().isBetween(validFrom, validTo) || moment().isBetween(validFrom, adminValidTo)
  }
}
