import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"
import CUSTOMER_TYPE from "../Base/Enum/CustomerType"

const NavBarMyCustomers = props => {
  return (
    <>
      <Switch>
        <Route
          path={"/my-customers/(customers|registration)"}
        >
          <nav
            className={"navbar navbar-expand-lg py-0 d-print-none"}
          >
            <div
              className={"navbar-nav mr-auto"}
            >
              <NavLink
                exact={true}
                to={"/my-customers/customers"}
                className={"nav-link"}
                activeClassName={"active"}
              >Přehled zákazníků</NavLink>
              {(props.authenticatedCustomer.type_id === CUSTOMER_TYPE.CK ||
                props.authenticatedCustomer.type_id === CUSTOMER_TYPE.PP ||
                props.authenticatedCustomer.type_id === CUSTOMER_TYPE.PbP ||
                props.authenticatedCustomer.type_id === CUSTOMER_TYPE.MS) && (
                <NavLink
                  exact={true}
                  to={"/my-customers/registration"}
                  className={"nav-link"}
                  activeClassName={"active"}
                >Registrace nového zákazníka</NavLink>
              )}
            </div>
          </nav>
        </Route>
      </Switch>
      <Switch>
        <Route
          path={"/my-customers/tree"}
          exact={true}
        >
          <nav
            className={"navbar navbar-expand-sm py-0 d-print-none"}
          >
            <div
              className={"navbar-nav mr-auto"}
            >
              <NavLink
                exact={true}
                to={"/my-customers/tree"}
                className={"nav-link"}
                activeClassName={"active"}
              >Moje skupinové body</NavLink>
            </div>
          </nav>
        </Route>
      </Switch>
    </>
  )
}

export default NavBarMyCustomers
