import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import DatePicker from "react-datepicker"
import {NavLink} from "react-router-dom"
import axios from "axios"
import Grid from "../../Base/Grid"
import {getParams} from "../../Utils"
import ExportButton from "../../Base/ExportButton"
import newBonusesExportColumn from "../../fixtures/newBonusesExportColumn"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import API from "../../API"
import CONTACT_TYPE from "../../Base/Enum/ContactType"

export default class BonusesList extends Grid {
  constructor(props) {
    super(props)

    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      customerTypes: [],
      countries: [],
      total: 0,
      isLoading: false,
      params: Object.assign({
        offset: 0,
        limit: settings.limit || 25,
        date: moment().format("YYYY-MM")
      }, params),
      _columns: [],
      goldCustomers: []
    }

    this.apiUrl = "/reports/bonuses"
    this.appUrl = "/reports/contests/bonuses"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12 mb-3 text-right"}
        >
          <ExportButton
            exportName={"bonuses-list"}
            fileName={"Nové bonusy"}
            params={this.state.params}
            columns={newBonusesExportColumn}
            apiUrl={this.apiUrl}
          />
        </div>

        <div
          className={"col-12 mb-3"}
        >
          <label>Datum:</label>
          <div
            className={"input-group"}
          >
            <div
              className={"input-group-prepend"}
            >
              <DatePicker
                showMonthYearPicker={true}
                locale={"cs"}
                className={"form-control"}
                selected={moment(this.state.params.date, "YYYY-MM").toDate()}
                onChange={this.handleDateChange.bind(this)}
                dateFormat={"MM/yyyy"}
              />
            </div>
            <button
              className={"btn btn-primary"}
              onClick={this.getData.bind(this)}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                ref={ref => this.gridTableRef = ref}
                columns={this.state._columns}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total,
                })}
                noDataIndication={"Nic nenalezeno"}
                sort={this.sort || {}}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  _getData() {
    axios.all([
      this.getCustomerTypes(),
      this.getCountries(),
      this.getGoldCustomers()
    ]).then(axios.spread((customerTypesRes, countriesRes, goldCustomersRes) => {
      this.setState({
        customerTypes: customerTypesRes.data.objects,
        countries: countriesRes.data.objects,
        goldCustomers: goldCustomersRes.data.objects,
      }, this.getData)
    }))
  }

  getCustomerTypes() {
    return API.get("/customerType?limit=0&offset=0")
  }

  getCountries() {
    return API.get("/countries?limit=0&offset=0")
  }

  getGoldCustomers() {
    return API.get("/customers?is_gold=1&limit=999&offset=0")
  }

  handleDateChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        date: moment(date).format("YYYY-MM")
      })
    })
  }

  formatBonus(cell, row, rowIndex, formatExtraData) {
    return row.points && row.points.length > 0 ? row.points[0].bonus : ""
  }

  formatCustomerNumber(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/customers/${row.id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatCustomerType(cell, row, rowIndex, formatExtraData) {
    let type = formatExtraData.find(customerType => customerType.id === cell)

    if (type) {
      return type.name
    }

    return ""
  }

  formatCountryId(cell, row, rowIndex, formatExtraData) {
    let country = formatExtraData.find(country => country.id === cell)

    if (country) {
      return country.name
    }

    return ""
  }

  formatPhone(cell, row, rowIndex, formatExtraData) {
    if (row.contact) {
      return row.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.PHONE_WORK ||
          contact.type_id === CONTACT_TYPE.PHONE_HOME ||
          contact.type_id === CONTACT_TYPE.PHONE_MOBILE ||
          contact.type_id === CONTACT_TYPE.PHONE_OTHER ||
          contact.type_id === CONTACT_TYPE.TARIFF_02
        )
        .map(contact => (
          <a
            href={`tel:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatEmail(cell, row, rowIndex, formatExtraData) {
    if (row.contact) {
      return row.contact
        .filter(contact => contact.type_id === CONTACT_TYPE.EMAIL)
        .map(contact => (
          <a
            href={`mailto:${contact.value}`}
            className={"d-block"}
            key={contact.id}
          >{contact.value}</a>
        ))
    }

    return ""
  }

  formatCreatedAt(cell, row, rowIndex, formatExtraData) {
    return moment(cell).format("DD. MM. YYYY")
  }

  formatGoldCustomerNo(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return (
        <NavLink
          to={`/customers/${row.id}/detail`}
        >{goldCustomer.customer_no}</NavLink>
      )
    }

    return ""
  }

  formatGoldName(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return goldCustomer.name
    }

    return ""
  }

  formatGoldSurname(cell, row, rowIndex, formatExtraData) {
    let goldCustomer = this.state.goldCustomers.find(goldCustomer => goldCustomer.id === row.gold_id)

    if (goldCustomer) {
      return goldCustomer.surname
    }

    return ""
  }

  getDefaultColumns() {
    return [{
      text: "Bonus",
      dataField: "customer_points__bonus",
      formatter: this.formatBonus.bind(this),
      sort: true,
    }, {
      text: "Č. zákazníka",
      dataField: "customer_no",
      formatter: this.formatCustomerNumber.bind(this),
    }, {
      text: "Příjmení",
      dataField: "surname",
    }, {
      text: "Jméno",
      dataField: "name",
    }, {
      text: "Město",
      dataField: "billing_address_city",
    }, {
      text: "Typ zákazníka",
      dataField: "type_id",
      formatter: this.formatCustomerType.bind(this),
      formatExtraData: this.state.customerTypes,
    }, {
      text: "Země",
      dataField: "country_id",
      formatter: this.formatCountryId.bind(this),
      formatExtraData: this.state.countries,
    }, {
      text: "Telefon",
      dataField: "customer__contact__phone",
      formatter: this.formatPhone.bind(this),
    }, {
      text: "E-mail",
      dataField: "customer__contact__email",
      formatter: this.formatEmail.bind(this),
    }, {
      text: "D. registrace",
      dataField: "created_at",
      formatter: this.formatCreatedAt.bind(this),
    }, {
      text: "Č. zlatého sponzora",
      dataField: "gold__customer_no",
      formatter: this.formatGoldCustomerNo.bind(this),
    }, {
      text: "Jméno zlatého sponzora",
      dataField: "gold__name",
      formatter: this.formatGoldName.bind(this),
    }, {
      text: "Příjmení zlatého sponzora",
      dataField: "gold__surname",
      formatter: this.formatGoldSurname.bind(this),
    },]
  }
}
