import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import UserList from "./Users/UserList"
import UserForm from "./Users/UserForm"
import BankList from "./Banks/BankList"
import BankForm from "./Banks/BankForm"
import CountryList from "./Countries/CountryList"
import CountryForm from "./Countries/CountryForm"
import PostList from "./Posts/PostList"
import PostForm from "./Posts/PostForm"
import UnitList from "./Units/UnitList"
import UnitForm from "./Units/UnitForm"
import ContactTypeList from "./ContactTypes/ContactTypeList"
import ContactTypeForm from "./ContactTypes/ContactTypeForm"
import DeliveryMethodForm from "./DeliveryMethods/DeliveryMethodForm"
import DeliveryMethodList from "./DeliveryMethods/DeliveryMethodList"
import PackageTypeList from "./PackageTypes/PackageTypeList"
import PackageTypeForm from "./PackageTypes/PackageTypeForm"
import OrderMethodForm from "./OrderMethods/OrderMethodForm"
import OrderMethodList from "./OrderMethods/OrderMethodList"
import ProductCategoriesForm from "./ProductCategories/ProductCategoriesForm"
import ProductCategoriesList from "./ProductCategories/ProductCategoriesList"
import LocalTextList from "./LocalText/LocalTextList"
import LocalTextForm from "./LocalText/LocalTextForm"
import TemplateList from "./Templates/TemplateList"
import TemplateForm from "./Templates/TemplateForm"

export default class Enums extends Component {
  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-12"}
        >
          <Switch>
            {/* USERS */}
            <Route
              path={"/enums/users/add"}
              key={"add"}
              component={UserForm}
            />
            <Route
              path={"/enums/users/:id/detail"}
              key={"detail"}
              component={UserForm}
            />
            <Route
              path={"/enums/users/:page?"}
              component={UserList}
            />
            {/* BANKS */}
            <Route
              path={"/enums/banks/add"}
              key={"add"}
              component={BankForm}
            />
            <Route
              path={"/enums/banks/:id/detail"}
              key={"detail"}
              component={BankForm}
            />
            <Route
              path={"/enums/banks/:page?"}
              component={BankList}
            />
            {/* COUNTRIES */}
            <Route
              path={"/enums/countries/add"}
              key={"add"}
              component={CountryForm}
            />
            <Route
              path={"/enums/countries/:id/detail"}
              key={"detail"}
              component={CountryForm}
            />
            <Route
              path={"/enums/countries/:page?"}
              component={CountryList}
            />
            {/* POSTS */}
            <Route
              path={"/enums/posts/add"}
              key={"add"}
              component={PostForm}
            />
            <Route
              path={"/enums/posts/:id/detail"}
              key={"detail"}
              component={PostForm}
            />
            <Route
              path={"/enums/posts/:page?"}
              component={PostList}
            />
            {/* UNITS */}
            <Route
              path={"/enums/units/add"}
              key={"add"}
              component={UnitForm}
            />
            <Route
              path={"/enums/units/:id/detail"}
              key={"detail"}
              component={UnitForm}
            />
            <Route
              path={"/enums/units/:page?"}
              component={UnitList}
            />
            {/* CONTACT TYPES */}
            <Route
              path={"/enums/contact-types/add"}
              key={"add"}
              component={ContactTypeForm}
            />
            <Route
              path={"/enums/contact-types/:id/detail"}
              key={"detail"}
              component={ContactTypeForm}
            />
            <Route
              path={"/enums/contact-types/:page?"}
              component={ContactTypeList}
            />
            {/* DELIVERY METHODS */}
            <Route
              path={"/enums/delivery-methods/:id/detail"}
              key={"detail"}
              component={DeliveryMethodForm}
            />
            <Route
              path={"/enums/delivery-methods/:page?"}
              component={DeliveryMethodList}
            />
            {/* PACKAGE TYPES */}
            <Route
              path={"/enums/package-types/add"}
              key={"add"}
              component={PackageTypeForm}
            />
            <Route
              path={"/enums/package-types/:id/detail"}
              key={"detail"}
              component={PackageTypeForm}
            />
            <Route
              exact
              path={"/enums/package-types/:page?"}
              component={PackageTypeList}
            />
            {/* ORDER METHODS */}
            <Route
              path={"/enums/order-methods/add"}
              key={"add"}
              component={OrderMethodForm}
            />
            <Route
              path={"/enums/order-methods/:id/detail"}
              key={"detail"}
              component={OrderMethodForm}
            />
            <Route
              path={"/enums/order-methods/:page?"}
              component={OrderMethodList}
            />
            {/* PRODUCT CATEGORIES */}
            <Route
              path={"/enums/categories/add"}
              key={"add"}
              component={ProductCategoriesForm}
            />
            <Route
              path={"/enums/categories/:id/detail"}
              key={"detail"}
              component={ProductCategoriesForm}
            />
            <Route
              path={"/enums/categories/:page?"}
              component={ProductCategoriesList}
            />
            {/* LOCAL TEXT */}
            <Route
              path={"/enums/local-text/:id/detail"}
              key={"detail"}
              component={LocalTextForm}
            />
            <Route
              path={"/enums/local-text/:page?"}
              component={LocalTextList}
            />
            {/* TEMPLATES */}
            <Route
              path={"/enums/templates/:service/:type"}
              key={"detail"}
              component={TemplateForm}
            />
            <Route
              path={"/enums/templates"}
              component={TemplateList}
            />
          </Switch>
        </div>
      </div>
    )
  }
}
