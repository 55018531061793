const BONUS_COLOR = {
  0: "#585858",
  3: "#DC143C",
  6: "#ffa100",
  9: "#FFFF00",
  12: "#7CFC00",
  15: "#0000CD",
  18: "#663399",
  21: "#F8F8FF",
}

export default BONUS_COLOR
