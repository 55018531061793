import React from "react"
import {NavLink} from "react-router-dom"

export default (props) => (
  <div
    className={"col-12 mb-3"}
  >
    <span
      className={"mr-3 text-secondary"}
    >Detail zákazníka</span>

    <NavLink
      to={`/my-sales/add/${props.customerId}`}
      className={"btn btn-primary mr-3"}
    >Nová objednávka</NavLink>

    {!props.isMyAccessLimited && (
      <NavLink
        to={`/my-customers/tree/${props.customerId}`}
        className={"btn btn-primary mr-3"}
      >Skupinové informace</NavLink>
    )}

    <NavLink
      to={`/my-sales?customer__customer_no=${props.customerNo}`}
      className={"btn btn-primary"}
    >Historie objednávek</NavLink>
  </div>
)
