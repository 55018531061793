import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import {getParams} from "../Utils"
import Grid from "../Base/Grid"
import ScrollableTable from "../Base/ScrollableTable/ScrollableTable"
import SpinnerWithOverlay from "../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import moment from "moment"
import DatePicker from "react-datepicker"
import DELIVERY_METHOD from "../Base/Enum/DeliveryMethod"
import {NavLink} from "react-router-dom"
import API from "../API"
import print from "../Base/print"

export default class PickUpList extends Grid {
  constructor(props) {
    super(props)

    let params = getParams()
    let settings = JSON.parse(localStorage.getItem("missiva_is.grid_settings")) || {}

    this.state = {
      data: [],
      total: 0,
      params: Object.assign({
        offset: params.offset || 0,
        limit: settings.limit || params.limit || 25,
        picked_up_at: moment().subtract(1, "days").format("YYYY-MM-DD"),
        delivery_method_id: DELIVERY_METHOD.CZECH_POST,
      }, params),
      isLoading: false,
      _columns: [],
    }

    this.apiUrl = "/shipment"
    this.appUrl = "/warehouse/pickup-lists"
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Datum:</label>
          <DatePicker
            isClearable={false}
            locale={"cs"}
            className={"form-control"}
            selected={moment(this.state.params.picked_up_at).toDate()}
            onChange={this.handlePickedUpAtChange.bind(this)}
            dateFormat={"dd. MM. yyyy"}
          />
        </div>

        <div
          className={"col-lg-2 mb-3"}
        >
          <label>Způsob doručení:</label>
          <select
            value={this.state.params.delivery_method_id}
            onChange={this.handleDeliveryMethodChange.bind(this)}
            className={"form-control"}
          >
            <option
              value={DELIVERY_METHOD.CZECH_POST}
            >ČP
            </option>
            <option
              value={DELIVERY_METHOD.DPD}
            >DPD
            </option>
          </select>
        </div>

        <div
          className={"col-lg-3 mb-3"}
        >
          <button
            className={"btn btn-primary mt-lg-4"}
            onClick={this.handlePickupListPrint.bind(this)}
          >Vytisknout předávací protokol
          </button>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          {this.state._columns.length > 0 && (
            <ScrollableTable>
              <BootstrapTable
                bootstrap4={true}
                remote={true}
                striped={true}
                hover={true}
                keyField={"id"}
                columns={this.getDefaultColumns()}
                data={this.state.data}
                onTableChange={this.handleTableChange.bind(this)}
                pagination={paginationFactory({
                  page: (this.state.params.offset / this.state.params.limit) + 1,
                  sizePerPage: this.state.params.limit,
                  totalSize: this.state.total
                })}
                noDataIndication={"Nic nenalezeno"}
              />
            </ScrollableTable>
          )}
        </div>
      </div>
    )
  }

  getDefaultColumns() {
    return [{
      text: "Č. zakázky / Variabilní symbol",
      dataField: "vs",
      formatter: this.formatVS.bind(this),
    }, {
      text: "Cena",
      dataField: "price_on_delivery"
    }, {
      text: "Váha",
      dataField: "weight",
      formatter: this.formatWeight.bind(this),
    }, {
      text: "Počet balíků",
      dataField: "boxes",
      formatter: this.formatBoxes.bind(this),
    },]
  }

  handlePickedUpAtChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        picked_up_at: moment(date).format("YYYY-MM-DD")
      })
    }, this.getData.bind(this))
  }

  handleDeliveryMethodChange(e) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        delivery_method_id: e.target.value,
      })
    }, this.getData.bind(this))
  }

  handlePickupListPrint() {
    this.setState({
      isLoading: true,
    })
    API.get(`/shipment/pickUpList?picked_up_at=${this.state.params.picked_up_at}&delivery_method_id=${this.state.params.delivery_method_id}`)
      .then(({data}) => {
        if (this.state.params.delivery_method_id === DELIVERY_METHOD.CZECH_POST) {
          print(data.ceska_posta_url)
        } else {
          print(data.dpd_url)
        }
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  formatVS(cell, row, rowIndex, formatExtraData) {
    return (
      <NavLink
        to={`/sales/${row.sale_id}/detail`}
      >{cell}</NavLink>
    )
  }

  formatWeight(cell, row, rowIndex, formatExtraData) {
    return row.boxes.reduce((acc, box) => {
      return acc + box.weight
    }, 0)
  }

  formatBoxes(cell, row, rowIndex, formatExtradata) {
    return cell.length || 0
  }
}

