import React, {Component, Fragment} from "react"
import {throttle} from "throttle-debounce"
import styles from "./styles.module.css"

export default class ScrollableTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentWidth: 0
    }
    this.scrollbarTopRef = React.createRef()
    this.scrollbarBottomRef = React.createRef()
    this.contentRef = React.createRef()

    this.handleScrollDebounced = throttle(20, target => {
      let x = target.scrollLeft
      let y = target.scrollTop
      this.scrollbarTopRef.current.scrollTo(x, y)
      this.scrollbarBottomRef.current.scrollTo(x, y)
    })
  }

  componentDidMount() {
    this.updateContentWidth()
    window.addEventListener("resize", this.handleResize.bind(this))
    this.updateInterval = setInterval(this.updateContentWidth.bind(this), 1000)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this))
    clearInterval(this.updateInterval)
  }

  render() {
    return (
      <Fragment>
        <div
          ref={this.scrollbarTopRef}
          onScroll={e => this.handleScrollDebounced(e.target)}
          className={styles.topScrollbar}
        >
          <div
            style={{
              minWidth: this.state.contentWidth,
              minHeight: "1px",
            }}
          />
        </div>

        <div
          ref={this.scrollbarBottomRef}
          onScroll={e => this.handleScrollDebounced(e.target)}
          className={styles.bottomScrollbar}
        >
          <div
            ref={this.contentRef}
          >{this.props.children}</div>
        </div>
      </Fragment>
    )
  }

  handleResize() {
    // TODO: why I need this.contentRef.current??
    if (this.contentRef.current && this.state.contentWidth !== this.contentRef.current.scrollWidth)
      this.setState({
        contentWidth: this.contentRef.current.scrollWidth
      })
  }

  updateContentWidth() {
    if (this.state.contentWidth !== this.contentRef.current.scrollWidth)
      this.setState({
        contentWidth: this.contentRef.current.scrollWidth
      })
  }
}
