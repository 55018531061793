import React, {Component} from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import AsyncSelect from "react-select/async"
import {getParams, serializeParams} from "../../Utils"
import SpinnerWithOverlay from "../../Base/SpinnerWithOverlay/SpinnerWithOverlay"
import ScrollableTable from "../../Base/ScrollableTable/ScrollableTable"
import {loadOptionsDebounced} from "../../Base/loadOptionsDebounced"
import {ReactSelectCustomStyles} from "../../Base/ReactSelectCustomStyles"
import API from "../../API"
import formatCustomerName from "../../Base/formatCustomerName"

export default class CustomerPointsList extends Component {
  constructor(props) {
    super(props)
    let params = getParams()

    this.state = {
      customer: {},
      isLoading: false,
      params: Object.assign({
        points_from: moment().subtract(1, "months").format("YYYY-MM"),
        points_to: moment().format("YYYY-MM"),
        customer_id: "",
      }, params),
    }

    this.appUrl = "/reports/commissions/customer-points"
  }

  componentDidMount() {
    if (this.state.params.customer_id) {
      this.getData()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        <div
          className={"col-lg-3"}
        >
          <label>Zákazník:</label>
          <div
            className={"form-group select-required"}
          >
            <AsyncSelect
              value={this.state.customer}
              loadOptions={(val, cb) => loadOptionsDebounced(val, cb, this.handleCustomerChange.bind(this))}
              onChange={this.handleCustomerSelect.bind(this)}
              noOptionsMessage={() => "Nic nenalezeno"}
              loadingMessage={() => "Načítám"}
              required={true}
              autoFocus={true}
              styles={ReactSelectCustomStyles}
            />
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Od:</label>
          <div
            className={"form-group"}
          >
            <DatePicker
              showMonthYearPicker={true}
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.points_from, "YYYY-MM").toDate()}
              onChange={this.handlePointsFromChange.bind(this)}
              dateFormat={"MM/yyyy"}
            />
          </div>
        </div>

        <div
          className={"col-lg-2"}
        >
          <label>Do:</label>
          <div
            className={"form-group"}
          >
            <DatePicker
              showMonthYearPicker={true}
              locale={"cs"}
              className={"form-control"}
              selected={moment(this.state.params.points_to, "YYYY-MM").toDate()}
              onChange={this.handlePointsToChange.bind(this)}
              dateFormat={"MM/yyyy"}
            />
          </div>
        </div>
        <div
          className={"col-lg-5"}
        >
          <div
            className={"form-group mt-4"}
          >
            <button
              className={"btn btn-primary"}
              onClick={this.getData.bind(this)}
              disabled={!this.state.params.customer_id}
            >Zobrazit přehled
            </button>
          </div>
        </div>

        <div
          className={"col-12"}
        >
          {this.state.isLoading && <SpinnerWithOverlay/>}

          <ScrollableTable>
            <table
              className={"table table-striped mb-0"}
            >
              <thead>
              <tr>
                <th>Měsíc</th>
                <th>Body</th>
                <th>Body na cestě</th>
                <th>Skupinové body</th>
                <th>Skupinové body na cestě</th>
                <th>Celkové body</th>
                <th>Celkové body na cestě</th>
              </tr>
              </thead>
              <tbody>
              {(!this.state.customer.id || this.state.customer.points.length === 0) && (
                <tr>
                  <td
                    colSpan={7}
                    className={"text-center"}
                  >Nic nenalezeno
                  </td>
                </tr>
              )}
              {this.state.customer.points && this.state.customer.points.map((row, i) => (
                <tr
                  key={row.id}
                >
                  <td>{row.month}/{row.year}</td>
                  <td>{row.points} {this.diff("points", i)}</td>
                  <td>{row.points_on_way} {this.diff("points_on_way", i)}</td>
                  <td>{row.group_points} {this.diff("group_points", i)}</td>
                  <td>{row.group_points_on_way} {this.diff("group_points_on_way", i)}</td>
                  <td>{row.total_points} {this.diff("total_points", i)}</td>
                  <td>{row.total_points_on_way} {this.diff("total_points_on_way", i)}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </ScrollableTable>
        </div>
      </div>
    )
  }

  getData() {
    this.setState({
      isLoading: true,
    })

    let params = serializeParams(this.state.params)
    window.history.replaceState({}, "", `/#${this.appUrl}${params}`)

    API.get(`/customers/${this.state.params.customer_id}?points_from=${this.state.params.points_from}&points_to=${this.state.params.points_to}`)
      .then(({data}) => {
        this.setState({
          customer: Object.assign(data, {
            value: data.id,
            label: formatCustomerName(data),
          })
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
      })
  }

  handleCustomerChange(val, cb) {
    API.get(`/customers?limit=20&offset=0&search=${val}`)
      .then(({data}) => {
        data.objects.map(item => Object.assign(item, {
          value: item.id,
          label: formatCustomerName(item),
        }))
        cb(data.objects)
      })
  }

  handleCustomerSelect(option) {
    option.points = [] // remove all points. they should be displayed after show button click.
    this.setState({
      customer: option,
      params: Object.assign({}, this.state.params, {
        customer_id: option.id,
      }),
    })
  }

  handlePointsFromChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        points_from: moment(date).format("YYYY-MM")
      })
    })
  }

  handlePointsToChange(date) {
    this.setState({
      params: Object.assign({}, this.state.params, {
        points_to: moment(date).format("YYYY-MM")
      })
    })
  }

  diff(name, index) {
    let sub = 0

    if (index + 1 < this.state.customer.points.length) {
      sub = this.state.customer.points[index][name] - this.state.customer.points[index + 1][name]
    }

    return (
      <small
        className={sub < 0 ? "text-danger" : sub > 0 ? "text-success" : "text-primary"}
      >
        <i
          className={`fas fa-${sub < 0 ? "long-arrow-alt-down" : sub > 0 ? "long-arrow-alt-up" : "equals"} ml-3 mr-1`}
        />
        {Math.abs(sub)}
      </small>
    )
  }
}
