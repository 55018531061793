import React from "react"
import {Switch, Route, NavLink} from "react-router-dom"

const NavBarCustomers = () => {
  return (
    <Switch>
      <Route
        path={"/customers"}
      >
        <nav
          className={"navbar navbar-expand-lg py-0 d-print-none"}
        >
          <div
            className={"navbar-nav mr-auto"}
          >
            <NavLink
              exact={true}
              to={"/customers"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zákazníci</NavLink>
            <NavLink
              to={"/customers/tree"}
              className={"nav-link"}
              activeClassName={"active"}
            >Skupinové informace</NavLink>
            <NavLink
              to={"/customers/gold"}
              className={"nav-link"}
              activeClassName={"active"}
            >Zlatí poradci</NavLink>
          </div>
        </nav>
      </Route>
    </Switch>
  )
}

export default NavBarCustomers
