import React, {Component} from "react"
import API from "../../API"

export default class OrderMethodForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      price: "",
      sale_price_limit: "",
    }

    if (props.match.params.id) {
      this.id = parseInt(props.match.params.id, 10)
      this.getOrderMethod()
    }
  }

  render() {
    return (
      <div
        className={"row"}
      >
        {this.id && (
          <div
            className={"col-12 mb-3"}
          >
            <button
              className={"btn btn-danger mr-3 float-right"}
              onClick={this.handleDelete.bind(this)}
            >Smazat způsob objednání
            </button>
          </div>
        )}

        <div
          className={"col-5"}
        >
          <form
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <div
              className={"form-group"}
            >
              <label>Název:</label>
              <input
                type={"text"}
                className={"form-control"}
                placeholder={"Název"}
                required={true}
                onChange={this.handleNameChange.bind(this)}
                value={this.state.name}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Cena poštovného:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Cena poštovného"}
                required={true}
                onChange={this.handlePriceChange.bind(this)}
                value={this.state.price}
                min={0}
              />
            </div>

            <div
              className={"form-group"}
            >
              <label>Limit ceny poštovného:</label>
              <input
                type={"number"}
                className={"form-control"}
                placeholder={"Limit ceny poštovného"}
                required={true}
                onChange={this.handleSalePriceLimitChange.bind(this)}
                value={this.state.sale_price_limit}
                min={0}
              />
            </div>

            <button
              type={"button"}
              className={"btn btn-warning mr-2"}
              onClick={this.handleCancel.bind(this)}
            >Zrušit
            </button>
            <button
              type={"submit"}
              className={"btn btn-success"}
            >Uložit
            </button>
          </form>
        </div>
      </div>
    )
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  handlePriceChange(e) {
    this.setState({
      price: parseInt(e.target.value, 10)
    })
  }

  handleSalePriceLimitChange(e) {
    this.setState({
      sale_price_limit: parseInt(e.target.value, 10)
    })
  }

  addOrderMethod() {
    API.post("/orderMethods", this.state)
      .then(() => {
        this.props.history.push("/enums/order-methods")
      })
  }

  handleCancel() {
    this.props.history.push("/enums/order-methods")
  }

  handleFormSubmit(e) {
    e.preventDefault()
    if (this.id) {
      this.updateOrderMethod()
    } else {
      this.addOrderMethod()
    }
  }

  getOrderMethod() {
    API.get(`/orderMethods/${this.id}`)
      .then((res) => {
        this.setState(res.data)
      })
  }

  updateOrderMethod() {
    API.put(`/orderMethods/${this.id}`, this.state)
      .then(() => {
        this.props.history.push("/enums/order-methods")
      })
  }

  handleDelete() {
    API.delete(`/orderMethods/${this.id}`)
      .then(() => {
        this.props.history.push("/enums/order-methods")
      })
  }
}
